import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ClientMasterService {

  //public _baseUrl: string ="http://localhost:53061/api"
  //public _baseUrl: string ="http://10.216.208.39:8058/api"
  //public _baseUrl: string ="http://10.216.208.39:8022/api"

  constructor(private _http: HttpClient) { }


  Upload(formdata: FormData) {

    return this._http.post(`${environment.apiUrl}/ClientEntities/Upload`, formdata);

  }
  GetClientENtity(isMyBanks) {

    return this._http.get(`${environment.apiUrl}/ClientEntities/Get?isMyBanks=`+isMyBanks);

  }
  ExcelToExport() {

    return this._http.get(`${environment.apiUrl}/ClientEntities/ExcelExport`, { responseType: "blob" });

  }
  GetMastersData() {

    return this._http.get(`${environment.apiUrl}/ClientEntities/GetMasterData`);

  }
  UpdateData(obj) {

    return this._http.post(`${environment.apiUrl}/ClientEntities/Update`, obj);

  }
  clientEntityExcelexport(columnnames) {

    return this._http.post(`${environment.apiUrl}/ClientEntities/ExcelExport`, columnnames, { responseType: "blob" });

  }

  SaveData(obj) {

    return this._http.post(`${environment.apiUrl}/ClientEntities/Save`, obj);

  }
  DeleteData(obj) {

    return this._http.post(`${environment.apiUrl}/ClientEntities/Delete`, obj);

  }
  ClientMasterTempleateExport() {

    return this._http.get(`${environment.apiUrl}/ClientEntities/DownloadTemplate`, { responseType: "blob" });

  }

  getAuditTrail() {

    return this._http.get(`${environment.apiUrl}/AuditClientEntities/Get`);

  }





}
