import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditAssignmentService } from '../modules/AuditAssignment/audit-assignment/audit-assignment.service';


@Component({
  selector: 'app-disabled-audit-assignment',
  templateUrl: './disabled-audit-assignment.component.html',
  styleUrls: ['./disabled-audit-assignment.component.scss']
})
export class DisabledAuditAssignmentComponent implements OnInit {

  @Output() BackEventEmitter = new EventEmitter<string>();
////#region form 
form: FormGroup;
public btnflag:boolean=true;
public RoleBasedRegion:any;
public lstYear:any=[];
public lstClassification:any=[];
public lstFormStatus:any=[];
public lstGroupScope:any=[];
public lstContacts:any=[];
public lstAPTeammembers:any=[];
public lstKPTeammembers:any=[];
public lstRPTeammembers:any=[];
public lstOTTeammembers:any=[];
public lstRegAuditLead:any; 
public lstHolidaysMaster:any;
public lstPlanningDates:any;
public lstAudittypeSatus:any;
public lstInternalAuditEngLeder:any;
public AuditAssignmentId :any;
public RegionBasedENabledFlag:boolean=false;
public endDateRPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:{year: 0, month: 0, day: 0}};
public executionEndDateRPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:{year: 0, month: 0, day: 0}};

public endDateAPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:{year: 0, month: 0, day: 0}};
public executionEndDateAPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:{year: 0, month: 0, day: 0}};

public executionEndDateKPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:{year: 0, month: 0, day: 0}};

public endDateOTOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:{year: 0, month: 0, day: 0}};
public executionEndDateOTOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:{year: 0, month: 0, day: 0}};

////#endregion
////#region Entity based details variables
public lstEntitydetails:any; 
public arrayCLientEntity:any=[];
public ngClientEntityID:any;
////#endregion

////#region toaster and Loader variables
public toastflag : boolean=false;
public actionAccess:any=[""];
public toasttype:string;
public toastmessage:string;
////#endregion
@Input() Params;
public editdata:any;
  constructor(private _auditassignmentService:AuditAssignmentService,private _formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.form =this.AuditAssignmentForm();     
    this.GetEntityDetails();
    this.GetYear();
    this.GetMastersData(this.Params);
    this.ValidationsforAuditTypes(); 
    //this.GetRoleBasedRegion();   
    //this.editdetails(this.Params);

  }
  ////#region Form methods
  editdetails(data){  
     
    this.btnflag=false;
    this.AuditAssignmentId=data.auditAssignmentId;
    this.ngClientEntityID=data.clientEnityId;
    this.form.patchValue({       
      audityear:data.auditYear,
      entityname:data.clientEntityName,
      entityshortname:data.clientEntityShortName,
      formtitle:data.formTitle,
      classification:data.classification,
      groupscope:data.groupScope,     
      formstatus:data.formStatus,      
      bankcode:data.bankCodeRgrCid,      
      location:data.locationName,
      canton:data.canton,
      reportinglanguage:data.reportingLanguage,     
      banksize:data.bankSize,
      eyoffice:data.eyOfficeName,
      eyregion:data.eyregionName,
      iroffice:data.irofficeName,
      financialauditleadAP:data.apFinancialAuditLead!=null?data.apFinancialAuditLead:null,
      auditperiodstartdateAP:data.apAuditPeriodStartDate!=null?data.apAuditPeriodStartDate==null||data.apAuditPeriodStartDate=='null'?'':
                                data.apAuditPeriodStartDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.apAuditPeriodStartDate.split('T')[0].split('-')[0]),
                                      month: Number(data.apAuditPeriodStartDate.split('T')[0].split('-')[1]),
                                      day: Number(data.apAuditPeriodStartDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.apAuditPeriodStartDate.split('T')[0]
                                  }
                                }:null,
      auditperiodenddateAP:data.apAuditPeriodEndDate!=null?data.apAuditPeriodEndDate==null||data.apAuditPeriodEndDate=='null'?'':
                              data.apAuditPeriodEndDate.indexOf('-')==-1?'': {
                                isRange: false, singleDate: {
                                  date: {
                                    year: Number(data.apAuditPeriodEndDate.split('T')[0].split('-')[0]),
                                    month: Number(data.apAuditPeriodEndDate.split('T')[0].split('-')[1]),
                                    day: Number(data.apAuditPeriodEndDate.split('T')[0].split('-')[2])
                                  },
                                  formatted:data.apAuditPeriodEndDate.split('T')[0]
                                }
                              }:null,
      regionalleadpartnerAP:data.apRegionalLeadPartnerId!=null?data.apRegionalLeadPartnerId:null,
      assignedleadauditorAP:data.apAssignedLeadAuditorId!=null?data.apAssignedLeadAuditorId:null,      
      auditexecutionstartdateAP:data.apAuditExecutionStartDate!=null?data.apAuditExecutionStartDate==null||data.apAuditType.apAuditExecutionStartDate=='null'?'':
                                data.apAuditExecutionStartDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.apAuditExecutionStartDate.split('T')[0].split('-')[0]),
                                      month: Number(data.apAuditExecutionStartDate.split('T')[0].split('-')[1]),
                                      day: Number(data.apAuditExecutionStartDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.apAuditExecutionStartDate.split('T')[0]
                                  }
                                }:null,
      auditexecutionenddateAP:data.apAuditExecutionEndDate!=null?data.apAuditExecutionEndDate==null||data.apAuditExecutionEndDate=='null'?'':
                                data.apAuditExecutionEndDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.apAuditExecutionEndDate.split('T')[0].split('-')[0]),
                                      month: Number(data.apAuditExecutionEndDate.split('T')[0].split('-')[1]),
                                      day: Number(data.apAuditExecutionEndDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.apAuditExecutionEndDate.split('T')[0]
                                  }
                                }:null,
      idcodeAP:data.apAuditIdcode!=null?data.apAuditIdcode:data.bankCodeRgrCid+"AP"+data.auditYear.toString().slice(2,4),      
      internalauditengagementleaderAP:data.apInternalAuditEngagementLeaderId!=null?data.apInternalAuditEngagementLeaderId:null,
      AuditTypeAP:data.apAuditType!=null?data.apAuditType=="AP"?true:false:null,
      iaworkcompletedAP:data.apIaworkCompleted!=null?data.apIaworkCompleted==null||data.apIaworkCompleted=='null'?'':
      data.apIaworkCompleted.indexOf('-')==-1?'': {
        isRange: false, singleDate: {
          date: {
            year: Number(data.apIaworkCompleted.split('T')[0].split('-')[0]),
            month: Number(data.apIaworkCompleted.split('T')[0].split('-')[1]),
            day: Number(data.apIaworkCompleted.split('T')[0].split('-')[2])
          },
          formatted:data.apIaworkCompleted.split('T')[0]
        }
      }:null,
      announcementdeliveryAP:data.apAnnouncementDelivery==null?null:data.apAnnouncementDelivery.split('T')[0].split('-')[2]+'.'+data.apAnnouncementDelivery.split('T')[0].split('-')[1]+'.'+data.apAnnouncementDelivery.split('T')[0].split('-')[0],
      clientdatadueAP:data.apClientDataDue==null?null:data.apClientDataDue.split('T')[0].split('-')[2]+'.'+data.apClientDataDue.split('T')[0].split('-')[1]+'.'+data.apClientDataDue.split('T')[0].split('-')[0],
      draftreportAP:data.apDraftReport==null?null:data.apDraftReport.split('T')[0].split('-')[2]+'.'+data.apDraftReport.split('T')[0].split('-')[1]+'.'+data.apDraftReport.split('T')[0].split('-')[0],
      iaworkpaperreviewdueAP:data.apIaworkPaperReviewDue==null?null:data.apIaworkPaperReviewDue.split('T')[0].split('-')[2]+'.'+data.apIaworkPaperReviewDue.split('T')[0].split('-')[1]+'.'+data.apIaworkPaperReviewDue.split('T')[0].split('-')[0],
      draftreporttoclientdueAP:data.apDraftReportToClientDue==null?null:data.apDraftReportToClientDue.split('T')[0].split('-')[2]+'.'+data.apDraftReportToClientDue.split('T')[0].split('-')[1]+'.'+data.apDraftReportToClientDue.split('T')[0].split('-')[0],
      clientfeedbackdueAP:data.apClientFeedbackDue==null?null:data.apClientFeedbackDue.split('T')[0].split('-')[2]+'.'+data.apClientFeedbackDue.split('T')[0].split('-')[1]+'.'+data.apClientFeedbackDue.split('T')[0].split('-')[0],
      finalreporttoclientdueAP:data.apFinalReportToClientDue==null?null:data.apFinalReportToClientDue.split('T')[0].split('-')[2]+'.'+data.apFinalReportToClientDue.split('T')[0].split('-')[1]+'.'+data.apFinalReportToClientDue.split('T')[0].split('-')[0],
      statusidAP:data.aPstatus==null?null:data.aPstatus.statusid,
     
      financialauditleadKP:data.kpFinancialAuditLead!=null?data.kpFinancialAuditLead:null,
      assignedleadauditorKP:data.kpAssignedLeadAuditorId!=null?data.kpAssignedLeadAuditorId:null,      
      auditexecutionstartdateKP:data.kpAuditExecutionStartDate!=null?data.kpAuditExecutionStartDate==null||data.kpAuditExecutionStartDate=='null'?'':
                                data.kpAuditExecutionStartDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.kpAuditExecutionStartDate.split('T')[0].split('-')[0]),
                                      month: Number(data.kpAuditExecutionStartDate.split('T')[0].split('-')[1]),
                                      day: Number(data.kpAuditExecutionStartDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.kpAuditExecutionStartDate.split('T')[0]
                                  }
                                }:null,
      auditexecutionenddateKP:data.kpAuditExecutionEndDate!=null?data.kpAuditExecutionEndDate==null||data.kpAuditExecutionEndDate=='null'?'':
                                data.kpAuditExecutionEndDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.kpAuditExecutionEndDate.split('T')[0].split('-')[0]),
                                      month: Number(data.kpAuditExecutionEndDate.split('T')[0].split('-')[1]),
                                      day: Number(data.kpAuditExecutionEndDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.kpAuditExecutionEndDate.split('T')[0]
                                  }
                                }:null,
      idcodeKP:data.kpAuditIdcode!=null?data.kpAuditIdcode:data.bankCodeRgrCid+"KP"+data.auditYear.toString().slice(2,4),
      AuditTypeKP:data.kpAuditType!=null?data.kpAuditType=="KP"?true:false:null,
      
iaworkcompletedKP:data.kpIaworkCompleted!=null?data.kpIaworkCompleted==null||data.kpIaworkCompleted=='null'?'':
data.kpIaworkCompleted.indexOf('-')==-1?'': {
  isRange: false, singleDate: {
    date: {
      year: Number(data.kpIaworkCompleted.split('T')[0].split('-')[0]),
      month: Number(data.kpIaworkCompleted.split('T')[0].split('-')[1]),
      day: Number(data.kpIaworkCompleted.split('T')[0].split('-')[2])
    },
    formatted:data.kpIaworkCompleted.split('T')[0]
  }
}:null,
announcementdeliveryKP:data.kpAnnouncementDelivery==null?null:data.kpAnnouncementDelivery.split('T')[0].split('-')[2]+'.'+data.kpAnnouncementDelivery.split('T')[0].split('-')[1]+'.'+data.kpAnnouncementDelivery.split('T')[0].split('-')[0],
clientdatadueKP:data.kpClientDataDue==null?null:data.kpClientDataDue.split('T')[0].split('-')[2]+'.'+data.kpClientDataDue.split('T')[0].split('-')[1]+'.'+data.kpClientDataDue.split('T')[0].split('-')[0],
draftreportKP:data.kpDraftReport==null?null:data.kpDraftReport.split('T')[0].split('-')[2]+'.'+data.kpDraftReport.split('T')[0].split('-')[1]+'.'+data.kpDraftReport.split('T')[0].split('-')[0],
iaworkpaperreviewdueKP:data.kpIaworkPaperReviewDue==null?null:data.kpIaworkPaperReviewDue.split('T')[0].split('-')[2]+'.'+data.kpIaworkPaperReviewDue.split('T')[0].split('-')[1]+'.'+data.kpIaworkPaperReviewDue.split('T')[0].split('-')[0],
draftreporttoclientdueKP:data.kpDraftReportToClientDue==null?null:data.kpDraftReportToClientDue.split('T')[0].split('-')[2]+'.'+data.kpDraftReportToClientDue.split('T')[0].split('-')[1]+'.'+data.kpDraftReportToClientDue.split('T')[0].split('-')[0],
clientfeedbackdueKP:data.kpClientFeedbackDue==null?null:data.kpClientFeedbackDue.split('T')[0].split('-')[2]+'.'+data.kpClientFeedbackDue.split('T')[0].split('-')[1]+'.'+data.kpClientFeedbackDue.split('T')[0].split('-')[0],
finalreporttoclientdueKP:data.kpFinalReportToClientDue==null?null:data.kpFinalReportToClientDue.split('T')[0].split('-')[2]+'.'+data.kpFinalReportToClientDue.split('T')[0].split('-')[1]+'.'+data.kpFinalReportToClientDue.split('T')[0].split('-')[0],
statusidKP:data.kPstatus==null?null:data.kPstatus.statusid,
      financialauditleadRP:data.rpFinancialAuditLead!=null?data.rpFinancialAuditLead:null,
      auditperiodstartdateRP:data.rpAuditPeriodStartDate!=null?data.rpAuditPeriodStartDate==null||data.rpAuditPeriodStartDate=='null'?'':
                                data.rpAuditPeriodStartDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.rpAuditPeriodStartDate.split('T')[0].split('-')[0]),
                                      month: Number(data.rpAuditPeriodStartDate.split('T')[0].split('-')[1]),
                                      day: Number(data.rpAuditPeriodStartDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.rpAuditPeriodStartDate.split('T')[0]
                                  }
                                }:null,
      auditperiodenddateRP:data.rpAuditPeriodEndDate!=null?data.rpAuditPeriodEndDate==null||data.rpAuditPeriodEndDate=='null'?'':
                              data.rpAuditPeriodEndDate.indexOf('-')==-1?'': {
                                isRange: false, singleDate: {
                                  date: {
                                    year: Number(data.rpAuditPeriodEndDate.split('T')[0].split('-')[0]),
                                    month: Number(data.rpAuditPeriodEndDate.split('T')[0].split('-')[1]),
                                    day: Number(data.rpAuditPeriodEndDate.split('T')[0].split('-')[2])
                                  },
                                  formatted:data.rpAuditPeriodEndDate.split('T')[0]
                                }
                              }:null,
      regionalleadpartnerRP:data.rpRegionalLeadPartnerId!=null?data.rpRegionalLeadPartnerId:null,
      assignedleadauditorRP:data.rpAssignedLeadAuditorId!=null?data.rpAssignedLeadAuditorId:null,      
      auditexecutionstartdateRP:data.rpAuditExecutionStartDate!=null?data.rpAuditExecutionStartDate==null||data.rpAuditExecutionStartDate=='null'?'':
                                data.rpAuditExecutionStartDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.rpAuditExecutionStartDate.split('T')[0].split('-')[0]),
                                      month: Number(data.rpAuditExecutionStartDate.split('T')[0].split('-')[1]),
                                      day: Number(data.rpAuditExecutionStartDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.rpAuditExecutionStartDate.split('T')[0]
                                  }
                                }:null,
      auditexecutionenddateRP:data.rpAuditExecutionEndDate!=null?data.rpAuditExecutionEndDate==null||data.rpAuditExecutionEndDate=='null'?'':
                                data.rpAuditExecutionEndDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.rpAuditExecutionEndDate.split('T')[0].split('-')[0]),
                                      month: Number(data.rpAuditExecutionEndDate.split('T')[0].split('-')[1]),
                                      day: Number(data.rpAuditExecutionEndDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.rpAuditExecutionEndDate.split('T')[0]
                                  }
                                }:null,
      idcodeRP:data.rpAuditIdcode!=null?data.rpAuditIdcode:data.bankCodeRgrCid+"RP"+data.auditYear.toString().slice(2,4),
      AuditTypeRP:data.rpAuditType!=null?data.rpAuditType=="RP"?true:false:null,
      internalauditengagementleaderRP:data.rpInternalAuditEngagementLeaderId!=null?data.rpInternalAuditEngagementLeaderId:null,
iaworkcompletedRP:data.rpIaworkCompleted!=null?data.rpIaworkCompleted==null||data.rpIaworkCompleted=='null'?'':
data.rpIaworkCompleted.indexOf('-')==-1?'': {
  isRange: false, singleDate: {
    date: {
      year: Number(data.rpIaworkCompleted.split('T')[0].split('-')[0]),
      month: Number(data.rpIaworkCompleted.split('T')[0].split('-')[1]),
      day: Number(data.rpIaworkCompleted.split('T')[0].split('-')[2])
    },
    formatted:data.rpIaworkCompleted.split('T')[0]
  }
}:null,
announcementdeliveryRP:data.rpAnnouncementDelivery==null?null:data.rpAnnouncementDelivery.split('T')[0].split('-')[2]+'.'+data.rpAnnouncementDelivery.split('T')[0].split('-')[1]+'.'+data.rpAnnouncementDelivery.split('T')[0].split('-')[0],
clientdatadueRP:data.rpClientDataDue==null?null:data.rpClientDataDue.split('T')[0].split('-')[2]+'.'+data.rpClientDataDue.split('T')[0].split('-')[1]+'.'+data.rpClientDataDue.split('T')[0].split('-')[0],
draftreportRP:data.rpDraftReport==null?null:data.rpDraftReport.split('T')[0].split('-')[2]+'.'+data.rpDraftReport.split('T')[0].split('-')[1]+'.'+data.rpDraftReport.split('T')[0].split('-')[0],
iaworkpaperreviewdueRP:data.rpIaworkPaperReviewDue==null?null:data.rpIaworkPaperReviewDue.split('T')[0].split('-')[2]+'.'+data.rpIaworkPaperReviewDue.split('T')[0].split('-')[1]+'.'+data.rpIaworkPaperReviewDue.split('T')[0].split('-')[0],
draftreporttoclientdueRP:data.rpDraftReportToClientDue==null?null:data.rpDraftReportToClientDue.split('T')[0].split('-')[2]+'.'+data.rpDraftReportToClientDue.split('T')[0].split('-')[1]+'.'+data.rpDraftReportToClientDue.split('T')[0].split('-')[0],
clientfeedbackdueRP:data.rpClientFeedbackDue==null?null:data.rpClientFeedbackDue.split('T')[0].split('-')[2]+'.'+data.rpClientFeedbackDue.split('T')[0].split('-')[1]+'.'+data.rpClientFeedbackDue.split('T')[0].split('-')[0],
finalreporttoclientdueRP:data.rpFinalReportToClientDue==null?null:data.rpFinalReportToClientDue.split('T')[0].split('-')[2]+'.'+data.rpFinalReportToClientDue.split('T')[0].split('-')[1]+'.'+data.rpFinalReportToClientDue.split('T')[0].split('-')[0],
statusidRP:data.rPstatus==null?null:data.rPstatus.statusid,
      financialauditleadOther:data.otFinancialAuditLead!=null?data.otFinancialAuditLead:null,
      auditperiodstartdateOther:data.otAuditPeriodStartDate!=null?data.otAuditPeriodStartDate==null||data.otAuditPeriodStartDate=='null'?'':
                                data.otAuditPeriodStartDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.otAuditPeriodStartDate.split('T')[0].split('-')[0]),
                                      month: Number(data.otAuditPeriodStartDate.split('T')[0].split('-')[1]),
                                      day: Number(data.otAuditPeriodStartDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.otAuditPeriodStartDate.split('T')[0]
                                  }
                                }:null,
      auditperiodenddateOther:data.otAuditPeriodEndDate!=null?data.otAuditPeriodEndDate==null||data.otAuditPeriodEndDate=='null'?'':
                              data.otAuditPeriodEndDate.indexOf('-')==-1?'': {
                                isRange: false, singleDate: {
                                  date: {
                                    year: Number(data.otAuditPeriodEndDate.split('T')[0].split('-')[0]),
                                    month: Number(data.otAuditPeriodEndDate.split('T')[0].split('-')[1]),
                                    day: Number(data.otAuditPeriodEndDate.split('T')[0].split('-')[2])
                                  },
                                  formatted:data.otAuditPeriodEndDate.split('T')[0]
                                }
                              }:null,
      regionalleadpartnerOther:data.otRegionalLeadPartnerId!=null?data.otRegionalLeadPartnerId:null,
      assignedleadauditorOther:data.otAssignedLeadAuditorId!=null?data.otAssignedLeadAuditorId:null,      
      auditexecutionstartdateOther:data.otAuditExecutionStartDate!=null?data.otAuditExecutionStartDate==null||data.otAuditExecutionStartDate=='null'?'':
                                data.otAuditExecutionStartDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.otAuditExecutionStartDate.split('T')[0].split('-')[0]),
                                      month: Number(data.otAuditExecutionStartDate.split('T')[0].split('-')[1]),
                                      day: Number(data.otAuditExecutionStartDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.otAuditExecutionStartDate.split('T')[0]
                                  }
                                }:null,
      auditexecutionenddateOther:data.otAuditExecutionEndDate!=null?data.otAuditExecutionEndDate==null||data.otAuditExecutionEndDate=='null'?'':
                                data.otAuditExecutionEndDate.indexOf('-')==-1?'': {
                                  isRange: false, singleDate: {
                                    date: {
                                      year: Number(data.otAuditExecutionEndDate.split('T')[0].split('-')[0]),
                                      month: Number(data.otAuditExecutionEndDate.split('T')[0].split('-')[1]),
                                      day: Number(data.otAuditExecutionEndDate.split('T')[0].split('-')[2])
                                    },
                                    formatted:data.otAuditExecutionEndDate.split('T')[0]
                                  }
                                }:null,
      idcodeOther:data.otAuditIdcode!=null?data.otAuditIdcode:data.bankCodeRgrCid+"OT"+data.auditYear.toString().slice(2,4),
      AuditTypeOther:data.otAuditType!=null?data.otAuditType=="OT"?true:false:null,
     
iaworkcompletedOT:data.otIaworkCompleted!=null?data.otIaworkCompleted==null||data.otIaworkCompleted=='null'?'':
data.otIaworkCompleted.indexOf('-')==-1?'': {
  isRange: false, singleDate: {
    date: {
      year: Number(data.otIaworkCompleted.split('T')[0].split('-')[0]),
      month: Number(data.otIaworkCompleted.split('T')[0].split('-')[1]),
      day: Number(data.otIaworkCompleted.split('T')[0].split('-')[2])
    },
    formatted:data.otIaworkCompleted.split('T')[0]
  }
}:null,
announcementdeliveryOT:data.otAnnouncementDelivery==null?null:data.otAnnouncementDelivery.split('T')[0].split('-')[2]+'.'+data.otAnnouncementDelivery.split('T')[0].split('-')[1]+'.'+data.otAnnouncementDelivery.split('T')[0].split('-')[0],
clientdatadueOT:data.otClientDataDue==null?null:data.otClientDataDue.split('T')[0].split('-')[2]+'.'+data.otClientDataDue.split('T')[0].split('-')[1]+'.'+data.otClientDataDue.split('T')[0].split('-')[0],
draftreportOT:data.otDraftReport==null?null:data.otDraftReport.split('T')[0].split('-')[2]+'.'+data.otDraftReport.split('T')[0].split('-')[1]+'.'+data.otDraftReport.split('T')[0].split('-')[0],
iaworotaperreviewdueOT:data.otIaworkPaperReviewDue==null?null:data.otIaworkPaperReviewDue.split('T')[0].split('-')[2]+'.'+data.otIaworkPaperReviewDue.split('T')[0].split('-')[1]+'.'+data.otIaworkPaperReviewDue.split('T')[0].split('-')[0],
draftreporttoclientdueOT:data.otDraftReportToClientDue==null?null:data.otDraftReportToClientDue.split('T')[0].split('-')[2]+'.'+data.otDraftReportToClientDue.split('T')[0].split('-')[1]+'.'+data.otDraftReportToClientDue.split('T')[0].split('-')[0],
clientfeedbackdueOT:data.otClientFeedbackDue==null?null:data.otClientFeedbackDue.split('T')[0].split('-')[2]+'.'+data.otClientFeedbackDue.split('T')[0].split('-')[1]+'.'+data.otClientFeedbackDue.split('T')[0].split('-')[0],
finalreporttoclientdueOT:data.otFinalReportToClientDue==null?null:data.otFinalReportToClientDue.split('T')[0].split('-')[2]+'.'+data.otFinalReportToClientDue.split('T')[0].split('-')[1]+'.'+data.otFinalReportToClientDue.split('T')[0].split('-')[0],
statusidOT:data.oTstatus==null?null:data.oTstatus.statusid,
      Comment:data.comment
      
      
   });
  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {        
        const control = form.get(field);
        if (control instanceof FormControl) {                        
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {            
          this.validateForm(control);
        }
    });      
}
  private AuditAssignmentForm()
   {
    return this._formBuilder.group({
      audityear: [null, [Validators.required]],
      entityname: [null, [Validators.required]],
      formtitle:[],
      classification:[null, [Validators.required]],
      groupscope:[null, [Validators.required]],
      clientcontacts:[],
      formstatus:[null, [Validators.required]],
      entityshortname:[],
      bankcode:[],      
      location:[],
      canton:[],
      reportinglanguage:[],      
      banksize:[],
      eyoffice:[],
      eyregion:[],
      iroffice:[],
      financialauditleadAP:[],
      auditperiodstartdateAP:[],
      auditperiodenddateAP:[],
      regionalleadpartnerAP:[],
      assignedleadauditorAP:[],
     // assignedauditteammembersAP:[],
      auditexecutionstartdateAP:[],
      auditexecutionenddateAP:[],
      idcodeAP:[],
      iaworkcompletedAP:[],
      announcementdeliveryAP:[],
      clientdatadueAP:[],
      draftreportAP:[],
      iaworkpaperreviewdueAP:[],
      draftreporttoclientdueAP:[],
      clientfeedbackdueAP:[],
      finalreporttoclientdueAP:[],
      internalauditengagementleaderAP:[],
      statusidAP:[],
      financialauditleadRP:[],
      auditperiodstartdateRP:[],
      auditperiodenddateRP:[],
      regionalleadpartnerRP:[],
      assignedleadauditorRP:[],     
      auditexecutionstartdateRP:[],
      auditexecutionenddateRP:[],
      internalauditengagementleaderRP:[],
      idcodeRP:[],
      iaworkcompletedRP:[],
      announcementdeliveryRP:[],
      clientdatadueRP:[],
      draftreportRP:[],
      iaworkpaperreviewdueRP:[],
      draftreporttoclientdueRP:[],
      clientfeedbackdueRP:[],
      finalreporttoclientdueRP:[],
      statusidRP:[],
      financialauditleadKP:[],     
      assignedleadauditorKP:[],
     // assignedauditteammembersKP:[],
      auditexecutionstartdateKP:[],
      auditexecutionenddateKP:[],
      idcodeKP:[],
      iaworkcompletedKP:[],
      announcementdeliveryKP:[],
      clientdatadueKP:[],
      draftreportKP:[],
      iaworkpaperreviewdueKP:[],
      draftreporttoclientdueKP:[],
      clientfeedbackdueKP:[],
      finalreporttoclientdueKP:[],
      statusidKP:[],
      AuditTypeRP:[],
      AuditTypeAP:[],
      AuditTypeKP:[],
      AuditTypeOther:[],
      financialauditleadOther:[],
      auditperiodstartdateOther:[],
      auditperiodenddateOther:[],
      regionalleadpartnerOther:[],
      assignedleadauditorOther:[],
    //  assignedauditteammembersOther:[],
      auditexecutionstartdateOther:[],
      auditexecutionenddateOther:[],
      idcodeOther:[],
      iaworkcompletedOT:[],
      announcementdeliveryOT:[],
      clientdatadueOT:[],
      draftreportOT:[],
      iaworkpaperreviewdueOT:[],
      draftreporttoclientdueOT:[],
      clientfeedbackdueOT:[],
      finalreporttoclientdueOT:[],
      statusidOT:[],
      
      Comment:[]
    });

}
////#endregion

 ////#region  Entity based details methods
 GetEntityDetails()
 {
 //this.toastflag=false;
   
   this._auditassignmentService.GetEntityDetails()
   .subscribe(
     res => {
       
      
      this.lstEntitydetails=res;
     this.lstEntitydetails.forEach(element => {
       this.arrayCLientEntity.push(element.clientEntityName);
      });
     //this.SeachEntityFlag=true;
     },
     err => {
       
       console.log(err);
     }
   )
   }
   FillEntityBaseDetails(){
     
     let data = this.lstEntitydetails.find(ob => ob.clientEntityName === this.form.value.entityname);
       if(data !==undefined){       
       this.form.patchValue({ 
        entityshortname:data.rbShortName,
        bankcode:data.bankCodeRgrCid,
        clientId:data.mercuryEntityId,       
        location:data.locationName,
        canton:data.cantonName,
        reportinglanguage:data.reportingLanguage,
        documentationlanguage:data.documentationLanguage,
        banksize:data.bankSize,
        eyoffice:data.eyOfficeName,
        eyregion:data.eyregionName,
        iroffice:data.irofficeName
        
     });
    
       }else{
         this.ngClientEntityID=0;
       this.form.patchValue({ 
        entityname:"",
        EYCID:"",
        bankcode:"",
        clientId:"",       
        location:"",
        canton:"",
        reportinglanguage:"",
        documentationlanguage:"",
        banksize:"",
        eyoffice:"",
        eyregion:"",
        iroffice:""   
     });

       }
       
       this.ngClientEntityID=data.clientEntityId;
       
       if(this.RoleBasedRegion[0]["regions"].toString().indexOf(data.eyregionId)!=-1){
         this.RegionBasedENabledFlag=true;
       }
       else
          this.RegionBasedENabledFlag=false;
       this.GenerateFormTitle();
   }
GenerateFormTitle(){

   if(this.form.value.entityname!=null &&this.form.value.entityname!="" &&this.form.value.audityear!=null){
    this.form.patchValue({ 
      formtitle:this.form.value.audityear +' | '+ this.form.value.entityshortname,
      idcodeAP:this.form.value.bankcode+"AP"+ this.form.value.audityear.toString().slice(2,4),
      idcodeRP:this.form.value.bankcode+"RP"+ this.form.value.audityear.toString().slice(2,4),
      idcodeKP:this.form.value.bankcode+"KP"+ this.form.value.audityear.toString().slice(2,4),
      idcodeOther:this.form.value.bankcode+"OT"+ this.form.value.audityear.toString().slice(2,4)
  });
  }else{
    this.form.patchValue({ 
      formtitle:"",
      idcodeAP:"",
      idcodeRP:"",
      idcodeKP:"",
      idcodeOther:""
  });
  }
  this.form.patchValue({ 
  auditexecutionstartdateRP:null ,
    auditexecutionenddateRP:null  ,
    iaworkcompletedRP:null
  });
    
}
GetYear(){
  var year = new Date().getFullYear();
    year =2021;
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
      this.lstYear.push(year + i);
    }
}
GetMastersData(data){
      
  this._auditassignmentService.GetMastersData()
  .subscribe(
    res => {
       
      this.lstClassification=res[0];
      this.lstGroupScope=res[1];
      this.lstFormStatus=res[2];
      this.lstContacts=res[3];
      this.lstKPTeammembers=res[4];
      this.lstAPTeammembers=res[5];
      this.lstRPTeammembers=res[6];
      this.lstOTTeammembers=res[7];
      this.lstRegAuditLead=res[8];
      this.lstHolidaysMaster=res[9];
      this.lstPlanningDates=res[10];
      this.lstAudittypeSatus=res[11];
      this.lstInternalAuditEngLeder=res[12];
      this.RoleBasedRegion=res[13];
      if(data!=null){
        if(data.length==undefined){
     let clientcontacts= data.clientContactIds.split(',').map(x=>+x);
     this.lstContacts.forEach(element => {        
      element["isChecked"]=clientcontacts.indexOf(element.contactId) > -1?true:false;        
    });
    if(this.RoleBasedRegion[0]["regions"]!=null){
          if(this.RoleBasedRegion[0]["regions"].toString().indexOf(data.eyregionId)!=-1){
            this.RegionBasedENabledFlag=true;
          }
    }
    else
       this.RegionBasedENabledFlag=false;
    //  if(data.kpAssignedAuditTeamMembersIds!=null){
    //  let KPteammemers= data.kpAssignedAuditTeamMembersIds.split(',').map(x=>+x);
    //  this.lstKPTeammembers.forEach(element => {        
    //   element["isChecked"]=KPteammemers.indexOf(element.contactId) > -1?true:false;        
    // });
    //  }
    //  if(data.apAssignedAuditTeamMembersIds!=null){
    //  let APteammemers= data.apAssignedAuditTeamMembersIds.split(',').map(x=>+x);
    //  this.lstAPTeammembers.forEach(element => {        
    //   element["isChecked"]=APteammemers.indexOf(element.contactId) > -1?true:false;        
    // });
    //  }
    //  if(data.rpAssignedAuditTeamMembersIds!=null){
    //  let RPteammemers= data.rpAssignedAuditTeamMembersIds.split(',').map(x=>+x);
    //  this.lstRPTeammembers.forEach(element => {        
    //   element["isChecked"]=RPteammemers.indexOf(element.contactId) > -1?true:false;        
    // });
    //  }
    //  if(data.otAssignedAuditTeamMembersIds!=null){
    //  let OTteammemers= data.otAssignedAuditTeamMembersIds.split(',').map(x=>+x);
    //  this.lstOTTeammembers.forEach(element => {        
    //   element["isChecked"]=OTteammemers.indexOf(element.contactId) > -1?true:false;        
    // });
    //  }
     
      
     
      
    
     
      
      this.editdetails(data);
    }
    }
    },
    err => {
      
      console.log(err);
    }
  )

}

UpdateAuditAssignment() {
  //this.toastflag=false;
  
  this.validateForm(this.form);
  if(!this.form.valid)
  return  
  if(!this.form.value.AuditTypeRP && !this.form.value.AuditTypeAP && !this.form.value.AuditTypeOther && !this.form.value.AuditTypeKP)
  return
     let lstAudittype:any=[]; 

     if(this.form.value.AuditTypeAP){

      var APobj={    
        AuditType :"AP",
         FinancialAuditLead:this.form.value.financialauditleadAP,
        RegionalLeadPartnerId :this.form.value.regionalleadpartnerAP,
         AssignedLeadAuditorId :this.form.value.assignedleadauditorAP,
         //sorting Assigned Audit Team members in asc order while save
         //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersAP.sort((a, b) => a - b).map(x=>x).join(","),
         AuditPeriodStartDate:this.form.value.auditperiodstartdateAP==null?null:this.form.value.auditperiodstartdateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted): new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[2]),
         AuditPeriodEndDate :this.form.value.auditperiodenddateAP==null?null:this.form.value.auditperiodenddateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodenddateAP.singleDate.formatted): new Date(this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[2]),
        
         AuditExecutionStartDate: this.form.value.auditexecutionstartdateAP==null?null:this.form.value.auditexecutionstartdateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[2]),
         AuditExecutionEndDate: this.form.value.auditexecutionenddateAP==null?null:this.form.value.auditexecutionenddateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted): new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[2]),
        AuditIdcode:this.form.value.idcodeAP,
       
     IaworkCompleted :this.form.value.iaworkcompletedAP=="" ||this.form.value.iaworkcompletedAP==null?null:new Date(this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[2]),
     AnnouncementDelivery  :this.form.value.announcementdeliveryAP=="" || this.form.value.announcementdeliveryAP==null?null:new Date(this.form.value.announcementdeliveryAP.split('.')[1]+'-'+this.form.value.announcementdeliveryAP.split('.')[0]+'-'+this.form.value.announcementdeliveryAP.split('.')[2]),
     ClientDataDue  :this.form.value.clientdatadueAP=="" || this.form.value.clientdatadueAP==null?null:new Date(this.form.value.clientdatadueAP.split('.')[1]+'-'+this.form.value.clientdatadueAP.split('.')[0]+'-'+this.form.value.clientdatadueAP.split('.')[2]),
    DraftReport :this.form.value.draftreportAP=="" || this.form.value.draftreportAP==null?null:new Date(this.form.value.draftreportAP.split('.')[1]+'-'+this.form.value.draftreportAP.split('.')[0]+'-'+this.form.value.draftreportAP.split('.')[2]),
    IaworkPaperReviewDue :this.form.value.iaworkpaperreviewdueAP=="" || this.form.value.iaworkpaperreviewdueAP==null?null:new Date(this.form.value.iaworkpaperreviewdueAP.split('.')[1]+'-'+this.form.value.iaworkpaperreviewdueAP.split('.')[0]+'-'+this.form.value.iaworkpaperreviewdueAP.split('.')[2]),
    DraftReportToClientDue :this.form.value.draftreporttoclientdueAP=="" || this.form.value.draftreporttoclientdueAP==null?null:new Date(this.form.value.draftreporttoclientdueAP.split('.')[1]+'-'+this.form.value.draftreporttoclientdueAP.split('.')[0]+'-'+this.form.value.draftreporttoclientdueAP.split('.')[2]),
    ClientFeedbackDue :this.form.value.clientfeedbackdueAP=="" || this.form.value.clientfeedbackdueAP==null?null:new Date(this.form.value.clientfeedbackdueAP.split('.')[1]+'-'+this.form.value.clientfeedbackdueAP.split('.')[0]+'-'+this.form.value.clientfeedbackdueAP.split('.')[2]),
    FinalReportToClientDue :this.form.value.finalreporttoclientdueAP=="" || this.form.value.finalreporttoclientdueAP==null?null:new Date(this.form.value.finalreporttoclientdueAP.split('.')[1]+'-'+this.form.value.finalreporttoclientdueAP.split('.')[0]+'-'+this.form.value.finalreporttoclientdueAP.split('.')[2]),
    InternalAuditEngagementLeaderId:this.form.value.internalauditengagementleaderAP,
    StatusId :this.form.value.statusidAP
      
      }
        lstAudittype.push(APobj);
    }
        if(this.form.value.AuditTypeRP){
          
            var RPobj={    
              AuditType :"RP",  
              FinancialAuditLead:this.form.value.financialauditleadRP,
              RegionalLeadPartnerId :this.form.value.regionalleadpartnerRP,
              AssignedLeadAuditorId :this.form.value.assignedleadauditorRP,
              //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.map(x=>x).join(","),
              //sorting Assigned Audit Team members in asc order while save
             // AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.sort((a, b) => a - b).map(x=>x).join(","),
              AuditIdcode:this.form.value.idcodeRP,
              AuditPeriodStartDate:this.form.value.auditperiodstartdateRP==null?null:this.form.value.auditperiodstartdateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted): new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[2]),
              AuditPeriodEndDate :this.form.value.auditperiodenddateRP==null?null:this.form.value.auditperiodenddateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodenddateRP.singleDate.formatted): new Date(this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[2]),
             
              AuditExecutionStartDate: this.form.value.auditexecutionstartdateRP==null?null:this.form.value.auditexecutionstartdateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[2]),
              AuditExecutionEndDate: this.form.value.auditexecutionenddateRP==null?null:this.form.value.auditexecutionenddateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted): new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[2]), 
              IaworkCompleted :this.form.value.iaworkcompletedRP=="" ||this.form.value.iaworkcompletedRP==null?null:new Date(this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[2]),
              AnnouncementDelivery  :this.form.value.announcementdeliveryRP=="" || this.form.value.announcementdeliveryRP==null?null:new Date(this.form.value.announcementdeliveryRP.split('.')[1]+'-'+this.form.value.announcementdeliveryRP.split('.')[0]+'-'+this.form.value.announcementdeliveryRP.split('.')[2]),
              ClientDataDue  :this.form.value.clientdatadueRP=="" || this.form.value.clientdatadueRP==null?null:new Date(this.form.value.clientdatadueRP.split('.')[1]+'-'+this.form.value.clientdatadueRP.split('.')[0]+'-'+this.form.value.clientdatadueRP.split('.')[2]),
             DraftReport :this.form.value.draftreportRP=="" || this.form.value.draftreportRP==null?null:new Date(this.form.value.draftreportRP.split('.')[1]+'-'+this.form.value.draftreportRP.split('.')[0]+'-'+this.form.value.draftreportRP.split('.')[2]),
             IaworkPRPerReviewDue :this.form.value.iaworkpRPerreviewdueRP=="" || this.form.value.iaworkpRPerreviewdueRP==null?null:new Date(this.form.value.iaworkpRPerreviewdueRP.split('.')[1]+'-'+this.form.value.iaworkpRPerreviewdueRP.split('.')[0]+'-'+this.form.value.iaworkpRPerreviewdueRP.split('.')[2]),
             DraftReportToClientDue :this.form.value.draftreporttoclientdueRP=="" || this.form.value.draftreporttoclientdueRP==null?null:new Date(this.form.value.draftreporttoclientdueRP.split('.')[1]+'-'+this.form.value.draftreporttoclientdueRP.split('.')[0]+'-'+this.form.value.draftreporttoclientdueRP.split('.')[2]),
             ClientFeedbackDue :this.form.value.clientfeedbackdueRP=="" || this.form.value.clientfeedbackdueRP==null?null:new Date(this.form.value.clientfeedbackdueRP.split('.')[1]+'-'+this.form.value.clientfeedbackdueRP.split('.')[0]+'-'+this.form.value.clientfeedbackdueRP.split('.')[2]),
             FinalReportToClientDue :this.form.value.finalreporttoclientdueRP=="" || this.form.value.finalreporttoclientdueRP==null?null:new Date(this.form.value.finalreporttoclientdueRP.split('.')[1]+'-'+this.form.value.finalreporttoclientdueRP.split('.')[0]+'-'+this.form.value.finalreporttoclientdueRP.split('.')[2]),
             
             InternalAuditEngagementLeaderId:this.form.value.internalauditengagementleaderRP,
               StatusId :this.form.value.statusidRP
            
            }
                lstAudittype.push(RPobj);
  }
        if(this.form.value.AuditTypeKP){
          var KPobj={    
            AuditType :"KP",
             FinancialAuditLead:this.form.value.financialauditleadKP,
            AuditPeriodStartDate:null,
            AuditPeriodEndDate :null,
            RegionalLeadPartnerId :null,
             AssignedLeadAuditorId :this.form.value.assignedleadauditorKP,
             //sorting Assigned Audit Team members in asc order while save
           //  AssignedAuditTeamMembers :this.form.value.assignedauditteammembersKP.sort((a, b) => a - b).map(x=>x).join(","),
          
           
          
           AuditExecutionStartDate: this.form.value.auditexecutionstartdateKP==null?null:this.form.value.auditexecutionstartdateKP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[2]),
           AuditExecutionEndDate: this.form.value.auditexecutionenddateKP==null?null:this.form.value.auditexecutionenddateKP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted): new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[2]),
           AuditIdcode:this.form.value.idcodeKP,
            
            IaworkCompleted :this.form.value.iaworkcompletedKP=="" ||this.form.value.iaworkcompletedKP==null?null:new Date(this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[2]),
            AnnouncementDelivery  :this.form.value.announcementdeliveryKP=="" || this.form.value.announcementdeliveryKP==null?null:new Date(this.form.value.announcementdeliveryKP.split('.')[1]+'-'+this.form.value.announcementdeliveryKP.split('.')[0]+'-'+this.form.value.announcementdeliveryKP.split('.')[2]),
            ClientDataDue  :this.form.value.clientdatadueKP=="" || this.form.value.clientdatadueKP==null?null:new Date(this.form.value.clientdatadueKP.split('.')[1]+'-'+this.form.value.clientdatadueKP.split('.')[0]+'-'+this.form.value.clientdatadueKP.split('.')[2]),
           DraftReport :this.form.value.draftreportKP=="" || this.form.value.draftreportKP==null?null:new Date(this.form.value.draftreportKP.split('.')[1]+'-'+this.form.value.draftreportKP.split('.')[0]+'-'+this.form.value.draftreportKP.split('.')[2]),
           IaworkPKPerReviewDue :this.form.value.iaworkpKPerreviewdueKP=="" || this.form.value.iaworkpKPerreviewdueKP==null?null:new Date(this.form.value.iaworkpKPerreviewdueKP.split('.')[1]+'-'+this.form.value.iaworkpKPerreviewdueKP.split('.')[0]+'-'+this.form.value.iaworkpKPerreviewdueKP.split('.')[2]),
           DraftReportToClientDue :this.form.value.draftreporttoclientdueKP=="" || this.form.value.draftreporttoclientdueKP==null?null:new Date(this.form.value.draftreporttoclientdueKP.split('.')[1]+'-'+this.form.value.draftreporttoclientdueKP.split('.')[0]+'-'+this.form.value.draftreporttoclientdueKP.split('.')[2]),
           ClientFeedbackDue :this.form.value.clientfeedbackdueKP=="" || this.form.value.clientfeedbackdueKP==null?null:new Date(this.form.value.clientfeedbackdueKP.split('.')[1]+'-'+this.form.value.clientfeedbackdueKP.split('.')[0]+'-'+this.form.value.clientfeedbackdueKP.split('.')[2]),
           FinalReportToClientDue :this.form.value.finalreporttoclientdueKP=="" || this.form.value.finalreporttoclientdueKP==null?null:new Date(this.form.value.finalreporttoclientdueKP.split('.')[1]+'-'+this.form.value.finalreporttoclientdueKP.split('.')[0]+'-'+this.form.value.finalreporttoclientdueKP.split('.')[2]),
           
           
             StatusId :this.form.value.statusidKP
          
          }
        
        lstAudittype.push(KPobj);
        }
        if(this.form.value.AuditTypeOther){
          
   
    var Otherobj={    
      AuditType :"OT",
       FinancialAuditLead:this.form.value.financialauditleadOther,
      RegionalLeadPartnerId :this.form.value.regionalleadpartnerOther,
       AssignedLeadAuditorId :this.form.value.assignedleadauditorOther,
       //sorting Assigned Audit Team members in asc order while save
    //   AssignedAuditTeamMembers :this.form.value.assignedauditteammembersOther.sort((a, b) => a - b).map(x=>x).join(","),
      AuditIdcode:this.form.value.idcodeOther,
      AuditPeriodStartDate:this.form.value.auditperiodstartdateOther==null?null:this.form.value.auditperiodstartdateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted): new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[2]),
         AuditPeriodEndDate :this.form.value.auditperiodenddateOther==null?null:this.form.value.auditperiodenddateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodenddateOther.singleDate.formatted): new Date(this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[2]),
        
         AuditExecutionStartDate: this.form.value.auditexecutionstartdateOther==null?null:this.form.value.auditexecutionstartdateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[2]),
         AuditExecutionEndDate: this.form.value.auditexecutionenddateOther==null?null:this.form.value.auditexecutionenddateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted): new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[2]),
       
      IaworkCompleted :this.form.value.iaworkcompletedOT=="" ||this.form.value.iaworkcompletedOT==null?null:new Date(this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[2]),
      AnnouncementDelivery  :this.form.value.announcementdeliveryOT=="" || this.form.value.announcementdeliveryOT==null?null:new Date(this.form.value.announcementdeliveryOT.split('.')[1]+'-'+this.form.value.announcementdeliveryOT.split('.')[0]+'-'+this.form.value.announcementdeliveryOT.split('.')[2]),
      ClientDataDue  :this.form.value.clientdatadueOT=="" || this.form.value.clientdatadueOT==null?null:new Date(this.form.value.clientdatadueOT.split('.')[1]+'-'+this.form.value.clientdatadueOT.split('.')[0]+'-'+this.form.value.clientdatadueOT.split('.')[2]),
     DraftReport :this.form.value.draftreportOT=="" || this.form.value.draftreportOT==null?null:new Date(this.form.value.draftreportOT.split('.')[1]+'-'+this.form.value.draftreportOT.split('.')[0]+'-'+this.form.value.draftreportOT.split('.')[2]),
     IaworkPOTerReviewDue :this.form.value.iaworkpOTerreviewdueOT=="" || this.form.value.iaworkpOTerreviewdueOT==null?null:new Date(this.form.value.iaworkpOTerreviewdueOT.split('.')[1]+'-'+this.form.value.iaworkpOTerreviewdueOT.split('.')[0]+'-'+this.form.value.iaworkpOTerreviewdueOT.split('.')[2]),
     DraftReportToClientDue :this.form.value.draftreporttoclientdueOT=="" || this.form.value.draftreporttoclientdueOT==null?null:new Date(this.form.value.draftreporttoclientdueOT.split('.')[1]+'-'+this.form.value.draftreporttoclientdueOT.split('.')[0]+'-'+this.form.value.draftreporttoclientdueOT.split('.')[2]),
     ClientFeedbackDue :this.form.value.clientfeedbackdueOT=="" || this.form.value.clientfeedbackdueOT==null?null:new Date(this.form.value.clientfeedbackdueOT.split('.')[1]+'-'+this.form.value.clientfeedbackdueOT.split('.')[0]+'-'+this.form.value.clientfeedbackdueOT.split('.')[2]),
     FinalReportToClientDue :this.form.value.finalreporttoclientdueOT=="" || this.form.value.finalreporttoclientdueOT==null?null:new Date(this.form.value.finalreporttoclientdueOT.split('.')[1]+'-'+this.form.value.finalreporttoclientdueOT.split('.')[0]+'-'+this.form.value.finalreporttoclientdueOT.split('.')[2]),
     StatusId :this.form.value.statusidOT
    
    }
        lstAudittype.push(Otherobj);
  }
  
  var obj={
    AuditAssignmentId:this.AuditAssignmentId,
    FormTitle:this.form.value.formtitle,
    ClientEnityId:this.ngClientEntityID,
    AuditYear :parseFloat(this.form.value.audityear),
    Classification:this.form.value.classification,
    ClientContact :this.form.value.clientcontacts.map(x=>x).join(","),
    GroupScope:this.form.value.groupscope,
    Comment:this.form.value.Comment,
    FormStatus: this.form.value.formstatus,
    Audit:lstAudittype
}
  this._auditassignmentService.UpdateData(obj)
  .subscribe(
    res => {
      
      if(res["message"]=="AuditAssignment updated"){
       this.toasttype="success";
      this.toastflag=true;
      this.oncancel("update");
      this.toastmessage="Audit Assignment has been updated successfully.";         
      this.form.reset();
      }
      else if(res["message"]=="AuditAssignment update failed"){
       // this.toasttype="error";
        //this.toastflag=true;
        
      //  this.toastmessage="Error occured please contact support team";
       
      }
      else if(res["message"]=="Duplicate AuditAssignment"){
        this.toasttype="warning";
        this.toastflag=true;
        
       this.toastmessage="Audit Assignment already exists with this entity and year";
       
      } 
      else if(res["message"]=="MobileNoExists"){
        //this.toasttype="warning";
        //this.toastflag=true;
        
       // this.toastmessage="Mobile no. already exists";
       
      }     
    },
    err => {
      if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
        //var key=Object.keys(err["error"]["errors"])[0];   
        //this.toasterService.message.next(err.toString());    
        this.toasttype = "warning";
        this.toastflag = true;
        
        this.toastmessage = err.toString();
      }
      else{
      this.toasttype="error";
     this.toastflag=true;
      
      this.toastmessage="Error occured. Please try after sometime";
      console.log(err);
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      }
    }

  )

  
}
CreateAuditAssignment() {
  //this.toastflag=false;
  
  this.validateForm(this.form);
  if(!this.form.valid)
  return  
  if(!this.form.value.AuditTypeRP && !this.form.value.AuditTypeAP && !this.form.value.AuditTypeOther && !this.form.value.AuditTypeKP)
  return
     let lstAudittype:any=[]; 

  if(this.form.value.AuditTypeAP){

    var APobj={    
      AuditType :"AP",
       FinancialAuditLead:this.form.value.financialauditleadAP,
      RegionalLeadPartnerId :this.form.value.regionalleadpartnerAP,
       AssignedLeadAuditorId :this.form.value.assignedleadauditorAP,
       //sorting Assigned Audit Team members in asc order while save
       //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersAP.sort((a, b) => a - b).map(x=>x).join(","),
       AuditPeriodStartDate:this.form.value.auditperiodstartdateAP==null?null:this.form.value.auditperiodstartdateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted): new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[2]),
       AuditPeriodEndDate :this.form.value.auditperiodenddateAP==null?null:this.form.value.auditperiodenddateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodenddateAP.singleDate.formatted): new Date(this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[2]),
      
       AuditExecutionStartDate: this.form.value.auditexecutionstartdateAP==null?null:this.form.value.auditexecutionstartdateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[2]),
       AuditExecutionEndDate: this.form.value.auditexecutionenddateAP==null?null:this.form.value.auditexecutionenddateAP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted): new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[2]),
      AuditIdcode:this.form.value.idcodeAP,
     
 	IaworkCompleted :this.form.value.iaworkcompletedAP=="" ||this.form.value.iaworkcompletedAP==null?null:new Date(this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[2]),
   AnnouncementDelivery  :this.form.value.announcementdeliveryAP=="" || this.form.value.announcementdeliveryAP==null?null:new Date(this.form.value.announcementdeliveryAP.split('.')[1]+'-'+this.form.value.announcementdeliveryAP.split('.')[0]+'-'+this.form.value.announcementdeliveryAP.split('.')[2]),
   ClientDataDue  :this.form.value.clientdatadueAP=="" || this.form.value.clientdatadueAP==null?null:new Date(this.form.value.clientdatadueAP.split('.')[1]+'-'+this.form.value.clientdatadueAP.split('.')[0]+'-'+this.form.value.clientdatadueAP.split('.')[2]),
  DraftReport :this.form.value.draftreportAP=="" || this.form.value.draftreportAP==null?null:new Date(this.form.value.draftreportAP.split('.')[1]+'-'+this.form.value.draftreportAP.split('.')[0]+'-'+this.form.value.draftreportAP.split('.')[2]),
  IaworkPaperReviewDue :this.form.value.iaworkpaperreviewdueAP=="" || this.form.value.iaworkpaperreviewdueAP==null?null:new Date(this.form.value.iaworkpaperreviewdueAP.split('.')[1]+'-'+this.form.value.iaworkpaperreviewdueAP.split('.')[0]+'-'+this.form.value.iaworkpaperreviewdueAP.split('.')[2]),
  DraftReportToClientDue :this.form.value.draftreporttoclientdueAP=="" || this.form.value.draftreporttoclientdueAP==null?null:new Date(this.form.value.draftreporttoclientdueAP.split('.')[1]+'-'+this.form.value.draftreporttoclientdueAP.split('.')[0]+'-'+this.form.value.draftreporttoclientdueAP.split('.')[2]),
  ClientFeedbackDue :this.form.value.clientfeedbackdueAP=="" || this.form.value.clientfeedbackdueAP==null?null:new Date(this.form.value.clientfeedbackdueAP.split('.')[1]+'-'+this.form.value.clientfeedbackdueAP.split('.')[0]+'-'+this.form.value.clientfeedbackdueAP.split('.')[2]),
  FinalReportToClientDue :this.form.value.finalreporttoclientdueAP=="" || this.form.value.finalreporttoclientdueAP==null?null:new Date(this.form.value.finalreporttoclientdueAP.split('.')[1]+'-'+this.form.value.finalreporttoclientdueAP.split('.')[0]+'-'+this.form.value.finalreporttoclientdueAP.split('.')[2]),
  InternalAuditEngagementLeaderId:this.form.value.internalauditengagementleaderAP,
  StatusId :this.form.value.statusidAP
    
    }
      lstAudittype.push(APobj);
  }
      if(this.form.value.AuditTypeRP){
        
          var RPobj={    
            AuditType :"RP",  
            FinancialAuditLead:this.form.value.financialauditleadRP,
            RegionalLeadPartnerId :this.form.value.regionalleadpartnerRP,
            AssignedLeadAuditorId :this.form.value.assignedleadauditorRP,
            //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.map(x=>x).join(","),
            //sorting Assigned Audit Team members in asc order while save
           // AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.sort((a, b) => a - b).map(x=>x).join(","),
            AuditIdcode:this.form.value.idcodeRP,
            AuditPeriodStartDate:this.form.value.auditperiodstartdateRP==null?null:this.form.value.auditperiodstartdateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted): new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[2]),
            AuditPeriodEndDate :this.form.value.auditperiodenddateRP==null?null:this.form.value.auditperiodenddateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodenddateRP.singleDate.formatted): new Date(this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[2]),
           
            AuditExecutionStartDate: this.form.value.auditexecutionstartdateRP==null?null:this.form.value.auditexecutionstartdateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[2]),
            AuditExecutionEndDate: this.form.value.auditexecutionenddateRP==null?null:this.form.value.auditexecutionenddateRP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted): new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[2]), 
            IaworkCompleted :this.form.value.iaworkcompletedRP=="" ||this.form.value.iaworkcompletedRP==null?null:new Date(this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[2]),
            AnnouncementDelivery  :this.form.value.announcementdeliveryRP=="" || this.form.value.announcementdeliveryRP==null?null:new Date(this.form.value.announcementdeliveryRP.split('.')[1]+'-'+this.form.value.announcementdeliveryRP.split('.')[0]+'-'+this.form.value.announcementdeliveryRP.split('.')[2]),
            ClientDataDue  :this.form.value.clientdatadueRP=="" || this.form.value.clientdatadueRP==null?null:new Date(this.form.value.clientdatadueRP.split('.')[1]+'-'+this.form.value.clientdatadueRP.split('.')[0]+'-'+this.form.value.clientdatadueRP.split('.')[2]),
           DraftReport :this.form.value.draftreportRP=="" || this.form.value.draftreportRP==null?null:new Date(this.form.value.draftreportRP.split('.')[1]+'-'+this.form.value.draftreportRP.split('.')[0]+'-'+this.form.value.draftreportRP.split('.')[2]),
           IaworkPRPerReviewDue :this.form.value.iaworkpRPerreviewdueRP=="" || this.form.value.iaworkpRPerreviewdueRP==null?null:new Date(this.form.value.iaworkpRPerreviewdueRP.split('.')[1]+'-'+this.form.value.iaworkpRPerreviewdueRP.split('.')[0]+'-'+this.form.value.iaworkpRPerreviewdueRP.split('.')[2]),
           DraftReportToClientDue :this.form.value.draftreporttoclientdueRP=="" || this.form.value.draftreporttoclientdueRP==null?null:new Date(this.form.value.draftreporttoclientdueRP.split('.')[1]+'-'+this.form.value.draftreporttoclientdueRP.split('.')[0]+'-'+this.form.value.draftreporttoclientdueRP.split('.')[2]),
           ClientFeedbackDue :this.form.value.clientfeedbackdueRP=="" || this.form.value.clientfeedbackdueRP==null?null:new Date(this.form.value.clientfeedbackdueRP.split('.')[1]+'-'+this.form.value.clientfeedbackdueRP.split('.')[0]+'-'+this.form.value.clientfeedbackdueRP.split('.')[2]),
           FinalReportToClientDue :this.form.value.finalreporttoclientdueRP=="" || this.form.value.finalreporttoclientdueRP==null?null:new Date(this.form.value.finalreporttoclientdueRP.split('.')[1]+'-'+this.form.value.finalreporttoclientdueRP.split('.')[0]+'-'+this.form.value.finalreporttoclientdueRP.split('.')[2]),
           
           InternalAuditEngagementLeaderId:this.form.value.internalauditengagementleaderRP,
             StatusId :this.form.value.statusidRP
          
          }
              lstAudittype.push(RPobj);
}
      if(this.form.value.AuditTypeKP){
        var KPobj={    
          AuditType :"KP",
           FinancialAuditLead:this.form.value.financialauditleadKP,
          AuditPeriodStartDate:null,
          AuditPeriodEndDate :null,
          RegionalLeadPartnerId :null,
           AssignedLeadAuditorId :this.form.value.assignedleadauditorKP,
           //sorting Assigned Audit Team members in asc order while save
         //  AssignedAuditTeamMembers :this.form.value.assignedauditteammembersKP.sort((a, b) => a - b).map(x=>x).join(","),
        
         
        
         AuditExecutionStartDate: this.form.value.auditexecutionstartdateKP==null?null:this.form.value.auditexecutionstartdateKP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[2]),
         AuditExecutionEndDate: this.form.value.auditexecutionenddateKP==null?null:this.form.value.auditexecutionenddateKP.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted): new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[2]),
         AuditIdcode:this.form.value.idcodeKP,
          
          IaworkCompleted :this.form.value.iaworkcompletedKP=="" ||this.form.value.iaworkcompletedKP==null?null:new Date(this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[2]),
          AnnouncementDelivery  :this.form.value.announcementdeliveryKP=="" || this.form.value.announcementdeliveryKP==null?null:new Date(this.form.value.announcementdeliveryKP.split('.')[1]+'-'+this.form.value.announcementdeliveryKP.split('.')[0]+'-'+this.form.value.announcementdeliveryKP.split('.')[2]),
          ClientDataDue  :this.form.value.clientdatadueKP=="" || this.form.value.clientdatadueKP==null?null:new Date(this.form.value.clientdatadueKP.split('.')[1]+'-'+this.form.value.clientdatadueKP.split('.')[0]+'-'+this.form.value.clientdatadueKP.split('.')[2]),
         DraftReport :this.form.value.draftreportKP=="" || this.form.value.draftreportKP==null?null:new Date(this.form.value.draftreportKP.split('.')[1]+'-'+this.form.value.draftreportKP.split('.')[0]+'-'+this.form.value.draftreportKP.split('.')[2]),
         IaworkPKPerReviewDue :this.form.value.iaworkpKPerreviewdueKP=="" || this.form.value.iaworkpKPerreviewdueKP==null?null:new Date(this.form.value.iaworkpKPerreviewdueKP.split('.')[1]+'-'+this.form.value.iaworkpKPerreviewdueKP.split('.')[0]+'-'+this.form.value.iaworkpKPerreviewdueKP.split('.')[2]),
         DraftReportToClientDue :this.form.value.draftreporttoclientdueKP=="" || this.form.value.draftreporttoclientdueKP==null?null:new Date(this.form.value.draftreporttoclientdueKP.split('.')[1]+'-'+this.form.value.draftreporttoclientdueKP.split('.')[0]+'-'+this.form.value.draftreporttoclientdueKP.split('.')[2]),
         ClientFeedbackDue :this.form.value.clientfeedbackdueKP=="" || this.form.value.clientfeedbackdueKP==null?null:new Date(this.form.value.clientfeedbackdueKP.split('.')[1]+'-'+this.form.value.clientfeedbackdueKP.split('.')[0]+'-'+this.form.value.clientfeedbackdueKP.split('.')[2]),
         FinalReportToClientDue :this.form.value.finalreporttoclientdueKP=="" || this.form.value.finalreporttoclientdueKP==null?null:new Date(this.form.value.finalreporttoclientdueKP.split('.')[1]+'-'+this.form.value.finalreporttoclientdueKP.split('.')[0]+'-'+this.form.value.finalreporttoclientdueKP.split('.')[2]),
         
         
           StatusId :this.form.value.statusidKP
        
        }
      
      lstAudittype.push(KPobj);
      }
      if(this.form.value.AuditTypeOther){
        
 
  var Otherobj={    
    AuditType :"OT",
     FinancialAuditLead:this.form.value.financialauditleadOther,
    RegionalLeadPartnerId :this.form.value.regionalleadpartnerOther,
     AssignedLeadAuditorId :this.form.value.assignedleadauditorOther,
     //sorting Assigned Audit Team members in asc order while save
  //   AssignedAuditTeamMembers :this.form.value.assignedauditteammembersOther.sort((a, b) => a - b).map(x=>x).join(","),
    AuditIdcode:this.form.value.idcodeOther,
    AuditPeriodStartDate:this.form.value.auditperiodstartdateOther==null?null:this.form.value.auditperiodstartdateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted): new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[2]),
       AuditPeriodEndDate :this.form.value.auditperiodenddateOther==null?null:this.form.value.auditperiodenddateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditperiodenddateOther.singleDate.formatted): new Date(this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[2]),
      
       AuditExecutionStartDate: this.form.value.auditexecutionstartdateOther==null?null:this.form.value.auditexecutionstartdateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted): new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[2]),
       AuditExecutionEndDate: this.form.value.auditexecutionenddateOther==null?null:this.form.value.auditexecutionenddateOther.singleDate.formatted.indexOf('-')!=-1?new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted): new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[1]+'-'+this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[0]+'-'+this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[2]),
     
    IaworkCompleted :this.form.value.iaworkcompletedOT=="" ||this.form.value.iaworkcompletedOT==null?null:new Date(this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[1]+'-'+this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[0]+'-'+this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[2]),
    AnnouncementDelivery  :this.form.value.announcementdeliveryOT=="" || this.form.value.announcementdeliveryOT==null?null:new Date(this.form.value.announcementdeliveryOT.split('.')[1]+'-'+this.form.value.announcementdeliveryOT.split('.')[0]+'-'+this.form.value.announcementdeliveryOT.split('.')[2]),
    ClientDataDue  :this.form.value.clientdatadueOT=="" || this.form.value.clientdatadueOT==null?null:new Date(this.form.value.clientdatadueOT.split('.')[1]+'-'+this.form.value.clientdatadueOT.split('.')[0]+'-'+this.form.value.clientdatadueOT.split('.')[2]),
   DraftReport :this.form.value.draftreportOT=="" || this.form.value.draftreportOT==null?null:new Date(this.form.value.draftreportOT.split('.')[1]+'-'+this.form.value.draftreportOT.split('.')[0]+'-'+this.form.value.draftreportOT.split('.')[2]),
   IaworkPOTerReviewDue :this.form.value.iaworkpOTerreviewdueOT=="" || this.form.value.iaworkpOTerreviewdueOT==null?null:new Date(this.form.value.iaworkpOTerreviewdueOT.split('.')[1]+'-'+this.form.value.iaworkpOTerreviewdueOT.split('.')[0]+'-'+this.form.value.iaworkpOTerreviewdueOT.split('.')[2]),
   DraftReportToClientDue :this.form.value.draftreporttoclientdueOT=="" || this.form.value.draftreporttoclientdueOT==null?null:new Date(this.form.value.draftreporttoclientdueOT.split('.')[1]+'-'+this.form.value.draftreporttoclientdueOT.split('.')[0]+'-'+this.form.value.draftreporttoclientdueOT.split('.')[2]),
   ClientFeedbackDue :this.form.value.clientfeedbackdueOT=="" || this.form.value.clientfeedbackdueOT==null?null:new Date(this.form.value.clientfeedbackdueOT.split('.')[1]+'-'+this.form.value.clientfeedbackdueOT.split('.')[0]+'-'+this.form.value.clientfeedbackdueOT.split('.')[2]),
   FinalReportToClientDue :this.form.value.finalreporttoclientdueOT=="" || this.form.value.finalreporttoclientdueOT==null?null:new Date(this.form.value.finalreporttoclientdueOT.split('.')[1]+'-'+this.form.value.finalreporttoclientdueOT.split('.')[0]+'-'+this.form.value.finalreporttoclientdueOT.split('.')[2]),
   StatusId :this.form.value.statusidOT
  
  }
      lstAudittype.push(Otherobj);
}

  var obj={
    
    FormTitle:this.form.value.formtitle,
    ClientEnityId:this.ngClientEntityID,
    AuditYear :parseFloat(this.form.value.audityear),
    Classification:this.form.value.classification,
    ClientContact :this.form.value.clientcontacts.map(x=>x).join(","),
    GroupScope:this.form.value.groupscope,
    Comment:this.form.value.Comment,
    FormStatus: this.form.value.formstatus,
    Audit:lstAudittype
}
  this._auditassignmentService.SaveData(obj)
  .subscribe(
    res => {
      
      if(res["message"]=="AuditAssignment Created"){
       this.toasttype="success";
      this.toastflag=true;
      this.oncancel("success");
      this.toastmessage="Audit Assignment has been added successfully.";         
      this.form.reset();
      }
      else if(res["message"]=="fail"){
       // this.toasttype="error";
        //this.toastflag=true;
        
      //  this.toastmessage="Error occured please contact support team";
       
      }
      else if(res["message"]=="Duplicate AuditAssignment"){
        this.toasttype="warning";
        this.toastflag=true;
        
       this.toastmessage="Audit Assignment already exists with this entity and year";
       
      } 
      else if(res["message"]=="MobileNoExists"){
        //this.toasttype="warning";
        //this.toastflag=true;
        
       // this.toastmessage="Mobile no. already exists";
       
      }     
    },
    err => {
      if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
        //var key=Object.keys(err["error"]["errors"])[0];   
        //this.toasterService.message.next(err.toString());    
        this.toasttype = "warning";
        this.toastflag = true;
        
        this.toastmessage = err.toString();
      }
      else{
      this.toasttype="error";
     this.toastflag=true;
      
      this.toastmessage="Error occured. Please try after sometime";
      console.log(err);
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      }
    }
  )

  
}
oncancel(status:string){
  this.BackEventEmitter.emit(status);
}
onChangeStartDateRP(currentdate){
  
  if(this.form.value.auditperiodenddateRP!=null){
  if(this.form.value.auditperiodenddateRP.singleDate.formatted<currentdate.singleDate.formatted){
    this.form.patchValue({ 
      auditperiodenddateRP:null       
   });
  }
}
  this.endDateRPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:currentdate==null?{year:0,month:0,day:0}:currentdate.singleDate.date}
}
onchangeFinancialAuditLeadRP(leadtype){
  this.form.patchValue({ 
    auditexecutionstartdateRP:null ,
    auditexecutionenddateRP:null  ,
    announcementdeliveryRP:null,
  clientdatadueRP :null,
  draftreportRP:null  ,
  iaworkcompletedRP:null,
  iaworkpaperreviewdueRP:null,
  draftreporttoclientdueRP:null,
  clientfeedbackdueRP :null,
  finalreporttoclientdueRP:null
 });

}
onChangeExcutionEndDateRP(currentdate){
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }
  let year=this.form.value.audityear;  
    let planningdates=this.lstPlanningDates.filter(function (n,i){    
      return n.year==year &&n.auditType=="RP";
  });
   let EydraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReportToClientDue,this.lstHolidaysMaster);
   let EyclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientFeedbackDue,this.lstHolidaysMaster);
   let EyfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyfinalReporttoClientDue,this.lstHolidaysMaster);
   this.form.patchValue({ 
    draftreporttoclientdueRP:this.formatDate(EydraftReportToClientDue),
    clientfeedbackdueRP :this.formatDate(EyclientFeedbackDue),
    finalreporttoclientdueRP:this.formatDate(EyfinalReporttoClientDue)
  });
  }
  
  onChangeIAworkCompletedDateRP(currentdate){
    if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
      this.toasttype="warning";
      this.toastflag=true;    
     this.toastmessage="Please provide entity details and audit year";
     return
    } 
    let year=this.form.value.audityear;  
      let planningdates=this.lstPlanningDates.filter(function (n,i){    
        return n.year==year &&n.auditType=="RP";
    });
     let IriaworkPaperReviewDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].iriaworkPaperReviewDue,this.lstHolidaysMaster);
     let IrdraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irdraftReportToClientDue,this.lstHolidaysMaster);
     let IrclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irclientFeedbackDue,this.lstHolidaysMaster);
     let IrfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irfinalReporttoClientDue,this.lstHolidaysMaster);
     this.form.patchValue({ 
      iaworkpaperreviewdueRP:this.formatDate(IriaworkPaperReviewDue),
      draftreporttoclientdueRP:this.formatDate(IrdraftReportToClientDue),
      clientfeedbackdueRP :this.formatDate(IrclientFeedbackDue),
      finalreporttoclientdueRP:this.formatDate(IrfinalReporttoClientDue)
    });
    }
onChangeExcutionStartDateRP(currentdate){
  
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }
  
  if(this.form.value.auditexecutionenddateRP!=null){
  if(this.form.value.auditexecutionenddateRP.singleDate.formatted<currentdate.singleDate.formatted){
    this.form.patchValue({ 
      auditexecutionenddateRP:null       
   });
  }  
}
let year=this.form.value.audityear;
  this.executionEndDateRPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:currentdate==null?{year:0,month:0,day:0}:currentdate.singleDate.date}
  let planningdates=this.lstPlanningDates.filter(function (n,i){    
    return n.year==year &&n.auditType=="RP";
});
 let Announcementdelivery= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyannouncementDelivery,this.lstHolidaysMaster);
 let eyclientDataDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientDataDue,this.lstHolidaysMaster);
 let EydraftReport= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReport,this.lstHolidaysMaster);
 this.form.patchValue({ 
  announcementdeliveryRP:this.formatDate(Announcementdelivery),
  clientdatadueRP :this.formatDate(eyclientDataDue),
  draftreportRP:this.formatDate(EydraftReport)
});
}

onChangeStartDateAP(currentdate){
  
  if(this.form.value.auditperiodenddateAP!=null){
  if(this.form.value.auditperiodenddateAP.singleDate.formatted<currentdate.singleDate.formatted){
    this.form.patchValue({ 
      auditperiodenddateAP:null       
   });
  }
}
  this.endDateAPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:currentdate==null?{year:0,month:0,day:0}:currentdate.singleDate.date}
}

onChangeExcutionStartDateAP(currentdate){
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }
  
  if(this.form.value.auditexecutionenddateAP!=null){
  if(this.form.value.auditexecutionenddateAP.singleDate.formatted<currentdate.singleDate.formatted){
    this.form.patchValue({ 
      auditexecutionenddateAP:null       
   });
  }
}
  this.executionEndDateAPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:currentdate==null?{year:0,month:0,day:0}:currentdate.singleDate.date}
  let year=this.form.value.audityear;
  
  let planningdates=this.lstPlanningDates.filter(function (n,i){    
    return n.year==year &&n.auditType=="AP";
});
 let Announcementdelivery= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyannouncementDelivery,this.lstHolidaysMaster);
 let eyclientDataDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientDataDue,this.lstHolidaysMaster);
 let EydraftReport= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReport,this.lstHolidaysMaster);
 this.form.patchValue({ 
  announcementdeliveryAP:this.formatDate(Announcementdelivery),
  clientdatadueAP :this.formatDate(eyclientDataDue),
  draftreportAP:this.formatDate(EydraftReport)
});
}
onchangeFinancialAuditLeadAP(leadtype){
  this.form.patchValue({ 
    auditexecutionstartdateAPher:null ,
    auditexecutionenddateAPher:null  ,
    announcementdeliveryAP:null,
  clientdatadueAP :null,
  draftreportAP:null  ,
  iaworkcompletedAP:null,
  iaworkpaperreviewdueAP:null,
  draftreporttoclientdueAP:null,
  clientfeedbackdueAP :null,
  finalreporttoclientdueAP:null
 });

}
onChangeExcutionEndDateAP(currentdate){
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }
  let year=this.form.value.audityear;  
    let planningdates=this.lstPlanningDates.filter(function (n,i){    
      return n.year==year &&n.auditType=="AP";
  });
   let EydraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReportToClientDue,this.lstHolidaysMaster);
   let EyclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientFeedbackDue,this.lstHolidaysMaster);
   let EyfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyfinalReporttoClientDue,this.lstHolidaysMaster);
   this.form.patchValue({ 
    draftreporttoclientdueAP:this.formatDate(EydraftReportToClientDue),
    clientfeedbackdueAP :this.formatDate(EyclientFeedbackDue),
    finalreporttoclientdueAP:this.formatDate(EyfinalReporttoClientDue)
  });
  }
  
  onChangeIAworkCompletedDateAP(currentdate){
    if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
      this.toasttype="warning";
      this.toastflag=true;    
     this.toastmessage="Please provide entity details and audit year";
     return
    } 
    let year=this.form.value.audityear;  
      let planningdates=this.lstPlanningDates.filter(function (n,i){    
        return n.year==year &&n.auditType=="AP";
    });
     let IriaworAPaperReviewDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].iriaworkPaperReviewDue,this.lstHolidaysMaster);
     let IrdraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irdraftReportToClientDue,this.lstHolidaysMaster);
     let IrclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irclientFeedbackDue,this.lstHolidaysMaster);
     let IrfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irfinalReporttoClientDue,this.lstHolidaysMaster);
     this.form.patchValue({ 
      iaworkpaperreviewdueAP:this.formatDate(IriaworAPaperReviewDue),
      draftreporttoclientdueAP:this.formatDate(IrdraftReportToClientDue),
      clientfeedbackdueAP :this.formatDate(IrclientFeedbackDue),
      finalreporttoclientdueAP:this.formatDate(IrfinalReporttoClientDue)
    });
    }

onChangeExcutionStartDateKP(currentdate){
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }
  
  if(this.form.value.auditexecutionenddateKP!=null){
  if(this.form.value.auditexecutionenddateKP.singleDate.formatted<currentdate.singleDate.formatted){
    this.form.patchValue({ 
      auditexecutionenddateKP:null       
   });
  }
}
  this.executionEndDateKPOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:currentdate==null?{year:0,month:0,day:0}:currentdate.singleDate.date}
  let year=this.form.value.audityear;
  
  let planningdates=this.lstPlanningDates.filter(function (n,i){    
    return n.year==year &&n.auditType=="KP";
});
 let Announcementdelivery= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyannouncementDelivery,this.lstHolidaysMaster);
 let eyclientDataDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientDataDue,this.lstHolidaysMaster);
 let EydraftReport= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReport,this.lstHolidaysMaster);
 this.form.patchValue({ 
  announcementdeliveryKP:this.formatDate(Announcementdelivery),
  clientdatadueKP :this.formatDate(eyclientDataDue),
  draftreportKP:this.formatDate(EydraftReport)
});
}
onchangeFinancialAuditLeadKP(leadtype){
  this.form.patchValue({ 
    auditexecutionstartdateKPher:null ,
    auditexecutionenddateKPher:null  ,
    announcementdeliveryKP:null,
  clientdatadueKP :null,
  draftreportKP:null  ,
  iaworkcompletedKP:null,
  iaworkpaperreviewdueKP:null,
  draftreporttoclientdueKP:null,
  clientfeedbackdueKP :null,
  finalreporttoclientdueKP:null
 });

}
onChangeExcutionEndDateKP(currentdate){
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }
  let year=this.form.value.audityear;  
    let planningdates=this.lstPlanningDates.filter(function (n,i){    
      return n.year==year &&n.auditType=="KP";
  });
   let EydraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReportToClientDue,this.lstHolidaysMaster);
   let EyclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientFeedbackDue,this.lstHolidaysMaster);
   let EyfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyfinalReporttoClientDue,this.lstHolidaysMaster);
   this.form.patchValue({ 
    draftreporttoclientdueKP:this.formatDate(EydraftReportToClientDue),
    clientfeedbackdueKP :this.formatDate(EyclientFeedbackDue),
    finalreporttoclientdueKP:this.formatDate(EyfinalReporttoClientDue)
  });
  }
  
  onChangeIAworkCompletedDateKP(currentdate){
    if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
      this.toasttype="warning";
      this.toastflag=true;    
     this.toastmessage="Please provide entity details and audit year";
     return
    }
    let year=this.form.value.audityear;  
      let planningdates=this.lstPlanningDates.filter(function (n,i){    
        return n.year==year &&n.auditType=="KP";
    });
     let IriaworkPaperReviewDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].iriaworkPaperReviewDue,this.lstHolidaysMaster);
     let IrdraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irdraftReportToClientDue,this.lstHolidaysMaster);
     let IrclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irclientFeedbackDue,this.lstHolidaysMaster);
     let IrfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irfinalReporttoClientDue,this.lstHolidaysMaster);
     this.form.patchValue({ 
      iaworkpaperreviewdueKP:this.formatDate(IriaworkPaperReviewDue),
      draftreporttoclientdueKP:this.formatDate(IrdraftReportToClientDue),
      clientfeedbackdueKP :this.formatDate(IrclientFeedbackDue),
      finalreporttoclientdueKP:this.formatDate(IrfinalReporttoClientDue)
    });
    }
onChangeStartDateOT(currentdate){
  
  if(this.form.value.auditperiodenddateOther!=null){
  if(this.form.value.auditperiodenddateOther.singleDate.formatted<currentdate.singleDate.formatted){
    this.form.patchValue({ 
      auditperiodenddateOther:null       
   });
  }
}
  this.endDateOTOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:currentdate==null?{year:0,month:0,day:0}:currentdate.singleDate.date}
}

onChangeExcutionStartDateOT(currentdate){
  
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }

  if(this.form.value.auditexecutionenddateOther!=null){
  if(this.form.value.auditexecutionenddateOther.singleDate.formatted<currentdate.singleDate.formatted){
    this.form.patchValue({ 
      auditexecutionenddateOther:null       
   });
  }
}
  this.executionEndDateOTOptions={dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo',disableUntil:currentdate==null?{year:0,month:0,day:0}:currentdate.singleDate.date}
  let year=this.form.value.audityear;
  
  let planningdates=this.lstPlanningDates.filter(function (n,i){    
    return n.year==year &&n.auditType=="OT";
});
 let Announcementdelivery= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyannouncementDelivery,this.lstHolidaysMaster);
 let eyclientDataDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientDataDue,this.lstHolidaysMaster);
 let EydraftReport= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReport,this.lstHolidaysMaster);
 this.form.patchValue({ 
  announcementdeliveryOT:this.formatDate(Announcementdelivery),
  clientdatadueOT :this.formatDate(eyclientDataDue),
  draftreportOT:this.formatDate(EydraftReport)
});
}
onchangeFinancialAuditLeadOT(leadtype){
  this.form.patchValue({ 
    auditexecutionstartdateOther:null ,
    auditexecutionenddateOther:null  ,
    announcementdeliveryOT:null,
  clientdatadueOT :null,
  draftreportOT:null  ,
  iaworkcompletedOT:null,
  iaworkpaperreviewdueOT:null,
  draftreporttoclientdueOT:null,
  clientfeedbackdueOT :null,
  finalreporttoclientdueOT:null
 });

}
onChangeExcutionEndDateOT(currentdate){
  if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
    this.toasttype="warning";
    this.toastflag=true;    
   this.toastmessage="Please provide entity details and audit year";
   return
  }
  let year=this.form.value.audityear;  
    let planningdates=this.lstPlanningDates.filter(function (n,i){    
      return n.year==year &&n.auditType=="OT";
  });
   let EydraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eydraftReportToClientDue,this.lstHolidaysMaster);
   let EyclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyclientFeedbackDue,this.lstHolidaysMaster);
   let EyfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].eyfinalReporttoClientDue,this.lstHolidaysMaster);
   this.form.patchValue({ 
    draftreporttoclientdueOT:this.formatDate(EydraftReportToClientDue),
    clientfeedbackdueOT :this.formatDate(EyclientFeedbackDue),
    finalreporttoclientdueOT:this.formatDate(EyfinalReporttoClientDue)
  });
  }
  
  onChangeIAworkCompletedDateOT(currentdate){
    if(this.form.value.entityname==null ||this.form.value.entityname=="" ||this.form.value.audityear==null){
      this.toasttype="warning";
      this.toastflag=true;    
     this.toastmessage="Please provide entity details and audit year";
     return
    }
    let year=this.form.value.audityear;  
      let planningdates=this.lstPlanningDates.filter(function (n,i){    
        return n.year==year &&n.auditType=="OT";
    });
     let IriaworkPaperReviewDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].iriaworkPaperReviewDue,this.lstHolidaysMaster);
     let IrdraftReportToClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irdraftReportToClientDue,this.lstHolidaysMaster);
     let IrclientFeedbackDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irclientFeedbackDue,this.lstHolidaysMaster);
     let IrfinalReporttoClientDue= this.CalculateBussinessDates(currentdate.singleDate.jsDate,planningdates[0].irfinalReporttoClientDue,this.lstHolidaysMaster);
     this.form.patchValue({ 
      iaworkpaperreviewdueOT:this.formatDate(IriaworkPaperReviewDue),
      draftreporttoclientdueOT:this.formatDate(IrdraftReportToClientDue),
      clientfeedbackdueOT :this.formatDate(IrclientFeedbackDue),
      finalreporttoclientdueOT:this.formatDate(IrfinalReporttoClientDue)
    });
    }
ValidationsforAuditTypes(){  
this.form.get('AuditTypeRP').valueChanges
    .subscribe(value => {
      
      if(value) {      
        this.form.get('financialauditleadRP').setValidators(Validators.required);
        this.form.get('auditperiodstartdateRP').setValidators(Validators.required);
        this.form.get('auditperiodenddateRP').setValidators(Validators.required);
        this.form.get('regionalleadpartnerRP').setValidators(Validators.required);
        this.form.get('assignedleadauditorRP').setValidators(Validators.required);
     //   this.form.get('statusidRP').setValidators(Validators.required);
        //this.form.get('assignedauditteammembersRP').setValidators(Validators.required);
        //this.form.get('auditexecutionstartdateRP').setValidators(Validators.required);
        //this.form.get('auditexecutionenddateRP').setValidators(Validators.required);
      } else {
        this.form.get('financialauditleadRP').setValidators(null);
        this.form.get('auditperiodstartdateRP').setValidators(null);
        this.form.get('auditperiodenddateRP').setValidators(null);
        this.form.get('regionalleadpartnerRP').setValidators(null);
        this.form.get('assignedleadauditorRP').setValidators(null);
       // this.form.get('statusidRP').setValidators(null);
        //this.form.get('assignedauditteammembersRP').setValidators(null);
       // this.form.get('auditexecutionstartdateRP').setValidators(null);
        //this.form.get('auditexecutionenddateRP').setValidators(null);
      }
      this.form.get('financialauditleadRP').updateValueAndValidity();
      this.form.get('auditperiodstartdateRP').updateValueAndValidity();
      this.form.get('auditperiodenddateRP').updateValueAndValidity();
      this.form.get('regionalleadpartnerRP').updateValueAndValidity();
      this.form.get('assignedleadauditorRP').updateValueAndValidity();
      //this.form.get('statusidRP').updateValueAndValidity();
      //this.form.get('assignedauditteammembersRP').updateValueAndValidity();
      //this.form.get('auditexecutionstartdateRP').updateValueAndValidity();
      //this.form.get('auditexecutionenddateRP').updateValueAndValidity();
      
      
    }
);
this.form.get('AuditTypeKP').valueChanges
    .subscribe(value => {
      
      if(value) {      
        this.form.get('financialauditleadKP').setValidators(Validators.required);   
        this.form.get('assignedleadauditorKP').setValidators(Validators.required);
        //this.form.get('statusidKP').setValidators(Validators.required);
        //this.form.get('assignedauditteammembersKP').setValidators(Validators.required);
       // this.form.get('auditexecutionstartdateKP').setValidators(Validators.required);
        //this.form.get('auditexecutionenddateKP').setValidators(Validators.required);
      } else {
        this.form.get('financialauditleadKP').setValidators(null);
        this.form.get('assignedleadauditorKP').setValidators(null);
        //this.form.get('statusidKP').setValidators(null);
       // this.form.get('assignedauditteammembersKP').setValidators(null);
      //  this.form.get('auditexecutionstartdateKP').setValidators(null);
        //this.form.get('auditexecutionenddateKP').setValidators(null);
      }
      this.form.get('financialauditleadKP').updateValueAndValidity();
      this.form.get('assignedleadauditorKP').updateValueAndValidity();
      //this.form.get('statusidKP').updateValueAndValidity();
     // this.form.get('assignedauditteammembersKP').updateValueAndValidity();
     // this.form.get('auditexecutionstartdateKP').updateValueAndValidity();
      //this.form.get('auditexecutionenddateKP').updateValueAndValidity();
      
      
    }
);
this.form.get('AuditTypeAP').valueChanges
    .subscribe(value => {
      
      if(value) {      
        this.form.get('financialauditleadAP').setValidators(Validators.required);
        //this.form.get('auditperiodstartdateAP').setValidators(Validators.required);
        //this.form.get('auditperiodenddateAP').setValidators(Validators.required);
        this.form.get('regionalleadpartnerAP').setValidators(Validators.required);
        this.form.get('assignedleadauditorAP').setValidators(Validators.required);
        //this.form.get('statusidAP').setValidators(Validators.required);
       // this.form.get('assignedauditteammembersAP').setValidators(Validators.required);
       // this.form.get('auditexecutionstartdateAP').setValidators(Validators.required);
        //this.form.get('auditexecutionenddateAP').setValidators(Validators.required);
      } else {
        this.form.get('financialauditleadAP').setValidators(null);
        //this.form.get('auditperiodstartdateAP').setValidators(null);
        //this.form.get('auditperiodenddateAP').setValidators(null);
        this.form.get('regionalleadpartnerAP').setValidators(null);
        this.form.get('assignedleadauditorAP').setValidators(null);
        //this.form.get('statusidAP').setValidators(null);
        //this.form.get('assignedauditteammembersAP').setValidators(null);
        //this.form.get('auditexecutionstartdateAP').setValidators(null);
        //this.form.get('auditexecutionenddateAP').setValidators(null);
      }
      this.form.get('financialauditleadAP').updateValueAndValidity();
      //this.form.get('auditperiodstartdateAP').updateValueAndValidity();
      //this.form.get('auditperiodenddateAP').updateValueAndValidity();
      this.form.get('regionalleadpartnerAP').updateValueAndValidity();
      this.form.get('assignedleadauditorAP').updateValueAndValidity();
      //this.form.get('statusidAP').updateValueAndValidity();
     // this.form.get('assignedauditteammembersAP').updateValueAndValidity();
      //this.form.get('auditexecutionstartdateAP').updateValueAndValidity();
     // this.form.get('auditexecutionenddateAP').updateValueAndValidity();
      

    }
);
this.form.get('AuditTypeOther').valueChanges
    .subscribe(value => {
      
      if(value) {      
        this.form.get('financialauditleadOther').setValidators(Validators.required);
        //this.form.get('auditperiodstartdateOther').setValidators(Validators.required);
        //this.form.get('auditperiodenddateOther').setValidators(Validators.required);
        this.form.get('regionalleadpartnerOther').setValidators(Validators.required);
        this.form.get('assignedleadauditorOther').setValidators(Validators.required);
        this.form.get('statusidOT').setValidators(Validators.required);
       // this.form.get('assignedauditteammembersOther').setValidators(Validators.required);
        //this.form.get('auditexecutionstartdateOther').setValidators(Validators.required);
        //this.form.get('auditexecutionenddateOther').setValidators(Validators.required);
      } else {
        this.form.get('financialauditleadOther').setValidators(null);
        //this.form.get('auditperiodstartdateOther').setValidators(null);
        //this.form.get('auditperiodenddateOther').setValidators(null);
        this.form.get('regionalleadpartnerOther').setValidators(null);
        this.form.get('assignedleadauditorOther').setValidators(null);
        //this.form.get('statusidOT').setValidators(null);
        //this.form.get('assignedauditteammembersOther').setValidators(null);
        //this.form.get('auditexecutionstartdateOther').setValidators(null);
        //this.form.get('auditexecutionenddateOther').setValidators(null);
      }
      this.form.get('financialauditleadOther').updateValueAndValidity();
      //this.form.get('auditperiodstartdateOther').updateValueAndValidity();
      //this.form.get('auditperiodenddateOther').updateValueAndValidity();
      this.form.get('regionalleadpartnerOther').updateValueAndValidity();
      this.form.get('assignedleadauditorOther').updateValueAndValidity();
      //this.form.get('statusidOT').updateValueAndValidity();
      //this.form.get('assignedauditteammembersOther').updateValueAndValidity();
      //this.form.get('auditexecutionstartdateOther').updateValueAndValidity();
      //this.form.get('auditexecutionenddateOther').updateValueAndValidity();
      
      
    }
);

}
CalculateBussinessDates(startDate,businessDays,holidayMasterData:any){
  
  let direction=Math.sign(businessDays);
  if (direction == 1)
  {
      if (startDate.getDay() == 6)
      {
        startDate.setDate(startDate.getDate()+2);
          businessDays = businessDays - 1;
      }
      else if (startDate.getDay() == 0)
      {
        startDate.setDate(startDate.getDate()+1);
          businessDays = businessDays - 1;
      }
  }
  else
  {
      if (startDate.getDay() ==6)
      {
        startDate.setDate(startDate.getDate()-1);
          businessDays = businessDays + 1;
      }
      else if (startDate.DayOfWeek == 0)
      {
        startDate.setDate(startDate.getDate()-2);
          businessDays = businessDays + 1;
      }
  }



  let initialDayOfWeek = startDate.getDay();



  let weeksBase = Math.abs(businessDays / 5);
  let addDays = Math.abs(businessDays % 5);



  if ((direction == 1 && addDays + initialDayOfWeek > 5) ||
       (direction == -1 && addDays >= initialDayOfWeek))
  {
      addDays += 2;
  }



  let totalDays = (weeksBase * 7) + addDays;
  let endDate=new Date(startDate);  
  endDate.setDate(endDate.getDate() + totalDays * direction);
  if(direction == 1){
    var canton=this.form.value.canton;
    var holidayDates = this.lstHolidaysMaster.filter(function (x,i){
      return (x.year== startDate.getFullYear()&& x.cantonShortname.trim()==canton) || (x.year== endDate.getFullYear()&& x.cantonShortname.trim()==canton) ;
  });  
  holidayDates=holidayDates.sort(function (a, b) {
    var key1 = a.startAt;
    var key2 = b.startAt;
  
    if (key1 < key2) {
        return -1;
    } else if (key1 == key2) {
        return 0;
    } else {
        return 1;
    }
  });
    //holidayList.Where(x => x.StartAt > startDate && x.StartAt < endDate).ToList();
    holidayDates.forEach(element => {
      
    if(new Date(element.startAt)>new Date(startDate)){
      if(new Date(element.startAt)<new Date(endDate)){
      
        if (new Date(element.startAt).getDay() != 6 && new Date(element.startAt).getDay() != 0)
          {
            endDate.setDate(endDate.getDate()+1);
  
          }
        
          if (endDate.getDay() ==6)
          {
            endDate.setDate(endDate.getDate()+1);
             
          }
          else if (endDate.getDay() == 0)
          {
            endDate.setDate(endDate.getDate()+2);
             
          }
        
        }
      }
    });
}
else{
  var canton=this.form.value.canton;
  var holidayDates = this.lstHolidaysMaster.filter(function (x,i){
    return (x.year== startDate.getFullYear()&& x.cantonShortname.trim()==canton) || (x.year== endDate.getFullYear()&& x.cantonShortname.trim()==canton) ;
});  
holidayDates=holidayDates.sort(function (a, b) {
  var key1 = a.startAt;
  var key2 = b.startAt;

  if (key1 > key2) {
      return -1;
  } else if (key1 == key2) {
      return 0;
  } else {
      return 1;
  }
});
  //holidayList.Where(x => x.StartAt > startDate && x.StartAt < endDate).ToList();
  holidayDates.forEach(element => {
    
  if(new Date(element.startAt)<new Date(startDate)){
    if(new Date(element.startAt)>new Date(endDate)){
    
      if (new Date(element.startAt).getDay() != 6 && new Date(element.startAt).getDay() != 0)
        {
          endDate.setDate(endDate.getDate()-1);

        }
      
        if (endDate.getDay() ==6)
        {
          endDate.setDate(endDate.getDate()-1);
           
        }
        else if (endDate.getDay() == 0)
        {
          endDate.setDate(endDate.getDate()-2);
           
        }
      
      }
    }
  });
}
 
  return endDate;

}
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day,month,year].join('.');
}
GetRoleBasedRegion(){
      
  this._auditassignmentService.GetRoleBasedRegion()
  .subscribe(
    res => {
      
      this.RoleBasedRegion=res;
      
    
    },
    err => {
      
      console.log(err);
    }
  )

}
 ////#endregion

}
