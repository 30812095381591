import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private _http: HttpClient) { }


  get()
  {    
    return this._http.get(`${environment.apiUrl}/location/get`);
  }

  delete(id)
  {    
    return this._http.post(`${environment.apiUrl}/location/Delete/`,{id:id});
  }

  Add(obj)
  {    
    return this._http.post(`${environment.apiUrl}/location/Post/`,obj);
  }

  Update(obj)
  {    
    return this._http.post(`${environment.apiUrl}/location/Update/`,obj);
  }

}
