import { Component, HostListener, OnInit } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { EditDeleteActionsComponent } from '../../../reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { NotificationService } from './notification.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { threadId } from 'worker_threads';
import * as internal from 'events';
//import { MotifFormsModule } from '@ey-xd/ng-motif';
import { MotifFormsModule } from '@ey-xd/ng-motif';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  
  public contactTypeMasterData:any;
  public contactTypeName:any;
  public contactTypeId:any;
  isSelected:boolean=false;

constructor(private router: Router,private _headerService : HeaderService,private _service: NotificationService, private _formBuilder: FormBuilder) { }

 //#region ag-grid_variables
 modules = AllCommunityModules;
 gridApi: any;
 public dynamicstyle={
  marginTop: "",
  width: "",
  height: "" 
 }
 public style = {
   marginTop: "",
   width: "",
   height: ""
 };

  public btnsubmittext: string = "ADD";

  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public ColumnDefs: any;
  public frameworkComponents: any;
  public tableDataset: any;
  public deletemodalflag: boolean = false;
 //#endregion ag-grid_variables
 
 

  form: FormGroup;
  public EditID: any;
  public DeletID: any;
  public ISUpdate: boolean = false;
  public showLoader: boolean = false;
  public rowData: any;


  //#region  toast

  public toasttype: string;
  public toastflag: boolean;
  public toastmessage: string;

  //#endregion toast

////#region actions variables
public divFlag : boolean=false;
public styleDIV = {
  marginTop: "",
  width: "",
  height: ""
};
public actionAccess:any;
////#endregion
@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableHeight();
  }
  ngOnInit(): void {
    this.GetContacttype();
    this.form = this.createForm();
    // this.form.controls['contacttype'].enable();
    //ContactTypeId:this.form.value.contacttype;
    this.setDivHeight();
    this._headerService.getActions("Notification").then((value) => {
      this.actionAccess=value;
      // this.form.controls['contacttype'].enable();
      if(this.actionAccess.indexOf("View")!=-1){
        this.divFlag=true;
        this.setFrameworkoptions();
        this.setTableHeight();
        this.buildHeaders();
        

      }
      else
      this.router.navigate(['/unauthorizedaccess'])
    });
    // this.form.controls['contacttype'].enable();
  }

  createForm() {
    return this._formBuilder.group({
      name: [null, [Validators.required]],
      contacttype: [null, [Validators.required]],
    })

  }

  handleGridReady(params) {
    this.gridApi = params;
    this.gridApi.api.showLoadingOverlay()
    this.gridApi.api.sizeColumnsToFit()
    this.bindGrid();
    // this.form.controls['contacttype'].enable();

  };

  setFrameworkoptions() {
    this.frameworkComponents = {
      editDeleteActions: EditDeleteActionsComponent,
    }

  }
  
  // setTableHeight() {
  //   this.style.width = "100%";
  //   this.style.height = (document.documentElement.clientHeight - 135) + "px";
  // }


  private buildHeaders() {

    this.ColumnDefs = [

      { headerName: 'Contact Type', field: 'contactTypeName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,width:80 },
      { headerName: 'Notification', field: 'notificationName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true ,
      cellStyle: { 'text-align': "left",'margin':"-15px" }
      ,cellRenderer: (data) => {
        
        const eDiv = document.createElement('div');
        eDiv.innerHTML=data.value;
        return eDiv.innerHTML;
      }},
      { headerName: 'Last Updated By', field: 'lastUpdatedByName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, width:80 },
      {
        headerName: 'Last Updated On', field: 'lastUpdatedDate', sortable: true, filter: 'agDateColumnFilter', unSortIcon: true, resizable: true,width:80 ,
        cellRenderer: (data) => {
          var celldate = moment(data.value);
          return data.lastUpdatedDate ? "" : celldate.format("DD-MMM-YYYY") + " "
            + celldate.format("H:m");
        },
        filterParams: {

          // provide comparator function
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = cellValue;

            if (dateAsString == null) {
              return 0;
            }


            var date = dateAsString.split('T')[0];
            var dateParts = date.split('-');

            var day = Number(dateParts[2]);
            var month = dateParts[1] - 1;
            var year = Number(dateParts[0]);
            var cellDate = moment(year + "/" + month + "/" + day, "YYYY/mm/DD");

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          }
        }
      }


    ]
    if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Add / Update")!=-1){
      
      this.ColumnDefs.unshift({
      headerName: '',
      cellRenderer: "editDeleteActions",
      cellRendererParams: {
        onEditClick: this.onEditClick.bind(this),
        onDeleteClick: this.onDeleteClick.bind(this),
        Actions:this.actionAccess,
      },
      width: 80,
      sortable: false,
      pinned: 'left',
      autoHeight: false,
      filter: false,

      });
    }
  }

  private bindGrid() {
    
    this._service.get()
      .subscribe(
        res => {
          ;

          this.tableDataset = res;
          console.log(res);
          //  this.gridApi.api.hideOverlay();

        },
        err => {

          console.log(err);
        }
      )
      // this.form.controls['contacttype'].enable();
  }

  onDeleteClick(e) {
    // let row = e.rowData;
    // this.deletemodalflag = true;
    // this.DeletID = e.rowData.notificationId;
    this.toastflag=false;
this.DeletID=e.rowData.notificationId;
this.deletemodalflag=true;

  }

 
  onEditClick(e) {
;
    this.ISUpdate = true;
    console.log(e.rowData);
    this.EditID = e.rowData.notificationId;
    let notificationName = e.rowData.notificationName;
    let contactType = e.rowData.contactTypeName;
    //  this.form.controls['contacttype'].disabled;
    this.btnsubmittext = "Update";
    this.rowData = e.rowData;
    // this.form.patchValue({
    //   contacttype: e.rowData.contactTypeName
    // })
    this.form.setValue({
      name: this.rowData.notificationName,
      contacttype:this.rowData.contactType
    });

    // this.form.value.contacttype.disable();
    // this.form.controls['contacttype'].disable();
    this.isSelected = true;
//     this.form.controls['contacttype'].valueChanges.subscribe(
//     value => {
//         if(value !== ''){
//             this.form.controls['inputValue'].disable();
//         } else {
//             this.form.controls['inputValue'].enable();
//         }
//     }
// );

  }

  submit() {
    this.validateForm(this.form);
    // this.form.controls['contacttype'].enable();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.ISUpdate) {
        this.update();
      } else {
        this.Add();
      }
    }

  }


  validateForm(form: FormGroup) {
    
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
        // this.form.controls['contacttype'].enable();
      }
    });
  }

  Add() {

    let name = this.form.get("name").value;
    let contacttype = this.form.get("contacttype").value;
    let addobj = {
      notificationName: btoa(unescape(encodeURIComponent(name))),//btoa(name),
      contactType:contacttype,
      // ContactTypeId:this.form.value.contacttype,
    }

    this._service.Add(addobj)
      .subscribe(
        (res: any) => {
          if (res.message == "success") {
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Notification has been added successfully.";
            this.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res.message == "NotificationExists") {
            this.showLoader = false;
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "Notification already exist.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }



        },
        err => {
          if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
                
            this.toasttype = "warning";
            this.toastflag = true;
            
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else{
          this.reset();
          }
        }
      )

      // this.form.controls['contacttype'].enable();
  }

  update() {

    let name = this.form.get("name").value;
    let contacttype = this.form.get("contacttype").value;
    let upobj = {
      notificationName:btoa(unescape(encodeURIComponent(name))),
      contactType:this.form.value.contacttype,

      notificationId: this.EditID
    }

    this._service.Update(upobj)
      .subscribe(
        (res: any) => {

          if (res.message == "NotificationExists") {
            this.showLoader = false;
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "Notification already exists.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else
            if (res.message == "success") {

              this.reset();
              this.toasttype = "success";
              this.toastflag = true;
              this.toastmessage = "Notification has been updated successfully.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);

            } else
              if (res.message == "fail") {
                {
                  this.reset();
                  this.toasttype = "error";
                  this.toastflag = true;
                  this.toastmessage = "An unexpected error has occured. Please contact support team.";
                  setTimeout(() => {
                    this.toastflag=false;
                   }, 3000);
                }


              }
        },
        err => {
          if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
              
            this.toasttype = "warning";
            this.toastflag = true;
            
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else{
          this.reset();
          }
        }
      )

      // this.form.controls['contacttype'].enable();

  }

  Delete() {
    this.toastflag=false;
    this._service.delete(this.DeletID)
      .subscribe(
        (res: any) => {

          if (res.message == "success") {
            this.deletemodalflag = false;


            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Notification has been deleted successfully.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          } else if (res.message == "NotificationCannotBeDeleted") {
            this.showLoader = false;
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "Notification cannot be deleted as it was mapped to Client Entity.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }

          this.reset();
        },
        err => {
          this.reset();

        }
      )


  }

  
  reset() {
    this.showLoader = false;
    this.ISUpdate = false;
    this.EditID = null;
    this.DeletID = null;
    this.btnsubmittext = "ADD";
    this.form.enabled;
    this.form.reset();
    
  this.isSelected=false;
    this.bindGrid();
    

  }

////#region Action check method
ActionsCheck(action){    
  if(this.actionAccess.indexOf(action)!=-1)
  return true;
  else
  return false;
}
setTableHeight() {
  
  this.style.marginTop = '20px';
  this.style.width = "100%";
  this.style.height = (document.documentElement.clientHeight - 480) + "px";  
}
setdynamicDivHeight() {
  this.dynamicstyle.marginTop = '20px';
  this.dynamicstyle.width = "100%";
  let height = document.documentElement.clientHeight;
  height = height - (height / 2) - (105);
  this.dynamicstyle.height = (height) + "px";
}
setDivHeight() {
    
  this.styleDIV.width = "100%";
  this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
}
////#endregion



//contact dropdown get
GetContacttype(){
    ;
  
  this._service.GetContacttype()
  .subscribe(
    res => {
      this.contactTypeMasterData=res;
      console.log(res);
      this.contactTypeMasterData.forEach(element => {
        this.contactTypeName=element;
        this.contactTypeId=element;
      });
      console.log(this.contactTypeMasterData);
    });
}

  ExcelExport(){
    this._service.ExcelExport().subscribe(
      res => {
        let date = new Date().toString();
        saveAs(res, "Notification" + date + ".xlsx");

      },
      err => {

        console.log(err);
      }
    )
  }
}

