<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
    <div class="Audit-playbook-main">
        <div class="examples-container">
            <div *ngIf="!AddUpdateFlag && !ArchiveFlag">
                <div class="motif-row" style="margin-bottom:14px">

                    <div class="motif-col-xs-4 motif-col-lg-3">
                        <motif-tab-bar class="motif-tab-bar">
                            <button motifButton (click)="currentactivetab = 'AuditAssignment'"
                                [ngClass]="{'motif-active': currentactivetab === 'AuditAssignment'}"
                                class="motif-text-link motif-active">
                                <motif-icon [src]="'/assets/icons/social/ic_group_24px.svg'"></motif-icon>
                                Audit Assignment
                            </button>
                            <button motifButton *ngIf="ActionsCheck('Audit Trail')"
                                (click)="getAuditTrail();auditTrailActiveTab='Timeline'"
                                [ngClass]="{'motif-active': currentactivetab === 'Audit'}" class="motif-text-link">
                                <motif-icon [src]="'/assets/icons/action/ic_history_24px.svg'"></motif-icon>
                                Audit Trail
                            </button>
                        </motif-tab-bar>
                    </div>

                    <div class="motif-col-xs-9 motif-col-lg-9">

                        <div class="motif-row motif-end-lg" *ngIf="currentactivetab == 'AuditAssignment'">
                            <!-- <div class="motif-col-xs-4 motif-col-md-2">
&nbsp;
                  </div> -->

                            <input motifInput id="Search" placeholder="Search.." type="text"
                                class="motif-col-xs-5 motif-col-lg-5" [(ngModel)]="ngSearchFilter"
                                (ngModelChange)="onFilterTextBoxChanged()" />
                            &nbsp;

                            <button size="large" *ngIf="ActionsCheck('Bulk Upload')"
                                class="motif-col-xs-4 motif-col-md-1" motifButton color="primary"
                                (click)="ShowFileUploadModel()">
                                <motif-icon class="Custom-motif-button-icon"
                                    [src]="'/assets/icons/file/ic_file_upload_24px.svg'"></motif-icon>Bulk Upload
                            </button> &nbsp;
                            <button size="large" *ngIf="ActionsCheck('Export')" class="motif-col-xs-4 motif-col-md-1"
                                motifButton color="primary" (click)="ExcelExportpopup()">
                                <motif-icon class="Custom-motif-button-icon"
                                    [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
                            </button> &nbsp;
                            <button size="large" *ngIf="ActionsCheck('Add')" class="motif-col-xs-4 motif-col-md-1"
                                motifButton color="primary" title="Add Audit Assignment"
                                (click)="Addauditassigment()">
                                <motif-icon class="Custom-motif-button-icon"
                                    [src]="'/assets/icons/content/ic_add_circle_24px.svg'"></motif-icon>Add Audit
                                Assignment
                            </button> &nbsp;
                            <button size="large" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary"
                                title="Update" (click)="onbtnclickMassUpdate()">
                                <motif-icon class="Custom-motif-button-icon"
                                    [src]="'/assets/icons/action/ic_update_24px.svg'"></motif-icon>Bulk Update
                            </button> &nbsp;

                            <!-- <button size="large" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary">
            <motif-icon [src]="'/assets/icons/action/ic_history_24px.svg'"></motif-icon>Audit Trail
          </button> -->
                        </div>
                    </div>
                </div>
                <div class="motif-row" *ngIf="currentactivetab == 'AuditAssignment'">
                    <div *ngIf="NgSearchFlag" class="motif-col-xs-4 motif-col-lg-2">
                        <motif-form-field>
                            <label motifLabel>Select Year</label>
                            <motif-select [(ngModel)]="selectedYear" (change)="externalYearFilterChanged($event)"
                                [multiple]="false" aria-label="function Form Field">
                                <motif-option *ngFor="let item of lstYear" [value]="item">
                                    {{item}}
                                </motif-option>
                            </motif-select>
                        </motif-form-field>

                    </div>
                    <div *ngIf="NgSearchFlag" class="motif-col-xs-4 motif-col-lg-2">
                        <motif-form-field *ngIf="NgSearchFlag">
                            <label motifLabel>Select Region</label>
                            <motif-select [(ngModel)]="RegionSelected" (change)="externalRegionFilterChanged($event)"
                                [multiple]="false" aria-label="function Form Field">
                                <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionName">
                                    {{item.eyregionName}}</motif-option>
                            </motif-select>
                        </motif-form-field>
                    </div>
                    <div *ngIf="NgSearchFlag" class="motif-col-xs-4 motif-col-lg-2">
                        <motif-form-field>
                            <label motifLabel>IR Office</label>
                            <motif-select [(ngModel)]="irofficeSelected"
                                (change)="externalIROfficeFilterChanged($event)" aria-label="IR office Form Field">
                                <motif-option *ngFor="let item of irRBofficeMasterData" [value]="item.irofficeName">
                                    {{item.irofficeName}}</motif-option>
                            </motif-select>

                        </motif-form-field>
                    </div>
                    <div *ngIf="NgSearchFlag" class="motif-col-xs-2 motif-col-lg-2 auditradiodiv">
                        <motif-form-field>
                          <motif-radio-group [(ngModel)]="BankSelected" (change)="GetAuditassignmentDetils()">
                              <div class="motif-row">
                                  <div class="motif-col-xs-6 motif-col-lg-6">
                                      <motif-radio-button value="allBanks" checked="checked" [title]="'Custom radio element title'">All Audits
                                      </motif-radio-button>
                                  </div>
                                  <div class="motif-col-xs-6 motif-col-lg-6">
                                      <motif-radio-button value="myBanks" [title]="'Custom radio element title'">My Audits
                                      </motif-radio-button>
                                  </div>
                              </div>
                          </motif-radio-group>
                        </motif-form-field>
                      </div>      
                    <div class="motif-col-xs-4 motif-col-lg-2 resetdiv">
                        <button size="large" motifButton color="primary" title="Reset" (click)="resetFIlters()">
                            <motif-icon class="Custom-motif-button-icon"
                                [src]="'/assets/icons/action/ic_autorenew_24px.svg'"></motif-icon>Reset
                        </button>

                    </div>
                </div>

                <div style="width: 100%;" [hidden]="currentactivetab != 'AuditAssignment'">
                    <div class="motif-table">
                        <div id="dvag-grid" class="motif-table-wrapper"
                            [ngClass]="{'motif-table-first-column-border': true}">
                            <ag-grid-angular [ngStyle]="Aggridstyle" [overlayLoadingTemplate]="overlayLoadingTemplate"
                                class="ag-theme-balham" [columnDefs]="ColumnDefs" rowSelection="multiple"
                                [rowData]="tableDataset" [modules]="modules" [animateRows]="true" [pagination]="true"
                                (gridReady)="handleGridReady($event)" (columnResized)="onColumnResized($event)"
                                (columnVisible)="onColumnVisible($event)" [paginationPageSize]=30
                                [frameworkComponents]="frameworkComponents"
                                [isExternalFilterPresent]="isExternalFilterPresent"
                                [doesExternalFilterPass]="doesExternalFilterPass"></ag-grid-angular>
                        </div>
                    </div>
                </div>
                <!-- <div style="width: 100%;" [hidden]="currentactivetab != 'AuditAssignment'">
          <div class="motif-table">
            <div id="dvag-grid" class="motif-table-wrapper" [ngClass]="{'motif-table-first-column-border': true}">
              <ag-grid-angular   [ngStyle]="Aggridstyle"
              class="ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="tableDataset" [modules]="modules"
              [frameworkComponents]="frameworkComponents" [pagination]="true" [paginationPageSize]=30
              >
              </ag-grid-angular>
          </div>
          </div>
      </div>  -->
                <div [hidden]="currentactivetab != 'Audit'">



                    <div class="motif-row motif-end-lg">

                        <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Timeline'}"
                            (click)="auditTrailActiveTab ='Timeline'" motifFab aria-label="floating-action-button"
                            size="small">
                            <motif-icon [src]="'./assets/icons/editor/ic_format_align_left_24px.svg'"></motif-icon>
                        </button>

                        &nbsp;

                        <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Grid'}"
                            (click)="auditTrailActiveTab ='Grid'" motifFab aria-label="floating-action-button"
                            size="small">
                            <motif-icon [src]="'./assets/icons/image/ic_grid_on_24px.svg'"></motif-icon>
                        </button>
                    </div>



                    <div *ngIf="auditTrailActiveTab =='Timeline'" class="tab2">
                        <div class="timeLineMain">
                            <div class="toggleGroup">
                                <ul class="list-inline">
                                    <li style="cursor:pointer" [ngClass]="{'active': adtinsertshow}"
                                        (click)="adtinsertshow=!adtinsertshow;audittrailfilter()">
                                        <span></span>
                                        <label style="cursor:pointer">Add</label>
                                    </li>
                                    <li style="cursor:pointer" [ngClass]="{'active': adtupdateshow}"
                                        (click)="adtupdateshow=!adtupdateshow;audittrailfilter()">
                                        <span></span>
                                        <label style="cursor:pointer">Update</label>
                                    </li>
                                    <li style="cursor:pointer" [ngClass]="{'active': adtdeleteshow}"
                                        (click)="adtdeleteshow=!adtdeleteshow;audittrailfilter()">
                                        <span></span>
                                        <label style="cursor:pointer">Delete</label>
                                    </li>
                                    <li style="cursor:pointer" [ngClass]="{'active': adtbulkinsertshow}"
                                        (click)="adtbulkinsertshow=!adtbulkinsertshow;audittrailfilter()">
                                        <span></span>
                                        <label style="cursor:pointer">Bulk Upload</label>
                                    </li>
                                </ul>
                            </div>
                            <div class="timeLineItem">
                                <ul style="min-height:800px" class="list-inline">
                                    <li *ngFor="let ad of AuditTrailDataset">
                                        <div class="dateTime">
                                            <!--September 14, 2020-->
                                            <span class="tlDate">{{ ad.dateTime | momentPipe :'LL'}} </span>
                                            <span class="tlTime"> {{ ad.dateTime | momentPipe:'LT'}} </span>
                                        </div>
                                        <div class="timelineMainItem" [ngClass]="
                         {
                         'insertItem': ad.action == 'Insert',
                         'updateItem': ad.action == 'Update',
                         'deleteItem': ad.action  == 'Delete',
                         'bulkInsertItem':ad.action  == 'Bulk Upload'

                         }">
                                            <span class="BoldHead1">{{ad.userName}}<a> ({{ad.email}})</a></span>
                                            <div class="content">
                                                <p *ngIf="ad.action  == 'Bulk Upload'">
                                                    <!-- <b>{{ad.rowCount}}</b> -->

                                                    Audit Assignment(s) has been uploaded.
                                                </p>
                                                <p *ngIf="ad.action  == 'Insert'">Audit Assignment
                                                    <span class="BoldHead">{{ad.formTitle}}</span> has been Created.
                                                </p>
                                                <p *ngIf="ad.action  == 'Delete'">Audit Assignment
                                                    <span class="BoldHead">{{ad.formTitle}}</span> has been deleted.
                                                </p>
                                                <p *ngIf="ad.action  == 'Update'">Audit Assignment
                                                    <span class="BoldHead">{{ad.formTitle}}</span> has been Updated.
                                                </p>

                                                <div *ngIf="ad.action  == 'Update'">


                                                    <span class="BoldHead" *ngIf="ad.newValue != null  &&
                          (
                            ad.newValue?.Classification !=undefined ||
                            ad.newValue?.ClientContact !=undefined ||
                            ad.newValue?.GroupScope !=undefined ||
                            ad.newValue?.FormStatus !=undefined ||
                            ad.newValue?.Comment !=undefined ||
                            ad.newValue?.EngagementPartnerId !=undefined ||
                            ad.newValue?.FinmaLeadAuditorId !=undefined ||
                            ad.newValue?.InternalAuditEngagementLeaderId !=undefined
                          )">Audit Assignment</span>
                                                    <ul *ngIf="ad.newValue != null">


                                                        <li *ngIf="ad.newValue?.Classification !=undefined">
                                                            Classification : {{ad.newValue?.Classification}} </li>

                                                        <li *ngIf="ad.newValue?.ClientContact !=undefined"> Client
                                                            Contact : {{ad.newValue?.ClientContact}} </li>

                                                        <li *ngIf="ad.newValue?.GroupScope !=undefined"> GroupScope :
                                                            {{ad.newValue?.GroupScope}} </li>
                                                        <li *ngIf="ad.newValue?.EngagementPartnerId !=undefined">
                                                            Engagement Partner :
                                                            {{ad.newValue?.EngagementPartnerId}} </li>



                                                        <!-- <li *ngIf="ad.newValue?.EngagementManagerId !=undefined">
                                                            Engagement Manager :
                                                            {{ad.newValue?.EngagementManagerId}} </li> -->
                                                        <li *ngIf="ad.newValue?.FinmaLeadAuditorId !=undefined">
                                                            FINMA Lead Auditor :
                                                            {{ad.newValue?.FinmaLeadAuditorId}} </li>

                                                        <li
                                                            *ngIf="ad.newValue?.InternalAuditEngagementLeaderId !=undefined">
                                                            Internal Audit Engagement Leader :
                                                            {{ad.newValue?.InternalAuditEngagementLeaderId}}
                                                        </li>

                                                        <li *ngIf="ad.newValue?.FormStatus !=undefined"> Form Status :
                                                            {{ad.newValue?.FormStatus}} </li>

                                                        <li *ngIf="ad.newValue?.Comment !=undefined"> Comment :
                                                            {{ad.newValue?.Comment}} </li>
                                                    </ul>



                                                    <span *ngIf="(ad.auditTypeRP != null &&
                                                    (
                                                      ad.auditTypeRP?.newValue?.NewAuditTypeRP !=undefined ||
                                                      ad.auditTypeRP?.newValue?.FinancialAuditLead !=undefined ||
                                                      ad.auditTypeRP?.newValue?.AuditPeriodStartDate !=undefined ||
                                                      ad.auditTypeRP?.newValue?.AuditPeriodEndDate !=undefined ||
                                                      ad.auditTypeRP?.newValue?.AuditExecutionStartDate !=undefined ||
                                                      ad.auditTypeRP?.newValue?.AuditExecutionEndDate !=undefined ||
                                                      ad.auditTypeRP?.newValue?.DecisiveDate !=undefined ||
                                                      ad.auditTypeRP?.newValue?.LatestSignoffDate !=undefined ||
                                                      ad.auditTypeRP?.newValue?.ActualSignoffDate !=undefined ||
                                                      ad.auditTypeRP?.newValue?.AnnouncementDelivery !=undefined ||
                                                      ad.auditTypeRP?.newValue?.ClientDataDue !=undefined || ad.auditTypeRP?.newValue?.DraftReport !=undefined ||ad.auditTypeRP?.newValue?.IaworkCompleted !=undefined ||
                                                      ad.auditTypeRP?.newValue?.IaworkPaperReviewDue !=undefined || ad.auditTypeRP?.newValue?.DraftReportToClientDue !=undefined || ad.auditTypeRP?.newValue?.ClientFeedbackDue !=undefined ||
                                                      ad.auditTypeRP?.newValue?.FinalReportToClientDue !=undefined ||ad.auditTypeRP?.newValue?.StatusRpid !=undefined || ad.auditTypeRP?.newValue?.FinAuditCoSign !=undefined
                                                    ))|| (ad.auditAGMDetails != null  &&
                                                    (
                                                        ad.auditAGMDetails?.newValue?.NewAuditTypeRP !=undefined ||
                                                      ad.auditAGMDetails?.newValue?.Date !=undefined ||
                                                      ad.auditAGMDetails?.newValue?.StartTime !=undefined ||
                                                      ad.auditAGMDetails?.newValue?.EndTime !=undefined ||
                                                      ad.auditAGMDetails?.newValue?.MeetingFormatId !=undefined ||
                                                      ad.auditAGMDetails?.newValue?.Location !=undefined ||
                                                      ad.auditAGMDetails?.newValue?.Eyrepresentative !=undefined ||
                                                      ad.auditAGMDetails?.newValue?.Notes !=undefined 
                                                    ))">RP</span>
                                                    <ul *ngIf="ad.auditTypeRP != null || ad.auditAGMDetails != null">
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.NewAuditTypeRP !=undefined">
                                                            {{ad.auditTypeRP?.newValue?.NewAuditTypeRP}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.FinancialAuditLead !=undefined">
                                                            Financial Audit Lead :
                                                            {{ad.auditTypeRP?.newValue?.FinancialAuditLead}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.AuditPeriodStartDate !=undefined">
                                                            Audit Period Start Date :
                                                            {{ad.auditTypeRP?.newValue?.AuditPeriodStartDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.AuditPeriodEndDate !=undefined">
                                                            Audit Period End Date :
                                                            {{ad.auditTypeRP?.newValue?.AuditPeriodEndDate | momentPipe
                                                            :'LL'}} </li>


                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.AuditExecutionStartDate !=undefined">
                                                            Audit Execution Start Date :
                                                            {{ad.auditTypeRP?.newValue?.AuditExecutionStartDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.AuditExecutionEndDate !=undefined">
                                                            Audit Execution End Date :
                                                            {{ad.auditTypeRP?.newValue?.AuditExecutionEndDate |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.DecisiveDate !=undefined">
                                                            Decisive Date :
                                                            {{ad.auditTypeRP?.newValue?.DecisiveDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.LatestSignoffDate !=undefined">
                                                            Latest Sign-off Date :
                                                            {{ad.auditTypeRP?.newValue?.LatestSignoffDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.ActualSignoffDate !=undefined">
                                                            Actual Sign-off Date :
                                                            {{ad.auditTypeRP?.newValue?.ActualSignoffDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.AnnouncementDelivery !=undefined">
                                                            Announcement Delivery :
                                                            {{ad.auditTypeRP?.newValue?.AnnouncementDelivery |
                                                            momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeRP?.newValue?.ClientDataDue !=undefined">
                                                            Client Data Due : {{ad.auditTypeRP?.newValue?.ClientDataDue
                                                            | momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeRP?.newValue?.DraftReport !=undefined">
                                                            Draft Report : {{ad.auditTypeRP?.newValue?.DraftReport |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.IaworkCompleted !=undefined">
                                                            IA work Completed :
                                                            {{ad.auditTypeRP?.newValue?.IaworkCompleted | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.IaworkPaperReviewDue !=undefined">
                                                            IA work Paper Review Due :
                                                            {{ad.auditTypeRP?.newValue?.IaworkPaperReviewDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.DraftReportToClientDue !=undefined">
                                                            Draft Report To Client Due :
                                                            {{ad.auditTypeRP?.newValue?.DraftReportToClientDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.ClientFeedbackDue !=undefined">
                                                            Client Feedback Due :
                                                            {{ad.auditTypeRP?.newValue?.ClientFeedbackDue | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeRP?.newValue?.FinalReportToClientDue !=undefined">
                                                            Final Report To Client Due :
                                                            {{ad.auditTypeRP?.newValue?.FinalReportToClientDue |
                                                            momentPipe :'LL'}} </li>

                                                        <li *ngIf="ad.auditTypeRP?.newValue?.StatusRpid !=undefined">
                                                            RP Status : {{ad.auditTypeRP?.newValue?.StatusRpid}} </li>
                                                        <li *ngIf="ad.auditTypeRP?.newValue?.FinAuditCoSign !=undefined">
                                                            Fin. Audit Co-Sign : {{ad.auditTypeRP?.newValue?.FinAuditCoSign}} </li>
                                                        <span class="BoldHead" *ngIf="ad.auditAGMDetails != null  &&
                                                        (
                                                            ad.auditAGMDetails?.newValue?.NewAuditTypeRP !=undefined ||
                                                          ad.auditAGMDetails?.newValue?.Date !=undefined ||
                                                          ad.auditAGMDetails?.newValue?.StartTime !=undefined ||
                                                          ad.auditAGMDetails?.newValue?.EndTime !=undefined ||
                                                          ad.auditAGMDetails?.newValue?.MeetingFormatId !=undefined ||
                                                          ad.auditAGMDetails?.newValue?.Location !=undefined ||
                                                          ad.auditAGMDetails?.newValue?.Eyrepresentative !=undefined ||
                                                          ad.auditAGMDetails?.newValue?.Notes !=undefined )">AGM Details</span>
                                                            <li
                                                            *ngIf="ad.auditAGMDetails?.newValue?.NewAuditTypeRP !=undefined">
                                                            {{ad.auditAGMDetails?.newValue?.NewAuditTypeRP}} </li>
                                                        <li
                                                            *ngIf="ad.auditAGMDetails?.newValue?.MeetingFormatId !=undefined">
                                                            Meeting Format :
                                                            {{ad.auditAGMDetails?.newValue?.MeetingFormatId}} </li>
                                                        <li *ngIf="ad.auditAGMDetails?.newValue?.Date !=undefined">
                                                            Date : {{ad.auditAGMDetails?.newValue?.Date| momentPipe :'LL'}}
                                                        </li>
                                                        <li *ngIf="ad.auditAGMDetails?.newValue?.StartTime !=undefined">
                                                            Start Time : {{ad.auditAGMDetails?.newValue?.StartTime}} </li>
                                                        <li *ngIf="ad.auditAGMDetails?.newValue?.EndTime !=undefined">
                                                            End Time : {{ad.auditAGMDetails?.newValue?.EndTime}} </li>
                                                        <li *ngIf="ad.auditAGMDetails?.newValue?.Location !=undefined">
                                                            Location : {{ad.auditAGMDetails?.newValue?.Location}} </li>
                                                        <li
                                                            *ngIf="ad.auditAGMDetails?.newValue?.Eyrepresentative !=undefined">
                                                            EY Representative :
                                                            {{ad.auditAGMDetails?.newValue?.Eyrepresentative}} </li>
                                                        <li *ngIf="ad.auditAGMDetails?.newValue?.Notes !=undefined">
                                                            Notes : {{ad.auditAGMDetails?.newValue?.Notes}} </li>


                                                    </ul>


                                                    <span class="BoldHead" *ngIf="ad.auditTypeKP != null  &&
                          (
                            ad.auditTypeKP?.newValue?.NewAuditTypeKP !=undefined ||
                            ad.auditTypeKP?.newValue?.FinancialAuditLead !=undefined ||
                            ad.auditTypeKP?.newValue?.AuditPeriodStartDate !=undefined ||
                            ad.auditTypeKP?.newValue?.AuditPeriodEndDate !=undefined ||
                            ad.auditTypeKP?.newValue?.AuditExecutionStartDate !=undefined ||
                            ad.auditTypeKP?.newValue?.AuditExecutionEndDate !=undefined ||
                            ad.auditTypeKP?.newValue?.AnnouncementDelivery !=undefined ||
                            ad.auditTypeKP?.newValue?.ClientDataDue !=undefined || ad.auditTypeKP?.newValue?.DraftReport !=undefined ||ad.auditTypeKP?.newValue?.IaworkCompleted !=undefined ||
                            ad.auditTypeKP?.newValue?.IaworkPaperReviewDue !=undefined || ad.auditTypeKP?.newValue?.DraftReportToClientDue !=undefined || ad.auditTypeKP?.newValue?.ClientFeedbackDue !=undefined ||
                            ad.auditTypeKP?.newValue?.FinalReportToClientDue !=undefined ||ad.auditTypeKP?.newValue?.StatusKpid !=undefined || ad.auditTypeKP?.newValue?.KpEngagementManager !=undefined
                          )">KP</span>
                                                    <ul *ngIf="ad.auditTypeKP != null">

                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.NewAuditTypeKP !=undefined">
                                                            {{ad.auditTypeKP?.newValue?.NewAuditTypeKP}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.FinancialAuditLead !=undefined">
                                                            Financial Audit Lead :
                                                            {{ad.auditTypeKP?.newValue?.FinancialAuditLead}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.AuditPeriodStartDate !=undefined">
                                                            Audit Period Start Date :
                                                            {{ad.auditTypeKP?.newValue?.AuditPeriodStartDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.AuditPeriodEndDate !=undefined">
                                                            Audit Period End Date :
                                                            {{ad.auditTypeKP?.newValue?.AuditPeriodEndDate | momentPipe
                                                            :'LL'}} </li>


                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.AuditExecutionStartDate !=undefined">
                                                            Audit Execution Start Date :
                                                            {{ad.auditTypeKP?.newValue?.AuditExecutionStartDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.AuditExecutionEndDate !=undefined">
                                                            Audit Execution End Date :
                                                            {{ad.auditTypeKP?.newValue?.AuditExecutionEndDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.AnnouncementDelivery !=undefined">
                                                            Announcement Delivery :
                                                            {{ad.auditTypeKP?.newValue?.AnnouncementDelivery |
                                                            momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeKP?.newValue?.ClientDataDue !=undefined">
                                                            Client Data Due : {{ad.auditTypeKP?.newValue?.ClientDataDue
                                                            | momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeKP?.newValue?.DraftReport !=undefined">
                                                            Draft Report : {{ad.auditTypeKP?.newValue?.DraftReport |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.IaworkCompleted !=undefined">
                                                            IA work Completed :
                                                            {{ad.auditTypeKP?.newValue?.IaworkCompleted | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.IaworkPaperReviewDue !=undefined">
                                                            IA work Paper Review Due :
                                                            {{ad.auditTypeKP?.newValue?.IaworkPaperReviewDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.DraftReportToClientDue !=undefined">
                                                            Draft Report To Client Due :
                                                            {{ad.auditTypeKP?.newValue?.DraftReportToClientDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.ClientFeedbackDue !=undefined">
                                                            Client Feedback Due :
                                                            {{ad.auditTypeKP?.newValue?.ClientFeedbackDue | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeKP?.newValue?.FinalReportToClientDue !=undefined">
                                                            Final Report To Client Due :
                                                            {{ad.auditTypeKP?.newValue?.FinalReportToClientDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeKP?.newValue?.StatusKpid !=undefined">
                                                            KP Status : {{ad.auditTypeKP?.newValue?.StatusKpid}} </li>
                                                        <li *ngIf="ad.auditTypeKP?.newValue?.KpEngagementManager !=undefined">
                                                            KP Engagement Manager : {{ad.auditTypeKP?.newValue?.KpEngagementManager}} </li>

                                                    </ul>


                                                    <span class="BoldHead" *ngIf="ad.auditTypeAP != null &&
                                                    (
                                                      ad.auditTypeAP?.newValue?.NewAuditTypeAP !=undefined ||
                                                      ad.auditTypeAP?.newValue?.FinancialAuditLead !=undefined ||
                                                      ad.auditTypeAP?.newValue?.AuditPeriodStartDate !=undefined ||
                                                      ad.auditTypeAP?.newValue?.AuditPeriodEndDate !=undefined ||
                                                      ad.auditTypeAP?.newValue?.AuditExecutionStartDate !=undefined ||
                                                      ad.auditTypeAP?.newValue?.AuditExecutionEndDate !=undefined ||
                                                      ad.auditTypeAP?.newValue?.AnnouncementDelivery !=undefined ||
                                                      ad.auditTypeAP?.newValue?.ClientDataDue !=undefined || ad.auditTypeAP?.newValue?.DraftReport !=undefined ||ad.auditTypeAP?.newValue?.IaworkCompleted !=undefined ||
                                                      ad.auditTypeAP?.newValue?.IaworkPaperReviewDue !=undefined || ad.auditTypeAP?.newValue?.DraftReportToClientDue !=undefined || ad.auditTypeAP?.newValue?.ClientFeedbackDue !=undefined ||
                                                      ad.auditTypeAP?.newValue?.FinalReportToClientDue !=undefined ||ad.auditTypeAP?.newValue?.StatusRpid !=undefined || ad.auditTypeAP?.newValue?.RegAuditCoSign !=undefined
                                                    )">AP</span>
                                                    <ul *ngIf="ad.auditTypeAP != null">
                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.NewAuditTypeAP !=undefined">
                                                            {{ad.auditTypeAP?.newValue?.NewAuditTypeAP}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.FinancialAuditLead !=undefined">
                                                            Regulatory Audit Lead :
                                                            {{ad.auditTypeAP?.newValue?.FinancialAuditLead}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.AuditPeriodStartDate !=undefined">
                                                            Audit Period Start Date :
                                                            {{ad.auditTypeAP?.newValue?.AuditPeriodStartDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.AuditPeriodEndDate !=undefined">
                                                            Audit Period End Date :
                                                            {{ad.auditTypeAP?.newValue?.AuditPeriodEndDate | momentPipe
                                                            :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.AuditExecutionStartDate !=undefined">
                                                            Audit Execution Start Date :
                                                            {{ad.auditTypeAP?.newValue?.AuditExecutionStartDate |
                                                            momentPipe :'LL' }} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.AuditExecutionEndDate !=undefined">
                                                            Audit Execution End Date :
                                                            {{ad.auditTypeAP?.newValue?.AuditExecutionEndDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.AnnouncementDelivery !=undefined">
                                                            Announcement Delivery :
                                                            {{ad.auditTypeAP?.newValue?.AnnouncementDelivery |
                                                            momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeAP?.newValue?.ClientDataDue !=undefined">
                                                            Client Data Due : {{ad.auditTypeAP?.newValue?.ClientDataDue
                                                            | momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeAP?.newValue?.DraftReport !=undefined">
                                                            Draft Report : {{ad.auditTypeAP?.newValue?.DraftReport |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.IaworkCompleted !=undefined">
                                                            IA work Completed :
                                                            {{ad.auditTypeAP?.newValue?.IaworkCompleted | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.IaworkPaperReviewDue !=undefined">
                                                            IA work Paper Review Due :
                                                            {{ad.auditTypeAP?.newValue?.IaworkPaperReviewDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.DraftReportToClientDue !=undefined">
                                                            Draft Report To Client Due :
                                                            {{ad.auditTypeAP?.newValue?.DraftReportToClientDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.ClientFeedbackDue !=undefined">
                                                            Client Feedback Due :
                                                            {{ad.auditTypeAP?.newValue?.ClientFeedbackDue | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeAP?.newValue?.FinalReportToClientDue !=undefined">
                                                            Final Report To Client Due :
                                                            {{ad.auditTypeAP?.newValue?.FinalReportToClientDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeAP?.newValue?.StatusApid !=undefined">
                                                            AP Status : {{ad.auditTypeAP?.newValue?.StatusApid}} </li>
                                                        <li *ngIf="ad.auditTypeAP?.newValue?.RegAuditCoSign !=undefined">
                                                            Reg. Audit Co-Sign : {{ad.auditTypeAP?.newValue?.RegAuditCoSign}} </li>

                                                    </ul>

                                                    <span class="BoldHead" *ngIf="ad.auditTypeOther != null&&
                                                    (                                                      ad.auditTypeOther?.newValue?.NewAuditTypeOT !=undefined ||
                                                      ad.auditTypeOther?.newValue?.FinancialAuditLead !=undefined ||
                                                      ad.auditTypeOther?.newValue?.AuditPeriodStartDate !=undefined ||
                                                      ad.auditTypeOther?.newValue?.AuditPeriodEndDate !=undefined ||
                                                      ad.auditTypeOther?.newValue?.AuditExecutionStartDate !=undefined ||
                                                      ad.auditTypeOther?.newValue?.RegionalLeadPartnerId !=undefined ||
                                                      ad.auditTypeOther?.newValue?.AssignedLeadAuditorId !=undefined ||
                                                      ad.auditTypeOther?.newValue?.AuditExecutionEndDate !=undefined ||
                                                      ad.auditTypeOther?.newValue?.AnnouncementDelivery !=undefined ||
                                                      ad.auditTypeOther?.newValue?.ClientDataDue !=undefined || ad.auditTypeOther?.newValue?.DraftReport !=undefined ||ad.auditTypeOther?.newValue?.IaworkCompleted !=undefined ||
                                                      ad.auditTypeOther?.newValue?.IaworkPaperReviewDue !=undefined || ad.auditTypeOther?.newValue?.DraftReportToClientDue !=undefined || ad.auditTypeOther?.newValue?.ClientFeedbackDue !=undefined ||
                                                      ad.auditTypeOther?.newValue?.FinalReportToClientDue !=undefined ||ad.auditTypeOther?.newValue?.StatusRpid !=undefined
                                                    )">Other</span>

                                                    <ul *ngIf="ad.auditTypeOther != null">
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.NewAuditTypeOT !=undefined">
                                                            {{ad.auditTypeOther?.newValue?.NewAuditTypeOT}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.FinancialAuditLead !=undefined">
                                                            Financial Audit Lead :
                                                            {{ad.auditTypeOther?.newValue?.FinancialAuditLead}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.AuditPeriodStartDate !=undefined">
                                                            Audit Period Start Date :
                                                            {{ad.auditTypeOther?.newValue?.AuditPeriodStartDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.AuditPeriodEndDate !=undefined">
                                                            Audit Period End Date :
                                                            {{ad.auditTypeOther?.newValue?.AuditPeriodEndDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.RegionalLeadPartnerId !=undefined">
                                                            OT Partner :
                                                            {{ad.auditTypeOther?.newValue?.RegionalLeadPartnerId}} </li>



                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.AssignedLeadAuditorId !=undefined">
                                                            OT Engaement Manager :
                                                            {{ad.auditTypeOther?.newValue?.AssignedLeadAuditorId}} </li>


                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.AuditExecutionStartDate !=undefined">
                                                            Audit Execution Start Date :
                                                            {{ad.auditTypeOther?.newValue?.AuditExecutionStartDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.AuditExecutionEndDate !=undefined">
                                                            Audit Execution End Date :
                                                            {{ad.auditTypeOther?.newValue?.AuditExecutionEndDate |
                                                            momentPipe :'LL'}} </li>

                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.AnnouncementDelivery !=undefined">
                                                            Announcement Delivery :
                                                            {{ad.auditTypeOther?.newValue?.AnnouncementDelivery |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.ClientDataDue !=undefined">
                                                            Client Data Due :
                                                            {{ad.auditTypeOther?.newValue?.ClientDataDue | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.DraftReport !=undefined">
                                                            Draft Report : {{ad.auditTypeOther?.newValue?.DraftReport |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.IaworkCompleted !=undefined">
                                                            IA work Completed :
                                                            {{ad.auditTypeOther?.newValue?.IaworkCompleted | momentPipe
                                                            :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.IaworkPaperReviewDue !=undefined">
                                                            IA work Paper Review Due :
                                                            {{ad.auditTypeOther?.newValue?.IaworkPaperReviewDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.DraftReportToClientDue !=undefined">
                                                            Draft Report To Client Due :
                                                            {{ad.auditTypeOther?.newValue?.DraftReportToClientDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.ClientFeedbackDue !=undefined">
                                                            Client Feedback Due :
                                                            {{ad.auditTypeOther?.newValue?.ClientFeedbackDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li
                                                            *ngIf="ad.auditTypeOther?.newValue?.FinalReportToClientDue !=undefined">
                                                            Final Report To Client Due :
                                                            {{ad.auditTypeOther?.newValue?.FinalReportToClientDue |
                                                            momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeOther?.newValue?.StatusOtid !=undefined">
                                                            OT Status : {{ad.auditTypeOther?.newValue?.StatusOtid}}
                                                        </li>

                                                    </ul>


                                                    <span class="BoldHead" *ngIf="ad.auditTypeIA != null&&
                                                    (
                                                        ad.auditTypeIA?.newValue?.NewAuditTypeIA  !=undefined ||
                                                        ad.auditTypeIA?.newValue?.Iaaudit1 !=undefined ||
                                                      ad.auditTypeIA?.newValue?.Iaaudit1ExecutionStartDate !=undefined ||
                                                      ad.auditTypeIA?.newValue?.Iaaudit2 !=undefined ||
                                                      ad.auditTypeIA?.newValue?.Iaaudit2ExecutionStartDate !=undefined ||
                                                      ad.auditTypeIA?.newValue?.Iaaudit2ExecutionEndDate !=undefined ||
                                                      ad.auditTypeIA?.newValue?.Iaaudit1ExecutionEndDate !=undefined 
                                                    )">IR</span>

                                                    <ul *ngIf="ad.auditTypeIA != null">
                                                        <li
                                                            *ngIf="ad.auditTypeIA?.newValue?.NewAuditTypeIA !=undefined">
                                                            {{ad.auditTypeIA?.newValue?.NewAuditTypeIA}} </li>

                                                        <li *ngIf="ad.auditTypeIA?.newValue?.Iaaudit1 !=undefined">
                                                          IA Audit 1 : {{ad.auditTypeIA?.newValue?.Iaaudit1}} </li>

                                                        <li *ngIf="ad.auditTypeIA?.newValue?.Iaaudit1ExecutionStartDate !=undefined">
                                                           Audit 1 Execution Startdate : {{ad.auditTypeIA?.newValue?.Iaaudit1ExecutionStartDate | momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeIA?.newValue?.Iaaudit1ExecutionEndDate !=undefined">
                                                            Audit 1 Execution Enddate :  {{ad.auditTypeIA?.newValue?.Iaaudit1ExecutionEndDate | momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeIA?.newValue?.Iaaudit2 !=undefined">
                                                           IA Audit 2 : {{ad.auditTypeIA?.newValue?.Iaaudit2}} </li>
                                                        <li *ngIf="ad.auditTypeIA?.newValue?.Iaaudit2ExecutionStartDate !=undefined">
                                                            Audit 2 Execution Startdate:  {{ad.auditTypeIA?.newValue?.Iaaudit2ExecutionStartDate | momentPipe :'LL'}} </li>
                                                        <li *ngIf="ad.auditTypeIA?.newValue?.Iaaudit2ExecutionEndDate !=undefined">
                                                            Audit 2 Execution Enddate   {{ad.auditTypeIA?.newValue?.Iaaudit2ExecutionEndDate | momentPipe :'LL'}} </li>

                                                    </ul>




                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="auditTrailActiveTab=='Grid'" class="tab2">

                        <ag-grid-angular [frameworkComponents]="auditframeworkComponents"
                            (gridReady)="handleauditGridReady($event)" [ngStyle]="style" [rowSelection]="rowSelection"
                            [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-material motif-ag-grid"
                            [columnDefs]="AuditAssignmentColumnDefs" [rowData]="AuditAssignmentAuditDataSet"
                            [modules]="modules" [animateRows]="true" [pagination]="true" [paginationPageSize]=30>
                        </ag-grid-angular>

                    </div>


                </div>
                <div>

                </div>
            </div>
            <app-add-update-audit-assignment [Params]="EditParams" [auditassign]="!auditassign"
                [advanceUpdate]="advanceUpdate" [basicUpdate]="basicUpdate" (BackEventEmitter)="onBack($event)" *ngIf="AddUpdateFlag">
            </app-add-update-audit-assignment>
            <app-archive-audit-assignment [Params]="EditParams" [auditassign]="!auditassign"
                [advanceUpdate]="advanceUpdate" [basicUpdate]="basicUpdate" (BackEventEmitter)="onBack($event)" *ngIf="ArchiveFlag">
            </app-archive-audit-assignment>
            <!-- <app-disabled-audit-assignment [Params]="EditParams" (BackEventEmitter)="onBack($event)" -->
            <!-- *ngIf="diasabledEditFlag"></app-disabled-audit-assignment> -->

        </div>

        <!-- FileUpload Model Start-->
        <motif-modal [show]="showFileUploadModel" (close)="showFileUploadModel = false">
            <motif-modal-header>
                Upload
            </motif-modal-header>
            <motif-modal-body>



                <div *ngIf="showFileUploadModel" class="motif-container">

                    <div class="motif-row motif-end-lg">
                        <button style="margin-bottom:8px" size="large" class="motif-col-xs-4 motif-col-md-2"
                            (click)="TempleateExport()" motifButton color="primary">
                            <motif-icon [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Download
                            Template
                        </button>


                    </div>

                    <motif-form-field>
                        <motif-file-upload [disabled]="Uploader.queue.length > 0 " (change)="fileChange($event)"
                            [uploader]="Uploader" [multiple]="false">
                            Drop files here or click to upload
                        </motif-file-upload>
                    </motif-form-field>

                    <motif-uploader (cancel)="clearFiles()" *ngFor="let item of Uploader?.queue"
                        fileName="{{item.file.name}}" progressValue="100" fileSize="" progressLabel=""
                        errorMessage="There was a problem while uploading the file, please upload valid file"
                        [error]="uploadfileerror">
                    </motif-uploader>


                </div>


            </motif-modal-body>
            <motif-modal-footer>
                <button motifButton color="primary" [disabled]="Uploader.queue.length == 0 "
                    (click)="UploadData()">Confirm</button>
                <button motifButton color="secondary" (click)="showFileUploadModel = false">Cancel</button>
            </motif-modal-footer>
        </motif-modal>

        <!--- FileUpload Model End-->
        <!-- Toster and loader start -->
        <motif-toast [type]="toasttype"  [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
            {{toastmessage}}</motif-toast>
        <!-- Toster and loader end -->

        <!-- FileUpload validation modal start-->

        <motif-modal [show]="Validationsmodalflag" (close)="Validationsmodalflag = false">
            <motif-modal-header>
                Incomplete data found in the following fields, please correct and upload again.

            </motif-modal-header>
            <motif-modal-body>

                <motif-accordion type="editorial" id="example-2" *ngFor="let item of invalidarryKeys">
                    <motif-accordion-header>
                        {{item}}
                    </motif-accordion-header>

                    <motif-accordion-content>
                        <ul>
                            <li *ngFor="let invaliditem of validationdata[item].split(',')">{{invaliditem}}</li>
                        </ul>
                    </motif-accordion-content>
                </motif-accordion>

            </motif-modal-body>
            <motif-modal-footer>

            </motif-modal-footer>
        </motif-modal>
        <!-- FileUpload validation model end -->

        <!--excel Export modal start-->
        <motif-modal [show]="ExcelExportmodalflag" (close)="ExcelExportmodalflag = false">
            <motif-modal-header>
                Export To Excel

            </motif-modal-header>
            <motif-modal-body>

                <form [formGroup]="ExcelToexportForm">
                    <motif-form-field>
                        <motif-radio-group formControlName="radioexceltype" (change)="ReportOptionChange()">
                            <div class="motif-row">
                                <div class="motif-col-xs-4 motif-col-lg-2">
                                    <motif-radio-button value="all" [title]="'Custom radio element title'">ALL
                                    </motif-radio-button>
                                </div>
                                <div class="motif-col-xs-4 motif-col-lg-2">
                                    <motif-radio-button value="AGM" [title]="'Custom radio element title'">AGM
                                    </motif-radio-button>
                                </div>
                                <div class="motif-col-xs-4 motif-col-lg-2 REGauditradioclass">
                                    <motif-radio-button value="RegulatoryAudit" [title]="'Custom radio element title'">Regulatory Audit
                                    </motif-radio-button>
                                </div>
                                <div class="motif-col-xs-4 motif-col-lg-2">
                                    <motif-radio-button value="LoanAudit" [title]="'Custom radio element title'">Loan Audit
                                    </motif-radio-button>
                                </div>
                                <div class="motif-col-xs-4 motif-col-lg-2 REGauditradioclass">
                                    <motif-radio-button value="FinancialAudit" [title]="'Custom radio element title'">Financial Audit
                                    </motif-radio-button>
                                </div>
                                <!-- <div class="motif-col-xs-4 motif-col-lg-2">
                          <motif-radio-button value="GIS" [title]="'Custom radio element title'">GIS</motif-radio-button>
                      </div>
                      <div class="motif-col-xs-4 motif-col-lg-2">
                          <motif-radio-button value="FINMA" [title]="'Custom radio element title'">FINMA</motif-radio-button>
                      </div>
                      <div class="motif-col-xs-4 motif-col-lg-2">
                          <motif-radio-button value="FAOA" [title]="'Custom radio element title'">FAOA</motif-radio-button>
                      </div> -->
                                <div class="motif-col-xs-4 motif-col-lg-2">
                                    <motif-radio-button value="Custom" [title]="'Custom radio element title'">Custom
                                    </motif-radio-button>
                                </div>
                            </div>
                        </motif-radio-group>
                    </motif-form-field>

                    <div class="motif-row">
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkBankCode">Bank Code</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientShortEntityName">Client Short Entity Name
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkBankCodeNumeric">Bank Code(Numeric)
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFormTitle">Form Title</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="motif-row">
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditYear">Audit Year</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClassification">Classification</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkGroupScope">Group Scope</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientContacts">Client Contacts</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="motif-row">
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientEntityName">Client Entity Name
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkCanton">Canton</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>


                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkLocation">Location</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkBankSize">Bank Size</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="motif-row">
                        <!-- <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkEYOffice">EY Office</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div> -->
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkEYRegion">EY Region</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIRRBOffice">IR Office</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkReportingLanguage">Reporting Language
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinancialAuditLeadRP">Financial Audit Lead - RP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="motif-row">
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditPeriodStartDateRP">Audit Period Start Date
                                        - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditPeriodEndDateRP">Audit Period End Date - RP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkRegionalLeadPartnerRP">Engagement Partner -
                                        RP</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <!-- <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAssignedLeadAuditorRP">Engagement Manager -
                                        RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div> -->
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkfinAuditCoSign">Fin. Audit Co-Sign
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="motif-row">

                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkInternalAuditEngagementLeaderRP">Internal Audit
                                        Engagement Leader - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionStartDateRP">Audit Execution Start
                                        Date - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionEndDateRP">Audit Execution End
                                        Date - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDecisiveDate">Decisive Date - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkLatestSignoffDate">Latest Sign-off Date - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkActualSignoffDate">Actual Sign-off Date - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAnnouncementdeliveryRP">Announcement delivery -
                                        RP</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientdatadueRP">Client data due - RP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">

                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreportRP">Draft report - RP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkcompletedRP">IA work completed - RP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkpaperreviewdueRP">IA work paper review due
                                        - RP</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreporttoclientdueRP">Draft report to client
                                        due - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientfeedbackdueRP">Client feedback due - RP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinalreporttoclientdueRP">Final report to client
                                        due - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditIdcodeRP">Audit Idcode RP</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkStatusRP">Status - RP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>


                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkMeetingFormat">Meeting Format</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAGMDate">Date</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAGMStartTime">Start Time</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAGMEndTime">End Time
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAGMLocation">AGM Location
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkEYRepresentative">EY Representative
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAGMNotes">Notes
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinancialAuditLeadKP">Financial Audit Lead - KP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">

                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkRegionalLeadPartnerKP">Engagement Partner -
                                        KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <!-- <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAssignedLeadAuditorKP">Engagement Manager -
                                        KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div> -->
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkkpEngagementManager">KP Engagement Manager
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkInternalAuditEngagementLeaderKP">Internal Audit
                                        Engagement Leader - KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionStartDateKP">Audit Execution Start
                                        Date - KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionEndDateKP">Audit Execution End
                                        Date - KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAnnouncementdeliveryKP">Announcement delivery -
                                        KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientdatadueKP">Client data due - KP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreportKP">Draft report - KP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkcompletedKP">IA work completed - KP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkpaperreviewdueKP">IA work paper review due
                                        - KP</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreporttoclientdueKP">Draft report to client
                                        due - KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientfeedbackdueKP">Client feedback due - KP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinalreporttoclientdueKP">Final report to client
                                        due - KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditIdcodeKP">Audit Idcode KP</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkStatusKP">Status - KP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinancialAuditLeadAP">Regulatory Audit Lead - AP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditPeriodStartDateAP">Audit Period Start Date
                                        - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditPeriodEndDateAP">Audit Period End Date - AP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAssignedLeadAuditorAP">FINMA Lead Auditor -
                                        AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <!-- <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkRegionalLeadPartnerAP">Engagement Manager -
                                        AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div> -->
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkregAuditCoSign">REG Audit Co-Sign
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkInternalAuditEngagementLeaderAP">Internal Audit
                                        Engagement Leader - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionStartDateAP">Audit Execution Start
                                        Date - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionEndDateAP">Audit Execution End
                                        Date - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAnnouncementdeliveryAP">Announcement delivery -
                                        AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientdatadueAP">Client data due - AP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreportAP">Draft report - AP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkcompletedAP">IA work completed - AP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkpaperreviewdueAP">IA work paper review due
                                        - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreporttoclientdueAP">Draft report to client
                                        due - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientfeedbackdueAP">Client feedback due - AP
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinalreporttoclientdueAP">Final report to client
                                        due - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditIdcodeAP">Audit Idcode AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                       
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkStatusAP">Status - AP</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinancialAuditLeadOT">Financial Audit Lead - OT
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditPeriodStartDateOT">Audit Period Start Date
                                        - OT</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditPeriodEndDateOT">Audit Period End Date - OT
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkRegionalLeadPartnerOT">Partner -
                                        OT</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAssignedLeadAuditorOT">Engaement Manager -
                                        OT</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionStartDateOT">Audit Execution Start
                                        Date - OT</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditExecutionEndDateOT">Audit Execution End
                                        Date - OT</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAnnouncementdeliveryOT">Announcement delivery -
                                        OT</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientdatadueOT">Client data due - OT
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreportOT">Draft report - OT
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">

                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkcompletedOT">IA work completed - OT
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAworkpaperreviewdueOT">IA work paper review due
                                        - OT</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkDraftreporttoclientdueOT">Draft report to client
                                        due - OT</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkClientfeedbackdueOT">Client feedback due - OT
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFinalreporttoclientdueOT">Final report to client
                                        due - OT</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkAuditIdcodeOT">Audit Idcode OT</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkStatusOT">Status OT</motif-checkbox>
                                </motif-form-field>
                            </div>

                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkComment">Comment</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkFormStatus">Form Status</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAAudit1">IA Audit 1</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAAudit1StartDate">IA Audit 1 Start Date
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAAudit1EndDate">IA Audit 1 End Date
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="motif-row">
                        
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAAudit2">IA Audit 2</motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAAudit2StartDate">IA Audit 2 Start Date
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-col-xs-1">
                            <div>
                                <motif-form-field>
                                    <motif-checkbox formControlName="chkIAAudit2EndDate">IA Audit 2 End Date
                                    </motif-checkbox>
                                </motif-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </motif-modal-body>
            <motif-modal-footer>
                <button motifButton color="primary" (click)="Excelexport(ExcelToexportForm)"
                    type="submit">Export</button>
                <button motifButton color="secondary" (click)="ExcelExportmodalflag = false">Cancel</button>
            </motif-modal-footer>
        </motif-modal>
    </div>
</div>
<!--excel export modal end-->
<motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
    <motif-modal-header>
        Delete Audit Assignment
    </motif-modal-header>
    <motif-modal-body>
        <p>
            Are you sure, you want to delete audit assignment?
        </p>
    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="DeleteData()">Confirm</button>
        <button motifButton color="secondary" (click)="deletemodalflag = false">Cancel</button>
    </motif-modal-footer>
</motif-modal>
<motif-modal class="CustomHeight" [show]="MassUpdateFlag" (close)="MassUpdateFlag = false">
    <motif-modal-header>
        Bulk Update
    </motif-modal-header>
    <motif-modal-body>



        <form [formGroup]="MassUpdateForm">
            <div class="motif-row">
                <div class="motif-col-xs-2">
                    <motif-form-field>
                        <label motifLabel>Form Status</label>
                        <motif-select formControlName="formstatus" class="ClienentitytModalselect"
                            aria-label="formstatus">
                            <motif-option *ngFor="let item of lstFormStatus" [value]="item">{{item}}</motif-option>
                        </motif-select>
                        <motif-error *ngIf="
                        MassUpdateForm.get('formstatus').hasError('required') &&
                        MassUpdateForm.get('formstatus').touched
                          ">Please select form status!</motif-error>
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-2">
                    <div>
                        <motif-form-field>
                            <label motifLabel>Comment</label>
                            <input motifInput type="text" aria-label="Comment Form Field" formControlName="comment" />
                        </motif-form-field>

                    </div>
                </div>
            </div>

        </form>

    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="MassUpdateAssignmentData(MassUpdateForm)">Update</button>
        <button motifButton color="secondary" (click)="MassUpdateFlag = false">Cancel</button>
    </motif-modal-footer>
</motif-modal>