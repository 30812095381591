<div class="playbook-main ng-star-inserted">
  <div class="motif-row" style="margin-bottom:20px">
    <div class="motif-col-xs-4 motif-col-lg-3">
      <h3 class="motif-h3 motif-col-xs-4 motif-col-md-10">Holiday Master</h3>
    </div>


    <div class="motif-col-xs-8 motif-col-lg-9">

      <div class="motif-row motif-end-lg" style="padding-right: 10px;">

        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field class="holidayMasButAlin">
            <label motifLabel>Year :</label>
            <motif-select [(ngModel)]="selectedYear" class="ClienentitytModalselect" (change)="LoadHolidays($event)"
              aria-label="Location Form Field">
              <motif-option *ngFor="let item of lstYear" [value]="item">
                {{item}}
              </motif-option>
            </motif-select>
          </motif-form-field>
        </div>


        <button style="margin-right: 5px;" motifButton class="motif-col-xs-4 motif-col-md-1" color="primary"
          *ngIf="!hasholidays" (click)="GenerateHolidays()">Generate Holidays</button>

        <button size="large" motifButton class="motif-col-xs-4 motif-col-md-1" *ngIf="hasholidays" color="primary"
          (click)="AddnewHoliday()">Add New Holiday</button>
        &nbsp;
        <button size="large" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary"
          (click)="ShowFileUploadModel()">
          <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_upload_24px.svg'">
          </motif-icon>Bulk Upload
        </button> &nbsp;
        <button size="large" (click)="ExportExcel()" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary">
          <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'">
          </motif-icon>Export To Excel
        </button>
      </div>
    </div>
  </div>


  <div style="width: 100%;">
    <div class="motif-table">
      <div id="dvag-grid" class="motif-table-wrapper" [ngClass]="{          'motif-table-first-column-border': true }">
        <ag-grid-angular [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate"
          class="csmotifaggrid ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="tableDataset"
          [modules]="modules" (gridReady)="handleGridReady($event)" [animateRows]="true" [pagination]="true"
          [paginationPageSize]=30 [frameworkComponents]="frameworkComponents">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
<motif-modal [show]="show2" (close)="closeModal()" [slideout]="true">
  <motif-modal-header>
    Add Holiday
  </motif-modal-header>
  <motif-modal-body>
    <form [formGroup]="form" novalidate>
      <div class="motif-modal-body-holiday">
        <motif-form-field>
          <label motifLabel aria-disabled="false">Name</label>
          <input motifInput [disabled]="isfixeddate" type="text" aria-label="Input Field" required
            formControlName="holidayName" />
          <motif-error *ngIf="form.get('holidayName').hasError('required') && form.get('holidayName').touched">Please
            enter holiday name</motif-error>
        </motif-form-field>
        <div *ngIf="show2">
          <motif-form-field>
            <label motifLabel>Canton</label>
            <motif-select [multiple]="true" formControlName="canton" class="ClienentitytModalselect"
              aria-label="Canton Form Field" *ngIf="cantonreset">
              <motif-option *ngFor="let item of cantonMasterData" [value]="item.cantonName" [checked]="item.isChecked">
                {{item.cantonName}}</motif-option>
            </motif-select>
            <motif-error *ngIf="
    form.get('canton').hasError('required') &&
    form.get('canton').touched
    ">Please select canton</motif-error>
          </motif-form-field>
        </div>

        <motif-form-field>
          <label motifLabel>Holiday Date</label>
          <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
            [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="HolidayDate" id="datepicker1"
            #dp2="motifDatepicker" (keydown.enter)="dp2.openCalendar()" (click)="dp2.toggleCalendar()"
            formControlName="HolidayDate" />
          <motif-error *ngIf="
form.get('HolidayDate').hasError('required') &&
form.get('HolidayDate').touched
">Please enter holiday date</motif-error>
        </motif-form-field>

      </div>
    </form>
  </motif-modal-body>
  <motif-modal-footer>
    <button motifButton color="primary" *ngIf="ISadd" type="submit" (click)="createNewHoliday(form)">Add</button>
    <button motifButton color="primary" *ngIf="!ISadd" type="submit" (click)="Update(form)">Update</button>
    <button motifButton color="secondary" (click)="cancel()">Cancel</button>
  </motif-modal-footer>
</motif-modal>

<!-- FileUpload Model Start-->
<motif-modal [show]="showFileUploadModel" (close)="showFileUploadModel = false">
  <motif-modal-header>
    Upload
  </motif-modal-header>
  <motif-modal-body>



    <div *ngIf="showFileUploadModel" class="motif-container">

      <div class="motif-row motif-end-lg">
        <button style="margin-bottom:8px" size="large" class="motif-col-xs-4 motif-col-md-2" (click)="TempleateExport()"
          motifButton color="primary">
          <motif-icon [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Download
          Template
        </button>


      </div>

      <motif-form-field>
        <motif-file-upload *ngIf="showFileUploadModel" [disabled]="Uploader.queue.length > 0 "
          (change)="fileChange($event)" [uploader]="Uploader" [multiple]="false">
          Drop files here or click to upload
        </motif-file-upload>
      </motif-form-field>

      <motif-uploader (cancel)="clearFiles()" *ngFor="let item of Uploader?.queue" fileName="{{item.file.name}}"
        progressValue="100" fileSize="" progressLabel=""
        errorMessage="There was a problem while uploading the file, please upload valid file" [error]="uploadfileerror">
      </motif-uploader>


    </div>


  </motif-modal-body>
  <motif-modal-footer>
    <button motifButton color="primary" [disabled]="Uploader.queue.length == 0 " (click)="UploadData()">Confirm</button>
    <button motifButton color="secondary" (click)="showFileUploadModel = false">Cancel</button>
  </motif-modal-footer>
</motif-modal>

<!--- FileUpload Model End-->

<motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
  <motif-modal-header>
    Delete Holiday
  </motif-modal-header>
  <motif-modal-body>
    <p>
      Are you sure, you want to delete Holiday?
    </p>
  </motif-modal-body>
  <motif-modal-footer>
    <button motifButton color="primary" (click)="Delete()">Confirm</button>
    <button motifButton color="secondary" (click)="deletemodalflag = false">Cancel</button>
  </motif-modal-footer>
</motif-modal>
<!-- FileUpload validation modal start-->

<motif-modal [show]="Validationsmodalflag" (close)="Validationsmodalflag = false">
  <motif-modal-header>
    Incomplete data found in the following fields, please correct and upload again.

  </motif-modal-header>
  <motif-modal-body>

    <motif-accordion type="editorial" id="example-2" *ngFor="let item of invalidarryKeys">
      <motif-accordion-header>
        {{item}}
      </motif-accordion-header>

      <motif-accordion-content>
        <ul>
          <li *ngFor="let invaliditem of validationdata[item].split(',')">{{invaliditem}}</li>
        </ul>
      </motif-accordion-content>
    </motif-accordion>

  </motif-modal-body>
  <motif-modal-footer>

  </motif-modal-footer>
</motif-modal>
<!-- FileUpload validation model end -->

<motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
  <motif-modal-header>
      Confirmation

  </motif-modal-header>
  <motif-modal-body>
    <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
  </motif-modal-body>
  <motif-modal-footer>
      <button motifButton color="primary" (click)="closeModal()" type="submit">Yes</button>
     <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
  </motif-modal-footer>
</motif-modal>

<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
  {{toastmessage}}
</motif-toast>