import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class DashBoardCountService{   
   

    constructor(private _http: HttpClient) { }
  getMastersData() {   
        
        return this._http.get(`${environment.apiUrl}/DashBoard/GetMasterData/`);

      } 
      getDashBoardData(obj) {   
        
        return this._http.post(`${environment.apiUrl}/DashBoard/GetDashBoardCount/`,obj);

      } 
      getAllDashBoardData(Year) {   
        
        return this._http.post(`${environment.apiUrl}/DashBoard/GetAllDashBoardCount/`,{year:Year.toString()});

      } 
 

      GenerateStartUp(year,isMyBanks) {
        var obj={
          year:year.toString(),
          isMyBanks:isMyBanks
        }
        return this._http.post(`${environment.apiUrl}/DashBoard/GetStatupDetails`,obj);
      }
      getDashBoarddata(obj) {   
        
        return this._http.post(`${environment.apiUrl}/DashBoard/GetLeadDashBoardCount/`,obj);

      } 
}
