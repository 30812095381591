import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-Cell-Rendors',
  templateUrl: './Cell-Rendors.component.html',
  styleUrls: ['./Cell-Rendors.component.css']
})
export class CellRendorsComponent implements ICellRendererAngularComp {
  public params: any;
  public Actions: any;
  agInit(params: any): void {

    this.params = params;
    this.Actions = this.params.Actions;
  }
  constructor() { }

 
  refresh(): boolean {
    return false;
  }

  ExpandTrackerView($event) {
    const params = {
      event: $event,
      rowData: this.params.node.data
      // ...something
    }
    this.params.ExpandTrackerView(params);
  }
}
