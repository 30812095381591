import { Component, OnInit } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { EditDeleteActionsComponent } from '../../../reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { ClientEntityFormStatusService } from './client-entity-form-status.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-client-entity-form-status',
  templateUrl: './client-entity-form-status.component.html',
  styleUrls: ['./client-entity-form-status.component.scss']
})
export class ClientEntityFormStatusComponent implements OnInit {

 
  constructor(private router: Router,private _headerService : HeaderService,private _service: ClientEntityFormStatusService, private _formBuilder: FormBuilder) { }

 //#region ag-grid_variables
 modules = AllCommunityModules;
 gridApi: any;
 public style = {
   marginTop: "",
   width: "",
   height: ""
 };
 public btnsubmittext: string = "ADD";
 public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
 public ColumnDefs: any;
 public frameworkComponents: any;
 public tableDataset: any;
 public deletemodalflag: boolean = false;

 //#endregion ag-grid_variables

 form: FormGroup;
 public EditID: any;
 public DeletID: any;
 public ISUpdate: boolean = false;
 public showLoader: boolean = false;
 public rowData: any;


 //#region  toast

 public toasttype: string;
 public toastflag: boolean;
 public toastmessage: string;

 //#endregion toast

////#region actions variables
public divFlag : boolean=false;
public styleDIV = {
 marginTop: "",
 width: "",
 height: ""
};
public actionAccess:any;
////#endregion

 ngOnInit(): void {
   this.form = this.createForm();
   this.setDivHeight();
   this._headerService.getActions("Entity Form Status").then((value) => {
     this.actionAccess=value;
     if(this.actionAccess.indexOf("View")!=-1){
       this.divFlag=true;
       this.setFrameworkoptions();
       this.setTableHeight();
       this.buildHeaders();

     }
     else
     this.router.navigate(['/unauthorizedaccess'])
   });


 }

 createForm() {
   return this._formBuilder.group({
     name: [null, [Validators.required]],
   })

 }
 handleGridReady(params) {
   this.gridApi = params;
   this.gridApi.api.showLoadingOverlay()
   this.gridApi.api.sizeColumnsToFit()
   this.bindGrid();

 };

 setFrameworkoptions() {
   this.frameworkComponents = {
     editDeleteActions: EditDeleteActionsComponent,
   }

 }
 setTableHeight() {
   this.style.width = "100%";
   this.style.height = (document.documentElement.clientHeight - 135) + "px";
 }

 private buildHeaders() {

   this.ColumnDefs = [

   
     { headerName: 'Form Status', field: 'formStatusName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
     { headerName: 'Last Updated By', field: 'lastUpdatedByName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
     {
       headerName: 'Last Updated On', field: 'lastUpdatedDate', sortable: true, filter: 'agDateColumnFilter', unSortIcon: true, resizable: true,
       cellRenderer: (data) => {
         var celldate = moment(data.value);
         return data.lastUpdatedDate ? "" : celldate.format("DD-MMM-YYYY") + " "
           + celldate.format("H:m");
       },
       filterParams: {

         // provide comparator function
         comparator: function (filterLocalDateAtMidnight, cellValue) {
           var dateAsString = cellValue;

           if (dateAsString == null) {
             return 0;
           }


           var date = dateAsString.split('T')[0];
           var dateParts = date.split('-');

           var day = Number(dateParts[2]);
           var month = dateParts[1] - 1;
           var year = Number(dateParts[0]);
           var cellDate = moment(year + "/" + month + "/" + day, "YYYY/mm/DD");

           // Now that both parameters are Date objects, we can compare
           if (cellDate < filterLocalDateAtMidnight) {
             return -1;
           } else if (cellDate > filterLocalDateAtMidnight) {
             return 1;
           }
           return 0;
         }
       }
     }


   ]
   if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Add / Update")!=-1){
     
     this.ColumnDefs.unshift({
     headerName: '',
     cellRenderer: "editDeleteActions",
     cellRendererParams: {
       onEditClick: this.onEditClick.bind(this),
       onDeleteClick: this.onDeleteClick.bind(this),
       Actions:this.actionAccess,
     },
     width: 80,
     sortable: false,
     pinned: 'left',
     autoHeight: false,
     filter: false,

     });
   }
 }


 private bindGrid() {
   this._service.get()
     .subscribe(
       res => {

         this.tableDataset = res;

         //  this.gridApi.api.hideOverlay();

       },
       err => {

         console.log(err);
       }
     )

 }
 onDeleteClick(e) {


   let row = e.rowData;
   this.deletemodalflag = true;
   this.DeletID = e.rowData.formStatusId;

 }
 onEditClick(e) {

   this.ISUpdate = true;
   console.log(e.rowData);
   this.EditID = e.rowData.formStatusId;
   let FormStatusName = e.rowData.formStatusName;
   this.btnsubmittext = "Update";
   this.rowData = e.rowData;
   this.form.setValue({
     name: FormStatusName
   });



 }

 submit() {
   this.validateForm(this.form);
   if (this.form.valid) {
     this.showLoader = true;
     if (this.ISUpdate) {
       this.update();
     } else {
       this.Add();
     }
   }

 }


 validateForm(form: FormGroup) {
   Object.keys(form.controls).forEach(field => {
     const control = form.get(field);
     if (control instanceof FormControl) {
       control.markAsTouched({ onlySelf: true });
     } else if (control instanceof FormGroup) {
       this.validateForm(control);
     }
   });
 }

 Add() {

   let name = this.form.get("name").value;
   let addobj = {
    FormStatusName: name
   }

   this._service.Add(addobj)
     .subscribe(
       (res: any) => {
         if (res.message == "success") {
           this.toasttype = "success";
           this.toastflag = true;
           this.toastmessage = "Entity form status has been added successfully.";
           this.reset();
           setTimeout(() => {
            this.toastflag=false;
           }, 3000);
         }
         else if (res.message == "ClientEntityFormStatusExists") {
           this.showLoader = false;
           this.toasttype = "error";
           this.toastflag = true;
           this.toastmessage = "Entity form status already exist.";
           setTimeout(() => {
            this.toastflag=false;
           }, 3000);

         }



       },
       err => {
        if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
          //var key=Object.keys(err["error"]["errors"])[0];   
          //this.toasterService.message.next(err.toString());    
          this.toasttype = "warning";
          this.toastflag = true;
          
          this.toastmessage = err.toString();
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
        else{
        this.reset();
        }
       }
     )


 }

 update() {

   let name = this.form.get("name").value;
   let upobj = {
    FormStatusName: name,
    FormStatusId: this.EditID
   }

   this._service.Update(upobj)
     .subscribe(
       (res: any) => {

         if (res.message == "ClientEntityFormStatusExists") {
           this.showLoader = false;
           this.toasttype = "error";
           this.toastflag = true;
           this.toastmessage = "Entity form status already exists.";
           setTimeout(() => {
            this.toastflag=false;
           }, 3000);
         }
         else
           if (res.message == "success") {

             this.reset();
             this.toasttype = "success";
             this.toastflag = true;
             this.toastmessage = "Entity form status has been updated successfully.";
             setTimeout(() => {
              this.toastflag=false;
             }, 3000);

           } else
             if (res.message == "fail") {
               {
                 this.reset();
                 this.toasttype = "error";
                 this.toastflag = true;
                 this.toastmessage = "An unexpected error has occured. Please contact support team.";
                 setTimeout(() => {
                  this.toastflag=false;
                 }, 3000);
               }


             }
       },
       err => {
        if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
          //var key=Object.keys(err["error"]["errors"])[0];   
          //this.toasterService.message.next(err.toString());    
          this.toasttype = "warning";
          this.toastflag = true;
          
          this.toastmessage = err.toString();
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
        else{
        this.reset();
        }
       }
     )



 }

 Delete() {
   this._service.delete(this.DeletID)
     .subscribe(
       (res: any) => {

         if (res.message == "success") {
           this.deletemodalflag = false;


           this.toasttype = "success";
           this.toastflag = true;
           this.toastmessage = "Entity form status has been deleted successfully.";
           setTimeout(() => {
            this.toastflag=false;
           }, 3000);

         } else if (res.message == "ClientEntityFormStatusCannotBeDeleted") {
           this.showLoader = false;
           this.toasttype = "error";
           this.toastflag = true;
           this.toastmessage = "Entity form status cannot be deleted as it was mapped to client entites.";
           setTimeout(() => {
            this.toastflag=false;
           }, 3000);
         }

         this.reset();
       },
       err => {
         this.reset();

       }
     )


 }
 reset() {
   this.showLoader = false;
   this.ISUpdate = false;
   this.EditID = null;
   this.DeletID = null;
   this.btnsubmittext = "ADD";
   this.form.reset();

   this.bindGrid();

 }

////#region Action check method
ActionsCheck(action){  
 if(this.actionAccess.indexOf(action)!=-1)
 return true;
 else
 return false;
}
setDivHeight() {
   
 this.styleDIV.width = "100%";
 this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
}

ExportExcel(){
  this._service.ExportExcel().subscribe(
    res => {
      let date = new Date().toString();
      saveAs(res, "FormStatus" + date + ".xlsx");

    },
    err => {

      console.log(err);
    }
  )
}
////#endregion

}
