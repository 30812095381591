import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {

  constructor(private _http: HttpClient) { }

  get() {
    
    return this._http.get(`${environment.apiUrl}/Role/Get`);
  }
  getMastersData() {   
        
    return this._http.get(`${environment.apiUrl}/Role/GetMasterData/`);

  } 
  GetAllRoles() {
    
    return this._http.get(`${environment.apiUrl}/Role/GetAllRoles`);
  }


  AddRole(obj)
  {
    
    return this._http.post(`${environment.apiUrl}/Role/AddRole`,obj);

  }


  UpdateRole(obj)
  {
    
    return this._http.post(`${environment.apiUrl}/Role/UpdateRole`,obj);

  }



  GetRolebyID(RoleID)
  {
    return this._http.post(`${environment.apiUrl}/Role/GetRole`,{id:RoleID});
  }
  Delete(RoleID)
  {

    return this._http.post(`${environment.apiUrl}/Role/Delete/`,{id:RoleID});
  }  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/Role/ExcelExport`, {responseType: "blob"});
  }
}
