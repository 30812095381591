<ul>


  <li *ngIf="params?.ClientEntityName !=undefined"> Entity Name : {{params.ClientEntityName}}  </li>

  <li *ngIf="params?.RbShortName !=undefined"> Entity Short Name : {{params.RbShortName}}  </li>

  <li *ngIf="params?.BankCodeRgrCid !=undefined"> Bank code : {{params.BankCodeRgrCid}}  </li>

  <li *ngIf="params?.EyCidGfis !=undefined"> EY CID(GFIS) : {{params.EyCidGfis}}  </li>



  <li *ngIf="params?.MercuryEntityId !=undefined"> ClientID(Mercury) : {{params.MercuryEntityId}}  </li>

  <li *ngIf="params?.Street !=undefined"> Street : {{params.Street}}  </li>

  <li *ngIf="params?.PostalCode !=undefined"> Postal Code : {{params.PostalCode| number| removeComma}}  </li>



  <li *ngIf="params?.Location !=undefined"> Location : {{ params?.Location}}  </li>


  <li *ngIf="params?.Canton !=undefined"> Canton : {{params.Canton}}  </li>

  <li *ngIf="params?.Country !=undefined"> Country : {{params.Country}}  </li>

  <li *ngIf="params?.ReportingLanguage !=undefined"> Reporting Language : {{params.ReportingLanguage}}  </li>

  <li *ngIf="params?.DocumentationLanguage !=undefined"> Documentation language : {{params.DocumentationLanguage}}  </li>

  <li *ngIf="params?.BankSize !=undefined"> Bank size : {{params.BankSize}}  </li>

  <li *ngIf="params?.Eyoffice !=undefined"> EY Office : {{params.Eyoffice}}  </li>

  <li *ngIf="params?.Eyregion !=undefined"> EY Region : {{params.Eyregion}}  </li>

  <li *ngIf="params?.Iroffice !=undefined">IR Office : {{params.Iroffice}}  </li>


  <li *ngIf="params?.Uidnumber != undefined">UID-No : {{params.Uidnumber}}  </li>

  <li *ngIf="params?.Finmaid !=undefined">FINMA ID : {{params.Finmaid}}  </li>

  <li *ngIf="params?.ClientFiscalYearEnd != undefined">Fiscal Year End : {{ params.ClientFiscalYearEnd}}  </li>

  <li *ngIf="params?.Faoaregister !=undefined">FAOA Register – entity Type : {{params.Faoaregister}}  </li>


  <li *ngIf="params?.Gismarkup !=undefined">GIS Mark-up : {{params.Gismarkup}}  </li>
  <li *ngIf="params?.Gisid !=undefined">GIS ID : {{params.Gisid| number| removeComma}}  </li>


  <li *ngIf="params?.FormStatus !=undefined">Form Status : {{params.FormStatus}}  </li>
  <li *ngIf="params?.Comments !=undefined">Comment - Entity Data : {{params.Comments}}  </li>

</ul>
