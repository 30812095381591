<div class="motif-row">
    <div class="motif-col-xs-4 motif-col-lg-4">
        <div class="motif-modal-header">
            <h3 class="motif-h3"> Audit Assignment</h3>
        </div>
    </div>
</div>

<form [formGroup]="form" novalidate>
    <!-- <span class="fonts-col__heading-4"><b> Entity Details </b></span> -->
    <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-4">
            <motif-form-field>
                <label motifLabel>Entity Name</label>
                <input motifInput disabled="true" motifSearch
                    type="text" placeholder="Search for entity name..." [hideClear]="true"
                    (blur)="FillEntityBaseDetails()" (change)="FillEntityBaseDetails()" aria-label="Search"
                    formControlName="entityname" [motifTypeahead]="arrayCLientEntity" />
                <ng-container *ngIf="form.controls.entityname.invalid">
                    <motif-error [showIcon]="false" *ngIf="form.get('entityname').hasError('required') &&
                        form.get('entityname').touched">Please select a valid entity name!</motif-error>
                </ng-container>

            </motif-form-field>

        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Audit Year</label>
                <motif-select formControlName="audityear"
                    disabled="true"
                    (change)="GenerateFormTitle()" class="ClienentitytModalselect" aria-label="audityear">
                    <motif-option *ngFor="let item of lstYear" [value]="item">{{item}}</motif-option>


                </motif-select>
                <motif-error *ngIf="
                      form.get('audityear').hasError('required') &&
                      form.get('audityear').touched
                      ">Please select audit year!</motif-error>
            </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Classification</label>
                <motif-select formControlName="classification" disabled="true"
                    class="ClienentitytModalselect" aria-label="classification">
                    <motif-option *ngFor="let item of lstClassification" [value]="item">{{item}}</motif-option>
                </motif-select>
                <motif-error *ngIf="
                      form.get('classification').hasError('required') &&
                      form.get('classification').touched
                      ">Please select classification!</motif-error>
            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Group Scope</label>
                <motif-select formControlName="groupscope" disabled="true"
                    class="ClienentitytModalselect" aria-label="groupscope">
                    <motif-option *ngFor="let item of lstGroupScope" [value]="item">{{item}}</motif-option>
                </motif-select>
                <motif-error *ngIf="
                      form.get('groupscope').hasError('required') &&
                      form.get('groupscope').touched
                      ">Please select group scope!</motif-error>
            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Client Contacts</label>
                <motif-select formControlName="clientcontacts" disabled="true" [multiple]="true"
                    class="ClienentitytModalselect" aria-label="clientcontacts">

                    <motif-option *ngFor="let item of lstContacts" [value]="item.contactId" [checked]="item.isChecked">
                        {{item.contactName}}</motif-option>
                </motif-select>
                <motif-error *ngIf="
                      form.get('clientcontacts').hasError('required') &&
                      form.get('clientcontacts').touched
                      ">Please select client contacts!</motif-error>
            </motif-form-field>
        </div>
    </div>
    <div class="motif-row">

        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Form Status</label>
                <motif-select formControlName="formstatus" disabled="true"
                    class="ClienentitytModalselect" aria-label="formstatus">
                    <motif-option *ngFor="let item of lstFormStatus" [value]="item">{{item}}</motif-option>
                </motif-select>
                <motif-error *ngIf="
                      form.get('formstatus').hasError('required') &&
                      form.get('formstatus').touched
                      ">Please select form status!</motif-error>
            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-10">
            <motif-form-field>
                <label motifLabel>Form Title</label>
                <input motifInput type="text" aria-label="Form Title Form Field" disabled formControlName="formtitle" />
            </motif-form-field>
        </div>
    </div>
    <span class="fonts-col__heading-4"><b> Entity Details </b></span>
    <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Entity Short Name</label>
                <input motifInput type="text" aria-label="Entity Short Name Form Field" disabled
                    formControlName="entityshortname" />
            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Bank Code</label>
                <input motifInput type="text" aria-label="Bank Code Form Field" disabled formControlName="bankcode" />

            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Location</label>
                <input motifInput type="text" aria-label="Location Form Field" disabled formControlName="location" />

            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Canton</label>
                <input motifInput type="text" aria-label="Canton Form Field" disabled formControlName="canton" />

            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Reporting Language</label>
                <input motifInput type="text" aria-label="Reporting language Form Field" disabled
                    formControlName="reportinglanguage" />

            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>Bank Size</label>
                <input motifInput type="text" aria-label="Bank size Form Field" disabled formControlName="banksize" />
            </motif-form-field>
        </div>
    </div>
    <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>EY Office</label>
                <input motifInput type="text" aria-label="EY Office Form Field" disabled formControlName="eyoffice" />
            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>EY Region</label>
                <input motifInput type="text" aria-label="EY Region Form Field" disabled formControlName="eyregion" />
            </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
                <label motifLabel>IR Office</label>
                <input motifInput type="text" aria-label="IR Office Form Field" disabled formControlName="iroffice" />
            </motif-form-field>
        </div>
    </div>

    <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-2">
            <div>
                <motif-form-field>
                    <motif-checkbox
                    disabled
                        formControlName="AuditTypeRP">Financial Audit - RP</motif-checkbox>
                    <motif-error *ngIf="!form.value.AuditTypeRP && !form.value.AuditTypeAP && !form.value.AuditTypeOther && !form.value.AuditTypeKP
                            ">Please select at least one audit type!</motif-error>
                </motif-form-field>
            </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <div>
                <motif-form-field>
                    <motif-checkbox
                    disabled
                        formControlName="AuditTypeKP">Loan Audit - KP</motif-checkbox>
                </motif-form-field>
            </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <div>
                <motif-form-field>
                    <motif-checkbox
                    disabled
                        formControlName="AuditTypeAP">Regulatory Audit - AP</motif-checkbox>
                </motif-form-field>
            </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
            <div>
                <motif-form-field>
                    <motif-checkbox
                    disabled
                        formControlName="AuditTypeOther">Other Audit - OT</motif-checkbox>
                </motif-form-field>
            </div>
        </div>
    </div>

    <div *ngIf="form.value.AuditTypeRP">
        <span class="fonts-col__heading-4"><b> Financial Audit - RP </b></span>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Financial Audit (RP) Lead</label>
                    <motif-select formControlName="financialauditleadRP" disabled="true"
                        (change)="onchangeFinancialAuditLeadRP($event)" class="ClienentitytModalselect"
                        aria-label="Financial Audit (RP) Lead">
                        <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('financialauditleadRP').hasError('required') &&
                      form.get('financialauditleadRP').touched
                      ">Please select a financial audit lead!</motif-error>
                </motif-form-field>
            </div>
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Period Start Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                        (dateChanged)="onChangeStartDateRP($event)" name="auditperiodstartdateRP" id="datepicker1"
                        #dp2="motifDatepicker" (keydown.enter)="dp2.openCalendar()" (click)="dp2.toggleCalendar()"
                        formControlName="auditperiodstartdateRP" />


                </motif-form-field>
            </div>
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Period End Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="endDateRPOptions" name="auditperiodenddateRP" id="datepicker2" #dp3="motifDatepicker"
                        (keydown.enter)="dp3.openCalendar()" (click)="dp3.toggleCalendar()"
                        formControlName="auditperiodenddateRP" />

                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Regional Lead Partner</label>
                    <motif-select formControlName="regionalleadpartnerRP" disabled="true"
                        class="ClienentitytModalselect" aria-label="Regional Lead Partner">
                        <motif-option *ngFor="let item of lstRPTeammembers" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('regionalleadpartnerRP').hasError('required') &&
                      form.get('regionalleadpartnerRP').touched
                      ">Please select a regional lead partner!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Assigned Lead Auditor</label>
                    <motif-select formControlName="assignedleadauditorRP" disabled="true"
                        class="ClienentitytModalselect" aria-label="Assigned Lead Auditor (LAEP) - RP">
                        <motif-option *ngFor="let item of lstRPTeammembers" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('assignedleadauditorRP').hasError('required') &&
                      form.get('assignedleadauditorRP').touched
                      ">Please select a assigned lead auditor!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Internal Audit Engagement Leader</label>
                    <motif-select formControlName="internalauditengagementleaderRP" disabled="true"
                        class="ClienentitytModalselect" aria-label="Internal Audit Engagement Leader - RP">
                        <motif-option *ngFor="let item of lstInternalAuditEngLeder" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>

                </motif-form-field>
            </div>

        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit ID Code</label>
                    <input motifInput type="text" disabled aria-label="RP ID Code Field" formControlName="idcodeRP" />

                </motif-form-field>

            </div>

            
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution Start Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                        (dateChanged)="onChangeExcutionStartDateRP($event)" name="auditexecutionstartdateRP"
                        id="datepicker4" #dp4="motifDatepicker" (keydown.enter)="dp4.openCalendar()"
                        (click)="dp4.toggleCalendar()" formControlName="auditexecutionstartdateRP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution End Date</label>
                    <input motifDatepicker class="motif-input" disabled
                        (dateChanged)="onChangeExcutionEndDateRP($event)" placeholder="DD.MM.YYYY"
                        [options]="executionEndDateRPOptions" name="auditexecutionenddateRP" id="datepicker5"
                        #dp5="motifDatepicker" (keydown.enter)="dp5.openCalendar()" (click)="dp5.toggleCalendar()"
                        formControlName="auditexecutionenddateRP" />

                </motif-form-field>
            </div>
            
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Completed - RP</label>
                    <input motifDatepicker class="motif-input" disabled
                        (dateChanged)="onChangeIAworkCompletedDateRP($event)" placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="iaworkcompletedRP"
                        id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                        (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedRP" />

                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Announcement Delivery - RP</label>
                    <input motifInput type="text" aria-label="Announcemrnt delivery Form Field" disabled
                        formControlName="announcementdeliveryRP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Data Due - RP</label>
                    <input motifInput type="text" aria-label="Client due date Form Field" disabled
                        formControlName="clientdatadueRP" />
                </motif-form-field>
            </div>

        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report - RP</label>
                    <input motifInput type="text" aria-label="Draft report Form Field" disabled
                        formControlName="draftreportRP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Paper Review Due - RP</label>
                    <input motifInput type="text" aria-label="IA Work Paper Review Due  Form Field" disabled
                        formControlName="iaworkpaperreviewdueRP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report To Client Due - RP</label>
                    <input motifInput type="text" aria-label="Draft Report To Client Due Form Field" disabled
                        formControlName="draftreporttoclientdueRP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Feedback Due - RP</label>
                    <input motifInput type="text" aria-label="Client feedback due Form Field" disabled
                        formControlName="clientfeedbackdueRP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Final Report To Client Due - RP</label>
                    <input motifInput type="text" aria-label="Final Report To Client Due Form Field" disabled
                        formControlName="finalreporttoclientdueRP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Status</label>
                    <motif-select formControlName="statusidRP" disabled
                        class="ClienentitytModalselect" aria-label="Status - RP">
                        <motif-option *ngFor="let item of lstAudittypeSatus" [value]="item.auditTypeStatusId">
                            {{item.auditTypeStatusName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                  form.get('statusidRP').hasError('required') &&
                  form.get('statusidRP').touched
                  ">Please select a status!</motif-error>
                </motif-form-field>
            </div>
        </div>
    </div>
    <div *ngIf="form.value.AuditTypeKP">
        <span class="fonts-col__heading-4"><b>Loan Audit - KP </b></span>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Financial Audit (KP) Lead</label>
                    <motif-select formControlName="financialauditleadKP" disabled="true"
                        (change)="onchangeFinancialAuditLeadKP($event)" class="ClienentitytModalselect"
                        aria-label="Financial Audit (KP) Lead">
                        <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('financialauditleadKP').hasError('required') &&
                      form.get('financialauditleadKP').touched
                      ">Please select a KP financial audit Lead!</motif-error>
                </motif-form-field>
            </div>

            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Assigned Lead Auditor</label>
                    <motif-select formControlName="assignedleadauditorKP" disabled="true"
                        class="ClienentitytModalselect" aria-label="Assigned Lead Auditor (LAEP) - KP">
                        <motif-option *ngFor="let item of lstKPTeammembers" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('assignedleadauditorKP').hasError('required') &&
                      form.get('assignedleadauditorKP').touched
                      ">Please select a assigned lead auditor!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit ID Code</label>
                    <input motifInput type="text" disabled aria-label="KP ID Code Field" formControlName="idcodeKP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution Start Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateKP"
                        id="datepicker6" #dp6="motifDatepicker" (keydown.enter)="dp6.openCalendar()"
                        (click)="dp6.toggleCalendar()" formControlName="auditexecutionstartdateKP"
                        (dateChanged)="onChangeExcutionStartDateKP($event)" />

                </motif-form-field>
            </div>
            
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution End Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="executionEndDateKPOptions" name="auditexecutionenddateKP" id="datepicker7"
                        #dp7="motifDatepicker" (keydown.enter)="dp7.openCalendar()" (click)="dp7.toggleCalendar()"
                        formControlName="auditexecutionenddateKP" />

                </motif-form-field>
            </div>

            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Completed - KP</label>
                    <input motifDatepicker class="motif-input" disabled
                        (dateChanged)="onChangeIAworkCompletedDateKP($event)" placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="iaworkcompletedKP"
                        id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                        (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedKP" />

                </motif-form-field>
            </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Announcement Delivery - KP</label>
                    <input motifInput type="text" aria-label="Announcemrnt delivery Form Field" disabled
                        formControlName="announcementdeliveryKP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Data Due - KP</label>
                    <input motifInput type="text" aria-label="Client due date Form Field" disabled
                        formControlName="clientdatadueKP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report - KP</label>
                    <input motifInput type="text" aria-label="Draft report Form Field" disabled
                        formControlName="draftreportKP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Paper Review Due - KP</label>
                    <input motifInput type="text" aria-label="IA Work PaPer Review Due  Form Field" disabled
                        formControlName="iaworkpaperreviewdueKP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report To Client Due - KP</label>
                    <input motifInput type="text" aria-label="Draft Report To Client Due Form Field" disabled
                        formControlName="draftreporttoclientdueKP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Feedback Due - KP</label>
                    <input motifInput type="text" aria-label="Client feedback due Form Field" disabled
                        formControlName="clientfeedbackdueKP" />
                </motif-form-field>
            </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Final Report To Client Due - KP</label>
                    <input motifInput type="text" aria-label="Final Report To Client Due Form Field" disabled
                        formControlName="finalreporttoclientdueKP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Status</label>
                    <motif-select formControlName="statusidKP" disabled
                        class="ClienentitytModalselect" aria-label="Status - KP">
                        <motif-option *ngFor="let item of lstAudittypeSatus" [value]="item.auditTypeStatusId">
                            {{item.auditTypeStatusName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                  form.get('statusidKP').hasError('required') &&
                  form.get('statusidKP').touched
                  ">Please select a status!</motif-error>
                </motif-form-field>
            </div>

        </div>
    </div>


    <div *ngIf="form.value.AuditTypeAP">
        <span class="fonts-col__heading-4"><b> Regulatory Audit - AP </b></span>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Regulatory Audit (AP) Lead</label>
                    <motif-select formControlName="financialauditleadAP" disabled="true"
                        (change)="onchangeFinancialAuditLeadAP($event)" class="ClienentitytModalselect"
                        aria-label="Financial Audit (AP) Lead">
                        <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('financialauditleadAP').hasError('required') &&
                      form.get('financialauditleadAP').touched
                      ">Please select a regulatory audit lead!</motif-error>
                </motif-form-field>
            </div>
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Period Start Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditperiodstartdateAP"
                        id="datepicker8" #dp8="motifDatepicker" (keydown.enter)="dp8.openCalendar()"
                        (click)="dp8.toggleCalendar()" formControlName="auditperiodstartdateAP"
                        (dateChanged)="onChangeStartDateAP($event)" />

                    <motif-error [showIcon]="false" *ngIf="
                    form.get('auditperiodstartdateAP').hasError('required') &&
                    form.get('auditperiodstartdateAP').touched
                    ">Please select a audit period start date!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Period End Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="endDateAPOptions" name="auditperiodenddateAP" id="datepicker9" #dp9="motifDatepicker"
                        (keydown.enter)="dp9.openCalendar()" (click)="dp9.toggleCalendar()"
                        formControlName="auditperiodenddateAP" />
                    <motif-error [showIcon]="false" *ngIf="
                    form.get('auditperiodenddateAP').hasError('required') &&
                    form.get('auditperiodenddateAP').touched
                    ">Please select a audit period end date!</motif-error>
                </motif-form-field>
            </div>

            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Regional Lead Partner</label>
                    <motif-select formControlName="regionalleadpartnerAP" disabled="true"
                        class="ClienentitytModalselect" aria-label="Regional Lead Partner">
                        <motif-option *ngFor="let item of lstAPTeammembers" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('regionalleadpartnerAP').hasError('required') &&
                      form.get('regionalleadpartnerAP').touched
                      ">Please select a regional lead partner!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Assigned Lead Auditor</label>
                    <motif-select formControlName="assignedleadauditorAP" disabled="true"
                        class="ClienentitytModalselect" aria-label="Assigned Lead Auditor (LAEP) - AP">
                        <motif-option *ngFor="let item of lstAPTeammembers" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('assignedleadauditorAP').hasError('required') &&
                      form.get('assignedleadauditorAP').touched
                      ">Please select a assigned lead auditor!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Internal Audit Engagement Leader</label>
                    <motif-select formControlName="internalauditengagementleaderAP" disabled="true"
                        class="ClienentitytModalselect" aria-label="Internal Audit Engagement Leader - RP">
                        <motif-option *ngFor="let item of lstInternalAuditEngLeder" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>

                </motif-form-field>
            </div>

        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit ID Code</label>
                    <input motifInput type="text" disabled aria-label="AP ID Code Field" formControlName="idcodeAP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution Start Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateAP"
                        id="datepicker10" #dp10="motifDatepicker" (keydown.enter)="dp10.openCalendar()"
                        (click)="dp10.toggleCalendar()" formControlName="auditexecutionstartdateAP"
                        (dateChanged)="onChangeExcutionStartDateAP($event)" />
                    <motif-error [showIcon]="false" *ngIf="
                    form.get('auditexecutionstartdateAP').hasError('required') &&
                    form.get('auditexecutionstartdateAP').touched
                    ">Please select a audit execution start date!</motif-error>
                </motif-form-field>
            </div>
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution End Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="executionEndDateAPOptions" name="auditexecutionenddateAP" id="datepicker11"
                        #dp11="motifDatepicker" (keydown.enter)="dp11.openCalendar()" (click)="dp11.toggleCalendar()"
                        formControlName="auditexecutionenddateAP" />
                    <motif-error [showIcon]="false" *ngIf="
                    form.get('auditexecutionenddateAP').hasError('required') &&
                    form.get('auditexecutionenddateAP').touched
                    ">Please select a audit execution end date!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Completed - AP</label>
                    <input motifDatepicker class="motif-input" disabled
                        (dateChanged)="onChangeIAworkCompletedDateAP($event)" placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="iaworkcompletedAP"
                        id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                        (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedAP" />

                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Announcement Delivery - AP</label>
                    <input motifInput type="text" aria-label="Announcemrnt delivery Form Field" disabled
                        formControlName="announcementdeliveryAP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Data Due - AP</label>
                    <input motifInput type="text" aria-label="Client due date Form Field" disabled
                        formControlName="clientdatadueAP" />
                </motif-form-field>
            </div>

        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report - AP</label>
                    <input motifInput type="text" aria-label="Draft report Form Field" disabled
                        formControlName="draftreportAP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Paper Review Due - AP</label>
                    <input motifInput type="text" aria-label="IA Work Paper Review Due  Form Field" disabled
                        formControlName="iaworkpaperreviewdueAP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report To Client Due - AP</label>
                    <input motifInput type="text" aria-label="Draft Report To Client Due Form Field" disabled
                        formControlName="draftreporttoclientdueAP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Feedback Due - AP</label>
                    <input motifInput type="text" aria-label="Client feedback due Form Field" disabled
                        formControlName="clientfeedbackdueAP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Final Report To Client Due - AP</label>
                    <input motifInput type="text" aria-label="Final Report To Client Due Form Field" disabled
                        formControlName="finalreporttoclientdueAP" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Status</label>
                    <motif-select formControlName="statusidAP" disabled
                        class="ClienentitytModalselect" aria-label="Status - AP">
                        <motif-option *ngFor="let item of lstAudittypeSatus" [value]="item.auditTypeStatusId">
                            {{item.auditTypeStatusName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                  form.get('statusidAP').hasError('required') &&
                  form.get('statusidAP').touched
                  ">Please select a status!</motif-error>
                </motif-form-field>
            </div>
        </div>
    </div>

    <div *ngIf="form.value.AuditTypeOther">
        <span class="fonts-col__heading-4"><b>Other Audit - OT </b></span>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Other Audit Lead</label>
                    <motif-select formControlName="financialauditleadOther" disabled="true"
                        (change)="onchangeFinancialAuditLeadOT($event)" class="ClienentitytModalselect"
                        aria-label="Financial Audit (Other) Lead">
                        <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('financialauditleadOther').hasError('required') &&
                      form.get('financialauditleadOther').touched
                      ">Please select a financial audit lead!</motif-error>
                </motif-form-field>
            </div>
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Period Start Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditperiodstartdateOther"
                        id="datepicker12" #dp12="motifDatepicker" (keydown.enter)="dp12.openCalendar()"
                        (click)="dp12.toggleCalendar()" formControlName="auditperiodstartdateOther"
                        (dateChanged)="onChangeStartDateOT($event)" />

                    <motif-error [showIcon]="false" *ngIf="
                    form.get('auditperiodstartdateOther').hasError('required') &&
                    form.get('auditperiodstartdateOther').touched
                    ">Please select a audit period start date!</motif-error>
                </motif-form-field>
            </div>
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Period End Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="endDateOTOptions" name="auditperiodenddateOther" id="datepicker13"
                        #dp13="motifDatepicker" (keydown.enter)="dp13.openCalendar()" (click)="dp13.toggleCalendar()"
                        formControlName="auditperiodenddateOther" />
                    <motif-error [showIcon]="false" *ngIf="
                    form.get('auditperiodenddateOther').hasError('required') &&
                    form.get('auditperiodenddateOther').touched
                    ">Please select a audit period end date!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Regional Lead Partner</label>
                    <motif-select formControlName="regionalleadpartnerOther" disabled="true"
                        class="ClienentitytModalselect" aria-label="Regional Lead Partner">
                        <motif-option *ngFor="let item of lstOTTeammembers" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('regionalleadpartnerOther').hasError('required') &&
                      form.get('regionalleadpartnerOther').touched
                      ">Please select a regional lead partner!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Assigned Lead Auditor</label>
                    <motif-select formControlName="assignedleadauditorOther" disabled="true"
                        class="ClienentitytModalselect" aria-label="Assigned Lead Auditor (LAEP) - Other">
                        <motif-option *ngFor="let item of lstOTTeammembers" [value]="item.contactId">
                            {{item.contactName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                      form.get('assignedleadauditorOther').hasError('required') &&
                      form.get('assignedleadauditorOther').touched
                      ">Please select a assigned lead auditor!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit ID Code</label>
                    <input motifInput type="text" disabled aria-label="Other ID Code Field"
                        formControlName="idcodeOther" />
                </motif-form-field>
            </div>
        </div>
        <div class="motif-row">
            <div  class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution Start Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateOther"
                        id="datepicker14" #dp14="motifDatepicker" (keydown.enter)="dp14.openCalendar()"
                        (click)="dp14.toggleCalendar()" formControlName="auditexecutionstartdateOther"
                        (dateChanged)="onChangeExcutionStartDateOT($event)" />

                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Audit Execution End Date</label>
                    <input motifDatepicker class="motif-input" disabled placeholder="DD.MM.YYYY"
                        [options]="executionEndDateOTOptions" name="auditexecutionenddateAP" id="datepicker15"
                        #dp15="motifDatepicker" (keydown.enter)="dp15.openCalendar()" (click)="dp15.toggleCalendar()"
                        formControlName="auditexecutionenddateOther" />
                    <motif-error [showIcon]="false" *ngIf="
                    form.get('auditexecutionenddateOther').hasError('required') &&
                    form.get('auditexecutionenddateOther').touched
                    ">Please select a audit execution end date!</motif-error>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Completed - OT</label>
                    <input motifDatepicker class="motif-input" disabled
                        (dateChanged)="onChangeIAworkCompletedDateOT($event)" placeholder="DD.MM.YYYY"
                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="iaworkcompletedOT"
                        id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                        (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedOT" />

                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Announcement Delivery - OT</label>
                    <input motifInput type="text" aria-label="Announcemrnt delivery Form Field" disabled
                        formControlName="announcementdeliveryOT" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Data Due - OT</label>
                    <input motifInput type="text" aria-label="Client due date Form Field" disabled
                        formControlName="clientdatadueOT" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report - OT</label>
                    <input motifInput type="text" aria-label="Draft report Form Field" disabled
                        formControlName="draftreportOT" />
                </motif-form-field>
            </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>IA Work Paper Review Due - OT</label>
                    <input motifInput type="text" aria-label="IA Work Paper Review Due  Form Field" disabled
                        formControlName="iaworkpaperreviewdueOT" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Draft Report To Client Due - OT</label>
                    <input motifInput type="text" aria-label="Draft Report To Client Due Form Field" disabled
                        formControlName="draftreporttoclientdueOT" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Client Feedback Due - OT</label>
                    <input motifInput type="text" aria-label="Client feedback due Form Field" disabled
                        formControlName="clientfeedbackdueOT" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Final Report To Client Due - OT</label>
                    <input motifInput type="text" aria-label="Final Report To Client Due Form Field" disabled
                        formControlName="finalreporttoclientdueOT" />
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                    <label motifLabel>Status</label>
                    <motif-select formControlName="statusidOT" disabled
                        class="ClienentitytModalselect" aria-label="Status - OT">
                        <motif-option *ngFor="let item of lstAudittypeSatus" [value]="item.auditTypeStatusId">
                            {{item.auditTypeStatusName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                  form.get('statusidOT').hasError('required') &&
                  form.get('statusidOT').touched
                  ">Please select a status!</motif-error>
                </motif-form-field>
            </div>
        </div>
    </div>
    <div class="motif-row">
        <div class="motif-col-xs-4">
            <motif-form-field>
                <label motifLabel>Comment</label>
                <textarea motifInput aria-label="disabled example textarea" disabled
                    class="motif-textarea" formControlName="Comment"></textarea>
            </motif-form-field>
        </div>
    </div>
</form>
<div class="motif-row">
    <div class="motif-col-xs-4 motif-col-lg-10"></div>
    <div class="motif-col-xs-4 motif-col-lg-1">
        <button *ngIf="btnflag" disabled motifButton
            color="primary" (click)="CreateAuditAssignment()" type="submit">Save</button> 
        <button motifButton color="primary" *ngIf="!btnflag" disabled
            (click)="UpdateAuditAssignment()" type="submit">Update</button>
    </div>
    <div class="motif-col-xs-4 motif-col-lg-1">
        <button motifButton id="btnCancelEmployee" (click)="oncancel('cancel')" color="secondary">Cancel</button>
    </div>

</div>
<!-- Toster and loader start -->
<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
    {{toastmessage}}</motif-toast>
<!-- Toster and loader end -->