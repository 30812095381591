import { Component, ErrorHandler, HostListener, OnInit, ViewChild } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
//import { EditDeleteActionsComponent } from '../../../reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import {ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { Location } from '@angular/common';
import { EmailConfigurationForDueDatesService } from '../email-configuration-for-due-dates.service';
import { EditDeleteActionsComponent } from 'src/app/reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { saveAs } from 'file-saver';
import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'app-email-configuration-for-due-dates',
  templateUrl: './email-configuration-for-due-dates.component.html',
  styleUrls: ['./email-configuration-for-due-dates.component.scss']
})
export class EmailConfigurationForDueDatesComponent implements OnInit {

  @ViewChild('editor') editor;

  constructor(private router: Router,private _headerService : HeaderService,private _EmailConfigservice:EmailConfigurationForDueDatesService ,
    private _formBuilder: FormBuilder,private location: Location,private route: ActivatedRoute) { }

    public filterdata: any;
    public uploadedfile: any;
    public uploadfileerror: boolean = false;
    public uploadshowprogress: boolean = false;
    public Validationsmodalflag: boolean = false;
    public invalidarryKeys: any;
    public validationdata: any;
    Uploader: FileUploader = new FileUploader({ url: '' });




    //#region ag-grid_variables
    modules = AllCommunityModules;
    public tableDataset: any;
    gridApi: any;
    public style = {
      marginTop: "",
      width: "",
      height: ""
    };
    public btnsubmittext: string = "ADD";
    public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    public ColumnDefs: any;
    public frameworkComponents: any;
    
    public deletemodalflag: boolean = false;
    public EmailConfigurationId: any;
    
    //#endregion ag-grid_variables
  
    // form: FormGroup;
    public EditID: any;
    public ISUpdate: boolean = false;
    public showLoader: boolean = false;
    public rowData: any;
  
    //#region  toast
    public toasttype: string;
    public toastflag: boolean;
    public toastmessage: string;
    //#endregion toast
  
    //public currentactivetab: string = 'AuditAssignment';
    public AddUpdateFlag: boolean = false;
    public EditParams: any = [];
    public auditassign: boolean = true;
    public showFileUploadModel: boolean = false;

  
  ////#region actions variables
  public divFlag : boolean=false;
  public Aggridstyle = {
    marginTop: "",
    width: "",
    height: ""
  };
  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };
  public actionAccess:any;
  ////#endregion
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableHeight();
  }
    ngOnInit(): void {
      this.setDivHeight();
      this.bindGrid();
      this._headerService.getActions("EmailConfigDueDates").then((value) => {
        this.actionAccess=value;
        if(this.actionAccess.indexOf("View")!=-1){
          this.divFlag=true;
          this.setFrameworkoptions();
          
          this.buildHeaders();
          this.setTableHeight();
          this.bindGrid();
        }
        else
        this.router.navigate(['/unauthorizedaccess'])
      });
    }
    setTableHeight() {
      
      this.style.marginTop = '20px';
      this.style.width = "100%";
      this.style.height = (document.documentElement.clientHeight - 180) + "px";  
    }
    pageloadflag: any = true;
    handleGridReady(params) {
      this.gridApi = params;
      this.gridApi.api.showLoadingOverlay()
      this.gridApi.api.hideOverlay()
      //this.gridApi.api.sizeColumnsToFit()
      //  this.DirectorybindGrid();
      if (this.pageloadflag) {
        this.pageloadflag = false;
        const Id: string = this.route.snapshot.queryParamMap.get('id');
        if (Id != null && Id.trim() != "") {
          var id = this._headerService.decryptUsingAES256(Id);
          this._EmailConfigservice.get()
            .subscribe(
              res => {
                this.tableDataset = res;
                var filtereddata = this.tableDataset.filter(data => data.emailConfigurationId == Number(id))
                this.filterdata = {
                  rowData: filtereddata[0]
                }
                if (this.actionAccess.indexOf("Update") != -1) {
                  this.onEditClick(this.filterdata);
                  }
              })
        }
      }
    };
  
    setFrameworkoptions() {
      this.frameworkComponents = {
        editDeleteActions: EditDeleteActionsComponent,
      }
    }
    
    
  
    private buildHeaders() {
  ;
      this.ColumnDefs = [
        { headerName: 'Configuration Title', field: 'configurationTitle', width:400, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', resizable: true, pinned: 'left'},
        { headerName: 'Audit Type', field: 'auditType', width:165, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', resizable: true },
        { headerName: 'Audit Status AP', field: 'auditStatusApName',width:195,  sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Audit Status KP', field: 'auditStatusKpName', width:195, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Audit Status RP', field: 'auditStatusRpName', width:195, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Audit Status OT', field: 'auditStatusOtName', width:195, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Audit Type Lead', field: 'auditTypeLead', width:195, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Due Date', field: 'dueDate', width:157, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Trigger Period', field: 'triggerPeriod', width:187, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Trigger Type', field: 'triggerType', width:185, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Enable Email Trigger', field: 'isEnabled', width:220, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true  ,
        cellRenderer: (data) => {
          ;
          //var isEnabled = moment(data.value);
          // return data.isEnabled== true ? "Enabled" : "Disabled";
          var isEnabled=data.value;
          if(isEnabled==true)
              {
            return "Enabled";
              }
          else{
            return "Disabled";
              }
          
           }
        
         },
        { headerName: 'Send To', field: 'sendTo', width:150, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Send CC', field: 'sendCc', width:150, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Additional Emails', field: 'additionalEmails', width:205, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', resizable: true },
        { headerName: 'Language', field: 'languageName', width:161, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Email Subject', field: 'emailSubject', width:180, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Email Body', field: 'emailBody', width:169, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', resizable: true },
        { headerName: 'Last Updated By', field: 'lastUpdatedByName', width:198, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        {headerName: 'Last Updated On', field: 'lastUpdatedDate', width:200, sortable: true, unSortIcon: true, filter: 'agDateColumnFilter',  resizable: true,
          cellRenderer: (data) => {
            var celldate = moment(data.value);
            return data.lastUpdatedDate ? "" : celldate.format("DD-MMM-YYYY") + " "
              + celldate.format("H:m");
          },
          filterParams: {
            // provide comparator function
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = cellValue;
              if (dateAsString == null) {
                return 0;
              }
              var date = dateAsString.split('T')[0];
              var dateParts = date.split('-');
              var day = Number(dateParts[2]);
              var month = dateParts[1] - 1;
              var year = Number(dateParts[0]);
              var cellDate = moment(year + "/" + month + "/" + day, "YYYY/mm/DD");

              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            }
          }
        }
      ]
      if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Add / Update")!=-1){
        this.ColumnDefs.unshift({
        headerName: '',
        cellRenderer: "editDeleteActions",
        cellRendererParams: {
          onEditClick: this.onEditClick.bind(this),
          onDeleteClick: this.onDeleteClick.bind(this),
          Actions:this.actionAccess,
        },
        width: 118,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,
        });
      }
    } 
  
    private bindGrid() {
      ;
      this._EmailConfigservice.get()
        .subscribe(
          res => {
            ;
            this.tableDataset = res;
             console.log(res);
            //  this.gridApi.api.hideOverlay();
          },
          err => {
            console.log(err);
          }
        )
    }
  
  onBack(status: string) {
      this.AddUpdateFlag = false;
      //this.ArchiveFlag = false;
      this.diasabledEditFlag = false;
      if (status == "success") {
        this.toasttype = "success";
        this.toastflag = true;
        this.toastmessage = "Email Configuration has been added successfully."; 
        setTimeout(() => {
          this.toastflag=false;
         }, 3000);
      }
      else if (status == "update") {
        this.toasttype = "success";
        this.toastflag = true;
        this.toastmessage = "Email Configuration has been updated successfully.";
        setTimeout(() => {
          this.toastflag=false;
         }, 3000);
      }
      this.bindGrid();
      this.EditParams = null;
    }
    public diasabledEditFlag: boolean = false;
    
    onEditClick(e) {
      ;
      var id = this._headerService.encryptUsingAES256(e.rowData.emailConfigurationId);
      this.location.go('/EmailConfigurationForDueDates');
      this.AddUpdateFlag = true;
      this.EditParams = e.rowData;
      console.log(this.EditParams);
      var obj = {
        newflag: "AUDupdate",
      }
      this._EmailConfigservice.setupdateData(obj);
      this.auditassign = true;
      if(this.EditParams!=null){
        if (this.actionAccess.indexOf("Update") != -1) {
         this.onEditClick(this.filterdata);
         }
      }
    }

    public ShowFileUploadModel() {
      this.showFileUploadModel = true;
    }

    public hideFileUploadModel() {
      this.showFileUploadModel = false;
      this.clearFiles();
    }  
    fileChange($event) {
      if ($event.length > 0)
        this.uploadedfile = $event[0].file.rawFile;
    }

    clearFiles() {
      this.uploadedfile = null;
      this.uploadfileerror = false;
      this.Uploader = new FileUploader({ url: '' });
    }

    UploadData() {
      this.toastflag = false;
      this.uploadshowprogress = true;
  debugger
      let formData: FormData = new FormData();
      formData.append("file", this.uploadedfile);
      this._EmailConfigservice.Upload(formData)
        .subscribe(
          res => {
  

            if (res["status"] == "success") {

              this.hideFileUploadModel();
              this.uploadshowprogress = false;
              this.toasttype = "success";
              this.toastflag = true;
  
              this.toastmessage = "Email Configuration For DueDates has been uploaded successfully.";
              this.bindGrid();
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            else if (res["status"] == "fail" || res["status"] == "invalidfile") {
  
              this.uploadfileerror = true;
                 this.toasttype="error";
              this.uploadshowprogress = false;
                this.toastflag=true;
  
                this.toastmessage="Error occured please contact support team";
                setTimeout(() => {
                  this.toastflag=false;
                 }, 3000);
            }
            else if (res["status"] == "invalid") {
              this.hideFileUploadModel();
               this.toasttype="error";
               this.toastflag=true;
              this.Validationsmodalflag = true;
              this.uploadshowprogress = false;
              this.invalidarryKeys = Object.keys(res).slice(0, -1);
              this.validationdata = res;
               this.toastmessage="Please Upload Valid Template";
               setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
  
  
          },
          err => {
  
            if (err == "Internal Server Error") {
  
              this.uploadfileerror = true;
                this.toasttype="error";
              this.uploadshowprogress = false;
                this.toastflag=true;
                setTimeout(() => {
                  this.toastflag=false;
                 }, 3000);
  
            } else {
              this.hideFileUploadModel();
              this.toasttype = "error";
              this.toastflag = true;
  
              this.uploadshowprogress = false;
              this.toastmessage = "Error occured please contact support team";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
          }
        )
  
    }

    TempleateExport() {
      this.toastflag = false;
      debugger;
  
  
      this._EmailConfigservice.TempleateExport()
        .subscribe(
          res => {
  
  
            saveAs(res, "EmailConfigurationforDueDatesTemplate.xlsx");
  
          },
          err => {
  
            console.log(err);
          }
        )
    }

    onDeleteClick(e) {
      let row = e.rowData;
      this.deletemodalflag = true;
      this.EmailConfigurationId = e.rowData.emailConfigurationId;
    }
   
    Delete() {
      this._EmailConfigservice.delete(this.EmailConfigurationId)
        .subscribe(
          (res: any) => {
            if (res.message == "success") {
              this.deletemodalflag = false;
  
              this.toasttype = "success";
              this.toastflag = true;
              this.toastmessage = "Email Configuration has been deleted successfully.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
  
            } else if (res.message == "AuditTypeStatusCannotBeDeleted") {
              this.showLoader = false;
              this.toasttype = "error";
              this.toastflag = true;
              this.toastmessage = "Email Configuration cannot be deleted as it was mapped to Client Entity.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            this.reset();
          },
          err => {
            this.reset();
          }
        )
      }
      
      reset() {
      this.showLoader = false;
      this.ISUpdate = false;
      this.EditID = null;
      this.EmailConfigurationId = null;
      this.btnsubmittext = "ADD";
      // this.form.reset();
      this.bindGrid();
    }
  ////#region Action check method
  ActionsCheck(action){  
    if(this.actionAccess.indexOf(action)!=-1)
    return true;
    else
    return false;
  }
  setDivHeight() {
    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }
  
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  
  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  ExportExcel(){
    this._EmailConfigservice.ExportExcel().subscribe(
      res => {
        let date = new Date().toString();
        saveAs(res, "EmailConfigurationDueDates" + date + ".xlsx");

      },
      err => {

        console.log(err);
      }
    )
  }
  ////#endregion
    Addemailconfig(){
      this.AddUpdateFlag=true;
      var obj = {
        newflag: "",
      }
      this._EmailConfigservice.setupdateData(obj);


    }
  }
  

