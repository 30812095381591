import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlanningDatesConfigurationService {

  constructor(private _http: HttpClient) { }


  get(year) {
    return this._http.post(`${environment.apiUrl}/PlanningDatesConfiguration/get`,{year:year.toString()});
  }

 

  GenerateDays(year) {
    return this._http.post(`${environment.apiUrl}/PlanningDatesConfiguration/GenerateDays`,{year:year.toString()});
  }
  Add(obj) {
    return this._http.post(`${environment.apiUrl}/PlanningDatesConfiguration/Post/`, obj);
  }

  Update(obj) {
    return this._http.post(`${environment.apiUrl}/PlanningDatesConfiguration/Put/`, obj);
  }

  ExcelExport(year:string){
    return this._http.get(`${environment.apiUrl}/PlanningDatesConfiguration/ExcelExport?year=` + year, {responseType: "blob"});
  }
}
