<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
<div *ngIf="!AddUpdateFlag">

    <div class="motif-row headerwithbutton">
        <div class="motif-col-xs-6 motif-col-md-6 motif-col-lg-6">
          <div class="motif-modal-header" >
            <h3 class="motif-h3" style="width: max-content;"> Email Configuration For Pre-Approval</h3>
          </div>
        </div>
          <div class="motif-col-xs-6 motif-col-md-6 motif-col-lg-6 buttonsdiv">
            <button size="large" motifButton color="primary" routerLink="/AddUpdatePreApprovalEmailConfiguration" (click)="Addemailconfig()" title="Add Email Configuration" >
              <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/content/ic_add_circle_24px.svg'"></motif-icon>Add Email Configuration
            </button>
            <button motifButton color="primary" size="large" (click)="ExportExcel()" style="margin-left: 20px !important;"><motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
            </button>
          </div>
      </div>

<div class="playbook-main">
        <div class="myemailtable" style=" width:100%;"> 
            <div class="motif-table"  >
              <div id="dvag-grid"  class="motif-table-wrapper" [ngClass]="{'motif-table-first-column-border': true}">
                 <ag-grid-angular  [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="tableDataset" [modules]="modules" (gridReady)="handleGridReady($event)" [animateRows]="true"
                   [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents" [ngStyle]="style">
                 </ag-grid-angular>
              </div>
            </div>
         </div>

    <motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
        <motif-modal-header> Delete Pre-Approval Email Configuration </motif-modal-header>
        <motif-modal-body>
            <p> Are you sure, you want to delete Pre Approval Email Configuration? </p>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="Delete()">Confirm</button>
            <button motifButton color="warn" (click)="deletemodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>
</div>

</div>

<app-add-update-pre-approval-email-configuration [Params]="EditParams" 
                (BackEventEmitter)="onBack($event)" *ngIf="AddUpdateFlag">
            </app-add-update-pre-approval-email-configuration>


<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
    {{toastmessage}}
</motif-toast>
</div>

