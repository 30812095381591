import { Component, HostListener, OnInit } from '@angular/core';
import { HolidayTableService } from './holiday-table.service';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HolidayEditDeleteActionsComponent } from './holiday-tableactions';
import { saveAs } from 'file-saver';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-holiday-table',
  templateUrl: './holiday-table.component.html',
  styleUrls: ['./holiday-table.component.scss']
})
export class HolidayTableComponent implements OnInit {


  //#region ag-grid_variables
  modules = AllCommunityModules;
  gridApi: any;
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };
  public btnsubmittext: string = "ADD";
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public ColumnDefs: any;
  public frameworkComponents: any;
  public tableDataset: any;
  public deletemodalflag: boolean = false;
  form: FormGroup;
  public selectedYear: any;
  public lstYear: any = [];
  public ISadd = true;
  public editid: any;

  public editrowdata: any;

  public hasholidays: any = true;

  public isfixeddate: any;
  //#endregion ag-grid_variables
  public hcanton:any;
  peopleFilter: any;
  cantonreset:any = true;

 //#region  toast

public toasttype: string;
public toastflag: boolean;
public toastmessage: string;

//#endregion toast

//#region upload
public showFileUploadModel:boolean=false;
public uploadedfile: any;
public  Uploader: FileUploader = new FileUploader({ url: '' });
  public uploadfileerror: boolean = false;
  public Validationsmodalflag: boolean = false;
  public invalidarryKeys: any;
  public validationdata: any;
 
  
//#endregion Upload
public Escmodalflag:boolean=false;
public editflag:any;



  constructor(private _service: HolidayTableService, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.LoadCantons();
    this.buildHeaders();
    this.form = this.HolidayForm();
    //this.GeneratYear();
    this.setFrameworkoptions();
    this.setTableHeight();
  }
  setTableHeight() {
    this.style.marginTop = '20px';
    this.style.width = "100%";
    this.style.height = (document.documentElement.clientHeight - 150) + "px";
  }

  public show2: boolean;
  public cantonMasterData: any =[]

  private LoadCantons() {
    this._service.getCantons()
      .subscribe(
        res => {
          this.cantonMasterData = res[0];
         

        },
        
      
      )
  }
  onFilterChangePeople(filter) {

    this.peopleFilter = filter;

}
  public AddnewHoliday() {
    ;

    this.ISadd = true;
    this.show2 = true;
    this.form.reset();
    this.editflag="add";


  }



  public HolidayForm(): FormGroup {


    return this._formBuilder.group({
      canton: [null, [Validators.required]],
      holidayName: [null, [Validators.required]],
      HolidayDate: [null, [Validators.required]]

    });

  }



  handleGridReady(params) {
    this.gridApi = params;
    this.gridApi.api.showLoadingOverlay()
    this.gridApi.api.sizeColumnsToFit()
    this.GeneratYear();
    this.LoadHolidays(this.selectedYear);

  };

  public GeneratYear() {
    var year = new Date().getFullYear();
    year = 2021;
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
      this.lstYear.push(year + i);
    }
    this.selectedYear = new Date().getFullYear();
  }
  public LoadHolidays(year) {

    this._service.get(year)
      .subscribe(
        res => {


          this.tableDataset = res;
          if (this.tableDataset.length == 0) {

            this.hasholidays = false;
          } else {
            this.hasholidays = true;
          }
        },
        err => {

          console.log(err);
        }
      )
  }


  public createNewHoliday(form: FormGroup) {
    ;

    this.validateForm(form);

    var holidaydate = new Date(this.form.value.HolidayDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.HolidayDate.singleDate.formatted.split('.')[0] +
      '-' + this.form.value.HolidayDate.singleDate.formatted.split('.')[2])

    if (form.valid) {
      var objHoliday = {
        id: "",
        holidayName: form.value.holidayName,
        startAt: holidaydate,
        endAt: holidaydate,
        isFixedDate: false,
        isManuel: true,
        iscaliculatedDate: false,
        year: this.selectedYear,
        isGlobalHoliday: false,
        cantons: []
      }

      // form.value.canton = [];
      var cantons = form.value.canton;

      for (var i = 0; i < cantons.length; i++) {
        objHoliday.cantons.push({ "cantonShortname": cantons[i] });
      }



      this._service.Add(objHoliday)
        .subscribe(
          (res:any) => {
            ;


            if (res.message == "success") {
              this.toasttype = "success";
              this.toastflag = true;
              this.toastmessage = "Holiday has been added successfully.";
              
              console.log(res);
              this.show2=false;
              console.log(this.toastmessage);
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
              
            }
            else if (res.message == "HolidayExists") {
              this.toasttype = "error";
              this.toastflag = true;
              this.toastmessage = "Holiday already exist.";
              this.show2=false;
              console.log(this.toastmessage);
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            
          },
          err => {
            if (err.toString().toLocaleLowerCase().indexOf('max') != -1 || err.toString().toLocaleLowerCase().indexOf('allowed') != -1) {
              //var key=Object.keys(err["error"]["errors"])[0];   
              //this.toasterService.message.next(err.toString());    
              //  this.toasttype = "warning";
              //this.toastflag = true;

              //this.toastmessage = err.toString();
              this.toasttype = "warning";
              this.toastflag = true;
              this.toastmessage = err.toString();
              this.show2=false;
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
              
            }
            else {

              this.LoadHolidays(this.selectedYear);
              this.show2 = false;
              this.form.reset();
              console.log(err);
            }
          }
        )

    }


  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  setFrameworkoptions() {
    this.frameworkComponents = {
      editDeleteActions: HolidayEditDeleteActionsComponent,
    }

  }

  private buildHeaders() {

    this.ColumnDefs = [


      { headerName: 'Holiday Name', field: 'holidayName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      {
        headerName: 'Holiday Date', field: 'startAt', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellRenderer: (data) => {
          if (data.value != undefined)
            return moment(data.value).format('DD.MM.YYYY')
        }
      },
      {
        headerName: 'Cantons', field: 'cantons', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellRenderer: function (params) {
          var chips = "";
          for (var i = 0; i < params.data.cantons.length; i++) {
            chips = params.data.cantons[i].cantonShortname + "," + chips;

          }
          if (params.data.cantons.length == 0) {
            chips = "All,";
          }
          chips = chips.slice(0, -1); //

          return chips
        }
      },



    ]


    this.ColumnDefs.unshift({
      headerName: 'Actions',
      cellRenderer: "editDeleteActions",
      cellRendererParams: {
        onEditClick: this.onEditClick.bind(this),
        onDeleteClick: this.onDeleteClick.bind(this),

      },
      width: 120,
      sortable: false,
      pinned: 'left',
      autoHeight: false,
      filter: false,

    });
  }

  public onEditClick(e) {

    this.ISadd = false;
    this.show2 = true;
    console.log("EDIT");
    this.form.reset();
    this.editflag="edit";

    var cantons = [];
    ;
    for (var i = 0; i < e.rowData.cantons.length; i++) {
      cantons.push(e.rowData.cantons[i].cantonShortname.trim());
    }

    if (e.rowData.isFixedDate == 1 || e.rowData.isCaliculatedDate == 1) {
      this.isfixeddate = true;
    } else {
      this.isfixeddate = false;
    }



    this.editid = e.rowData.id;
    this.show2 = true;


    var holidaydate = {
      isRange: false, singleDate: {
        date: {
          year: Number(e.rowData.startAt.split('T')[0].split('-')[0]),
          month: Number(e.rowData.startAt.split('T')[0].split('-')[1]),
          day: Number(e.rowData.startAt.split('T')[0].split('-')[2])
        },
        formatted: e.rowData.startAt.split('T')[0]
      }
    }

    this.form.setValue({

      holidayName: e.rowData.holidayName,
      HolidayDate: holidaydate,
      canton: cantons
    });

    this.editrowdata = e.rowData;

  }

  public GenerateHolidays() {



    this._service.GenarateHolidays(this.selectedYear)
      .subscribe(
        res => {


          this.LoadHolidays(this.selectedYear);


        },
        err => {
          this.LoadHolidays(this.selectedYear);

        }
      )
  }

  public onDeleteClick(e) {
    console.log("Delete");
    console.log(e);

    this.deletemodalflag = true;

    this.editid = e.rowData.id;

  }
  public Delete() {


    this._service.Delete(this.editid)
      .subscribe(
        res => {


          this.LoadHolidays(this.selectedYear);
          this.deletemodalflag = false;

        },
        err => {
          this.LoadHolidays(this.selectedYear);
          this.deletemodalflag = false;
        }
      )

  }
  public Update(form: FormGroup) {
    ;




    this.validateForm(form);

    // var holidaydate= new Date(this.form.value.HolidayDate.singleDate.formatted.split('.')[1]+'-'+this.form.value.HolidayDate.singleDate.formatted.split('.')[0]+
    // '-'+this.form.value.HolidayDate.singleDate.formatted.split('.')[2])
    // var holidaydate= new Date(this.form.value.HolidayDate.singleDate.formatted.split('.')[1]+'-'+this.form.value.HolidayDate.singleDate.formatted.split('.')[0]+
    // '-'+this.form.value.HolidayDate.singleDate.formatted.split('.')[2])
    var holidaydate = new Date(this.form.value.HolidayDate.singleDate.date.year + '-' + this.form.value.HolidayDate.singleDate.date.month + '-' + this.form.value.HolidayDate.singleDate.date.day)

    

    if (form.valid) {
      var objHoliday = {
        id: this.editid,
        holidayName: form.value.holidayName,
        startAt: holidaydate,
        endAt: holidaydate,
        isFixedDate: this.editrowdata.isFixedDate,
        isManuel: this.editrowdata.isManuel,
        iscaliculatedDate: this.editrowdata.iscaliculatedDate,
        year: 0,
        isGlobalHoliday: this.editrowdata.isGlobalHoliday,
        cantons: []
      }


      var cantons = form.value.canton;

      for (var i = 0; i < cantons.length; i++) {
        objHoliday.cantons.push({ "cantonShortname": cantons[i] });
      }



      this._service.Update(objHoliday)
        .subscribe(
          (res:any) => {


            this.LoadHolidays(this.selectedYear);
            this.show2 = false;
            this.form.reset();
            if (res.message == "HolidayExists") {
              this.toasttype = "error";
              this.toastflag = true;
              this.toastmessage = "Holiday already exists.";
              this.show2=false;
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            else
              if (res.message == "success") {
                this.toasttype = "success";
                this.toastflag = true;
                this.toastmessage = "Holiday has been updated successfully.";
                this.show2=false;
                setTimeout(() => {
                  this.toastflag=false;
                 }, 3000);
  
              } else
                if (res.message == "fail") {
                  {
                    this.toasttype = "error";
                    this.toastflag = true;
                    this.toastmessage = "An unexpected error has occured. Please contact support team.";
                    this.show2=false;
                    setTimeout(() => {
                      this.toastflag=false;
                     }, 3000);
                  }
                }
          },
          err => {
            this.LoadHolidays(this.selectedYear);
            this.show2 = false;
            this.form.reset();
            console.log(err);
            this.toasttype = "warning";
            this.toastflag = true;
            
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        )

    }
  }

  cancel() {
    ;
    this.show2 = false;
    this.form.reset();
  }

  closeModal(){
    this.form.reset();
    this.form.value.canton = [];
    this.show2 = false;
    this.Escmodalflag = false;
    this.editflag="";
  }
  ExportExcel(){
    this._service.ExportExcel(this.selectedYear).subscribe(
      res => {
        let date = new Date().toString();
        saveAs(res, "Holiday" + date + ".xlsx");

      },
      err => {

        console.log(err);
      }
    )
  }
  ////////////// upload code/////////////////////////////
 ////#region template download
 TempleateExport() {
  this.toastflag = false;
  this._service.TempleateExport()
    .subscribe(
      res => {
        saveAs(res, "HolidayMasterTemplate.xlsx");
      },
      err => {
        console.log(err);
      }
    )
 }

 fileChange($event) {
    if ($event.length > 0)
      this.uploadedfile = $event[0].file.rawFile;
 }
 UploadData() {
  this.toastflag = false;
  let formData: FormData = new FormData();
  formData.append("file", this.uploadedfile);
  formData.append("year", this.selectedYear);
  this._service.Upload(formData)
    .subscribe(
      res => {
        if (res["status"] == "success") {
          this.hideFileUploadModel();
          this.LoadHolidays(this.selectedYear);
          this.toasttype = "success";
          this.toastflag = true;
          this.toastmessage = "Holiday dates has been uploaded successfully.";
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
          
        }
        else if (res["status"] == "fail" || res["status"] == "invalidfile") {
          this.uploadfileerror = true;
        }
        else if (res["status"] == "invalid") {
          this.hideFileUploadModel();
          this.Validationsmodalflag = true;          
          this.invalidarryKeys = Object.keys(res).slice(0, -1);
          this.validationdata = res;
        }
      },
      err => {
        if (err == "Internal Server Error") {
          this.uploadfileerror = true;
        } else {
          this.hideFileUploadModel();
          this.toasttype = "error";
          this.toastflag = true;
          this.toastmessage = "Error occured please contact support team";
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      }
    )
 }

 public hideFileUploadModel() {
    this.showFileUploadModel = false;
    this.clearFiles();
 }
 clearFiles() {
  this.uploadedfile = null;
  this.uploadfileerror = false;
  this.Uploader = new FileUploader({ url: '' });
}

public ShowFileUploadModel() {
  this.showFileUploadModel = true;
}

  ////#endregion

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    debugger;
    if(this.editflag == "edit"){
      this.Escmodalflag=true;
    }
    else{
      this.closeModal();
    }
  }  
  
}
