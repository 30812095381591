<footer class="footer">
    <div class="footer__group">
        <img class="footer__image" src="../../../assets/ey-logo-with-tagline.svg" alt="EY Logo with Tagline" />
        <p class="footer__parag">
            <i class="material-icons fCopyRight">copyright</i> 2019 EYGM Limited | All Rights Reserved.
            <br /> Content within this application is confidential and meant for EY staff and EY clients only.
            <br /> Please review the
            <a class="footer--anchor" href="#">Privacy Statement</a> and
            <a class="footer--anchor" href="#">Master Agreement</a> for additional information.
        </p>
    </div>
</footer>