import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-auditassignmenttypechangeslog',
  templateUrl: './auditassignmenttypechangeslog.component.html',
  styleUrls: ['./auditassignmenttypechangeslog.component.scss']
})
export class AuditassignmenttypechangeslogComponent implements ICellRendererAngularComp {
  public params: any;


  agInit(params: any): void {






    switch (params.colDef.field) {

      case "auditTypeRP.oldValue": {
        if (params.data.auditTypeRP != null) {
          this.params = params.data.auditTypeRP.oldValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'RP';
        }
        break;
      }
      case "auditTypeRP.newValue": {
        if (params.data.auditTypeRP != null ) {
          this.params = params.data.auditTypeRP.newValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'RP';
        }
        break;
      }
      case "auditAGMDetails.oldValue": {
        if (params.data.auditAGMDetails != null) {
          this.params = params.data.auditAGMDetails.oldValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'AGM';
        }
        break;
      }
      case "auditAGMDetails.newValue": {
        if (params.data.auditAGMDetails != null ) {
          this.params = params.data.auditAGMDetails.newValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'AGM';
        }
        break;
      }



      case "auditTypeKP.oldValue": {
        if (params.data.auditTypeKP != null) {
          this.params = params.data.auditTypeKP.oldValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'KP';
        }
        break;
      }
      case "auditTypeKP.newValue": {
        if (params.data.auditTypeKP != null) {
          this.params = params.data.auditTypeKP.newValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'KP';
        }
        break;
      }

      case "auditTypeIA.oldValue": {
        if (params.data.auditTypeIA != null) {
          this.params = params.data.auditTypeIA.oldValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'IR';
        }
        break;
      }
      case "auditTypeIA.newValue": {
        if (params.data.auditTypeIA != null) {
          this.params = params.data.auditTypeIA.newValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'IR';
        }
        break;
      }

      case "auditTypeAP.oldValue": {
        if (params.data.auditTypeAP != null) {
          this.params = params.data.auditTypeAP.oldValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'AP';
        }
        break;
      }
      case "auditTypeAP.newValue": {
        if (params.data.auditTypeAP != null) {
          this.params = params.data.auditTypeAP.newValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'AP';
        }
        break;
      }


      case "auditTypeOther.oldValue": {
        if (params.data.auditTypeOther != null) {
          this.params = params.data.auditTypeOther.oldValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'OT';
        }
        break;
      }
      case "auditTypeOther.newValue": {
        if (params.data.auditTypeOther != null) {
          this.params = params.data.auditTypeOther.newValue;
          if (this.params != null && this.params != undefined)
            this.params['auditType'] = 'OT';
        }
        break;
      }


      default: {
        this.params = null
        break;
      }

    }
  }

  refresh(): boolean {
    return false;
  }

}
