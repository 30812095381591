<div class="accessDeied">
    <div class="container">
        <!-- start logo -->
        <a href="" data-scroll-nav="0" class="white-logo" routerLink="/">
            <svg width="100%" height="100%" viewBox="0 0 561 573" version="1.1" width="42px" height="42px" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g>
          <path
            d="M90.031,460.846l102.535,0l0,-59.48l-102.535,0l0,-46.735l113.289,0l-37.513,-65.228l-160.805,0l0,283.405l226.828,0l0,-65.228l-141.799,0l0,-46.734Z"
            style="fill:#ffffff;fill-rule:nonzero;" />
          <path
            d="M382.882,289.403l-48.267,92.469l-48.016,-92.469l-94.033,0l99.034,171.443l0,111.962l84.779,0l0,-111.962l99.285,-171.443l-92.782,0Z"
            style="fill:#ffffff;fill-rule:nonzero;" />
          <path d="M560.693,0l-560.693,204.432l560.693,-98.967l0,-105.465Z" style="fill:#ffe600;fill-rule:nonzero;" />
        </g>
      </svg>
        </a>
        <!-- end logo -->

        <svg width="380px" height="500px" viewBox="0 0 837 1045" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <path d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
          id="Polygon-1" stroke="#336699" stroke-width="6" sketch:type="MSShapeGroup"></path>
        <path d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
          id="Polygon-2" stroke="#F04C3E" stroke-width="6" sketch:type="MSShapeGroup"></path>
        <path d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
          id="Polygon-3" stroke="#91278F" stroke-width="6" sketch:type="MSShapeGroup"></path>
        <path d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
          id="Polygon-4" stroke="#F2773F" stroke-width="6" sketch:type="MSShapeGroup"></path>
        <path d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
          id="Polygon-5" stroke="#36B455" stroke-width="6" sketch:type="MSShapeGroup"></path>
      </g>
    </svg>
        <div class="message-box">
            <h1>Access denied!</h1>
            <p>For access register your-self.</p>
            <div class="buttons-con">
                <div class="action-link-wrap">
                    <a routerLink="" style="float: right;" class="butn link-button link-back-button">Home</a>
                </div>
            </div>
        </div>
        <div class="ToolName">
            <a href="" routerLink="/">
                <h1 class="">Audit Management Tool</h1>
            </a>
        </div>
    </div>
</div>