import { Injectable } from '@angular/core';
import { environment } from 'src/app/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PreApprovalserviceService {


  private preapprovalobj:any={
    auditassignmentid:"",
     screen:""
   }
  constructor(private _http: HttpClient) { }

  GetMasterData() {   
        
    return this._http.get(`${environment.apiUrl}/preapproval/GetMasterData/`);

  }
  setupdateData(obj) {      
    this.preapprovalobj = obj;  
  }  
  getupdateData() {  
    return this.preapprovalobj;  
  }

  // GetClientENtity() {
  //    ;
  //      return this._http.get(`${environment.apiUrl}/preapproval/GetClientEntity`);
  // }

  GetEntityDetails() {   
        
    return this._http.get(`${environment.apiUrl}/preapproval/GetEntityDetails/`);

  }
  
  Add(obj) {
    return this._http.post(`${environment.apiUrl}/preapproval/AddPreApproval`, obj);
  }
  
  PreApprovalTempleateExport() {  
        
    return this._http.get(`${environment.apiUrl}/preapproval/DownloadTemplate/`,{responseType: "blob"});

  }
  Upload(formdata:FormData) {   
        
    return this._http.post(`${environment.apiUrl}/preapproval/Upload/`, formdata);

  } 
  get() {
    return this._http.get(`${environment.apiUrl}/preapproval/getPreApproval`);
  }
  Update(obj) {
    return this._http.post(`${environment.apiUrl}/preapproval/UpdatePreApproval/`, obj);
  }
  delete(id) {
    ;
    // return this._http.post(`${environment.apiUrl}/preapproval/DeletePreApproval/?id=`,+id)

  return this._http.post(`${environment.apiUrl}/preapproval/DeletePreApproval/`,{id:id});
  
  }
  PreApprovalExcelexport(columnnames) {   
        
    return this._http.post(`${environment.apiUrl}/preapproval/ExcelExport/`,columnnames,{responseType: "blob"});

  }


  getAuditTrail() {
       
    return this._http.get(`${environment.apiUrl}/preapproval/GetAuditTrailPreApproval`);

  }
}
