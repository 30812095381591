import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AgdatetimepickerComponent } from 'src/app/reusable/components/ag-grid/date-time-picker/agdatetimepicker.component';
import { PreApprovalserviceService } from '../pre-approvalservice.service';
import { PreapprovalauditlistComponent } from '../TableActions/preapprovalauditlist/preapprovalauditlist.component';

@Component({
  selector: 'app-audit-pre-approval',
  templateUrl: './audit-pre-approval.component.html',
  styleUrls: ['./audit-pre-approval.component.scss']
})
export class AuditPreApprovalComponent implements OnInit {

  public auditTrailActiveTab :string='Timeline';
  public AuditTrailDataset:any;
  public DirectoryAuditDataSet:any;
  public AuditTrailfilterDataset:any;
  ////#endregion tab variables
  ////#region  loader variables start
  public loaderflag:boolean=false;
  ////#endregion loader variable end
  ////#region Timeline Variables start
  
  public adtinsertshow: boolean = true;
  public adtbulkinsertshow: boolean = true;
  public adtupdateshow: boolean = true;
  public adtdeleteshow: boolean = true;
  
  ////#endregion  Timeline Variables end
  ////#region Ag grid variables start
  public DirectoryAuditColumnDefs:any;
  gridApi: any;
    auditgridApi: any;
    auditframeworkComponents: any;
  
    public style = {
      marginTop: "",
      width: "",
      height: ""
    };
  
    public auditstyle = {
      height: ""
    }
    public overlayLoadingTemplate: string =
      ' <span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  
      modules = AllCommunityModules;
  ////#endregion Ag grid variables end
    constructor(private _preapprovalService:PreApprovalserviceService) {
      this.auditframeworkComponents = {
       // agDateInput: AgdatetimepickerComponent,
        auditlist: PreapprovalauditlistComponent
  
  
      }
     }
  
    ngOnInit(): void {
      
      this.getAuditTrail();
     // this.getAuditTrail();
      
    }
  
  ////#region AuditTrail Methods start
  getAuditTrail() {
    ;
    this.setTableHeight();
  //  this.currentactivetab = "Audit";
    this.loaderflag = true;
  
    this._preapprovalService.getAuditTrail()
      .subscribe(
        res => {
  
          this.AuditTrailBuildHeaders();
          this.AuditTrailDataset = res;
          this.DirectoryAuditDataSet = res;
          this.AuditTrailfilterDataset = res;
          this.loaderflag = false;
        },
        err => {
  
          console.log(err);
        }
      )
  
  }
  
  ////#endregion end
  ////#region Timeline Methods start
  audittrailfilter() {
  
  
  
    let filteredset = null;
  
    let audittypes = [];
  
    if (this.adtinsertshow) {
      audittypes.push("Insert")
    }
  
    if (this.adtdeleteshow) {
      audittypes.push("Delete")
    }
  
    if (this.adtbulkinsertshow) {
      audittypes.push("Bulk Upload")
    }
    if (this.adtupdateshow) {
      audittypes.push("Update")
    }
  
  
  
    filteredset = this.AuditTrailfilterDataset.filter(function (i, n) {
  
      return audittypes.indexOf(i.auditType) + 1 > 0;
    });
  
  
    this.AuditTrailDataset = filteredset;
  
  
  
  }
  ////#endregion Time line Methods end
  
  ////#region AG grid Methods start
  
  AuditTrailBuildHeaders() {
    ;
  
    this.DirectoryAuditColumnDefs = [
      {
        headerName: 'Audit Date', field: 'auditDateTime', sortable: true, filter: 'agDateColumnFilter',
        unSortIcon: true, cellClass: "ag-grid-cell-wrap-text",
        wrapText: true,
        autoHeight: true,
        width: 200,
        resizable: true,
        cellRenderer: (data) => {
          
          return moment(data.value).format('DD.MM.YYYY HH:mm')
        },
       filterParams: {
         buttons: ['reset', 'apply'],
     
         comparator: function (filterLocalDate, cellValue) {
           
           filterLocalDate.setMilliseconds(0);
           cellValue = new Date(cellValue);
           cellValue.setMilliseconds(0);
           cellValue.setSeconds(0);
            let filterBy = filterLocalDate.getTime();
           let filterMe = cellValue.getTime();
           
           if (filterBy == filterMe) {
             
              return 0;
            }
  
            if (filterMe < filterBy) {
              return -1;
            }
  
            if (filterMe > filterBy) {
              return 1;
            }
          
        
      }
        },
      
      },
      {
        headerName: 'Action', field: 'auditType', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellClassRules: {
          'ag-grid-auditlog-status-add': function (params) {
            
            if (params.data) {
              return params.data.auditType == 'Add';
            }
          },
          'ag-grid-auditlog-status-Update': function (params) {
            if (params.data) {
              return params.data.auditType == 'Update';
            }
          },
          'ag-grid-auditlog-status-Delete': function (params) {
            if (params.data) {
              return params.data.auditType == 'Delete';
            }
          },
          'ag-grid-auditlog-status-BulkUpload': function (params) {
            if (params.data) {
              return params.data.auditType == 'Bulk Insert';
            }
          }
        }
      },
      {
        headerName: 'Pre-Approval', field: 'formTitle', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true
      },
      {
        headerName: 'Old Values', field: 'oldValues', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellRenderer: "auditlist",
        autoHeight: true,
        filterParams: {
          valueGetter: params => {
            return JSON.stringify(params.data.oldValues)
          },
          textCustomComparator: this.agcustomauditlogfilter
  
        }
      },
      {
        headerName: 'New Values', field: 'newValues', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellRenderer: "auditlist",
        autoHeight: true,
        filterParams: {
          valueGetter: params => {
            
            return JSON.stringify(params.data.newValues)
          },
          textCustomComparator: this.agcustomauditlogfilter
  
        }
      },
      
  
      {
        headerName: 'Updated By', field: 'userName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true
      },
      {
        headerName: 'Rows Updated', field: 'rowCount', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true
      }
  
    ];
  
  
  }
  
  
  agcustomauditlogfilter(filter, value, filterText) {
      
    let filterTextLowerCase = filterText.toLowerCase();
  
  
    if (value != null && value != undefined && value != "null") {
      var obj = JSON.parse(value);
      let values = Object.keys(obj).map((key) => {
        
  
  
        if (obj[key] != null) {
          return (key+" : " +obj[key]).toLowerCase();
        }
  
       
      });
  
      let valueLowerCase = values.join(" ");
  
      //  let valueLowerCase = JSON.parse(value).toString().toLowerCase();
  
      switch (filter) {
        case 'contains':
          return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
        case 'notContains':
          return valueLowerCase.indexOf(filterTextLowerCase) === -1;
        case 'equals':
          return valueLowerCase === filterTextLowerCase;
        case 'notEqual':
          return valueLowerCase != filterTextLowerCase;
        case 'startsWith':
          return valueLowerCase.indexOf(filterTextLowerCase) === 0;
        case 'endsWith':
          var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
          return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
        default:
          // should never happen
          console.warn('invalid filter type ' + filter);
          return false;
      }
    } else return false
  }
  handleauditGridReady(params) {
    this.auditgridApi = params;
    this.auditgridApi.api.sizeColumnsToFit()
  }
  setTableHeight() {
    this.style.marginTop = '20px';
    this.style.width = "100%";
  
    this.style.height = (document.documentElement.clientHeight -200)+ "px";
  
  
    this.auditstyle.height = (document.documentElement.clientHeight - 200) + "px";
  
  
  
  }


  // refreshgrid(){
  //   this.auditTrailActiveTab ='Grid'
  //   this.AuditTrailBuildHeaders();
  // }
  ////#endregion ag grid end
  }
  