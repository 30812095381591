
<ul>
<li *ngIf="params?.Classification !=undefined"> Classification : {{params.Classification}}  </li>

<li *ngIf="params?.ClientContact !=undefined"> Client Contact : {{params.ClientContact}}  </li>

<li *ngIf="params?.GroupScope !=undefined"> GroupScope : {{params.GroupScope}}  </li>
<li *ngIf="params?.FormStatus !=undefined"> Form Status : {{params.FormStatus}}  </li>

<li *ngIf="params?.Comment !=undefined"> Comment : {{params.Comment}}  </li>
<li *ngIf="params?.EngagementPartnerId !=undefined">
    Engagement Partner :
    {{params?.EngagementPartnerId}} </li>



<!-- <li *ngIf="params?.EngagementManagerId !=undefined">
    Engagement Manager :
    {{params?.EngagementManagerId}} </li> -->
<li *ngIf="params?.FinmaLeadAuditorId !=undefined">
    FINMA Lead Auditor :
    {{params?.FinmaLeadAuditorId}} </li>

<li
    *ngIf="params?.InternalAuditEngagementLeaderId !=undefined">
    Internal Audit Engagement Leader :
    {{params?.InternalAuditEngagementLeaderId}}
</li>

</ul>