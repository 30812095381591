import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuditTypeService {

  constructor(private _http: HttpClient) { }


  get() {
    return this._http.get(`${environment.apiUrl}/audittypestatusot/get`);
  }

  delete(id) {
    return this._http.post(`${environment.apiUrl}/audittypestatusot/Delete/`,{id:id});
  }

  Add(obj) {
    return this._http.post(`${environment.apiUrl}/audittypestatusot/Post/`, obj);
  }

  Update(obj) {
    return this._http.post(`${environment.apiUrl}/audittypestatusot/Update/`, obj);
  }
  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/audittypestatusot/ExcelExport`, {responseType: "blob"});
  }
}
