import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApprovalStatusService {

  constructor(private _http: HttpClient) { }


  get() {
    return this._http.get(`${environment.apiUrl}/ApprovalStatus/get`);
  }

  delete(id) {
    return this._http.post(`${environment.apiUrl}/ApprovalStatus/Delete/`,{id:id});
  }

  Add(obj) {
    return this._http.post(`${environment.apiUrl}/ApprovalStatus/Post/`, obj);
  }

  Update(obj) {
    return this._http.post(`${environment.apiUrl}/ApprovalStatus/Update/`, obj);
  }  
  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/ApprovalStatus/ExcelExport`, {responseType: "blob"});
  }
}
