<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
    <div class="playbook-main">
        <div class="examples-container">

            <div class="motif-row" style="margin-bottom:20px">

                <div class="motif-col-xs-4 motif-col-lg-3">
                    <motif-tab-bar class="motif-tab-bar">
                        <button motifButton (click)="currentactivetab = 'Client'"
                            [ngClass]="{'motif-active': currentactivetab === 'Client'}"
                            class="motif-text-link motif-active">
                            <motif-icon [src]="'/assets/icons/maps/ic_store_mall_directory_24px.svg'"></motif-icon>
                            Client Entities
                        </button>
                        <button motifButton (click)="getAuditTrail()" *ngIf="ActionsCheck('Audit Trail')"
                            [ngClass]="{'motif-active': currentactivetab === 'Audit'}" class="motif-text-link">
                            <motif-icon [src]="'/assets/icons/action/ic_history_24px.svg'"></motif-icon>
                            Audit Trail
                        </button>
                    </motif-tab-bar>
                </div>

                <div class="motif-col-xs-8 motif-col-lg-9">

                    <div class="motif-row motif-end-lg" *ngIf="currentactivetab == 'Client'">
                        <!-- <div class="motif-col-xs-4 motif-col-md-2">
&nbsp;
                    </div> -->
                        <input motifInput id="Search" placeholder="Search.." type="text"
                            class="motif-col-xs-4 motif-col-lg-5" [(ngModel)]="ngSearchFilter"
                            (ngModelChange)="onFilterTextBoxChanged()" />
                        &nbsp;
                        <button size="large" *ngIf="ActionsCheck('Bulk Upload')" (click)="ShowFileUploadModel()"
                            class="motif-col-xs-4 motif-col-lg-2" motifButton color="primary">
                            <motif-icon class="Custom-motif-button-icon"
                                [src]="'/assets/icons/file/ic_file_upload_24px.svg'"></motif-icon>Bulk Upload
                        </button> &nbsp;
                        <button size="large" *ngIf="ActionsCheck('Export')" class="motif-col-xs-4 motif-col-lg-2"
                            motifButton color="primary" (click)="ExcelExportpopup()">
                            <motif-icon class="Custom-motif-button-icon"
                                [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
                        </button> &nbsp;
                        <button size="large" *ngIf="ActionsCheck('Add')" class="motif-col-xs-4 motif-col-lg-2"
                            motifButton color="primary" (click)="onAddClientEntity()" title="Add Entity">
                            <motif-icon class="Custom-motif-button-icon"
                                [src]="'/assets/icons/content/ic_add_circle_24px.svg'"></motif-icon>Add Entity
                        </button> &nbsp;
                        <!-- <button size="large" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary">
              <motif-icon [src]="'/assets/icons/action/ic_history_24px.svg'"></motif-icon>Audit Trail
            </button> -->
                    </div>
                </div>
            </div>
            <div class="motif-row" *ngIf="currentactivetab == 'Client'">
                <div *ngIf="NgSearchFlag" class="motif-col-xs-4 motif-col-lg-2">
                    <motif-form-field *ngIf="NgSearchFlag">
                        <label motifLabel>Select Region</label>
                        <motif-select [(ngModel)]="RegionSelected" (change)="externalRegionFilterChanged($event)"
                            [multiple]="false"  aria-label="function Form Field">
                            <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionName">
                                {{item.eyregionName}}</motif-option>
                        </motif-select>
                    </motif-form-field>
 
                </div>
                <div *ngIf="NgSearchFlag" class="motif-col-xs-4 motif-col-lg-2">
                    <motif-form-field>
                        <label motifLabel>IR Office</label>
                        <motif-select [(ngModel)]="irofficeSelected" class="ClienentitytModalselect" (change)="externalIROfficeFilterChanged($event)"
                            aria-label="IR office Form Field">
                            <motif-option *ngFor="let item of irRBofficeMasterData" [value]="item.irofficeName">
                                {{item.irofficeName}}</motif-option>
                        </motif-select>
                   
                    </motif-form-field>
                </div>
                <div *ngIf="NgSearchFlag" class="motif-col-xs-2 motif-col-lg-2 clientradiodiv">
                  <motif-form-field>
                    <motif-radio-group [(ngModel)]="BankSelected" (change)="GetClientEntityData()">
                        <div class="motif-row">
                            <div class="motif-col-xs-6 motif-col-lg-6">
                                <motif-radio-button value="allBanks" checked="checked" [title]="'Custom radio element title'">All Banks
                                </motif-radio-button>
                            </div>
                            <div class="motif-col-xs-6 motif-col-lg-6">
                                <motif-radio-button value="myBanks" [title]="'Custom radio element title'">My Banks
                                </motif-radio-button>
                            </div>
                        </div>
                    </motif-radio-group>
                  </motif-form-field>
                </div>      
                <div class="motif-col-xs-4 motif-col-lg-1">
                    <button size="large" motifButton color="primary" title="Reset" (click)="resetFIlters()">
                        <motif-icon class="Custom-motif-button-icon"
                            [src]="'/assets/icons/action/ic_autorenew_24px.svg'"></motif-icon>Reset
                    </button>

                </div>
            </div>

            <div style="width: 100%;" [hidden]="currentactivetab != 'Client'">
                <div class="motif-table">
                    <div id="dvag-grid" class="motif-table-wrapper"
                        [ngClass]="{'motif-table-first-column-border': true}">
                        <ag-grid-angular [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate"
                            class="ag-theme-balham" [columnDefs]="clientEntityColumnDefs" [rowSelection]="rowSelection"
                            [rowData]="clientEntityRowData" [modules]="modules" (gridReady)="handleGridReady($event)"
                            [animateRows]="true" (columnResized)="onColumnResized($event)"
                            (columnVisible)="onColumnVisible($event)" [pagination]="true" [paginationPageSize]=30
                            [frameworkComponents]="frameworkComponents"
                            [isExternalFilterPresent]="isExternalFilterPresent"  [doesExternalFilterPass]="doesExternalFilterPass"></ag-grid-angular>
                    </div>
                </div>
            </div>
            <div [hidden]="currentactivetab != 'Audit'">


                <div class="motif-row motif-end-lg">

                    <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Timeline'}"
                        (click)="auditTrailActiveTab ='Timeline'" motifFab aria-label="floating-action-button"
                        size="small">
                        <motif-icon [src]="'./assets/icons/editor/ic_format_align_left_24px.svg'"></motif-icon>
                    </button> &nbsp;

                    <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Grid'}"
                        (click)="auditTrailActiveTab ='Grid'" motifFab aria-label="floating-action-button" size="small">
                        <motif-icon [src]="'./assets/icons/image/ic_grid_on_24px.svg'"></motif-icon>
                    </button>
                </div>



                <div *ngIf="auditTrailActiveTab =='Timeline'" class="tab2">
                    <div class="timeLineMain">
                        <div class="toggleGroup">
                            <ul class="list-inline">
                                <li style="cursor:pointer" [ngClass]="{'active': adtinsertshow}"
                                    (click)="adtinsertshow=!adtinsertshow;audittrailfilter()">
                                    <span></span>
                                    <label style="cursor:pointer">Add</label>
                                </li>
                                <li style="cursor:pointer" [ngClass]="{'active': adtupdateshow}"
                                    (click)="adtupdateshow=!adtupdateshow;audittrailfilter()">
                                    <span></span>
                                    <label style="cursor:pointer">Update</label>
                                </li>
                                <li style="cursor:pointer" [ngClass]="{'active': adtdeleteshow}"
                                    (click)="adtdeleteshow=!adtdeleteshow;audittrailfilter()">
                                    <span></span>
                                    <label style="cursor:pointer">Delete</label>
                                </li>
                                <li style="cursor:pointer" [ngClass]="{'active': adtbulkinsertshow}"
                                    (click)="adtbulkinsertshow=!adtbulkinsertshow;audittrailfilter()">
                                    <span></span>
                                    <label style="cursor:pointer">Bulk Upload</label>
                                </li>
                            </ul>
                        </div>
                        <div class="timeLineItem">
                            <ul style="min-height:800px" class="list-inline">
                                <li *ngFor="let ad of AuditTrailDataset">
                                    <div class="dateTime">
                                        <!--September 14, 2020-->
                                        <span class="tlDate">{{ ad.auditDateTime | momentPipe :'LL'}} </span>
                                        <span class="tlTime"> {{ ad.auditDateTime | momentPipe:'LT'}} </span>
                                    </div>
                                    <div class="timelineMainItem" [ngClass]="
                         {
                         'insertItem': ad.auditType == 'Insert',
                         'updateItem': ad.auditType == 'Update',
                         'deleteItem': ad.auditType  == 'Delete',
                         'bulkInsertItem':ad.auditType  == 'Bulk Upload'

                         }">
                                        <span class="BoldHead1">{{ad.userName}}<a> ({{ad.email}})</a></span>
                                        <div class="content">
                                            <p *ngIf="ad.auditType  == 'Bulk Upload'"><b>{{ad.rowCount}}</b> Client
                                                Entities has been uploaded.</p>
                                            <p *ngIf="ad.auditType  == 'Insert'">Client Entity
                                                <span class="BoldHead1">{{ad.clientEntityName}}</span> has been inserted.</p>
                                            <p *ngIf="ad.auditType  == 'Delete'">Client Entity
                                                <span class="BoldHead1">{{ad.clientEntityName}}</span> has been deleted.</p>
                                            <p *ngIf="ad.auditType  == 'Update'">Client Entity
                                                <span class="BoldHead1">{{ad.clientEntityName}}</span> has been Updated.</p>

                                            <ul *ngIf="ad.auditType  == 'Update'">


                                                <li *ngIf="ad.newValues?.ClientEntityName !=undefined"> Entity Name :
                                                    {{ad.newValues.ClientEntityName}} </li>

                                                <li *ngIf="ad.newValues?.RbShortName !=undefined"> Entity Short Name :
                                                    {{ad.newValues.RbShortName}} </li>

                                                <li *ngIf="ad.newValues?.BankCodeRgrCid !=undefined"> Bank Code :
                                                    {{ad.newValues.BankCodeRgrCid}} </li>

                                                <li *ngIf="ad.newValues?.EyCidGfis !=undefined"> EY CID(GFIS) :
                                                    {{ad.newValues.EyCidGfis}} </li>



                                                <li *ngIf="ad.newValues?.MercuryEntityId !=undefined"> ClientID(Mercury)
                                                    : {{ad.newValues.MercuryEntityId}} </li>

                                                <li *ngIf="ad.newValues?.Street !=undefined"> Street :
                                                    {{ad.newValues.Street}} </li>

                                                <li *ngIf="ad.newValues?.PostalCode !=undefined"> Postal Code :
                                                    {{ad.newValues.PostalCode | number| removeComma}} </li>



                                                <li *ngIf="ad.newValues?.Location !=undefined"> Location : {{
                                                    ad.newValues?.Location}} </li>


                                                <li *ngIf="ad.newValues?.Canton !=undefined"> Canton :
                                                    {{ad.newValues.Canton}} </li>

                                                <li *ngIf="ad.newValues?.Country !=undefined"> Country :
                                                    {{ad.newValues.Country}} </li>

                                                <li *ngIf="ad.newValues?.ReportingLanguage !=undefined"> Reporting
                                                    Language : {{ad.newValues.ReportingLanguage}} </li>

                                                <li *ngIf="ad.newValues?.DocumentationLanguage !=undefined">
                                                    Documentation language : {{ad.newValues.DocumentationLanguage}}
                                                </li>

                                                <li *ngIf="ad.newValues?.BankSize !=undefined"> Bank size :
                                                    {{ad.newValues.BankSize}} </li>

                                                <li *ngIf="ad.newValues?.Eyoffice !=undefined"> EY Office :
                                                    {{ad.newValues.Eyoffice}} </li>

                                                <li *ngIf="ad.newValues?.Eyregion !=undefined"> EY Region :
                                                    {{ad.newValues.Eyregion}} </li>

                                                <li *ngIf="ad.newValues?.Iroffice !=undefined">IR office :
                                                    {{ad.newValues.Iroffice}} </li>


                                                <li *ngIf="ad.newValues?.Uidnumber != undefined">UID-No :
                                                    {{ad.newValues.Uidnumber}} </li>

                                                <li *ngIf="ad.newValues?.Finmaid !=undefined">FINMA ID :
                                                    {{ad.newValues.Finmaid}} </li>

                                                <li *ngIf="ad.newValues?.ClientFiscalYearEnd != undefined">Fiscal Year
                                                    End : {{ ad.newValues.ClientFiscalYearEnd}} </li>

                                                <li *ngIf="ad.newValues?.Faoaregister !=undefined">FAOA Register –
                                                    entity Type : {{ad.newValues.Faoaregister}} </li>


                                                <li *ngIf="ad.newValues?.Gismarkup !=undefined">GIS Mark-up :
                                                    {{ad.newValues.Gismarkup}} </li>
                                                <li *ngIf="ad.newValues?.Gisid !=undefined">GIS ID :
                                                    {{ad.newValues.Gisid| number| removeComma}} </li>


                                                <li *ngIf="ad.newValues?.FormStatus !=undefined">Form Status :
                                                    {{ad.newValues.FormStatus}} </li>
                                                <li *ngIf="ad.newValues?.Comments !=undefined">Comment - Entity Data :
                                                    {{ad.newValues.Comments}} </li>





                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <!--<li>
            <div class="dateTime">
              <span class="tlDate">September 14, 2020 </span>
              <span class="tlTime">10:15 AM</span>
            </div>
            <div class="timelineMainItem updateItem">
              <h5>Hari</h5>
              <div class="content">
                <p>Item description writing here!</p>
                <ul>
                  <li>Item1</li>
                  <li>Item2</li>
                  <li>Item3</li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="dateTime">
              <span class="tlDate">September 14, 2020 </span>
              <span class="tlTime">10:15 AM</span>
            </div>
            <div class="timelineMainItem deleteItem">
              <h5>Hari</h5>
              <div class="content">
                <p>Item description writing here!</p>
                <ul>
                  <li>Item1</li>
                  <li>Item2</li>
                  <li>Item3</li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="dateTime">
              <span class="tlDate">September 14, 2020              </span>
              <span class="tlTime">10:15 AM</span>
            </div>
            <div class="timelineMainItem bulkInsertItem">
              <h5>Hari</h5>
              <div class="content">
                <p>Item description writing here!</p>
                <ul>
                  <li>Item1</li>
                  <li>Item2</li>
                  <li>Item3</li>
                </ul>
              </div>
            </div>
          </li>-->
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="auditTrailActiveTab=='Grid'" class="tab2">

                    <ag-grid-angular [frameworkComponents]="auditframeworkComponents"
                        (gridReady)="handleauditGridReady($event)" [ngStyle]="style"
                        [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-material motif-ag-grid"
                        [columnDefs]="clientEntityAuditColumnDefs" [rowData]="clientEntityAuditDataSet"
                        [modules]="modules" [animateRows]="true" (columnResized)="onColumnResized($event)"
                        (columnVisible)="onColumnVisible($event)" [pagination]="true" [paginationPageSize]=30>
                    </ag-grid-angular>

                </div>

            </div>
        </div>


    </div>


    <!-- FileUpload Model Start-->
    <motif-modal [show]="showFileUploadModel" (close)="showFileUploadModel = false">
        <motif-modal-header>
            Upload
        </motif-modal-header>
        <motif-modal-body>



            <div *ngIf="showFileUploadModel" class="motif-container">

                <div class="motif-row motif-end-lg">
                    <button style="margin-bottom:8px" size="large" class="motif-col-xs-4 motif-col-md-2" motifButton
                        color="primary" (click)="ClientMasterTempleateExport()">
                        <motif-icon [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Download
                        Template
                    </button>


                </div>

                <motif-form-field>
                    <motif-file-upload [disabled]="ClientEntityUploader.queue.length > 0 " (change)="fileChange($event)"
                        [uploader]="ClientEntityUploader" [multiple]="false">
                        Drop files here or click to upload
                    </motif-file-upload>
                </motif-form-field>

                <motif-uploader (cancel)="clearEntityFiles()" *ngFor="let item of ClientEntityUploader?.queue"
                    fileName="{{item.file.name}}" progressValue="100" fileSize="" progressLabel=""
                    errorMessage="There was a problem while uploading the file, please upload valid file"
                    [error]="uploadfileerror">
                </motif-uploader>


            </div>


        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" [disabled]="ClientEntityUploader.queue.length == 0 "
                (click)="UploadData()">Confirm</button>
            <button motifButton color="secondary" (click)="showFileUploadModel = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>

    <!--- FileUpload Model End-->

    <!-- modalstart -->
    <motif-modal [show]="clientEntityModal" (close)="clientEntityModal = false" [slideout]="true"
        class="ClienentitytModal">
        <motif-modal-header>
            Client Entity
        </motif-modal-header>
        <motif-modal-body>
            <form [formGroup]="form" novalidate >
                <fieldset [disabled]='viewcliententity' style="border: 0;">
                <span class="fonts-col__heading-4"><span class="BoldHead2"> Entity Details </span></span>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Entity Name</label>
                            <input motifInput id="entName" type="text" aria-label="Entity Name Form Field"
                                formControlName="entityname" />
                            <motif-error *ngIf="
                                        form.get('entityname').hasError('required') &&
                                        form.get('entityname').touched
                                        ">Please provide a entity name!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Entity Short Name</label>
                            <input motifInput type="text" aria-label="Entity Short Name Form Field"
                                formControlName="entityshortname" />
                            <motif-error *ngIf="
                                    form.get('entityshortname').hasError('required') &&
                                    form.get('entityshortname').touched
                                    ">Please provide a entity short name!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Bank Code (Numeric)</label>
                            <input motifInput type="number" (change)="GenerateBankCode()"
                                aria-label="Bank Code Form Field" formControlName="bankcodenumeric" />
                            <motif-error *ngIf="
                                form.get('bankcode').hasError('required') &&
                                form.get('bankcode').touched
                                ">Please provide a bank code!</motif-error>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Bank Code</label>
                            <input motifInput type="text" disabled aria-label="Bank Code Form Field"
                                formControlName="bankcode" />
                            <motif-error *ngIf="
                                form.get('bankcode').hasError('required') &&
                                form.get('bankcode').touched
                                ">Please provide a bank code!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>EY CID (GFIS)</label>
                            <input motifInput type="text" aria-label="EY CID(GFIS) Form Field"
                                formControlName="eycid" />
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Client ID (Mercury)</label>
                            <input motifInput type="text" aria-label="Client Id Form Field"
                                formControlName="clientid" />
                        </motif-form-field>
                    </div>
                </div>
                <span class="fonts-col__heading-4"><span class="BoldHead2">Address</span> </span>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Street</label>
                            <input motifInput type="text" aria-label="Street Form Field" formControlName="street" />
                            <motif-error *ngIf="
                                        form.get('street').hasError('required') &&
                                        form.get('street').touched
                                        ">Please provide a street</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Postal Code</label>
                            <input motifInput type="number" aria-label="Postal Code Form Field"
                                formControlName="postalcode" />
                            <motif-error *ngIf="
                                    form.get('postalcode').hasError('required') &&
                                    form.get('postalcode').touched
                                    ">Please provide a postal code!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <!-- <motif-form-field> 
                        <label motifLabel>Location</label> -->
                        <!-- <p-dropdown [options]="locationMasterData" formControlName="location" optionLabel="locationName"
                            [filter]="true" filterBy="locationName" [showClear]="true" placeholder="Location">
                            <ng-template pTemplate="selectedItem">
                                <div class="ClienentitytModalselect" *ngIf="form.value.location">
                                    <div><b class="newAddedLabel"> Location </b> {{form.value.location.locationName}}
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template let-loc pTemplate="item">
                                <div class="country-item">
                                    <div>{{loc.locationName}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown> -->


                        <motif-form-field>
                            <label motifLabel>Location</label>
                            <input motifInput type="text" aria-label="Street Form Field" formControlName="location" />
                            <motif-error *ngIf="
                                        form.get('location').hasError('required') &&
                                        form.get('location').touched
                                        ">Please provide a Location</motif-error>
                        </motif-form-field>
                  

                        <!-- </motif-form-field> -->
                        <!-- </motif-form-field>  -->
                        <!-- <motif-select formControlName="location"  class="ClienentitytModalselect" aria-label="Location Form Field">
                            <motif-option *ngFor="let item of locationMasterData" [value]="item.locationId">
                                {{item.locationName}}</motif-option>
                        </motif-select>
                       
                    </motif-form-field> -->
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Canton</label>
                            <motif-select formControlName="canton" class="ClienentitytModalselect" [multiple]="false"
                                aria-label="Canton Form Field">
                                <motif-option  *ngFor="let item of cantonMasterData"  [value]="item.cantonName">
                                    {{item.cantonName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                        form.get('canton').hasError('required') &&
                        form.get('canton').touched
                        ">Please select a Canton!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Country</label>
                            <motif-select formControlName="country" class="ClienentitytModalselect"
                                aria-label="Country Form Field">
                                <motif-option *ngFor="let item of countryMasterData" [value]="item.countryId">
                                    {{item.countryName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                        form.get('country').hasError('required') &&
                        form.get('country').touched
                        ">Please select a country!</motif-error>
                        </motif-form-field>
                    </div>
                </div>
                <span class="fonts-col__heading-4"><span class="BoldHead2">Localization</span> </span>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Reporting Language</label>
                            <motif-select formControlName="reportinglanguage" class="ClienentitytModalselect"
                                aria-label="Reporting language Form Field">
                                <motif-option *ngFor="let item of reportingLanMasterData"
                                    [value]="item.reportingLanguageId">
                                    {{item.reportingLanguageName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                        form.get('reportinglanguage').hasError('required') &&
                        form.get('reportinglanguage').touched
                        ">Please select a reporting language!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Documentation Language</label>
                            <motif-select formControlName="documentationlanguage" class="ClienentitytModalselect"
                                aria-label="Documentation language Form Field">
                                <motif-option *ngFor="let item of documentLanMasterData"
                                    [value]="item.documentationLanguageId">
                                    {{item.documentationLanguageName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                        form.get('documentationlanguage').hasError('required') &&
                        form.get('documentationlanguage').touched
                        ">Please select a documentation language!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Bank Size</label>
                            <motif-select formControlName="banksize" class="ClienentitytModalselect"
                                aria-label="Bank size Form Field">
                                <motif-option *ngFor="let item of bankSizeMasterData" [value]="item.bankSizeId">
                                    {{item.bankSizeName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                        form.get('banksize').hasError('required') &&
                        form.get('banksize').touched
                        ">Please select a bank size!</motif-error>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>EY Office</label>
                            <motif-select formControlName="eyoffice" class="ClienentitytModalselect"
                                aria-label="EY Office Form Field">
                                <motif-option *ngFor="let item of eyOffcieMasterData" [value]="item.eyofficeId">
                                    {{item.eyOfficeName}}</motif-option>
                            </motif-select>
                            <!-- <motif-error *ngIf="
                        form.get('eyoffice').hasError('required') &&
                        form.get('eyoffice').touched
                        ">Please select a EY Office!</motif-error> -->
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>EY Region</label>
                            <motif-select formControlName="eyregion" class="ClienentitytModalselect"
                                aria-label="EY Region Form Field">
                                <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionId">
                                    {{item.eyregionName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                        form.get('eyregion').hasError('required') &&
                        form.get('eyregion').touched
                        ">Please select a EY Region!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>IR Office</label>
                            <motif-select formControlName="irrboffice" class="ClienentitytModalselect"
                                aria-label="IR office Form Field">
                                <motif-option *ngFor="let item of irRBofficeMasterData" [value]="item.irofficeId">
                                    {{item.irofficeName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                        form.get('irrboffice').hasError('required') &&
                        form.get('irrboffice').touched
                        ">Please select a IR Office!</motif-error>
                        </motif-form-field>
                    </div>
                </div>
                <span class="fonts-col__heading-4"> <span class="BoldHead2"> Other</span> </span>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>UID-No </label>
                            <input motifInput type="text" aria-label="UID-No Form Field" formControlName="uidno" />
                            <motif-error *ngIf="
                                        form.get('uidno').hasError('required') &&
                                        form.get('uidno').touched
                                        ">Please provide a UID No.!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>FINMA ID</label>
                            <input motifInput type="text" aria-label="FINMA ID Form Field" formControlName="finmaid" />
                            <motif-error *ngIf="
                                    form.get('finmaid').hasError('required') &&
                                    form.get('finmaid').touched
                                    ">Please provide a FINMA ID!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Fiscal Year End</label>
                            <input motifDatepicker class="motif-input" placeholder="DD.MM"
                                [options]="{dateRange: false, dateFormat: 'dd.mm',firstDayOfWeek:'mo'}" name="fiscalyearend" id="datepicker"
                                #dp1="motifDatepicker" (keydown.enter)="dp1.openCalendar()"
                                (click)="dp1.toggleCalendar()" formControlName="fiscalyearend" />
                        </motif-form-field>
                        <motif-error *ngIf="form.get('fiscalyearend').hasError('required') &&
                        form.get('fiscalyearend').touched">
                            Please select fiscal year end!
                        </motif-error>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>FAOA Register – entity Type</label>
                            <input motifInput type="text" aria-label="FAOA Register – entity Type Form Field"
                                formControlName="faoaregisterentitytype" />
                            <motif-error *ngIf="
                                form.get('faoaregisterentitytype').hasError('required') &&
                                form.get('faoaregisterentitytype').touched
                                ">Please provide a FAOA Register entity type!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>GIS Restriction</label>
                            <input motifInput type="text" aria-label="GIS Mark-up Form Field"
                                formControlName="gismarkup" />
                            <motif-error *ngIf="
                                        form.get('gismarkup').hasError('required') &&
                                        form.get('gismarkup').touched
                                        ">Please provide a GIS restriction!</motif-error>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>GIS ID</label>
                            <input motifInput type="number" aria-label="GIS ID Form Field" formControlName="gisid" />
                            <motif-error *ngIf="
                                    form.get('gisid').hasError('required') &&
                                    form.get('gisid').touched
                                    ">Please provide a GIS ID!</motif-error>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-4">
                        <motif-form-field>
                            <label motifLabel>Form Status</label>
                            <motif-select formControlName="formstatus" class="ClienentitytModalselect"
                                aria-label="Form status Form Field">
                                <motif-option *ngFor="let item of formStatusMasterData" [value]="item.formStatusId">
                                    {{item.formStatusName}}</motif-option>
                                <!-- <motif-option value="Active" [checked]="true">Active</motif-option> -->
                                <!-- <motif-option value="Discontinue">Discontinue</motif-option>
                                <motif-option value="Merge">Merge</motif-option> -->
                            </motif-select>
                            <motif-error *ngIf="
                                    form.get('formstatus').hasError('required') &&
                                    form.get('formstatus').touched
                                    ">Please select a form status!</motif-error>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-lg-12">
                        <motif-form-field>
                            <label motifLabel>Comment - Entity Data Form</label>
                            <textarea motifInput aria-label="disabled example textarea" class="motif-textarea"
                                formControlName="Comment"></textarea>
                        </motif-form-field>
                    </div>
                </div>
                <!-- <div class="motif-row motif-end-xs">
               <button motifButton color="primary" size="large" type="submit">Validate Example</button>
           </div> -->
        </fieldset>
            </form>
        
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="ClientEntitySave(form)" *ngIf="btncliententity"
                type="submit">Save</button>
            <button motifButton color="primary" (click)="ClientEntityUpdate(form)" *ngIf="!btncliententity && !viewcliententity"
                type="submit">Update</button>
            <button motifButton id="btnCancelEntity" color="secondary"
                (click)="clientEntityModal = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>

    <!-- modalend -->
    <motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
        {{toastmessage}}</motif-toast>
    <!--<motif-loader [show]="loaderflag" [fullScreen]="true"></motif-loader>-->

    <motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
        <motif-modal-header>
            Delete Client Entity
        </motif-modal-header>
        <motif-modal-body>
            <p>
                Are you sure, you want to delete client entity?
            </p>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="DeleteClientEntity()">Confirm</button>
            <button motifButton color="secondary" (click)="deletemodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>

    <motif-modal [show]="Validationsmodalflag" (close)="Validationsmodalflag = false">
        <motif-modal-header>
            Incomplete data found in the following fields, please correct and upload again.

        </motif-modal-header>
        <motif-modal-body>

            <motif-accordion type="editorial" id="example-2" *ngFor="let item of invalidarryKeys">
                <motif-accordion-header>
                    {{item}}
                </motif-accordion-header>

                <motif-accordion-content>
                    <ul>
                        <li *ngFor="let invaliditem of validationdata[item].split(',')">{{invaliditem}}</li>
                    </ul>
                </motif-accordion-content>
            </motif-accordion>

        </motif-modal-body>
        <motif-modal-footer>

        </motif-modal-footer>
    </motif-modal>

    <motif-modal [show]="ExcelExportmodalflag" (close)="ExcelExportmodalflag = false">
        <motif-modal-header>
            Export To Excel

        </motif-modal-header>
        <motif-modal-body>

            <form [formGroup]="ExcelToexportForm">
                <motif-form-field>
                    <motif-radio-group formControlName="radioexceltype" (change)="ReportOptionChange()">
                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="all" [title]="'Custom radio element title'">ALL
                                </motif-radio-button>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="GIS" [title]="'Custom radio element title'">GIS
                                </motif-radio-button>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="FINMA" [title]="'Custom radio element title'">FINMA
                                </motif-radio-button>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="FAOA" [title]="'Custom radio element title'">FAOA
                                </motif-radio-button>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="Custom" [title]="'Custom radio element title'">Custom
                                </motif-radio-button>
                            </div>
                        </div>
                    </motif-radio-group>
                </motif-form-field>

                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkBankCode">Bank Code</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEntityName">Entity Name</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEntityShortName">Entity Short Name</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <!-- <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkBankCode">Bank Code</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div> -->
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEYCIDGFIS">EY CID (GFIS)</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkClientIDMercury">Client ID (Mercury)
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>

                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkAddressStreet">Address – Street</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkAddressPostalCode">Address – Postal Code
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkAddressLocation">Address – Location</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkAddressCanton">Address – Canton</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkAddressCountry">Address – Country</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkReportinglanguage">Reporting Language
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkDocumentationlanguage">Documentation Language
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkBanksize">Bank Size</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEYOffice">EY Office</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEYRegion">EY Region</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkIRRBoffice">IR Office</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkCommentEntityDataForm">Comment - Entity Data Form
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkUIDNo">UID-No.</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFINMAID">FINMA ID</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFiscalYearEnd">Fiscal Year End</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFAOARegisterentitytype">FAOA Register – entity type
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkGISMarkup">GIS Mark-up</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkGISID">GIS ID</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFormStatus">Form Status</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="clientEntityExcelexport(ExcelToexportForm)"
                type="submit">Export</button>
            <button motifButton color="secondary" (click)="ExcelExportmodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>

    <motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
        <motif-modal-header>
            Confirmation
    
        </motif-modal-header>
        <motif-modal-body>
          <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="EscapeYes()" type="submit">Yes</button>
           <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
        </motif-modal-footer>
    </motif-modal>

</div>