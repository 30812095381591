<div>
 
  <form [formGroup]="form" novalidate>
    <div class="motif-row">
      <div class="motif-col-xs-2 motif-col-md-2 motif-col-lg-2 headingcol">
        <div class="motif-modal-header">
          <h3 class="motif-h3"> Audit Assignment</h3>
          </div>
      </div>
      <div class="motif-col-xs-7 motif-col-md-7 motif-col-lg-7 formtitlecol">
        <fieldset [disabled]='auditassign' style="border: 0; padding: 0;"> 
               <motif-form-field>
               <label motifLabel>Form Title</label>
               <input motifInput type="text" disabled aria-label="Form Title Form Field" formControlName="formtitle" />
               </motif-form-field>
          </fieldset>
      </div>
      <div class="motif-col-xs-3 motif-col-md-3 motif-col-lg-3"> 
          <div class="canceldiv">
            <button motifButton id="btnCancelauditassign"  (click)="oncancel('cancel')" color="secondary"><motif-icon class="cancelicon" [src]="'/assets/icons/content/ic_clear_24px.svg'"></motif-icon></button>
          </div>
        </div>
  
      </div>
      <fieldset [disabled]='auditassign' style="border: 0;">
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Entity Name</label>
            <input motifInput motifSearch type="text"
              [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
              placeholder="Search for entity name..." [hideClear]="true" 
              (change)="FillEntityBaseDetails()" aria-label="Search" formControlName="entityname"
              [motifTypeahead]="arrayCLientEntity" />
            <ng-container *ngIf="form.controls.entityname.invalid">
              <motif-error [showIcon]="false" *ngIf="form.get('entityname').hasError('required') &&
                            form.get('entityname').touched">Please select a valid entity name!</motif-error>
            </ng-container>

          </motif-form-field>

        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Audit Year</label>
            <motif-select formControlName="audityear"
              [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
              (change)="GenerateFormTitle($event)" class="ClienentitytModalselect" aria-label="audityear">
              <motif-option *ngFor="let item of lstYear" [value]="item">{{item}}</motif-option>


            </motif-select>
            <motif-error *ngIf="
                          form.get('audityear').hasError('required') &&
                          form.get('audityear').touched
                          ">Please select audit year!</motif-error>
          </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Classification</label>
            <motif-select formControlName="classification"
              [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
              class="ClienentitytModalselect" aria-label="classification">
              <motif-option *ngFor="let item of lstClassification" [value]="item">{{item}}</motif-option>
            </motif-select>
            <motif-error *ngIf="
                          form.get('classification').hasError('required') &&
                          form.get('classification').touched
                          ">Please select classification!</motif-error>
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Group Scope</label>
            <motif-select formControlName="groupscope"
              [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
              class="ClienentitytModalselect" aria-label="groupscope">
              <motif-option *ngFor="let item of lstGroupScope" [value]="item">{{item}}</motif-option>
            </motif-select>
            <motif-error *ngIf="
                          form.get('groupscope').hasError('required') &&
                          form.get('groupscope').touched
                          ">Please select group scope!</motif-error>
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Form Status</label>
            <motif-select formControlName="formstatus"
              [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
              class="ClienentitytModalselect" aria-label="formstatus">
              <motif-option *ngFor="let item of lstFormStatus" [value]="item">{{item}}</motif-option>
            </motif-select>
            <motif-error *ngIf="
                          form.get('formstatus').hasError('required') &&
                          form.get('formstatus').touched
                          ">Please select form status!</motif-error>
          </motif-form-field>
        </div>
      </div>

      <div class="motif-row">
      </div>
      <span class="fonts-col__heading-4"><b> Entity Details </b></span>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Entity Short Name</label>
            <input motifInput type="text" disabled aria-label="Entity Short Name Form Field"
              formControlName="entityshortname" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Bank Code</label>
            <input motifInput type="text" disabled aria-label="Bank Code Form Field" formControlName="bankcode" />

          </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-2">
          <div style="display: none;">
            <motif-form-field>
              <label motifLabel>Location</label>
              <input motifInput type="text" disabled aria-label="Location Form Field" formControlName="location" />

            </motif-form-field>
          </div>
          <motif-form-field>
            <label motifLabel>EY Region</label>
            <input motifInput type="text" disabled aria-label="EY Region Form Field" formControlName="eyregion" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <div style="display: none;">
            <motif-form-field>
              <label motifLabel>Canton</label>
              <input motifInput type="text" disabled aria-label="Canton Form Field" formControlName="canton" />

            </motif-form-field>
          </div>
          <motif-form-field>
            <label motifLabel>IR Office</label>
            <input motifInput type="text" disabled aria-label="IR Office Form Field" formControlName="iroffice" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">

        </div>
      </div>


      <span class="fonts-col__heading-4"><b> Roles and Functions </b></span>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstRPTeammembers"
            formControlName="regionalleadpartner" optionLabel="contactName" [filter]="true" filterBy="contactName"
            [showClear]="true" placeholder="Engagement Partner"
            [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false">
            <ng-template pTemplate="selectedItem">
              <div class="ClienentitytModalselect" *ngIf="form.value.regionalleadpartner">
                <div><b class="newAddedLabel"> Engagement Partner </b>
                  {{form.value.regionalleadpartner.contactName}}
                </div>
              </div>
            </ng-template>
            <ng-template let-contact pTemplate="item">
              <div class="country-item">
                <div>{{contact.contactName}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <motif-form-field>
            <motif-error *ngIf="
    form.get('regionalleadpartner').hasError('required') &&
    form.get('regionalleadpartner').touched
    ">Please select a engagement partner!</motif-error>
            <motif-error *ngIf="form.value.regionalleadpartner==null && !form.get('regionalleadpartner').touched"
              class="addedExtraIconUntouched"></motif-error>
            <motif-error *ngIf="form.get('regionalleadpartner').hasError('required') &&
    form.get('regionalleadpartner').touched" class="addedExtraIcon"></motif-error>
          </motif-form-field>
        </div>
        
        <!-- <div class="motif-col-xs-4 motif-col-lg-2">
          <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstRPTeammembers"
            formControlName="assignedleadauditor" optionLabel="contactName" [filter]="true" filterBy="contactName"
            [showClear]="true" placeholder="Engagement Manager"
            [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false">
            <ng-template pTemplate="selectedItem">
              <div class="ClienentitytModalselect" *ngIf="form.value.assignedleadauditor">
                <div><b class="newAddedLabel"> Engagement Manager </b>
                  {{form.value.assignedleadauditor.contactName}}
                </div>
              </div>
            </ng-template>
            <ng-template let-contact pTemplate="item">
              <div class="country-item">
                <div>{{contact.contactName}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <motif-form-field>
            <motif-error *ngIf="
        form.get('assignedleadauditor').hasError('required') &&
        form.get('assignedleadauditor').touched
        ">Please select a engagement manager!</motif-error>
            <motif-error *ngIf="form.value.assignedleadauditor==null && !form.get('assignedleadauditor').touched"
              class="addedExtraIconUntouched"></motif-error>
            <motif-error *ngIf="form.get('assignedleadauditor').hasError('required') &&
        form.get('assignedleadauditor').touched" class="addedExtraIcon"></motif-error>
          </motif-form-field>

        </div> -->
        <div class="motif-col-xs-4 motif-col-lg-2">
          <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstAPTeammembers"
            formControlName="assignedFinmaleadauditor" optionLabel="contactName" [filter]="true" filterBy="contactName"
            [showClear]="true" placeholder="FINMA Lead Auditor"
            [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false">
            <ng-template pTemplate="selectedItem">
              <div class="ClienentitytModalselect" *ngIf="form.value.assignedFinmaleadauditor">
                <div><b class="newAddedLabel"> FINMA Lead Auditor </b>
                  {{form.value.assignedFinmaleadauditor.contactName}}
                </div>
              </div>
            </ng-template>
            <ng-template let-contact pTemplate="item">
              <div class="country-item">
                <div>{{contact.contactName}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <motif-form-field>
            <motif-error *ngIf="
                            form.get('assignedFinmaleadauditor').hasError('required') &&
                            form.get('assignedFinmaleadauditor').touched
                            ">Please select a FINMA lead auditor!</motif-error>
            <motif-error
              *ngIf="form.value.assignedFinmaleadauditor==null && !form.get('assignedFinmaleadauditor').touched"
              class="addedExtraIconUntouched"></motif-error>
            <motif-error *ngIf="form.get('assignedFinmaleadauditor').hasError('required') &&
                            form.get('assignedFinmaleadauditor').touched" class="addedExtraIcon"></motif-error>
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstInternalAuditEngLeder"
            formControlName="internalauditengagementleader" optionLabel="contactName" [filter]="true"
            filterBy="contactName" [showClear]="true" placeholder="Internal Audit Engagement Leader"
            [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false">
            <ng-template pTemplate="selectedItem">
              <div class="ClienentitytModalselect" *ngIf="form.value.internalauditengagementleader">
                <div><b class="newAddedLabel"> Internal Audit Engagement Leader </b>
                  {{form.value.internalauditengagementleader.contactName}}
                </div>
              </div>
            </ng-template>
            <ng-template let-contact pTemplate="item">
              <div class="country-item">
                <div>{{contact.contactName}}</div>
              </div>
            </ng-template>
          </p-dropdown>

        </div>
        <div class="motif-col-xs-4 motif-col-lg-3">
          <p-multiSelect [virtualScroll]="true" itemSize="15" [options]="lstContacts" [(ngModel)]="selectCLientContacts"
            formControlName="clientcontacts" placeholder="Client Contacts"
            [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
            defaultLabel="Client Contacts" optionLabel="contactName" class="multiselect-custom">
            <ng-template pTemplate="header"> </ng-template>
            <ng-template let-value pTemplate="selectedItems">

              <div class="country-item country-item-value inlineBlockFlag" *ngFor="let option of selectCLientContacts">
                <b class="newAddedLabel" *ngIf="selectCLientContacts.length >= 1">
                  Client Contacts</b>
                <div *ngIf="selectCLientContacts.length == 1" class="inlineBlockFlag"> {{option.contactName}}</div>
                <div *ngIf="selectCLientContacts.length > 1" class="inlineBlockFlag"> {{option.contactName}}/</div>
              </div>
              <div *ngIf="!selectCLientContacts || selectCLientContacts.length === 0"
                class="country-placeholder ClienentitytModalselect">
                <div><span> Client Contacts</span></div>
              </div>
            </ng-template>
            <ng-template let-cont pTemplate="item">
              <div class="country-item">
                <div> {{cont.contactName}}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
          </p-multiSelect>

        </div>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeRP" [disabled]="advanceUpdate">Financial Audit -
                RP</motif-checkbox>
              <motif-error *ngIf="!form.value.AuditTypeRP && !form.value.AuditTypeAP && !form.value.AuditTypeOther && !form.value.AuditTypeKP && !form.value.AuditTypeIA
                                  ">Please select at least one audit type!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeKP" [disabled]="advanceUpdate">Loan Audit - KP
              </motif-checkbox>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeAP" [disabled]="advanceUpdate">Regulatory Audit -
                AP</motif-checkbox>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeOther" [disabled]="advanceUpdate">Other Audit - OT
              </motif-checkbox>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-3">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeIA" [disabled]="advanceUpdate">Internal Audit - IR
              </motif-checkbox>
            </motif-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="form.value.AuditTypeRP">
        <div class="fonts-col__heading-4 AuditHeadingdiv"><span class="AuditHeading BoldHead1"> Financial Audit - RP </span></div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Financial Audit (RP) Lead</label>
              <motif-select formControlName="financialauditleadRP"
                [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                (change)="onchangeFinancialAuditLeadRP($event)" class="ClienentitytModalselect"
                aria-label="Financial Audit (RP) Lead">
                <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}
                </motif-option>
              </motif-select>
              <motif-error *ngIf="
                          form.get('financialauditleadRP').hasError('required') &&
                          form.get('financialauditleadRP').touched
                          ">Please select a financial audit lead!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field
              [ngClass]="{'disabledClass':RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false}">
              <label motifLabel>Audit Period Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" (dateChanged)="onChangeStartDateRP($event)"
                name="auditperiodstartdateRP" id="datepicker1" #dp2="motifDatepicker"
                (keydown.enter)="dp2.openCalendar()" (click)="dp2.toggleCalendar()"
                formControlName="auditperiodstartdateRP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditperiodstartdateRP').hasError('required') &&
                        form.get('auditperiodstartdateRP').touched
                        ">Please select a audit period start date!</motif-error>

            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field
              [ngClass]="{'disabledClass':RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false}">
              <label motifLabel>Audit Period End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [ngClass]="{'disabledClass':RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false}"
                [options]="endDateRPOptions" name="auditperiodenddateRP" id="datepicker2" #dp3="motifDatepicker"
                (keydown.enter)="dp3.openCalendar()" (click)="dp3.toggleCalendar()"
                formControlName="auditperiodenddateRP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditperiodenddateRP').hasError('required') &&
                        form.get('auditperiodenddateRP').touched
                        ">Please select a audit period end date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                (dateChanged)="onChangeExcutionStartDateRP($event)" name="auditexecutionstartdateRP" id="datepicker4"
                #dp4="motifDatepicker" (keydown.enter)="dp4.openCalendar()" (click)="dp4.toggleCalendar()"
                formControlName="auditexecutionstartdateRP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditexecutionstartdateRP').hasError('required') &&
                        form.get('auditexecutionstartdateRP').touched
                        ">Please select a audit execution start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit Execution End Date</label>
              <input motifDatepicker class="motif-input" (dateChanged)="onChangeExcutionEndDateRP($event)"
                placeholder="DD.MM.YYYY" [options]="executionEndDateRPOptions" name="auditexecutionenddateRP"
                id="datepicker5" #dp5="motifDatepicker" (keydown.enter)="dp5.openCalendar()"
                (click)="dp5.toggleCalendar()" formControlName="auditexecutionenddateRP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditexecutionenddateRP').hasError('required') &&
                        form.get('auditexecutionenddateRP').touched
                        ">Please select a audit execution end date!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Completed - RP</label>
              <input motifDatepicker class="motif-input" (dateChanged)="onChangeIAworkCompletedDateRP($event)"
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="iaworkcompletedRP" id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedRP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('iaworkcompletedRP').hasError('required') &&
                        form.get('iaworkcompletedRP').touched
                        ">Please select a iA work completed date!</motif-error>
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-3 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Decisive Date</label>
              <input motifDatepicker class="motif-input"  [ngClass]="{'disabledClass':RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false}"
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="decisivedate" id="datepickerDD" #dpDD="motifDatepicker" (keydown.enter)="dpDD.openCalendar()"
                (click)="dpDD.toggleCalendar()" formControlName="decisivedate"/>
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-3 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Latest Sign-off Date</label>
              <input motifDatepicker class="motif-input" [ngClass]="{'disabledClass':RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false}"
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="latestSignoffDate" id="datepickerLSD" #dpLSD="motifDatepicker" (keydown.enter)="dpLSD.openCalendar()"
                (click)="dpLSD.toggleCalendar()" formControlName="latestSignoffDate"/>
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>RP Status</label>
              <motif-select formControlName="statusidRP" [disabled]="RegionBasedENabledFlag"
                class="ClienentitytModalselect" aria-label="Status - RP">
                <motif-option *ngFor="let item of lstAudittypeSatusRp" [value]="item.auditTypeStatusRpId">
                  {{item.auditTypeStatusName}}</motif-option>
              </motif-select>
              <motif-error *ngIf="
                      form.get('statusidRP').hasError('required') &&
                      form.get('statusidRP').touched
                      ">Please select a status!</motif-error>
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-3 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Actual Sign-off Date</label>
              <input motifDatepicker class="motif-input"
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="actualSignoffDate" id="datepickerLSD" #dpASD="motifDatepicker" (keydown.enter)="dpASD.openCalendar()"
                (click)="dpASD.toggleCalendar()" formControlName="actualSignoffDate"/>
            </motif-form-field>
          </div>

          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Announcement Delivery - RP</label>
              <input motifInput type="text" disabled aria-label="Announcemrnt delivery Form Field"
                formControlName="announcementdeliveryRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Data Due - RP</label>
              <input motifInput type="text" disabled aria-label="Client due date Form Field"
                formControlName="clientdatadueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Draft Report - RP</label>
              <input motifInput type="text" disabled aria-label="Draft report Form Field"
                formControlName="draftreportRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Paper Review Due - RP</label>
              <input motifInput type="text" disabled aria-label="IA Work Paper Review Due  Form Field"
                formControlName="iaworkpaperreviewdueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Draft Report To Client Due - RP</label>
              <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                formControlName="draftreporttoclientdueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Feedback Due - RP</label>
              <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                formControlName="clientfeedbackdueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Final Report To Client Due - RP</label>
              <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                formControlName="finalreporttoclientdueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>RP Status</label>
              <motif-select formControlName="statusidRP" [disabled]="RegionBasedENabledFlag"
                class="ClienentitytModalselect" aria-label="Status - RP">
                <motif-option *ngFor="let item of lstAudittypeSatusRp" [value]="item.auditTypeStatusRpId">
                  {{item.auditTypeStatusName}}</motif-option>
              </motif-select>
              <motif-error *ngIf="
                      form.get('statusidRP').hasError('required') &&
                      form.get('statusidRP').touched
                      ">Please select a status!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row finauditcosigndiv">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstRPTeammembers"
              formControlName="finAuditCoSign" [filter]="true" filterBy="contactName" [showClear]="true"
              placeholder="Fin. Audit Co-Sign"
              [disabled]="RegionBasedENabledFlag==true?true:false">
              <ng-template pTemplate="selectedItem">
                <div class="ClienentitytModalselect" *ngIf="form.value.finAuditCoSign">
                  <div><b class="newAddedLabel"> Fin. Audit Co-Sign </b>
                    {{form.value.finAuditCoSign.contactName}}
                  </div>
                </div>
              </ng-template>
              <ng-template let-contact pTemplate="item">
                <div class="country-item">
                  <div>{{contact.contactName}}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <span class="fonts-col__heading-4"><b>AGM Details </b></span>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Meeting Format</label>
              <motif-select formControlName="meetingFormat"
                [disabled]="RegionBasedENabledFlag==true?true:false"
                class="ClienentitytModalselect" aria-label="Meeting Format">
                <motif-option *ngFor="let item of lstMeetingFormat" [value]="item.meetingFormatId">
                  {{item.meetingFormat1}}
                </motif-option>
              </motif-select>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field [ngClass]="{'disabledClass':auditassign==true?true:RegionBasedENabledFlag==true?true: false}">
              <label motifLabel>Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="agmDate" id="datepicker20"
                #dp20="motifDatepicker" (keydown.enter)="dp20.openCalendar()" (click)="dp20.toggleCalendar()"
                formControlName="agmDate" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <div [ngClass]="{'disabledClass':auditassign==true?true:RegionBasedENabledFlag==true?true: false}" class="timeBlock">
              <label> Start Time </label>
              <input [ngxTimepicker]="startTimepicker" style="display: none" formControlName="startTime" name="startTime"/>
              <ngx-timepicker-field #startTimepicker [format]="24" [controlOnly]="false" (timeChanged)="onStartTimeChanged($event)"></ngx-timepicker-field>
            </div>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <div [ngClass]="{'disabledClass':auditassign==true?true:RegionBasedENabledFlag==true?true: false}" class="timeBlock">
              <label> End Time </label>
              <input [ngxTimepicker]="endTimepicker" style="display: none"  formControlName="endTime" name="endTime"/>
              <ngx-timepicker-field #endTimepicker [format]="24" [controlOnly]="false" (timeChanged)="onEndTimeChanged($event)"></ngx-timepicker-field>
            </div>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Location</label>
              <input motifInput type="text" [disabled]="RegionBasedENabledFlag==true?true:false"  aria-label="Location" formControlName="agmLocation" />
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstRPTeammembers"
              formControlName="eyRepresentative" [filter]="true" filterBy="contactName" [showClear]="true"
              placeholder="EY Representative"
              [disabled]="RegionBasedENabledFlag==true?true: false">
              <ng-template pTemplate="selectedItem">
                <div class="ClienentitytModalselect" *ngIf="form.value.eyRepresentative">
                  <div><b class="newAddedLabel"> EY Representative </b>
                    {{form.value.eyRepresentative.contactName}}
                  </div>
                </div>
              </ng-template>
              <ng-template let-contact pTemplate="item">
                <div class="country-item">
                  <div>{{contact.contactName}}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-9">
            <motif-form-field>
              <label motifLabel>Notes</label>
              <input motifInput type="text" [disabled]="RegionBasedENabledFlag==true?true:false" aria-label="Notes" formControlName="agmNotes" />
            </motif-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="form.value.AuditTypeKP">
        <div class="fonts-col__heading-4 AuditHeadingdiv"><span class="AuditHeading BoldHead1">Loan Audit - KP </span></div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Financial Audit (KP) Lead</label>
              <motif-select formControlName="financialauditleadKP"
                [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                (change)="onchangeFinancialAuditLeadKP($event)" class="ClienentitytModalselect"
                aria-label="Financial Audit (KP) Lead">
                <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}
                </motif-option>
              </motif-select>
              <motif-error *ngIf="
                          form.get('financialauditleadKP').hasError('required') &&
                          form.get('financialauditleadKP').touched
                          ">Please select a KP financial audit Lead!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateKP"
                id="datepicker6" #dp6="motifDatepicker" (keydown.enter)="dp6.openCalendar()"
                (click)="dp6.toggleCalendar()" formControlName="auditexecutionstartdateKP"
                (dateChanged)="onChangeExcutionStartDateKP($event)" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditexecutionstartdateKP').hasError('required') &&
                        form.get('auditexecutionstartdateKP').touched
                        ">Please select a audit execution start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                (dateChanged)="onChangeExcutionEndDateKP($event)" [options]="executionEndDateKPOptions"
                name="auditexecutionenddateKP" id="datepicker7" #dp7="motifDatepicker"
                (keydown.enter)="dp7.openCalendar()" (click)="dp7.toggleCalendar()"
                formControlName="auditexecutionenddateKP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditexecutionenddateKP').hasError('required') &&
                        form.get('auditexecutionenddateKP').touched
                        ">Please select a audit execution end date!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Completed - KP</label>
              <input motifDatepicker class="motif-input" (dateChanged)="onChangeIAworkCompletedDateKP($event)"
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="iaworkcompletedKP" id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedKP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('iaworkcompletedKP').hasError('required') &&
                        form.get('iaworkcompletedKP').touched
                        ">Please select a iA work completed date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Announcement Delivery - KP</label>
              <input motifInput type="text" disabled aria-label="Announcemrnt delivery Form Field"
                formControlName="announcementdeliveryKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Data Due - KP</label>
              <input motifInput type="text" disabled aria-label="Client due date Form Field"
                formControlName="clientdatadueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Draft Report - KP</label>
              <input motifInput type="text" disabled aria-label="Draft report Form Field"
                formControlName="draftreportKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Paper Review Due - KP</label>
              <input motifInput type="text" disabled aria-label="IA Work PaPer Review Due  Form Field"
                formControlName="iaworkpaperreviewdueKP" />
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Draft Report To Client Due - KP</label>
              <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                formControlName="draftreporttoclientdueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Feedback Due - KP</label>
              <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                formControlName="clientfeedbackdueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label  [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" motifLabel>Final Report To Client Due - KP</label>
              <label  [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" motifLabel>Final Feedback To Client Due - KP</label>
              <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                formControlName="finalreporttoclientdueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>KP Status</label>
              <motif-select formControlName="statusidKP" class="ClienentitytModalselect"
                [disabled]="RegionBasedENabledFlag" aria-label="Status - KP">
                <motif-option *ngFor="let item of lstAudittypeSatusKp" [value]="item.auditTypeStatusKpId">
                  {{item.auditTypeStatusName}}</motif-option>
              </motif-select>
              <motif-error *ngIf="
                      form.get('statusidKP').hasError('required') &&
                      form.get('statusidKP').touched
                      ">Please select a status!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
              <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstKPTeammembers"
                formControlName="kpEngagementManager" [filter]="true" filterBy="contactName" [showClear]="true"
                placeholder="KP Engagement Manager"
                [disabled]="RegionBasedENabledFlag==true?true:false">
                <ng-template pTemplate="selectedItem">
                  <div class="ClienentitytModalselect" *ngIf="form.value.kpEngagementManager">
                    <div><b class="newAddedLabel"> KP Engagement Manager </b>
                      {{form.value.kpEngagementManager.contactName}}
                    </div>
                  </div>
                </ng-template>
                <ng-template let-contact pTemplate="item">
                  <div class="country-item">
                    <div>{{contact.contactName}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
          </div>

        </div>
      </div>

      <div *ngIf="form.value.AuditTypeAP">
        <div class="fonts-col__heading-4 AuditHeadingdiv"><span class="AuditHeading BoldHead1"> Regulatory Audit - AP </span></div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Regulatory Audit (AP) Lead</label>
              <motif-select formControlName="financialauditleadAP"
                [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                (change)="onchangeFinancialAuditLeadAP($event)" class="ClienentitytModalselect"
                aria-label="Financial Audit (AP) Lead">
                <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}
                </motif-option>
              </motif-select>
              <motif-error *ngIf="
                          form.get('financialauditleadAP').hasError('required') &&
                          form.get('financialauditleadAP').touched
                          ">Please select a regulatory audit lead!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditperiodstartdateAP" id="datepicker8"
                #dp8="motifDatepicker" (keydown.enter)="dp8.openCalendar()" (click)="dp8.toggleCalendar()"
                formControlName="auditperiodstartdateAP" (dateChanged)="onChangeStartDateAP($event)" />

              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditperiodstartdateAP').hasError('required') &&
                        form.get('auditperiodstartdateAP').touched
                        ">Please select a audit period start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [options]="endDateAPOptions"
                name="auditperiodenddateAP" id="datepicker9" #dp9="motifDatepicker" (keydown.enter)="dp9.openCalendar()"
                (click)="dp9.toggleCalendar()" formControlName="auditperiodenddateAP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditperiodenddateAP').hasError('required') &&
                        form.get('auditperiodenddateAP').touched
                        ">Please select a audit period end date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [disabled]="RegionBasedENabledFlag"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateAP"
                id="datepicker10" #dp10="motifDatepicker" (keydown.enter)="dp10.openCalendar()"
                (click)="dp10.toggleCalendar()" formControlName="auditexecutionstartdateAP"
                (dateChanged)="onChangeExcutionStartDateAP($event)" />
              <motif-error [showIcon]="false" *ngIf="
            form.get('auditexecutionstartdateAP').hasError('required') &&
            form.get('auditexecutionstartdateAP').touched
            ">Please select a audit execution start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit Execution End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [disabled]="RegionBasedENabledFlag"
                (dateChanged)="onChangeExcutionEndDateAP($event)" [options]="executionEndDateAPOptions"
                name="auditexecutionenddateAP" id="datepicker11" #dp11="motifDatepicker"
                (keydown.enter)="dp11.openCalendar()" (click)="dp11.toggleCalendar()"
                formControlName="auditexecutionenddateAP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditexecutionenddateAP').hasError('required') &&
                        form.get('auditexecutionenddateAP').touched
                        ">Please select a audit execution end date!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Completed - AP</label>
              <input motifDatepicker class="motif-input" (dateChanged)="onChangeIAworkCompletedDateAP($event)"
                placeholder="DD.MM.YYYY" [disabled]="RegionBasedENabledFlag"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="iaworkcompletedAP" id="datepickerIA"
                #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()" (click)="dpIA.toggleCalendar()"
                formControlName="iaworkcompletedAP" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('iaworkcompletedAP').hasError('required') &&
                        form.get('iaworkcompletedAP').touched
                        ">Please select a iA work completed date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Announcement Delivery - AP</label>
              <input motifInput type="text" disabled aria-label="Announcemrnt delivery Form Field"
                formControlName="announcementdeliveryAP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Data Due - AP</label>
              <input motifInput type="text" disabled aria-label="Client due date Form Field"
                formControlName="clientdatadueAP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Draft Report - AP</label>
              <input motifInput type="text" disabled aria-label="Draft report Form Field"
                formControlName="draftreportAP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Paper Review Due - AP</label>
              <input motifInput type="text" disabled aria-label="IA Work Paper Review Due  Form Field"
                formControlName="iaworkpaperreviewdueAP" />
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Draft Report To Client Due - AP</label>
              <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                formControlName="draftreporttoclientdueAP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Feedback Due - AP</label>
              <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                formControlName="clientfeedbackdueAP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Final Report To Client Due - AP</label>
              <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                formControlName="finalreporttoclientdueAP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>AP Status</label>
              <motif-select formControlName="statusidAP" [disabled]="RegionBasedENabledFlag"
                class="ClienentitytModalselect" aria-label="Status - AP">
                <motif-option *ngFor="let item of lstAudittypeSatusAp" [value]="item.auditTypeStatusApId">
                  {{item.auditTypeStatusName}}</motif-option>
              </motif-select>
              <motif-error *ngIf="
                      form.get('statusidAP').hasError('required') &&
                      form.get('statusidAP').touched
                      ">Please select a status!</motif-error>
            </motif-form-field>
          </div>      
          <div class="motif-col-xs-4 motif-col-lg-3">
            <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstAPTeammembers"
              formControlName="regAuditCoSign" [filter]="true" filterBy="contactName" [showClear]="true"
              placeholder="Reg. Audit Co-Sign"
              [disabled]="RegionBasedENabledFlag==true?true:false">
              <ng-template pTemplate="selectedItem">
                <div class="ClienentitytModalselect" *ngIf="form.value.regAuditCoSign">
                  <div><b class="newAddedLabel"> Reg. Audit Co-Sign </b>
                    {{form.value.regAuditCoSign.contactName}}
                  </div>
                </div>
              </ng-template>
              <ng-template let-contact pTemplate="item">
                <div class="country-item">
                  <div>{{contact.contactName}}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
      <div *ngIf="form.value.AuditTypeOther">
        <div class="fonts-col__heading-4 AuditHeadingdiv"><span class="AuditHeading BoldHead1">Other Audit - OT </span></div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Other Audit Lead</label>
              <motif-select formControlName="financialauditleadOther"
                [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                (change)="onchangeFinancialAuditLeadOT($event)" class="ClienentitytModalselect"
                aria-label="Financial Audit (Other) Lead">
                <motif-option *ngFor="let item of lstRegAuditLead" [value]="item">{{item}}
                </motif-option>
              </motif-select>
              <motif-error *ngIf="
                          form.get('financialauditleadOther').hasError('required') &&
                          form.get('financialauditleadOther').touched
                          ">Please select a financial audit lead!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [disabled]="RegionBasedENabledFlag"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditperiodstartdateOther"
                id="datepicker12" #dp12="motifDatepicker" (keydown.enter)="dp12.openCalendar()"
                (click)="dp12.toggleCalendar()" formControlName="auditperiodstartdateOther"
                (dateChanged)="onChangeStartDateOT($event)" />

              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditperiodstartdateOther').hasError('required') &&
                        form.get('auditperiodstartdateOther').touched
                        ">Please select a audit period start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [disabled]="RegionBasedENabledFlag"
                [options]="endDateOTOptions" name="auditperiodenddateOther" id="datepicker13" #dp13="motifDatepicker"
                (keydown.enter)="dp13.openCalendar()" (click)="dp13.toggleCalendar()"
                formControlName="auditperiodenddateOther" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditperiodenddateOther').hasError('required') &&
                        form.get('auditperiodenddateOther').touched
                        ">Please select a audit period end date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [disabled]="RegionBasedENabledFlag"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateOther"
                id="datepicker14" #dp14="motifDatepicker" (keydown.enter)="dp14.openCalendar()"
                (click)="dp14.toggleCalendar()" formControlName="auditexecutionstartdateOther"
                (dateChanged)="onChangeExcutionStartDateOT($event)" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditexecutionstartdateOther').hasError('required') &&
                        form.get('auditexecutionstartdateOther').touched
                        ">Please select a audit execution start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit Execution End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [disabled]="RegionBasedENabledFlag"
                [options]="executionEndDateOTOptions" name="auditexecutionenddateAP" id="datepicker15"
                #dp15="motifDatepicker" (keydown.enter)="dp15.openCalendar()" (click)="dp15.toggleCalendar()"
                formControlName="auditexecutionenddateOther" (dateChanged)="onChangeExcutionEndDateOT($event)" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('auditexecutionenddateOther').hasError('required') &&
                        form.get('auditexecutionenddateOther').touched
                        ">Please select a audit execution end date!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Completed - OT</label>
              <input motifDatepicker class="motif-input" [disabled]="RegionBasedENabledFlag"
                (dateChanged)="onChangeIAworkCompletedDateOT($event)" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="iaworkcompletedOT" id="datepickerIA"
                #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()" (click)="dpIA.toggleCalendar()"
                formControlName="iaworkcompletedOT" />
              <motif-error [showIcon]="false" *ngIf="
                        form.get('iaworkcompletedOT').hasError('required') &&
                        form.get('iaworkcompletedOT').touched
                        ">Please select a iA work completed date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Announcement Delivery - OT</label>
              <input motifInput type="text" disabled aria-label="Announcemrnt delivery Form Field"
                formControlName="announcementdeliveryOT" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Data Due - OT</label>
              <input motifInput type="text" disabled aria-label="Client due date Form Field"
                formControlName="clientdatadueOT" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Draft Report - OT</label>
              <input motifInput type="text" disabled aria-label="Draft report Form Field"
                formControlName="draftreportOT" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Paper Review Due - OT</label>
              <input motifInput type="text" disabled aria-label="IA Work Paper Review Due  Form Field"
                formControlName="iaworkpaperreviewdueOT" />
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Draft Report To Client Due - OT</label>
              <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                formControlName="draftreporttoclientdueOT" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Feedback Due - OT</label>
              <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                formControlName="clientfeedbackdueOT" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Final Report To Client Due - OT</label>
              <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                formControlName="finalreporttoclientdueOT" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>OT Status</label>
              <motif-select formControlName="statusidOT" [disabled]="RegionBasedENabledFlag"
                class="ClienentitytModalselect" aria-label="Status - OT">
                <motif-option *ngFor="let item of lstAudittypeSatusOt" [value]="item.auditTypeStatusOtId">
                  {{item.auditTypeStatusName}}</motif-option>
              </motif-select>
              <motif-error *ngIf="
                      form.get('statusidOT').hasError('required') &&
                      form.get('statusidOT').touched
                      ">Please select a status!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstOTTeammembers"
              formControlName="regionalleadpartnerOther" optionLabel="contactName" [filter]="true"
              filterBy="contactName" [showClear]="true" placeholder="OT Partner"
              [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false">
              <ng-template pTemplate="selectedItem">
                <div class="ClienentitytModalselect" *ngIf="form.value.regionalleadpartnerOther">
                  <div><b class="newAddedLabel"> OT Partner </b>
                    {{form.value.regionalleadpartnerOther.contactName}}
                  </div>
                </div>
              </ng-template>
              <ng-template let-contact pTemplate="item">
                <div class="country-item">
                  <div>{{contact.contactName}}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <motif-form-field>
              <motif-error *ngIf="
            form.get('regionalleadpartnerOther').hasError('required') &&
            form.get('regionalleadpartnerOther').touched
            ">Please select a ot partner!</motif-error>
              <motif-error
                *ngIf="form.value.regionalleadpartnerOther==null && !form.get('regionalleadpartnerOther').touched"
                class="addedExtraIconUntouched"></motif-error>
              <motif-error *ngIf="form.get('regionalleadpartnerOther').hasError('required') &&
            form.get('regionalleadpartnerOther').touched" class="addedExtraIcon"></motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <p-dropdown [virtualScroll]="true" itemSize="15" [options]="lstOTTeammembers"
              formControlName="assignedleadauditorOther" optionLabel="contactName" [filter]="true"
              filterBy="contactName" [showClear]="true" placeholder="OT Engagement Manager"
              [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false">
              <ng-template pTemplate="selectedItem">
                <div class="ClienentitytModalselect" *ngIf="form.value.assignedleadauditorOther">
                  <div><b class="newAddedLabel"> OT Engagement Manager </b>
                    {{form.value.assignedleadauditorOther.contactName}}
                  </div>
                </div>
              </ng-template>
              <ng-template let-contact pTemplate="item">
                <div class="country-item">
                  <div>{{contact.contactName}}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <motif-form-field>
              <motif-error *ngIf="
                form.get('assignedleadauditorOther').hasError('required') &&
                form.get('assignedleadauditorOther').touched
                ">Please select a engagement manager!</motif-error>
              <motif-error
                *ngIf="form.value.assignedleadauditorOther==null && !form.get('assignedleadauditorOther').touched"
                class="addedExtraIconUntouched"></motif-error>
              <motif-error *ngIf="form.get('assignedleadauditorOther').hasError('required') &&
                form.get('assignedleadauditorOther').touched" class="addedExtraIcon"></motif-error>
            </motif-form-field>
          </div>
        </div>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4">
          <motif-form-field>
            <label motifLabel>Comment</label>
            <textarea motifInput aria-label=" example textarea" class="motif-textarea"
              [disabled]="RegionBasedENabledFlag" formControlName="Comment"></textarea>
          </motif-form-field>
        </div>
      </div>
      <div *ngIf="form.value.AuditTypeIA">
        <div class="fonts-col__heading-4 AuditHeadingdiv"><span class="AuditHeading BoldHead1"> Internal Audit - IR </span></div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Audit 1</label>
              <input motifInput type="text" formControlName="internalAudit1"
                [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                class="ClienentitytModalselect" aria-label="IA Audit 1" />
              <motif-error *ngIf="form.get('internalAudit1').hasError('required') && form.get('internalAudit1').touched
                      ">Please Enter Internal Audit 1</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit 1 Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="ia1periodstartdate" id="datepicker16"
                #dp16="motifDatepicker" (keydown.enter)="dp16.openCalendar()" (click)="dp16.toggleCalendar()"
                formControlName="ia1periodstartdate" (dateChanged)="onChangeStartDateIR1($event)" />

              <motif-error [showIcon]="false" *ngIf="
                    form.get('ia1periodstartdate').hasError('required') &&
                    form.get('ia1periodstartdate').touched
                    ">Please select a audit period start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit 1 Execution End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [options]="endDateIR1Options" 
                name="ia1periodenddate" id="datepicker17" #dp17="motifDatepicker" (keydown.enter)="dp17.openCalendar()"
                (click)="dp17.toggleCalendar()" formControlName="ia1periodenddate" />
              <motif-error [showIcon]="false" *ngIf="
                    form.get('ia1periodenddate').hasError('required') &&
                    form.get('ia1periodenddate').touched
                    ">Please select a audit period end date!</motif-error>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Audit 2</label>
              <input motifInput type="text" formControlName="internalAudit2"
                [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                class="ClienentitytModalselect" aria-label="IA Audit 2" />
              <motif-error *ngIf="form.get('internalAudit2').hasError('required') && form.get('internalAudit2').touched
                      ">Please Enter Internal Audit 2</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit 2 Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="ia2periodstartdate" id="datepicker18"
                #dp18="motifDatepicker" (keydown.enter)="dp18.openCalendar()" (click)="dp18.toggleCalendar()"
                formControlName="ia2periodstartdate" (dateChanged)="onChangeStartDateIR2($event)" />

              <motif-error [showIcon]="false" *ngIf="
                    form.get('ia2periodstartdate').hasError('required') &&
                    form.get('ia2periodstartdate').touched
                    ">Please select a audit period start date!</motif-error>
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit 2 Execution End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [options]="endDateIR2Options"
                name="ia2periodenddate" id="datepicker19" #dp19="motifDatepicker" (keydown.enter)="dp19.openCalendar()"
                (click)="dp19.toggleCalendar()" formControlName="ia2periodenddate" />
              <motif-error [showIcon]="false" *ngIf="
                    form.get('ia2periodenddate').hasError('required') &&
                    form.get('ia2periodenddate').touched
                    ">Please select a audit period end date!</motif-error>
            </motif-form-field>
          </div>
        </div>
      </div>
      <motif-accordion id="collapseSection">
        <motif-accordion-header>
          Additional details
        </motif-accordion-header>
        <motif-accordion-content>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Reporting Language</label>
                <input motifInput type="text" disabled aria-label="Reporting language Form Field"
                  formControlName="reportinglanguage" />

              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Bank Size</label>
                <input motifInput type="text" disabled aria-label="Bank size Form Field" formControlName="banksize" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit ID Code - RP</label>
                <input motifInput type="text" disabled aria-label="RP ID Code Field" formControlName="idcodeRP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit ID Code - KP</label>
                <input motifInput type="text" disabled aria-label="KP ID Code Field" formControlName="idcodeKP" />
              </motif-form-field>
            </div>
          </div>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit ID Code - AP</label>
                <input motifInput type="text" disabled aria-label="AP ID Code Field" formControlName="idcodeAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit ID Code - OT</label>
                <input motifInput type="text" disabled aria-label="Other ID Code Field" formControlName="idcodeOther" />
              </motif-form-field>
            </div>
          </div>
        </motif-accordion-content>
      </motif-accordion>
    </fieldset>
  </form>
  <div class="motif-row">
    <div class="motif-col-xs-4 motif-col-lg-10"></div>
    <div class="motif-col-xs-4 motif-col-lg-1">
      <button *ngIf="btnflag" motifButton color="primary" [disabled]="RegionBasedENabledFlag"
        (click)="CreateAuditAssignment()" type="submit">Save</button>
      <button motifButton color="primary" *ngIf="!btnflag && !auditassign" [disabled]="RegionBasedENabledFlag"
        (click)="UpdateAuditAssignment()" type="submit">Update</button>
    </div>
    <div class="motif-col-xs-4 motif-col-lg-1">
      <button motifButton id="btnCancelEmployee" (click)="oncancel('cancel')" color="secondary">Cancel</button>
    </div>

  </div>
  <motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
    <motif-modal-header>
        Confirmation

    </motif-modal-header>
    <motif-modal-body>
      <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="oncancel('cancel')" type="submit">Yes</button>
       <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
    </motif-modal-footer>
  </motif-modal>
</div>
<!-- Toster and loader start -->
<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
  {{toastmessage}}</motif-toast>
<motif-loader [show]="loaderflag" [fullScreen]="true"></motif-loader>
<!-- Toster and loader end -->

