
          <div class="motif-row motif-end-lg">

            <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Timeline'}"
                    (click)="auditTrailActiveTab ='Timeline'" motifFab aria-label="floating-action-button" size="small">
              <motif-icon [src]="'./assets/icons/editor/ic_format_align_left_24px.svg'"></motif-icon>
            </button>

            &nbsp;

            <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Grid'}"
                    (click)="auditTrailActiveTab ='Grid'" motifFab aria-label="floating-action-button" size="small">
              <motif-icon [src]="'./assets/icons/image/ic_grid_on_24px.svg'"></motif-icon>
            </button>
          </div>

        
 <div *ngIf="auditTrailActiveTab =='Timeline'" class="tab2">
          
            <div class="timeLineMain">
                <div class="toggleGroup">
                  <ul class="list-inline">
                    <li style="cursor:pointer" [ngClass]="{'active': adtinsertshow}" (click)="adtinsertshow=!adtinsertshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Add</label>
                    </li>
                    <li style="cursor:pointer" [ngClass]="{'active': adtupdateshow}" (click)="adtupdateshow=!adtupdateshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Update</label>
                    </li>
                    <li style="cursor:pointer" [ngClass]="{'active': adtdeleteshow}" (click)="adtdeleteshow=!adtdeleteshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Delete</label>
                    </li>
                    <li style="cursor:pointer" [ngClass]="{'active': adtbulkinsertshow}" (click)="adtbulkinsertshow=!adtbulkinsertshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Bulk Upload</label>
                    </li>
                  </ul>
                </div>
                <div class="timeLineItem">
                  <ul style="min-height:800px" class="list-inline">
                    <li *ngFor="let ad of AuditTrailDataset">
                      <div class="dateTime">
                        <!--September 14, 2020-->
                        <span class="tlDate">{{ ad.auditDateTime | momentPipe :'LL'}}    </span>
                        <span class="tlTime"> {{ ad.auditDateTime | momentPipe:'LT'}} </span>
                      </div>
                      <div class="timelineMainItem"
                           [ngClass]="
                           {
                           'insertItem': ad.auditType == 'Insert',
                           'updateItem': ad.auditType == 'Update',
                           'deleteItem': ad.auditType  == 'Delete',
                           'bulkInsertItem':ad.auditType  == 'Bulk Upload'
  
                           }">
                        <span class="BoldHead1">{{ad.userName}}<a> ({{ad.email}})</a></span>
                        <div class="content">
                          <p *ngIf="ad.auditType  == 'Bulk Upload'"><b>{{ad.rowCount}}</b> Contacts has been uploaded.</p>
                          <p *ngIf="ad.auditType  == 'Insert'">Name <b>{{ad.nameCombined}}</b> has been inserted.</p>
                          <p *ngIf="ad.auditType  == 'Delete'">Name <b>{{ad.nameCombined}}</b> has been deleted.</p>
                          <p *ngIf="ad.auditType  == 'Update'">Name <b>{{ad.nameCombined}}</b> has been updated.</p>
  
                          <ul *ngIf="ad.auditType  == 'Update'">
  
                            <li *ngIf="ad.newValues?.FirstName !=undefined"> First Name : {{ad.newValues.FirstName}}  </li>
                            <li *ngIf="ad.newValues?.LastName !=undefined"> Last Name : {{ad.newValues.LastName}}  </li>
  
                            <!-- <li *ngIf="ad.newValues?.NameCombined !=undefined"> Name Combined : {{ad.newValues.NameCombined}}  </li> -->
  
                            <li *ngIf="ad.newValues?.Gender !=undefined"> Gender : {{ad.newValues.Gender}}  </li>
                            <li *ngIf="ad.newValues?.ContactType !=undefined"> Contact Type : {{ad.newValues.ContactType}}  </li>
  
                            <li *ngIf="ad.newValues?.Salutation !=undefined"> Salutation : {{ad.newValues.Salutation}}  </li>
  
                            <li *ngIf="ad.newValues?.Function !=undefined"> Function : {{ad.newValues.Function}}  </li>
  
                            <li *ngIf="ad.newValues?.Email !=undefined"> Email : {{ad.newValues.Email}}  </li>
  
                            <li *ngIf="ad.newValues?.Phone !=undefined"> Phone no. : {{ ad.newValues?.Phone}}  </li>
  
  
                            <li *ngIf="ad.newValues?.Mobile !=undefined"> Mobile no.: {{ad.newValues?.Mobile}}  </li>
  
                            <li *ngIf="ad.newValues?.CommunicationLanguage !=undefined"> Communication Language : {{ad.newValues.CommunicationLanguage}}  </li>
  
                            <li *ngIf="ad.newValues?.Location !=undefined"> Location : {{ad.newValues.Location}}  </li>
                            <li *ngIf="ad.newValues?.Role !=undefined"> Role : {{ad.newValues.Role}}  </li>
                            <li *ngIf="ad.newValues?.Rank !=undefined"> Rank : {{ad.newValues.Rank}}  </li>
  
                            <li *ngIf="ad.newValues?.EycontactSeniorManagerPromotiondate !=undefined"> EY contact Senior Manager Promotion date : {{ad.newValues?.EycontactSeniorManagerPromotiondate| date: 'yyyy-MM-dd'}}  </li>
  
                            <li *ngIf="ad.newValues?.StartDateonmandate !=undefined"> Start Date on mandate : {{ad.newValues?.StartDateonmandate| date: 'yyyy-MM-dd'}}  </li>
                            <li *ngIf="ad.newValues?.EntityName !=undefined"> Entity short Name : {{ad.newValues.EntityName}}  </li>
                            <li *ngIf="ad.newValues?.EYOffice !=undefined"> EY Office : {{ad.newValues.EYOffice}}  </li>
                            <li *ngIf="ad.newValues?.IROffice !=undefined"> IR Office : {{ad.newValues.IROffice}}  </li>
                            <li *ngIf="ad.newValues?.UserAccount !=undefined"> User Account : {{ad.newValues.UserAccount}}  </li>
                            <li *ngIf="ad.newValues?.CommentContactForm !=undefined"> Comment Contact Form : {{ad.newValues.CommentContactForm}}  </li>
                            <li *ngIf="ad.newValues?.CommunicationStatus !=undefined"> Commnunication Status : {{ad.newValues.CommunicationStatus}}  </li>
             
  
                          </ul>
                        </div>
                      </div>
                    </li>
                    <!--<li>
              <div class="dateTime">
                <span class="tlDate">September 14, 2020 </span>
                <span class="tlTime">10:15 AM</span>
              </div>
              <div class="timelineMainItem updateItem">
                <h5>Hari</h5>
                <div class="content">
                  <p>Item description writing here!</p>
                  <ul>
                    <li>Item1</li>
                    <li>Item2</li>
                    <li>Item3</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="dateTime">
                <span class="tlDate">September 14, 2020 </span>
                <span class="tlTime">10:15 AM</span>
              </div>
              <div class="timelineMainItem deleteItem">
                <h5>Hari</h5>
                <div class="content">
                  <p>Item description writing here!</p>
                  <ul>
                    <li>Item1</li>
                    <li>Item2</li>
                    <li>Item3</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="dateTime">
                <span class="tlDate">September 14, 2020              </span>
                <span class="tlTime">10:15 AM</span>
              </div>
              <div class="timelineMainItem bulkInsertItem">
                <h5>Hari</h5>
                <div class="content">
                  <p>Item description writing here!</p>
                  <ul>
                    <li>Item1</li>
                    <li>Item2</li>
                    <li>Item3</li>
                  </ul>
                </div>
              </div>
            </li>-->
            </ul>
        </div>
    </div>
            
</div>
<div *ngIf="auditTrailActiveTab=='Grid'" class="tab2">

    <ag-grid-angular [frameworkComponents]="auditframeworkComponents" (gridReady)="handleauditGridReady($event)" [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-material motif-ag-grid"
    [columnDefs]="DirectoryAuditColumnDefs" [rowData]="DirectoryAuditDataSet" [modules]="modules"
    [animateRows]="true" [pagination]="true" [paginationPageSize]=30></ag-grid-angular>

</div>

<motif-loader [show]="loaderflag" [fullScreen]="true"></motif-loader>