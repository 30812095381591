import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private _http: HttpClient) { }

  private AuthrizedActions: any;
  public key = CryptoJS.enc.Utf8.parse('4512631236589784');
  public iv = CryptoJS.enc.Utf8.parse('4512631236589784');
  timer: any;
  GetUserRolesActions() {
    return this._http.get(`${environment.apiUrl}/Role/GetUserRolesActions`);
  }

  setActions(Actions) {
    this.AuthrizedActions = Actions;

  }
  ClearActions() {
    this.AuthrizedActions = null;
  }

  getAllActions(): any {
    return this.AuthrizedActions;
  }


  getActions(ScreenName: string): any {
    return new Promise(resolve => {
      if (this.AuthrizedActions != null && this.AuthrizedActions != undefined) {
        let currentscreen = this.AuthrizedActions.filter(screen => screen.screenName == ScreenName);

        let actions = currentscreen[0].actions.map(function (action) {

          if (action.isChecked)
            return action.actionName
        });
        return resolve(actions);
      }
      else {
        //To get Actions for logged in user based on Role
        this.GetUserRolesActions()
          .subscribe(
            (res: any) => {
              let Actions = [];

              Actions = res[0].menuScreenAction.concat(res[1].menuScreenAction)
              this.setActions(Actions);
              let currentscreen = this.AuthrizedActions.filter(screen => screen.screenName == ScreenName);

              let actions = currentscreen[0].actions.map(function (action) {

                if (action.isChecked)
                  return action.actionName
              });
              return resolve(actions);
            },
            err => {

            }
          )
      }
    });

  }

  GetMenus() {
    return this._http.get(`${environment.apiUrl}/Role/GetMenus`);
  }
  getUserEmail(obj) {
    return this._http.post(`${environment.apiUrl}/Role/getUserEmail`, obj);
  }
  encryptUsingAES256(DecryptedUserId) {    
    var EncryptedUserId = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(DecryptedUserId), this.key, {
        keySize: 512 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return EncryptedUserId;
  }


  decryptUsingAES256(EncryptedId) {
    var decrypted = CryptoJS.AES.decrypt(EncryptedId, this.key, {
      keySize: 512 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
