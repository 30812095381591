import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AuditAssignmentService{   
   

    constructor(private _http: HttpClient) { }
    
//public auditassignmentid:any;
private auditobj:any={
 auditassignmentid:"",
  flag: "",
  screen:"",
  newflag:""
}
    private startupdata:any={
      bankcode:"",
      AuditYear:"",
      
    }

    

    GetAuditassignmentDetils(isMyBanks) {   
        
      return this._http.get(`${environment.apiUrl}/AuditAssignment/GetAuditAssignment?isMyBanks=`+isMyBanks);

    }
      GetEntityDetails() {   
        
        return this._http.get(`${environment.apiUrl}/AuditAssignment/GetEntityDetails/`);

      }
      DeleteData(obj) {

        return this._http.post(`${environment.apiUrl}/AuditAssignment/Delete/`, obj);
    
      }
      GetMastersData() {   
        
        return this._http.get(`${environment.apiUrl}/AuditAssignment/GetMasterData/`);

      }
      GetFormStatusData() {   
        
        return this._http.get(`${environment.apiUrl}/AuditAssignment/GetFormStatusData/`);

      } 
      GetRoleBasedRegion(){   
        
        return this._http.get(`${environment.apiUrl}/AuditAssignment/GetROleBasedRegion/`);

      } 
      SaveData(obj) {   
        
        return this._http.post(`${environment.apiUrl}/AuditAssignment/Post`,obj);

      }  
      UpdateData(obj){
        return this._http.post(`${environment.apiUrl}/AuditAssignment/put`,obj);
      }
      Upload(formdata:FormData) {   
        
        return this._http.post(`${environment.apiUrl}/AuditAssignment/Upload/`, formdata);

      } 
      Excelexport(columnnames) {   
        
        return this._http.post(`${environment.apiUrl}/AuditAssignment/ExcelExport/`,columnnames,{responseType: "blob"});

      } 
       TempleateExport() {   
        
        return this._http.get(`${environment.apiUrl}/AuditAssignment/DownloadTemplate/`,{responseType: "blob"});

      }


      getAuditTrail()
      {

        return this._http.get(`${environment.apiUrl}/AuditAssignment/GetAuditAssignmentsAuditData/`);
      }
      setData(obj) {      
        this.startupdata = obj;  
      }  
      getData() {  
        return this.startupdata;  
      }

      // setupdateData(id){
      //   this.auditassignmentid=id;
      // }
      // getupdateData() {  
      //   return this.auditassignmentid;  
      // }
      setupdateData(obj) {      
        this.auditobj = obj;  
      }  
      getupdateData() {  
        return this.auditobj;  
      }
      

      
      
      MassUpdateAssignmentData(obj) {   
        
        return this._http.post(`${environment.apiUrl}/AuditAssignment/MassUpdateAssignmentData/`,obj);

      }
      getRegionData() {   
        
        return this._http.get(`${environment.apiUrl}/DashBoard/GetMasterData/`);

      } 
  
    
}
