import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    //const httpRequest = req.clone({
      // headers: new HttpHeaders({
      //    //'Cache-Control': 'no-cache',
      //    //'Pragma': 'no-cache',
      //   // 'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      //  // 'Cache-Control':  'no-cache, no-store,max-age=604800, must-revalidate, post-check=0, pre-check=0',
      //   //'Pragma': 'no-cache',
      //   //'Expires': '0'
      // })
    //});
      const httpRequest = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0no-cache, no-store')
          .set('Pragma', 'no-cache')
          .set('Expires', '0')
      })
    

    return next.handle(httpRequest);
  }
}