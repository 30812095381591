import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../Services/authentication.service';
import { SignalRService } from '../Services/signal-r.service';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,private _signalRService: SignalRService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                
                if (event instanceof HttpResponse && event.status === 400) {
                  console.log('hitted');
                }
              }),
              catchError(err => {            
            if (err.status === 401) {                
                // auto logout if 401 response returned from api
                if(err.error === "InvalidUser"){
                    sessionStorage.removeItem('jwt');
                    sessionStorage.removeItem("refreshToken");
                }
                else if(err.error === "InvalidRefreshTokenOrExpired"){
                    sessionStorage.removeItem('jwt');
                    sessionStorage.removeItem("refreshToken");
                    this.authenticationService.logout();
                }
                else{
                    this._signalRService.UpdateLogOutDateTime();
                    this.authenticationService.logout();
                }
                //this.authenticationService.logout();
                //location.reload(true);
            } 
            let error='';          
            if(err.status==400){               
                var key=Object.keys(err["error"]["errors"])[0];             
                error=err["error"]["errors"][key];
              
              }
              else
                error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}