 <!--<div class="loginSec">
    <div class="card">
        <div class="card-body">
            <div class="side form">
                <div class="img-logo">
                    <img src="./assets/EY_logo_dark-new.svg" width="50px">
                </div>

               <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="username">User Name</label>
                        <input type="text" formControlName="username" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">User Name is required</div>
                        </div>
                    </div>-->
                    <!-- <div class="form-group"> 
                        <label style="display: none;" for="password">Password</label>
                        <input style="display: none;" value="xyz" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div> 
                                    <div class="motif-row motif-end-lg" style="margin: 32px 0 0;">
                        <button class="button"
                            style="background-color: #ffe600; /* Green */border: none;color: black;font-weight: bold;padding: 15px 32px;text-align: center;text-decoration: none;display: inline-block;font-size: 16px;"
                            type="submit" [disabled]="loading" class="btn btn-primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Login
                        </button>
                    </div>
                    <div class="motif-row">
                        <div style="color: red;" *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </div>
                </form> 
            </div>
        </div>

        <div class="side picture">
            <h2>Raiffeisen AMT</h2>
        </div>
    </div>
</div>-->


<!-- <div class="form-group">
    <label style="display: none;" for="password">Password</label>
    <input style="display: none;" value="xyz" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">Password is required</div>
    </div>
</div> -->

<!-- Old and normal user login screen -->



<!-- 
    ------------------------------------------------------------------------------
    UAT Merge Login
    ------------------------------------------------------------------------------
 -->

<div class="loginSec">
    <div class="contentBox">
        <div class="titleAMT">
            <h2>AMT</h2>
            <button class="button"
                type="submit" [disabled]="loading" (click)="onSubmit()" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Login
            </button>
        </div>
        <p>Raiffeisen – Audit Management Tool <br> (Version 1.0) </p> <br>
    </div>

    <div class="loginFooter">
        <div class="footer__group">
            <img class="footer__image" src="../../../assets/ey-logo-with-tagline.svg" alt="EY Logo with Tagline" />
            <p class="footer__parag">
                <i class="material-icons fCopyRight">copyright</i> 2021 EYGM Limited | All Rights Reserved.
                <br /> Content within this application is confidential and meant for EY staff and EY clients only.
                <br /> For legal and privacy policies refer to My EY legal agreements              
            </p>
            <div class="linkBlock">
                <ul class="list-inline">
                    <li>
                        <a href="https://login.ey.com/myey/cookie-policy" target="_blank">Cookie policy</a>
                    </li>
                    <li>
                        <a href="https://euwppatscesta01.blob.core.windows.net/tnc/PrivacyNotice.html" target="_blank">Privacy notice</a>
                    </li>
                    <li>
                        <a href="https://login.ey.com/myey/terms-of-use" target="_blank">My EY terms of use (Multilingual)</a>
                    </li>
                    <li>
                        <a href="https://euwppatscesta01.blob.core.windows.net/tnc/CountryReference.html" target="_blank">My EY country list</a>
                    </li>
                    <li>
                        <a href="https://login.ey.com/myey/global-terms-of-use">Global legal agreement</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div> 

<!-- add 5 points -->



