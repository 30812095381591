import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
  selector: 'app-preapprovalauditlist',
  templateUrl: './preapprovalauditlist.component.html',
  styleUrls: ['./preapprovalauditlist.component.scss']
})
export class PreapprovalauditlistComponent implements ICellRendererAngularComp {

  public params: any;


  agInit(params: any): void {
;
    if (params.data.auditType != "Bulk Upload" && params.data.auditType != "Insert" && params.data.auditType != "Delete") {
      if (params.colDef.headerName == "New Values") {
        this.params = params.data.newValues;
      }
      else if (params.colDef.headerName == "Old Values") {

        this.params = params.data.oldValues;
      }
    } else { this.params=null }
  }

  refresh(): boolean {
    return false;
  }

}
