import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/helpers/auth.guard';
import { LoginComponent } from './core/login/login.component';
import { HomeComponent } from './layouts/home/home.component';
import { ClientMasterComponent } from './modules/clientEntity/components/client-master/client-master.component';
import { LocationComponent } from '../app/modules/Masters/location/location.component'
import { CantonComponent } from './modules/Masters/canton/canton.component';
import { EYOfficeComponent } from './modules/Masters/eyoffice/eyoffice.component';
import { EYRegionComponent } from './modules/Masters/eyregion/eyregion.component';
import { IRRBOfficeComponent } from './modules/Masters/irrboffice/irrboffice.component';
import { UserMangementComponent } from './modules/Admin/UserManagement/user-mangement/user-mangement.component';
import { DirectoryMasterComponent } from './modules/directory/directory-master/directory-master.component';
import { RoleManagementComponent } from './modules/Admin/role-management/role-management.component';
import { UnauthorizedAccessComponent } from './reusable/components/unauthorizedAccess/unauthorized-access/unauthorized-access.component';
import { AuditAssignmentComponent } from './modules/AuditAssignment/audit-assignment/audit-assignment.component';
import { LandingComponent } from './reusable/components/landing/landing.component';
import { DashboradCountComponent } from './dashborad-count/dashborad-count.component';

import { ContactTypeComponent } from './modules/Masters/contact-type/contact-type.component';
import { ClientEntityFormStatusComponent } from './modules/masters/client-entity-form-status/client-entity-form-status.component';
import { AuditTypeStatusComponent } from './modules/masters/audit-type-status/audit-type-status.component';
import { HolidayTableComponent } from './modules/Masters/holiday-table/holiday-table.component';
import { PlanningDatesConfigurationComponent } from './modules/masters/planning-dates-configuration/planning-dates-configuration.component';
import { StartUpComponent } from './start-up/start-up.component';
import { AuditTypeStatusApComponent } from './modules/Masters/audit-type-status-ap/audit-type-status-ap.component';
import { AuditTypeStatusKpComponent } from './modules/Masters/audit-type-status-kp/audit-type-status-kp.component';
import { AuditTypeStatusRpComponent } from './modules/Masters/audit-type-status-rp/audit-type-status-rp.component';
import { AuditTypeStatusOtComponent } from './modules/Masters/audit-type-status-ot/audit-type-status-ot.component';
import { FunctionComponent } from './modules/Masters/function/function.component';
import { RankComponent } from './modules/Masters/rank/rank.component';
import { NotificationComponent } from './modules/Masters/notification/notification.component';
import { NotificationAlterComponent } from './modules/Masters/notification-alter/notification-alter.component';
import { EmailConfigurationForAuditTypeStatusComponent } from './modules/Masters/EmailConfiguration/email-configuration-for-audit-type-status/email-configuration-for-audit-type-status.component';
import { AddUpdateEmailConfigurationComponent } from './modules/Masters/EmailConfiguration/add-update-email-configuration/add-update-email-configuration.component';
import { AddUpdateEmailConfigurationForDueDatesComponent } from './modules/Masters/EmailConfigurationForDueDates/add-update-email-configuration-for-due-dates/add-update-email-configuration-for-due-dates.component';
import { EmailConfigurationForDueDatesComponent } from './modules/Masters/EmailConfigurationForDueDates/email-configuration-for-due-dates/email-configuration-for-due-dates.component';
import { MeetingFormatComponent } from './modules/Masters/meeting-format/meeting-format.component';
import { AGGridGanttChartComponent } from './modules/Masters/AG-Grid-GanttChart/AG-Grid-GanttChart.component';
import { PreApprovalEmailConfigurationComponent } from './modules/Masters/PreApprovalEmailConfiguration/pre-approval-email-configuration/pre-approval-email-configuration.component';
import { AddUpdatePreApprovalEmailConfigurationComponent } from './modules/Masters/PreApprovalEmailConfiguration/add-update-pre-approval-email-configuration/add-update-pre-approval-email-configuration.component';
import { EntityTypeComponent } from './modules/Masters/entity-type/entity-type.component';
import { ServiceStatusComponent } from './modules/Masters/service-status/service-status.component';
import { ApprovalStatusComponent } from './modules/Masters/approval-status/approval-status.component';
import { ApprovalLevelComponent } from './modules/Masters/approval-level/approval-level.component';
import { PreApprovalComponent } from './modules/pre-approval/pre-approval.component';
//import { EmailConfigurationForAuditTypeStatusComponent } from './modules/Masters/email-configuration-for-audit-type-status/email-configuration-for-audit-type-status.component';
//import { AddUpdateAuditAssignmentComponent } from './modules/AuditAssignment/add-update-audit-assignment/add-update-audit-assignment.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: "",
    component: HomeComponent,canActivate: [AuthGuard],
    children: [
      //{ path: "", component: ClientMasterComponent, canActivate: [AuthGuard] },
      { path: "clientEntity", component: ClientMasterComponent, canActivate: [AuthGuard] },
      { path: "clientEntity/:id", component: ClientMasterComponent, canActivate: [AuthGuard] },
      { path: "location", component: LocationComponent, canActivate: [AuthGuard] },
      { path: "canton", component: CantonComponent, canActivate: [AuthGuard] },
      { path: "eyoffice", component: EYOfficeComponent, canActivate: [AuthGuard] },
      { path: "eyregion", component: EYRegionComponent, canActivate: [AuthGuard] },
      { path: "function", component: FunctionComponent, canActivate: [AuthGuard] },
      { path: "rank", component: RankComponent, canActivate: [AuthGuard] },
      { path: "irrboffice", component: IRRBOfficeComponent, canActivate: [AuthGuard] },
      { path: "contacttype", component: ContactTypeComponent, canActivate: [AuthGuard] },
      { path: "formstatus", component: ClientEntityFormStatusComponent, canActivate: [AuthGuard] },
      { path: "auditstatus", component: AuditTypeStatusComponent, canActivate: [AuthGuard] },
      { path: "auditstatusap", component: AuditTypeStatusApComponent, canActivate: [AuthGuard] },
      { path: "auditstatuskp", component: AuditTypeStatusKpComponent, canActivate: [AuthGuard] },
      { path: "auditstatusrp", component: AuditTypeStatusRpComponent, canActivate: [AuthGuard] },
      { path: "auditstatusot", component: AuditTypeStatusOtComponent, canActivate: [AuthGuard] },
      { path: "EntityType", component: EntityTypeComponent, canActivate: [AuthGuard] },
      { path: "ServiceStatus", component: ServiceStatusComponent, canActivate: [AuthGuard] },
      { path: "ApprovalStatus", component: ApprovalStatusComponent, canActivate: [AuthGuard] },
      { path: "ApprovalLevel", component: ApprovalLevelComponent, canActivate: [AuthGuard] },
      //{ path: "userManagement", component: UserMangementComponent },
      { path: "directory", component: DirectoryMasterComponent, canActivate: [AuthGuard] },
      { path: "directory/:id", component: DirectoryMasterComponent, canActivate: [AuthGuard] },
      // { path: "", component: LandingComponent, canActivate: [AuthGuard] },
      { path: "holiday", component: HolidayTableComponent, canActivate: [AuthGuard] },
      {path:"trackerview",component:AGGridGanttChartComponent,canActivate :[AuthGuard]},
      {path:"preapproval",component:PreApprovalComponent,canActivate :[AuthGuard]},
      { path: "preapproval/:id", component: PreApprovalComponent, canActivate: [AuthGuard] },


      { path: "landing", component: LandingComponent, canActivate: [AuthGuard] },
      //{ path: "clientEntity", component: ClientMasterComponent , canActivate: [AuthGuard]},
      { path: "role", component: RoleManagementComponent, canActivate: [AuthGuard] },
      { path: "auditassignment", component: AuditAssignmentComponent, canActivate: [AuthGuard] },
      { path: "auditassignment/:id", component: AuditAssignmentComponent, canActivate: [AuthGuard] },
      { path: "unauthorizedaccess", component: UnauthorizedAccessComponent },
      { path: "Dashboard", component: DashboradCountComponent, canActivate: [AuthGuard]},
      { path: "planningdatesconfigurations", component: PlanningDatesConfigurationComponent },
      {path:"Notification",component:NotificationComponent},
      {path:"NotificationAlter",component:NotificationAlterComponent},
      //{path:"EmailConfigurationForAuditTypeStatus",component:EmailConfigurationForAuditTypeStatusComponent},
      //{ path: "AddUpdateEmailConfiguration", component:AddUpdateAuditAssignmentComponent },

      {path:"EmailConfiguration", component:EmailConfigurationForAuditTypeStatusComponent, canActivate: [AuthGuard]},
      { path: "EmailConfiguration/:id", component: EmailConfigurationForAuditTypeStatusComponent, canActivate: [AuthGuard] },
      {path:"AddUpdateEmailConfiguration",component:AddUpdateEmailConfigurationComponent, canActivate: [AuthGuard]},

      { path: "PreApprovalEmailConfiguration", component: PreApprovalEmailConfigurationComponent, canActivate: [AuthGuard] },
      { path: "PreApprovalEmailConfiguration/:id", component: PreApprovalEmailConfigurationComponent, canActivate: [AuthGuard] },
      {path:"AddUpdatePreApprovalEmailConfiguration",component:AddUpdatePreApprovalEmailConfigurationComponent, canActivate: [AuthGuard]},

      {path:"EmailConfigurationForDueDates", component:EmailConfigurationForDueDatesComponent},
      { path: "EmailConfigurationForDueDates/:id", component: EmailConfigurationForDueDatesComponent, canActivate: [AuthGuard] },
      {path:"AddUpdateEmailConfigurationForDueDates", component:AddUpdateEmailConfigurationForDueDatesComponent},
      { path: "", component: StartUpComponent ,canActivate: [AuthGuard] },      
      {path:"meetingFormat", component: MeetingFormatComponent, canActivate: [AuthGuard]}
    ]
  }]

@NgModule({
  imports: [RouterModule.forRoot(routes,{enableTracing:true,useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
