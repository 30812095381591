import { Component, NgZone, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { NotificationService } from 'src/app/modules/Masters/notification/notification.service';
import { AuthenticationService } from '../Services/authentication.service';
import { SignalRService } from '../Services/signal-r.service';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  

  constructor(private _AuthenticationService: AuthenticationService, private _headerService: HeaderService,
    private _signalRService: SignalRService,private adalService:AdalService, private _ngZone: NgZone,private notificationservice:NotificationService) { }
  lstMenus: any = [];
  showMenu = false;
  loginuser: string;
  email: string;
  roleName: string;
  userClick: boolean = false;
  public deletemodalflag: boolean = false;
  public menuopened1:boolean;
  public menuopened2:boolean;
  public menuopened3:boolean;
  public menuopen1:any;
  public menuopen2:any;
  public menuopen3:any;


  public notifications: any =[];
  ngOnInit(): void {    
    this.GetMenus();
    // var userInfo = this._AuthenticationService.getUserEmail(sessionStorage.getItem("username"));
    // this.loginuser = userInfo.userName;
    // this.email = userInfo.email;
    // this.roleName = userInfo.RoleName;
    this.getUserEmail();
    this.GetNotications();
  }

  GetMenus() {
    this._headerService.GetMenus()
      .subscribe(
        res => {
          this.lstMenus = res;
        },
        err => {
          console.log(err);
        }
      )
  }
  getUserEmail(){
    let user=this.adalService.userInfo.userName;
    var obj={
      UserName:user
    }
    this._headerService.getUserEmail(obj)
      .subscribe(
        res => {
          this.loginuser = res["message"]["name"];
          this.email = res["message"]["email"];
          this.roleName = res["message"]["roleName"];
        },
        err => {
          console.log(err);
        }
      )
  }

  //To check whether logged in user has access to menu
  //returns true, if a user has access to it
  //returns false, if a user doesn't have view access
  CheckMenuAccess(menuName): boolean {
    return this.lstMenus.find(x => x.screenName == menuName);
  }

  onClickNotifications() {
    alert('Click on notifications header icon');
  }

  onClickProfile() {
    alert('Click on profile header icon');
  }

  onClickLocation() {
    alert('Click on location header icon');
  }

  OnMenuclick1(){
    debugger;
    this.menuopen1="opened";
    if(this.menuopened1==false && this.menuopen1!="opened")
      this.menuopened1=false;
    else
      this.menuopened1=true;

    if(this.menuopened2==false)
       this.menuopened2=false;
    else if(this.menuopened2==true)
      this.menuopened2=false;
    
    if(this.menuopened3==false)
    this.menuopened3=false;
    
    else if(this.menuopened3==true)
      this.menuopened3=false;
  }
  OnMenuclick2(){
    debugger;
    this.menuopen2="opened";

    if(this.menuopened1==false)
      this.menuopened1=false;
    else if(this.menuopened1==true)
      this.menuopened1=false;

    if(this.menuopened2==false && this.menuopen2!="opened")
      this.menuopened2=false;
    else
      this.menuopened2=true;
  
    if(this.menuopened3==false)
      this.menuopened3=false;
    else if(this.menuopened3==true)
        this.menuopened3=false;
  }

  OnMenuclick3(){
    debugger;
    this.menuopen3="opened";
    if(this.menuopened1==false)
      this.menuopened1=false;
    else if(this.menuopened1==true)
      this.menuopened1=false;

    if(this.menuopened2==false)
        this.menuopened2=false;
    else if(this.menuopened2==true)
        this.menuopened2=false;

    if(this.menuopened3==false && this.menuopen3!="opened")
      this.menuopened3=false;
    else
      this.menuopened3=true;
  }

  logout() {    
    this._signalRService.UpdateLogOutDateTime();
    this._headerService.ClearActions();
    this._AuthenticationService.logout();
  }

  showUserProfile() {
    this.userClick = !this.userClick;
  }

  closeUserProfile() {
    this.userClick = false;
  }
notificationmodelopen(){
  this.deletemodalflag=true;
}
notificationmodelclose(){
  this.deletemodalflag=false;
}
  clearNotification(){
    this.notifications.reset();
  }
  GetNotications() {
    this._signalRService.Notificationrecieved.subscribe((data: any) => {      
      this._ngZone.run(() => {        
        this.notifications = [];
        if (Array.isArray(data.message)) {
          data.message.forEach((obj) => {
           // obj.localDate = obj.CreatedDateTime.toString();
            this.notifications.push(obj);
            console.log(this.notifications);
          });
        } else {
          this.notifications.push(data.message);
        }
       // if (data.isFirstNotification != true) this.openNotificationSnackBar();
      });
    });
  }
  ClearEachNotification(notificationId){    
      let ClearEachobj = {
        notificationId: notificationId
      }
      console.log(ClearEachobj)
  
      this.notificationservice.ClearEachNotificationUpdate(ClearEachobj)
        .subscribe(
          (res: any) => {
          console.log(res);
            
          },
          
        ) 
  }

  ClearAllNotifications(){
   let listids=[];
  //  for(let id in listids){
  //  listids.push(this.notifications.notificationId)
  //  }

  // foreach(let id in this.notifications.notificationId){
    this.notifications.forEach(element => {
      listids.push(element.notificationId);
    });

    let ClearEachobj = {
      notificationIds: listids
    }
   
  // }
  this.notificationservice.ClearAllNotificationsUpdate(ClearEachobj)
  .subscribe(
    (res:any) => {
      console.log(res);
      if(res.message=="success"){
        this.notifications=[];
      }
    },
  )
  }


}
