<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
<div class="playbook-main">
    <div class="examples-container">

        <div class="motif-row" style="margin-bottom:20px">

            <div class="motif-col-xs-4 motif-col-lg-3">
                <motif-tab-bar class="motif-tab-bar">
                  <button motifButton (click)="currentactivetab = 'Directory'" [ngClass]="{'motif-active': currentactivetab === 'Directory'}" class="motif-text-link motif-active">
                    <motif-icon [src]="'/assets/icons/social/ic_group_24px.svg'"></motif-icon>
                    Directory
                  </button>
                    <button motifButton *ngIf="ActionsCheck('Audit Trail')" (click)="currentactivetab = 'Audit';AuditTrail.getAuditTrail()" [ngClass]="{'motif-active': currentactivetab === 'Audit'}" class="motif-text-link" >
                        <motif-icon [src]="'/assets/icons/action/ic_history_24px.svg'"></motif-icon>
                        Audit Trail</button>
                </motif-tab-bar>
            </div>

            <div class="motif-col-xs-8 motif-col-lg-9">

        <div class="motif-row motif-end-lg" *ngIf="currentactivetab == 'Directory'">
                    <!-- <div class="motif-col-xs-4 motif-col-md-2">
&nbsp;
                    </div> -->

                    <input motifInput id="Search" placeholder="Search.." type="text" class="motif-col-xs-4 motif-col-md-3" [(ngModel)]="ngSearchFilter" (ngModelChange)="onFilterTextBoxChanged()"/>
                    &nbsp;
                   
                    <button size="large" *ngIf="ActionsCheck('Bulk Upload')" (click)="ShowFileUploadModel()" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary">
            <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_upload_24px.svg'"></motif-icon>Bulk Upload
          </button> &nbsp;
                    <button size="large" *ngIf="ActionsCheck('Export')" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary" (click)="ExcelExportpopup()">
            <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
          </button> &nbsp;
                    <button size="large" *ngIf="ActionsCheck('Add')" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary" title="Add Request" (click)="AddDirectory()">
                      <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/content/ic_add_circle_24px.svg'"></motif-icon>Add Contact
                    </button> &nbsp;
                    <button size="large" *ngIf="ActionsCheck('Update')" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary" title="Update" (click)="onbtnclickMassUpdate()">
                        <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/action/ic_update_24px.svg'"></motif-icon>Bulk Update
                      </button> &nbsp;
                     
                    <!-- <button size="large" class="motif-col-xs-4 motif-col-md-1" motifButton color="primary">
              <motif-icon [src]="'/assets/icons/action/ic_history_24px.svg'"></motif-icon>Audit Trail
            </button> -->
                </div>
            </div>
        </div>
        
        <div class="motif-row" *ngIf="currentactivetab == 'Directory'">           
                <div *ngIf="NgSearchFlag" class="motif-col-xs-4 motif-col-lg-2">
                        <motif-form-field>
                        <label motifLabel>Contact Type</label>
                        <motif-select [(ngModel)]="ngSearchContactType" [multiple]="true" class="ClienentitytModalselect" (change)="externalContactTypeFilterChanged($event)" aria-label="Contact Type Form Field">
                            <motif-option *ngFor="let item of lstSearchFilterContactType"  [value]="item.contactTypeName">
                                {{item.contactTypeName}}</motif-option>
                        </motif-select>
                       </motif-form-field>                   
                    
                </div>
                <div *ngIf="NgSearchFlag" class="motif-col-xs-4 motif-col-lg-2">
                    <motif-form-field>
                        <label motifLabel>Function</label>
                        <motif-select [(ngModel)]="ngSearchFunction"  [multiple]="true" class="ClienentitytModalselect" (change)="externalFunctionFilterChanged($event)"  aria-label="function Form Field">            
                        <motif-option *ngFor="let item of lstSearchFilterFunction" [value]="item.functionName" >
                                {{item.functionName}}</motif-option>
                        </motif-select>               
                    </motif-form-field>
                </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <button size="large" motifButton color="primary" title="Reset" (click)="resetFIlters()">
                    <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/action/ic_autorenew_24px.svg'"></motif-icon>Reset
                  </button>
                
            </div>
        </div>
        
        <div style="width: 100%;" [hidden]="currentactivetab != 'Directory'">
            <div class="motif-table">
                <div id="dvag-grid" class="motif-table-wrapper" [ngClass]="{'motif-table-first-column-border': true}">
                    <ag-grid-angular [ngStyle]="style"  [suppressRowClickSelection]="true"  [rowSelection]="rowSelection" [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-balham" [columnDefs]="direcotryColumnDefs" [rowData]="directoryTableDataset" [modules]="modules" (gridReady)="handleGridReady($event)"
                        [animateRows]="true" (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)" [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents"
                        [isExternalFilterPresent]="isExternalFilterPresent"  [doesExternalFilterPass]="doesExternalFilterPass"></ag-grid-angular>
                </div>
            </div>
        </div> 
        <div [hidden]="currentactivetab != 'Audit'">
         
            <app-audit-directory-master #AuditTrail></app-audit-directory-master>
        </div>
    </div>

   
</div>

<!-- FileUpload Model Start-->
<motif-modal [show]="showFileUploadModel" (close)="showFileUploadModel = false">
    <motif-modal-header>
        Upload
    </motif-modal-header>
    <motif-modal-body>



        <div *ngIf="showFileUploadModel" class="motif-container">

            <div class="motif-row motif-end-lg">
                <button style="margin-bottom:8px" size="large" class="motif-col-xs-4 motif-col-md-2" motifButton color="primary" (click)="DirectoryTempleateExport()">
                        <motif-icon [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Download Template
                </button>


            </div>

            <motif-form-field>
                <motif-file-upload [disabled]="DirectoryUploader.queue.length > 0 " (change)="fileChange($event)" [uploader]="DirectoryUploader" [multiple]="false">
                    Drop files here or click to upload
                </motif-file-upload>
            </motif-form-field>

            <motif-uploader (cancel)="clearDirectoryFiles()" *ngFor="let item of DirectoryUploader?.queue" fileName="{{item.file.name}}" progressValue="100" fileSize="" progressLabel="" errorMessage="There was a problem while uploading the file, please upload valid file"
                [error]="uploadfileerror">
            </motif-uploader>


        </div>


    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" [disabled]="DirectoryUploader.queue.length == 0 " (click)="UploadData()">Confirm</button>
         <button motifButton color="secondary" (click)="showFileUploadModel = false">Cancel</button>
    </motif-modal-footer>
</motif-modal>

<!--- FileUpload Model End-->
<!-- Toster and loader start -->
<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
    {{toastmessage}}</motif-toast>
<!-- Toster and loader end -->

<!-- FileUpload validation modal start-->

<motif-modal [show]="Validationsmodalflag" (close)="Validationsmodalflag = false">
    <motif-modal-header>
        Incomplete data found in the following fields, please correct and upload again.

    </motif-modal-header>
    <motif-modal-body>

        <motif-accordion type="editorial" id="example-2" *ngFor="let item of invalidarryKeys">
            <motif-accordion-header>
                {{item}}
            </motif-accordion-header>

            <motif-accordion-content>
                <ul>
                    <li *ngFor="let invaliditem of validationdata[item].split(',')">{{invaliditem}}</li>
                </ul>
            </motif-accordion-content>
        </motif-accordion>

    </motif-modal-body>
    <motif-modal-footer>

    </motif-modal-footer>
</motif-modal>
<!-- FileUpload validation model end -->

<!-- Directory Add and Edit modal start -->
<motif-modal [show]="directortAddEditModal" (close)="directortAddEditModal = false" [slideout]="true" class="DirectoryModal">
  <motif-modal-header>
    Contact
  </motif-modal-header>
    <motif-modal-body style="height: 650px;">
        <form [formGroup]="form" novalidate>
            <fieldset [disabled]='viewdirectoryentity' style="border: 0;">
            <!-- <span class="fonts-col__heading-4"><b> Entity Details </b></span> -->
            <div class="motif-row">
                <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>First Name</label>
                        <input motifInput type="text"  aria-label="First Name Form Field" (change)="FirstNameLastNamChange()" formControlName="firstname" />
                        <motif-error *ngIf="
                                        form.get('firstname').hasError('required') &&
                                        form.get('firstname').touched
                                        ">Please provide a first name!</motif-error>
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>Last Name</label>
                        <input motifInput type="text" aria-label="Last Name Form Field" (change)="FirstNameLastNamChange()"  formControlName="lastname" />
                        <motif-error *ngIf="
                                    form.get('lastname').hasError('required') &&
                                    form.get('lastname').touched
                                    ">Please provide a last name!</motif-error>
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>Name Combined</label>
                        <input motifInput type="text" style="cursor:default;pointer:none" readonly [(ngModel)]="nameCombined" aria-label="Name Combined Form Field" formControlName="namecombined" />                        
                    </motif-form-field>
                </div>
                
            </div>
            <div class="motif-row">
                <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <motif-radio-group formControlName="gendertype">
                            <div class="motif-row">
                                <div class="motif-col-xs-6 motif-col-lg-4">
                                    <motif-radio-button value="M"  class="radio" [title]="'Custom radio element title'">Male</motif-radio-button>
                                </div>
                                <div class="motif-col-xs-6 motif-col-lg-4">
                                    <motif-radio-button value="F" class="radio" [title]="'Custom radio element title'">Female</motif-radio-button>
                                </div>                               
                            </div>
                        </motif-radio-group>
                        <motif-error *ngIf="
                                    form.get('gendertype').hasError('required') &&
                                    form.get('gendertype').touched
                                    ">Please select a gender!</motif-error>
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>                   
                      <label motifLabel>Salutation</label>
                      <motif-select formControlName="salutation" class="ClienentitytModalselect" aria-label="Salutation">
                        <motif-option value="Herr">Herr</motif-option>
                        <motif-option value="Frau">Frau</motif-option> 
                        <motif-option value="Monsieur">Monsieur</motif-option>
                        <motif-option value="Madame">Madame</motif-option>
                        <motif-option value="Signor">Signor</motif-option>
                        <motif-option value="Signora">Signora</motif-option>
                      </motif-select>
                      <motif-error *ngIf="
                          form.get('salutation').hasError('required') &&
                          form.get('salutation').touched
                          ">Please select salutation!</motif-error>
                    </motif-form-field>
                  </div>  
                  <div class="motif-col-xs-4 motif-col-lg-4">
                      <motif-form-field>
                          <label motifLabel>Email</label>
                          <input motifInput type="text" aria-label="Email Form Field" formControlName="email" />
                          <motif-error *ngIf="
                                          form.get('email').hasError('required') &&
                                          form.get('email').touched
                                          ">Please provide a valid email</motif-error>
                                          <motif-error *ngIf="
                                          form.get('email').hasError('email')
                                          ">Please provide a valid email</motif-error>
                      </motif-form-field>
                  </div>
           </div>
            <!-- <span class="fonts-col__heading-4"><b>Address</b> </span> -->
           
            <div class="motif-row">
                <div  class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>Phone no.</label>
                        <input motifInput type="text" aria-label="Phoneno Form Field" [(ngModel)]="ngPhoneno" (ngModelChange)="onphoneInputChange($event)" formControlName="phoneno" />
                        <motif-error *ngIf="
                                    form.get('phoneno').hasError('required') &&
                                    form.get('phoneno').touched
                                    ">Please provide a phone no!</motif-error>
                                    <motif-error *ngIf="form.get('phoneno').hasError('pattern')">Please provide a valid phone no!</motif-error>
                    </motif-form-field>
                </div>
                <div  class="motif-col-xs-4 motif-col-lg-4">                    
                    <motif-form-field>
                        <label motifLabel>Mobile no.</label>
                        <input motifInput type="text" aria-label="Phoneno Form Field" [(ngModel)]="ngMobileno" (ngModelChange)="onmobileInputChange($event)" formControlName="mobileno" />
                        <motif-error *ngIf="
                                    form.get('mobileno').hasError('required') &&
                                    form.get('mobileno').touched
                                    ">Please provide a mobile no!</motif-error>
                                    <motif-error *ngIf="form.get('mobileno').hasError('pattern')">Please provide a valid mobile no!</motif-error>
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>Contact Type</label>
                        <motif-select formControlName="contacttype" class="ClienentitytModalselect" aria-label="Contatct Type Form Field" (change)="onChangeContactType($event)">
                            <motif-option *ngFor="let item of contactTypeMasterData" [value]="item.contactTypeId">
                                {{item.contactTypeName}}</motif-option>
                        </motif-select>
                        <motif-error *ngIf="
                                        form.get('contacttype').hasError('required') &&
                                        form.get('contacttype').touched
                                        ">Please select a contact type!</motif-error>
                    </motif-form-field>
                </div>
            </div>
            <div class="motif-row">
               
                <div *ngIf="directortAddEditModal" class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>Function</label>
                        <motif-select formControlName="function" [multiple]="true"  class="ClienentitytModalselect" aria-label="function Form Field">
                    
                         <motif-option *ngFor="let item of functionMasterDatafilteredlist" [value]="item.functionId"   [checked]="item.isChecked">
                                {{item.functionName}}</motif-option>
                        </motif-select>
                        <motif-error *ngIf="
                        form.get('function').hasError('required') &&
                        form.get('function').touched
                        ">Please select a function!</motif-error>
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>Communication Language</label>
                        <motif-select formControlName="communicationlanguage" class="ClienentitytModalselect" aria-label="Communication Language Form Field">
                            <motif-option *ngFor="let item of cummunicationLanguageMasterData" [value]="item.communicationLanguageId">
                                {{item.communicationLanguageName}}</motif-option>
                        </motif-select>
                        <motif-error *ngIf="
                        form.get('communicationlanguage').hasError('required') &&
                        form.get('communicationlanguage').touched
                        ">Please select a communication language!</motif-error>
                    </motif-form-field>
                </div>
                <!-- <div class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                        <label motifLabel>Location</label>
                        <motif-select formControlName="location" class="ClienentitytModalselect" aria-label="Location Form Field">
                            <motif-option *ngFor="let item of locationMasterData" [value]="item.locationId">
                                {{item.locationName}}</motif-option>
                        </motif-select>
                        <motif-error *ngIf="
                        form.get('location').hasError('required') &&
                        form.get('location').touched
                        ">Please select a location!</motif-error>
                    </motif-form-field>
                </div> -->
                <div *ngIf="directortAddEditModal" class="motif-col-xs-4 motif-col-lg-4">
                    <motif-form-field>
                      <label motifLabel>Rank</label>
                      <motif-select formControlName="rank" [multiple]="true" class="ClienentitytModalselect" aria-label="Location Form Field">
                        <motif-option *ngFor="let item of rankMasterData" [value]="item.rankId" [checked]="item.isChecked">
                          {{item.rankName}}
                        </motif-option>
                      </motif-select>                 
                    </motif-form-field>
                  </div>
            </div>          
            <!-- <span class="fonts-col__heading-4"> <b> Other</b> </span> -->           
            <div class="motif-row">     
             
              <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                  <label motifLabel>EY Contact: Senior Manager-promotion date</label>
                  <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="promotiondate" id="datepicker" #dp1="motifDatepicker" (keydown.enter)="dp1.openCalendar()" (click)="dp1.toggleCalendar()" formControlName="promotiondate" />
                  </motif-form-field>
              </div>
              <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                        <label motifLabel>Start Date on mandate</label>
                        <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="startDatemanDate" id="datepicker1" #dp2="motifDatepicker" (keydown.enter)="dp2.openCalendar()" (click)="dp2.toggleCalendar()" formControlName="startDatemanDate"
                        />                    
                   
                </motif-form-field>
                </div>
                <div class="motif-col-xs-1 motif-col-lg-4">
                    <motif-form-field>
                      <label motifLabel>Role</label>
                      <motif-select formControlName="role" class="ClienentitytModalselect" aria-label="Role Form Field">
                        <motif-option *ngFor="let item of roleMasterData" [value]="item.roleId">
                          {{item.roleName}}
                        </motif-option>
                      </motif-select>
                      <motif-error *ngIf="
                            form.get('role').hasError('required') &&
                            form.get('role').touched
                            ">Please select a role!</motif-error>
                    </motif-form-field>
                  </div>
            </div>
       <div *ngIf="EntityFieldFlag">     
        <div class="motif-row">          
            
              <div class="motif-col-xs-4 motif-col-lg-12">
                <motif-form-field>
                    <label motifLabel>Entity Short Name</label>
                    <input motifInput  motifSearch  type="text" placeholder="Search for entity name..."  [hideClear]="true"
                        aria-label="Search" formControlName="entityname" [motifTypeahead]="arrayCLientEntity" (change)="FillEntityBaseDetails()"/>   
                    <ng-container *ngIf="form.controls.entityname.invalid">
                        <motif-error   [showIcon]="false"                          
                            *ngIf="form.get('entityname').hasError('required') &&
                            form.get('entityname').touched"
                            >Please select a valid entity short name!</motif-error>                     
                     </ng-container>
                    
                </motif-form-field>
               
            </div>
        </div>
        <div class="motif-row">            
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>EY CID (GFIS)</label>
                    <input motifInput type="text" aria-label="EY CID (GFIS) Form Field" disabled formControlName="EYCID"/>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Bank Code</label>
                    <input motifInput type="text" aria-label="Bank Code Form Field" disabled formControlName="BankCode" />
                   
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Client ID(Mercury)</label>
                    <input motifInput type="text" aria-label="Client ID(Mercury) Form Field" disabled formControlName="ClientId"/>
                   
                </motif-form-field>
            </div>
        </div>        
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Street</label>
                    <input motifInput type="text" aria-label="Street Form Field" disabled formControlName="Street"/>
                    
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Postal Code</label>
                    <input motifInput type="text" aria-label="Postal Code Form Field" disabled formControlName="PostalCode"/>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Canton</label>
                    <input motifInput type="text" aria-label="Canton Form Field" disabled formControlName="Canton"/>
                    
                </motif-form-field>
            </div>
        </div>          
      
        <div class="motif-row">           
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Country</label>
                    <input motifInput type="text" aria-label="Country Form Field" disabled formControlName="Country"/>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>IR Office</label>
                    <input motifInput type="text" aria-label="IR Office Form Field" disabled formControlName="IROffice"/>
                </motif-form-field>
            </div>

            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Location</label>
                    <input motifInput type="text" aria-label="IR Office Form Field" disabled formControlName="Location"/>
                </motif-form-field>
            </div>
        </div>
    </div>   
        <div class="motif-row"> 
            <div [hidden]="!IROfficeFieldFlag" class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>IR Office</label>
                    <motif-select formControlName="irrboffice" class="ClienentitytModalselect" aria-label="IR RB office Form Field">
                        <motif-option *ngFor="let item of IROfficeMasterData" [value]="item.irofficeId">
                            {{item.irofficeName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                form.get('irrboffice').hasError('required') &&
                form.get('irrboffice').touched
                ">Please select a IR Office!</motif-error>
                </motif-form-field>          
             </div>
             <div  [hidden]="!EYOfficeEntityFieldFlag" class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>EY Office</label>
                    <motif-select formControlName="eyoffice" class="ClienentitytModalselect" aria-label="EY Office Form Field">
                        <motif-option *ngFor="let item of EYOfficeMasterData" [value]="item.eyofficeId">
                            {{item.eyOfficeName}}</motif-option>
                    </motif-select>
                    <motif-error *ngIf="
                form.get('eyoffice').hasError('required') &&
                form.get('eyoffice').touched
                ">Please select a EY Office!</motif-error>
                </motif-form-field>
             </div>
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>User Account</label>
                    <input motifInput type="text" aria-label="User Account Form Field"  formControlName="useraccount"/>
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-4">
                <motif-form-field>
                    <label motifLabel>Communication Status</label>
                    <input motifInput type="text" aria-label="Communication status Form Field" formControlName="commuStatus"/>
                </motif-form-field>
            </div>
        </div>   
            <div class="motif-row">
                <div class="motif-col-xs-4 motif-col-lg-12">
                    <motif-form-field>
                        <label motifLabel>Comment - Contact Form</label>
                        <textarea motifInput aria-label="disabled example textarea" class="motif-textarea" formControlName="Comment"></textarea>
                    </motif-form-field>
                </div>
            </div>
            <!-- <div class="motif-row motif-end-xs">
               <button motifButton color="primary" size="large" type="submit">Validate Example</button>
           </div> -->
           </fieldset>
        </form>

    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="DirectorySave(form)" *ngIf="btnAddFlag" type="submit">Save</button>
        <button motifButton color="primary" (click)="DirectoryUpdate(form)" *ngIf="!btnAddFlag && !viewdirectoryentity" type="submit">Update</button>
        <button motifButton id="btnCancelEmployee" color="secondary" (click)="directortAddEditModal = false">Cancel</button>
    </motif-modal-footer>
</motif-modal>

<!-- Directory Add and Edit modal end -->

<!-- Directory delete modal start -->
<motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
    <motif-modal-header>
        Delete Contact
    </motif-modal-header>
    <motif-modal-body>
      <p>
        Are you sure, you want to delete contact?
      </p>
    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="DeleteDirectory()">Confirm</button>
        <button motifButton color="secondary" (click)="deletemodalflag = false">Cancel</button>
    </motif-modal-footer>
</motif-modal>
<!-- Directory delete modal end -->

<!--excel Export modal start-->
<motif-modal [show]="ExcelExportmodalflag" (close)="ExcelExportmodalflag = false">
    <motif-modal-header>
        Export To Excel

    </motif-modal-header>
    <motif-modal-body>

        <form [formGroup]="ExcelToexportForm">
            <motif-form-field>
                <motif-radio-group formControlName="radioexceltype" (change)="ReportOptionChange()">
                    <div class="motif-row">
                        <div class="motif-col-xs-4 motif-col-lg-2">
                            <motif-radio-button value="all" [title]="'Custom radio element title'">ALL</motif-radio-button>
                        </div>
                        <div class="motif-col-xs-4 motif-col-lg-2">
                            <motif-radio-button value="RBContacts" [title]="'Custom radio element title'">RB Contacts</motif-radio-button>
                        </div>
                        <div class="motif-col-xs-4 motif-col-lg-2">
                            <motif-radio-button value="EYContacts" [title]="'Custom radio element title'">EY Contacts</motif-radio-button>
                        </div>
                        <!-- <div class="motif-col-xs-4 motif-col-lg-2">
                            <motif-radio-button value="GIS" [title]="'Custom radio element title'">GIS</motif-radio-button>
                        </div>
                        <div class="motif-col-xs-4 motif-col-lg-2">
                            <motif-radio-button value="FINMA" [title]="'Custom radio element title'">FINMA</motif-radio-button>
                        </div>
                        <div class="motif-col-xs-4 motif-col-lg-2">
                            <motif-radio-button value="FAOA" [title]="'Custom radio element title'">FAOA</motif-radio-button>
                        </div> -->
                        <div class="motif-col-xs-4 motif-col-lg-2">
                            <motif-radio-button value="Custom" [title]="'Custom radio element title'">Custom</motif-radio-button>
                        </div>
                    </div>
                </motif-radio-group>
            </motif-form-field>

            <div class="motif-row">
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkBankCode">Bank Code</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkContactType">Contact Type</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkSalutation">Salutation</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkFirstName">First Name</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                
            </div>
            <div class="motif-row">
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkLastName">Last Name</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkNamecombined">Name combined</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkGender">Gender</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkFunction">Function</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                
            </div>
            <div class="motif-row">
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkEmail">Email</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkPhone">Phone</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkMobile">Mobile</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkCommunicationLanguage">Communication Language</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                
            </div>
            <div class="motif-row">
              <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkRole">Role</motif-checkbox>
                        </motif-form-field>
                    </div>
              </div>
             
              <div class="motif-col-xs-1">
                <div>
                  <motif-form-field>
                    <motif-checkbox formControlName="chkRank">Rank</motif-checkbox>
                  </motif-form-field>
                </div>
              </div>
              <div class="motif-col-xs-1">
                <div>
                  <motif-form-field>
                    <motif-checkbox formControlName="chkEYContactifSeniorManagerpromotiondate">EY Contact: if Senior Manager - promotion date</motif-checkbox>
                  </motif-form-field>
                </div>
              </div>
              <div class="motif-col-xs-1">
                <div>
                  <motif-form-field>
                    <motif-checkbox formControlName="chkStartDateonmandate">Start Date on mandate</motif-checkbox>
                  </motif-form-field>
                </div>
              </div>
              
              </div>
              <div class="motif-row">
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkEYOffice">EY Office</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkDirIROffCie">IR Office</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkEntityName">Entity Short Name</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkEYCID">EY CID (GFIS)</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                
              </div>
              <div class="motif-row">
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkClientID">Client ID (Mercury)</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkStreet">Street</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkPostalCode">Postal Code</motif-checkbox>
                        </motif-form-field>
                    </div>

                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkLocation">Location</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>             
             
              </div>
              <div class="motif-row">
                
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkCanton">Canton</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkCountry">Country</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkIROffice">IR Office</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>  
                <div class="motif-col-xs-1">
                    <div>
                        <motif-form-field>
                            <motif-checkbox formControlName="chkUserAccount">User Account</motif-checkbox>
                        </motif-form-field>
                    </div>
                </div>             
            </div>
                  <div class="motif-row">
                    
                    <div class="motif-col-xs-1">
                        <div>
                          <motif-form-field>
                            <motif-checkbox formControlName="chkCommentEntityDataForm">Comment - Contact Form</motif-checkbox>
                          </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                          <motif-form-field>
                            <motif-checkbox formControlName="chkCommunicationStatus">Communication Status</motif-checkbox>
                          </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                          <motif-form-field>
                            <motif-checkbox formControlName="chkNameCombinedFL">Name Combined(First & Last Name)</motif-checkbox>
                          </motif-form-field>
                        </div>
                    </div>
            </div>
        </form>
    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="DirectoryExcelexport(ExcelToexportForm)" type="submit">Export</button>
        <button motifButton color="secondary" (click)="ExcelExportmodalflag = false">Cancel</button>
    </motif-modal-footer>
</motif-modal>

<!--excel export modal end-->
</div>


<!-- FileUpload Model Start-->
<motif-modal [show]="UpdateDirectoryModel" (close)="UpdateDirectoryModel = false">
    <motif-modal-header>
        Bulk Update
    </motif-modal-header>
    <motif-modal-body>



        <form [formGroup]="MassUpdateForm">            
            <div class="motif-row">
                <div class="motif-col-xs-2">                    
                        <motif-form-field>
                            <label motifLabel>Function</label>
                            <motif-select formControlName="function" [multiple]="true" class="ClienentitytModalselect" aria-label="function Form Field">
                        
                             <motif-option *ngFor="let item of functionMasterData" [value]="item.functionId"   [checked]="item.isChecked">
                                    {{item.functionName}}</motif-option>
                            </motif-select>                           
                        </motif-form-field>                    
                </div>
                <div class="motif-col-xs-2">
                    <div>
                        <motif-form-field>
                            <label motifLabel>Rank</label>
                            <motif-select formControlName="rank" [multiple]="true" class="ClienentitytModalselect" aria-label="rank Form Field">
                              <motif-option *ngFor="let item of rankMasterData" [value]="item.rankId" [checked]="item.isChecked">
                                {{item.rankName}}
                              </motif-option>
                            </motif-select>                 
                          </motif-form-field>
                     
                    </div>
                </div>                
            </div>            
            <div class="motif-row">
                <div class="motif-col-xs-2">                    
                    <motif-form-field>
                        <label motifLabel>User Account</label>
                        <input motifInput type="text" aria-label="User Account Form Field"  formControlName="useraccount"/>
                    </motif-form-field>
              
                </div>
                <div class="motif-col-xs-2">
                    <div>
                        <motif-form-field>
                            <label motifLabel>Comment</label>
                            <input motifInput type="text" aria-label="Comment Form Field"  formControlName="comment"/>   </motif-form-field>
                     
                    </div>
                </div>  
                <div class="motif-col-xs-2">                    
                    <motif-form-field>
                        <label motifLabel>Communication status</label>
                        <input motifInput type="text" aria-label="Communication Status Form Field"  formControlName="commuStatus"/>
                    </motif-form-field>
              
                </div>              
            </div>            
                  
        </form>

    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="MassUpdateDirectoryData()" >Update</button>
         <button motifButton color="secondary" (click)="UpdateDirectoryModel = false">Cancel</button>
    </motif-modal-footer>
</motif-modal>

<motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
    <motif-modal-header>
        Confirmation

    </motif-modal-header>
    <motif-modal-body>
      <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="EscapeYes()" type="submit">Yes</button>
       <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
    </motif-modal-footer>
</motif-modal>