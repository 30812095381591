<div class="GanttMain">
  <div class="dashboardHeader">
    <h2>Tracker View - {{selectedYear}}<span> </span></h2>
    <div class="regionSelect">
      <ul class="list-inline list">
        <li class="listclass17">
          <motif-form-field>
            <label motifLabel>Select Year</label>
            <motif-select [(ngModel)]="selectedYear"
              (change)="GetTrackerView($event);GenerateMondaydatesFromSelectedYear($event)" [multiple]="false"
              aria-label="function Form Field">
              <motif-option *ngFor="let item of lstYear" [value]="item">
                {{item}}
              </motif-option>
            </motif-select>
          </motif-form-field>
        </li>
        <li class="listclass17">
          <motif-form-field *ngIf="NgSearchFlag">
            <label motifLabel>Select Lead</label>
            <motif-select [(ngModel)]="selectedLead" [multiple]="false" (change)="onChangeAuditType()"
              aria-label="function Form Field">
              <motif-option value="EY">EY</motif-option>
              <motif-option value="IR">IR</motif-option>
              <motif-option value="NA">NA</motif-option>
            </motif-select>
          </motif-form-field>
        </li>
        <li class="listclass16">
          <motif-form-field *ngIf="NgSearchFlag">
            <label motifLabel>Audit Type</label>
            <motif-select [(ngModel)]="selectedAuditType" (change)="onChangeAuditType()" [multiple]="false"
              aria-label="function Form Field">
              <motif-option value="AP">AP</motif-option>
              <motif-option value="KP">KP</motif-option>
              <motif-option value="RP">RP</motif-option>
              <motif-option value="OT">OT</motif-option>
            </motif-select>
          </motif-form-field>
        </li>
        <li class="listclass">
          <motif-form-field
            *ngIf="selectedAuditType !='AP' && selectedAuditType !='KP' && selectedAuditType !='RP' && selectedAuditType !='OT'">
            <label motifLabel>Audit Type status</label>
            <motif-select class="ClienentitytModalselect" aria-label="Audit Type" [disabled]="true">
              <motif-option [value]="">""</motif-option>
            </motif-select>
          </motif-form-field>
          <motif-form-field  *ngIf="selectedAuditType=='AP' && NgSearchFlag">
            <label motifLabel>Audit Status AP</label>
            <motif-select [(ngModel)]="selectedAuditStatus" [multiple]="true"
              (change)="externalFilterChanged($event,'Status')" class="ClienentitytModalselect"
              aria-label="auditstatusap">
              <motif-option *ngFor="let item of lstAuditTypeAP" [value]="item.auditTypeStatusName">
                {{item.auditTypeStatusName}}
              </motif-option>
            </motif-select>
          </motif-form-field>

          <motif-form-field *ngIf="selectedAuditType=='KP' && NgSearchFlag">
            <label motifLabel>Audit Status KP</label>
            <motif-select [(ngModel)]="selectedAuditStatus" [multiple]="true"
              (change)="externalFilterChanged($event,'Status')" class="ClienentitytModalselect"
              aria-label="auditstatuskp">
              <motif-option *ngFor="let item of lstAuditTypeKP" [value]="item.auditTypeStatusName">
                {{item.auditTypeStatusName}}
              </motif-option>
            </motif-select>
          </motif-form-field>

          <motif-form-field *ngIf="selectedAuditType=='RP' && NgSearchFlag">
            <label motifLabel>Audit Status RP</label>
            <motif-select [(ngModel)]="selectedAuditStatus" [multiple]="true"
              (change)="externalFilterChanged($event,'Status')" class="ClienentitytModalselect"
              aria-label="auditstatusrp">
              <motif-option *ngFor="let item of lstAuditTypeRP" [value]="item.auditTypeStatusName">
                {{item.auditTypeStatusName}}
              </motif-option>
            </motif-select>

          </motif-form-field>

          <motif-form-field *ngIf="selectedAuditType=='OT' && NgSearchFlag">
            <label motifLabel>Audit Status OT</label>
            <motif-select [(ngModel)]="selectedAuditStatus" [multiple]="true"
              (change)="externalFilterChanged($event,'Status')" class="ClienentitytModalselect"
              aria-label="auditstatusot">
              <motif-option *ngFor="let item of lstAuditTypeOT" [value]="item.auditTypeStatusName">
                {{item.auditTypeStatusName}}
              </motif-option>
            </motif-select>

          </motif-form-field>

        </li>

        <li class="listclass18">
          <motif-form-field *ngIf="NgSearchFlag">
            <label motifLabel>Select Region</label>
            <motif-select [(ngModel)]="selectedRegion" (change)="externalFilterChanged($event,'Region')"
              [multiple]="true" aria-label="function Form Field">
              <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionName">
                {{item.eyregionName}}</motif-option>
            </motif-select>
          </motif-form-field>
        </li>
        <li class="listclass18">

          <motif-form-field *ngIf="NgSearchFlag">
            <label motifLabel>IR Office</label>
            <motif-select [(ngModel)]="selectedIRoffice" [multiple]="true"
              (change)="externalFilterChanged($event,'IROffice')" aria-label="IR office Form Field">
              <motif-option *ngFor="let item of irRBofficeMasterData" [value]="item.irofficeName">
                {{item.irofficeName}}</motif-option>
            </motif-select>

          </motif-form-field>


        </li>
        <li class="listclass14">
          <p-multiSelect *ngIf="NgSearchFlag" [virtualScroll]="true" itemSize="15" [options]="lstEYContacts" [(ngModel)]="selectedPartner" (onChange)="externalFilterChanged($event,'Partner')"
            placeholder="Engagement Partner" defaultLabel="Engagement Partner" optionLabel="contactName" class="multiselect-custom">
            <ng-template pTemplate="header"> </ng-template>
            <ng-template let-value pTemplate="selectedItems">
              <div class="country-item country-item-value inlineBlockFlag" *ngFor="let option of selectedPartner">
                <b class="newAddedLabel" *ngIf="selectedPartner.length >= 1">
                  Engagement Partner</b>
                <div *ngIf="selectedPartner.length == 1" class="inlineBlockFlag"> {{option.contactName}}</div>
                <div *ngIf="selectedPartner.length > 1" class="inlineBlockFlag"> {{option.contactName}}/</div>
              </div>
              <div *ngIf="!selectedPartner || selectedPartner.length === 0"
                class="country-placeholder ClienentitytModalselect">
                <div class="pmarginright Engagementlabel">Engagement Partner</div>
              </div>
            </ng-template>
            <ng-template let-cont pTemplate="item">
              <div class="country-item">
                <div> {{cont.contactName}}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
          </p-multiSelect>

          
        </li>
        <!-- <li class="listclass14">
          <p-multiSelect *ngIf="NgSearchFlag" [virtualScroll]="true" itemSize="15" [options]="lstEYContacts" [(ngModel)]="selectedManager" (onChange)="externalFilterChanged($event,'Manager')"
            placeholder="Engagement Manager" defaultLabel="Engagement Manger" optionLabel="contactName" class="multiselect-custom">
            <ng-template pTemplate="header"> </ng-template>
            <ng-template let-value pTemplate="selectedItems">
              <div class="country-item country-item-value inlineBlockFlag" *ngFor="let option of selectedManager">
                <b class="newAddedLabel" *ngIf="selectedManager.length >= 1">
                  Engagement Manager</b>
                <div *ngIf="selectedManager.length == 1" class="inlineBlockFlag"> {{option.contactName}}</div>
                <div *ngIf="selectedManager.length > 1" class="inlineBlockFlag"> {{option.contactName}}/</div>
              </div>
              <div *ngIf="!selectedManager || selectedManager.length === 0"
                class="country-placeholder ClienentitytModalselect">
                <div class="pmarginright Engagementlabel">Engagement Manager</div>
              </div>
            </ng-template>
            <ng-template let-cont pTemplate="item">
              <div class="country-item">
                <div> {{cont.contactName}}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
          </p-multiSelect>         
         
        </li> -->
        <li class="btnli">
          <button size="large" motifButton color="primary" title="Reset" (click)="resetFIlters()">
            <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/action/ic_autorenew_24px.svg'">
            </motif-icon>Reset
          </button>
        </li>
      </ul>  
    </div>
  </div>
  <div style="width: 100%;">
    <div class="motif-table">
      <div id="dvag-grid" class="motif-table-wrapper" [ngClass]="{'motif-table-first-column-border': true}">
        <ag-grid-angular style="height: 592px; width: 100%;" class="ag-theme-balham"
          [frameworkComponents]="frameworkComponents" [columnDefs]="TrackerViewMaingridcolDef"
          [rowData]="TrackerViewData" [modules]="modules" [animateRows]="true" [pagination]="false"
          (gridReady)="handleGridReady($event)" [isExternalFilterPresent]="isExternalFilterPresent"
          [doesExternalFilterPass]="TrackerViewExternalFilter">
        </ag-grid-angular>
      </div>
    </div>
  </div>
  <div class="floatbtn">
    <button class="Legendbtn">
      <div class="floater">
        <h1>Legend</h1>
        <div class="colorwithtext">
          <div class="FieldworkEY"></div>
          <span>Field work EY</span>
        </div>
        <div class="colorwithtext">
          <div class="FieldworkIR"></div>
          <span>Field work IR</span>
        </div>
        <div class="colorwithtext">
          <div class="DraftReporttoClientDue"></div>
          <span>Draft Report to Client Due</span>
        </div>
        <div class="colorwithtext">
          <div class="IAWorkPaperReviewDue"></div>
          <span>IA Work Paper Review Due</span>
        </div>
        <div class="colorwithtext">
          <div class="DecisiveDate"></div>
          <span>Decisive Date</span>
        </div>
        <div class="colorwithtext finalreportparentdiv">
          <div class="FinalReporttoClientDue"></div>
          <span class="finalreportspan">Final Report (AP) or Feedback (KP) to Client Due / Latest Sign-off Date (RP)</span>
        </div>
        <div class="colorwithtext">
          <div class="Today"></div>
          <span>Today</span>
        </div>
        <div class="colorwithtext">
          <div class="TodayFinalReportisOverdue"></div>
          <span>Today - Final Report is Overdue</span>
        </div>
        <div class="colorwithtext">
          <div class="AGMDate"></div>
          <span>AGM Date</span>
        </div>
                
      </div>
      
    </button>
  </div> 
</div>
<!-- 
<ag-grid-angular *ngIf="GridView" style="height: 700px; width: 100%;" class="ag-theme-balham"
  [frameworkComponents]="frameworkComponents" [columnDefs]="colDef" [rowData]="Data" [modules]="modules"
  [animateRows]="true" [pagination]="true" [paginationPageSize]=70 (gridReady)="handleGridReady($event)" 
  [isExternalFilterPresent]="isExternalFilterPresent"
  [doesExternalFilterPass]="doesExternalFilterPass">
</ag-grid-angular> -->

<div class="ganttChartPopup">
  <motif-modal [show]="ShowModel" (close)="ShowModel = false">
    <motif-modal-header >
      <u style="cursor:pointer" (click)="gotoAuditAssignmentfromPopup()">{{popupFormTitle}}</u>
    </motif-modal-header>

    <motif-modal-body>
      <div class="">
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Engagement Partner</label>
              <input motifInput type="text" disabled aria-label="Bank size Form Field"
                [(ngModel)]="Engagementpartner" />
            </motif-form-field>
          </div>
          <!-- <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Engagement Manager</label>
              <input motifInput type="text" disabled aria-label="Bank size Form Field"
                [(ngModel)]="EngagementManager" />
            </motif-form-field>
          </div> -->

          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>FINMA Lead Auditor</label>
              <input motifInput type="text" disabled aria-label="Bank size Form Field" [(ngModel)]="Finmaleadauditor" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Internal Audit Engagement Leader</label>
              <input motifInput type="text" disabled aria-label="Bank size Form Field"
                [(ngModel)]="internalauditengagementleader" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>OT Partner</label>
              <input motifInput type="text" disabled aria-label="Bank size Form Field" [(ngModel)]="OTPartner" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>OT Manager </label>
              <input motifInput type="text" disabled aria-label="Bank size Form Field" [(ngModel)]="OTManager" />
            </motif-form-field>
          </div>

        </div>
        <ag-grid-angular *ngIf="ShowModel"
          style="height: 300px; width: 100% !important; padding-top: 20px; max-width: none;" class="ag-theme-balham"
          [frameworkComponents]="frameworkComponents" [columnDefs]="AuditTypecolDef" [rowData]="AuditTypeRowData"
          [modules]="modules" [animateRows]="true" [pagination]="false" [paginationPageSize]=70>
        </ag-grid-angular>
      </div>
    </motif-modal-body>

    <motif-modal-footer>
    </motif-modal-footer>
  </motif-modal>
</div>