import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MotifTableHeaderRendererComponent } from '@ey-xd/ng-motif';
import { ButtonRendererComponent } from './button-renderer-component';
import { ClientMasterService } from './client-master.service'
import { saveAs } from 'file-saver';
import { MotifTableCellRendererComponent } from '@ey-xd/ng-motif';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { ClientEntityTableActionsComponent } from '../../TableActions/client-entity-table-actions/client-entity-table-actions.component';
import { FileUploader } from 'ng2-file-upload';
import * as moment from 'moment';
import { AgdatetimepickerComponent } from '../../../../reusable/components/ag-grid/date-time-picker/agdatetimepicker.component';
import { AuditchnageslistComponent } from '../../TableActions/auditchnageslist/auditchnageslist.component';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { SelectItem } from 'primeng/api';
import { SelectItemGroup } from 'primeng/api';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';

//import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-client-master',
  templateUrl: './client-master.component.html',
  styleUrls: ['./client-master.component.scss']
})
export class ClientMasterComponent implements OnInit {

  @ViewChild('entName') entNameElement: ElementRef;

  @HostListener('focusout', ['$event']) public onListenerTriggered(event: any): void {

    if (event.target.id == "btnCancelEntity") {
      this.setFocusToInput();
    }
  }
  /////////////////search select
  // ngAfterViewInit() {
  //   this.setInitialValue();
  // }

  // ngOnDestroy() {
  //   this._onDestroy.next();
  //   this._onDestroy.complete();
  // }
  // protected setInitialValue() {
  //   this.filteredBanks
  //     .pipe(take(1), takeUntil(this._onDestroy))
  //     .subscribe(() => {
  //       // setting the compareWith property to a comparison function
  //       // triggers initializing the selection according to the initial value of
  //       // the form control (i.e. _initializeSelection())
  //       // this needs to be done after the filteredBanks are loaded initially
  //       // and after the mat-option elements are available
  //       this.singleSelect.compareWith = (a: Bank, b: Bank) => a && b && a.id === b.id;
  //     });
  // }
  //////////////////////////////////////////////////////
  setFocusToInput() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.renderer.selectRootElement("#entName").focus();
    }, 500);

  }

  toasttype: string;
  toastflag: boolean = false;
  toastmessage: string;
  form: FormGroup;
  ExcelToexportForm: FormGroup;
  public loaderflag: boolean = false;
  public Validationsmodalflag: boolean = false;
  public ExcelExportmodalflag: boolean = false;
  public invalidarryKeys: any = []
  gridApi: any;
  auditgridApi: any;
  public clientEntityId: number;
  public clientEntityModal: boolean = false;
  public btncliententity: boolean = true;
  public viewcliententity : boolean = true;
  public ngSearchFilter: any;
  public auditTrailActiveTab = "Timeline";
  public editflag:any;

  myOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd'
  };
  @ViewChild("datepicker") datePicker;
  @ViewChild('dp2', { static: false })
  dp2: TemplateRef<any>;

  modules = AllCommunityModules;
  public PaginationDataset: any;
  public uploadshowprogress: boolean = false;
  public deletemodalflag: boolean = false;
  public currentlySelectedPageSize: any;
  public validationdata: any;
  public clientEntityColumnDefs: any;
  public clientEntityRowData: any = [];
  public locationMasterData: any = [];
  public cantonMasterData: any;
  public countryMasterData: any;
  public reportingLanMasterData: any;
  public documentLanMasterData: any;
  public bankSizeMasterData: any;
  public eyOffcieMasterData: any;
  public eyRegionMasterData: any;
  public irRBofficeMasterData: any;
  public formStatusMasterData: any;
  public fiscalyearendData: any;
  public filterdata:any;
  public Escmodalflag:boolean=false;
  frameworkComponents: any;
  private pageSize: number;
  //public thisIsFirstColumn: string;
  public showFileUploadModel: boolean;
  ClientEntityUploader: FileUploader = new FileUploader({ url: '' });
  public uploadfileerror: boolean = false;
  public currentactivetab: string = "Client";
  public rowSelection:any='multiple';
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };

  public auditstyle = {
    height: ""
  }
  public overlayLoadingTemplate: string =
    ' <span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';



  public AuditTrailDataset: any;
  public AuditTrailfilterDataset: any;
  public adtinsertshow: boolean = true;
  public adtbulkinsertshow: boolean = true;
  public adtupdateshow: boolean = true;
  public adtdeleteshow: boolean = true;
  public clientEntityAuditColumnDefs: any;
  public clientEntityAuditDataSet: any = [];
  auditframeworkComponents: any;


  ////#region actions variables
  public divFlag: boolean = false;
  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };
  public actionAccess: any;
  ////#endregion

//#region filtervariables
public RegionSelected:any;
public irofficeSelected:any;
public BankSelected="allBanks";
public isMyBanks:boolean=false;
public NgSearchFlag:boolean=true;
//#endregion
  //isFirstColumn = (params) => {
  //  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  //  const thisIsFirstColumn = displayedColumns[0] === params.column;

  //  return thisIsFirstColumn;
  //};






  handleGridReady(params) {
    this.gridApi = params;

    this.gridApi.api.showLoadingOverlay()
    

    const Id: string = this.route.snapshot.queryParamMap.get('id');
    if(Id != null)
    {
      var id=this._headerService.decryptUsingAES256(Id);
      this._clientmasterservice.GetClientENtity("allBanks")
      .subscribe(
        res => {
          this.ClientEntityData = res;
          var filtereddata=this.ClientEntityData.filter(data=>data.clientEntityId == Number(id))
          this.filterdata={
            rowData:filtereddata[0]
          }
          this.viewclientEntity(this.filterdata);
        })
    }
    this.gridApi.api.hideOverlay()
  };
  private buildHeaders() {

    this.clientEntityColumnDefs = [
      {
       
        headerName: 'Bank Code', field: 'bankCodeRgrCid', sortable: true, filter: 'agTextColumnFilter',
        unSortIcon: true, wrapText: true, autoHeight: true, resizable: true, width: 124,  checkboxSelection: true, headerCheckboxSelection: true,headerCheckboxSelectionFilteredOnly: true,
        filterParams: {
          buttons: ['reset'],
        },
      },
      {
        headerName: 'Client Entity Name', field: 'clientEntityName', sortable: true, filter: 'agTextColumnFilter',
        unSortIcon: true, cellClass: "ag-grid-cell-wrap-text",        
        autoHeight: true,
        width: 300,
        resizable: true,
        filterParams: {
          buttons: ['reset'],
        },
      },
      {
        headerName: 'Entity Short Name', field: 'rbShortName', sortable: true, filter: 'agTextColumnFilter', 
        unSortIcon: true, autoHeight: true, resizable: true, width: 168, 
        filterParams: {
          buttons: ['reset'],
        },
      },
      { headerName: 'Bank Size', field: 'bankSize1', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
      { headerName: 'EY Region', field: 'eyregionName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
      { headerName: 'IR Office', field: 'irofficeName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 132 },

      {
        headerName: 'Fiscal Year End', field: 'clientFiscalYearEnd', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 150,

        // cellRenderer: (data) => {
        // 
        //   var celldate = moment(data.clientFiscalYearEnd);

        //   return data.clientFiscalYearEnd ? "" : celldate.format("DD.MM") + " "


        //},

        //filterParams: {
        //  buttons: ['reset'],
        //  // provide comparator function

        //}
      
        filterParams: {
          valueGetter: (params) => {

            return params.data.clientFiscalYearEnd ? "" : moment(params.data.clientFiscalYearEnd).format("DD.MM") + " "
          },
          textCustomComparator: this.agcustomfisicaldatefilter

        }

      },

      { headerName: 'Street', field: 'street', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 168 },
      { headerName: 'Postal Code', field: 'postalCode', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 134 },
      { headerName: 'Location', field: 'location', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 115 },
      { headerName: 'Canton', field: 'cantonName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 106 },
      { headerName: 'Country', field: 'countryName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 106 },
      { headerName: 'Reporting Language', field: 'reportingLanguage1', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 180 },
      { headerName: 'Documentation Language', field: 'documentationLanguage1', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true },

      { headerName: 'EY Office', field: 'eyOfficeName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 125 },
      { headerName: 'UID-No.', field: 'uidnumber', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 130 },
      { headerName: 'Form Status', field: 'formStatus', sortable: true, filter: 'agTextColumnFilter', pinned: 'right', unSortIcon: true, autoHeight: true, resizable: true, width: 136 }
    ];
    //if (this.actionAccess.indexOf("Delete") != -1 || this.actionAccess.indexOf("Update") != -1) {
      
      this.clientEntityColumnDefs.unshift({
        headerName: '',
        cellRenderer: "ClientEntityActions",
        cellRendererParams: {
          onClick: this.onEditClientEntity.bind(this),
          onDeleteclick: this.onDeleteClientEntity.bind(this),
          onViewClick: this.onViewClientEntity.bind(this),
          Actions: this.actionAccess
        },
        width: 80,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,

      });

   // }
    //   this.thisIsFirstColumn = this.clientEntityColumnDefs[0];
  }




  constructor(private router: Router, private _headerService: HeaderService, private renderer: Renderer2, private _clientmasterservice: ClientMasterService, private _formBuilder: FormBuilder, private cdr: ChangeDetectorRef,private location: Location,private route: ActivatedRoute) {

    this.frameworkComponents = {
      ClientEntityActions: ClientEntityTableActionsComponent,
    }

    this.auditframeworkComponents = {
      // agDateInput: AgdatetimepickerComponent,
      auditlist: AuditchnageslistComponent


    }

  }
  file: any;
  ClientEntityData: any;
  ngOnInit(): void {
    this.setDivHeight();
    this._headerService.getActions("Client Entity").then((value) => {
      
      this.actionAccess = value;
      if (this.actionAccess.indexOf("View") != -1) {
        this.divFlag = true;
        this.buildHeaders();
        this.GetClientEntityData();
        this.GetMastersData();
        this.setTableHeight();
        this.resetFIlters();
      }
      else
        this.router.navigate(['/unauthorizedaccess'])
    });
   


    this.form = this.ClientEntityForm();
    this.ExcelToexportForm = this.ExceltoExport();
    
  }
  setTableHeight() {
    this.style.marginTop = '4px';
    this.style.width = "100%";

    this.style.height = (document.documentElement.clientHeight - 221) + "px";


    this.auditstyle.height = (document.documentElement.clientHeight - 200) + "px";



  }
  uploadedfile: any;
  fileChange($event) {

    if ($event.length > 0)
      this.uploadedfile = $event[0].file.rawFile;
  }
  clearEntityFiles() {
    this.uploadedfile = null;
    this.uploadfileerror = false;
    this.ClientEntityUploader = new FileUploader({ url: '' });
  }

  UploadData() {


    this.toastflag = false;
    console.log(this.ClientEntityUploader);
    this.uploadshowprogress = true;
    this.loaderflag = true;
    let formData: FormData = new FormData();
    formData.append("file", this.uploadedfile);
    this._clientmasterservice.Upload(formData)
      .subscribe(
        res => {

          if (res["status"] == "success") {


            this.hideFileUploadModel();
            this.uploadshowprogress = false;
            this.toasttype = "success";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Client Entites has been uploaded successfully.";
            this.GetClientEntityData();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["status"] == "fail" || res["status"] == "invalidfile") {

            this.uploadfileerror = true;
            //   this.toasttype="error";
            this.uploadshowprogress = false;
            //  this.toastflag=true;
            this.loaderflag = false;
            //   this.toastmessage="Error occured please contact support team";           
          }
          else if (res["status"] == "invalid") {
            this.hideFileUploadModel();
            //  this.toasttype="error";
            this.loaderflag = false;
            this.Validationsmodalflag = true;
            this.uploadshowprogress = false;
            this.invalidarryKeys = Object.keys(res).slice(0, -1);
            this.validationdata = res;
            // this.toastmessage="Please Upload Valid Template";   
          }


        },
        err => {
          this.hideFileUploadModel();
          this.toasttype = "error";
          this.toastflag = true;
          this.loaderflag = false;
          this.uploadshowprogress = false;
          this.toastmessage = "Error occured please contact support team";
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      )

  }
  GetClientEntityData() {
    this.loaderflag = true;
    if(this.BankSelected=="allBanks")
    {
      this.isMyBanks=false;
    this._clientmasterservice.GetClientENtity(this.isMyBanks)
      .subscribe(
        res => {
          console.log(res)
          this.ClientEntityData = res;
          this.clientEntityRowData = this.ClientEntityData;
          this.originaldata=this.ClientEntityData;
          this.uploadshowprogress = false;
          this.loaderflag = false;
        },
        err => {

          console.log(err);
        }
      )

    }
    else{
      this.isMyBanks=true;
      this._clientmasterservice.GetClientENtity(this.isMyBanks).subscribe(
        res => {
          this.lstclientIds=res;
          console.log(this.lstclientIds);
          this.clientEntityRowData=this.lstclientIds;
        })


    }

  }
  GetMastersData() {


    this._clientmasterservice.GetMastersData()
      .subscribe(
        res => {
          console.log(res);

          this.locationMasterData = res[0];
          this.cantonMasterData = res[1];
          this.countryMasterData = res[2];
          this.reportingLanMasterData = res[3];
          this.documentLanMasterData = res[4];
          this.bankSizeMasterData = res[5];
          this.eyOffcieMasterData = res[6];
          this.eyRegionMasterData = res[7];
          this.irRBofficeMasterData = res[8];
          this.formStatusMasterData = res[9];


        },
        err => {

          console.log(err);
        }
      )

  }

  updatePaginationSize(newPageSize: number) {
    this.gridApi.api.paginationSetPageSize(newPageSize);
  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }
  ClientEntitySave(form: FormGroup) {
    this.toastflag = false;
;
    this.validateForm(form);
    if (!form.valid)
      return
    this.loaderflag = true;
    let contanid=this.cantonMasterData.filter(x=>x.cantonName==form.value.canton.trim())[0].cantonId;
    var obj = {

      ClientEntityId: 0,
      ClientEntityName: form.value.entityname,
      RbShortName: form.value.entityshortname,
      EyCidGfis: form.value.eycid,
      BankCodeRgrCid: form.value.bankcode.toString(),
      MercuryEntityId: form.value.clientid,
      Street: form.value.street,
      PostalCode: parseFloat(form.value.postalcode),
      Location: form.value.location,
      CantonId: contanid,
      CountryId: form.value.country,
      ReportingLanguageId: form.value.reportinglanguage,
      DocumentationLanguageId: form.value.documentationlanguage,
      BankSizeId: form.value.banksize,
      EyofficeId: form.value.eyoffice,
      EyregionId: form.value.eyregion,
      IrofficeId: form.value.irrboffice,
      Comments: form.value.Comment,
      Uidnumber: form.value.uidno,
      Finmaid: form.value.finmaid,
      ClientFiscalYearEnd: form.value.fiscalyearend.singleDate.formatted,
      Faoaregister: form.value.faoaregisterentitytype,
      Gismarkup: form.value.gismarkup,
      Gisid: parseFloat(form.value.gisid),
      CreatedBy: "Admin",
      FormStatusID: form.value.formstatus
    }
    this._clientmasterservice.SaveData(obj)
      .subscribe(
        res => {

          if (res["message"] == "success") {
            this.toasttype = "success";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Client Entity has been added successfully.";
            this.GetClientEntityData();
            this.clientEntityModal = false;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {
            this.toasttype = "error";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "clientEntityNameExists") {
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Client Entity name already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "clientEntityShortNameExists") {
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Client Entity short name already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
        },
        err => {
          
          if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
            //var key=Object.keys(err["error"]["errors"])[0];   
            //this.toasterService.message.next(err.toString());    
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else {
            this.toasttype = "error";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Error occured. Please try after sometime";
            console.log(err);
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }
      )


  }
  ClientEntityUpdate(form: FormGroup) {
    this.toastflag = false;
    this.validateForm(form);
    if (!form.valid)
      return
    this.loaderflag = true;
    let contanid=this.cantonMasterData.filter(x=>x.cantonName==form.value.canton.trim())[0].cantonId;
    var obj = {

      ClientEntityId: this.clientEntityId,
      ClientEntityName: form.value.entityname,
      RbShortName: form.value.entityshortname,
      EyCidGfis: form.value.eycid,
      BankCodeRgrCid: form.value.bankcode.toString(),
      MercuryEntityId: form.value.clientid,
      Street: form.value.street,
      PostalCode: parseFloat(form.value.postalcode),
      Location: form.value.location,
      CantonId: contanid,
      CountryId: form.value.country,
      ReportingLanguageId: form.value.reportinglanguage,
      DocumentationLanguageId: form.value.documentationlanguage,
      BankSizeId: form.value.banksize,
      EyofficeId: form.value.eyoffice,
      EyregionId: form.value.eyregion,
      IrofficeId: form.value.irrboffice,
      Comments: form.value.Comment,
      Uidnumber: form.value.uidno,
      Finmaid: form.value.finmaid,
      ClientFiscalYearEnd: form.value.fiscalyearend.singleDate.formatted,
      Faoaregister: form.value.faoaregisterentitytype,
      Gismarkup: form.value.gismarkup,
      Gisid: parseFloat(form.value.gisid),
      CreatedBy: "Admin",
      FormStatusID: form.value.formstatus,
    }
    this._clientmasterservice.UpdateData(obj)
      .subscribe(
        res => {

          //if(res){
          if (res["message"] == "success") {
            this.loaderflag = false;
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Client Entity has been updated successfully.";
            this.GetClientEntityData();
            this.clientEntityModal = false;
            this.clientEntityId = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {
            this.loaderflag = false;
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Client Entity has been updated successfully.";
            this.GetClientEntityData();
            this.clientEntityModal = false;
            this.clientEntityId = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "clientEntityNameExists") {
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Client Entity name already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "clientEntityShortNameExists") {
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Client Entity short name already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        },
        err => {
          if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
            //var key=Object.keys(err["error"]["errors"])[0];   
            //this.toasterService.message.next(err.toString());    
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else {
            this.toasttype = "error";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Error occured please contact support team";
            console.log(err);
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }
      )


  }

  private ClientEntityForm() {
    return this._formBuilder.group({
      entityname: [null, [Validators.required]],
      entityshortname: [null, [Validators.required]],
      bankcode: [null, [Validators.required]],
      eycid: [],
      clientid: [],
      street: [null, [Validators.required]],
      postalcode: [null, [Validators.required]],
      location: [null, [Validators.required]],
      canton: [null, [Validators.required]],
      country: [null, [Validators.required]],
      reportinglanguage: [null, [Validators.required]],
      documentationlanguage: [null, [Validators.required]],
      banksize: [null, [Validators.required]],
      //eyoffice: [null, [Validators.required]],
      eyoffice: [],
      eyregion: [null, [Validators.required]],
      irrboffice: [null, [Validators.required]],
      uidno: [null, [Validators.required]],
      finmaid: [null, [Validators.required]],
      faoaregisterentitytype: [null, [Validators.required]],
      gismarkup: [null, [Validators.required]],
      gisid: [null, [Validators.required]],
      Comment: [],
      bankcodenumeric: [null, [Validators.required]],
      fiscalyearend: [null, [Validators.required]],
      formstatus: [null, [Validators.required]]
    });
  }

  // document.addEventListener('focusin', evt => {
  //   if (!isDescendentOfModal(evt.target)) {
  //     moveFocusBackToModal()
  //   }
  // });

  onAddClientEntity() {
    this.viewcliententity = false;
    this.toastflag = false;
    this.form.reset();
    this.editflag="add";
    let date = this.formStatusMasterData.find(ob => ob.formStatusName === 'Active');
    this.form.patchValue({
      formstatus: date.formStatusId
    })
    this.clientEntityModal = true;
    this.btncliententity = true;
    this.viewcliententity=false;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.renderer.selectRootElement("#entName").focus();
    }, 500);
  }
  onDeleteClientEntity(e) {

    this.clientEntityId = e.rowData.clientEntityId;
    this.deletemodalflag = true;
  }
  onEditClientEntity(e) {

    var id=this._headerService.encryptUsingAES256(e.rowData.clientEntityId)
    this.location.go('/clientEntity?id=' + id);
    
    this.toastflag = false;
    this.clientEntityId = e.rowData.clientEntityId;
    this.editflag="edit";
    this.form.setValue({

      entityname: e.rowData.clientEntityName,
      entityshortname: e.rowData.rbShortName,
      bankcode: e.rowData.bankCodeRgrCid,
      eycid: e.rowData.eyCidGfis,
      clientid: e.rowData.mercuryEntityId,
      street: e.rowData.street,
      postalcode: e.rowData.postalCode,
      //location: e.rowData.locationId,
      location: e.rowData.location,//{ locationId: e.rowData.locationId, locationName: e.rowData.locationName },
      canton: e.rowData.cantonName,
      country: e.rowData.countryId,
      reportinglanguage: e.rowData.reportingLanguageId,
      documentationlanguage: e.rowData.documentationLanguageId,
      banksize: e.rowData.bankSizeId,
      eyoffice: e.rowData.eyofficeId,
      eyregion: e.rowData.eyregionId,
      irrboffice: e.rowData.irofficeId,
      uidno: e.rowData.uidnumber,
      finmaid: e.rowData.finmaid,
      faoaregisterentitytype: e.rowData.faoaregister,
      gismarkup: e.rowData.gismarkup,
      gisid: e.rowData.gisid,
      Comment: e.rowData.comments,
      formstatus: e.rowData.formstatusId,
      bankcodenumeric: Number(e.rowData.bankCodeRgrCid),
      fiscalyearend: e.rowData.clientFiscalYearEnd == null || e.rowData.clientFiscalYearEnd == 'null' ? '' :
        e.rowData.clientFiscalYearEnd.indexOf('.') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(2020),
              month: Number(e.rowData.clientFiscalYearEnd.split('.')[1]),
              day: Number(e.rowData.clientFiscalYearEnd.split('.')[0])
            },
            formatted: e.rowData.clientFiscalYearEnd
          }
        }

    })
    this.fiscalyearendData = new FormControl(e.rowData.clientFiscalYearEnd);
    this.clientEntityModal = true;
    this.btncliententity = false;
    this.viewcliententity=false;
  }
  viewclientEntity(e)
  {
    this.toastflag = false;
    this.clientEntityId = e.rowData.clientEntityId;
    this.form.setValue({

      entityname: e.rowData.clientEntityName,
      entityshortname: e.rowData.rbShortName,
      bankcode: e.rowData.bankCodeRgrCid,
      eycid: e.rowData.eyCidGfis,
      clientid: e.rowData.mercuryEntityId,
      street: e.rowData.street,
      postalcode: e.rowData.postalCode,
      //location: e.rowData.locationId,
      location:e.rowData.location, // { locationId: e.rowData.locationId, locationName: e.rowData.locationName },
      canton: e.rowData.cantonName,
      country: e.rowData.countryId,
      reportinglanguage: e.rowData.reportingLanguageId,
      documentationlanguage: e.rowData.documentationLanguageId,
      banksize: e.rowData.bankSizeId,
      eyoffice: e.rowData.eyofficeId,
      eyregion: e.rowData.eyregionId,
      irrboffice: e.rowData.irofficeId,
      uidno: e.rowData.uidnumber,
      finmaid: e.rowData.finmaid,
      faoaregisterentitytype: e.rowData.faoaregister,
      gismarkup: e.rowData.gismarkup,
      gisid: e.rowData.gisid,
      Comment: e.rowData.comments,
      formstatus: e.rowData.formstatusId,
      bankcodenumeric: Number(e.rowData.bankCodeRgrCid),
      fiscalyearend: e.rowData.clientFiscalYearEnd == null || e.rowData.clientFiscalYearEnd == 'null' ? '' :
        e.rowData.clientFiscalYearEnd.indexOf('.') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(2020),
              month: Number(e.rowData.clientFiscalYearEnd.split('.')[1]),
              day: Number(e.rowData.clientFiscalYearEnd.split('.')[0])
            },
            formatted: e.rowData.clientFiscalYearEnd
          }
        }

    })
    this.fiscalyearendData = new FormControl(e.rowData.clientFiscalYearEnd);
    this.clientEntityModal = true;
    this.btncliententity = false;

    this.viewcliententity = true;
    // if(this.actionAccess.indexOf("Update") != -1)
    // {
    //   this.viewcliententity = false;
    // }
    // else{
    //   this.viewcliententity = true;
    // }
  }
  onViewClientEntity(e) {
    var id=this._headerService.encryptUsingAES256(e.rowData.clientEntityId)
    this.location.go('/clientEntity?id=' + id);
    this.viewclientEntity(e);
    this.editflag="view";
  }

  ExcelToExport() {
    this.loaderflag = true;

    this._clientmasterservice.ExcelToExport()
      .subscribe(
        res => {

          this.loaderflag = false;
          let date = new Date().toString();
          saveAs(res, "ClientEntityData" + date + ".xlsx");

        },
        err => {

          console.log(err);
        }
      )

  }
  DeleteClientEntity() {
    this.loaderflag = true;
    this.toastflag = false;
    var obj = {
      ClientEntityId: this.clientEntityId
    }
    this._clientmasterservice.DeleteData(obj)
      .subscribe(
        res => {
          

          if (res) {

            this.toasttype = "success";
            this.toastflag = true;
            this.deletemodalflag = false;
            this.toastmessage = "Client Entity has been deleted successfully.";
            this.GetClientEntityData();
            this.clientEntityId = 0;
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else {
            this.toasttype = "error";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
        },
        err => {

          this.toasttype = "error";
          this.toastflag = true;
          this.loaderflag = false;
          this.toastmessage = "Error occured. Please try after sometime";
          console.log(err);
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
          //}
        }
      )
  }

  ClientMasterTempleateExport() {
    this.toastflag = false;
    this.loaderflag = true;

    this._clientmasterservice.ClientMasterTempleateExport()
      .subscribe(
        res => {

          this.loaderflag = false;
          saveAs(res, "ClientEntityTemplate.xlsx");

        },
        err => {

          console.log(err);
        }
      )
  }
  public ShowFileUploadModel() {

    this.showFileUploadModel = true;

  }
  public hideFileUploadModel() {
    this.showFileUploadModel = false;
    this.clearEntityFiles();
  }
  private ExceltoExport(): FormGroup {
    return this._formBuilder.group({
      chkBankCode: [],
      chkEntityName: [],
      chkEntityShortName: [],
      chkEYCIDGFIS: [],
      chkClientIDMercury: [],
      chkAddressStreet: [],
      chkAddressPostalCode: [],
      chkAddressLocation: [],
      chkAddressCanton: [],
      chkAddressCountry: [],
      chkReportinglanguage: [],
      chkDocumentationlanguage: [],
      chkBanksize: [],
      chkEYOffice: [],
      chkEYRegion: [],
      chkIRRBoffice: [],
      chkCommentEntityDataForm: [],
      chkUIDNo: [],
      chkFINMAID: [],
      chkFiscalYearEnd: [],
      chkFAOARegisterentitytype: [],
      chkGISMarkup: [],
      chkGISID: [],
      chkFormStatus: [],
      radioexceltype: []

    });
  }
  public clientEntityExcelexport(form: FormGroup) {

    this.toastflag = false;
    this.loaderflag = true;
    let selectedNodes = this.gridApi.api.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data.clientEntityId);
    let columnNames = [];
    columnNames.push(form.value.chkBankCode == true ? "Bank Code" : "");
    columnNames.push(form.value.chkEntityName == true ? "Entity Name" : "");
    columnNames.push(form.value.chkEntityShortName == true ? "Entity Short Name" : "");
    columnNames.push(form.value.chkEYCIDGFIS == true ? "EY CID (GFIS)" : "");
    columnNames.push(form.value.chkClientIDMercury == true ? "Client ID (Mercury)" : "");
    columnNames.push(form.value.chkAddressStreet == true ? "Address – Street" : "");
    columnNames.push(form.value.chkAddressPostalCode == true ? "Address – Postal Code" : "");
    columnNames.push(form.value.chkAddressLocation == true ? "Address – Location" : "");
    columnNames.push(form.value.chkAddressCanton == true ? "Address – Canton" : "");
    columnNames.push(form.value.chkAddressCountry == true ? "Address – Country" : "");
    columnNames.push(form.value.chkReportinglanguage == true ? "Reporting language" : "");
    columnNames.push(form.value.chkDocumentationlanguage == true ? "Documentation language" : "");
    columnNames.push(form.value.chkBanksize == true ? "Bank size" : "");
    columnNames.push(form.value.chkEYOffice == true ? "EY Office" : "");
    columnNames.push(form.value.chkEYRegion == true ? "EY Region" : "");
    columnNames.push(form.value.chkIRRBoffice == true ? "IR Office" : "");
    columnNames.push(form.value.chkCommentEntityDataForm == true ? "Comment - Entity Data Form" : "");
    columnNames.push(form.value.chkUIDNo == true ? "UID-No." : "");
    columnNames.push(form.value.chkFINMAID == true ? "FINMA ID" : "");
    columnNames.push(form.value.chkFiscalYearEnd == true ? "Fiscal Year End" : "");
    columnNames.push(form.value.chkFAOARegisterentitytype == true ? "FAOA Register – entity type" : "");
    columnNames.push(form.value.chkGISMarkup == true ? "GIS Mark-up" : "");
    columnNames.push(form.value.chkGISID == true ? "GIS ID" : "");
    columnNames.push(form.value.chkFormStatus == true ? "Form Status" : "");

    columnNames = columnNames.filter(function (el) {
      return el != "";
    });
    if (columnNames.length == 0) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.loaderflag = false;
      this.toastmessage = "Please select atleast one column";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    var bodydata={
      columnNames : columnNames,
      selectedData : selectedData
    }
    this._clientmasterservice.clientEntityExcelexport(bodydata)
      .subscribe(
        res => {
          
          this.ExcelExportmodalflag = false;
          this.loaderflag = false;
          let date = new Date().toString();
          saveAs(res, "ClientEntityData" + date + ".xlsx");

        },
        err => {

          console.log(err);
        }
      )
  }
  ExcelExportpopup() {
    this.toastflag = false;
    this.ExcelExportmodalflag = true;
    this.ExcelToexportForm.setValue({
      chkBankCode: true,
      chkEntityName: true,
      chkEntityShortName: true,
      chkEYCIDGFIS: true,
      chkClientIDMercury: true,
      chkAddressStreet: true,
      chkAddressPostalCode: true,
      chkAddressLocation: true,
      chkAddressCanton: true,
      chkAddressCountry: true,
      chkReportinglanguage: true,
      chkDocumentationlanguage: true,
      chkBanksize: true,
      chkEYOffice: true,
      chkEYRegion: true,
      chkIRRBoffice: true,
      chkCommentEntityDataForm: true,
      chkUIDNo: true,
      chkFINMAID: true,
      chkFiscalYearEnd: true,
      chkFAOARegisterentitytype: true,
      chkGISMarkup: true,
      chkGISID: true,
      chkFormStatus: true,
      radioexceltype: "all"
    })


  }

  ReportOptionChange() {

    this.toastflag = false;
    if (this.ExcelToexportForm.value.radioexceltype == "all") {
      this.ExcelToexportForm.setValue({
        chkBankCode: true,
        chkEntityName: true,
        chkEntityShortName: true,
        chkEYCIDGFIS: true,
        chkClientIDMercury: true,
        chkAddressStreet: true,
        chkAddressPostalCode: true,
        chkAddressLocation: true,
        chkAddressCanton: true,
        chkAddressCountry: true,
        chkReportinglanguage: true,
        chkDocumentationlanguage: true,
        chkBanksize: true,
        chkEYOffice: true,
        chkEYRegion: true,
        chkIRRBoffice: true,
        chkCommentEntityDataForm: true,
        chkUIDNo: true,
        chkFINMAID: true,
        chkFiscalYearEnd: true,
        chkFAOARegisterentitytype: true,
        chkGISMarkup: true,
        chkGISID: true,
        chkFormStatus: true,
        radioexceltype: "all"
      })
    }
    else if (this.ExcelToexportForm.value.radioexceltype == "GIS") {
      this.ExcelToexportForm.setValue({
        chkBankCode: true,
        chkEntityName: true,
        chkEntityShortName: false,
        chkEYCIDGFIS: false,
        chkClientIDMercury: false,
        chkAddressStreet: true,
        chkAddressPostalCode: true,
        chkAddressLocation: true,
        chkAddressCanton: true,
        chkAddressCountry: true,
        chkReportinglanguage: false,
        chkDocumentationlanguage: false,
        chkBanksize: false,
        chkEYOffice: false,
        chkEYRegion: false,
        chkIRRBoffice: false,
        chkCommentEntityDataForm: true,
        chkUIDNo: false,
        chkFINMAID: false,
        chkFiscalYearEnd: false,
        chkFAOARegisterentitytype: false,
        chkGISMarkup: true,
        chkGISID: true,
        chkFormStatus: true,
        radioexceltype: "GIS"
      })
    } else if (this.ExcelToexportForm.value.radioexceltype == "FINMA") {
      this.ExcelToexportForm.setValue({
        chkBankCode: true,
        chkEntityName: true,
        chkEntityShortName: true,
        chkEYCIDGFIS: false,
        chkClientIDMercury: false,
        chkAddressStreet: false,
        chkAddressPostalCode: false,
        chkAddressLocation: true,
        chkAddressCanton: false,
        chkAddressCountry: true,
        chkReportinglanguage: true,
        chkDocumentationlanguage: true,
        chkBanksize: true,
        chkEYOffice: true,
        chkEYRegion: true,
        chkIRRBoffice: false,
        chkCommentEntityDataForm: true,
        chkUIDNo: true,
        chkFINMAID: true,
        chkFiscalYearEnd: true,
        chkFAOARegisterentitytype: true,
        chkGISMarkup: true,
        chkGISID: true,
        chkFormStatus: true,
        radioexceltype: "FINMA"
      })
    } else if (this.ExcelToexportForm.value.radioexceltype == "FAOA") {
      this.ExcelToexportForm.setValue({
        chkBankCode: true,
        chkEntityName: true,
        chkEntityShortName: false,
        chkEYCIDGFIS: false,
        chkClientIDMercury: false,
        chkAddressStreet: true,
        chkAddressPostalCode: true,
        chkAddressLocation: true,
        chkAddressCanton: true,
        chkAddressCountry: true,
        chkReportinglanguage: false,
        chkDocumentationlanguage: false,
        chkBanksize: false,
        chkEYOffice: false,
        chkEYRegion: false,
        chkIRRBoffice: false,
        chkCommentEntityDataForm: true,
        chkUIDNo: true,
        chkFINMAID: false,
        chkFiscalYearEnd: true,
        chkFAOARegisterentitytype: true,
        chkGISMarkup: false,
        chkGISID: false,
        chkFormStatus: true,
        radioexceltype: "FAOA"
      })
    } else if (this.ExcelToexportForm.value.radioexceltype == "Custom") {
      this.ExcelToexportForm.setValue({
        chkBankCode: false,
        chkEntityName: false,
        chkEntityShortName: false,
        chkEYCIDGFIS: false,
        chkClientIDMercury: false,
        chkAddressStreet: false,
        chkAddressPostalCode: false,
        chkAddressLocation: false,
        chkAddressCanton: false,
        chkAddressCountry: false,
        chkReportinglanguage: false,
        chkDocumentationlanguage: false,
        chkBanksize: false,
        chkEYOffice: false,
        chkEYRegion: false,
        chkIRRBoffice: false,
        chkCommentEntityDataForm: false,
        chkUIDNo: false,
        chkFINMAID: false,
        chkFiscalYearEnd: false,
        chkFAOARegisterentitytype: false,
        chkGISMarkup: false,
        chkGISID: false,
        chkFormStatus: false,
        radioexceltype: "Custom"
      })
    }
  }


  handleauditGridReady(params) {
  
    this.auditgridApi = params;
    this.auditgridApi.api.sizeColumnsToFit()
  }
  getAuditTrail() {

    this.currentactivetab = "Audit";
    this.loaderflag = true;

    this._clientmasterservice.getAuditTrail()
      .subscribe(
        res => {

          this.AuditTrailBuildHeaders();
          this.AuditTrailDataset = res;
          this.clientEntityAuditDataSet = res;
          this.AuditTrailfilterDataset = res;
          this.loaderflag = false;
        },
        err => {

          console.log(err);
        }
      )

  }

  AuditTrailBuildHeaders() {
    ;

    this.clientEntityAuditColumnDefs = [
      {
        headerName: 'Audit Date', field: 'auditDateTime', sortable: true,
        filter: 'agDateColumnFilter',
        unSortIcon: true,
        cellClass: "ag-grid-cell-wrap-text",
        wrapText: true,
        autoHeight: true,
        width: 200,
        resizable: true,
        cellRenderer: (data) => {

          return moment(data.value).format('DD.MM.YYYY HH:mm')
        },
        filterParams: {
          buttons: ['reset'],

          comparator: function (filterLocalDate, cellValue) {

            
            var dateAsString = cellValue;

            if (dateAsString == null) {
              return 0;
            }


            var date = dateAsString.split('T')[0];
            var dateParts = date.split('-');

            var day = Number(dateParts[2]);
            var month = parseInt(dateParts[1]) - 1;
            var year = Number(dateParts[0]);
            var cellDate = moment(year + "/" + month + "/" + day, "YYYY/mm/DD");

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDate) {
              return -1;
            } else if (cellDate > filterLocalDate) {
              return 1;
            }
            return 0;





            //filterLocalDate.setMilliseconds(0);
            //cellValue = new Date(cellValue);
            //cellValue.setMilliseconds(0);
            //cellValue.setSeconds(0);
            // let filterBy = filterLocalDate.getTime();
            //let filterMe = cellValue.getTime();

            //if (filterBy == filterMe) {

            //   return 0;
            // }
            // if (filterMe < filterBy) {
            //   return -1;
            // }

            // if (filterMe > filterBy) {
            //   return 1;
            // }


          }
        },

      },
      {
        headerName: 'Action', field: 'auditType', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellClassRules: {
          'ag-grid-auditlog-status-add': function (params) {

            if (params.data) {
              return params.data.auditType == 'Add';
            }
          },
          'ag-grid-auditlog-status-Update': function (params) {
            if (params.data) {
              return params.data.auditType == 'Update';
            }
          },
          'ag-grid-auditlog-status-Delete': function (params) {
            if (params.data) {
              return params.data.auditType == 'Delete';
            }
          },
          'ag-grid-auditlog-status-BulkUpload': function (params) {
            if (params.data) {
              return params.data.auditType == 'Bulk Insert';
            }
          }
        }
      },
      {
        headerName: 'Client Entity Name', field: 'clientEntityName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true
      },
      
      {
        headerName: 'Old Values', field: 'oldValues', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellRenderer: "auditlist",
        autoHeight: true,
        filterParams: {
          valueGetter: params => {
            return JSON.stringify(params.data.oldValues)
          },
          textCustomComparator: this.agcustomauditlogfilter

        }
      },
      {
        headerName: 'New Values', field: 'newValues', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
        cellRenderer: "auditlist",
        autoHeight: true,
        filterParams: {
          valueGetter: params => {

            return JSON.stringify(params.data.newValues)
          },
          textCustomComparator: this.agcustomauditlogfilter

        }
      },


      {
        headerName: 'Updated By', field: 'userName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true
      },
      {
        headerName: 'Rows Updated', field: 'rowCount', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true
      }

    ];


  }
  audittrailfilter() {



    let filteredset = null;

    let audittypes = [];

    if (this.adtinsertshow) {
      audittypes.push("Insert")
    }

    if (this.adtdeleteshow) {
      audittypes.push("Delete")
    }

    if (this.adtbulkinsertshow) {
      audittypes.push("Bulk Upload")
    }
    if (this.adtupdateshow) {
      audittypes.push("Update")
    }



    filteredset = this.AuditTrailfilterDataset.filter(function (i, n) {

      return audittypes.indexOf(i.auditType) + 1 > 0;
    });


    this.AuditTrailDataset = filteredset;



  }
  agcustomfisicaldatefilter(filter, value, filterText) {



    let filterTextLowerCase = filterText.toLowerCase();


    let valueLowerCase = value;
    //  let valueLowerCase = JSON.parse(value).toString().toLowerCase();

    switch (filter) {
      case 'contains':
        return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
      case 'notContains':
        return valueLowerCase.indexOf(filterTextLowerCase) === -1;
      case 'equals':
        return valueLowerCase === filterTextLowerCase;
      case 'notEqual':
        return valueLowerCase != filterTextLowerCase;
      case 'startsWith':
        return valueLowerCase.indexOf(filterTextLowerCase) === 0;
      case 'endsWith':
        var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
        return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
      default:
        // should never happen
        console.warn('invalid filter type ' + filter);
        return false;
    }
  }
  agcustomauditlogfilter(filter, value, filterText) {

    let filterTextLowerCase = filterText.toLowerCase();


    if (value != null && value != undefined && value != "null") {
      var obj = JSON.parse(value);
      let values = Object.keys(obj).map((key) => {



        if (obj[key] != null) {
          return (key + " : " + obj[key]).toLowerCase();
        }


      });

      let valueLowerCase = values.join(" ");

      //  let valueLowerCase = JSON.parse(value).toString().toLowerCase();

      switch (filter) {
        case 'contains':
          return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
        case 'notContains':
          return valueLowerCase.indexOf(filterTextLowerCase) === -1;
        case 'equals':
          return valueLowerCase === filterTextLowerCase;
        case 'notEqual':
          return valueLowerCase != filterTextLowerCase;
        case 'startsWith':
          return valueLowerCase.indexOf(filterTextLowerCase) === 0;
        case 'endsWith':
          var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
          return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
        default:
          // should never happen
          console.warn('invalid filter type ' + filter);
          return false;
      }
    } else return false
  }


  ////#region Action check method
  ActionsCheck(action) {
    if (this.actionAccess.indexOf(action) != -1)
      return true;
    else
      return false;
  }
  setDivHeight() {

    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }
  ////#endregion

  GenerateBankCode() {
    
    if (this.form.value.bankcodenumeric == null || this.form.value.bankcodenumeric == undefined || this.form.value.bankcodenumeric == "") {
      this.form.patchValue({
        bankcode: ''
      })
    }
    else if (this.form.value.bankcodenumeric.toString().length == 1) {
      this.form.patchValue({
        bankcode: '000' + this.form.value.bankcodenumeric
      })
    }
    else if (this.form.value.bankcodenumeric.toString().length == 2) {
      this.form.patchValue({
        bankcode: '00' + this.form.value.bankcodenumeric
      })
    }
    else if (this.form.value.bankcodenumeric.toString().length == 3) {
      this.form.patchValue({
        bankcode: '0' + this.form.value.bankcodenumeric
      })
    }
    else if (this.form.value.bankcodenumeric.toString().length == 4) {
      this.form.patchValue({
        bankcode: this.form.value.bankcodenumeric
      })
    }
  }
  onFilterTextBoxChanged() {
    
    this.gridApi.api.setQuickFilter(this.ngSearchFilter);
  }
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  //#region Filters code

  externalIROfficeFilterChanged(newValue) {
    
   filterIRoffice  = newValue;  
      this.gridApi.api.onFilterChanged();
  }
  externalRegionFilterChanged(newValue){
    
    filterRegion  = newValue;  
    this.gridApi.api.onFilterChanged();
    
  }
  doesExternalFilterPass(node) {
    
    if (filterIRoffice != "" && filterRegion != "") {
      if (node.data.irofficeName == filterIRoffice && node.data.eyregionName == filterRegion)
        return true;
    }
    else if (filterRegion != "") {
      if (node.data.eyregionName == filterRegion)
        return true;
    }
    else if (filterIRoffice != "") {
      if (node.data.irofficeName == filterIRoffice)
        return true;
    }
    else
      return false;
  }
  resetFIlters(){
    
    //this.lstSearchFilterFunction;
   // this.GetSearchFilterMastersData();
    this.NgSearchFlag=false;  
    filterIRoffice="",  filterRegion  = "";
    this.irofficeSelected=null;
    this.RegionSelected=null;
    this.BankSelected="allBanks";
    this.isMyBanks=false;
    this.clientEntityRowData=this.originaldata;
    setTimeout(() => {
      this.NgSearchFlag=true;  
    }, 50);  
   this.gridApi.api.onFilterChanged();
  }
  isExternalFilterPresent() {  
    return filterIRoffice != "" ||filterRegion != "";
  }
  
  //#endregion
lstclientIds:any;
originaldata:any;

EscapeYes(){
  this.clientEntityModal = false;
  this.Escmodalflag = false;
  this.editflag="";
}

@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  debugger;
  if(this.editflag == "edit"){
    this.Escmodalflag=true;
  }
  else{
    this.clientEntityModal = false;
  }
}  
  
}
var filterIRoffice="",filterRegion="";