import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-client-entity-table-actions',
  template: `
 <div class="motif-row">
 <div class="" *ngIf="ActionsCheck('Update(Basic)')" [ngClass]="{'masterDeleteIcon': params.data.hasDelete}">
  <motif-icon [ngClass]="{'iconDisable': params.data.hasDelete}" [src]="'./assets/icons/editor/ic_mode_edit_24px.svg'" (click)="onEditClick($event)"></motif-icon>
  </div>
  <div class="" *ngIf="ActionsCheck('Delete')" [ngClass]="{'masterDeleteIcon': params.data.hasDelete}">
  <motif-icon [ngClass]="{'iconDisable': params.data.hasDelete}" [src]="'./assets/icons/action/ic_delete_24px.svg'" (click)="onDeleteClick($event)"></motif-icon>
  </div>
  <div class="" *ngIf="ActionsCheck('View')">
  <motif-icon  [src]="'./assets/icons/action/ic_visibility_24px.svg'" (click)="onViewClick($event)"></motif-icon>
  </div>
  <div class="" *ngIf="ActionsCheck('AGM Download')">
  <motif-icon  [src]="'./assets/icons/editor/ic_insert_invitation_24px.svg'" (click)="onDownloadClick($event)"></motif-icon>
  </div>
</div>
  
  `
 
})
export class AuditAssignemntTableActionsComponent implements ICellRendererAngularComp {
  public params: any;
  public Actions: any;

  agInit(params: any): void {

    
        
    this.params = params;
    this.Actions=this.params.Actions;
}
ActionsCheck(action){    
  
  if(this.Actions.indexOf(action)!=-1)
  return true;
  else
  return false;
}
refresh(): boolean {
  return false;
}
onEditClick($event) {
    // put anything into params u want pass into parents component
       const params = {
         event: $event,
         rowData: this.params.node.data
         // ...something
       }
       this.params.onEditClick(params);
   }
     onDeleteClick($event) {
      
         // put anything into params u want pass into parents component
         const params = {
           event: $event,
           rowData: this.params.node.data
           // ...something
         }
         this.params.onDeleteClick(params);
   }
   onViewClick($event) {
    
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onViewClick(params);

  }
  onDownloadClick($event) {
    
    // put anything into params u want pass into parents component
    const params = {
      event: $event,
      rowData: this.params.node.data
      // ...something
    }
    this.params.onDownloadAGMICSFile(params);

}
}
