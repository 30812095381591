"use strict";

import { retry } from "rxjs/operators";

export let loadApp = false;
export const env = getEnv();
export const baseApiUrl = getBaseApiUrl();
export const signalRBaseAPIUrl =
  "https://euwuint0hpapa01.azurewebsites.net/api";
export const apiClientId = "099153c2-6251-49bc-8ecb-3e85e03f0011";
export let maxfileSize = 50000000;

export const adalEYConfiguration = {
  tenant: "eygs.onmicrosoft.com",
  clientId: "3a27616a-339d-4c6a-be17-e726cd9480e6",
  instance: "https://login.microsoftonline.com/",
  postLogoutRedirectUri: "https://uat-eych.raiffeisen.amt.ey.com",
  extraQueryParameter: "nux=1",
  redirectUri: "https://uat-eych.raiffeisen.amt.ey.com",
  cacheLocation: "sessionStorage",
  navigateToLoginRequestUrl: false,
};

function getEnv() {
  switch (window.location.host) {
    case "http://localhost:4200/":
      return "dev";
    case "https://uat-eych.raiffeisen.amt.ey.com":
      return "uat";
    default:
      return "uat";
  }
}

function getBaseApiUrl() {
  switch (env) {
    case "dev":
      return "http://localhost:51371/api";   
    case "uat":
      return "https://uat-eych.raiffeisen.amt.ey.com";    
    default:
      return "";
  }
  
}
