<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
    <div class="playbook-main">
        <form [formGroup]="form">
            <section class="motif-modal-content">
                <div class="row">
                    <div class="motif-col-xs-4 motif-col-lg-4" style="padding:0px">
                        <div class="motif-modal-header">
                            <h3 class="motif-h3"> Notification Master</h3>
                        </div>
                    </div>
                </div>
                <div class="csdivformmastercreation" *ngIf="ActionsCheck('Add / Update')">
                    <div class="csdivcenterformmastercreation ">
                        <motif-form-field>
                            <label motifLabel>Contact Type</label>
                            <motif-select formControlName="contacttype" class="ClienentitytModalselect"
                                aria-label="Contatct Type Form Field" [disabled]="isSelected">
                                <motif-option *ngFor="let item of contactTypeName" [value]="item.contactTypeId">
                                    {{item.contactTypeName}}</motif-option>
                            </motif-select>
                            <motif-error *ngIf="
                                            form.get('contacttype').hasError('required') &&
                                            form.get('contacttype').touched
                                            ">Please select a contact type!</motif-error>
                        </motif-form-field>

                        <motif-form-field>
                            <quill-editor formControlName="name"></quill-editor>
                            <label motifLabel class="notificationLabel">Notification</label>
                        </motif-form-field>

                        <motif-error *ngIf="
                    form.get('name').hasError('required') &&
                    form.get('name').touched
                    ">Please enter notification</motif-error>
                    </div>

                    <div class="csdivcenterformmastercreation">
                        <div class="motif-row">
                            <div class="button-container motif-col-XS-1" style="width: 120px;">
                                <motif-loader-button *ngIf="ActionsCheck('Add / Update')" type="submit"
                                    [determinatedProgressBar]="!showLoader" showProgressbar="showLoader"
                                    buttonColor="yellow" (click)="submit()" value="{{btnsubmittext}}"
                                    progressValue="showLoader ? 0 :5">
                                </motif-loader-button>

                            </div>
                            <div class="button-container motif-col-XS-1">
                                <button (click)="reset()" class="csresetbutton" motifButton color="warn" size="large">Reset</button>
                            </div>
                            <div class="button-container motif-col-XS-1">
                                <button (click)="ExcelExport()" color="primary" size="large" motifButton><motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
    <div style="width: 100%;">
        <div class="playbook-main">
            <div class="motif-table">
                <div id="dvag-grid" class="motif-table-wrapper"  [ngClass]="{          'motif-table-first-column-border': true}">
                    <ag-grid-angular [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate"
                        class="ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="tableDataset"
                        [modules]="modules" (gridReady)="handleGridReady($event)" [animateRows]="true"
                        [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents">
                    </ag-grid-angular>
                </div>
            </div>

        </div>
    </div>
    <motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
        <motif-modal-header> Delete Notification </motif-modal-header>
        <motif-modal-body>
            <p> Are you sure, you want to delete Notification? </p>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="Delete()">Confirm</button>
            <button motifButton color="warn" (click)="deletemodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>


    <motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
        {{toastmessage}}
    </motif-toast>
</div>