import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { environment } from 'src/app/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public Notificationrecieved = new EventEmitter<any>();
  constructor(private _AuthenticationService: AuthenticationService) { }
  private hubConnection: signalR.HubConnection

  public startConnection = () => {    
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.signalRUrl}/notify`)
      .build();
    this.hubConnection
      .start()
      .then(() => {        
        var userID = this._AuthenticationService.getUserID();
        setTimeout(() => {
          this.logConnection(userID);  
        }, 3000);        
        this.GetNotifications(userID);
        this.onMessageReceived();
      }
      )
      .catch(err => console.log('Error while starting connection: ' + err))
  }
  public logConnection(UserID) {
    console.log("hello", UserID)
    this.hubConnection.invoke('logConnection', UserID)
      .catch(err => console.error(err));

  }


  public logOutListner = () => {
    this.hubConnection.on('logOut', (data) => {
      this._AuthenticationService.logout();
    });

  }

  public UpdateLogOutDateTime = () => {
    this.hubConnection.invoke('updateLogOutDateTime')
      .catch(err => console.error(err));
  }

  public UpdateLogOutDateTimeListner = () => {
    this.hubConnection.on('updateLogOutDateTime', (data) => {      
      console.log(data);
    })

  }
  public GetNotifications = (UserID) => {
    this.hubConnection.invoke('GetAllNotification', UserID)
      .catch(err => console.error(err));

  }
  public onMessageReceived = () => {
    this.hubConnection.on('broadcaastNotif', (data) => {      
      this.Notificationrecieved.emit({
        message: data
      });
    });

  }
}
