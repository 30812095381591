
          <div class="motif-row motif-end-lg">

            <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Timeline'}"
                    (click)="auditTrailActiveTab ='Timeline'" motifFab aria-label="floating-action-button" size="small">
              <motif-icon [src]="'./assets/icons/editor/ic_format_align_left_24px.svg'"></motif-icon>
            </button>

            &nbsp;

            <button [ngClass]="{'motif-fab-active': auditTrailActiveTab === 'Grid'}"
                    (click)="auditTrailActiveTab ='Grid'" motifFab aria-label="floating-action-button" size="small">
              <motif-icon [src]="'./assets/icons/image/ic_grid_on_24px.svg'"></motif-icon>
            </button>
          </div>

        
 <div *ngIf="auditTrailActiveTab =='Timeline'" class="tab2">
          
            <div class="timeLineMain">
                <div class="toggleGroup">
                  <ul class="list-inline">
                    <li style="cursor:pointer" [ngClass]="{'active': adtinsertshow}" (click)="adtinsertshow=!adtinsertshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Add</label>
                    </li>
                    <li style="cursor:pointer" [ngClass]="{'active': adtupdateshow}" (click)="adtupdateshow=!adtupdateshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Update</label>
                    </li>
                    <li style="cursor:pointer" [ngClass]="{'active': adtdeleteshow}" (click)="adtdeleteshow=!adtdeleteshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Delete</label>
                    </li>
                    <li style="cursor:pointer" [ngClass]="{'active': adtbulkinsertshow}" (click)="adtbulkinsertshow=!adtbulkinsertshow;audittrailfilter()">
                      <span></span>
                      <label style="cursor:pointer">Bulk Upload</label>
                    </li>
                  </ul>
                </div>
                <div class="timeLineItem">
                  <ul style="min-height:800px" class="list-inline">
                    <li *ngFor="let ad of AuditTrailDataset">
                      <div class="dateTime">
                        <!--September 14, 2020-->
                        <span class="tlDate">{{ ad.auditDateTime | momentPipe :'LL'}}    </span>
                        <span class="tlTime"> {{ ad.auditDateTime | momentPipe:'LT'}} </span>
                      </div>
                      <div class="timelineMainItem"
                           [ngClass]="
                           {
                           'insertItem': ad.auditType == 'Insert',
                           'updateItem': ad.auditType == 'Update',
                           'deleteItem': ad.auditType  == 'Delete',
                           'bulkInsertItem':ad.auditType  == 'Bulk Upload'
  
                           }">
                        <span class="BoldHead1">{{ad.userName}}<a> ({{ad.email}})</a></span>
                        <div class="content">
                          <p *ngIf="ad.auditType  == 'Bulk Upload'"><b>{{ad.rowCount}}</b> Pre-approval forms has been uploaded.</p>
                          <p *ngIf="ad.auditType  == 'Insert'">Pre-approval forms <span class="BoldHead1">{{ad.formTitle}}</span>  has been inserted.</p>
                          <p *ngIf="ad.auditType  == 'Delete'">Pre-approval forms <span class="BoldHead1">{{ad.newValues?.AdditionalComments}}</span> has been deleted.</p>
                          <p *ngIf="ad.auditType  == 'Update'">Pre-approval forms <span class="BoldHead1">{{ad.formTitle}}</span> has been updated.</p>
  
                          <ul *ngIf="ad.auditType  == 'Update'">
                            <li *ngIf="ad.newValues?.formtitle !=undefined"> Form Title : {{ad.newValues.formtitle}}  </li>
                            <li *ngIf="ad.newValues?.Department !=undefined"> Department : {{ad.newValues.Department}}  </li>
                            <li *ngIf="ad.newValues?.EntityTypeId !=undefined"> Entity Type : {{ad.newValues.EntityTypeId}}  </li>
                            <li *ngIf="ad.newValues?.DateServiceRequest !=undefined"> DateServiceRequest : {{ad.newValues?.DateServiceRequest| momentPipe :'LL'}}  </li>
                            <li *ngIf="ad.newValues?.EyPartnerId !=undefined"> Ey Partner : {{ad.newValues.EyPartnerId}}  </li>
                            <li *ngIf="ad.newValues?.EyManagerId !=undefined"> Ey Manager : {{ad.newValues.EyManagerId}}  </li>
                            <li *ngIf="ad.newValues?.ClientContactName !=undefined"> Client Contact Name : {{ad.newValues.ClientContactName}}  </li>
                            <li *ngIf="ad.newValues?.ClientContactEmail !=undefined"> Client Contact Email : {{ad.newValues?.ClientContactEmail}}  </li>                            
                            <li *ngIf="ad.newValues?.ClientContactPhone !=undefined"> Client Contact Phone : {{ad.newValues.ClientContactPhone}}  </li>
                            <li *ngIf="ad.newValues?.ServiceTitle !=undefined"> Service Title : {{ad.newValues.ServiceTitle}}  </li>
                            <li *ngIf="ad.newValues?.NatureOfServices !=undefined"> Nature Of Services : {{ad.newValues.NatureOfServices}}  </li>
                            <li *ngIf="ad.newValues?.FundRelated !=undefined"> Fund Related : {{ad.newValues.FundRelated}}  </li>
                            <li *ngIf="ad.newValues?.RecurringService !=undefined"> Recurring Service : {{ad.newValues.RecurringService}}  </li>
                            <li *ngIf="ad.newValues?.ServiceDescription !=undefined"> Service Description : {{ad.newValues.ServiceDescription}}  </li>
                            <li *ngIf="ad.newValues?.ServiceStartDate !=undefined"> Service StartDate : {{ad.newValues?.ServiceStartDate| momentPipe :'LL'}}  </li>
                            <li *ngIf="ad.newValues?.ServiceEndDate !=undefined"> Service EndDate : {{ad.newValues?.ServiceEndDate| momentPipe :'LL'}}  </li>
                            <li *ngIf="ad.newValues?.ReportingRequirements !=undefined"> Reporting Requirements : {{ad.newValues.ReportingRequirements}}  </li>
                            <li *ngIf="ad.newValues?.AdditionalComments !=undefined"> Additional Comments : {{ad.newValues.AdditionalComments}}  </li>
                            <li *ngIf="ad.newValues?.TotalHours !=undefined"> Total Hours : {{ad.newValues.TotalHours}}  </li>
                            <li *ngIf="ad.newValues?.TotalFee !=undefined"> Total Fee : {{ad.newValues.TotalFee}}  </li>
                            <li *ngIf="ad.newValues?.FeeConfirmed !=undefined"> Fee Confirmed : {{ad.newValues.FeeConfirmed}}  </li>
                            <li *ngIf="ad.newValues?.FeeComments !=undefined"> Fee Comments : {{ad.newValues.FeeComments}}  </li>
                            <li *ngIf="ad.newValues?.ServiceIndependenceChecked !=undefined"> Service Independence Checked : {{ad.newValues.ServiceIndependenceChecked}}  </li>
                            <li *ngIf="ad.newValues?.ServiceStatusId !=undefined"> Service Status : {{ad.newValues.ServiceStatusId}}  </li>
                            <li *ngIf="ad.newValues?.ApprovalStatusId !=undefined"> Approval Status : {{ad.newValues.ApprovalStatusId}}  </li>
                            <li *ngIf="ad.newValues?.ApprovalNumber !=undefined"> Approval Number : {{ad.newValues.ApprovalNumber}}  </li>
                            <li *ngIf="ad.newValues?.ApprovalRequestDate !=undefined"> Approval Request Date : {{ad.newValues.ApprovalRequestDate| momentPipe :'LL'}}  </li>
                            <li *ngIf="ad.newValues?.ApprovalResponseDate !=undefined"> Approval Response Date : {{ad.newValues.ApprovalResponseDate| momentPipe :'LL'}}  </li>                            
                            <li *ngIf="ad.newValues?.ApprovalLevelId !=undefined"> Approval Level : {{ad.newValues.ApprovalLevelId}}  </li>                    
                            <li *ngIf="ad.newValues?.BillingCode !=undefined"> Billing Code : {{ad.newValues.BillingCode}}  </li>
                            <li *ngIf="ad.newValues?.BillingComments !=undefined"> Billing Comments : {{ ad.newValues?.BillingComments}}  </li>
                            <li *ngIf="ad.newValues?.ClientEntityId !=undefined"> Client Entity : {{ad.newValues.ClientEntityId}}  </li>                          
                          </ul>
                        </div>
                      </div>
                    </li>
                    
            </ul>
        </div>
    </div>
            
</div>
<div *ngIf="auditTrailActiveTab=='Grid'" class="tab2">

    <ag-grid-angular [frameworkComponents]="auditframeworkComponents" (gridReady)="handleauditGridReady($event)" [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-material motif-ag-grid"
    [columnDefs]="DirectoryAuditColumnDefs" [rowData]="DirectoryAuditDataSet" [modules]="modules"
    [animateRows]="true" [pagination]="true" [paginationPageSize]=30></ag-grid-angular>

</div>

<motif-loader [show]="loaderflag" [fullScreen]="true"></motif-loader>