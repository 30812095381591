import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { saveAs } from 'file-saver';
import { DirectoryMasterService } from './directory-master.service';
import { HeaderService } from 'src/app/core/header/header.service';
import { Router } from '@angular/router';
import { DirectoryTableActionsComponent } from '../tableactions/directory-edit-delete-actions.component';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-directory-master',
  templateUrl: './directory-master.component.html',
  styleUrls: ['./directory-master.component.scss']
})

export class DirectoryMasterComponent implements OnInit {
  ////#region actions variables
  public divFlag: boolean = false;

  ////#endregion
  @HostListener('focusout', ['$event']) public onListenerTriggered(event: any): void {

    if (event.target.id == "btnCancelEmployee") {
      this.setFocusToInput();
    }
  }

  setFocusToInput() {
    // setTimeout(() => { // this will make the execution after the above boolean has changed
    //   this.renderer.selectRootElement("#empFirstName").focus();
    // }, 500);
  }

  ////#region Upload variables
  public showFileUploadModel: boolean = false;
  DirectoryUploader: FileUploader = new FileUploader({ url: '' });
  public uploadfileerror: boolean = false;
  public uploadshowprogress: boolean = false;
  public uploadedfile: any;
  public Validationsmodalflag: boolean = false;
  public invalidarryKeys: any;
  public validationdata: any;
  public ngfunctionids: any;
  public ngrankids: any;
  ////#endregion
  public Escmodalflag:boolean=false;


  ////#region toaster and Loader variables
  public toastflag: boolean = false;
  public actionAccess: any = [""];
  public toasttype: string;
  public toastmessage: string;
  ////#endregion

  ////#region MainTab variables
  public ngMobileno: any;
  public ngPhoneno: any;
  public ngSearchFilter: any;
  public lstSearchFilterContactType: any;
  public lstSearchFilterFunction: any;
  public lstSearchFilterFunctioncopy: any;
  public ngSearchContactType: any;
  public ngSearchFunction: any;
  public currentactivetab: string = 'Directory';
  ////#endregion


  //#region ag-grid_variables
  modules = AllCommunityModules;
  public rowSelection: any = 'multiple';

  gridApi: any;
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };
  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };
  public btnsubmittext: string = "ADD";
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public direcotryColumnDefs: any = [];
  public frameworkComponents: any;
  public directoryTableDataset: any;
  public deletemodalflag: boolean = false;
  public editflag:any;


  //#endregion ag-grid_variables

  ////#region directory Form Variables
  form: FormGroup;
  entityname = new FormControl();
  public directortAddEditModal: boolean;
  public locationMasterData: any;
  public rankMasterData: any;
  public functionMasterData: any;
  public functionMasterDatafilteredlist:any
  public directoryId: number;
  public btnAddFlag: boolean = true;
  public nameCombined: string = "";
  public roleMasterData: any;
  public cummunicationLanguageMasterData: any;
  public contactTypeMasterData: any;
  public EYOfficeMasterData: any;
  public IROfficeMasterData: any;
  public EntityFieldFlag: boolean = false;
  public EYOfficeEntityFieldFlag: boolean = false;
  public IROfficeFieldFlag: boolean = false;
  public contactTypeName: any;
  public viewdirectoryentity: boolean = true;
  public filterdata: any;
  ////#endregion

  //#region Exce export modal
  public ExcelExportmodalflag: boolean = false;
  ExcelToexportForm: FormGroup;
  ////#endregion
  MassUpdateForm: FormGroup;
  ////#region Entity based details variables
  public lstEntitydetails: any;
  public arrayCLientEntity: any = [];
  public ngClientEntityID: any;
  ////#endregion
  constructor(private router: Router, private _headerService: HeaderService, private _directoryMasterService: DirectoryMasterService, private _formBuilder: FormBuilder, private renderer: Renderer2, private location: Location, private route: ActivatedRoute) { }


  ngOnInit(): void {
    this.setDivHeight();
    this._headerService.getActions("Directory").then((value) => {

      this.actionAccess = value;
      if (this.actionAccess.indexOf("View") != -1) {
        this.divFlag = true;
        this.GetSearchFilterMastersData();
        this.GetEntityDetails();
        this.setFrameworkoptions();
        this.setTableHeight();
        this.buildHeaders();
        this.GetMastersData();
        this.resetFIlters();
      }
      else
        this.router.navigate(['/unauthorizedaccess'])
    });
    this.form = this.DirectoryForm();
    this.ExcelToexportForm = this.ExceltoExport();
    this.MassUpdateForm = this.MassUpdateFormDirectory();



  }
  ////#region Action check method
  ActionsCheck(action) {
    if (this.actionAccess.indexOf(action) != -1)
      return true;
    else
      return false;
  }
  ////#endregion
  ////#region  Upload Code
  fileChange($event) {
    if ($event.length > 0)
      this.uploadedfile = $event[0].file.rawFile;
  }

  clearDirectoryFiles() {
    this.uploadedfile = null;
    this.uploadfileerror = false;
    this.DirectoryUploader = new FileUploader({ url: '' });
  }

  public ShowFileUploadModel() {
    this.showFileUploadModel = true;
  }


  UploadData() {
    this.toastflag = false;
    this.uploadshowprogress = true;

    let formData: FormData = new FormData();
    formData.append("file", this.uploadedfile);
    this._directoryMasterService.Upload(formData)
      .subscribe(
        res => {


          if (res["status"] == "success") {


            this.hideFileUploadModel();
            this.uploadshowprogress = false;
            this.toasttype = "success";
            this.toastflag = true;

            this.toastmessage = "Contacts has been uploaded successfully.";
            this.DirectorybindGrid();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["status"] == "fail" || res["status"] == "invalidfile") {

            this.uploadfileerror = true;
            //   this.toasttype="error";
            this.uploadshowprogress = false;
            //  this.toastflag=true;

            //   this.toastmessage="Error occured please contact support team";           
          }
          else if (res["status"] == "invalid") {
            this.hideFileUploadModel();
            //  this.toasttype="error";

            this.Validationsmodalflag = true;
            this.uploadshowprogress = false;
            this.invalidarryKeys = Object.keys(res).slice(0, -1);
            this.validationdata = res;
            // this.toastmessage="Please Upload Valid Template";   
          }


        },
        err => {

          if (err == "Internal Server Error") {

            this.uploadfileerror = true;
            //   this.toasttype="error";
            this.uploadshowprogress = false;
            //  this.toastflag=true;

          } else {
            this.hideFileUploadModel();
            this.toasttype = "error";
            this.toastflag = true;

            this.uploadshowprogress = false;
            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }
      )

  }


  public hideFileUploadModel() {
    this.showFileUploadModel = false;
    this.clearDirectoryFiles();
  }
  clearEntityFiles() {
    this.uploadedfile = null;
    this.uploadfileerror = false;
    this.DirectoryUploader = new FileUploader({ url: '' });
  }

  ////#endregion

  ////#region ad-grid methods
  handleGridReady(params) {
    this.gridApi = params;
    this.gridApi.api.showLoadingOverlay()
    this.gridApi.api.hideOverlay()
    //this.gridApi.api.sizeColumnsToFit()
    this.DirectorybindGrid();


    const Id: string = this.route.snapshot.queryParamMap.get('id');
    if (Id != null) {
      var id = this._headerService.decryptUsingAES256(Id);
      this._directoryMasterService.GetDirectoryDetils()
        .subscribe(
          res => {
            this.directoryTableDataset = res;
            var filtereddata = this.directoryTableDataset.filter(data => data.directoryId == Number(id));
            this.filterdata = {
              rowData: filtereddata[0]
            }
            this.viewDirectoryClick(this.filterdata);
          })
    }

  };

  setTableHeight() {
    this.style.marginTop = '4px';
    this.style.width = "100%";
    this.style.height = (document.documentElement.clientHeight - 221) + "px";
  }
  setDivHeight() {

    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }

  private buildHeaders() {

    this.direcotryColumnDefs = [
      {
        headerName: 'Contact Type', field: 'contactTypeName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true
        , headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 160
      },
      { headerName: 'Last Name', field: 'lastName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
      //{ headerName: 'Salutation', field: 'salutation', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true,autoHeight: true, resizable: true },
      {
        headerName: 'First Name', field: 'firstName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        cellRenderer: function (params) {

          return '<a href="sip:' + params.data.email + '" target="_blank" >' + params.value + '</a>'
        },
        width: 124,
      },

      // {
      //   headerName: 'Name Combined', field: 'nameCombined', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,

      // },
      // { headerName: 'Gender', field: 'gender', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },


      {
        headerName: 'Function', field: 'functionName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, cellClass: "ag-grid-cell-wrap-text",
        wrapText: true, autoHeight: true, width: 212
      },
      {
        headerName: 'Email', field: 'email', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, width: 300,
        cellRenderer: function (params) {
          return '<a href="mailto:' + params.value + '" target="_blank" >' + params.value + '</a>'
        }
      },
      { headerName: 'Phone', field: 'phone', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 106 },
      { headerName: 'Mobile', field: 'mobile', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 100 },
      { headerName: 'EY Office', field: 'eyOfficeName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
      { headerName: 'IR Office', field: 'directoryIROfficeName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 116 },


      {
        headerName: 'Client Entity',
        children:
          [{
            headerName: 'Entity Short Name', field: 'entityShortName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, width: 212,
            resizable: true, columnGroupShow: 'closed'
          },
          { headerName: 'Location', field: 'locationName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, columnGroupShow: 'closed', width: 130 },

          {
            headerName: 'Entity Name', field: 'clientEntityName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, width: 325,
            resizable: true, columnGroupShow: 'open'
          },
          { headerName: 'Location', field: 'locationName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, columnGroupShow: 'open', width: 130 },


          { headerName: 'EY CID (GFIS)', field: 'eyCidGfis', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 140 },
          { headerName: 'Bank Code', field: 'bankCodeRgrCid', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 130 },
          { headerName: 'Client ID (Mercury)', field: 'mercuryEntityId', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 170 },
          { headerName: 'Street', field: 'street', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 150 },
          { headerName: 'Postal Code', field: 'postalCode', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 132 },
          { headerName: 'Canton', field: 'cantonName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 102 },
          { headerName: 'Country', field: 'countryName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 106 },
          { headerName: 'IR Office', field: 'irofficeName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, cellStyle: { color: '#155CB4' }, columnGroupShow: 'open', width: 111 }
          ]
      },



      //{ headerName: 'Role', field: 'roleName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      { headerName: 'Rank', field: 'rankName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 145 },
      //{ headerName: 'Communication Language', field: 'communicationLanguageName', sortable: true,autoHeight: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      { headerName: 'User Account', field: 'userAccount', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 140 },
      // {
      //   headerName: 'Last Updated On', field: 'lastUpdatedDate', sortable: true, filter: 'agDateColumnFilter', unSortIcon: true, resizable: true,
      //   cellRenderer: (data) => {

      //     var celldate = moment(data.lastUpdatedDate);

      //     return data.lastUpdatedDate ? "" : celldate.format("DD-MMM-YYYY") + " "
      //       + celldate.format("H:m");


      //   },
      // filterParams: {

      //   // provide comparator function
      //   comparator: function (filterLocalDateAtMidnight, cellValue) {

      //     var dateAsString = cellValue;

      //     if (dateAsString == null) {
      //       return 0;
      //     }


      //     var date = dateAsString.split('T')[0];
      //     var dateParts = date.split('-');

      //     var day = Number(dateParts[2]);
      //     var month = dateParts[1] - 1;
      //     var year = Number(dateParts[0]);
      //     var cellDate = moment(year + "/" + month + "/" + day, "YYYY/mm/DD");

      //     // Now that both parameters are Date objects, we can compare
      //     if (cellDate < filterLocalDateAtMidnight) {
      //       return -1;
      //     } else if (cellDate > filterLocalDateAtMidnight) {
      //       return 1;
      //     }
      //     return 0;
      //   }
      // }
      // }
    ]
    //if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Update")!=-1){

    this.direcotryColumnDefs.unshift({
      headerName: '',
      cellRenderer: "editDeleteActions",
      cellRendererParams: {
        onEditClick: this.onEditClick.bind(this),
        onDeleteClick: this.onDeleteClick.bind(this),
        onViewClick: this.onViewClick.bind(this),
        Actions: this.actionAccess
      },
      width: 80,
      sortable: false,
      pinned: 'left',
      autoHeight: false,
      filter: false,

    });
    //}
  }


  private DirectorybindGrid() {

    this._directoryMasterService.GetDirectoryDetils()
      .subscribe(
        res => {

          this.directoryTableDataset = res;

          //  this.gridApi.api.hideOverlay();

        },
        err => {

          console.log(err);
        }
      )

  }
  onEditClick(e) {
    this.ngMobileno = null;
    this.ngPhoneno = null;
    var id = this._headerService.encryptUsingAES256(e.rowData.directoryId);
    this.location.go('/directory?id=' + id);
    this.toastflag = false;
    this.editflag="edit";
    this.directoryId = e.rowData.directoryId;
    this.ngClientEntityID = (e.rowData.contactTypeName == "EY" || e.rowData.contactTypeName == "IR") ? null : e.rowData.clientEntityId;
    //this.GetMastersData(e.rowData.functionId,e.rowData.rankId)


    if (e.rowData.contactTypeName == "EY") {
      this.EntityFieldFlag = false;
      this.IROfficeFieldFlag = false;
      this.EYOfficeEntityFieldFlag = true;
      this.form.get('entityname').setValidators(null);
      this.form.get('irrboffice').setValidators(null);
      this.form.get('eyoffice').setValidators(Validators.required);
    }
    else if (e.rowData.contactTypeName == "IR") {
      this.EntityFieldFlag = false;
      this.IROfficeFieldFlag = true;
      this.EYOfficeEntityFieldFlag = false;
      this.form.get('entityname').setValidators(null);
      this.form.get('irrboffice').setValidators(Validators.required);
      this.form.get('eyoffice').setValidators(null);
    }
    else {
      this.EntityFieldFlag = true;
      this.IROfficeFieldFlag = false;
      this.EYOfficeEntityFieldFlag = false;
      this.form.get('entityname').setValidators(Validators.required);
      this.form.get('irrboffice').setValidators(null);
      this.form.get('eyoffice').setValidators(null);

    }
    this.form.get('entityname').updateValueAndValidity();
    this.form.get('irrboffice').updateValueAndValidity();
    this.form.get('eyoffice').updateValueAndValidity();
    this.directortAddEditModal = true;
    this.btnAddFlag = false;
    this.viewdirectoryentity = false;
     let data = this.contactTypeMasterData.find(ob => ob.contactTypeId === e.rowData.contactTypeId);
     this.contactTypeName = data.contactTypeName;
      this.functionMasterDatafilteredlist=this.functionMasterData.filter(x=>x.type==this.contactTypeName);
    let IDS = e.rowData.functionId; let rankIds = e.rowData.rankId;
    let functions = null; let ranks = null;
    if (IDS != null) {
      functions = IDS.split(',');
      for (var i = 0; i < functions.length; i++) { functions[i] = +functions[i]; }
      //this.ngfunctionids=functions;
    }
    if (rankIds != null) {
      ranks = rankIds.split(',');
      for (var i = 0; i < ranks.length; i++) { ranks[i] = +ranks[i]; }
      //this.ngrankids=ranks;
    }
    setTimeout(() => {
      this.form.patchValue({

        firstname: e.rowData.firstName,
        lastname: e.rowData.lastName,
        namecombined: e.rowData.nameCombined,
        gendertype: e.rowData.gender,
        salutation: e.rowData.salutation,
        email: e.rowData.email,
        phoneno: e.rowData.phone,
        mobileno: e.rowData.mobile,
        contacttype: e.rowData.contactTypeId,
        function: functions,
        entityname: (e.rowData.contactTypeName == "EY" || e.rowData.contactTypeName == "IR") ? null : e.rowData.entityShortName,

        //  toolaccessandauthorization:e.rowData.toolAccessAuthorization,
        rank: ranks,
        promotiondate: e.rowData.eycontactSeniorManagerPromotiondate == null || e.rowData.eycontactSeniorManagerPromotiondate == 'null' ? '' :
          e.rowData.eycontactSeniorManagerPromotiondate.indexOf('-') == -1 ? '' : {
            isRange: false, singleDate: {
              date: {
                year: Number(e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0].split('-')[0]),
                month: Number(e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0].split('-')[1]),
                day: Number(e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0].split('-')[2])
              },
              formatted: e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0]
            }
          },
        startDatemanDate: e.rowData.startDateonmandate == null || e.rowData.startDateonmandate == 'null' ? '' :
          e.rowData.startDateonmandate.indexOf('-') == -1 ? '' : {
            isRange: false, singleDate: {
              date: {
                year: Number(e.rowData.startDateonmandate.split('T')[0].split('-')[0]),
                month: Number(e.rowData.startDateonmandate.split('T')[0].split('-')[1]),
                day: Number(e.rowData.startDateonmandate.split('T')[0].split('-')[2])
              },
              formatted: e.rowData.startDateonmandate.split('T')[0]
            }
          },
        Comment: e.rowData.commentContactForm,
        role: e.rowData.roleId,
        communicationlanguage: e.rowData.communicationLanguageId,
        EYCID: e.rowData.eyCidGfis,
        BankCode: e.rowData.bankCodeRgrCid,
        ClientId: e.rowData.mercuryEntityId,
        Street: e.rowData.street,
        PostalCode: e.rowData.postalCode,
        Canton: e.rowData.cantonName,
        Country: e.rowData.countryName,
        IROffice: e.rowData.irofficeName,
        Location: e.rowData.locationName,
        useraccount: e.rowData.userAccount,
        eyoffice: (e.rowData.contactTypeName == "EY") ? e.rowData.eyofficeId : null,
        irrboffice: (e.rowData.contactTypeName == "IR") ? e.rowData.irofficeId : null,
        commuStatus: e.rowData.communicationStatus
      })
    }, 10);

  }
  onViewClick(e) {
    var id = this._headerService.encryptUsingAES256(e.rowData.directoryId);
    this.location.go('/directory?id=' + id);
    this.viewDirectoryClick(e);
    this.editflag="view";

  }
  viewDirectoryClick(e) {
    
    this.ngMobileno = null;
    this.ngPhoneno = null;
    this.toastflag = false;
    this.directoryId = e.rowData.directoryId;
    this.ngClientEntityID = (e.rowData.contactTypeName == "EY" || e.rowData.contactTypeName == "IR") ? null : e.rowData.clientEntityId;
    //this.GetMastersData(e.rowData.functionId,e.rowData.rankId)


    if (e.rowData.contactTypeName == "EY") {
      this.EntityFieldFlag = false;
      this.IROfficeFieldFlag = false;
      this.EYOfficeEntityFieldFlag = true;
      this.form.get('entityname').setValidators(null);
      this.form.get('irrboffice').setValidators(null);
      this.form.get('eyoffice').setValidators(Validators.required);
    }
    else if (e.rowData.contactTypeName == "IR") {
      this.EntityFieldFlag = false;
      this.IROfficeFieldFlag = true;
      this.EYOfficeEntityFieldFlag = false;
      this.form.get('entityname').setValidators(null);
      this.form.get('irrboffice').setValidators(Validators.required);
      this.form.get('eyoffice').setValidators(null);
    }
    else {
      this.EntityFieldFlag = true;
      this.IROfficeFieldFlag = false;
      this.EYOfficeEntityFieldFlag = false;
      this.form.get('entityname').setValidators(Validators.required);
      this.form.get('irrboffice').setValidators(null);
      this.form.get('eyoffice').setValidators(null);

    }
    this.form.get('entityname').updateValueAndValidity();
    this.form.get('irrboffice').updateValueAndValidity();
    this.form.get('eyoffice').updateValueAndValidity();
    this.directortAddEditModal = true;
    this.btnAddFlag = false;
    // if(this.actionAccess.indexOf("Update") != -1)
    // {
    //   this.viewdirectoryentity = false;
    // }
    // else{
    //   this.viewdirectoryentity = true;
    // }
    this.viewdirectoryentity = true;
    let data = this.contactTypeMasterData.find(ob => ob.contactTypeId === e.rowData.contactTypeId);
     this.contactTypeName = data.contactTypeName;
      this.functionMasterDatafilteredlist=this.functionMasterData.filter(x=>x.type==this.contactTypeName);
    let IDS = e.rowData.functionId; let rankIds = e.rowData.rankId;
    let functions = null; let ranks = null;
    if (IDS != null) {
      functions = IDS.split(',');
      for (var i = 0; i < functions.length; i++) { functions[i] = +functions[i]; }
      //this.ngfunctionids=functions;
    }
    if (rankIds != null) {
      ranks = rankIds.split(',');
      for (var i = 0; i < ranks.length; i++) { ranks[i] = +ranks[i]; }
      //this.ngrankids=ranks;
    }
    setTimeout(() => {
      this.form.patchValue({

        firstname: e.rowData.firstName,
        lastname: e.rowData.lastName,
        namecombined: e.rowData.nameCombined,
        gendertype: e.rowData.gender,
        salutation: e.rowData.salutation,
        email: e.rowData.email,
        phoneno: e.rowData.phone,
        mobileno: e.rowData.mobile,
        contacttype: e.rowData.contactTypeId,
        function: functions,
        entityname: (e.rowData.contactTypeName == "EY" || e.rowData.contactTypeName == "IR") ? null : e.rowData.entityShortName,

        //  toolaccessandauthorization:e.rowData.toolAccessAuthorization,
        rank: ranks,
        promotiondate: e.rowData.eycontactSeniorManagerPromotiondate == null || e.rowData.eycontactSeniorManagerPromotiondate == 'null' ? '' :
          e.rowData.eycontactSeniorManagerPromotiondate.indexOf('-') == -1 ? '' : {
            isRange: false, singleDate: {
              date: {
                year: Number(e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0].split('-')[0]),
                month: Number(e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0].split('-')[1]),
                day: Number(e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0].split('-')[2])
              },
              formatted: e.rowData.eycontactSeniorManagerPromotiondate.split('T')[0]
            }
          },
        startDatemanDate: e.rowData.startDateonmandate == null || e.rowData.startDateonmandate == 'null' ? '' :
          e.rowData.startDateonmandate.indexOf('-') == -1 ? '' : {
            isRange: false, singleDate: {
              date: {
                year: Number(e.rowData.startDateonmandate.split('T')[0].split('-')[0]),
                month: Number(e.rowData.startDateonmandate.split('T')[0].split('-')[1]),
                day: Number(e.rowData.startDateonmandate.split('T')[0].split('-')[2])
              },
              formatted: e.rowData.startDateonmandate.split('T')[0]
            }
          },
        Comment: e.rowData.commentContactForm,
        role: e.rowData.roleId,
        communicationlanguage: e.rowData.communicationLanguageId,
        EYCID: e.rowData.eyCidGfis,
        BankCode: e.rowData.bankCodeRgrCid,
        ClientId: e.rowData.mercuryEntityId,
        Street: e.rowData.street,
        PostalCode: e.rowData.postalCode,
        Canton: e.rowData.cantonName,
        Country: e.rowData.countryName,
        IROffice: e.rowData.irofficeName,
        Location: e.rowData.locationName,
        useraccount: e.rowData.userAccount,
        eyoffice: (e.rowData.contactTypeName == "EY") ? e.rowData.eyofficeId : null,
        irrboffice: (e.rowData.contactTypeName == "IR") ? e.rowData.irofficeId : null,
        commuStatus: e.rowData.communicationStatus

      })
    }, 10);

  }
  onDeleteClick(e) {
    this.toastflag = false;
    this.directoryId = e.rowData.directoryId;
    this.deletemodalflag = true;
  }
  setFrameworkoptions() {
    this.frameworkComponents = {
      editDeleteActions: DirectoryTableActionsComponent,
    }

  }
  ////#endregion

  ////#region Directory Form Code
  AddDirectory() {
    this.viewdirectoryentity = false;
    this.directortAddEditModal = true;
    this.btnAddFlag = true;
    this.form.reset();
    this.ngrankids = null;
    this.ngfunctionids = null;
    this.ngMobileno = null;
    this.ngPhoneno = null;
    this.viewdirectoryentity = false;
    this.editflag="add";
    
    // this.form.controls.function.reset();
    // this.form.controls.rank.reset();
    // this.GetMastersData(null,null);

    // setTimeout(() => { // this will make the execution after the above boolean has changed
    //   this.renderer.selectRootElement("#empFirstName").focus();
    // }, 10);
  }
  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }
  private DirectoryForm() {
    return this._formBuilder.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      namecombined: [],
      gendertype: [null, [Validators.required]],
      salutation: [null, [Validators.required]],
      email: [null, [Validators.email]],
      phoneno: [null, [Validators.pattern("[0-9 ]{13}")]],
      mobileno: [null, [Validators.pattern("[0-9 ]{13}")]],
      contacttype: [null, [Validators.required]],
      function: [null, [Validators.required]],
      entityname: [],
      // location: [null, [Validators.required]],     
      rank: [],
      promotiondate: [],
      startDatemanDate: [],
      role: [null, [Validators.required]],
      communicationlanguage: [null, [Validators.required]],
      EYCID: [],
      BankCode: [],
      ClientId: [],
      Street: [],
      PostalCode: [],
      Canton: [],
      Country: [],
      IROffice: [],
      Location: [],
      useraccount: [],
      eyoffice: [],
      irrboffice: [],
      Comment: [],
      commuStatus: []

    });
  }
  GetSearchFilterMastersData() {
    this._directoryMasterService.GetSearchFilterMastersData()
      .subscribe(
        res => {
          this.lstSearchFilterFunction = res[0];
          this.lstSearchFilterFunctioncopy = res[0];
          this.lstSearchFilterContactType = res[1];
        },
        err => {

          console.log(err);
        }
      )

  }


  GetMastersData() {


    this._directoryMasterService.GetMastersData()
      .subscribe(
        res => {

          this.locationMasterData = res[0];
          this.rankMasterData = res[1];
          this.functionMasterData = res[2];
          this.roleMasterData = res[3];
          this.cummunicationLanguageMasterData = res[4];
          this.contactTypeMasterData = res[5];
          this.EYOfficeMasterData = res[6];
          this.IROfficeMasterData = res[7];
          //   if(IDS!=null){
          //   let functions= IDS.split(',').map(x=>+x);
          //   this.functionMasterData.forEach(element => {        
          //    element["isChecked"]=functions.indexOf(element.functionId) > -1?true:false;        
          //  });     
          // }
          // if(rankIds!=null){
          //   let ranks= rankIds.split(',').map(x=>+x);
          //   this.rankMasterData.forEach(element => {        
          //    element["isChecked"]=ranks.indexOf(element.rankId) > -1?true:false;        
          //  });
          // }

        },
        err => {

          console.log(err);
        }
      )

  }

  DirectorySave(form: FormGroup) {
    this.toastflag = false;

    this.validateForm(form);
    if (!form.valid)
      return
    let contact = this.contactTypeMasterData.find(ob => ob.contactTypeId === this.form.value.contacttype).contactTypeName;
    var obj = {

      DirectoryId: 0,
      FirstName: form.value.firstname,
      LastName: form.value.lastname,
      NameCombined: form.value.namecombined,
      Gender: form.value.gendertype,
      ContactTypeId: form.value.contacttype,
      Salutation: form.value.salutation,
      FunctionId: form.value.function.sort((a, b) => a - b).map(x => x).join(","),
      Email: form.value.email,
      Phone: form.value.phoneno,
      Mobile: form.value.mobileno,
      ClientEntityId: contact == "EY" || contact == "IR" ? null : this.ngClientEntityID,
      // LocationId:null,
      CommunicationLanguageId: form.value.communicationlanguage,
      RankId: form.value.rank.sort((a, b) => a - b).map(x => x).join(","),
      EycontactSeniorManagerPromotiondate: form.value.promotiondate == null || form.value.promotiondate == "" || form.value.promotiondate == undefined ? null : form.value.promotiondate.singleDate.formatted.indexOf('-') != -1 ? new Date(form.value.promotiondate.singleDate.formatted) : new Date(form.value.promotiondate.singleDate.formatted.split('.')[1] + '-' + form.value.promotiondate.singleDate.formatted.split('.')[0] + '-' + form.value.promotiondate.singleDate.formatted.split('.')[2]),
      //form.value.promotiondate==null?null: form.value.promotiondate.singleDate.formatted,
      StartDateonmandate: form.value.startDatemanDate == null || form.value.startDatemanDate == "" || form.value.startDatemanDate == undefined ? null : form.value.startDatemanDate.singleDate.formatted.indexOf('-') != -1 ? new Date(form.value.startDatemanDate.singleDate.formatted) : new Date(form.value.startDatemanDate.singleDate.formatted.split('.')[1] + '-' + form.value.startDatemanDate.singleDate.formatted.split('.')[0] + '-' + form.value.startDatemanDate.singleDate.formatted.split('.')[2]),
      //form.value.startDatemanDate==null?null:form.value.startDatemanDate.singleDate.formatted,
      CommentContactForm: form.value.Comment,
      UserAccount: form.value.useraccount,
      FormStatus: "Active",
      RoleId: form.value.role,
      EyofficeId: contact == "EY" ? form.value.eyoffice : null,
      IrofficeId: contact == "IR" ? form.value.irrboffice : null,
      CommunicationStatus: form.value.commuStatus,
    }
    this._directoryMasterService.SaveData(obj)
      .subscribe(
        res => {

          if (res["message"] == "success") {
            this.toasttype = "success";
            this.toastflag = true;

            this.toastmessage = "Contact has been added successfully.";
            this.DirectorybindGrid();
            this.directortAddEditModal = false;
            this.ngClientEntityID = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {
            this.toasttype = "error";
            this.toastflag = true;

            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "EmailExists") {
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = "Email already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "MobileNoExists") {
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = "Mobile no. already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
        },
        err => {
          if (err.toString().toLocaleLowerCase().indexOf('max') != -1 || err.toString().toLocaleLowerCase().indexOf('allowed') != -1) {
            //var key=Object.keys(err["error"]["errors"])[0];   
            //this.toasterService.message.next(err.toString());    
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else {
            this.toasttype = "error";
            this.toastflag = true;

            this.toastmessage = "Error occured. Please try after sometime";
            console.log(err);
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }
      )


  }
  DirectoryUpdate(form: FormGroup) {
    
    this.toastflag = false;
    this.validateForm(form);
    if (!form.valid)
      return
    let contact = this.contactTypeMasterData.find(ob => ob.contactTypeId === this.form.value.contacttype).contactTypeName;
    var obj = {


      DirectoryId: this.directoryId,
      FirstName: form.value.firstname,
      LastName: form.value.lastname,
      NameCombined: form.value.namecombined,
      Gender: form.value.gendertype,
      ContactTypeId: form.value.contacttype,
      Salutation: form.value.salutation,
      FunctionId: form.value.function.sort((a, b) => a - b).map(x => x).join(","),
      Email: form.value.email,
      Phone: form.value.phoneno,
      Mobile: form.value.mobileno,
      ClientEntityId: contact == "EY" || contact == "IR" ? null : this.ngClientEntityID,
      // LocationId:null,
      CommunicationLanguageId: form.value.communicationlanguage,
      RankId: form.value.rank.sort((a, b) => a - b).map(x => x).join(","),
      EycontactSeniorManagerPromotiondate: form.value.promotiondate == null || form.value.promotiondate == "" || form.value.promotiondate == undefined ? null : form.value.promotiondate.singleDate.formatted.indexOf('-') != -1 ? new Date(form.value.promotiondate.singleDate.formatted) : new Date(form.value.promotiondate.singleDate.formatted.split('.')[1] + '-' + form.value.promotiondate.singleDate.formatted.split('.')[0] + '-' + form.value.promotiondate.singleDate.formatted.split('.')[2]),
      //form.value.promotiondate==null?null: form.value.promotiondate.singleDate.formatted,
      StartDateonmandate: form.value.startDatemanDate == null || form.value.startDatemanDate == "" || form.value.startDatemanDate == undefined ? null : form.value.startDatemanDate.singleDate.formatted.indexOf('-') != -1 ? new Date(form.value.startDatemanDate.singleDate.formatted) : new Date(form.value.startDatemanDate.singleDate.formatted.split('.')[1] + '-' + form.value.startDatemanDate.singleDate.formatted.split('.')[0] + '-' + form.value.startDatemanDate.singleDate.formatted.split('.')[2]),
      //form.value.startDatemanDate==null?null:form.value.startDatemanDate.singleDate.formatted,
      CommentContactForm: form.value.Comment,
      CommunicationStatus: form.value.commuStatus,
      FormStatus: "Active",
      UserAccount: form.value.useraccount,
      RoleId: form.value.role,
      EyofficeId: contact == "EY" ? form.value.eyoffice : null,
      IrofficeId: contact == "IR" ? form.value.irrboffice : null
    }

    this._directoryMasterService.UpdateData(obj)
      .subscribe(
        res => {

          //if(res){
          if (res["message"] == "success") {

            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Contact has been updated successfully.";
            this.DirectorybindGrid();
            this.directortAddEditModal = false;
            this.directoryId = 0;
            this.ngClientEntityID = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {

            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Contact has been updated successfully.";
            this.DirectorybindGrid();
            this.directortAddEditModal = false;
            this.directoryId = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "EmailExists") {
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = "Email already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "MobileNoExists") {
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = "Mobile no. already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
        },
        err => {
          if (err.toString().toLocaleLowerCase().indexOf('max') != -1 || err.toString().toLocaleLowerCase().indexOf('allowed') != -1) {
            //var key=Object.keys(err["error"]["errors"])[0];   
            //this.toasterService.message.next(err.toString());    
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else {
            this.toasttype = "error";
            this.toastflag = true;

            this.toastmessage = "Error occured please contact support team";
            console.log(err);
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }
      )


  }

  DeleteDirectory() {

    this.toastflag = false;
    this._directoryMasterService.DeleteData(this.directoryId)
      .subscribe(
        res => {

          if (res['message'] == "success") {
            this.toasttype = "success";
            this.toastflag = true;
            this.deletemodalflag = false;
            this.toastmessage = "Contact has been deleted successfully.";
            this.DirectorybindGrid();
            this.directoryId = 0;
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res['message'] == "contactsexists") {
            this.toasttype = "warning";
            this.toastflag = true;
            this.toastmessage = "The contact is mapped to the audit assignment form,so it can't be deleted.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else {
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        },
        err => {
          this.toasttype = "error";
          this.toastflag = true;

          this.toastmessage = "Error occured. Please try after sometime";
          console.log(err);
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      )
  }


  ////#endregion

  //#region Excel export modal methods
  private ExceltoExport(): FormGroup {
    return this._formBuilder.group({
      chkContactType: [],
      chkSalutation: [],
      chkFirstName: [],
      chkLastName: [],
      chkNamecombined: [],
      chkGender: [],
      chkFunction: [],
      chkEmail: [],
      chkPhone: [],
      chkMobile: [],
      chkEYOffice: [],
      chkDirIROffCie: [],
      chkEntityName: [],
      chkLocation: [],
      chkRole: [],
      chkRank: [],
      chkEYContactifSeniorManagerpromotiondate: [],
      chkStartDateonmandate: [],
      chkCommentEntityDataForm: [],
      chkCommunicationStatus: [],
      radioexceltype: [],
      chkCommunicationLanguage: [],
      chkEYCID: [],
      chkBankCode: [],
      chkClientID: [],
      chkStreet: [],
      chkPostalCode: [],
      chkCanton: [],
      chkCountry: [],
      chkIROffice: [],
      chkUserAccount: [],
      chkNameCombinedFL:[]

    });
  }
  ExcelExportpopup() {
    this.toastflag = false;
    this.ExcelExportmodalflag = true;
    this.ExcelToexportForm.setValue({
      chkContactType: true,
      chkSalutation: true,
      chkFirstName: true,
      chkLastName: true,
      chkNamecombined: true,
      chkGender: true,
      chkFunction: true,
      chkEmail: true,
      chkPhone: true,
      chkMobile: true,
      chkEntityName: true,
      chkEYOffice: true,
      chkDirIROffCie: true,
      chkLocation: true,
      chkRole: true,
      chkRank: true,
      chkEYContactifSeniorManagerpromotiondate: true,
      chkStartDateonmandate: true,
      chkCommentEntityDataForm: true,
      chkCommunicationStatus: true,
      chkCommunicationLanguage: true,
      chkEYCID: true,
      chkBankCode: true,
      chkClientID: true,
      chkStreet: true,
      chkPostalCode: true,
      chkCanton: true,
      chkCountry: true,
      chkIROffice: true,
      chkUserAccount: true,
      chkNameCombinedFL:true,
      radioexceltype: "all"
    })

  }

  DirectoryExcelexport(form: FormGroup) {

    this.toastflag = false;
    let selectedNodes = this.gridApi.api.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data.directoryId);
    let columnNames = [];
    columnNames.push(form.value.chkBankCode == true ? "Bank Code" : "");
    columnNames.push(form.value.chkContactType == true ? "Contact Type" : "");
    columnNames.push(form.value.chkSalutation == true ? "Salutation" : "");
    columnNames.push(form.value.chkFirstName == true ? "First Name" : "");
    columnNames.push(form.value.chkLastName == true ? "Last Name" : "");
    columnNames.push(form.value.chkNamecombined == true ? "Name combined" : "");
    columnNames.push(form.value.chkGender == true ? "Gender" : "");
    columnNames.push(form.value.chkFunction == true ? "Function" : "");
    columnNames.push(form.value.chkEmail == true ? "Email" : "");
    columnNames.push(form.value.chkPhone == true ? "Phone" : "");
    columnNames.push(form.value.chkMobile == true ? "Mobile" : "");
    columnNames.push(form.value.chkCommunicationLanguage == true ? "Communication Language" : "");
    columnNames.push(form.value.chkRole == true ? "Role" : "");
    columnNames.push(form.value.chkRank == true ? "Rank" : "");
    columnNames.push(form.value.chkEYContactifSeniorManagerpromotiondate == true ? "EY Contact: if Senior Manager - promotion date" : "");
    columnNames.push(form.value.chkStartDateonmandate == true ? "Start Date on mandate" : "");
    columnNames.push(form.value.chkEYOffice == true ? "EY Office" : "");
    columnNames.push(form.value.chkDirIROffCie == true ? "IR Office" : "");
    columnNames.push(form.value.chkEntityName == true ? "Entity Short Name" : "");
    columnNames.push(form.value.chkEYCID == true ? "EY CID (GFIS)" : "");
    columnNames.push(form.value.chkClientID == true ? "Entity ID (Mercury)" : "");
    columnNames.push(form.value.chkStreet == true ? "Street" : "");
    columnNames.push(form.value.chkPostalCode == true ? "Postal Code" : "");
    columnNames.push(form.value.chkLocation == true ? "Location" : "");
    columnNames.push(form.value.chkCanton == true ? "Canton" : "");
    columnNames.push(form.value.chkCountry == true ? "Country" : "");
    columnNames.push(form.value.chkIROffice == true ? "IR Office" : "");
    columnNames.push(form.value.chkUserAccount == true ? "User Account" : "");
    columnNames.push(form.value.chkCommentEntityDataForm == true ? "Comment - Contact Form" : "");
    columnNames.push(form.value.chkCommunicationStatus == true ? "Communication Status" : "");
    columnNames.push(form.value.chkNameCombinedFL == true ? "Name Combined(First & Last Name)" : "");
    

    columnNames = columnNames.filter(function (el) {
      return el != "";
    });
    if (columnNames.length == 0) {
      this.toasttype = "warning";
      this.toastflag = true;

      this.toastmessage = "Please select atleast one column";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    var bodydata = {
      columnNames: columnNames,
      selectedData: selectedData
    }
    this._directoryMasterService.DirectoryExcelexport(bodydata)
      .subscribe(
        res => {

          this.ExcelExportmodalflag = false;

          let date = new Date().toString();
          saveAs(res, "DirectoryData" + date + ".xlsx");

        },
        err => {

          console.log(err);
        }
      )
  }
  ReportOptionChange() {
    this.toastflag = false;
    if (this.ExcelToexportForm.value.radioexceltype == "all") {
      this.ExcelToexportForm.setValue({
        chkBankCode: true,
        chkContactType: true,
        chkSalutation: true,
        chkFirstName: true,
        chkLastName: true,
        chkNamecombined: true,
        chkGender: true,
        chkFunction: true,
        chkEmail: true,
        chkPhone: true,
        chkMobile: true,
        chkDirIROffCie: true,
        chkEYOffice: true,
        chkEntityName: true,
        chkLocation: true,
        chkRole: true,
        chkRank: true,
        chkEYContactifSeniorManagerpromotiondate: true,
        chkStartDateonmandate: true,
        chkCommentEntityDataForm: true,
        chkCommunicationStatus: true,
        chkCommunicationLanguage: true,
        chkEYCID: true,
        chkClientID: true,
        chkStreet: true,
        chkPostalCode: true,
        chkCanton: true,
        chkCountry: true,
        chkIROffice: true,
        chkUserAccount: true,
        chkNameCombinedFL:true,
        radioexceltype: "all"
      })
    }
    else if (this.ExcelToexportForm.value.radioexceltype == "RBContacts") {
      this.ExcelToexportForm.setValue({
        chkBankCode: true,
        chkContactType: true,
        chkSalutation: false,
        chkFirstName: true,
        chkLastName: true,
        chkNamecombined: false,
        chkGender: true,
        chkFunction: true,
        chkEmail: true,
        chkPhone: false,
        chkMobile: false,
        chkDirIROffCie: false,
        chkEYOffice: false,
        chkEntityName: true,
        chkLocation: false,
        chkRole: false,
        chkRank: false,
        chkEYContactifSeniorManagerpromotiondate: false,
        chkStartDateonmandate: false,
        chkCommentEntityDataForm: false,
        chkCommunicationStatus: false,
        chkCommunicationLanguage: true,
        chkEYCID: false,
        chkClientID: false,
        chkStreet: false,
        chkPostalCode: false,
        chkCanton: false,
        chkCountry: false,
        chkIROffice: false,
        chkUserAccount: false,
        chkNameCombinedFL:false,
        radioexceltype: "RBContacts"
      })

    }

    else if (this.ExcelToexportForm.value.radioexceltype == "EYContacts") {
      this.ExcelToexportForm.setValue({
        chkBankCode: false,
        chkContactType: true,
        chkSalutation: false,
        chkFirstName: true,
        chkLastName: true,
        chkNamecombined: true,
        chkGender: false,
        chkFunction: true,
        chkEmail: true,
        chkPhone: false,
        chkMobile: false,
        chkDirIROffCie: false,
        chkEYOffice: true,
        chkEntityName: false,
        chkLocation: false,
        chkRole: false,
        chkRank: false,
        chkEYContactifSeniorManagerpromotiondate: false,
        chkStartDateonmandate: false,
        chkCommentEntityDataForm: false,
        chkCommunicationStatus: false,
        chkCommunicationLanguage: false,
        chkEYCID: false,
        chkClientID: false,
        chkStreet: false,
        chkPostalCode: false,
        chkCanton: false,
        chkCountry: false,
        chkIROffice: false,
        chkUserAccount: true,
        chkNameCombinedFL:false,
        radioexceltype: "EYContacts"
      })

    }
    
    else if (this.ExcelToexportForm.value.radioexceltype == "Custom") {
      this.ExcelToexportForm.setValue({
        chkBankCode: false,
        chkContactType: false,
        chkSalutation: false,
        chkFirstName: false,
        chkLastName: false,
        chkNamecombined: false,
        chkGender: false,
        chkFunction: false,
        chkEmail: false,
        chkPhone: false,
        chkMobile: false,
        chkDirIROffCie: false,
        chkEYOffice: false,
        chkEntityName: false,
        chkLocation: false,
        chkRole: false,
        chkRank: false,
        chkEYContactifSeniorManagerpromotiondate: false,
        chkStartDateonmandate: false,
        chkCommentEntityDataForm: false,
        chkCommunicationStatus: false,
        chkCommunicationLanguage: false,
        chkEYCID: false,
        chkClientID: false,
        chkStreet: false,
        chkPostalCode: false,
        chkCanton: false,
        chkCountry: false,
        chkIROffice: false,
        chkUserAccount: false,
        chkNameCombinedFL:false,
        radioexceltype: "Custom"
      })

    }
  }

  FirstNameLastNamChange() {
    this.nameCombined = "";
    if (this.form.value.firstname != null && this.form.value.lastname != null)
      this.nameCombined = this.form.value.lastname + ', ' + this.form.value.firstname;
    else if (this.form.value.firstname != null && this.form.value.lastname == null)
      this.nameCombined = this.form.value.firstname;
    else if (this.form.value.firstname == null && this.form.value.lastname != null)
      this.nameCombined = this.form.value.lastname;
    else if (this.form.value.firstname != "" && this.form.value.lastname != "")
      this.nameCombined = this.form.value.lastname + ', ' + this.form.value.firstname;
    else if (this.form.value.firstname != "" && this.form.value.lastname == "")
      this.nameCombined = this.form.value.firstname;
    else if (this.form.value.firstname == "" && this.form.value.lastname != "")
      this.nameCombined = this.form.value.lastname;

  }
  ////#endregion

  ////#region template download
  DirectoryTempleateExport() {
    this.toastflag = false;
    this._directoryMasterService.DirectoryTempleateExport()
      .subscribe(
        res => {

          saveAs(res, "DirectoryTemplate.xlsx");
        },
        err => {

          console.log(err);
        }
      )
  }
  ////#endregion
  ////#region  Entity based details methods
  GetEntityDetails() {
    this.toastflag = false;

    this._directoryMasterService.GetEntityDetails()
      .subscribe(
        res => {


          this.lstEntitydetails = res;
          this.lstEntitydetails.forEach(element => {
            this.arrayCLientEntity.push(element.entityShortName);
          });
          //this.SeachEntityFlag=true;
        },
        err => {

          console.log(err);
        }
      )
  }
  FillEntityBaseDetails() {

    let data = this.lstEntitydetails.find(ob => ob.entityShortName === this.form.value.entityname);
    
    if (data !== undefined) {
      this.ngClientEntityID = data.clientEntityId;
      this.form.patchValue({
        EYCID: data.eyCidGfis,
        BankCode: data.bankCodeRgrCid,
        ClientId: data.mercuryEntityId,
        Street: data.street,
        PostalCode: data.postalCode,
        Canton: data.cantonName,
        Country: data.countryName,
        IROffice: data.irofficeName,
        Location: data.locationName
      });
    } else {
      this.ngClientEntityID = 0;
      this.form.patchValue({
        EYCID: "",
        BankCode: "",
        ClientId: "",
        Street: "",
        PostalCode: "",
        Canton: "",
        Country: "",
        entityname: "",
        IROffice: "",
        Location: ""
      });

    }
  }
  ////#endregion
  onFilterTextBoxChanged() {
    
    this.gridApi.api.setQuickFilter(this.ngSearchFilter);
  }
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  onmobileInputChange(value) {
    
    if (value != null) {
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      value = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '');
      this.ngMobileno = value;
    }
  }
  onphoneInputChange(value) {
    if (value != null) {
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      value = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '');
      this.ngPhoneno = value;
    }
  }
  ////#region  mass Update
  public UpdateDirectoryModel: boolean = false;
  private MassUpdateFormDirectory(): FormGroup {
    return this._formBuilder.group({
      function: [],
      rank: [],
      useraccount: [],
      comment: [],
      commuStatus: []
    });
  }
  onbtnclickMassUpdate() {
    let selectedRows;
    selectedRows = this.gridApi.api.getSelectedRows();
    if (selectedRows.length === 0) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please select at least one contact";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    this.MassUpdateForm.reset();
    this.UpdateDirectoryModel = true;
    let functiondata = this.functionMasterData;
    this.functionMasterData = null;
    let rankdata = this.rankMasterData;
    this.rankMasterData = null
    setTimeout(() => { // this will make the execution after the above boolean has changed

      this.functionMasterData = functiondata;
      this.rankMasterData = rankdata;
      this.functionMasterData.forEach(element => {
        element["isChecked"] = false;
      });

      this.rankMasterData.forEach(element => {
        element["isChecked"] = false;
      });

    }, 1);
    //this.GetMastersData();
  }
  MassUpdateDirectoryData() {
    
    if (this.MassUpdateForm.value.function.sort((a, b) => a - b).map(x => x).join(",") == "" && this.MassUpdateForm.value.rank == null
      && this.MassUpdateForm.value.useraccount == null && this.MassUpdateForm.value.comment == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide at least one information";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let selectedRows;
    selectedRows = this.gridApi.api.getSelectedRows();
    let DirectoryIds = [];
    ///than you can map your selectedRows 
    selectedRows.map((row) => {
      
      DirectoryIds.push(Number(row.directoryId));
    });
    let obj = {
      DirectoryId: DirectoryIds,
      FunctionId: this.MassUpdateForm.value.function.sort((a, b) => a - b).map(x => x).join(","),
      RankId: this.MassUpdateForm.value.rank.sort((a, b) => a - b).map(x => x).join(","),
      UserAccount: this.MassUpdateForm.value.useraccount,
      CommentContactForm: this.MassUpdateForm.value.comment,
      CommunicationStatus: this.MassUpdateForm.value.commuStatus
    }
    this._directoryMasterService.MassUpdateData(obj)
      .subscribe(
        res => {

          //if(res){
          if (res["message"] == "success") {

            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Contacts has been updated successfully.";
            this.DirectorybindGrid();
            this.directortAddEditModal = false;
            this.UpdateDirectoryModel = false;
            this.directoryId = 0;
            this.ngClientEntityID = 0;
            this.form.reset();
            this.MassUpdateFormDirectory();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {

            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Contacts has been updated successfully.";
            this.DirectorybindGrid();
            this.directortAddEditModal = false;
            this.directoryId = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
        },
        err => {
          this.toasttype = "error";
          this.toastflag = true;

          this.toastmessage = "Error occured please contact support team";
          console.log(err);
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      )


  }
  ////#endregion
  ////#region Aggid External search

  isExternalFilterPresent() {
    return searchType.length != 0 || searchFunction.length != 0;
  }
  externalContactTypeFilterChanged(newValue) {
    searchType = newValue;
    this.lstSearchFilterFunction = []
    if (searchType.length > 0) {
      searchType.forEach(element => {
        this.lstSearchFilterFunctioncopy.forEach(item => {
          if (item.type == element) {
            this.lstSearchFilterFunction.push(item);
          }
        });
      })
    }
    else if (searchType.length == 0) {
      //   searchType.forEach(element=>{
      //     this.lstSearchFilterFunctioncopy.forEach(item => {
      //       if(item.type != element)
      //       {
      //         this.lstSearchFilterFunction.push(item);
      //       }
      //     });
      // })
      this.lstSearchFilterFunctioncopy.forEach(item => {
        this.lstSearchFilterFunction.push(item);
      });

    }
    this.gridApi.api.onFilterChanged();
  }
  externalFunctionFilterChanged(newValue) {
    debugger;
    searchFunction = newValue;
    this.gridApi.api.onFilterChanged();

  }

  doesExternalFilterPass(node) {
    
    let functionFlag = false;
    let contactTypeFlag = false;
    if (searchType.length != 0 && searchFunction.length != 0) {
      searchType.forEach(element => {
        
        searchFunction.forEach(functionelement => {
          
          if (node.data.contactTypeName == element && node.data.functionName.includes(functionelement))
            return contactTypeFlag = true;
        });
        if (contactTypeFlag)
          return contactTypeFlag
      });
      if (contactTypeFlag)
        return true;
      else
        return false;
    }
    if (searchType.length != 0) {
      searchType.forEach(element => {
        
        if (node.data.contactTypeName == element)
          return contactTypeFlag = true;
      });
      if (contactTypeFlag)
        return true;
      else
        return false;
    }
    if (searchFunction.length != 0) {
      searchFunction.forEach(element => {
        
        if (node.data.functionName.includes(element))
          return functionFlag = true;
      });
      if (functionFlag)
        return true;
      else
        return false;
    }
    return true;
  }
  public NgSearchFlag = true;
  resetFIlters() {
    
    //this.lstSearchFilterFunction;
    // this.GetSearchFilterMastersData();
    this.NgSearchFlag = false;
    searchType = [], searchFunction = [];
    this.ngSearchFunction = null;
    this.ngSearchContactType = null;
    setTimeout(() => {
      this.NgSearchFlag = true;
    }, 50);
    this.gridApi.api.onFilterChanged();
  }
  ////#endregion
  onChangeContactType(Contactypeid) {
    debugger;
    let data = this.contactTypeMasterData.find(ob => ob.contactTypeId === Contactypeid);
    this.contactTypeName = data.contactTypeName;
     this.functionMasterDatafilteredlist=this.functionMasterData.filter(x=>x.type==this.contactTypeName);
    
    if (data.contactTypeName == "EY") {
      this.EntityFieldFlag = false;
      this.IROfficeFieldFlag = false;
      this.EYOfficeEntityFieldFlag = true;
      this.form.get('entityname').setValidators(null);
      this.form.get('irrboffice').setValidators(null);
      this.form.get('eyoffice').setValidators(Validators.required);
    }
    else if (data.contactTypeName == "IR") {
      this.EntityFieldFlag = false;
      this.IROfficeFieldFlag = true;
      this.EYOfficeEntityFieldFlag = false;
      this.form.get('entityname').setValidators(null);
      this.form.get('irrboffice').setValidators(Validators.required);
      this.form.get('eyoffice').setValidators(null);
    }
    else {
      this.EntityFieldFlag = true;
      this.IROfficeFieldFlag = false;
      this.EYOfficeEntityFieldFlag = false;
      this.form.get('entityname').setValidators(Validators.required);
      this.form.get('irrboffice').setValidators(null);
      this.form.get('eyoffice').setValidators(null);

    }
    this.form.get('entityname').updateValueAndValidity();
    this.form.get('irrboffice').updateValueAndValidity();
    this.form.get('eyoffice').updateValueAndValidity();
  }


  EscapeYes(){
    this.directortAddEditModal = false;
    this.Escmodalflag = false;
    this.editflag="";
  }


  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    debugger;
    if(this.editflag == "edit"){
      this.Escmodalflag=true;
    }
    else{
      this.directortAddEditModal = false;
    }
  }  

}
var searchType = [], searchFunction = [];