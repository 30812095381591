import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditDeleteActionsComponent } from 'src/app/reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-user-mangement',
  templateUrl: './user-mangement.component.html',
  styleUrls: ['./user-mangement.component.scss']
})
export class UserMangementComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder, private _userService: UserManagementService) { }

  toasttype: string;
  toastflag: boolean = false;
  toastmessage: string;
  form: FormGroup;
  userModal: boolean = false;
  btnIsSaveUser: boolean = true;
  roles: any = [];
  loaderflag: boolean = false;
  public userId: number;
  userModalHeader:string;

  public EditID: any;
  public DeletID: any;
  public ISUpdate: boolean = false;
  public showLoader: boolean = false;
  public rowData: any;

  //#region ag-grid_variables
  modules = AllCommunityModules;
  gridApi: any;
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };
  //public btnsubmittext: string = "ADD";
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public ColumnDefs: any;
  public frameworkComponents: any;
  public usertableDataset: any;
  public deletemodalflag: boolean = false;

  //#endregion ag-grid_variables

  ngOnInit(): void {
    this.form = this.createForm();
    this.setFrameworkoptions();
    this.setTableHeight();
    this.buildHeaders();
    this.getRoles();
  }

  createForm() {
    return this._formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      userName: [null, [Validators.required, Validators.email]],
      phoneNo: [null, [Validators.required, Validators.maxLength(10)]],
      role: [null, [Validators.required]],
    })
  }

  handleGridReady(params) {
    this.gridApi = params;
    this.gridApi.api.showLoadingOverlay()
    this.gridApi.api.sizeColumnsToFit()
    this.getUsers();
  };

  addUser() {
    this.userModalHeader = "Add User"
    this.btnIsSaveUser = true;
    this.toastflag = false;
    this.form.reset();
    //this.form.patchValue({ formstatus: "Active" });
    this.userModal = true;
  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  saveUser(form: FormGroup) {
    
    this.toastflag = false;

    this.validateForm(form);
    if (!form.valid)
      return
    this.loaderflag = true;
    var obj = {

      firstName: form.value.firstName,
      lastName: form.value.lastName,
      userName: form.value.userName,
      phoneNo:  (form.value.phoneNo).toString(),
      roleId: form.value.role
    }
    this._userService.Add(obj)
      .subscribe(
        res => {
          if (res["message"] == "success") {
            this.toasttype = "success";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "User has been saved successfully.";
            this.getUsers();
            this.userModal = false;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {
            this.toasttype = "error";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "An unexpected error has occured. Please contact support team.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "UserExists") {
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "User Name already exists";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        },
        err => {
          this.toasttype = "error";
          this.toastflag = true;
          this.loaderflag = false;
          this.toastmessage = "An unexpected error has occured. Please contact support team.";
          console.log(err);
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      )
  }

  updateUser(form: FormGroup) {
    
    this.toastflag = false;
    this.validateForm(form);
    if (!form.valid)
      return
    this.loaderflag = true;
    var obj = {
      userId: this.userId,
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      userName: form.value.userName,
      phoneNo: form.value.phoneNo,
      roleId: form.value.role
    }
    this._userService.Update(obj)
      .subscribe(
        res => {
          
          if (res["message"] == "success") {
            this.loaderflag = false;
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "User has been updated successfully.";
            this.getUsers();
            this.userModal = false;
            this.userId = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {
            this.loaderflag = false;
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "An unexpected error has occured. Please contat support team.";
            this.getUsers();
            this.userModal = false;
            this.userId = 0;
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "UserExists") {
            this.userModal = false;
            this.userId = 0;
            this.form.reset();
            this.toasttype = "warning";
            this.toastflag = true;
            this.loaderflag = false;
            this.toastmessage = "User Name already exists.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        },
        err => {
          this.toasttype = "error";
          this.toastflag = true;
          this.loaderflag = false;
          this.toastmessage = "An unexpected error has occured. Please contat support team.";
          console.log(err);
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      )
  }

  getRoles() {
    this._userService.getRoles()
      .subscribe(
        res => {
          this.roles = res;
          console.log(this.roles);
        },
        err => {
          console.log(err);
        }
      )
  }

  setFrameworkoptions() {
    this.frameworkComponents = {
      editDeleteActions: EditDeleteActionsComponent,
    }

  }

  setTableHeight() {
    this.style.marginTop = '20px';
    this.style.width = "100%";
    this.style.height = (document.documentElement.clientHeight - 160) + "px";
  }

  private buildHeaders() {

    this.ColumnDefs = [

      {
        headerName: '',
        cellRenderer: "editDeleteActions",
        cellRendererParams: {
          onEditClick: this.onEditClick.bind(this),
          onDeleteClick: this.onDeleteClick.bind(this),
        },
        width: 60,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,

      },
      { headerName: 'First Name', field: 'firstName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      { headerName: 'Last Name', field: 'lastName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      { headerName: 'User Name', field: 'userName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      { headerName: 'Role', field: 'roleName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      { headerName: 'Phone No.', field: 'phoneNo', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true }
    


    ]
  }

  private getUsers() {
    this._userService.get()
      .subscribe(
        res => {
          this.usertableDataset = res;
        },
        err => {
          console.log(err);
        }
      )
  }

  onDeleteClick(e) {
    
    console.log(e.rowData);
    let row = e.rowData;
    this.deletemodalflag = true;
    this.userId = e.rowData.userId;
  }
  onEditClick(e) {
    console.log("update click");
    console.log(e.rowData);
    
    this.rowData = e.rowData;
    this.toastflag = false;
    this.userId = e.rowData.userId;
    this.form.setValue({
      firstName: e.rowData.firstName,
      lastName: e.rowData.lastName,
      userName: e.rowData.userName,
      phoneNo: e.rowData.phoneNo,
      role: e.rowData.roleId
    });
    this.userModal = true;
    this.userModalHeader = "Update User"
    this.btnIsSaveUser = false;
  }

  Delete() {
    this._userService.delete(this.userId)
      .subscribe(
        (res: any) => {
          if (res.message == "success") {
            this.deletemodalflag = false;
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "User has been deleted successfully.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          this.reset();
        },
        err => {
          this.reset();
        }
      )
  }

  reset() {
    this.showLoader = false;
    this.ISUpdate = false;
    this.userId = 0;
    //this.btnsubmittext = "ADD";
    this.form.reset();

    this.getUsers();

  }
}
