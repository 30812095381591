import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { HeaderService } from '../core/header/header.service';
import { DashBoardCountService } from '../dashborad-count/dashboard-count.service';
import { AuditAssignmentService } from '../modules/AuditAssignment/audit-assignment/audit-assignment.service';
import { MyLinkRendererComponent } from './table-link-render-component';
import { Location } from '@angular/common';
import { EditActionComponent } from '../reusable/components/ag-grid/edit-action/edit-action.component';
import { PreApprovalserviceService } from '../modules/pre-approval/pre-approvalservice.service';


@Component({
  selector: 'app-start-up',
  templateUrl: './start-up.component.html',
  styleUrls: ['./start-up.component.scss']
})
export class StartUpComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableHeight();
  }
  constructor(private _service: DashBoardCountService, private _preapprovalservice:PreApprovalserviceService, private _auditAssignmentService: AuditAssignmentService, private _headerService: HeaderService, private location: Location, private router: Router, private route: ActivatedRoute) { }

  //#region ag-grid_variables
  public selectedYear: any;
  public currentmonth:any;
  public lstStartUpdetails: any;
  modules = AllCommunityModules;
  public rowSelection: any = 'multiple';
  public lstYear: any = [];
  RPgridApi: any;
  APgridApi: any;
  KPgridApi: any;
  OTgridApi: any;
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };
  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };
  public btnsubmittext: string = "ADD";
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public RPStatusColumnDefs: any = [];
  public KPStatusColumnDefs: any = [];
  public APStatusColumnDefs: any = [];
  public OTStatusColumnDefs: any = [];
  public frameworkComponents: any;
  public RPStatusTableDataset: any;
  public APStatusTableDataset: any;
  public KPStatusTableDataset: any;
  public OTStatusTableDataset: any;
  public deletemodalflag: boolean = false;
  public actionAccess: any;
  public divFlag: boolean = false;
  public AddUpdateFlag: boolean = false;
  public EditParams: any = [];
  public auditassign: boolean = true;
  public advanceUpdate: boolean = true;
  public basicUpdate: boolean = true;
  public tableDataset: any;
  public ngSearchFilter: any;
  public lstFormStatus: any;

  ////#region toaster and Loader variables
  public toastflag: boolean = false;
  public toasttype: string;
  public toastmessage: string;
  public isMyBanks:boolean=false;
  ////#endregion

  //#endregion ag-grid_variables
  GetStartUP(year,selectedBank) {
    if(this.selectedBank=="allBanks")
    {
        this.isMyBanks=false;
        this._service.GenerateStartUp(year,this.isMyBanks)
        .subscribe(
         res => {

          this.lstStartUpdetails = res;
          this.RPStatusTableDataset = res["rpStatusTypes"];
          this.APStatusTableDataset = res["apStatusTypes"];
          this.KPStatusTableDataset = res["kpStatusTypes"];
          this.OTStatusTableDataset = res["otStatusTypes"];
          this.originaldataRPStatusTableDataset=this.RPStatusTableDataset;
          this.originaldataAPStatusTableDataset=this.APStatusTableDataset;
          this.originaldataKPStatusTableDataset=this.KPStatusTableDataset;
          this.originaldataOTStatusTableDataset=this.OTStatusTableDataset;
          this.buildHeaders();
          //this.resetFIlters();
        },
        err => {

          console.log(err);
        }
      )
    }
    else{
      this.isMyBanks=true;
      this._service.GenerateStartUp(year,this.isMyBanks).subscribe(
        res => {
          this.lstAuditData=res;
          console.log(this.lstAuditData);
          this.RPStatusTableDataset = res["rpStatusTypes"];
          this.APStatusTableDataset = res["apStatusTypes"];
          this.KPStatusTableDataset = res["kpStatusTypes"];
          this.OTStatusTableDataset = res["otStatusTypes"];
        })
    }

  }

  lstAuditData:any;
  originaldataRPStatusTableDataset:any;
  originaldataAPStatusTableDataset:any;
  originaldataKPStatusTableDataset:any;
  originaldataOTStatusTableDataset:any;


  pageloadflag: any = true;
  public filterdata: any;
  ////#region ad-grid methods
  RPhandleGridReady(params) {
    this.RPgridApi = params;
    this.RPgridApi.api.showLoadingOverlay()
    this.RPgridApi.api.hideOverlay();
  };
  ////#region ad-grid methods
  KPhandleGridReady(params) {
    this.KPgridApi = params;
    this.KPgridApi.api.showLoadingOverlay()
    this.RPgridApi.api.hideOverlay()
    //this.gridApi.api.sizeColumnsToFit()

  };
  ////#region ad-grid methods
  APhandleGridReady(params) {
    this.APgridApi = params;
    this.APgridApi.api.showLoadingOverlay()
    this.RPgridApi.api.hideOverlay()
    //this.gridApi.api.sizeColumnsToFit()

  };
  ////#region ad-grid methods
  OThandleGridReady(params) {
    this.OTgridApi = params;
    this.OTgridApi.api.showLoadingOverlay()
    this.RPgridApi.api.hideOverlay()

    //this.gridApi.api.sizeColumnsToFit()

  };

  setTableHeight() {
    this.style.marginTop = '20px';
    this.style.width = "100%";
    let height = document.documentElement.clientHeight;
    height = height - (height / 2) - (105);
    this.style.height = (height) + "px";
  }
  setDivHeight() {
    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }

  setFrameworkoptions() {
    this.frameworkComponents = {
      linkActions: MyLinkRendererComponent,
      editAction: EditActionComponent
    }
  }

  onFilterTextBoxChangedRP() {

    if (this.RPgridApi != null || this.RPgridApi != undefined)
      this.RPgridApi.api.setQuickFilter(this.ngSearchFilter);
  }


  onEditClick(e) {
    ;
    var editdata = e.rowData;
    if (editdata.formStatus == "Archive") {
      this.toasttype = "error";
      this.toastflag = true;
      this.toastmessage = "User Cannot Edit selected data as it was archived";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
    }
    else {
      var obj = {
        auditassignmentid: editdata.auditAssignmentId,
        flag: "update",
        newflag:"AUDupdate"
      }
      this._auditAssignmentService.setupdateData(obj);
      this.router.navigate(['/auditassignment']);
    }

  }

  private buildHeaders() {
   if(this.selectedYear>=2023){
    
    this.RPStatusColumnDefs = [

      {
        headerName: 'Bank Code', field: 'bankCode', sortable: true, filter: 'agTextColumnFilter' ,unSortIcon: true, autoHeight: true, resizable: true, width: 120,
        cellRenderer: "linkActions",
        cellRendererParams: {
          onClick: this.gotoAuditAssignment.bind(this),

        }
      },
      { headerName: 'Bank Short Name', sortable: true, filter: 'agTextColumnFilter',field: 'clientEntityShortName', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      { headerName: 'Fin. Audit Lead', sortable: true, field: 'financialAuditLead', unSortIcon: true, autoHeight: true, resizable: true, width: 110 },
      { headerName: 'EY Region', sortable: true, field: 'eyregionName', unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'IR Office', sortable: true, field: 'irOffice', unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'Client Contacts', sortable: true, filter: 'agTextColumnFilter',field: 'clientContact', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Engagement Partner', sortable: true, filter: 'agTextColumnFilter' ,field: 'regionalLeadPartner', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      //{ headerName: 'Engagement Manager', sortable: true, filter: 'agTextColumnFilter',field: 'assignedLeadAuditor', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Fin. Audit Co-Sign', sortable: true, filter: 'agTextColumnFilter',field: 'finAuditCoSign',  unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Internal Audit Eng. Leader', sortable: true, filter: 'agTextColumnFilter',field: 'internalAuditEngaLeader', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Status', field: 'status', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      {
        headerName: 'Audit Execution Start Date', field: 'auditExecutionStartDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution End Date', field: 'auditExecutionEndDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      
      {
        headerName: 'IA audit work completed', field: 'iaworkCompleted', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Decisive Date', field: 'decisiveDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Latest Sign-off Date', field: 'latestSignoffDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Actual Sign-off Date', field: 'actualSignoffDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        },
        cellStyle: params => {
          console.log(params);
          if (params.data.actualSignoffDate == null && params.data.status == 'EY-7 - Completed') {
            return { backgroundColor: 'red', color: 'white' };
          }
          else{
            return { backgroundColor: 'transperent', color: 'black' };
          }
        }
      },
      
    ]
      ;
  }
  else{
    this.RPStatusColumnDefs = [

      {
        headerName: 'Bank Code', field: 'bankCode', sortable: true, filter: 'agTextColumnFilter' ,unSortIcon: true, autoHeight: true, resizable: true, width: 120,
        cellRenderer: "linkActions",
        cellRendererParams: {
          onClick: this.gotoAuditAssignment.bind(this),

        }
      },
      { headerName: 'Bank Short Name', sortable: true, filter: 'agTextColumnFilter',field: 'clientEntityShortName', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      { headerName: 'Fin. Audit Lead', sortable: true, field: 'financialAuditLead', unSortIcon: true, autoHeight: true, resizable: true, width: 110 },
      { headerName: 'EY Region', sortable: true, field: 'eyregionName', unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'IR Office', sortable: true, field: 'irOffice', unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'Client Contacts', sortable: true, filter: 'agTextColumnFilter',field: 'clientContact', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Engagement Partner', sortable: true, filter: 'agTextColumnFilter' ,field: 'regionalLeadPartner', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      //{ headerName: 'Engagement Manager', sortable: true, filter: 'agTextColumnFilter',field: 'assignedLeadAuditor', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Fin. Audit Co-Sign', sortable: true, filter: 'agTextColumnFilter',field: 'finAuditCoSign',  unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Internal Audit Eng. Leader', sortable: true, filter: 'agTextColumnFilter',field: 'internalAuditEngaLeader', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Status', field: 'status', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      {
        headerName: 'Announcement delivery', field: 'announcementDelivery', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 100,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client data due', field: 'clientDataDue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 111,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution Start Date', field: 'auditExecutionStartDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution End Date', field: 'auditExecutionEndDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report', field: 'draftReport', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 101,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA audit work completed', field: 'iaworkCompleted', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA work paper review completed', field: 'iaworkPaperReviewDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report to client', field: 'draftReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client feedback due', field: 'clientFeedbackDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Final report to client due', field: 'finalReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      }




    ]
      ;
  }
    if (this.actionAccess.indexOf("Update(Basic)") != -1 || this.actionAccess.indexOf("Update(Advance)") != -1) {

      this.RPStatusColumnDefs.unshift({
        headerName: '',
        cellRenderer: "editAction",
        cellRendererParams: {
          onEditClick: this.onEditClick.bind(this),
          //onEditClick:this.gotoAuditAssignment.bind(this).then(this.onEditClick.bind(this)),
          Actions: this.actionAccess,
        },
        width: 60,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,

      });
    }

    this.KPStatusColumnDefs = [

      {
        headerName: 'Bank Code', field: 'bankCode', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 120,
        cellRenderer: "linkActions",
        cellRendererParams: {
          onClick: this.gotoAuditAssignment.bind(this),

        }
      },
      { headerName: 'Bank Short Name', field: 'clientEntityShortName', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      { headerName: 'Fin. Audit Lead', field: 'financialAuditLead', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 110 },
      { headerName: 'EY Region', field: 'eyregionName', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'IR Office', field: 'irOffice', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'Client Contacts', field: 'clientContact', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Engagement Partner', field: 'regionalLeadPartner', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      //{ headerName: 'Engagement Manager', field: 'assignedLeadAuditor', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'KP Engagement Manager', field: 'kpEngagementManager', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true},
      { headerName: 'Internal Audit Eng. Leader', field: 'internalAuditEngaLeader', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Status', field: 'status', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      {
        headerName: 'Announcement delivery', field: 'announcementDelivery', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 100,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client data due', field: 'clientDataDue', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 111,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution Start Date', field: 'auditExecutionStartDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution End Date', field: 'auditExecutionEndDate', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report', field: 'draftReport', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 101,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA audit work completed', field: 'iaworkCompleted', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA work paper review completed', field: 'iaworkPaperReviewDue', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report to client', field: 'draftReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client feedback due', field: 'clientFeedbackDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Final report to client due', field: 'finalReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      }




    ]

    if (this.actionAccess.indexOf("Update(Basic)") != -1 || this.actionAccess.indexOf("Update(Advance)") != -1) {
      this.KPStatusColumnDefs.unshift({
        headerName: '',
        cellRenderer: "editAction",
        cellRendererParams: {
          onEditClick: this.onEditClick.bind(this),
          Actions: this.actionAccess,

        },
        width: 60,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,

      });
    }

    this.APStatusColumnDefs = [

      {
        headerName: 'Bank Code', field: 'bankCode', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 120,
        cellRenderer: "linkActions",
        cellRendererParams: {
          onClick: this.gotoAuditAssignment.bind(this),

        }
      },
      { headerName: 'Bank Short Name', field: 'clientEntityShortName', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      { headerName: 'Reg. Audit Lead', field: 'financialAuditLead', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 110 },
      { headerName: 'EY Region', field: 'eyregionName', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'IR Office', field: 'irOffice', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'Client Contacts', field: 'clientContact', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'FINMA Lead Auditor', field: 'assignedLeadAuditor', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      //{ headerName: 'Engagement Manager', field: 'regionalLeadPartner', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Reg. Audit Co-Sign', field: 'regAuditCoSign', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true,autoHeight: true, resizable: true, width: 155  },

      { headerName: 'Internal Audit Eng. Leader', field: 'internalAuditEngaLeader', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },

      { headerName: 'Status', field: 'status', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      {
        headerName: 'Announcement delivery', field: 'announcementDelivery', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 100,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client data due', field: 'clientDataDue', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 111,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution Start Date', field: 'auditExecutionStartDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution End Date', field: 'auditExecutionEndDate', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report', field: 'draftReport', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 101,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA audit work completed', field: 'iaworkCompleted', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA work paper review completed', field: 'iaworkPaperReviewDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report to client', field: 'draftReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client feedback due', field: 'clientFeedbackDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Final report to client due', field: 'finalReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      }




    ]

    if (this.actionAccess.indexOf("Update(Basic)") != -1 || this.actionAccess.indexOf("Update(Advance)") != -1) {
      this.APStatusColumnDefs.unshift({

        headerName: '',
        cellRenderer: "editAction",
        cellRendererParams: {
          onEditClick: this.onEditClick.bind(this),
          Actions: this.actionAccess,
        },
        width: 60,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,

      });
    }

    this.OTStatusColumnDefs = [

      {
        headerName: 'Bank Code', field: 'bankCode', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 120,
        cellRenderer: "linkActions",
        cellRendererParams: {
          onClick: this.gotoAuditAssignment.bind(this),

        }
      },
      { headerName: 'Bank Short Name', field: 'clientEntityShortName', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      { headerName: 'Other Audit Lead', field: 'financialAuditLead', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 110 },
      { headerName: 'EY Region', field: 'eyregionName', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'IR Office', field: 'irOffice', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 90 },
      { headerName: 'Client Contacts', field: 'clientContact', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'OT Engagement Partner', field: 'regionalLeadPartner', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'OT Engagement Manager', field: 'assignedLeadAuditor', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true, width: 155 },
      { headerName: 'Status', field: 'status', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 148 },
      {
        headerName: 'Announcement delivery', field: 'announcementDelivery', sortable: true,filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true,
        width: 100,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client data due', field: 'clientDataDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 111,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution Start Date', field: 'auditExecutionStartDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")

        }
      },
      {
        headerName: 'Audit Execution End Date', field: 'auditExecutionEndDate', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report', field: 'draftReport', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 101,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA audit work completed', field: 'iaworkCompleted', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'IA work paper review completed', field: 'iaworkPaperReviewDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Draft report to client', field: 'draftReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Client feedback due', field: 'clientFeedbackDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      },
      {
        headerName: 'Final report to client due', field: 'finalReportToClientDue', sortable: true, filter: 'agTextColumnFilter',unSortIcon: true, autoHeight: true, resizable: true,
        width: 119,
        cellRenderer: (data) => {

          var celldate = moment(data.value);

          return data.value == null || data.value == "" ? "" : celldate.format("DD-MMM-YYYY")


        }
      }




    ]

    if (this.actionAccess.indexOf("Update(Basic)") != -1 || this.actionAccess.indexOf("Update(Advance)") != -1) {
      this.OTStatusColumnDefs.unshift({
        headerName: '',
        cellRenderer: "editAction",
        cellRendererParams: {

          onEditClick: this.onEditClick.bind(this),
          Actions: this.actionAccess,
        },
        width: 60,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,

      });
    }
  }
  GeneratYear() {
    var year = new Date().getFullYear();
    year = 2021;
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
      this.lstYear.push(year + i);
    }
  }
  public eyRegionMasterData: any;
  public irRBofficeMasterData: any;
  GetMastersData() {


    this._service.getMastersData()
      .subscribe(
        res => {
          console.log(res);

          this.eyRegionMasterData = res[0];
          this.irRBofficeMasterData = res[1];


        },
        err => {

          console.log(err);
        }
      )

  }

  public RoleBasedRegion: any;
  GetRoleBasedRegion() {

    this._auditAssignmentService.GetRoleBasedRegion()
      .subscribe(
        res => {

          this.RoleBasedRegion = res;


        },
        err => {

          console.log(err);
        }
      )

  }

  GetFormStatusData() {

    this._auditAssignmentService.GetFormStatusData()
      .subscribe(
        res => {
          this.lstFormStatus = res;
        },
        err => {

          console.log(err);
        }
      )

  }


  getRegionData() {
    this._auditAssignmentService.getRegionData()
      .subscribe(
        res => {
          console.log(res);

          this.eyRegionMasterData = res[0];
          this.irRBofficeMasterData = res[1];



        },
        err => {

          console.log(err);
        }
      )

  }

  ngOnInit(): void {

    let redirecturl = localStorage.getItem('url');
    localStorage.removeItem("url");
    if ((redirecturl != null && redirecturl != "") && redirecturl?.indexOf("id") != -1 && (redirecturl?.indexOf("directory") != -1 ||
      redirecturl?.indexOf("clientEntity") != -1 || redirecturl?.indexOf("auditassignment") != -1 || redirecturl?.indexOf("preapproval") != -1)) {
      var params = redirecturl.split("id=");
      if (redirecturl?.indexOf("directory") != -1) {

        this.router.navigate(['/directory'], { queryParams: { id: params[1] } })
      } else if (redirecturl?.indexOf("clientEntity") != -1) {
          this.router.navigate(['/clientEntity'], { queryParams: { id: params[1] } })
        }
        else if (redirecturl?.indexOf("auditassignment") != -1) {
          let idparams = params[1].split("&flag=");
          var id=this._headerService.decryptUsingAES256(idparams[0])
          var objauditassignment = {
            auditassignmentid: id,
            flag: idparams[1],
          }
          this._auditAssignmentService.setupdateData(objauditassignment);

          this.router.navigate(['/auditassignment'], { queryParams: { id: idparams[0] } })
        }
        else if (redirecturl?.indexOf("preapproval") != -1) {
          let idparams = params[1]?.split("</a>");
          var id=this._headerService.decryptUsingAES256(idparams[0])
          var objpreapproval = {
            preapprovalid: id,
          }
          this._preapprovalservice.setupdateData(objpreapproval);

          this.router.navigate(['/preapproval'], { queryParams: { id: idparams[0] } })
        }


    }
    else {

      var yearData = this._auditAssignmentService.getData();
      if (yearData["AuditYear"] != ""){
        this.selectedYear = yearData["AuditYear"];
      }
      else{
        this.currentmonth=new Date().getMonth();
          this.selectedYear = new Date().getFullYear();
      }
      let objyear = {
        bankcode: "",
        AuditYear: ""
      }
      this._auditAssignmentService.setData(objyear);

      this._headerService.getActions("Audit Assignment").then((value) => {
        ;
        this.actionAccess = value;
        this.divFlag = true;
        this.buildHeaders();
      })
      // this.HolidayList();
      this.GetMastersData();
      this.setTableHeight();
      this.setFrameworkoptions();
      this.GeneratYear();
       if (this.currentmonth<=2){
        this.selectedYear=this.selectedYear-1;
          this.GetStartUP(this.selectedYear,this.selectedBank);
       }
       else{
          this.GetStartUP(this.selectedYear,this.selectedBank);
       }
      var validdata = this._auditAssignmentService.getupdateData();
      if (validdata.flag == "update") {
        this.toasttype = "success";
        this.toastflag = true;
        this.toastmessage = "Audit Assignment has been updated successfully.";
        setTimeout(() => {
          this.toastflag=false;
         }, 3000);
      }

      var obj = {
        auditassignmentid: null,
        flag: null,
        newflag:null
      }
      this._auditAssignmentService.setupdateData(obj);
      // this.resetFIlters();
    }


  }
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  isExternalFilterPresent() {
    return searchLead.length != 0 || searchRegion.length != 0 || searchIRoffice != "";
  }
  externalIROfficeFilterChanged(newValue) {

    searchIRoffice = "";
    searchIRoffice = newValue;
    this.RPgridApi.api.onFilterChanged();
    this.KPgridApi.api.onFilterChanged();
    this.APgridApi.api.onFilterChanged();
    this.OTgridApi.api.onFilterChanged();

  }
  externalLeadFilterChanged(newValue) {

    searchLead = [];
    searchLead.push(newValue);
    this.RPgridApi.api.onFilterChanged();
    this.KPgridApi.api.onFilterChanged();
    this.APgridApi.api.onFilterChanged();
    this.OTgridApi.api.onFilterChanged();

  }
  externalRegionFilterChanged(newValue) {

    let data = this.eyRegionMasterData.find(ob => ob.eyregionId === newValue);

    searchRegion = [];
    searchRegion.push(data.eyregionName);
    this.RPgridApi.api.onFilterChanged();
    this.KPgridApi.api.onFilterChanged();
    this.APgridApi.api.onFilterChanged();
    this.OTgridApi.api.onFilterChanged();

  }

  doesExternalFilterPass(node) {

    let LeadFlag = false;
    let RegionFlag = false;
    let IROfficeFlag = false;
    if (searchRegion.length != 0 && searchLead.length != 0 && searchIRoffice != "") {
      searchRegion.forEach(element => {

        searchLead.forEach(functionelement => {

          if (node.data.eyregionName == element && node.data.financialAuditLead == functionelement && node.data.irOffice == searchIRoffice)
            return RegionFlag = true;
        });
        if (RegionFlag)
          return RegionFlag
      });
      if (RegionFlag)
        return true;
      else
        return false;
    }
    if (searchRegion.length != 0 && searchLead.length != 0) {
      searchRegion.forEach(element => {

        searchLead.forEach(functionelement => {

          if (node.data.eyregionName == element && node.data.financialAuditLead == functionelement)
            return RegionFlag = true;
        });
        if (RegionFlag)
          return RegionFlag
      });
      if (RegionFlag)
        return true;
      else
        return false;
    }
    if (searchRegion.length != 0 && searchIRoffice != "") {
      searchRegion.forEach(element => {

        if (node.data.eyregionName == element && node.data.irOffice == searchIRoffice)
          return RegionFlag = true;
      });
      if (RegionFlag)
        return true;
      else
        return false;
    }
    if (searchLead.length != 0 && searchIRoffice != "") {
      searchLead.forEach(element => {

        if (node.data.financialAuditLead == element && node.data.irOffice == searchIRoffice)
          return LeadFlag = true;
      });
      if (LeadFlag)
        return true;
      else
        return false;
    }
    if (searchRegion.length != 0) {
      searchRegion.forEach(element => {

        if (node.data.eyregionName == element)
          return RegionFlag = true;
      });
      if (RegionFlag)
        return true;
      else
        return false;
    }
    if (searchLead.length != 0) {
      searchLead.forEach(element => {

        if (node.data.financialAuditLead == element)
          return LeadFlag = true;
      });
      if (LeadFlag)
        return true;
      else
        return false;
    }
    if (searchIRoffice != "") {
      if (node.data.irOffice == searchIRoffice)
        return true;
      else
        return false;
    }
    return true;
  }
  public NgSearchFlag: boolean = true;
  public RegionSelected: any;
  public selectedLead: any;
  public irofficeSelected: any;
  public selectedBank="allBanks";
  
  resetFIlters() {
    this.RegionSelected = null;
    this.selectedLead = null;
    this.irofficeSelected = null;
    this.selectedBank="allBanks";
    this.isMyBanks=false;
    this.selectedYear=this.selectedYear;
    this.GetStartUP(this.selectedYear,this.selectedBank);
    
    this.NgSearchFlag = false;
    searchLead = [], searchRegion = [], searchIRoffice = "";
    this.RPStatusTableDataset = this.originaldataRPStatusTableDataset;
    this.APStatusTableDataset = this.originaldataAPStatusTableDataset;
    this.KPStatusTableDataset = this.originaldataKPStatusTableDataset;
    this.OTStatusTableDataset = this.originaldataOTStatusTableDataset;
    
    setTimeout(() => {
      this.NgSearchFlag = true;
    }, 50);
    this.RPgridApi.api.onFilterChanged();
    this.KPgridApi.api.onFilterChanged();
    this.APgridApi.api.onFilterChanged();
    this.OTgridApi.api.onFilterChanged();
    this.RPgridApi.api.setFilterModel(null);
    this.KPgridApi.api.setFilterModel(null);
    this.APgridApi.api.setFilterModel(null);
    this.OTgridApi.api.setFilterModel(null);

  }

  table1Element: boolean = false;
  table2Element: boolean = false;
  table3Element: boolean = false;
  table4Element: boolean = false;
  tableSizeO: boolean = false;

  table1() {
    this.table1Element = true;
    this.table2Element = false;
    this.table3Element = false;
    this.table4Element = false;
    this.style.height = (document.documentElement.clientHeight - 180) + "px";
    this.tableSizeO = true;
  }
  table2() {
    this.table1Element = false;
    this.table2Element = true;
    this.table3Element = false;
    this.table4Element = false;
    this.style.height = (document.documentElement.clientHeight - 180) + "px";
    this.tableSizeO = true;
  }
  table3() {
    this.table1Element = false;
    this.table2Element = false;
    this.table3Element = true;
    this.table4Element = false;
    this.style.height = (document.documentElement.clientHeight - 180) + "px";
    this.tableSizeO = true;
  }
  table4() {
    this.table1Element = false;
    this.table2Element = false;
    this.table3Element = false;
    this.table4Element = true;
    this.style.height = (document.documentElement.clientHeight - 180) + "px";
    this.tableSizeO = true;
  }
  table1Close() {
    this.table1Element = false;
    this.tableSizeO = false;
    this.style.height = (document.documentElement.clientHeight - 480) + "px";
  }
  table2Close() {
    this.table2Element = false;
    this.tableSizeO = false;
    this.style.height = (document.documentElement.clientHeight - 480) + "px";
  }
  table3Close() {
    this.table3Element = false;
    this.tableSizeO = false;
    this.style.height = (document.documentElement.clientHeight - 480) + "px";
  }
  table4Close() {
    this.table4Element = false;
    this.tableSizeO = false;
    this.style.height = (document.documentElement.clientHeight - 480) + "px";
  }



  getContextMenuItems(params) {
    var result = [
      {
        // custom item
        name: 'Increase Font',
        action: function () {
          /*window.alert('Alerting about ' + params.value);*/
          console.log(params)
          params.context.a = params.context.a + 5;
          params.context.refreshCells();
        },

      },
      {
        // custom item
        name: 'Decrease Font',
        action: function () {
          /*window.alert('Alerting about ' + params.value);*/
          console.log(params)
          params.context.a = params.context.a - 5;
          params.context.refreshCells();
        },

      }
    ];

    return result;
  }

  ////#endregion
  /////////////example//////////////////////

  // Easter(Y) {
  //   var C = Math.floor(Y/100);
  //   var N = Y - 19*Math.floor(Y/19);
  //   var K = Math.floor((C - 17)/25);
  //   var I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
  //   I = I - 30*Math.floor((I/30));
  //   I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
  //   var J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
  //   J = J - 7*Math.floor(J/7);
  //   var L = I - J;
  //   var M = 3 + Math.floor((L + 40)/44);
  //   var D = L + 28 - 31*Math.floor(M/4);

  //   return this.padout(M) + '.' + this.padout(D);
  // }

  // padout(number) { return (number < 10) ? '0' + number : number; }

  // getMM(string)  { return eval(string.substring(0,2)); }

  // getDD(string)  { return eval(string.substring(3,5)); }

  // makeArray()    {
  //   this[0] = this.makeArray.arguments.length;
  //   for (let i = 0; i<this.makeArray.arguments.length; i++)
  //       this[i+1] = this.makeArray.arguments[i];
  // }

  // //public accumulate:any    = this.makeArray(  0, 31, 59, 90,120,151,181,212,243,273,304,334);
  // //public accumulateLY:any  = this.makeArray(  0, 31, 60, 91,121,152,182,213,244,274,305,335);

  // public accumulate:any    =[12, 0, 31, 59, 90,120,151,181,212,243,273,304,334];
  // public accumulateLY:any  = [12, 0, 31, 60, 91,121,152,182,213,244,274,305,335];

  // LeapYear(year) {
  //   if ((year/4)   != Math.floor(year/4))   return false;
  //   if ((year/100) != Math.floor(year/100)) return true;
  //   if ((year/400) != Math.floor(year/400)) return false;
  //   return true;
  // }

  // daysinyear(year) { if (this.LeapYear(year)) return 366; else return 365; }

  //  addDays(day,month,year,addition) {
  //   if (this.LeapYear(year)) var number = day + this.accumulateLY[month] + addition;
  //   else                var number = day + this.accumulate[month]   + addition;

  //   var days = this.daysinyear(year);

  //   while (number > days) {
  //       number -= days;
  //       days = this.daysinyear(++year);
  //   }

  //   while (number < 1) {
  //       days = this.daysinyear(--year);
  //       number += days;
  //   }

  //   month = 1;

  //   if (this.LeapYear(year)) {
  //       while (number > this.accumulateLY[month]) { month++; }
  //       day = number - this.accumulateLY[--month];
  //   }
  //   else {
  //       while (number > this.accumulate[month]) { month++; }
  //       day = number - this.accumulate[--month];
  //   }

  //   return year + '.' + this.padout(month) + '.' + this.padout(day);
  // }

  // getYYYY(string) {
  //   return eval(string.substring(0,string.indexOf('.')));
  // }

  // getMMDD(string) {
  //   return string.substring(string.indexOf('.')+1,string.length);
  // }

  // public  daysofweek :any  = [7,'Sunday',
  //                                'Monday',
  //                                'Tuesday',
  //                                'Wednesday',
  //                                'Thursday',
  //                                'Friday',
  //                                'Saturday'];

  // public monthsofyear:any = [12,'January',
  //                                'February',
  //                                'March',
  //                                'April',
  //                                'May',
  //                                'June',
  //                                'July',
  //                                'August',
  //                                'September',
  //                                'October',
  //                                'November',
  //                                'December'];

  //  DayOfWeek(day,month,year) {
  //   var a = Math.floor((14 - month)/12);
  //   var y = year - a;
  //   var m = month + 12*a - 2;
  //   var d = (day + y + Math.floor(y/4) - Math.floor(y/100) + Math.floor(y/400) + Math.floor((31*m)/12)) % 7;
  //   return d+1;
  // }

  //  Nths(day) { 
  //   if (day == 1 || day == 21 || day == 31) return 'st';
  //   if (day == 2 || day == 22) return 'nd';
  //   if (day == 3 || day == 23) return 'rd';
  //   return 'th';
  // }

  //  FullDate(day,month,year) {
  //   return this.daysofweek[this.DayOfWeek(day,month,year)] +' '+ day + this.Nths(day) +' '+ this.monthsofyear[month] +' '+ year;
  // }

  // public year =2024
  // public holidaydates:any=[];

  // public easter = this.Easter(this.year);
  // public easterDay = this.getDD(this.easter);
  // public easterMonth = this.getMM(this.easter);
  // HolidayList(){
  //   
  // let when = this.addDays(this.easterDay,this.easterMonth,this.year,-63);
  // console.log('Septuagesima Sunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));


  // // this.when = this.addDays(this.easterDay,this.easterMonth,this.year,-56);
  // // document.write('Sexagesima Sunday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  // when = this.addDays(this.easterDay,this.easterMonth,this.year,-56);
  // console.log('Sexagesima Sunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));


  // // var when = addDays(easterDay,easterMonth,year,-49);
  // // document.write('Shrove Sunday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  // when = this.addDays(this.easterDay,this.easterMonth,this.year,-49);
  // console.log('Shrove Sunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));


  // // var when = addDays(easterDay,easterMonth,year,-47);
  // // document.write('Shrove Tuesday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,-47);
  // console.log('Shrove Tuesday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,-46);
  // // document.write('Ash Wednesday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,-46);
  // console.log('Ash Wednesday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,-14);
  // // document.write('Passion Sunday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,-14);
  // console.log('Passion Sunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,-7);
  // // document.write('Palm Sunday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,-7);
  // console.log('Palm Sunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,-3);
  // // document.write('Holy or Maundy Thursday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,-3);
  // console.log('Holy or Maundy Thursday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,-2);
  // // document.write('Good Friday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,-2);
  // console.log('Good Friday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));
  // console.log('Easter Sunday -' + this.FullDate(this.easterDay,this.easterMonth,this.year));
  // // document.write('Easter Sunday - ' + FullDate(easterDay,easterMonth,year)+'<BR>');
  // // var when = addDays(easterDay,easterMonth,year,1);
  // // document.write('Easter Monday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,1);
  // console.log('Easter Monday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,35);
  // // document.write('Rogation Sunday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,35);
  // console.log('Rogation Sunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,39);
  // // document.write('Ascension Day - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,39);
  // console.log('Ascension Day - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,49);
  // // document.write('Pentecost or Whitsunday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');

  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,49);
  // console.log('Pentecost or Whitsunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));



  // // var when = addDays(easterDay,easterMonth,year,50);
  // // document.write('Whitmundy - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,50);
  // console.log('Whitmundy - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,56);
  // // document.write('Trinity Sunday - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,56);
  // console.log('Trinity Sunday - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,60);
  // // document.write('Corpus Christi - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,60);
  // console.log('Corpus Christi - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  // // var when = addDays(easterDay,easterMonth,year,63);
  // // document.write('Corpus Christi (Catholic Church in the United States) - ' + FullDate(getDD(getMMDD(when)),getMM(getMMDD(when)),getYYYY(when))+'<BR>');
  //  when = this.addDays(this.easterDay,this.easterMonth,this.year,63);
  // console.log('Corpus Christi (Catholic Church in the United States) - ' + this.FullDate(this.getDD(this.getMMDD(when)),this.getMM(this.getMMDD(when)),this.getYYYY(when)));

  //}






  // gotoAuditAssignment(bankcode) {
  //   ;
  //   var obj = {
  //     bankcode: bankcode,
  //     AuditYear: this.selectedYear,
  //     // update:true,
  //   }
  //   this._auditAssignmentService.setData(obj);
  //   this.router.navigate(['/auditassignment'])
  // }
  gotoAuditAssignment(e) {
    ;
    var editdata = e.rowData;
    var obj = {
      auditassignmentid: editdata.auditAssignmentId,
      flag: "view",
      newflag:""
    }
    this._auditAssignmentService.setupdateData(obj);
    this.router.navigate(['/auditassignment']);
  }

}
var searchRegion = [], searchLead = [], searchIRoffice = "";