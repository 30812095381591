import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { HomeComponent } from './layouts/home/home.component';
import { ClientMasterComponent } from './modules/clientEntity/components/client-master/client-master.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ButtonRendererComponent } from '../app/modules/clientEntity/components/client-master/button-renderer-component';
import { MotifModule, MotifAccordionModule } from '@ey-xd/ng-motif';
import { MotifCssVariablePonyfillModule } from '@ey-xd/ng-motif';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from "@ag-grid-community/angular";
import { ClientEntityTableActionsComponent } from './modules/clientEntity/TableActions/client-entity-table-actions/client-entity-table-actions.component';
import { LoginComponent } from './core/login/login.component';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { LocationComponent } from './modules/Masters/location/location.component';
import { CantonComponent } from './modules/Masters/canton/canton.component';
import { EYOfficeComponent } from './modules/Masters/eyoffice/eyoffice.component';
import { EYRegionComponent } from './modules/Masters/eyregion/eyregion.component';
import { IRRBOfficeComponent } from './modules/Masters/irrboffice/irrboffice.component';
import { MomentPipePipe } from './reusable/Pipes/moment-pipe.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AgdatetimepickerComponent } from './reusable/components/ag-grid/date-time-picker/agdatetimepicker.component';
import { AuditchnageslistComponent } from './modules/clientEntity/TableActions/auditchnageslist/auditchnageslist.component';
import { UserMangementComponent } from './modules/Admin/UserManagement/user-mangement/user-mangement.component';
import { DirectoryMasterComponent } from './modules/directory/directory-master/directory-master.component';
import { AuditDirectoryMasterComponent } from './modules/directory/AuditDirectory/audit-directory-master/audit-directory-master.component';
import { DiectoryauditlistComponent } from './modules/directory/tableactions/diectoryauditlist/diectoryauditlist.component';
import { RemoveCommaPipe } from './reusable/Pipes/remove-comma.pipe';
import { RoleManagementComponent } from './modules/Admin/role-management/role-management.component';
import { LoaderComponent } from './core/loader/loader.component';
import { EditDeleteActionsComponent } from './reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { DirectoryTableActionsComponent } from './modules/directory/tableactions/directory-edit-delete-actions.component';
//import { PreapprovalTableActionsComponent } from './modules/Masters/Pre-Approval/TableActions/preapproval-edit-delete-actions.component';
import { UnauthorizedAccessComponent } from './reusable/components/unauthorizedAccess/unauthorized-access/unauthorized-access.component';
import { AuditAssignmentComponent } from './modules/AuditAssignment/audit-assignment/audit-assignment.component';
import { AddUpdateAuditAssignmentComponent } from './modules/AuditAssignment/add-update-audit-assignment/add-update-audit-assignment.component';
import { AuthGuard } from './core/helpers/auth.guard';
import { JwtHelperService, JwtModule, JwtModuleOptions, JWT_OPTIONS } from '@auth0/angular-jwt';
import { LandingComponent } from './reusable/components/landing/landing.component';
import { AuditAssignemntTableActionsComponent } from './modules/AuditAssignment/audit-assignment-table-actions/audit-assignment-table-actions.component';
import { AuditassignmentchangeslogComponent } from './modules/AuditAssignment/auditassignmentchangeslog/auditassignmentchangeslog.component';
import { AuditassignmenttypechangeslogComponent } from './modules/AuditAssignment/auditassignmenttypechangeslog/auditassignmenttypechangeslog.component';

import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { PhoneDirective } from './reusable/Directives/phone-number-directive';
import { DropdownModule } from 'primeng/dropdown';
import { DashboradCountComponent } from './dashborad-count/dashborad-count.component';
import { ContactTypeComponent } from './modules/Masters/contact-type/contact-type.component';
import { ClientEntityFormStatusComponent } from './modules/masters/client-entity-form-status/client-entity-form-status.component';
import { AuditTypeStatusComponent } from './modules/masters/audit-type-status/audit-type-status.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HolidayComponent } from './modules/Masters/holiday/holiday.component';
import { PlanningDatesConfigurationComponent } from './modules/masters/planning-dates-configuration/planning-dates-configuration.component';
import { StartUpComponent } from './start-up/start-up.component';
import { HolidayTableComponent } from './modules/Masters/holiday-table/holiday-table.component';
import { HolidayEditDeleteActionsComponent } from './modules/Masters/holiday-table/holiday-tableactions';
import { CacheInterceptor } from './core/helpers/http-interceptors';
import { DisabledAuditAssignmentComponent } from './disabled-audit-assignment/disabled-audit-assignment.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AuditTypeStatusApComponent } from './modules/Masters/audit-type-status-ap/audit-type-status-ap.component';
import { AuditTypeStatusKpComponent } from './modules/Masters/audit-type-status-kp/audit-type-status-kp.component';
import { AuditTypeStatusRpComponent } from './modules/Masters/audit-type-status-rp/audit-type-status-rp.component';
import { AuditTypeStatusOtComponent } from './modules/Masters/audit-type-status-ot/audit-type-status-ot.component';
import { FunctionComponent } from './modules/Masters/function/function.component';
import { RankComponent } from './modules/Masters/rank/rank.component'
import { AdalService } from "adal-angular4";
import { ArchiveAuditAssignmentComponent } from './modules/AuditAssignment/archive-audit-assignment/archive-audit-assignment.component';
import { NotificationComponent } from './modules/Masters/notification/notification.component';
import { NotificationAlterComponent } from './modules/Masters/notification-alter/notification-alter.component';
import { MotifFormsModule } from '@ey-xd/ng-motif';
import { QuillModule } from 'ngx-quill';
import { EmailConfigurationForAuditTypeStatusComponent } from './modules/Masters/EmailConfiguration/email-configuration-for-audit-type-status/email-configuration-for-audit-type-status.component';
import { AddUpdateEmailConfigurationComponent } from './modules/Masters/EmailConfiguration/add-update-email-configuration/add-update-email-configuration.component';
import { EmailConfigurationForDueDatesComponent } from './modules/Masters/EmailConfigurationForDueDates/email-configuration-for-due-dates/email-configuration-for-due-dates.component';
import { AddUpdateEmailConfigurationForDueDatesComponent } from './modules/Masters/EmailConfigurationForDueDates/add-update-email-configuration-for-due-dates/add-update-email-configuration-for-due-dates.component';
import { GannchartTrackerViewComponent } from './modules/Masters/gannchart-tracker-view/gannchart-tracker-view.component';
// import { NgGanttEditorModule } from 'ng-gantt';
import { EditActionComponent } from './reusable/components/ag-grid/edit-action/edit-action.component';
import { AGGridGanttChartComponent } from './modules/Masters/AG-Grid-GanttChart/AG-Grid-GanttChart.component';
import { DatePipe,LowerCasePipe } from '@angular/common';
import { CellRendorsComponent } from './modules/Masters/AG-Grid-GanttChart/Cell-Rendors/Cell-Rendors/Cell-Rendors.component';
import { MeetingFormatComponent } from './modules/Masters/meeting-format/meeting-format.component';
import { PreApprovalEmailConfigurationComponent } from './modules/Masters/PreApprovalEmailConfiguration/pre-approval-email-configuration/pre-approval-email-configuration.component';
import { AddUpdatePreApprovalEmailConfigurationComponent } from './modules/Masters/PreApprovalEmailConfiguration/add-update-pre-approval-email-configuration/add-update-pre-approval-email-configuration.component';
import { PreApprovalComponent } from './modules/pre-approval/pre-approval.component';
import { AuditPreApprovalComponent } from './modules/pre-approval/audit-pre-approval/audit-pre-approval.component';
import { PreapprovalTableActionsComponent } from './modules/pre-approval/TableActions/preapproval-edit-delete-actions.component';
import { PreapprovalauditlistComponent } from './modules/pre-approval/TableActions/preapprovalauditlist/preapprovalauditlist.component';
// import { EmailConfigurationForAuditTypeStatusComponent } from './modules/Masters/email-configuration-for-audit-type-status/email-configuration-for-audit-type-status.component';
// import { AgGridButtonRendererComponent } from './modules/Masters/email-configuration-for-audit-type-status/ag-grid-button-renderer/ag-grid-button-renderer.component';
// import { AddUpdateEmailConfigurationComponent } from './modules/masters/email-configuration-for-audit-type-status/add-update-email-configuration/add-update-email-configuration.component'
//import { EmailConfigurationForAuditTypeStatusService } from './modules/Masters/email-configuration-for-audit-type-status/email-configuration-for-audit-type-status.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { EntityTypeComponent } from './modules/Masters/entity-type/entity-type.component';
import { ServiceStatusComponent } from './modules/Masters/service-status/service-status.component';
import { ApprovalStatusComponent } from './modules/Masters/approval-status/approval-status.component';
import { ApprovalLevelComponent } from './modules/Masters/approval-level/approval-level.component';
import { SwissNumberFormatDirective } from './reusable/Directives/number-format-directive';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ClientMasterComponent,
    ButtonRendererComponent,
    ClientEntityTableActionsComponent, LoginComponent, LocationComponent, EditDeleteActionsComponent, HolidayEditDeleteActionsComponent, CantonComponent, EYOfficeComponent, EYRegionComponent, IRRBOfficeComponent, MomentPipePipe, AgdatetimepickerComponent, AuditchnageslistComponent
    , UserMangementComponent,
    DirectoryMasterComponent,
    AuditDirectoryMasterComponent,
    DiectoryauditlistComponent,
    RemoveCommaPipe,
    RoleManagementComponent,
    LoaderComponent,
    DirectoryTableActionsComponent,
    PreapprovalTableActionsComponent,
    UnauthorizedAccessComponent,
    AuditAssignmentComponent,
    AddUpdateAuditAssignmentComponent,
    AuditAssignemntTableActionsComponent,
    LandingComponent,
    AuditassignmentchangeslogComponent,
    AuditassignmenttypechangeslogComponent,
    PhoneDirective,
    DashboradCountComponent,
    ContactTypeComponent,
    ClientEntityFormStatusComponent,
    AuditTypeStatusComponent,
    HolidayComponent,
    PlanningDatesConfigurationComponent,
    StartUpComponent, HolidayTableComponent, DisabledAuditAssignmentComponent, AuditTypeStatusApComponent, AuditTypeStatusKpComponent, AuditTypeStatusRpComponent,
    AuditTypeStatusOtComponent, FunctionComponent, RankComponent, ArchiveAuditAssignmentComponent, NotificationComponent, NotificationAlterComponent,
    EmailConfigurationForAuditTypeStatusComponent, AddUpdateEmailConfigurationComponent, EmailConfigurationForDueDatesComponent,
    AddUpdateEmailConfigurationForDueDatesComponent, GannchartTrackerViewComponent, EditActionComponent, AGGridGanttChartComponent,
    CellRendorsComponent,
    MeetingFormatComponent,
    PreApprovalEmailConfigurationComponent,
    AddUpdatePreApprovalEmailConfigurationComponent,
     // EmailConfigurationForAuditTypeStatusComponent, AgGridButtonRendererComponent, AddUpdateEmailConfigurationComponent
    PreApprovalComponent,
    AuditPreApprovalComponent,
    PreapprovalauditlistComponent,
    EntityTypeComponent,
    ServiceStatusComponent,
    ApprovalLevelComponent,
    ApprovalStatusComponent,
    SwissNumberFormatDirective
    // EmailConfigurationForAuditTypeStatusComponent, AgGridButtonRendererComponent, AddUpdateEmailConfigurationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MotifModule,
    MotifCssVariablePonyfillModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DropdownModule,
    MultiSelectModule,
    MotifFormsModule,
    // NgGanttEditorModule,
    QuillModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts
    }),
    //JwtModule.forRoot(JWT_Module_Options),

    AgGridModule.withComponents([ClientEntityTableActionsComponent, DirectoryTableActionsComponent, PreapprovalTableActionsComponent, AuditAssignemntTableActionsComponent, EditDeleteActionsComponent, HolidayEditDeleteActionsComponent, AuditchnageslistComponent, AgdatetimepickerComponent, DiectoryauditlistComponent,
      // AgGridButtonRendererComponent
    ]),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    MotifAccordionModule,
    NgxMaterialTimepickerModule
    
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, DatePipe,LowerCasePipe,
  // EmailConfigurationForAuditTypeStatusService,
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    HeaderComponent,
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    AdalService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
