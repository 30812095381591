import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output ,ErrorHandler,ViewChild, HostListener} from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import {Location} from '@angular/common';
import { PreApprovalEmailConfigurationService } from '../pre-approval-email-configuration.service';

@Component({
  selector: 'app-add-update-pre-approval-email-configuration',
  templateUrl: './add-update-pre-approval-email-configuration.component.html',
  styleUrls: ['./add-update-pre-approval-email-configuration.component.scss']
})
export class AddUpdatePreApprovalEmailConfigurationComponent implements OnInit {

  @Output() BackEventEmitter = new EventEmitter<string>();

  public sendtolist=["EY Partner","EY Manager"];
  public sendcclist=["EY Partner","EY Manager"];
  public languagelist:any;
  public reportingLanguageName:any;
  public reportingLanguageId:any;
  isSelected:boolean=true;
  dummyshow:boolean=true;

  @ViewChild('editor') editor;
 
 public textShow:boolean=false;
 public AlreadySelected:boolean=false;
 public ServiceStatusList:any;
 public serviceTypeName:any;
 public serviceTypeId:any;
 public ApprovalStatusList:any;
 public approvalStatusName:any;
  public approvalStatusId:any;

 constructor(private router: Router,private _headerService : HeaderService,private _preEmailConfigservice:PreApprovalEmailConfigurationService ,
   private _formBuilder: FormBuilder,private location: Location) { }

    public loaderflag: boolean = false;

  //#region ag-grid_variables
  modules = AllCommunityModules;
  gridApi: any;
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };
  public btnsubmittext: string = "ADD";
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public ColumnDefs: any;
  public frameworkComponents: any;
  
  public tableDataset: any;
  public deletemodalflag: boolean = false;
//#endregion ag-grid_variables

form: FormGroup;
public EditID: any;
public DeletID: any;
public ISUpdate: boolean = false;
public showLoader: boolean = false;
public rowData: any;
public btnflag: boolean = true;
public Escmodalflag:boolean=false;


@Input() Params;
public editdata: any;

//#region  toast
public toasttype: string;
public toastflag: boolean;
public toastmessage: string;
//#endregion toast

////#region actions variables
public divFlag : boolean=false;
public styleDIV = {
marginTop: "",
width: "",
height: ""
};
public actionAccess:any;
////#endregion

  ngOnInit(): void {
    this.form = this.createForm();
    this.GetMastersData();
    this.getalllanguages(this.Params);
    this.setDivHeight();
    this._headerService.getActions("PreApproval EmailConfig").then((value) => {
      this.actionAccess=value;
      if(this.actionAccess.indexOf("View")!=-1){
        this.divFlag=true;
      }
      else
      this.router.navigate(['/unauthorizedaccess'])
    });
  }

  ngAfterViewInit(): void{
  }

  public EmailConfurationId: any;
  public multiSelectValues:any;
  public editorText:any;

  editdetails(data) {
    
    this.btnflag = false;
    this.dummyshow = false;
    this.AlreadySelected =true;
    this.EmailConfurationId = data.preApprovalEmailConfigurationId;
    let STIDS=data.sendTo;
    let sendTo=null; 
    let sendCc=null;
    let language=[];
    let additionalEmails=null;

    if(STIDS!=null){
      ;
      sendTo= STIDS.split(',');     
     for(var i=0; i<sendTo.length; i++) { sendTo[i] = sendTo[i]; } 
     }
     let SCCIDS=data.sendCc;
     ;
    if(SCCIDS!=null){
      sendCc= SCCIDS.split(',');     
     for(var i=0; i<sendCc.length; i++) { sendCc[i] = sendCc[i]; } 
     }
     let addtemails=data.additionalEmails;
     ;
    if(addtemails!=null){
      this.textShow=true;
      addtemails = data.additionalEmails.split(',').sort((a, b) => a - b).map(x=>x).join("\n");
    }
    else
    {
      this.textShow=false;
      additionalEmails=null;
    }
    
    let LangIDS = data.language;
    ;
    if (LangIDS != null) {
      language = LangIDS.split(',');
      for (var i = 0; i < language.length; i++) { language[i] = +language[i]; }
      this.languagelist.forEach(element => {      
        if (language.indexOf(element.reportingLanguageId) != -1)
          element.isChecked = true;
        else
          element.isChecked = false;
      });
  
    }
    else {
      language = null;
    }

    console.log(data);
    this.form.patchValue({
      servicestatus: data.serviceStatusID,
      approvalstatus:data.approvalStatusID,
      isenabled:data.isEnabled,
      sendto:sendTo,
      sendcc:sendCc,
      additionalemails:addtemails,
      language:language,
      emailsubject:data.emailSubject,
      emailbody:data.emailBody,
    })
  }

  createForm() {
    return this._formBuilder.group({
      servicestatus: [null, [Validators.required]],
      approvalstatus: [null, [Validators.required]],
      isenabled: [null],
      sendto: [null, [Validators.required]],
      sendcc: [null],
      additionalemails: [null],
      language: [null, [Validators.required]],
      emailsubject: [null, [Validators.required]],
      emailbody: [null, [Validators.required]],
    })
  }

  submit() {
    ;
    this.validateForm(this.form);
    if (this.form.valid) {
      this.showLoader = true;
      if (this.ISUpdate) {
        this.update();
      } else {
        this.Add();
      }
    }
    else{
      this.validateForm(this.form);
    }
  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  Add() {
    ;
    this.validateForm(this.form);
    if (this.form.valid) {
      this.showLoader = true;
    let ServiceStatusID= this.form.get("servicestatus").value;
    let ApprovalStatusID = this.form.get("approvalstatus").value;
    let isenabled = this.form.get("isenabled").value== true ? true : false;
    let sendto = this.form.value.sendto.sort((a, b) => a - b).map(x=>x).join(",");
    let sendcc = this.form.value.sendcc.sort((a, b) => a - b).map(x=>x).join(",");
    let additionalemails = this.form.get("additionalemails").value;
    if(additionalemails!=null){
      additionalemails = this.form.value.additionalemails.split('\n').sort((a, b) => a - b).map(x=>x).join(",");
    }
    else
    {
      additionalemails=null;
    }
  
    let language= this.form.value.language.sort((a, b) => a - b).map(x=>x).join(",");
    let emailsubject = this.form.get("emailsubject").value;
    let emailbody=this.form.value.emailbody;
    emailbody=btoa(unescape(encodeURIComponent(emailbody)));
    let addobj = {
      ServiceStatusID: ServiceStatusID,
      ApprovalStatusID: ApprovalStatusID,
      IsEnabled: isenabled,
      SendTo: sendto,
      SendCC: sendcc,
      AdditionalEmails: additionalemails,
      Language: language,
      EmailSubject: emailsubject,
      EmailBody: emailbody
    }
    ;

    this._preEmailConfigservice.Add(addobj)
      .subscribe(
        (res: any) => {
          if (res.message == "success") {
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Email Configuration has been added successfully.";
            this.reset();
            console.log(res);
            this.router.navigate(['/PreApprovalEmailConfiguration']);
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res.message == "EmailConfigurationExists") {
            this.showLoader = false;
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "Email Configuration already exist.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        },
        err => {
          if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){ 
            this.toasttype = "warning";
            this.toastflag = true;
            
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else{
          this.reset();
          }
        }
      )
    }
  }

  oncancel(status: string) {
    this.location.go('/PreApprovalEmailConfiguration/0');
    this.loaderflag = true;
    this.BackEventEmitter.emit(status);
       this.router.navigate(['/PreApprovalEmailConfiguration']);   
  }

  update() {
    ;
    this.validateForm(this.form);
    
    if (this.form.valid) {      this.showLoader = true;
    let ServiceStatusID= this.form.get("servicestatus").value;
    let ApprovalStatusID = this.form.get("approvalstatus").value;
    let isenabled = this.form.get("isenabled").value;
    let sendto = this.form.value.sendto.sort((a, b) => a - b).map(x=>x).join(",");
    let sendcc = this.form.value.sendcc.sort((a, b) => a - b).map(x=>x).join(",");
    let additionalemails = this.form.get("additionalemails").value;
    if(additionalemails!=null){
      additionalemails = this.form.value.additionalemails.split('\n').sort((a, b) => a - b).map(x=>x).join(",");
    }
    else
    {
      additionalemails=null;
    }
    let language=this.form.get("language").value;
    if (language != null) {
        language = this.form.value.language.sort((a, b) => a - b).map(x => x).join(",");
    }
    else {
        language = null;
    }
    let emailsubject = this.form.get("emailsubject").value;
    let emailbody=this.form.value.emailbody;
    emailbody=btoa(unescape(encodeURIComponent(emailbody)));
    let upobj = {
      PreApprovalEmailConfigurationId: this.EmailConfurationId,
      ServiceStatusID: ServiceStatusID,
      ApprovalStatusID: ApprovalStatusID,
      IsEnabled: isenabled,
      SendTo: sendto,
      SendCC: sendcc,
      AdditionalEmails: additionalemails,
      Language: language,
      EmailSubject: emailsubject,
      EmailBody: emailbody,
    }

    this._preEmailConfigservice.Update(upobj)
      .subscribe(
        (res: any) => {
          if (res.message == "PreApprovalExists") {
            this.showLoader = false;
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "Pre Approval Email Configuration already exists.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else
            if (res.message == "success") {
              this.reset();
              this.toasttype = "success";
              this.toastflag = true;
              this.oncancel("update");
              this.toastmessage = "Pre Approval Email Configuration has been updated successfully.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);

            } else
              if (res.message == "fail") {
                {
                  this.reset();
                  this.toasttype = "error";
                  this.toastflag = true;
                  this.toastmessage = "An unexpected error has occured. Please contact support team.";
                  setTimeout(() => {
                    this.toastflag=false;
                   }, 3000);
                }
              }
        },
        err => {
          
          if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
            this.toasttype = "warning";
            this.toastflag = true;
            
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else{
          this.reset();
          }
        } 
      )
    }
  }

  reset() {
    ;
    this.dummyshow=false;
    this.form.patchValue({
      sendto:null,
      sendcc:null,
    });
    this.showLoader = false;
    this.ISUpdate = false;
    this.EditID = null;
    this.DeletID = null;
    
    this.btnsubmittext = "ADD";
    this.form.reset();
  }

////#region Action check method
ActionsCheck(action){  
  if(this.actionAccess.indexOf(action)!=-1)
  return true;
  else
  return false;
}
setDivHeight() {
  this.styleDIV.width = "100%";
  this.styleDIV.height = (document.documentElement.clientHeight - 150) + "px";
}

public pageload: boolean = false;

  Addmail(){
    this.textShow = true;
    this.form.get('additionalemails').setValidators(Validators.required);
}

get additionalemails(): any { return this.form.get('additionalemails'); }

Removemail(){
  ;
  this.textShow = false;
  this.additionalemails.reset();
  this.form.get('additionalemails').clearValidators();
  this.form.get('additionalemails').updateValueAndValidity();
}
 
  ////#endregion
  public dataTable1=[{columnvalue:'[[Entity Name]]'},{columnvalue:'[[Department]]'},{columnvalue:'[[Entity Type]]'},{columnvalue:'[[EY Partner_LastName]]'},{columnvalue:'[[EY Partner_FirstName]]'}
  ,{columnvalue:'[[EY Manager_LastName]]'},{columnvalue:'[[EY Manager_FirstName]]'},{columnvalue:'[[Data Service Request]]'},{columnvalue:'[[Client Contact Name]]'},{columnvalue:'[[Client Contact Email]]'},
  {columnvalue:'[[Client Contact Phone]]'},{columnvalue:'[[Service Title]]'},{columnvalue:'[[Nature of Services]]'},{columnvalue:'[[Fund – related?]]'},
  {columnvalue:'[[Recurring service?]]'},{columnvalue:'[[Service description]]'},{columnvalue:'[[Service - Start Date]]'},{columnvalue:'[[Service - End Date]]'}
  ,{columnvalue:'[[Reporting requirements]]'},{columnvalue:'[[Total Hours]]'},{columnvalue:'[[Total Fee]]'},{columnvalue:'[[Additional comments/notes]]'}
  ,{columnvalue:'[[Fee confirmed with Client?]]'},{columnvalue:'[[Fee comments/notes]]'},{columnvalue:'[[Service Independence checked and confirmed?]]'},{columnvalue:'[[Service Status]]'}
,{columnvalue:'[[Approval Status]]'},{columnvalue:'[[Approval Number]]'},{columnvalue:'[[Approval request to Management]]'},{columnvalue:'[[Approval response from Management]]'}
,{columnvalue:'[[Approval Level]]'},{columnvalue:'[[Billing Code]]'},{columnvalue:'[[Client Entity Name]]'},{columnvalue:'[[Street]]'}
,{columnvalue:'[[Postal Code Location]]'},{columnvalue:'[[Billing comments/notes]]'},{myid:25,columnname:'Update Link',columnvalue:'[[Update Link]]'},{myid:26,columnname:'View Link',columnvalue:'[[View Link]]'}]

AddColData(i){
   ;
   console.log(this.dataTable1);
   console.log(i.dataTable1);
  const selection =this.editor.quillEditor.getSelection();
   this.editor.quillEditor.insertText(selection.index,this.dataTable1[i].columnvalue);
}

getalllanguages(data){
  ;
  this._preEmailConfigservice.GetMastersData().subscribe(res=>{
    console.log(res);
    this.languagelist=res[2];
    this.languagelist.forEach(element => {
      this.reportingLanguageName=element;
      this.reportingLanguageId=element;
      console.log(element);
    });
    console.log(this.languagelist);
    
          this.pageload = true;
          if (data != null) {
            if (data.length == undefined) {
              
              this.editdetails(data);
            }
          }
})
}

GetMastersData(){
  ;
  this._preEmailConfigservice.GetMastersData().subscribe(res=>{
    console.log(res);
    this.ServiceStatusList=res[0];
    this.ServiceStatusList.forEach(element => {
      this.serviceTypeName=element;
      this.serviceTypeId=element;
      console.log(element);
    });
    console.log(this.ServiceStatusList);

    this.ApprovalStatusList=res[1];
    this.ApprovalStatusList.forEach(element => {
      this.approvalStatusName=element;
      this.approvalStatusId=element;
      console.log(element);
    });
    console.log(this.ApprovalStatusList);
})
}

CLOSE(){
  ;
    this.router.navigate(['/PreApprovalEmailConfiguration']);
}


@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  debugger;
  let updateid = this._preEmailConfigservice.getupdateData();
  if(updateid.newflag == "AUDupdate"){
    this.Escmodalflag=true;
  }
  else{
    this.oncancel('cancel');
  }
}  

}

