import { Component, Inject, OnInit } from '@angular/core';
import { NotificationAlterService } from './notification-alter.service';
// import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
// import { MatDialog } from "@angular/material";


@Component({
  selector: 'app-notification-alter',
  templateUrl: './notification-alter.component.html',
  styleUrls: ['./notification-alter.component.scss']
})
export class NotificationAlterComponent implements OnInit {
  // public notifications: any = ["Client Entity-newly founded Raiffeisen Banks have been added on 12 jan 2022",
  //                              "Client Entity-changes on Raiffeisen Bank's names due to upcoming mergers have been applied already to be available for Budget Letter 2022 Processing-15 Feb 2022.",
  //                              "Direcory-IR Contact Data is upto date as of 10 Dec 2022.Report any changes to eych.raiffeisen.audit@ch.ey.com",
  //                              "Direcory-RB Contact Data is upto date as of 10 Dec 2022.Report any changes to eych.raiffeisen.audit@ch.ey.com-Next Review and update is scheduled for early March 2022 Prior to the regulatory audit 2022 start",
  //                              "Audit Assignment-Lead changes between EY and IR for four Banks have occured and are reflected in the data-15 March 2022"];
   notifications:any;
  showModal: boolean;
  constructor(
    // public dialogRef: MatDialogRef<NotificationAlterComponent>,@Inject(MAT_DIALOG_DATA) private InData: any
  // ,private dialog: MatDialog, 
  private _service: NotificationAlterService) { }

  ngOnInit(): void {
     this.getallNotifications();
  }


  getallNotifications(){
    this._service.getnotifications().subscribe(res=>{
      this.notifications=res;
      // this.geteachcity(this.id);
      console.log(res);

  })
}



  clearNotification(){
    this.notifications.reset();
  }

  // close(){
  //   this.dialogRef.close()
  // }
  showModel(){
    this.showModal = true;
  }



}
