import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-auditassignmentchangeslog',
  templateUrl: './auditassignmentchangeslog.component.html',
  styleUrls: ['./auditassignmentchangeslog.component.scss']
})
export class AuditassignmentchangeslogComponent implements ICellRendererAngularComp {
  public params: any;


  agInit(params: any): void {
    
    
    if (params.data.action == "Update") {

      if (params.colDef.headerName == "New Values") {
        this.params = params.data.newValue;
      }
      else if (params.colDef.headerName == "Old Values") {

        this.params = params.data.oldValue;
      }
    } else { this.params=null }
  }

  refresh(): boolean {
    return false;
  }
}
