<ul>
    <li *ngIf="params?.formTitle !=undefined"> Form Title : {{params.formTitle}}  </li>
    <li *ngIf="params?.Department !=undefined"> Department : {{params.Department}}  </li>
    <li *ngIf="params?.EntityTypeId !=undefined"> Entity Type : {{params.EntityTypeId}}  </li>
    <li *ngIf="params?.DateServiceRequest !=undefined"> DateServiceRequest : {{params?.DateServiceRequest| momentPipe :'LL'}}  </li>
    <li *ngIf="params?.EyPartnerId !=undefined"> Ey Partner : {{params.EyPartnerId}}  </li>
    <li *ngIf="params?.EyManagerId !=undefined"> Ey Manager : {{params.EyManagerId}}  </li>
    <li *ngIf="params?.ClientContactName !=undefined"> Client Contact Name : {{params.ClientContactName}}  </li>
    <li *ngIf="params?.ClientContactEmail !=undefined"> Client Contact Email : {{params?.ClientContactEmail}}  </li>                            
    <li *ngIf="params?.ClientContactPhone !=undefined"> Client Contact Phone : {{params.ClientContactPhone}}  </li>
    <li *ngIf="params?.ServiceTitle !=undefined"> Service Title : {{params.ServiceTitle}}  </li>
    <li *ngIf="params?.NatureOfServices !=undefined"> Nature Of Services : {{params.NatureOfServices}}  </li>
    <li *ngIf="params?.FundRelated !=undefined"> Fund Related : {{params.FundRelated}}  </li>
    <li *ngIf="params?.RecurringService !=undefined"> Recurring Service : {{params.RecurringService}}  </li>
    <li *ngIf="params?.ServiceDescription !=undefined"> Service Description : {{params.ServiceDescription}}  </li>
    <li *ngIf="params?.ServiceStartDate !=undefined"> Service StartDate : {{params?.ServiceStartDate| momentPipe :'LL'}}  </li>
    <li *ngIf="params?.ServiceEndDate !=undefined"> Service EndDate : {{params?.ServiceEndDate| momentPipe :'LL'}}  </li>
    <li *ngIf="params?.ReportingRequirements !=undefined"> Reporting Requirements : {{params.ReportingRequirements}}  </li>
    <li *ngIf="params?.AdditionalComments !=undefined"> Additional Comments : {{params.AdditionalComments}}  </li>
    <li *ngIf="params?.TotalHours !=undefined"> Total Hours : {{params.TotalHours}}  </li>
    <li *ngIf="params?.TotalFee !=undefined"> Total Fee : {{params.TotalFee}}  </li>
    <li *ngIf="params?.FeeConfirmed !=undefined"> Fee Confirmed : {{params.FeeConfirmed}}  </li>
    <li *ngIf="params?.FeeComments !=undefined"> Fee Comments : {{params.FeeComments}}  </li>
    <li *ngIf="params?.ServiceIndependenceChecked !=undefined"> Service Independence Checked : {{params.ServiceIndependenceChecked}}  </li>
    <li *ngIf="params?.ServiceStatusId !=undefined"> Service Status : {{params.ServiceStatusId}}  </li>
    <li *ngIf="params?.ApprovalStatusId !=undefined"> Approval Status : {{params.ApprovalStatusId}}  </li>
    <li *ngIf="params?.ApprovalNumber !=undefined"> Approval Number : {{params.ApprovalNumber}}  </li>
    <li *ngIf="params?.ApprovalRequestDate !=undefined"> Approval Request Date : {{params.ApprovalRequestDate| momentPipe :'LL'}}  </li>
    <li *ngIf="params?.ApprovalResponseDate !=undefined"> Approval Response Date : {{params.ApprovalResponseDate| momentPipe :'LL'}}  </li>                            
    <li *ngIf="params?.ApprovalLevelId !=undefined"> Approval Level : {{params.ApprovalLevelId}}  </li>                    
    <li *ngIf="params?.BillingCode !=undefined"> Billing Code : {{params.BillingCode}}  </li>
    <li *ngIf="params?.BillingComments !=undefined"> Billing Comments : {{ params?.BillingComments}}  </li>
    <li *ngIf="params?.ClientEntityId !=undefined"> Client Entity : {{params.ClientEntityId}}  </li>                          
</ul>