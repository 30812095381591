import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AuditDirectoryMasterService{   
   

    constructor(private _http: HttpClient) { }


    
  getAuditTrail() {
       
    return this._http.get(`${environment.apiUrl}/AuditDirectory/Get`);

      }





}
