import { Component, HostListener, OnInit } from '@angular/core';
import { RoleManagementService } from './role-management.service';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HeaderService } from 'src/app/core/header/header.service';
import { EditDeleteActionsComponent } from 'src/app/reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { Router } from '@angular/router';
import { DistinctSubscriber } from 'rxjs/internal/operators/distinct';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementComponent implements OnInit {z

  constructor(private router:Router,private _service: RoleManagementService, private _formBuilder: FormBuilder,private _headerService : HeaderService) { }
  CreateRoleModelShow = false;
  UpdateRoleModelShow =false;
  AddScreenActions: any;
   UpdateScreenActions:any;
  roledescription = new FormControl();
  toasttype: string;
  toastflag: boolean = false;
  toastmessage: string;
  UpdateRoleID:number;
 deleterowID:any;
  form: FormGroup;
  roleupdateform: FormGroup;
  modules = AllCommunityModules;
  gridApi: any;
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };
  public deletemodalflag:boolean=false;
  public eyRegionMasterData:any;
  public AccessRegionIds:any;

////#region Actions variables
public divFlag: boolean=false;
public screenFlag:boolean=false;
public actionAccess:any=[];
public styleDIV = {
  marginTop: "",
  width: "",
  height: ""
};
////#endregion

  //public btnsubmittext: string = "ADD";
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public ColumnDefs: any;
  public frameworkComponents: any;
  public tableDataset: any;
  public ngSelectedRegion:any;
  public Escmodalflag:boolean=false;
  public editflag:any;



  handleGridReady(params) {
    this.gridApi = params;
    this.gridApi.api.showLoadingOverlay()
    this.gridApi.api.sizeColumnsToFit()
    this.GetRoles();
  };

  GetRoles() {

    this._service.GetAllRoles()
      .subscribe(
        res => {

          this.tableDataset = res;


        },
        err => {

          console.log(err);
        }
      )

  }
  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  ngOnInit(): void {
    this.setDivHeight();
    
    this._headerService.getActions("Role Management").then((value) => {
                  
      this.actionAccess=value;
      if(this.actionAccess.indexOf("View")!=-1){
        this.divFlag=true;
        this.setFrameworkoptions();
        this.setTableHeight();
        this.buildHeaders();
        this.GetMastersData();

      }
      else
      this.router.navigate(['/unauthorizedaccess'])
    });
   
    this.form = this.createForm();
    this.roleupdateform= this.createForm();
 

  }
  ////#region Actions methods 
  setDivHeight() {
    
    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }
  ////#endregion
  createForm() {
    return this._formBuilder.group({
      roleName: [null, [Validators.required]],
      roledescription: [null, [Validators.required]],



    })
  }
  setFrameworkoptions() {
    this.frameworkComponents = {
      editDeleteActions: EditDeleteActionsComponent,
    }

  }

  setTableHeight() {
    this.style.marginTop = '20px';
    this.style.width = "100%";
    this.style.height = (document.documentElement.clientHeight - 160) + "px";
  }

  private buildHeaders() {

    this.ColumnDefs = [

      { headerName: 'Role Name', field: 'roleName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
      { headerName: 'Role Description', field: 'roledesc', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true },
   ]
   if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Add / Update")!=-1){
      
    this.ColumnDefs.unshift({
         headerName: '',
         cellRenderer: "editDeleteActions",
         cellRendererParams: {
           onEditClick: this.onEditClick.bind(this),
           onDeleteClick: this.onDeleteClick.bind(this),
           Actions: this.actionAccess
         },
         width: 60,         
         pinned: 'left',
         autoHeight: false,
       });
     }
  }

  onDeleteClick(e) {

    this.deletemodalflag=true;
    let row = e.rowData;
    this.deleterowID= e.rowData.id;

  }
  onEditClick(e) {

    this.eyRegionMasterData.forEach(element => {        
      element["isChecked"] =  false;        
     });

     this.editflag="edit";
  

    this.UpdateRoleID=e.rowData.id;
    this._service.GetRolebyID(e.rowData.id)
    .subscribe(
      res => {

 
        
        this.roleupdateform.setValue({
          roleName: e.rowData.roleName,
          roledescription: e.rowData.roledesc,
         
        });

        
        this.UpdateScreenActions = res;

        let isparentchecked=true;
        this.UpdateScreenActions[0].menuScreenAction.filter(element => {

        
      
          let isscreencheked=true;
          element.actions.filter(action => {           
          
          if(action.regionIds!=null)
          {
            
            this.AccessRegionIds={
              id:action.id,
              RegionIds:action.regionIds
            }
                  this.eyRegionMasterData.forEach(element => {        
                    element["isChecked"] = action.regionIds.indexOf(element.eyregionId) > -1 ? true : false;        
                   });
          }
              // this.GetMastersData(action.regionIds);
           if(!action.isChecked)
           {
            isscreencheked=false;
           }


          });
          

          element.isChecked=isscreencheked;
          

           if(!element.isChecked)
           {
            isparentchecked=false;
           }        
          })
          this.UpdateScreenActions[0].isChecked=isparentchecked;


          let isadminparentchecked=true;
          this.UpdateScreenActions[1].menuScreenAction.filter(element => {
  
   
            let isscreencheked=true;
            element.actions.filter(action => {
  
            
             if(!action.isChecked)
             {
              isscreencheked=false;
             }
  
  
            });
            
  
            element.isChecked=isscreencheked;
            
  
             if(!element.isChecked)
             {
              isadminparentchecked=false;
             }        
            })
            this.UpdateScreenActions[1].isChecked=isadminparentchecked;












        this.UpdateRoleModelShow = true;

      },
      err => {

        console.log(err);
      }
    )

   this.toastflag = false;

  }


  Add() {

    this.AddScreenActions = [];
    this.editflag="add";
    //this.GetMastersData(null);
    this._service.get()
      .subscribe(
        res => {
          this.eyRegionMasterData.forEach(element => {        
            element["isChecked"] =  false;        
           });
          this.AddScreenActions = res;          
          this.CreateRoleModelShow = true;

        },
        err => {

          console.log(err);
        }
      )

  }
  Update(form:FormGroup)
  {
    
    this.validateForm(form);
    if (form.valid) {

      var newRole = {
        id: this.UpdateRoleID,
        roleName: form.value.roleName,
        roledesc: form.value.roledescription,

      }
      var objRole = {

        role: newRole,
        actions: this.UpdateScreenActions,
        id:this.AccessRegionIds==null||this.AccessRegionIds ==""||this.AccessRegionIds==undefined?0:this.AccessRegionIds.id==0?0:this.AccessRegionIds.id,
        regionIds:this.AccessRegionIds==null||this.AccessRegionIds ==""||this.AccessRegionIds==undefined?null:this.AccessRegionIds.RegionIds==0?null:this.AccessRegionIds.RegionIds
      }

      this._service.UpdateRole(objRole).subscribe((response: any) => {

        if (response.statusCode == 200) {
          this.toasttype = "success";
          this.toastmessage = "Role updated successfully"
          this.toastflag = true;
          this.GetRoles();
          this.UpdateRoleModelShow = false;
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
        else if (response.statusCode == 500) {

          this.toasttype = "error";
          this.toastmessage = "Role updation failed"
          this.toastflag = true;
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);

        }



      });






    }


  }
  GetMastersData() {


    this._service.getMastersData()
      .subscribe(
        res => {
          console.log(res);

          this.eyRegionMasterData = res[0];
        //  if(ids!=null){
        //     let eyRegionMasters = ids.split(',').map(x=>+x);
        //     this.eyRegionMasterData.forEach(element => {        
        //       element["isChecked"] = eyRegionMasters.indexOf(element.eyregionId) > -1 ? true : false;        
        //     });
        //  }

        },
        err => {

          console.log(err);
        }
      )

  }
  save(form: FormGroup) {
    
    this.validateForm(form);
    if (form.valid) {

      var newRole = {
        id: 0,
        roleName: form.value.roleName,
        roledesc: form.value.roledescription,

      }
      var objRole = {

        role: newRole,
        actions: this.AddScreenActions,
        id:this.AccessRegionIds==null||this.AccessRegionIds ==""||this.AccessRegionIds==undefined?0:this.AccessRegionIds.id,
        regionIds:this.AccessRegionIds==null||this.AccessRegionIds ==""||this.AccessRegionIds==undefined?null:this.AccessRegionIds.RegionIds
      }

      this._service.AddRole(objRole).subscribe((response: any) => {

        if (response.statusCode == 200) {
          this.toasttype = "success";
          this.toastmessage = "Role created successfully"
          this.toastflag = true;
          this.GetRoles();
          this.CreateRoleModelShow = false;
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
        else if (response.statusCode == 409) {

          this.toasttype = "warning";
          this.toastmessage = "Duplicate role found"
          this.toastflag = true;
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);

        }



      });






    }
  }
  onRegionChange($event: any, screen: any, screenActions: any){
    
    this.AccessRegionIds=[];
    if($event.length>0){
        this.AccessRegionIds={
          id:screen['actions'][0].id,
          RegionIds:$event.map(x=>x).join(",")
        }
    }  
    else{
      this.AccessRegionIds={
        id:0,
        RegionIds:0
      }
    }
   
  }

  onActionChange($event: any, screen: any, screenActions: any, CurrentAction: any) {
    

    if ($event) {
      let allActionStatus = true;
      let actionidsets = new Set();

      screen.actions.forEach(function (action) {


        if (action.isChecked) {

          actionidsets.add(action.id);

          if (action.dependencyActionIDS != null && action.dependencyActionIDS.trim() != "") {

            action.dependencyActionIDS.split(",")
              .forEach(id => {
                actionidsets.add(parseInt(id))
              });

          }

        }
      });

      screen.actions.forEach(function (action) {


        if (actionidsets.has(action.id)) {
          action.isChecked = true;

        }

        if (!action.isChecked) {
          allActionStatus = false;
          // for (let i = 0; i < screen.Actions.count; i++) {
          //   screen.Actions[i].isChecked = false;
          // }
          return false;
        }

      })
      if (allActionStatus)
        screen.isChecked = true;
      else
        screen.isChecked = false;
    }
    else {


      screen.isChecked = false;

      if (CurrentAction.id == CurrentAction.dependencyActionIDS) {
        screen.actions.forEach(function (action) {



          action.isChecked = false;


        });



      }



    }

    let screenActionStatus = true;
    screenActions.menuScreenAction.forEach(function (screenAction) {
      if (!screenAction.isChecked) {
        screenActionStatus = false;
        return false;
      }
    })

    if (screenActionStatus)
      screenActions.isChecked = true;
    else
      screenActions.isChecked = false;
  }
  onScreenChange($event: any, screenActions: any, screen: any) {


    if ($event) {
      screen.actions.forEach(function (action) {
        action.isChecked = true;
      })
    }
    else {
      screen.actions.forEach(function (action) {
        action.isChecked = false;
      })
    }

    let screenActionStatus = true;



    screen.actions.forEach(function (action) {

      screenActions.menuScreenAction.forEach(function (screenAction) {
        if (!screenAction.isChecked) {
          screenActionStatus = false;
          return false;
        }
      })

      if (screenActionStatus)
        screenActions.isChecked = true;
      else
        screenActions.isChecked = false;
    });

  }
  onScreenActionChange($event: any, screnAction: any) {

    if ($event) {
      screnAction.menuScreenAction.forEach(function (screenAction) {
        //
        screenAction.isChecked = true;
        screenAction.actions.forEach(function (action) {
          // 
          action.isChecked = true;
        })
      })
    }
    else {
      screnAction.menuScreenAction.forEach(function (screenAction) {
        //
        screenAction.isChecked = false;
        screenAction.actions.forEach(function (action) {
          // 
          action.isChecked = false;
        })
      })
    }
    //alert($event.checked);
  }
    ////#region Action check method
ActionsCheck(action){  
  if(this.actionAccess.indexOf(action)!=-1)
  return true;
  else
  return false;
}
////#endregion

  Delete()
  {

    
   

    this._service.Delete(this.deleterowID)
    .subscribe(
      (res: any) => {

    if (res.message == "success") {
     
      this.deletemodalflag = false;


      this.toasttype = "success";
      this.toastflag = true;
      this.toastmessage = "Role has been deleted successfully.";
      this.GetRoles();
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
    }
    else if (res.message == "RoleCannotBeDeleted") {
      
      this.toasttype = "error";
      this.toastflag = true;
      this.toastmessage = "Role cannot be deleted as it was mapped to Contact.";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
    }
  
  });



  }

  ExportExcel(){
    this._service.ExportExcel().subscribe(
      res => {
        let date = new Date().toString();
        saveAs(res, "RoleManagement" + date + ".xlsx");
      },
      err => {
  
        console.log(err);
      }
    )
  }

  EscapeYes(){
    this.UpdateRoleModelShow = false;
    this.Escmodalflag = false; 
    this.editflag="";
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    debugger;
    if(this.editflag == "edit"){
      this.Escmodalflag=true;
    }
    else{
      //this.UpdateRoleModelShow = false;
      this.CreateRoleModelShow = false;
    }
  }  
}
