import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class EmailConfigurationForDueDatesService {
  private emailobj:any={
    newflag:""
  }

  constructor(private _http: HttpClient) { }

  GetAudittype(){
    return this._http.get(`${environment.apiUrl}/EmailConfigurationForDueDates/GetAuditTypess`);
  }
  Add(obj) {
    return this._http.post(`${environment.apiUrl}/EmailConfigurationForDueDates/Post/`, obj);
  }
  get() {
    return this._http.get(`${environment.apiUrl}/EmailConfigurationForDueDates/get`);
  }

  Upload(formdata: FormData) {
    return this._http.post(`${environment.apiUrl}/EmailConfigurationForDueDates/Upload`, formdata);
  //  return this._http.post(`${environment.apiUrl}/emailconfigforDueDates/Upload`, formdata);

  }
  TempleateExport() {  
    return this._http.get(`${environment.apiUrl}/EmailConfigurationForDueDates/DownloadTemplate/`,{responseType: "blob"});
  }

  delete(id) {
    return this._http.post(`${environment.apiUrl}/EmailConfigurationForDueDates/Delete/`,{id:id});
  }
  Update(obj) {
    return this._http.post(`${environment.apiUrl}/EmailConfigurationForDueDates/Update/`, obj);
  }

  GetMastersData() {

    return this._http.get(`${environment.apiUrl}/EmailConfigurationForDueDates/GetMasterData`);

  }  
  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/EmailConfigurationForDueDates/ExcelExport`, {responseType: "blob"});
  }

  setupdateData(obj) {      
    this.emailobj = obj;  
  }  
  getupdateData() {  
    return this.emailobj;  
  }



}
