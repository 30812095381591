<motif-header [fixed]="true" appHeaderName="Audit Management Tool">
    <!-- Logo -->
    <motif-header-logo>
        <a routerLink="/" tabindex="0" aria-label="Home Page Link">
            <img src="./assets/images/ey-logo-inverted.svg" alt="EY Motif Logo" />
        </a>
    </motif-header-logo>

    <!-- Icons slot -->
    <motif-header-icons-slot>
        <!-- <motif-icon (click)="onClickNotifications()" [src]="'/assets/icons/social/ic_notifications_24px.svg'"></motif-icon> -->

        <!-- User profile DOM - start -->
        <div class="userProfileDropDown" *ngIf="userClick">
            <div class="userProfileBack" (click)="closeUserProfile()"></div>
            <div class="dropdown-content">
                <motif-card>
                    <motif-card-header style="background-color: #ffe600 !important;">
                        <div class="motif-row motif-between-xs">
                            <div class="motif-col-xs-3 motif-middle-xs">
                                <span style="padding-left: 20px;" class="motif-h3">{{loginuser}} ({{roleName}})</span>
                            </div>
                        </div>
                    </motif-card-header>
                    <motif-card-body>
                        <div class="userDetailsBlock">
                            <ul class="list-inline">
                                <li>
                                    <a href="#">
                                        <img src="../../../assets/mail.svg" alt="Mail" />
                                        <span class="motif-h6">{{email}}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" (click)="logout()">
                                        <!-- <motif-icon (click)="logout()" [src]="'/assets/icons/action/ic_power_settings_new_24px.svg'"></motif-icon> -->
                                        <img src="../../../assets/icons/action/ic_power_settings_new_24px.svg"
                                            alt="Mail" />
                                        <span class="motif-h6">Logout</span>
                                    </a>

                                </li>
                            </ul>
                        </div>
                        <!-- <div>
              <a href="#">
                <img style="float: left;" src="../../../assets/users.svg" alt="Role" />
                <span class="motif-h6">{{roleName}}</span></a></div> -->
                    </motif-card-body>
                </motif-card>
            </div>

        </div>
        <!-- User profile DOM - end -->
        <motif-icon class=" usericon" (click)="showUserProfile()" [src]="'/assets/icons/social/ic_person_24px.svg'">
        </motif-icon>

        <button motifIconButton aria-label="icon-button" class="notificationbtn" (click)="notificationmodelopen()">
            <!-- <motif-icon class="notificationicon"  [src]="'/assets/icons/alert/ic_add_alert_24px.svg'" style="margin: 0px; position: absolute; top: 17px; right: 69px; cursor: pointer;" ></motif-icon> -->
            <motif-icon class="notificationicon" [src]="'/assets/icons/alert/ic_add_alert_24px.svg'"
                style="background: transparent;"></motif-icon>
            <!-- <span class="NotificationLength" *ngIf="notifications.length != 0"> {{notifications.length}}</span> -->
        </button>
        <span class="NotificationLength" *ngIf="notifications.length != 0"> {{notifications.length}}</span>
        <div class="notificationPOPUP">
            <motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
                <motif-modal-header style="background: #2E2E38; ">
                    <span>Notification Center</span>
                    <button motifButton color="warn" class="ClearAll" (click)="ClearAllNotifications()">Clear</button>
                </motif-modal-header>
                <motif-modal-body style="text-align: left; padding: 8px; overflow: hidden scroll; height: 292px;">
                    <ul class="notificationdata" *ngFor="let notification of notifications">
                        <!-- <div [innerHTML]="notification.notification" class="NotificationList"></div> -->
                        <li [innerHTML]="notification.notification"></li>
                        <!-- {{ notification.notification }} -->
                        <button class="cleareachnotificationButton"
                            (click)="ClearEachNotification(notification.notificationId)">
                            <motif-icon class="cleareachnotification" [src]="'/assets/icons/action/ic_delete_24px.svg'">
                            </motif-icon>
                        </button>
                    </ul>
                </motif-modal-body>
                <!-- <motif-modal-footer style="DISPLAY: flex; JUSTIFY-CONTENT: FLEX-END;">
                <button motifButton color="warn">Clear</button>
                <button motifButton color="warn" (click)="notificationmodelclose()">Cancel</button>
            </motif-modal-footer> -->
            </motif-modal>
        </div>



    </motif-header-icons-slot>

    <!-- Menu -->
    <button motifIconButton aria-label="Open Main Menu" tabindex="0" role="button" (click)="showMenu = !showMenu"
        (keypress.Space)="showMenu = !showMenu">
        <motif-icon [src]="'./assets/icons/navigation/ic_menu_24px.svg'"></motif-icon>
    </button>
    <motif-menu [(open)]="showMenu" variant="alt">
        <motif-menu-item routerLinkActive="active-link">
            <a (click)="showMenu = !showMenu" routerLink="/Dashboard" role="menuitem" tabindex="0"
                aria-label="Active Link Example">Status Dashboard</a>
        </motif-menu-item>
        <motif-menu-item *ngIf="CheckMenuAccess('Client Entity')" routerLinkActive="active-link">
            <a (click)="showMenu = !showMenu" routerLink="/clientEntity" role="menuitem" tabindex="0"
                aria-label="Active Link Example">Client Entity</a>
        </motif-menu-item>
        <motif-menu-item *ngIf="CheckMenuAccess('Directory')" routerLinkActive="active-link">
            <a (click)="showMenu = !showMenu" routerLink="/directory" role="menuitem" tabindex="0"
                aria-label="Inactive Link Example">Directory</a>
        </motif-menu-item>
        <motif-menu-item *ngIf="CheckMenuAccess('Audit Assignment')" routerLinkActive="active-link">
            <a routerLink="/auditassignment" (click)="showMenu = !showMenu" role="menuitem" tabindex="0"
                aria-label="Inactive Link Example">Audit Assignment</a>
        </motif-menu-item>
        <motif-menu-item *ngIf="CheckMenuAccess('Pre Approval')" routerLinkActive="active-link">
            <a (click)="showMenu = !showMenu" routerLink="/preapproval" role="menuitem" tabindex="0"
                aria-label="Sub Inactive Link Example">Pre-Approval</a>
        </motif-menu-item>
        <motif-menu-item routerLinkActive="active-link">
            <a (click)="showMenu = !showMenu" routerLink="/" role="menuitem" tabindex="0"
                aria-label="Active Link Example">Planning</a>
        </motif-menu-item>
        <motif-menu-item extraClasses="dark-item" routerLinkActive="active-link">
            <a (click)="showMenu = !showMenu" routerLink="/trackerview" role="menuitem" tabindex="0"
                aria-label="Sub Inactive Link Example">Tracker View</a>
        </motif-menu-item>
        <motif-menu-group title="Configurations" *ngIf="CheckMenuAccess('Notification') || CheckMenuAccess('Email Configuration')
        CheckMenuAccess('EmailConfigDueDates')|| CheckMenuAccess('PreApproval EmailConfig')"
            routerLinkActive="active-link" closeItemGroupAriaLabel="Close item group" (click)="OnMenuclick1()">
            <motif-menu-item *ngIf="CheckMenuAccess('Notification')" [ngClass]="{'hideclass': menuopened1==false, 'showclass':menuopened1==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/Notification" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Notification</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Email Configuration')" [ngClass]="{'hideclass': menuopened1==false, 'showclass':menuopened1==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/EmailConfiguration" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Email Configuration</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('EmailConfigDueDates')" [ngClass]="{'hideclass': menuopened1==false, 'showclass':menuopened1==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/EmailConfigurationForDueDates" role="menuitem"
                    tabindex="0" aria-label="Sub Inactive Link Example">Email Configuration for Due Dates</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('PreApproval EmailConfig')" [ngClass]="{'hideclass': menuopened1==false, 'showclass':menuopened1==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/PreApprovalEmailConfiguration" role="menuitem"
                    tabindex="0" aria-label="Sub Inactive Link Example">PreApproval Email Configuration</a>
            </motif-menu-item>
            
        </motif-menu-group>



        <!-- <motif-accordion togglePosition="after" color="default" type="default" hideToggle="false" multi="false">
            <motif-expansion-panel disabled="false" expanded="false" *ngIf="CheckMenuAccess('Notification') || CheckMenuAccess('Email Configuration')
            CheckMenuAccess('EmailConfigDueDates')|| CheckMenuAccess('PreApproval EmailConfig')"
                routerLinkActive="active-link">
               <motif-expansion-panel-header>
                  configurations
               </motif-expansion-panel-header>
               <p>hi</p>
            </motif-expansion-panel>
          </motif-accordion> -->
  

        <motif-menu-group title="Masters" *ngIf="CheckMenuAccess('Location') || CheckMenuAccess('Canton')
       || CheckMenuAccess('EY Office') || CheckMenuAccess('EY Region') ||CheckMenuAccess('Function') ||CheckMenuAccess('Rank')
        || CheckMenuAccess('IR RB Office') ||CheckMenuAccess('Contact Type') ||CheckMenuAccess('Entity Form Status') || CheckMenuAccess('Audit Type Status')
        ||CheckMenuAccess('Audit Type Status AP') || CheckMenuAccess('Audit Type Status KP') ||CheckMenuAccess('Audit Type Status RP')|| CheckMenuAccess('Audit Type Status OT')
        || CheckMenuAccess('Planing Date Config') || CheckMenuAccess('Holiday') || CheckMenuAccess('MeetingFormat') 
        || CheckMenuAccess('Entity Type') || CheckMenuAccess('Service Status') || CheckMenuAccess('Approval Status') || CheckMenuAccess('Approval Level')
        " routerLinkActive="active-link" closeItemGroupAriaLabel="Close item group" (click)="OnMenuclick2()">
            <motif-menu-item *ngIf="CheckMenuAccess('Location')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/location" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Location</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('MeetingFormat')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/meetingFormat" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Meeting Format</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Canton')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item" routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/canton" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Canton</a>
            </motif-menu-item>
            
            <motif-menu-item *ngIf="CheckMenuAccess('Entity Type')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/EntityType" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Entity Type</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Service Status')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/ServiceStatus" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Service Status</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Approval Status')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/ApprovalStatus" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Approval Status</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Approval Level')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/ApprovalLevel" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Approval Level</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('EY Office')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/eyoffice" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">EY Office</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('EY Region')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/eyregion" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">EY Region</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Function')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/function" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Function</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Rank')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item" routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/rank" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Rank</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('IR RB Office')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/irrboffice" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">IR Office</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Contact Type')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/contacttype" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Contact Type</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Entity Form Status')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/formstatus" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Form Status</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Audit Type Status')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/auditstatus" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Audit Status</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Audit Type Status AP')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/auditstatusap" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Audit Status AP</a>
            </motif-menu-item>

            <motif-menu-item *ngIf="CheckMenuAccess('Audit Type Status KP')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/auditstatuskp" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Audit Status KP</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Audit Type Status RP')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/auditstatusrp" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Audit Status RP</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Audit Type Status OT')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/auditstatusot" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Audit Status OT</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Planing Date Config')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/planningdatesconfigurations" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Planning Dates Configuration</a>
            </motif-menu-item>
            <motif-menu-item *ngIf="CheckMenuAccess('Holiday')" [ngClass]="{'hideclass': menuopened2==false, 'showclass':menuopened2==true}" extraClasses="dark-item" routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/holiday" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Holiday</a>
            </motif-menu-item>
        </motif-menu-group>

        <motif-menu-group title="Admin" *ngIf="CheckMenuAccess('Role Management')" routerLinkActive="active-link" (click)="OnMenuclick3()">
            <!-- <motif-menu-item extraClasses="dark-item" routerLinkActive="active-link">
        <a (click)="showMenu = !showMenu"
           routerLink="/userManagement"
           role="menuitem"
           tabindex="0"
           aria-label="Sub Inactive Link Example">User Management</a>
      </motif-menu-item> -->
            <motif-menu-item *ngIf="CheckMenuAccess('Role Management')" [ngClass]="{'hideclass': menuopened3==false, 'showclass':menuopened3==true}" extraClasses="dark-item"
                routerLinkActive="active-link">
                <a (click)="showMenu = !showMenu" routerLink="/role" role="menuitem" tabindex="0"
                    aria-label="Sub Inactive Link Example">Role Management</a>
            </motif-menu-item>


        </motif-menu-group>




    </motif-menu>
</motif-header>