import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationAlterService {

  constructor(private _http: HttpClient) { }
  getnotifications() {
    return this._http.get(`${environment.apiUrl}/notificationAlter/get`);
  }
}
