import { Directive, ElementRef, HostListener, Input, OnInit, Pipe, PipeTransform, SimpleChange } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({ selector: "[ngModel][swissNumberFormat]" ,
host: {
    '(ngModelChange)': 'onInputChange($event)'
  }
})
export class SwissNumberFormatDirective implements OnInit {


    private el: HTMLInputElement;
    constructor(
      private elementRef: ElementRef, public model: NgControl){
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
    }

    onInputChange(value) {       
        if (value != "" && value != undefined && value != null) {
          value = value.replace(/[a-zA-Z](.*)/g, '');
          value = value.replace(/[^\w\s]/gi, '');
          this.el.value = value.trim() == "" ? null : parseInt(value).toLocaleString("de-CH", { currency: 'EUR'});
        }
    }
}