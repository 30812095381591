<!-- <div class="motif-row">
  <div class="motif-col-md-5">
    <div class="motif-h4">
      User Management
    </div>
  </div>
  <div class="motif-col-md-3 motif-end-lg">
    <button size="large" (click)="addUser()" class="motif-col-xs-4 motif-col-md-2" motifButton color="primary">
      <motif-icon [src]="'/assets/icons/file/ic_file_upload_24px.svg'"></motif-icon>Add User
    </button>
  </div>
</div> -->
<div class="playbook-main">
  <div class="motif-row" style="margin-bottom:20px">

    <div class="motif-col-xs-4 motif-col-lg-4 motif-h3">
      User Management
    </div>

    <div class="motif-col-xs-4 motif-col-lg-8">

      <div class="motif-row motif-end-lg">

        <!-- class="motif-col-xs-4 motif-col-md-2" -->
        <button size="medium" motifButton color="primary" (click)="addUser()"
          title="Add">
          <motif-icon [src]="'/assets/icons/content/ic_add_circle_24px.svg'"></motif-icon>Add User
        </button>

      </div>
    </div>
  </div>

  <!--user grid -->
  <div class="motif-table">

    <div id="divUserGrid" class="motif-table-wrapper" [ngClass]="{'motif-table-first-column-border': true}">
      <ag-grid-angular [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate"
        class="ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="usertableDataset"
        [modules]="modules" (gridReady)="handleGridReady($event)" [animateRows]="true" [pagination]="true"
        [paginationPageSize]=30 [frameworkComponents]="frameworkComponents">
      </ag-grid-angular>

    </div>
  </div>
</div>

<!-- modal - add user -->
<motif-modal [show]="userModal" (close)="userModal = false" [slideout]="true" class="userManagementModal">
  <motif-modal-header>
    {{userModalHeader}}
  </motif-modal-header>
  <motif-modal-body>
    <form [formGroup]="form" novalidate>
      <span class="fonts-col__heading-4"><b> User Details </b></span>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-12">
          <motif-form-field>
            <label motifLabel>First Name</label>
            <input motifInput type="text" aria-label="First Name Form Field" formControlName="firstName" />
            <motif-error *ngIf="form.get('firstName').hasError('required') && form.get('firstName').touched">
              Please provide First Name!
            </motif-error>
          </motif-form-field>
        </div>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-12">
          <motif-form-field>
            <label motifLabel>Last Name</label>
            <input motifInput type="text" aria-label="Last Name Form Field" formControlName="lastName" />
            <motif-error *ngIf="form.get('lastName').hasError('required') && form.get('lastName').touched">
              Please provide Last Name!
            </motif-error>
          </motif-form-field>
        </div>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-12">
          <motif-form-field>
            <label motifLabel>User Name</label>
            <input motifInput type="text" aria-label="User Name Form Field" formControlName="userName" />
            <motif-error *ngIf="form.get('userName').hasError('required') && form.get('userName').touched">
              Please provide User Name!
            </motif-error>
            <motif-error *ngIf="form.get('userName').hasError('email')">
              Please provide valid email!
            </motif-error>
          </motif-form-field>
        </div>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-12">
          <motif-form-field>
            <label motifLabel>Phone No</label>
            <input motifInput type="number" maxlength="10" aria-label="Phone No. Form Field"
              formControlName="phoneNo" />
            <motif-error *ngIf="form.get('phoneNo').hasError('required') && form.get('phoneNo').touched">
              Please provide Phone No.!
            </motif-error>
          </motif-form-field>
        </div>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-12">
          <motif-form-field>
            <label motifLabel class="select-label">Role</label>
            <motif-select class="ClienentitytModalselect" formControlName="role" aria-label="Role Form Field">
              <motif-option *ngFor="let role of roles" [value]="role.roleId">
                {{role.roleName}}</motif-option>
            </motif-select>
            <motif-error *ngIf="form.get('role').hasError('required') && form.get('role').touched">
              Please provide Role!
            </motif-error>
          </motif-form-field>
        </div>
      </div>

    </form>

  </motif-modal-body>
  <motif-modal-footer>
    <button motifButton color="primary" (click)="saveUser(form)" *ngIf="btnIsSaveUser" type="submit">Save</button>
    <button motifButton color="primary" (click)="updateUser(form)" *ngIf="!btnIsSaveUser" type="submit">Update</button>
    <button motifButton color="secondary" (click)="userModal = false">Cancel</button>
  </motif-modal-footer>
</motif-modal>

<!-- modal - Delete user -->
<motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
  <motif-modal-header>
    Delete User
  </motif-modal-header>
  <motif-modal-body>
    <p>
      Are you sure, you want to delete user?
    </p>
  </motif-modal-body>
  <motif-modal-footer>
    <button motifButton color="primary" (click)="Delete()">Confirm</button>
    <button motifButton color="secondary" (click)="deletemodalflag = false">Cancel</button>
  </motif-modal-footer>
</motif-modal>



<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
  {{toastmessage}}</motif-toast>
<motif-loader [show]="loaderflag" [fullScreen]="true"></motif-loader>
