import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class EmailconfigurationService {

  constructor(private _http: HttpClient) { }

  private emailobj:any={
     newflag:""
   }

  GetAudittype(){
    return this._http.get(`${environment.apiUrl}/emailconfigforAudittypes/GetAuditTypess`);
  }
  Add(obj) {
    return this._http.post(`${environment.apiUrl}/emailconfigforAudittypes/Post/`, obj);
  }
  get() {
    return this._http.get(`${environment.apiUrl}/emailconfigforAudittypes/get`);
  }

  delete(id) {
    return this._http.post(`${environment.apiUrl}/emailconfigforAudittypes/Delete/`,{id:id});
  }
  Update(obj) {
    return this._http.post(`${environment.apiUrl}/emailconfigforAudittypes/Update/`, obj);
  }

  GetMastersData() {

    return this._http.get(`${environment.apiUrl}/emailconfigforAudittypes/GetMasterData`);
  }

  Upload(formdata: FormData) {
    return this._http.post(`${environment.apiUrl}/emailconfigforAudittypes/Upload`, formdata);
  }

  TemplateExport() {  
    return this._http.get(`${environment.apiUrl}/emailconfigforAudittypes/DownloadTemplate/`,{responseType: "blob"});
  }
  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/emailconfigforAudittypes/ExcelExport`, {responseType: "blob"});
  }
  setupdateData(obj) {      
    this.emailobj = obj;  
  }  
  getupdateData() {  
    return this.emailobj;  
  }

}
