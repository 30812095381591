<section *ngIf="!AddUpdateFlag">
    <div class="startUpMain">
        <div class="startupdashboardHeader">
            <h2> Raiffeisen - Planning {{selectedYear}}<span> </span></h2>
            <div class="regionSelect">
                <ul class="list-inline">
                    <li class="">
                        <motif-form-field>
                            <label motifLabel>Select Year</label>
                            <motif-select [(ngModel)]="selectedYear" (change)="GetStartUP(selectedYear,selectedBank)" [multiple]="false"
                                aria-label="function Form Field">
                                <motif-option *ngFor="let item of lstYear" [value]="item">
                                    {{item}}
                                </motif-option>
                            </motif-select>
                        </motif-form-field>
                    </li>
                    <li>
                        <motif-form-field *ngIf="NgSearchFlag">
                            <label motifLabel>Select Lead</label>
                            <motif-select [(ngModel)]="selectedLead" (change)="externalLeadFilterChanged($event)"
                                [(ngModel)]="selectedLead" [multiple]="false" aria-label="function Form Field">
                                <motif-option value="EY">EY</motif-option>
                                <motif-option value="IR">IR</motif-option>
                                <motif-option value="NA">NA</motif-option>
                            </motif-select>
                        </motif-form-field>
                    </li>
                    <li>
                        <motif-form-field *ngIf="NgSearchFlag">
                            <label motifLabel>Select Region</label>
                            <motif-select [(ngModel)]="RegionSelected" (change)="externalRegionFilterChanged($event)"
                                [multiple]="false" [(ngModel)]="RegionSelected" aria-label="function Form Field">
                                <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionId">
                                    {{item.eyregionName}}</motif-option>
                            </motif-select>
                        </motif-form-field>
                    </li>
                    <li>
                     
                            <motif-form-field *ngIf="NgSearchFlag">
                                <label motifLabel>IR Office</label>
                                <motif-select [(ngModel)]="irofficeSelected"  (change)="externalIROfficeFilterChanged($event)"
                                    aria-label="IR office Form Field">
                                    <motif-option *ngFor="let item of irRBofficeMasterData" [value]="item.irofficeName">
                                        {{item.irofficeName}}</motif-option>
                                </motif-select>
                           
                            </motif-form-field>
                        
        
                    </li>
                    <li class="banklist">
                        <motif-form-field>
                          <motif-radio-group  [(ngModel)]="selectedBank" (change)="GetStartUP(selectedYear,selectedBank)">
                              <div class="motif-row">
                                  <div class="motif-col-xs-6 motif-col-lg-6">
                                      <motif-radio-button value="allBanks" checked="checked" [title]="'Custom radio element title'">All Audits
                                      </motif-radio-button>
                                  </div>
                                  <div class="motif-col-xs-6 motif-col-lg-6">
                                      <motif-radio-button value="myBanks" [title]="'Custom radio element title'">My Audits
                                      </motif-radio-button>
                                  </div>
                              </div>
                          </motif-radio-group>
                        </motif-form-field>
                    </li>
                         
                    <li class="resetclass">
                        <button size="large" motifButton color="primary" title="Reset" (click)="resetFIlters()">
                            <motif-icon class="Custom-motif-button-icon"
                                [src]="'/assets/icons/action/ic_autorenew_24px.svg'"></motif-icon>Reset
                        </button>
                    </li>
                </ul>

            </div>
        </div>

        <div class="DashboardTable">
            <div class="Tables1" [ngClass]="{'class1Active' : table1Element, 'tableSizeODeactive' : tableSizeO }">
                <span class="tableHeaderTitle BoldHead">Regulatory Audit (AP)
                    <svg width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16" *ngIf="!table1Element" (click)="table1();">
                        <path fill-rule="evenodd"
                            d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                    </svg>
                    <svg width="12" height="12" fill="currentColor" class="bi bi-dice-4-fill" viewBox="0 0 16 16" *ngIf="table1Element" (click)="table1Close();">
                        <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm1 5.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM4 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                      </svg>
                </span>
                <div style="width: 100%;">
                    <div class="motif-table">
                        <div id="dvag-grid" class="motif-table-wrapper"
                            [ngClass]="{'motif-table-first-column-border': true}">
                            <ag-grid-angular [ngStyle]="style" [suppressRowClickSelection]="true"
                                [rowSelection]="rowSelection" [overlayLoadingTemplate]="overlayLoadingTemplate"
                                class="ag-theme-balham" [columnDefs]="APStatusColumnDefs"
                                [rowData]="APStatusTableDataset" [modules]="modules"
                                (gridReady)="APhandleGridReady($event)" [animateRows]="true"
                                (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)"
                                [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents"
                                [isExternalFilterPresent]="isExternalFilterPresent"
                                [doesExternalFilterPass]="doesExternalFilterPass"></ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Tables2" [ngClass]="{'class2Active' : table2Element, 'tableSizeODeactive' : tableSizeO }">
                <span class="tableHeaderTitle BoldHead">Loan Audit (KP)
                    <svg width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16" *ngIf="!table2Element" (click)="table2();">
                        <path fill-rule="evenodd"
                            d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                    </svg>
                    <svg width="12" height="12" fill="currentColor" class="bi bi-dice-4-fill" viewBox="0 0 16 16" *ngIf="table2Element" (click)="table2Close();">
                        <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm1 5.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM4 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                      </svg>
                </span>
                <div style="width: 100%;">
                    <div class="motif-table">
                        <div id="dvag-grid" class="motif-table-wrapper"
                            [ngClass]="{'motif-table-first-column-border': true}">
                            <ag-grid-angular [ngStyle]="style" [suppressRowClickSelection]="true"
                                [rowSelection]="rowSelection" [overlayLoadingTemplate]="overlayLoadingTemplate"
                                class="ag-theme-balham" [columnDefs]="KPStatusColumnDefs"
                                [rowData]="KPStatusTableDataset" [modules]="modules"
                                (gridReady)="KPhandleGridReady($event)" [animateRows]="true"
                                (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)"
                                [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents"
                                [isExternalFilterPresent]="isExternalFilterPresent"
                                [doesExternalFilterPass]="doesExternalFilterPass"></ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Tables3" [ngClass]="{'class3Active' : table3Element, 'tableSizeODeactive' : tableSizeO }">
                <span class="tableHeaderTitle BoldHead">Financial Audit (RP)
                    <svg width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16" *ngIf="!table3Element" (click)="table3();">
                        <path fill-rule="evenodd"
                            d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                    </svg>
                    <svg width="12" height="12" fill="currentColor" class="bi bi-dice-4-fill" viewBox="0 0 16 16" *ngIf="table3Element" (click)="table3Close();">
                        <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm1 5.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM4 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                      </svg>
                </span>
                <div style="width: 100%;">
                    <div class="motif-table">
                        <div id="dvag-grid" class="motif-table-wrapper"
                            [ngClass]="{'motif-table-first-column-border': true}">
                            <ag-grid-angular [ngStyle]="style" [suppressRowClickSelection]="true"
                                [rowSelection]="rowSelection" [overlayLoadingTemplate]="overlayLoadingTemplate"
                                class="ag-theme-balham" [columnDefs]="RPStatusColumnDefs"
                                [rowData]="RPStatusTableDataset" [modules]="modules"
                                (gridReady)="RPhandleGridReady($event)" [animateRows]="true"
                                (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)"
                                [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents"
                                [isExternalFilterPresent]="isExternalFilterPresent"
                                [doesExternalFilterPass]="doesExternalFilterPass"></ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Tables4" [ngClass]="{'class4Active' : table4Element, 'tableSizeODeactive' : tableSizeO }">
                <span class="tableHeaderTitle BoldHead">Other Audit (OT)
                    <svg width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16" *ngIf="!table4Element" (click)="table4();">
                        <path fill-rule="evenodd"
                            d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                    </svg>
                    <svg width="12" height="12" fill="currentColor" class="bi bi-dice-4-fill" viewBox="0 0 16 16" *ngIf="table4Element" (click)="table4Close();">
                        <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm1 5.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM4 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                      </svg>
                </span>
                <div style="width: 100%;">
                    <div class="motif-table">
                        <div id="dvag-grid" class="motif-table-wrapper"
                            [ngClass]="{'motif-table-first-column-border': true}">
                            <ag-grid-angular [ngStyle]="style" [suppressRowClickSelection]="true"
                                [rowSelection]="rowSelection" [overlayLoadingTemplate]="overlayLoadingTemplate"
                                class="ag-theme-balham" [columnDefs]="OTStatusColumnDefs"
                                [rowData]="OTStatusTableDataset" [modules]="modules"
                                (gridReady)="OThandleGridReady($event)" [animateRows]="true"
                                (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)"
                                [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents"
                                [isExternalFilterPresent]="isExternalFilterPresent"
                                [doesExternalFilterPass]="doesExternalFilterPass"></ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="startuptoast">
  <motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
    {{toastmessage}}
  </motif-toast>
</div>
<!-- <app-add-update-audit-assignment [Params]="EditParams" [auditassign]="!auditassign"
[advanceUpdate]="advanceUpdate"  *ngIf="AddUpdateFlag">
</app-add-update-audit-assignment> -->