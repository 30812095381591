<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
<div class="playbook-main motif-row">
    <div class="motif-col-xs-1">
        <form [formGroup]="form">
            <section class="motif-modal-content">
                <div class="motif-modal-header">
                    <h3 class="motif-h3"> Audit Type Status Master AP</h3>
                </div>
                <div class="csdivformmastercreation" *ngIf="ActionsCheck('Add / Update')">
                    <div class="csdivcenterformmastercreation ">
                        <motif-form-field>
                            <label motifLabel>Audit Type Status</label>
                            <input motifInput formControlName="name" type="text" aria-label="Input" />
                        </motif-form-field>
                        <motif-error *ngIf="
                    form.get('name').hasError('required') &&
                    form.get('name').touched
                    ">Please enter audit type status</motif-error>
                    </div>

                    <div class="csdivcenterformmastercreation">
                        <div class="motif-row">
                            <div class="button-container motif-col-xs-2">
                                <motif-loader-button *ngIf="ActionsCheck('Add / Update')" type="submit" [determinatedProgressBar]="!showLoader" showProgressbar="showLoader" buttonColor="yellow" (click)="submit()" value="{{btnsubmittext}}" progressValue="showLoader ? 0 :5">
                                </motif-loader-button>
                             
                            </div>
                            <div class="motif-col-xs-2">
                                <button (click)="reset()" class="csresetbutton" motifButton color="warn">Reset</button>

                            </div>
                        </div><br/>
                        <div class="motif-row">
                            <div class="button-container motif-col-xs-2">
                                <button (click)="ExportExcel()" size="large" class="csresetbutton" motifButton color="primary"><motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>

    <div class="motif-col-xs-3">
        <div style="width: 100%;">
            <div class="motif-table">
                <div id="dvag-grid" class="motif-table-wrapper" [ngClass]="{          'motif-table-first-column-border': true}">
                    <ag-grid-angular [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="tableDataset" [modules]="modules" (gridReady)="handleGridReady($event)" [animateRows]="true"
                        [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>

    <motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
        <motif-modal-header> Delete Audit Type Status </motif-modal-header>
        <motif-modal-body>
            <p> Are you sure, you want to delete audit type status? </p>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="Delete()">Confirm</button>
            <button motifButton color="warn" (click)="deletemodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>
</div>

<!--<motif-loader [show]="showLoader" [fullScreen]="true"></motif-loader>-->

<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
    {{toastmessage}}
</motif-toast>
</div>
