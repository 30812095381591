import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/app/environments/environment';
import { HeaderComponent } from '../header/header.component';
import { AuthenticationService } from '../Services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private _http: HttpClient,
    private jwtHelper: JwtHelperService,
    private _headerComp: HeaderComponent,
    private adalService:AdalService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
     const refreshToken: string = sessionStorage.getItem("refreshToken");
    const jwtToken = JSON.parse(sessionStorage.getItem("jwt"));
    if(refreshToken === null || refreshToken === undefined)
    {
      let msg:string="";  
      //  this.loading = true;
      let loginUserName = this.adalService.userInfo.token;
      this.authenticationService.login(loginUserName)
        .subscribe(
          res => {
            
            if (res.statusCode == 200) {
             this.router.navigate([""]);  
              //return true;            
            } else if (res.statusCode == 404) {
              //return false;
              this.router.navigate(["/unauthorizedaccess"]);  
             // this.error = "The email address that you've entered doesn't match any account.Please try again.";
            }
            else if (res.statusCode == 401) {
              //return false;
              this.router.navigate(["/unauthorizedaccess"]);  
             // this.error = "The email address that you've entered doesn't match any account.Please try again.";
            }
            //  this.router.navigate([""]);
          },
          error => {
            //return false;
           // this.error = "User is not authorized to login. Please contact Admin.";
            //this.loading = false;
            this.router.navigate(["/login"]);  
          }
        );
    }     
    else{    
    if (jwtToken !== null && jwtToken !== undefined) {
      if (jwtToken.token && !this.jwtHelper.isTokenExpired(jwtToken.token)) {
        console.log(this.jwtHelper.decodeToken(jwtToken.token));
        return true;
      }
    }
    else{
      this.router.navigate(["/login"],{ queryParams: { returnUrl: state.url }});
      return true;
    }    
    const isRefreshSuccess = this.tryRefreshingTokens(jwtToken.token);
    if (!isRefreshSuccess) {
      alert("hello...");
      this.router.navigate(["/login"]);
    }

    // const currentUser = this.authenticationService.currentUserValue;
    // if (currentUser) {
    //   // logged in so return true
    //   return true;
    // }

    // not logged in so redirect to login page with the return url
    //this.router.navigate(['/login']);
    return isRefreshSuccess;
  }
  }

  private async tryRefreshingTokens(token: string): Promise<boolean> {
   
    // Try refreshing tokens using refresh token
    const refreshToken: string = sessionStorage.getItem("refreshToken");
    if (!token || !refreshToken) {
      return false;
    }
    const credentials = JSON.stringify({ accessToken: token, refreshToken: refreshToken });
    let isRefreshSuccess: boolean;
    try {
      const response = await this._http.post(`${environment.apiUrl}/token/refresh`, credentials, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        }),
        observe: 'response'
      }).toPromise();      
      console.log(response);
      // If token refresh is successful, set new tokens in local storage.
      const newToken = (<any>response).body.accessToken;
      const newRefreshToken = (<any>response).body.refreshToken;
      sessionStorage.setItem("jwt", JSON.stringify({ token: newToken }));
      sessionStorage.setItem("refreshToken", newRefreshToken);
      isRefreshSuccess = true;
    }
    catch (ex) {      
      isRefreshSuccess = false;
      this._headerComp.logout();
      //this.authenticationService.logout();
    }
    return isRefreshSuccess;
  }
CheckAccess() { 
  let msg:string="";  
    //  this.loading = true;
    let loginUserName = this.adalService.userInfo.userName;
    this.authenticationService.login(loginUserName)
      .subscribe(
        res => {
          if (res.statusCode == 200) {
            //this.router.navigate([""]);
            msg= "success";
          } else if (res.statusCode == 404) {
            msg= "Fail";
           // this.error = "The email address that you've entered doesn't match any account.Please try again.";
          }
          //  this.router.navigate([""]);
        },
        error => {
         // this.error = "User is not authorized to login. Please contact Admin.";
          //this.loading = false;
          msg= "APIError"
        }
      );
      return msg;
  }


}