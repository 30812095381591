import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agdatetimepicker',
  template: ` 
<input type="datetime-local" class="ag-input-field-input ag-text-field-input" [(ngModel)]="date"
 (ngModelChange)="onChange($event)" />
`,
})
export class AgdatetimepickerComponent  {
  
  constructor() { }

  date: Date;
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  getDate(): Date {

    if (this.date != null) {
      return new Date(this.date);
    }
    else {
      return this.date;
    }
  }

  onChange(event) {
   
   this.params.onDateChanged();
  }
  setDate(date: Date): void {
    this.date = date || null;
  }
}
