import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AggridganttchartService {

  constructor(private _http: HttpClient) { }

  GetTrackerView(year, AuditType,Lead, isMyBanks) {
    let obj = {
      Year: year.toString(),
      AuditType: AuditType,
      Lead:Lead,
      isMyBanks:isMyBanks
    }
    return this._http.post(`${environment.apiUrl}/AuditAssignment/GetTrackerView`, obj);

  }
  getMastersData(){
    return this._http.get(`${environment.apiUrl}/AuditAssignment/GetMastersDataforTrackerView`);
  }
}
