<section>
    <div class="mainLanding">
        <div class="firstCardsBlock">
            <ul class="list-inline">
                <li class="card">
                    <h6>Total Client Entities</h6>
                    <h3>245</h3>
                </li>
                <li class="card">
                    <h6>Active Client Entities</h6>
                    <h3>198</h3>
                </li>
                <li class="card">
                    <h6>Total Employees</h6>
                    <h3>398</h3>
                </li>
                <li class="card">
                    <h6>Assigned Audits (FY-2021)</h6>
                    <h3>194</h3>
                </li>
            </ul>
        </div>
        <div class="firstBox card">
            <div (chartClick)="addChartFilter($event)" echarts [options]="option" class="demo-chart"></div>
        </div>

        <div class="secondBox">
            <ul class="list-inline">
                <li class="card">
                    <div class="PieChartblock">
                        <div echarts [options]="barChartofGS" class="demo-chart"></div>
                    </div>
                </li>
                <li class="card">
                    <div class="PieChartblock">
                        <div echarts [options]="barChartofFormStatus" class="demo-chart"></div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="firstTable">
            <div *ngIf="tableShow" class="card">
                <h2>Underlying Details:</h2>
                <motif-table style="width: 100%;" [columnDefs]="columnDefs1" [rowData]="rowData" rowSelection="multiple" 
                (gridReady)="(handleGridReady)" [suppressRowClickSelection]="true" [suppressCellSelection]="true" 
                [pagination]="true" paginationPageSize="4">
                </motif-table>
            </div>
            <div *ngIf="auditTypeTable" class="card">
                <h2>Underlying Details:</h2>
                <motif-table [columnDefs]="columnDefs2" [rowData]="tempDataforAuditType" rowSelection="multiple" 
                (gridReady)="(handleGridReady)" [suppressRowClickSelection]="true" 
                [suppressCellSelection]="true" [pagination]="true" paginationPageSize="4"></motif-table>
            </div>
        </div>

        <!-- <div class="PieChartblock">
            <div echarts [options]="PieChart" class="demo-chart"></div>
        </div>

        <div class="doughChartblock">
            <div echarts [options]="doughNutChart" class="demo-chart"></div>
        </div> -->





        <!-- <div class="block" *ngIf="tableShow">
            <motif-table [columnDefs]="columnDefs1" [rowData]="rowData" rowSelection="multiple" (gridReady)="(handleGridReady)" [suppressRowClickSelection]="true" [suppressCellSelection]="true" [pagination]="true" paginationPageSize="4"></motif-table>
        </div>
        <div class="block" *ngIf="auditTypeTable">
            <motif-table [columnDefs]="columnDefs2" [rowData]="tempDataforAuditType" rowSelection="multiple" (gridReady)="(handleGridReady)" [suppressRowClickSelection]="true" [suppressCellSelection]="true" [pagination]="true" paginationPageSize="4"></motif-table>
        </div> -->
    </div>
</section>