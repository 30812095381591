<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag" class="playbook-main">

  <div class="motif-row" style="margin-bottom:20px">
    <div class="motif-col-xs-4 motif-col-lg-3">
      <h3 class="motif-h3 motif-col-xs-4 motif-col-md-10">Role Management</h3>
    </div>

    <div class="motif-col-xs-8 motif-col-lg-9 btnrow">
        <button (click)="Add()" size="large" *ngIf="ActionsCheck('Add / Update')" motifButton color="primary" class="addbtn"
          variant="stepper">
          Add Role
          <motif-icon [src]="'/assets/icons/av/ic_playlist_add_24px.svg'"></motif-icon>

        </button>
        <button (click)="ExportExcel()" size="large" class="csresetbutton" motifButton color="primary"><motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
        </button>
    </div>
  </div>






  <div class="motif-table-wrapper" [ngClass]="{'motif-table-first-column-border': true}">
    <ag-grid-angular [ngStyle]="style" [overlayLoadingTemplate]="overlayLoadingTemplate"
      class="ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="tableDataset" [modules]="modules"
      (gridReady)="handleGridReady($event)" [animateRows]="true" [pagination]="true" [paginationPageSize]=30
      [frameworkComponents]="frameworkComponents">
    </ag-grid-angular>

  </div>


  <motif-modal [show]="CreateRoleModelShow" (close)="CreateRoleModelShow = false">
    <motif-modal-header>
      Create Role
    </motif-modal-header>
    <motif-modal-body>
      <form [formGroup]="form">

        <div class="motif-row">
          <div class="motif-col-xs-2">

            <motif-form-field>
              <label motifLabel>Role Name</label>
              <input motifInput type="text" formControlName="roleName" />
              <motif-error *ngIf="form.get('roleName').hasError('required') && form.get('roleName').touched">
                Please provide Role Name!
              </motif-error>
            </motif-form-field>


          </div>
          <div class="motif-col-xs-2">
            <motif-form-field>
              <label motifLabel>Role Description</label>
              <textarea motifInput class="motif-textarea" required formControlName="roledescription"></textarea>

              <motif-error
                *ngIf="form.get('roledescription').hasError('required') &&  form.get('roledescription').touched">
                Please provide role description
              </motif-error>

              <!-- <motif-error [showIcon]="false" *ngIf="roledescription.invalid">Please provide role description</motif-error> -->
            </motif-form-field>
          </div>
        </div>
      </form>
      <div class="">

        <div class="screenToRole">
          <ul class="list-inline parentScreen">

            <li *ngFor="let menu of AddScreenActions">
              <div class="screen">
                <motif-checkbox [(ngModel)]="menu.isChecked" [ngModelOptions]="{standalone: true}"
                  (change)="onScreenActionChange($event,menu)"> {{menu.name}}</motif-checkbox>


              </div>
              <ul class="list-inline" *ngIf="menu.menuScreenAction?.length > 0">
                <li class="divactions" *ngFor="let screen of menu.menuScreenAction">
                  <div class="screen">
                    <motif-checkbox [(ngModel)]="screen.isChecked" [ngModelOptions]="{standalone: true}"
                      (change)="onScreenChange($event, menu, screen)">{{screen.screenName}}</motif-checkbox>

                  </div>
                  <div *ngIf="screen.actions?.length > 0">
                    <div class="actionsSec" *ngFor="let action of screen.actions">
                      <div class="actionI">
                        <motif-checkbox [(ngModel)]="action.isChecked" [ngModelOptions]="{standalone: true}"
                          (change)="onActionChange($event,screen, menu, action)">{{action.actionName}}</motif-checkbox>

                      </div>
                    </div>
                  </div>
                  <div style="margin: 12px 0 0;" *ngIf="screen.screenName=='Audit Assignment'">
                    <motif-form-field>
                      <label motifLabel>EY Region</label>
                      <motif-select (change)="onRegionChange($event,screen, menu)" [multiple]="true"
                        class="ClienentitytModalselect" aria-label="Region Form Field">
                        <!-- <motif-option *ngFor="let item of lstSearchFilterFunction" [value]="item.functionName" >
                              {{item.functionName}}</motif-option> -->
                        <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionId"
                          [checked]="item.isChecked">{{item.eyregionName}}</motif-option>
                      </motif-select>
                    </motif-form-field>
                  </div>
                </li>

              </ul>
            </li>
          </ul>

        </div>

      </div>
    </motif-modal-body>
    <motif-modal-footer>
      <div class="motif-row motif-end-lg">
        <button motifButton color="primary" (click)="save(form)">Save</button>
        <button motifButton color="secondary" (click)="CreateRoleModelShow = false">Cancel</button>

      </div>






    </motif-modal-footer>
  </motif-modal>





  <motif-modal [show]="UpdateRoleModelShow" (close)="UpdateRoleModelShow = false">
    <motif-modal-header>
      Update Role
    </motif-modal-header>
    <motif-modal-body>
      <form [formGroup]="roleupdateform">

        <div class="motif-row">
          <div class="motif-col-xs-2">

            <motif-form-field>
              <label motifLabel>Role Name</label>
              <input style="cursor:not-allowed" readonly motifInput type="text" formControlName="roleName" />
              <motif-error *ngIf="form.get('roleName').hasError('required') && form.get('roleName').touched">
                Please provide Role Name!
              </motif-error>
            </motif-form-field>


          </div>
          <div class="motif-col-xs-2">
            <motif-form-field>
              <label motifLabel>Role Description</label>
              <textarea motifInput class="motif-textarea" required formControlName="roledescription"></textarea>

              <motif-error
                *ngIf="form.get('roledescription').hasError('required') &&  form.get('roledescription').touched">
                Please provide role description
              </motif-error>

              <!-- <motif-error [showIcon]="false" *ngIf="roledescription.invalid">Please provide role description</motif-error> -->
            </motif-form-field>
          </div>
        </div>
      </form>
      <div class="">

        <div class="screenToRole">
          <ul class="list-inline parentScreen">

            <li *ngFor="let menu of UpdateScreenActions">
              <div class="screen">
                <motif-checkbox [(ngModel)]="menu.isChecked" [ngModelOptions]="{standalone: true}"
                  (change)="onScreenActionChange($event,menu)"> {{menu.name}}</motif-checkbox>


              </div>
              <ul class="list-inline" *ngIf="menu.menuScreenAction?.length > 0">
                <li class="divactions" *ngFor="let screen of menu.menuScreenAction">
                  <div class="screen">
                    <motif-checkbox [(ngModel)]="screen.isChecked" [ngModelOptions]="{standalone: true}"
                      (change)="onScreenChange($event, menu, screen)">{{screen.screenName}}</motif-checkbox>

                  </div>
                  <div *ngIf="screen.actions?.length > 0">
                    <div class="actionsSec" *ngFor="let action of screen.actions">
                      <div class="actionI" *ngIf="action.actionName!='EY Region'">
                        <motif-checkbox [(ngModel)]="action.isChecked" [ngModelOptions]="{standalone: true}"
                          (change)="onActionChange($event,screen, menu, action)">{{action.actionName}}</motif-checkbox>

                      </div>
                    </div>
                  </div>
                  <div style="margin: 12px 0 0;" *ngIf="screen.screenName=='Audit Assignment'">
                    <motif-form-field>
                      <label motifLabel>EY Region</label>
                      <motif-select (change)="onRegionChange($event,screen, menu)" [multiple]="true"
                        class="ClienentitytModalselect" aria-label="Region Form Field">
                        <!-- <motif-option *ngFor="let item of lstSearchFilterFunction" [value]="item.functionName" >
                                {{item.functionName}}</motif-option> -->
                        <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionId"
                          [checked]="item.isChecked">{{item.eyregionName}}</motif-option>
                      </motif-select>
                    </motif-form-field>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

        </div>

      </div>
    </motif-modal-body>
    <motif-modal-footer>

      <button motifButton color="primary" (click)="Update(roleupdateform)">Update</button>
      <button motifButton color="secondary" (click)="UpdateRoleModelShow = false">Cancel</button>

    </motif-modal-footer>
  </motif-modal>

</div>

<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
  {{toastmessage}}</motif-toast>




<motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
  <motif-modal-header> Delete Role </motif-modal-header>
  <motif-modal-body>
    <p> Are you sure, you want to delete Role? </p>
  </motif-modal-body>
  <motif-modal-footer>
    <button motifButton color="primary" (click)="Delete()">Confirm</button>
    <button motifButton color="warn" (click)="deletemodalflag = false">Cancel</button>
  </motif-modal-footer>
</motif-modal>


<motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
  <motif-modal-header>
      Confirmation

  </motif-modal-header>
  <motif-modal-body>
    <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
  </motif-modal-body>
  <motif-modal-footer>
      <button motifButton color="primary" (click)="EscapeYes()" type="submit">Yes</button>
     <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
  </motif-modal-footer>
</motif-modal>