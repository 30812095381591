import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuditAssignmentService } from '../audit-assignment/audit-assignment.service';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { end } from '@popperjs/core';
import { NullLogger } from '@microsoft/signalr';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HeaderService } from 'src/app/core/header/header.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-update-audit-assignment',
  templateUrl: './add-update-audit-assignment.component.html',
  styleUrls: ['./add-update-audit-assignment.component.scss']
})
export class AddUpdateAuditAssignmentComponent implements OnInit, AfterViewInit {

  @Output() BackEventEmitter = new EventEmitter<string>();
  ////#region form
  form: FormGroup;
  public entityname: string = "";
  public selectCLientContacts: any;
  public btnflag: boolean = true;
  public isNeccessaryfield:boolean=true;
  public RoleBasedRegion: any;
  public lstYear: any = [];
  public lstClassification: any = [];
  public lstFormStatus: any = [];
  public lstGroupScope: any = [];
  public lstContacts: any = [];
  public lstAPTeammembers: any = [];
  public lstKPTeammembers: any = [];
  public lstRPTeammembers: any = [];
  public lstOTTeammembersEY: any = [];
  public lstOTTeammembersIR: any = [];
  public lstOTTeammembers: any = [];
  public Escmodalflag:boolean=false;



  public lstRegAuditLead: any;
  public lstHolidaysMaster: any;
  public lstPlanningDates: any;
  public lstAudittypeSatus: any;
  public lstAudittypeSatusAp: any;
  public lstAudittypeSatusKp: any;
  public lstAudittypeSatusRp: any;
  public lstAudittypeSatusOt: any;
  public lstInternalAuditEngLeder: any;
  public AuditAssignmentId: any;
  public lstMeetingFormat: any;
  public RegionBasedENabledFlag: boolean = true;
  public endDateRPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };
  public executionEndDateRPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };

  public endDateAPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };
  public executionEndDateAPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };

  public endDateIR1Options = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };
  public endDateIR2Options = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };

  public executionEndDateKPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };

  public endDateOTOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy', firstDayOfWeek:'mo',disableUntil: { year: 0, month: 0, day: 0 } };
  public executionEndDateOTOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: { year: 0, month: 0, day: 0 } };

  ////#endregion
  ////#region Entity based details variables
  public lstEntitydetails: any;
  public arrayCLientEntity: any = [];
  public ngClientEntityID: any;
  ////#endregion

  ////#region toaster and Loader variables
  public toastflag: boolean = false;
  public actionAccess: any = [""];
  public toasttype: string;
  public toastmessage: string;
  ////#endregion

  @Input() Params;
  @Input() auditassign;
  @Input() advanceUpdate;

  public editdata: any;

  startTimeChanged: any;
  endTimeChanged: any;

  constructor(private _auditassignmentService: AuditAssignmentService,private _headerService: HeaderService, private _formBuilder: FormBuilder, private router: Router, private location: Location, private route:ActivatedRoute) { }
  public loaderflag: boolean = false;
  ngOnInit(): void {
    this.form = this.AuditAssignmentForm();
    this.GetEntityDetails();
    this.GetYear();
    this.GetMastersData(this.Params);
    this.ValidationsforAuditTypes();
    this.GetRoleBasedRegion();
    this.isNeccessaryfield=false; 
    this._headerService.getActions("Audit Assignment").then((value) => {

      this.actionAccess = value; 
    })
    //this.editdetails(this.Params);
  }

  ngAfterViewInit(): void {
  }
  ////#region Form methods
  editdetails(data) {

    if(data.auditYear>=2023 || data.auditYear==null)
      this.isNeccessaryfield=false;
    else
      this.isNeccessaryfield=true;
    
    console.log(this.actionAccess.indexOf("Update(Advance)"));
    if (this.actionAccess.indexOf("Update(Advance)") != -1) {
      
      this.advanceUpdate = false;
    }
    else
      this.advanceUpdate = true;
    //this.onchange();
    this.btnflag = false;
    this.AuditAssignmentId = data.auditAssignmentId;
    this.ngClientEntityID = data.clientEnityId;
    let clientcontactsdata = [];
    if (data.clientContactIds != null && data.clientContactIds != "") {
      let clientcontacts = data.clientContactIds.split(',');

      for (let i = 0; i < clientcontacts.length; i++) {
        
        if (this.lstContacts.find(ob => ob.contactId === Number(clientcontacts[i])) != undefined)
          clientcontactsdata.push(this.lstContacts.find(ob => ob.contactId === Number(clientcontacts[i])));
      }
      this.selectCLientContacts = clientcontactsdata;
    }
    console.log(data)
    ;

    this.form.patchValue({
      audityear: data.auditYear,
      entityname: data.clientEntityName,
      entityshortname: data.clientEntityShortName,
      formtitle: data.formTitle,
      classification: data.classification,
      groupscope: data.groupScope,
      formstatus: data.formStatus,
      bankcode: data.bankCodeRgrCid,
      location: data.locationName,
      canton: data.canton,
      reportinglanguage: data.reportingLanguage,
      banksize: data.bankSize,
      eyregion: data.eyregionName,
      iroffice: data.irofficeName,
      regionalleadpartner: data.engagementPartnerID != null ? { contactId: Number(data.engagementPartnerID), contactName: data.engagementPartner } : null,
      //assignedleadauditorRF: data.engagementManagerID != null ? data.engagementManagerID : null,
      //assignedleadauditor: data.engagementManagerID != null ? { contactId: Number(data.engagementManagerID), contactName: data.engagementManager } : null,
      assignedFinmaleadauditor: data.finmaLeadAuditorID != null ? { contactId: Number(data.finmaLeadAuditorID), contactName: data.finmaLeadAuditor } : null,
      internalauditengagementleader: data.internalAuditEngagementLeaderID != null ? { contactId: Number(data.internalAuditEngagementLeaderID), contactName: data.internalAuditEngagementLeader } : null,
      // regionalleadpartnerRF:data.EngagementPartnerID,
      // assignedleadauditorRF:data.EngagementManagerID,
      // assignedFinmaleadauditorRF:data.FinmaLeadAuditorID,
      // internalauditengagementleaderRF:data.InternalAuditEngagementLeaderID,

      financialauditleadAP: data.apFinancialAuditLead != null ? data.apFinancialAuditLead : null,
      auditperiodstartdateAP: data.apAuditPeriodStartDate != null ? data.apAuditPeriodStartDate == null || data.apAuditPeriodStartDate == 'null' ? '' :
        data.apAuditPeriodStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.apAuditPeriodStartDate.split('T')[0].split('-')[0]),
              month: Number(data.apAuditPeriodStartDate.split('T')[0].split('-')[1]),
              day: Number(data.apAuditPeriodStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.apAuditPeriodStartDate.split('T')[0]
          }
        } : null,
      auditperiodenddateAP: data.apAuditPeriodEndDate != null ? data.apAuditPeriodEndDate == null || data.apAuditPeriodEndDate == 'null' ? '' :
        data.apAuditPeriodEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.apAuditPeriodEndDate.split('T')[0].split('-')[0]),
              month: Number(data.apAuditPeriodEndDate.split('T')[0].split('-')[1]),
              day: Number(data.apAuditPeriodEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.apAuditPeriodEndDate.split('T')[0]
          }
        } : null,
      // regionalleadpartnerAP: data.apRegionalLeadPartnerId != null ? data.apRegionalLeadPartnerId : null,
      // assignedleadauditorAP: data.apAssignedLeadAuditorId != null ? { contactId: Number(data.apAssignedLeadAuditorId), contactName: data.apAssignedLeadAuditor } : null,
      auditexecutionstartdateAP: data.apAuditExecutionStartDate != null ? data.apAuditExecutionStartDate == null || data.apAuditType.apAuditExecutionStartDate == 'null' ? '' :
        data.apAuditExecutionStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.apAuditExecutionStartDate.split('T')[0].split('-')[0]),
              month: Number(data.apAuditExecutionStartDate.split('T')[0].split('-')[1]),
              day: Number(data.apAuditExecutionStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.apAuditExecutionStartDate.split('T')[0]
          }
        } : null,
      auditexecutionenddateAP: data.apAuditExecutionEndDate != null ? data.apAuditExecutionEndDate == null || data.apAuditExecutionEndDate == 'null' ? '' :
        data.apAuditExecutionEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.apAuditExecutionEndDate.split('T')[0].split('-')[0]),
              month: Number(data.apAuditExecutionEndDate.split('T')[0].split('-')[1]),
              day: Number(data.apAuditExecutionEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.apAuditExecutionEndDate.split('T')[0]
          }
        } : null,
      idcodeAP: data.apAuditIdcode != null ? data.apAuditIdcode : data.bankCodeRgrCid + "AP" + data.auditYear?.toString().slice(2, 4),
      //  internalauditengagementleaderAP: data.apInternalAuditEngagementLeaderId != null ? { contactId: Number(data.apInternalAuditEngagementLeaderId), contactName: data.apInternalAuditEngagementLeader } : null,
      AuditTypeAP: data.apAuditType != null ? data.apAuditType == "AP" ? true : false : null,
      iaworkcompletedAP: data.apIaworkCompleted != null ? data.apIaworkCompleted == null || data.apIaworkCompleted == 'null' ? '' :
        data.apIaworkCompleted.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.apIaworkCompleted.split('T')[0].split('-')[0]),
              month: Number(data.apIaworkCompleted.split('T')[0].split('-')[1]),
              day: Number(data.apIaworkCompleted.split('T')[0].split('-')[2])
            },
            formatted: data.apIaworkCompleted.split('T')[0]
          }
        } : null,
      announcementdeliveryAP: data.apAnnouncementDelivery == null ? null : data.apAnnouncementDelivery.split('T')[0].split('-')[2] + '.' + data.apAnnouncementDelivery.split('T')[0].split('-')[1] + '.' + data.apAnnouncementDelivery.split('T')[0].split('-')[0],
      clientdatadueAP: data.apClientDataDue == null ? null : data.apClientDataDue.split('T')[0].split('-')[2] + '.' + data.apClientDataDue.split('T')[0].split('-')[1] + '.' + data.apClientDataDue.split('T')[0].split('-')[0],
      draftreportAP: data.apDraftReport == null ? null : data.apDraftReport.split('T')[0].split('-')[2] + '.' + data.apDraftReport.split('T')[0].split('-')[1] + '.' + data.apDraftReport.split('T')[0].split('-')[0],
      iaworkpaperreviewdueAP: data.apIaworkPaperReviewDue == null ? null : data.apIaworkPaperReviewDue.split('T')[0].split('-')[2] + '.' + data.apIaworkPaperReviewDue.split('T')[0].split('-')[1] + '.' + data.apIaworkPaperReviewDue.split('T')[0].split('-')[0],
      draftreporttoclientdueAP: data.apDraftReportToClientDue == null ? null : data.apDraftReportToClientDue.split('T')[0].split('-')[2] + '.' + data.apDraftReportToClientDue.split('T')[0].split('-')[1] + '.' + data.apDraftReportToClientDue.split('T')[0].split('-')[0],
      clientfeedbackdueAP: data.apClientFeedbackDue == null ? null : data.apClientFeedbackDue.split('T')[0].split('-')[2] + '.' + data.apClientFeedbackDue.split('T')[0].split('-')[1] + '.' + data.apClientFeedbackDue.split('T')[0].split('-')[0],
      finalreporttoclientdueAP: data.apFinalReportToClientDue == null ? null : data.apFinalReportToClientDue.split('T')[0].split('-')[2] + '.' + data.apFinalReportToClientDue.split('T')[0].split('-')[1] + '.' + data.apFinalReportToClientDue.split('T')[0].split('-')[0],
      statusidAP: data.aPstatus == null ? null : data.aPstatus.statusid,
      regAuditCoSign: data.regAuditCoSign != null ? { contactId: Number(data.regAuditCoSignId), contactName: data.regAuditCoSign } : null,

      financialauditleadKP: data.kpFinancialAuditLead != null ? data.kpFinancialAuditLead : null,
      // assignedleadauditorKP: data.kpAssignedLeadAuditorId != null ? { contactId: Number(data.kpAssignedLeadAuditorId), contactName: data.kpAssignedLeadAuditor } : null,
      auditexecutionstartdateKP: data.kpAuditExecutionStartDate != null ? data.kpAuditExecutionStartDate == null || data.kpAuditExecutionStartDate == 'null' ? '' :
        data.kpAuditExecutionStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.kpAuditExecutionStartDate.split('T')[0].split('-')[0]),
              month: Number(data.kpAuditExecutionStartDate.split('T')[0].split('-')[1]),
              day: Number(data.kpAuditExecutionStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.kpAuditExecutionStartDate.split('T')[0]
          }
        } : null,
      auditexecutionenddateKP: data.kpAuditExecutionEndDate != null ? data.kpAuditExecutionEndDate == null || data.kpAuditExecutionEndDate == 'null' ? '' :
        data.kpAuditExecutionEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.kpAuditExecutionEndDate.split('T')[0].split('-')[0]),
              month: Number(data.kpAuditExecutionEndDate.split('T')[0].split('-')[1]),
              day: Number(data.kpAuditExecutionEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.kpAuditExecutionEndDate.split('T')[0]
          }
        } : null,
      idcodeKP: data.kpAuditIdcode != null ? data.kpAuditIdcode : data.bankCodeRgrCid + "KP" + data.auditYear?.toString().slice(2, 4),
      AuditTypeKP: data.kpAuditType != null ? data.kpAuditType == "KP" ? true : false : null,

      iaworkcompletedKP: data.kpIaworkCompleted != null ? data.kpIaworkCompleted == null || data.kpIaworkCompleted == 'null' ? '' :
        data.kpIaworkCompleted.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.kpIaworkCompleted.split('T')[0].split('-')[0]),
              month: Number(data.kpIaworkCompleted.split('T')[0].split('-')[1]),
              day: Number(data.kpIaworkCompleted.split('T')[0].split('-')[2])
            },
            formatted: data.kpIaworkCompleted.split('T')[0]
          }
        } : null,
      announcementdeliveryKP: data.kpAnnouncementDelivery == null ? null : data.kpAnnouncementDelivery.split('T')[0].split('-')[2] + '.' + data.kpAnnouncementDelivery.split('T')[0].split('-')[1] + '.' + data.kpAnnouncementDelivery.split('T')[0].split('-')[0],
      clientdatadueKP: data.kpClientDataDue == null ? null : data.kpClientDataDue.split('T')[0].split('-')[2] + '.' + data.kpClientDataDue.split('T')[0].split('-')[1] + '.' + data.kpClientDataDue.split('T')[0].split('-')[0],
      draftreportKP: data.kpDraftReport == null ? null : data.kpDraftReport.split('T')[0].split('-')[2] + '.' + data.kpDraftReport.split('T')[0].split('-')[1] + '.' + data.kpDraftReport.split('T')[0].split('-')[0],
      iaworkpaperreviewdueKP: data.kpIaworkPaperReviewDue == null ? null : data.kpIaworkPaperReviewDue.split('T')[0].split('-')[2] + '.' + data.kpIaworkPaperReviewDue.split('T')[0].split('-')[1] + '.' + data.kpIaworkPaperReviewDue.split('T')[0].split('-')[0],
      draftreporttoclientdueKP: data.kpDraftReportToClientDue == null ? null : data.kpDraftReportToClientDue.split('T')[0].split('-')[2] + '.' + data.kpDraftReportToClientDue.split('T')[0].split('-')[1] + '.' + data.kpDraftReportToClientDue.split('T')[0].split('-')[0],
      clientfeedbackdueKP: data.kpClientFeedbackDue == null ? null : data.kpClientFeedbackDue.split('T')[0].split('-')[2] + '.' + data.kpClientFeedbackDue.split('T')[0].split('-')[1] + '.' + data.kpClientFeedbackDue.split('T')[0].split('-')[0],
      finalreporttoclientdueKP: data.kpFinalReportToClientDue == null ? null : data.kpFinalReportToClientDue.split('T')[0].split('-')[2] + '.' + data.kpFinalReportToClientDue.split('T')[0].split('-')[1] + '.' + data.kpFinalReportToClientDue.split('T')[0].split('-')[0],
      // regionalleadpartnerRF: data.rfRegionalLeadPartnerId != null ? data.rfRegionalLeadPartnerId : null,
      statusidKP: data.kPstatus == null ? null : data.kPstatus.statusid,
      kpEngagementManager: data.kpEngagementManager != null ? { contactId: Number(data.kpEngagementManagerId), contactName: data.kpEngagementManager } : null,
      // assignedleadauditorRP: data.rpAssignedLeadAuditorId != null ? { contactId: Number(data.rpAssignedLeadAuditorId), contactName: data.rpAssignedLeadAuditor } : null,
      financialauditleadRP: data.rpFinancialAuditLead,
      auditperiodstartdateRP: data.rpAuditPeriodStartDate != null ? data.rpAuditPeriodStartDate == null || data.rpAuditPeriodStartDate == 'null' ? '' :
        data.rpAuditPeriodStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.rpAuditPeriodStartDate.split('T')[0].split('-')[0]),
              month: Number(data.rpAuditPeriodStartDate.split('T')[0].split('-')[1]),
              day: Number(data.rpAuditPeriodStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.rpAuditPeriodStartDate.split('T')[0]
          }
        } : null,
      auditperiodenddateRP: data.rpAuditPeriodEndDate != null ? data.rpAuditPeriodEndDate == null || data.rpAuditPeriodEndDate == 'null' ? '' :
        data.rpAuditPeriodEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.rpAuditPeriodEndDate.split('T')[0].split('-')[0]),
              month: Number(data.rpAuditPeriodEndDate.split('T')[0].split('-')[1]),
              day: Number(data.rpAuditPeriodEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.rpAuditPeriodEndDate.split('T')[0]
          }
        } : null,
      // regionalleadpartnerRP: data.rpRegionalLeadPartnerId != null ? data.rpRegionalLeadPartnerId : null,
      //assignedleadauditorRP: data.rpAssignedLeadAuditorId != null ? { contactId: Number(data.rpAssignedLeadAuditorId), contactName: data.rpAssignedLeadAuditor } : null,
      auditexecutionstartdateRP: data.rpAuditExecutionStartDate != null ? data.rpAuditExecutionStartDate == null || data.rpAuditExecutionStartDate == 'null' ? '' :
        data.rpAuditExecutionStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.rpAuditExecutionStartDate.split('T')[0].split('-')[0]),
              month: Number(data.rpAuditExecutionStartDate.split('T')[0].split('-')[1]),
              day: Number(data.rpAuditExecutionStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.rpAuditExecutionStartDate.split('T')[0]
          }
        } : null,
      auditexecutionenddateRP: data.rpAuditExecutionEndDate != null ? data.rpAuditExecutionEndDate == null || data.rpAuditExecutionEndDate == 'null' ? '' :
        data.rpAuditExecutionEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.rpAuditExecutionEndDate.split('T')[0].split('-')[0]),
              month: Number(data.rpAuditExecutionEndDate.split('T')[0].split('-')[1]),
              day: Number(data.rpAuditExecutionEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.rpAuditExecutionEndDate.split('T')[0]
          }
        } : null,
      idcodeRP: data.rpAuditIdcode != null ? data.rpAuditIdcode : data.bankCodeRgrCid + "RP" + data.auditYear?.toString().slice(2, 4),
      AuditTypeRP: data.rpAuditType != null ? data.rpAuditType == "RP" ? true : false : null,
      // internalauditengagementleaderRP: data.rpInternalAuditEngagementLeaderId != null ? { contactId: Number(data.rpInternalAuditEngagementLeaderId), contactName: data.rpInternalAuditEngagementLeader } : null,
      iaworkcompletedRP: data.rpIaworkCompleted != null ? data.rpIaworkCompleted == null || data.rpIaworkCompleted == 'null' ? '' :
        data.rpIaworkCompleted.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.rpIaworkCompleted.split('T')[0].split('-')[0]),
              month: Number(data.rpIaworkCompleted.split('T')[0].split('-')[1]),
              day: Number(data.rpIaworkCompleted.split('T')[0].split('-')[2])
            },
            formatted: data.rpIaworkCompleted.split('T')[0]
          }
        } : null,
        decisivedate: data.decisiveDate != null ? data.decisiveDate == null || data.decisiveDate == 'null' ? '' :
        data.decisiveDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.decisiveDate.split('T')[0].split('-')[0]),
              month: Number(data.decisiveDate.split('T')[0].split('-')[1]),
              day: Number(data.decisiveDate.split('T')[0].split('-')[2])
            },
            formatted: data.decisiveDate.split('T')[0]
          }
        } : null,
        latestSignoffDate: data.latestSignoffDate != null ? data.latestSignoffDate == null || data.latestSignoffDate == 'null' ? '' :
        data.latestSignoffDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.latestSignoffDate.split('T')[0].split('-')[0]),
              month: Number(data.latestSignoffDate.split('T')[0].split('-')[1]),
              day: Number(data.latestSignoffDate.split('T')[0].split('-')[2])
            },
            formatted: data.latestSignoffDate.split('T')[0]
          }
        } : null,
        actualSignoffDate: data.actualSignoffDate != null ? data.actualSignoffDate == null || data.actualSignoffDate == 'null' ? '' :
        data.actualSignoffDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.actualSignoffDate.split('T')[0].split('-')[0]),
              month: Number(data.actualSignoffDate.split('T')[0].split('-')[1]),
              day: Number(data.actualSignoffDate.split('T')[0].split('-')[2])
            },
            formatted: data.actualSignoffDate.split('T')[0]
          }
        } : null,
      announcementdeliveryRP: data.rpAnnouncementDelivery == null ? null : data.rpAnnouncementDelivery.split('T')[0].split('-')[2] + '.' + data.rpAnnouncementDelivery.split('T')[0].split('-')[1] + '.' + data.rpAnnouncementDelivery.split('T')[0].split('-')[0],
      clientdatadueRP: data.rpClientDataDue == null ? null : data.rpClientDataDue.split('T')[0].split('-')[2] + '.' + data.rpClientDataDue.split('T')[0].split('-')[1] + '.' + data.rpClientDataDue.split('T')[0].split('-')[0],
      draftreportRP: data.rpDraftReport == null ? null : data.rpDraftReport.split('T')[0].split('-')[2] + '.' + data.rpDraftReport.split('T')[0].split('-')[1] + '.' + data.rpDraftReport.split('T')[0].split('-')[0],
      iaworkpaperreviewdueRP: data.rpIaworkPaperReviewDue == null ? null : data.rpIaworkPaperReviewDue.split('T')[0].split('-')[2] + '.' + data.rpIaworkPaperReviewDue.split('T')[0].split('-')[1] + '.' + data.rpIaworkPaperReviewDue.split('T')[0].split('-')[0],
      draftreporttoclientdueRP: data.rpDraftReportToClientDue == null ? null : data.rpDraftReportToClientDue.split('T')[0].split('-')[2] + '.' + data.rpDraftReportToClientDue.split('T')[0].split('-')[1] + '.' + data.rpDraftReportToClientDue.split('T')[0].split('-')[0],
      clientfeedbackdueRP: data.rpClientFeedbackDue == null ? null : data.rpClientFeedbackDue.split('T')[0].split('-')[2] + '.' + data.rpClientFeedbackDue.split('T')[0].split('-')[1] + '.' + data.rpClientFeedbackDue.split('T')[0].split('-')[0],
      finalreporttoclientdueRP: data.rpFinalReportToClientDue == null ? null : data.rpFinalReportToClientDue.split('T')[0].split('-')[2] + '.' + data.rpFinalReportToClientDue.split('T')[0].split('-')[1] + '.' + data.rpFinalReportToClientDue.split('T')[0].split('-')[0],
      statusidRP: data.rPstatus == null ? null : data.rPstatus.statusid,
      finAuditCoSign: data.finAuditCoSign != null ? { contactId: Number(data.finAuditCoSignId), contactName: data.finAuditCoSign } : null,
      meetingFormat: data.meetingFormatId,
      agmDate: data.agmDate != null ? data.agmDate == null || data.agmDate == 'null' ? '' :
        data.agmDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.agmDate.split('T')[0].split('-')[0]),
              month: Number(data.agmDate.split('T')[0].split('-')[1]),
              day: Number(data.agmDate.split('T')[0].split('-')[2])
            },
            formatted: data.agmDate.split('T')[0]
          }
        } : null,
      startTime: data.startTime == null ? null : data.startTime,
      endTime: data.endTime == null ? null : data.endTime,
      agmLocation: data.agmLocation == null ? null : data.agmLocation,
      eyRepresentative: data.eyRepresentative != null ? { contactId: Number(data.eyRepresentativeId), contactName: data.eyRepresentative } : null,
      agmNotes: data.notes == null ? null : data.notes,
      financialauditleadOther: data.otFinancialAuditLead,
      // if(financialauditleadOther='EY'){
      //   this.lstOTTeammembers = this.lstOTTeammembersEY

      // }
      // else if(financialauditleadOther='IR'){
      //   this.lstOTTeammembers = this.lstOTTeammembersIR
      // },
      auditperiodstartdateOther: data.otAuditPeriodStartDate != null ? data.otAuditPeriodStartDate == null || data.otAuditPeriodStartDate == 'null' ? '' :
        data.otAuditPeriodStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.otAuditPeriodStartDate.split('T')[0].split('-')[0]),
              month: Number(data.otAuditPeriodStartDate.split('T')[0].split('-')[1]),
              day: Number(data.otAuditPeriodStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.otAuditPeriodStartDate.split('T')[0]
          }
        } : null,
      auditperiodenddateOther: data.otAuditPeriodEndDate != null ? data.otAuditPeriodEndDate == null || data.otAuditPeriodEndDate == 'null' ? '' :
        data.otAuditPeriodEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.otAuditPeriodEndDate.split('T')[0].split('-')[0]),
              month: Number(data.otAuditPeriodEndDate.split('T')[0].split('-')[1]),
              day: Number(data.otAuditPeriodEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.otAuditPeriodEndDate.split('T')[0]
          }
        } : null,

      regionalleadpartnerOther: data.otRegionalLeadPartnerId != null ? { contactId: Number(data.otRegionalLeadPartnerId), contactName: data.otRegionalLeadPartner, contactTypeName: data.otFinancialAuditLead } : null,
      // regionalleadpartnerOther: data.otRegionalLeadPartnerId != null ? { contactId: Number(data.otRegionalLeadPartnerId)} : null,
      //regionalleadpartnerOther: data.otRegionalLeadPartner,
      //data.otRegionalLeadPartnerId != null ? { contactId: Number(data.otRegionalLeadPartnerId), contactName: data.otRegionalLeadPartner } : null
      assignedleadauditorOther: data.otAssignedLeadAuditorId != null ? { contactId: Number(data.otAssignedLeadAuditorId), contactName: data.otAssignedLeadAuditor, contactTypeName: data.otFinancialAuditLead } : null,
      auditexecutionstartdateOther: data.otAuditExecutionStartDate != null ? data.otAuditExecutionStartDate == null || data.otAuditExecutionStartDate == 'null' ? '' :
        data.otAuditExecutionStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.otAuditExecutionStartDate.split('T')[0].split('-')[0]),
              month: Number(data.otAuditExecutionStartDate.split('T')[0].split('-')[1]),
              day: Number(data.otAuditExecutionStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.otAuditExecutionStartDate.split('T')[0]
          }
        } : null,
      auditexecutionenddateOther: data.otAuditExecutionEndDate != null ? data.otAuditExecutionEndDate == null || data.otAuditExecutionEndDate == 'null' ? '' :
        data.otAuditExecutionEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.otAuditExecutionEndDate.split('T')[0].split('-')[0]),
              month: Number(data.otAuditExecutionEndDate.split('T')[0].split('-')[1]),
              day: Number(data.otAuditExecutionEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.otAuditExecutionEndDate.split('T')[0]
          }
        } : null,
      idcodeOther: data.otAuditIdcode != null ? data.otAuditIdcode : data.bankCodeRgrCid + "OT" + data.auditYear?.toString().slice(2, 4),
      AuditTypeOther: data.otAuditType != null ? data.otAuditType == "OT" ? true : false : null,

      iaworkcompletedOT: data.otIaworkCompleted != null ? data.otIaworkCompleted == null || data.otIaworkCompleted == 'null' ? '' :
        data.otIaworkCompleted.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.otIaworkCompleted.split('T')[0].split('-')[0]),
              month: Number(data.otIaworkCompleted.split('T')[0].split('-')[1]),
              day: Number(data.otIaworkCompleted.split('T')[0].split('-')[2])
            },
            formatted: data.otIaworkCompleted.split('T')[0]
          }
        } : null,
      announcementdeliveryOT: data.otAnnouncementDelivery == null ? null : data.otAnnouncementDelivery.split('T')[0].split('-')[2] + '.' + data.otAnnouncementDelivery.split('T')[0].split('-')[1] + '.' + data.otAnnouncementDelivery.split('T')[0].split('-')[0],
      clientdatadueOT: data.otClientDataDue == null ? null : data.otClientDataDue.split('T')[0].split('-')[2] + '.' + data.otClientDataDue.split('T')[0].split('-')[1] + '.' + data.otClientDataDue.split('T')[0].split('-')[0],
      draftreportOT: data.otDraftReport == null ? null : data.otDraftReport.split('T')[0].split('-')[2] + '.' + data.otDraftReport.split('T')[0].split('-')[1] + '.' + data.otDraftReport.split('T')[0].split('-')[0],
      iaworkpaperreviewdueOT: data.otIaworkPaperReviewDue == null ? null : data.otIaworkPaperReviewDue.split('T')[0].split('-')[2] + '.' + data.otIaworkPaperReviewDue.split('T')[0].split('-')[1] + '.' + data.otIaworkPaperReviewDue.split('T')[0].split('-')[0],
      draftreporttoclientdueOT: data.otDraftReportToClientDue == null ? null : data.otDraftReportToClientDue.split('T')[0].split('-')[2] + '.' + data.otDraftReportToClientDue.split('T')[0].split('-')[1] + '.' + data.otDraftReportToClientDue.split('T')[0].split('-')[0],
      clientfeedbackdueOT: data.otClientFeedbackDue == null ? null : data.otClientFeedbackDue.split('T')[0].split('-')[2] + '.' + data.otClientFeedbackDue.split('T')[0].split('-')[1] + '.' + data.otClientFeedbackDue.split('T')[0].split('-')[0],
      finalreporttoclientdueOT: data.otFinalReportToClientDue == null ? null : data.otFinalReportToClientDue.split('T')[0].split('-')[2] + '.' + data.otFinalReportToClientDue.split('T')[0].split('-')[1] + '.' + data.otFinalReportToClientDue.split('T')[0].split('-')[0],
      statusidOT: data.oTstatus == null ? null : data.oTstatus.statusid,
      Comment: data.comment,
      AuditTypeIA: data.internalAuditType != null ? data.internalAuditType == "IR" ? true : false : null,
      internalAudit1: data.iaAudit1,
      ia1periodstartdate: data.iaAudit1ExecutionStartDate != null ? data.iaAudit1ExecutionStartDate == null || data.iaAudit1ExecutionStartDate == 'null' ? '' :
        data.iaAudit1ExecutionStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.iaAudit1ExecutionStartDate.split('T')[0].split('-')[0]),
              month: Number(data.iaAudit1ExecutionStartDate.split('T')[0].split('-')[1]),
              day: Number(data.iaAudit1ExecutionStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.iaAudit1ExecutionStartDate.split('T')[0]
          }
        } : null,
      ia1periodenddate: data.iaAudit1ExecutionEndDate != null ? data.iaAudit1ExecutionEndDate == null || data.iaAudit1ExecutionEndDate == 'null' ? '' :
        data.iaAudit1ExecutionEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.iaAudit1ExecutionEndDate.split('T')[0].split('-')[0]),
              month: Number(data.iaAudit1ExecutionEndDate.split('T')[0].split('-')[1]),
              day: Number(data.iaAudit1ExecutionEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.iaAudit1ExecutionEndDate.split('T')[0]
          }
        } : null,
      internalAudit2: data.iaAudit2,
      ia2periodstartdate: data.iaAudit2ExecutionStartDate != null ? data.iaAudit2ExecutionStartDate == null || data.iaAudit2ExecutionStartDate == 'null' ? '' :
        data.iaAudit2ExecutionStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.iaAudit2ExecutionStartDate.split('T')[0].split('-')[0]),
              month: Number(data.iaAudit2ExecutionStartDate.split('T')[0].split('-')[1]),
              day: Number(data.iaAudit2ExecutionStartDate.split('T')[0].split('-')[2])
            },
            formatted: data.iaAudit2ExecutionStartDate.split('T')[0]
          }
        } : null,
      ia2periodenddate: data.iaAudit2ExecutionEndDate != null ? data.iaAudit2ExecutionEndDate == null || data.iaAudit2ExecutionEndDate == 'null' ? '' :
        data.iaAudit2ExecutionEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(data.iaAudit2ExecutionEndDate.split('T')[0].split('-')[0]),
              month: Number(data.iaAudit2ExecutionEndDate.split('T')[0].split('-')[1]),
              day: Number(data.iaAudit2ExecutionEndDate.split('T')[0].split('-')[2])
            },
            formatted: data.iaAudit2ExecutionEndDate.split('T')[0]
          }
        } : null
    });
  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }
  private AuditAssignmentForm() {
    return this._formBuilder.group({
      audityear: [null, [Validators.required]],
      entityname: [null, [Validators.required]],
      formtitle: [],
      classification: [null, [Validators.required]],
      groupscope: [null, [Validators.required]],
      clientcontacts: [],
      formstatus: [null, [Validators.required]],
      entityshortname: [],
      bankcode: [],
      location: [],
      canton: [],
      reportinglanguage: [],
      banksize: [],
      eyregion: [],
      iroffice: [],
      regionalleadpartner: [null, [Validators.required]],
      //assignedleadauditor: [null, [Validators.required]],
      assignedFinmaleadauditor: [null, [Validators.required]],
      internalauditengagementleader: [],
      financialauditleadAP: [],
      internalAudit1: [],
      internalAudit2: [],
      auditperiodstartdateAP: [],
      auditperiodenddateAP: [],
      ia1periodstartdate: [],
      ia1periodenddate: [],
      ia2periodstartdate: [],
      ia2periodenddate: [],
      // regionalleadpartnerAP: [],
      // assignedleadauditorAP: [],
      // assignedauditteammembersAP:[],
      auditexecutionstartdateAP: [],
      auditexecutionenddateAP: [],
      idcodeAP: [],
      iaworkcompletedAP: [],
      announcementdeliveryAP: [],
      clientdatadueAP: [],
      draftreportAP: [],
      iaworkpaperreviewdueAP: [],
      draftreporttoclientdueAP: [],
      clientfeedbackdueAP: [],
      finalreporttoclientdueAP: [],
      // internalauditengagementleaderAP: [],
      statusidAP: [],
      financialauditleadRP: [],
      auditperiodstartdateRP: [],
      auditperiodenddateRP: [],
      //regionalleadpartnerRP: [],
      //assignedleadauditorRP: [],
      auditexecutionstartdateRP: [],
      auditexecutionenddateRP: [],
      //internalauditengagementleaderRP: [],
      idcodeRP: [],
      iaworkcompletedRP: [],
      decisivedate:[],
      latestSignoffDate:[],
      actualSignoffDate:[],
      announcementdeliveryRP: [],
      clientdatadueRP: [],
      draftreportRP: [],
      iaworkpaperreviewdueRP: [],
      draftreporttoclientdueRP: [],
      clientfeedbackdueRP: [],
      finalreporttoclientdueRP: [],
      statusidRP: [],
      meetingFormat: [],
      agmDate: [],
      startTime: [],
      endTime: [],
      agmLocation: [],
      agmNotes: [],
      eyRepresentative: [],
      regAuditCoSign:[],
      finAuditCoSign:[],
      kpEngagementManager:[],
      financialauditleadKP: [],
      // assignedleadauditorKP: [],
      // assignedauditteammembersKP:[],
      auditexecutionstartdateKP: [],
      auditexecutionenddateKP: [],
      idcodeKP: [],
      iaworkcompletedKP: [],
      announcementdeliveryKP: [],
      clientdatadueKP: [],
      draftreportKP: [],
      iaworkpaperreviewdueKP: [],
      draftreporttoclientdueKP: [],
      clientfeedbackdueKP: [],
      finalreporttoclientdueKP: [],
      statusidKP: [],
      AuditTypeRP: [],
      AuditTypeAP: [],
      AuditTypeKP: [],
      AuditTypeIA: [],
      AuditTypeOther: [],
      financialauditleadOther: [],
      auditperiodstartdateOther: [],
      auditperiodenddateOther: [],
      regionalleadpartnerOther: [],
      assignedleadauditorOther: [],
      //  assignedauditteammembersOther:[],
      auditexecutionstartdateOther: [],
      auditexecutionenddateOther: [],
      idcodeOther: [],
      iaworkcompletedOT: [],
      announcementdeliveryOT: [],
      clientdatadueOT: [],
      draftreportOT: [],
      iaworkpaperreviewdueOT: [],
      draftreporttoclientdueOT: [],
      clientfeedbackdueOT: [],
      finalreporttoclientdueOT: [],
      statusidOT: [],

      Comment: []
    });

  }
  ////#endregion

  ////#region  Entity based details methods
  GetEntityDetails() {
    //this.toastflag=false;
    this._auditassignmentService.GetEntityDetails()
      .subscribe(
        res => {
          this.lstEntitydetails = res;
          this.lstEntitydetails.forEach(element => {
            this.arrayCLientEntity.push(element.clientEntityName);
          });
        },
        err => {
          console.log(err);
        }
      )
  }
  FillEntityBaseDetails() {

    let data = this.lstEntitydetails.find(ob => ob.clientEntityName === this.form.value.entityname);
    if (data !== undefined) {
      this.form.patchValue({
        entityshortname: data.rbShortName,
        bankcode: data.bankCodeRgrCid,
        clientId: data.mercuryEntityId,
        location: data.locationName,
        canton: data.cantonName,
        reportinglanguage: data.reportingLanguage,
        documentationlanguage: data.documentationLanguage,
        banksize: data.bankSize,
        eyregion: data.eyregionName,
        iroffice: data.irofficeName,


      });

    } else {
      this.ngClientEntityID = 0;
      this.form.patchValue({
        entityname: "",
        EYCID: "",
        bankcode: "",
        clientId: "",
        location: "",
        canton: "",
        reportinglanguage: "",
        documentationlanguage: "",
        banksize: "",
        eyregion: "",
        iroffice: ""
      });

    }
    
    if (data != undefined) {
      this.ngClientEntityID = data.clientEntityId;
      
      if (this.RoleBasedRegion[0]["regions"].toString().indexOf(data.eyregionId) != -1) {
        this.RegionBasedENabledFlag = false;
      }
      else {
        this.RegionBasedENabledFlag = true;
        this.form.disable();
      }
      let year=null;
      this.GenerateFormTitle(year);
    }



  }
  GenerateFormTitle(year) {

    if(year>=2023 || year==null){
      this.isNeccessaryfield=false;
    }
    else{
      this.isNeccessaryfield=true;
    }


    if (this.form.value.entityname != null && this.form.value.entityname != "" && this.form.value.audityear != null) {
      this.form.patchValue({
        formtitle: this.form.value.audityear + ' | ' + this.form.value.entityshortname,
        idcodeAP: this.form.value.bankcode + "AP" + this.form.value.audityear.toString().slice(2, 4),
        idcodeRP: this.form.value.bankcode + "RP" + this.form.value.audityear.toString().slice(2, 4),
        idcodeKP: this.form.value.bankcode + "KP" + this.form.value.audityear.toString().slice(2, 4),
        idcodeOther: this.form.value.bankcode + "OT" + this.form.value.audityear.toString().slice(2, 4)
      });
    } else {
      this.form.patchValue({
        formtitle: "",
        idcodeAP: "",
        idcodeRP: "",
        idcodeKP: "",
        idcodeOther: ""
      });
    }
    this.form.patchValue({
      auditexecutionstartdateRP: null,
      auditexecutionenddateRP: null,
      iaworkcompletedRP: null,
      decisivedate: null,
      latestSignoffDate: null,
      actualSignoffDate: null,
    });

  }
  GetYear() {
    var year = new Date().getFullYear();
    year = 2021;
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
      this.lstYear.push(year + i);
    }
  }
  public pageload: boolean = false;
  GetMastersData(data) {

    this._auditassignmentService.GetMastersData()
      .subscribe(
        res => {
          
          console.log(res)
          // console.time("response");
          this.lstClassification = res[0];
          this.lstGroupScope = res[1];
          this.lstFormStatus = res[2];
          this.lstRegAuditLead = res[9];
          this.lstHolidaysMaster = res[10];
          this.lstPlanningDates = res[11];
          this.lstAudittypeSatus = res[12];
          this.lstAudittypeSatusAp = res[15];
          this.lstAudittypeSatusKp = res[16];
          this.lstAudittypeSatusRp = res[17];
          this.lstAudittypeSatusOt = res[18];
          this.lstMeetingFormat = res[19];
          this.lstContacts = res[3];
          this.lstKPTeammembers = res[4];
          this.lstAPTeammembers = res[5];

          this.lstRPTeammembers = res[6];
          this.lstOTTeammembersEY = res[7];
          this.lstOTTeammembersIR = res[8];
          this.lstInternalAuditEngLeder = res[13];

          this.RoleBasedRegion = res[14];
          
          this.pageload = true;
          //console.timeEnd("response");
          if (data != null) {
            if (data.length == undefined) {
              // let clientcontacts = data.clientContactIds.split(',').map(x => +x);
              // this.lstContacts.forEach(element => {
              //   element["isChecked"] = clientcontacts.indexOf(element.contactId) > -1 ? true : false;
              // });
              if (data.otFinancialAuditLead == 'EY') {

                this.lstOTTeammembers = res[7];

              }
              else if (data.otFinancialAuditLead == 'IR') {
                this.lstOTTeammembers = res[8];
              }


              if (this.RoleBasedRegion[0]["regions"] != null) {
                if (this.RoleBasedRegion[0]["regions"].toString().indexOf(data.eyregionId) != -1) {
                  this.RegionBasedENabledFlag = false;
                }
                else {
                  this.form.disable();
                  this.RegionBasedENabledFlag = true;
                }
              }
              else {
                this.RegionBasedENabledFlag = true;
                this.form.disable();
              }
              
              this.editdetails(data);
            }
          }
        },
        err => {
          console.log(err);
        }
      )
  }

  onStartTimeChanged(time) {
    this.startTimeChanged = time;
  }

  onEndTimeChanged(time) {
    this.endTimeChanged = time;
  }

  UpdateAuditAssignment() {
    //this.toastflag=false;
    
    this.validateForm(this.form);
    if (!this.form.valid)
      return
    if (!this.form.value.AuditTypeRP && !this.form.value.AuditTypeAP && !this.form.value.AuditTypeOther && !this.form.value.AuditTypeKP && !this.form.value.AuditTypeIA)
      return
    let lstAudittype: any = [];

    if (this.form.value.AuditTypeAP) {

      var APobj = {
        AuditType: "AP",
        FinancialAuditLead: this.form.value.financialauditleadAP,
        RegionalLeadPartnerId: this.form.value.regionalleadpartner.contactId,
        AssignedLeadAuditorId: this.form.value.assignedFinmaleadauditor.contactId,
        //sorting Assigned Audit Team members in asc order while save
        //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersAP.sort((a, b) => a - b).map(x=>x).join(","),
        AuditPeriodStartDate: this.form.value.auditperiodstartdateAP == null ? null : this.form.value.auditperiodstartdateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted) : new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[2]),
        AuditPeriodEndDate: this.form.value.auditperiodenddateAP == null ? null : this.form.value.auditperiodenddateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodenddateAP.singleDate.formatted) : new Date(this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[2]),

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateAP == null ? null : this.form.value.auditexecutionstartdateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateAP == null ? null : this.form.value.auditexecutionenddateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[2]),
        AuditIdcode: this.form.value.idcodeAP,
        IaworkCompleted: this.form.value.iaworkcompletedAP == null ? null : this.form.value.iaworkcompletedAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.iaworkcompletedAP.singleDate.formatted) : new Date(this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[2]),
        // IaworkCompleted: this.form.value.iaworkcompletedAP == "" || this.form.value.iaworkcompletedAP == null ? null : new Date(this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryAP == "" || this.form.value.announcementdeliveryAP == null ? null : new Date(this.form.value.announcementdeliveryAP.split('.')[1] + '-' + this.form.value.announcementdeliveryAP.split('.')[0] + '-' + this.form.value.announcementdeliveryAP.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueAP == "" || this.form.value.clientdatadueAP == null ? null : new Date(this.form.value.clientdatadueAP.split('.')[1] + '-' + this.form.value.clientdatadueAP.split('.')[0] + '-' + this.form.value.clientdatadueAP.split('.')[2]),
        DraftReport: this.form.value.draftreportAP == "" || this.form.value.draftreportAP == null ? null : new Date(this.form.value.draftreportAP.split('.')[1] + '-' + this.form.value.draftreportAP.split('.')[0] + '-' + this.form.value.draftreportAP.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueAP == "" || this.form.value.iaworkpaperreviewdueAP == null ? null : new Date(this.form.value.iaworkpaperreviewdueAP.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueAP.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueAP.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueAP == "" || this.form.value.draftreporttoclientdueAP == null ? null : new Date(this.form.value.draftreporttoclientdueAP.split('.')[1] + '-' + this.form.value.draftreporttoclientdueAP.split('.')[0] + '-' + this.form.value.draftreporttoclientdueAP.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueAP == "" || this.form.value.clientfeedbackdueAP == null ? null : new Date(this.form.value.clientfeedbackdueAP.split('.')[1] + '-' + this.form.value.clientfeedbackdueAP.split('.')[0] + '-' + this.form.value.clientfeedbackdueAP.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueAP == "" || this.form.value.finalreporttoclientdueAP == null ? null : new Date(this.form.value.finalreporttoclientdueAP.split('.')[1] + '-' + this.form.value.finalreporttoclientdueAP.split('.')[0] + '-' + this.form.value.finalreporttoclientdueAP.split('.')[2]),
        InternalAuditEngagementLeaderId: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,
        StatusId: this.form.value.statusidAP,
        regAuditCoSign: this.form.value.regAuditCoSign != null ? this.form.value.regAuditCoSign.contactId : null

      }
      lstAudittype.push(APobj);
    }
    if (this.form.value.AuditTypeRP) {

      var RPobj = {
        AuditType: "RP",
        FinancialAuditLead: this.form.value.financialauditleadRP,
        RegionalLeadPartnerId: this.form.value.regionalleadpartner.contactId,
        //AssignedLeadAuditorId: this.form.value.assignedleadauditor.contactId,
        //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.map(x=>x).join(","),
        //sorting Assigned Audit Team members in asc order while save
        // AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.sort((a, b) => a - b).map(x=>x).join(","),
        AuditIdcode: this.form.value.idcodeRP,
        AuditPeriodStartDate: this.form.value.auditperiodstartdateRP == null ? null : this.form.value.auditperiodstartdateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted) : new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[2]),
        AuditPeriodEndDate: this.form.value.auditperiodenddateRP == null ? null : this.form.value.auditperiodenddateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodenddateRP.singleDate.formatted) : new Date(this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[2]),

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateRP == null ? null : this.form.value.auditexecutionstartdateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateRP == null ? null : this.form.value.auditexecutionenddateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[2]),
        IaworkCompleted: this.form.value.iaworkcompletedRP == null ? null : this.form.value.iaworkcompletedRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.iaworkcompletedRP.singleDate.formatted) : new Date(this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[2]),
        Decisivedate: this.form.value.decisivedate == null ? null : this.form.value.decisivedate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.decisivedate.singleDate.formatted) : new Date(this.form.value.decisivedate.singleDate.formatted.split('.')[1] + '-' + this.form.value.decisivedate.singleDate.formatted.split('.')[0] + '-' + this.form.value.decisivedate.singleDate.formatted.split('.')[2]),
        LatestSignoffDate: this.form.value.latestSignoffDate == null ? null : this.form.value.latestSignoffDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.latestSignoffDate.singleDate.formatted) : new Date(this.form.value.latestSignoffDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.latestSignoffDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.latestSignoffDate.singleDate.formatted.split('.')[2]),
        ActualSignoffDate: this.form.value.actualSignoffDate == null ? null : this.form.value.actualSignoffDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.actualSignoffDate.singleDate.formatted) : new Date(this.form.value.actualSignoffDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.actualSignoffDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.actualSignoffDate.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryRP == "" || this.form.value.announcementdeliveryRP == null ? null : new Date(this.form.value.announcementdeliveryRP.split('.')[1] + '-' + this.form.value.announcementdeliveryRP.split('.')[0] + '-' + this.form.value.announcementdeliveryRP.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueRP == "" || this.form.value.clientdatadueRP == null ? null : new Date(this.form.value.clientdatadueRP.split('.')[1] + '-' + this.form.value.clientdatadueRP.split('.')[0] + '-' + this.form.value.clientdatadueRP.split('.')[2]),
        DraftReport: this.form.value.draftreportRP == "" || this.form.value.draftreportRP == null ? null : new Date(this.form.value.draftreportRP.split('.')[1] + '-' + this.form.value.draftreportRP.split('.')[0] + '-' + this.form.value.draftreportRP.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueRP == "" || this.form.value.iaworkpaperreviewdueRP == null ? null : new Date(this.form.value.iaworkpaperreviewdueRP.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueRP.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueRP.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueRP == "" || this.form.value.draftreporttoclientdueRP == null ? null : new Date(this.form.value.draftreporttoclientdueRP.split('.')[1] + '-' + this.form.value.draftreporttoclientdueRP.split('.')[0] + '-' + this.form.value.draftreporttoclientdueRP.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueRP == "" || this.form.value.clientfeedbackdueRP == null ? null : new Date(this.form.value.clientfeedbackdueRP.split('.')[1] + '-' + this.form.value.clientfeedbackdueRP.split('.')[0] + '-' + this.form.value.clientfeedbackdueRP.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueRP == "" || this.form.value.finalreporttoclientdueRP == null ? null : new Date(this.form.value.finalreporttoclientdueRP.split('.')[1] + '-' + this.form.value.finalreporttoclientdueRP.split('.')[0] + '-' + this.form.value.finalreporttoclientdueRP.split('.')[2]),

        InternalAuditEngagementLeaderId: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,
        StatusId: this.form.value.statusidRP,
        finAuditCoSign: this.form.value.finAuditCoSign != null ? this.form.value.finAuditCoSign.contactId : null,
        MeetingFormatId: this.form.value.meetingFormat,
        AGMDate: this.form.value.agmDate == null ? null : this.form.value.agmDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.agmDate.singleDate.formatted) : new Date(this.form.value.agmDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.agmDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.agmDate.singleDate.formatted.split('.')[2]),
        StartTime: this.startTimeChanged != null || this.startTimeChanged != undefined ? this.startTimeChanged.toString('HH:mm') : this.form.value.startTime,
        EndTime: this.endTimeChanged != null || this.endTimeChanged != undefined ? this.endTimeChanged.toString('HH:mm') : this.form.value.endTime,
        AGMLocation:btoa(unescape(encodeURIComponent(this.form.value.agmLocation))),
        EYRepresentative: this.form.value.eyRepresentative != null ? this.form.value.eyRepresentative.contactId : null,
        Notes: btoa(unescape(encodeURIComponent(this.form.value.agmNotes)))
      }
      lstAudittype.push(RPobj);
    } if (this.form.value.AuditTypeKP) {
      var KPobj = {
        AuditType: "KP",
        FinancialAuditLead: this.form.value.financialauditleadKP,
        AuditPeriodStartDate: null,
        AuditPeriodEndDate: null,
        //AssignedLeadAuditorId: this.form.value.assignedleadauditorKP.contactId,
        //sorting Assigned Audit Team members in asc order while save
        //  AssignedAuditTeamMembers :this.form.value.assignedauditteammembersKP.sort((a, b) => a - b).map(x=>x).join(","),


        RegionalLeadPartnerId: this.form.value.regionalleadpartner.contactId,
        //AssignedLeadAuditorId: this.form.value.assignedleadauditor.contactId,

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateKP == null ? null : this.form.value.auditexecutionstartdateKP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateKP == null ? null : this.form.value.auditexecutionenddateKP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[2]),
        AuditIdcode: this.form.value.idcodeKP,
        IaworkCompleted: this.form.value.iaworkcompletedKP == null ? null : this.form.value.iaworkcompletedKP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.iaworkcompletedKP.singleDate.formatted) : new Date(this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[2]),
        // IaworkCompleted: this.form.value.iaworkcompletedKP == "" || this.form.value.iaworkcompletedKP == null ? null : new Date(this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryKP == "" || this.form.value.announcementdeliveryKP == null ? null : new Date(this.form.value.announcementdeliveryKP.split('.')[1] + '-' + this.form.value.announcementdeliveryKP.split('.')[0] + '-' + this.form.value.announcementdeliveryKP.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueKP == "" || this.form.value.clientdatadueKP == null ? null : new Date(this.form.value.clientdatadueKP.split('.')[1] + '-' + this.form.value.clientdatadueKP.split('.')[0] + '-' + this.form.value.clientdatadueKP.split('.')[2]),
        DraftReport: this.form.value.draftreportKP == "" || this.form.value.draftreportKP == null ? null : new Date(this.form.value.draftreportKP.split('.')[1] + '-' + this.form.value.draftreportKP.split('.')[0] + '-' + this.form.value.draftreportKP.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueKP == "" || this.form.value.iaworkpaperreviewdueKP == null ? null : new Date(this.form.value.iaworkpaperreviewdueKP.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueKP.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueKP.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueKP == "" || this.form.value.draftreporttoclientdueKP == null ? null : new Date(this.form.value.draftreporttoclientdueKP.split('.')[1] + '-' + this.form.value.draftreporttoclientdueKP.split('.')[0] + '-' + this.form.value.draftreporttoclientdueKP.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueKP == "" || this.form.value.clientfeedbackdueKP == null ? null : new Date(this.form.value.clientfeedbackdueKP.split('.')[1] + '-' + this.form.value.clientfeedbackdueKP.split('.')[0] + '-' + this.form.value.clientfeedbackdueKP.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueKP == "" || this.form.value.finalreporttoclientdueKP == null ? null : new Date(this.form.value.finalreporttoclientdueKP.split('.')[1] + '-' + this.form.value.finalreporttoclientdueKP.split('.')[0] + '-' + this.form.value.finalreporttoclientdueKP.split('.')[2]),

        InternalAuditEngagementLeaderId: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,
        StatusId: this.form.value.statusidKP,
        kpEngagementManager: this.form.value.kpEngagementManager != null ? this.form.value.kpEngagementManager.contactId : null
      }

      lstAudittype.push(KPobj);
    } if (this.form.value.AuditTypeIA) {
      var IRobj = {
        AuditType: "IR",
        IAAudit1: this.form.value.internalAudit1,
        IAAudit1StartDate: this.form.value.ia1periodstartdate == null ? null : this.form.value.ia1periodstartdate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia1periodstartdate.singleDate.formatted) : new Date(this.form.value.ia1periodstartdate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia1periodstartdate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia1periodstartdate.singleDate.formatted.split('.')[2]),
        IAAudit1EndDate: this.form.value.ia1periodenddate == null ? null : this.form.value.ia1periodenddate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia1periodenddate.singleDate.formatted) : new Date(this.form.value.ia1periodenddate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia1periodenddate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia1periodenddate.singleDate.formatted.split('.')[2]),
        IAAudit2: this.form.value.internalAudit2,
        IAAudit2StartDate: this.form.value.ia2periodstartdate == null ? null : this.form.value.ia2periodstartdate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia2periodstartdate.singleDate.formatted) : new Date(this.form.value.ia2periodstartdate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia2periodstartdate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia2periodstartdate.singleDate.formatted.split('.')[2]),
        IAAudit2EndDate: this.form.value.ia2periodenddate == null ? null : this.form.value.ia2periodenddate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia2periodenddate.singleDate.formatted) : new Date(this.form.value.ia2periodenddate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia2periodenddate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia2periodenddate.singleDate.formatted.split('.')[2]),
      }
      lstAudittype.push(IRobj);
    }
    if (this.form.value.AuditTypeOther) {


      var Otherobj = {
        AuditType: "OT",
        FinancialAuditLead: this.form.value.financialauditleadOther,
        RegionalLeadPartnerId: this.form.value.regionalleadpartnerOther.contactId,
        AssignedLeadAuditorId: this.form.value.assignedleadauditorOther.contactId,
        //sorting Assigned Audit Team members in asc order while save
        //   AssignedAuditTeamMembers :this.form.value.assignedauditteammembersOther.sort((a, b) => a - b).map(x=>x).join(","),
        AuditIdcode: this.form.value.idcodeOther,
        AuditPeriodStartDate: this.form.value.auditperiodstartdateOther == null ? null : this.form.value.auditperiodstartdateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted) : new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[2]),
        AuditPeriodEndDate: this.form.value.auditperiodenddateOther == null ? null : this.form.value.auditperiodenddateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodenddateOther.singleDate.formatted) : new Date(this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[2]),

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateOther == null ? null : this.form.value.auditexecutionstartdateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateOther == null ? null : this.form.value.auditexecutionenddateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[2]),
        IaworkCompleted: this.form.value.iaworkcompletedOT == null ? null : this.form.value.iaworkcompletedOT.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.iaworkcompletedOT.singleDate.formatted) : new Date(this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[2]),
        //  IaworkCompleted: this.form.value.iaworkcompletedOT == "" || this.form.value.iaworkcompletedOT == null ? null : new Date(this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryOT == "" || this.form.value.announcementdeliveryOT == null ? null : new Date(this.form.value.announcementdeliveryOT.split('.')[1] + '-' + this.form.value.announcementdeliveryOT.split('.')[0] + '-' + this.form.value.announcementdeliveryOT.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueOT == "" || this.form.value.clientdatadueOT == null ? null : new Date(this.form.value.clientdatadueOT.split('.')[1] + '-' + this.form.value.clientdatadueOT.split('.')[0] + '-' + this.form.value.clientdatadueOT.split('.')[2]),
        DraftReport: this.form.value.draftreportOT == "" || this.form.value.draftreportOT == null ? null : new Date(this.form.value.draftreportOT.split('.')[1] + '-' + this.form.value.draftreportOT.split('.')[0] + '-' + this.form.value.draftreportOT.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueOT == "" || this.form.value.iaworkpaperreviewdueOT == null ? null : new Date(this.form.value.iaworkpaperreviewdueOT.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueOT.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueOT.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueOT == "" || this.form.value.draftreporttoclientdueOT == null ? null : new Date(this.form.value.draftreporttoclientdueOT.split('.')[1] + '-' + this.form.value.draftreporttoclientdueOT.split('.')[0] + '-' + this.form.value.draftreporttoclientdueOT.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueOT == "" || this.form.value.clientfeedbackdueOT == null ? null : new Date(this.form.value.clientfeedbackdueOT.split('.')[1] + '-' + this.form.value.clientfeedbackdueOT.split('.')[0] + '-' + this.form.value.clientfeedbackdueOT.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueOT == "" || this.form.value.finalreporttoclientdueOT == null ? null : new Date(this.form.value.finalreporttoclientdueOT.split('.')[1] + '-' + this.form.value.finalreporttoclientdueOT.split('.')[0] + '-' + this.form.value.finalreporttoclientdueOT.split('.')[2]),
        StatusId: this.form.value.statusidOT

      }
      lstAudittype.push(Otherobj);
    }
    ;
    var obj = {
      AuditAssignmentId: this.AuditAssignmentId,
      FormTitle: this.form.value.formtitle.replace('| d', '- d'),
      ClientEnityId: this.ngClientEntityID,
      AuditYear: parseFloat(this.form.value.audityear),
      Classification: this.form.value.classification,
      ClientContact: this.form.value.clientcontacts != null && this.form.value.clientcontacts != undefined ? this.form.value.clientcontacts.map(x => x.contactId).join(",") : "",
      GroupScope: this.form.value.groupscope,
      Comment: this.form.value.Comment,
      FormStatus: this.form.value.formstatus,
      Audit: lstAudittype,
      EngagementPartnerID: this.form.value.regionalleadpartner.contactId,
      //EngagementManagerID: this.form.value.assignedleadauditor.contactId,
      FinmaLeadAuditorID: this.form.value.assignedFinmaleadauditor.contactId,
      InternalAuditEngagementLeaderID: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,


    }
    
    this._auditassignmentService.UpdateData(obj)
      .subscribe(
        res => {

          if (res["message"] == "AuditAssignment updated") {
            this.toasttype = "success";
            this.toastflag = true;
            this.oncancel("update");
            this.toastmessage = "Audit Assignment has been updated successfully.";
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "AuditAssignment update failed") {
            // this.toasttype="error";
            //this.toastflag=true;

            //  this.toastmessage="Error occured please contact support team";

          }
          else if (res["message"] == "Duplicate AuditAssignment") {
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = "Audit Assignment already exists with this entity and year";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "MobileNoExists") {
            //this.toasttype="warning";
            //this.toastflag=true;

            // this.toastmessage="Mobile no. already exists";

          }
        },
        err => {
          if (err.toString().toLocaleLowerCase().indexOf('max') != -1 || err.toString().toLocaleLowerCase().indexOf('allowed') != -1) {
            //var key=Object.keys(err["error"]["errors"])[0];
            //this.toasterService.message.next(err.toString());
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else {
            this.toasttype = "error";
            this.toastflag = true;

            this.toastmessage = "Error occured. Please try after sometime";
            console.log(err);
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }

      )


  }
  CreateAuditAssignment() {
    //this.toastflag=false;
    
    this.validateForm(this.form);
    if (!this.form.valid)
      return
    if (!this.form.value.AuditTypeRP && !this.form.value.AuditTypeAP && !this.form.value.AuditTypeOther && !this.form.value.AuditTypeKP && !this.form.value.AuditTypeIA)
      return
    let lstAudittype: any = [];

    if (this.form.value.AuditTypeAP) {

      var APobj = {
        AuditType: "AP",
        FinancialAuditLead: this.form.value.financialauditleadAP,
        RegionalLeadPartnerId: this.form.value.regionalleadpartner.contactId,
        AssignedLeadAuditorId: this.form.value.assignedFinmaleadauditor.contactId,
        //sorting Assigned Audit Team members in asc order while save
        //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersAP.sort((a, b) => a - b).map(x=>x).join(","),
        AuditPeriodStartDate: this.form.value.auditperiodstartdateAP == null ? null : this.form.value.auditperiodstartdateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted) : new Date(this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodstartdateAP.singleDate.formatted.split('.')[2]),
        AuditPeriodEndDate: this.form.value.auditperiodenddateAP == null ? null : this.form.value.auditperiodenddateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodenddateAP.singleDate.formatted) : new Date(this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodenddateAP.singleDate.formatted.split('.')[2]),

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateAP == null ? null : this.form.value.auditexecutionstartdateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateAP.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateAP == null ? null : this.form.value.auditexecutionenddateAP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateAP.singleDate.formatted.split('.')[2]),
        AuditIdcode: this.form.value.idcodeAP,

        IaworkCompleted: this.form.value.iaworkcompletedAP == "" || this.form.value.iaworkcompletedAP == null ? null : new Date(this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedAP.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryAP == "" || this.form.value.announcementdeliveryAP == null ? null : new Date(this.form.value.announcementdeliveryAP.split('.')[1] + '-' + this.form.value.announcementdeliveryAP.split('.')[0] + '-' + this.form.value.announcementdeliveryAP.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueAP == "" || this.form.value.clientdatadueAP == null ? null : new Date(this.form.value.clientdatadueAP.split('.')[1] + '-' + this.form.value.clientdatadueAP.split('.')[0] + '-' + this.form.value.clientdatadueAP.split('.')[2]),
        DraftReport: this.form.value.draftreportAP == "" || this.form.value.draftreportAP == null ? null : new Date(this.form.value.draftreportAP.split('.')[1] + '-' + this.form.value.draftreportAP.split('.')[0] + '-' + this.form.value.draftreportAP.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueAP == "" || this.form.value.iaworkpaperreviewdueAP == null ? null : new Date(this.form.value.iaworkpaperreviewdueAP.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueAP.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueAP.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueAP == "" || this.form.value.draftreporttoclientdueAP == null ? null : new Date(this.form.value.draftreporttoclientdueAP.split('.')[1] + '-' + this.form.value.draftreporttoclientdueAP.split('.')[0] + '-' + this.form.value.draftreporttoclientdueAP.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueAP == "" || this.form.value.clientfeedbackdueAP == null ? null : new Date(this.form.value.clientfeedbackdueAP.split('.')[1] + '-' + this.form.value.clientfeedbackdueAP.split('.')[0] + '-' + this.form.value.clientfeedbackdueAP.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueAP == "" || this.form.value.finalreporttoclientdueAP == null ? null : new Date(this.form.value.finalreporttoclientdueAP.split('.')[1] + '-' + this.form.value.finalreporttoclientdueAP.split('.')[0] + '-' + this.form.value.finalreporttoclientdueAP.split('.')[2]),
        InternalAuditEngagementLeaderId: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,
        StatusId: this.form.value.statusidAP,
        regAuditCoSign: this.form.value.regAuditCoSign != null ? this.form.value.regAuditCoSign.contactId : null,
      }
      lstAudittype.push(APobj);
    }
    if (this.form.value.AuditTypeRP) {

      var RPobj = {
        AuditType: "RP",
        FinancialAuditLead: this.form.value.financialauditleadRP,
        RegionalLeadPartnerId: this.form.value.regionalleadpartner.contactId,
        //AssignedLeadAuditorId: this.form.value.assignedleadauditor.contactId,
        //AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.map(x=>x).join(","),
        //sorting Assigned Audit Team members in asc order while save
        // AssignedAuditTeamMembers :this.form.value.assignedauditteammembersRP.sort((a, b) => a - b).map(x=>x).join(","),
        AuditIdcode: this.form.value.idcodeRP,
        AuditPeriodStartDate: this.form.value.auditperiodstartdateRP == null ? null : this.form.value.auditperiodstartdateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted) : new Date(this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodstartdateRP.singleDate.formatted.split('.')[2]),
        AuditPeriodEndDate: this.form.value.auditperiodenddateRP == null ? null : this.form.value.auditperiodenddateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodenddateRP.singleDate.formatted) : new Date(this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodenddateRP.singleDate.formatted.split('.')[2]),

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateRP == null ? null : this.form.value.auditexecutionstartdateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateRP.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateRP == null ? null : this.form.value.auditexecutionenddateRP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateRP.singleDate.formatted.split('.')[2]),
        IaworkCompleted: this.form.value.iaworkcompletedRP == "" || this.form.value.iaworkcompletedRP == null ? null : new Date(this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedRP.singleDate.formatted.split('.')[2]),
        Decisivedate: this.form.value.decisivedate == null ? null : this.form.value.decisivedate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.decisivedate.singleDate.formatted) : new Date(this.form.value.decisivedate.singleDate.formatted.split('.')[1] + '-' + this.form.value.decisivedate.singleDate.formatted.split('.')[0] + '-' + this.form.value.decisivedate.singleDate.formatted.split('.')[2]),
        LatestSignoffDate: this.form.value.latestSignoffDate == null ? null : this.form.value.latestSignoffDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.latestSignoffDate.singleDate.formatted) : new Date(this.form.value.latestSignoffDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.latestSignoffDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.latestSignoffDate.singleDate.formatted.split('.')[2]),
        ActualSignoffDate: this.form.value.actualSignoffDate == null ? null : this.form.value.actualSignoffDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.actualSignoffDate.singleDate.formatted) : new Date(this.form.value.actualSignoffDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.actualSignoffDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.actualSignoffDate.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryRP == "" || this.form.value.announcementdeliveryRP == null ? null : new Date(this.form.value.announcementdeliveryRP.split('.')[1] + '-' + this.form.value.announcementdeliveryRP.split('.')[0] + '-' + this.form.value.announcementdeliveryRP.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueRP == "" || this.form.value.clientdatadueRP == null ? null : new Date(this.form.value.clientdatadueRP.split('.')[1] + '-' + this.form.value.clientdatadueRP.split('.')[0] + '-' + this.form.value.clientdatadueRP.split('.')[2]),
        DraftReport: this.form.value.draftreportRP == "" || this.form.value.draftreportRP == null ? null : new Date(this.form.value.draftreportRP.split('.')[1] + '-' + this.form.value.draftreportRP.split('.')[0] + '-' + this.form.value.draftreportRP.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueRP == "" || this.form.value.iaworkpaperreviewdueRP == null ? null : new Date(this.form.value.iaworkpaperreviewdueRP.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueRP.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueRP.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueRP == "" || this.form.value.draftreporttoclientdueRP == null ? null : new Date(this.form.value.draftreporttoclientdueRP.split('.')[1] + '-' + this.form.value.draftreporttoclientdueRP.split('.')[0] + '-' + this.form.value.draftreporttoclientdueRP.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueRP == "" || this.form.value.clientfeedbackdueRP == null ? null : new Date(this.form.value.clientfeedbackdueRP.split('.')[1] + '-' + this.form.value.clientfeedbackdueRP.split('.')[0] + '-' + this.form.value.clientfeedbackdueRP.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueRP == "" || this.form.value.finalreporttoclientdueRP == null ? null : new Date(this.form.value.finalreporttoclientdueRP.split('.')[1] + '-' + this.form.value.finalreporttoclientdueRP.split('.')[0] + '-' + this.form.value.finalreporttoclientdueRP.split('.')[2]),

        InternalAuditEngagementLeaderId: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,
        StatusId: this.form.value.statusidRP,
        finAuditCoSign: this.form.value.finAuditCoSign != null ? this.form.value.finAuditCoSign.contactId : null,
        MeetingFormatId: this.form.value.meetingFormat,
        AGMDate: this.form.value.agmDate == null ? null : this.form.value.agmDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.agmDate.singleDate.formatted) : new Date(this.form.value.agmDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.agmDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.agmDate.singleDate.formatted.split('.')[2]),
        StartTime: this.startTimeChanged != null || this.startTimeChanged != undefined ? this.startTimeChanged.toString('HH:mm') : this.form.value.startTime,
        EndTime: this.endTimeChanged != null || this.endTimeChanged != undefined ? this.endTimeChanged.toString('HH:mm') : this.form.value.endTime,
        AGMLocation: btoa(unescape(encodeURIComponent(this.form.value.agmLocation))),
        EYRepresentative: this.form.value.eyRepresentative != null && this.form.value.eyRepresentative != undefined ? this.form.value.eyRepresentative.contactId : null,
        Notes: btoa(unescape(encodeURIComponent(this.form.value.agmNotes)))
      }
      lstAudittype.push(RPobj);
    }
    if (this.form.value.AuditTypeIA) {
      var IRobj = {
        AuditType: "IR",
        IAAudit1: this.form.value.internalAudit1,
        IAAudit1StartDate: this.form.value.ia1periodstartdate == null ? null : this.form.value.ia1periodstartdate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia1periodstartdate.singleDate.formatted) : new Date(this.form.value.ia1periodstartdate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia1periodstartdate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia1periodstartdate.singleDate.formatted.split('.')[2]),
        IAAudit1EndDate: this.form.value.ia1periodenddate == null ? null : this.form.value.ia1periodenddate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia1periodenddate.singleDate.formatted) : new Date(this.form.value.ia1periodenddate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia1periodenddate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia1periodenddate.singleDate.formatted.split('.')[2]),
        IAAudit2: this.form.value.internalAudit2,
        IAAudit2StartDate: this.form.value.ia2periodstartdate == null ? null : this.form.value.ia2periodstartdate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia2periodstartdate.singleDate.formatted) : new Date(this.form.value.ia2periodstartdate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia2periodstartdate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia2periodstartdate.singleDate.formatted.split('.')[2]),
        IAAudit2EndDate: this.form.value.ia2periodenddate == null ? null : this.form.value.ia2periodenddate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ia2periodenddate.singleDate.formatted) : new Date(this.form.value.ia2periodenddate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ia2periodenddate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ia2periodenddate.singleDate.formatted.split('.')[2]),
      }
      lstAudittype.push(IRobj);
    }
    if (this.form.value.AuditTypeKP) {
      var KPobj = {
        AuditType: "KP",
        FinancialAuditLead: this.form.value.financialauditleadKP,
        AuditPeriodStartDate: null,
        AuditPeriodEndDate: null,
        //AssignedLeadAuditorId: this.form.value.assignedleadauditorKP.contactId,
        //sorting Assigned Audit Team members in asc order while save
        //  AssignedAuditTeamMembers :this.form.value.assignedauditteammembersKP.sort((a, b) => a - b).map(x=>x).join(","),


        RegionalLeadPartnerId: this.form.value.regionalleadpartner.contactId,
        //AssignedLeadAuditorId: this.form.value.assignedleadauditor.contactId,

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateKP == null ? null : this.form.value.auditexecutionstartdateKP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateKP.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateKP == null ? null : this.form.value.auditexecutionenddateKP.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateKP.singleDate.formatted.split('.')[2]),
        AuditIdcode: this.form.value.idcodeKP,

        IaworkCompleted: this.form.value.iaworkcompletedKP == "" || this.form.value.iaworkcompletedKP == null ? null : new Date(this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedKP.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryKP == "" || this.form.value.announcementdeliveryKP == null ? null : new Date(this.form.value.announcementdeliveryKP.split('.')[1] + '-' + this.form.value.announcementdeliveryKP.split('.')[0] + '-' + this.form.value.announcementdeliveryKP.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueKP == "" || this.form.value.clientdatadueKP == null ? null : new Date(this.form.value.clientdatadueKP.split('.')[1] + '-' + this.form.value.clientdatadueKP.split('.')[0] + '-' + this.form.value.clientdatadueKP.split('.')[2]),
        DraftReport: this.form.value.draftreportKP == "" || this.form.value.draftreportKP == null ? null : new Date(this.form.value.draftreportKP.split('.')[1] + '-' + this.form.value.draftreportKP.split('.')[0] + '-' + this.form.value.draftreportKP.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueKP == "" || this.form.value.iaworkpaperreviewdueKP == null ? null : new Date(this.form.value.iaworkpaperreviewdueKP.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueKP.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueKP.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueKP == "" || this.form.value.draftreporttoclientdueKP == null ? null : new Date(this.form.value.draftreporttoclientdueKP.split('.')[1] + '-' + this.form.value.draftreporttoclientdueKP.split('.')[0] + '-' + this.form.value.draftreporttoclientdueKP.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueKP == "" || this.form.value.clientfeedbackdueKP == null ? null : new Date(this.form.value.clientfeedbackdueKP.split('.')[1] + '-' + this.form.value.clientfeedbackdueKP.split('.')[0] + '-' + this.form.value.clientfeedbackdueKP.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueKP == "" || this.form.value.finalreporttoclientdueKP == null ? null : new Date(this.form.value.finalreporttoclientdueKP.split('.')[1] + '-' + this.form.value.finalreporttoclientdueKP.split('.')[0] + '-' + this.form.value.finalreporttoclientdueKP.split('.')[2]),

        InternalAuditEngagementLeaderId: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,
        StatusId: this.form.value.statusidKP,
        kpEngagementManager: this.form.value.kpEngagementManager != null ? this.form.value.kpEngagementManager.contactId : null,

      }

      lstAudittype.push(KPobj);
    }
    if (this.form.value.AuditTypeOther) {


      var Otherobj = {
        AuditType: "OT",
        FinancialAuditLead: this.form.value.financialauditleadOther,
        RegionalLeadPartnerId: this.form.value.regionalleadpartnerOther.contactId,
        AssignedLeadAuditorId: this.form.value.assignedleadauditorOther.contactId,
        //sorting Assigned Audit Team members in asc order while save
        //   AssignedAuditTeamMembers :this.form.value.assignedauditteammembersOther.sort((a, b) => a - b).map(x=>x).join(","),
        AuditIdcode: this.form.value.idcodeOther,
        AuditPeriodStartDate: this.form.value.auditperiodstartdateOther == null ? null : this.form.value.auditperiodstartdateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted) : new Date(this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodstartdateOther.singleDate.formatted.split('.')[2]),
        AuditPeriodEndDate: this.form.value.auditperiodenddateOther == null ? null : this.form.value.auditperiodenddateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditperiodenddateOther.singleDate.formatted) : new Date(this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditperiodenddateOther.singleDate.formatted.split('.')[2]),

        AuditExecutionStartDate: this.form.value.auditexecutionstartdateOther == null ? null : this.form.value.auditexecutionstartdateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted) : new Date(this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionstartdateOther.singleDate.formatted.split('.')[2]),
        AuditExecutionEndDate: this.form.value.auditexecutionenddateOther == null ? null : this.form.value.auditexecutionenddateOther.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted) : new Date(this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[1] + '-' + this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[0] + '-' + this.form.value.auditexecutionenddateOther.singleDate.formatted.split('.')[2]),

        IaworkCompleted: this.form.value.iaworkcompletedOT == "" || this.form.value.iaworkcompletedOT == null ? null : new Date(this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[1] + '-' + this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[0] + '-' + this.form.value.iaworkcompletedOT.singleDate.formatted.split('.')[2]),
        AnnouncementDelivery: this.form.value.announcementdeliveryOT == "" || this.form.value.announcementdeliveryOT == null ? null : new Date(this.form.value.announcementdeliveryOT.split('.')[1] + '-' + this.form.value.announcementdeliveryOT.split('.')[0] + '-' + this.form.value.announcementdeliveryOT.split('.')[2]),
        ClientDataDue: this.form.value.clientdatadueOT == "" || this.form.value.clientdatadueOT == null ? null : new Date(this.form.value.clientdatadueOT.split('.')[1] + '-' + this.form.value.clientdatadueOT.split('.')[0] + '-' + this.form.value.clientdatadueOT.split('.')[2]),
        DraftReport: this.form.value.draftreportOT == "" || this.form.value.draftreportOT == null ? null : new Date(this.form.value.draftreportOT.split('.')[1] + '-' + this.form.value.draftreportOT.split('.')[0] + '-' + this.form.value.draftreportOT.split('.')[2]),
        IaworkPaperReviewDue: this.form.value.iaworkpaperreviewdueOT == "" || this.form.value.iaworkpaperreviewdueOT == null ? null : new Date(this.form.value.iaworkpaperreviewdueOT.split('.')[1] + '-' + this.form.value.iaworkpaperreviewdueOT.split('.')[0] + '-' + this.form.value.iaworkpaperreviewdueOT.split('.')[2]),
        DraftReportToClientDue: this.form.value.draftreporttoclientdueOT == "" || this.form.value.draftreporttoclientdueOT == null ? null : new Date(this.form.value.draftreporttoclientdueOT.split('.')[1] + '-' + this.form.value.draftreporttoclientdueOT.split('.')[0] + '-' + this.form.value.draftreporttoclientdueOT.split('.')[2]),
        ClientFeedbackDue: this.form.value.clientfeedbackdueOT == "" || this.form.value.clientfeedbackdueOT == null ? null : new Date(this.form.value.clientfeedbackdueOT.split('.')[1] + '-' + this.form.value.clientfeedbackdueOT.split('.')[0] + '-' + this.form.value.clientfeedbackdueOT.split('.')[2]),
        FinalReportToClientDue: this.form.value.finalreporttoclientdueOT == "" || this.form.value.finalreporttoclientdueOT == null ? null : new Date(this.form.value.finalreporttoclientdueOT.split('.')[1] + '-' + this.form.value.finalreporttoclientdueOT.split('.')[0] + '-' + this.form.value.finalreporttoclientdueOT.split('.')[2]),
        StatusId: this.form.value.statusidOT

      }
      lstAudittype.push(Otherobj);
    }

    ;

    var obj = {

      FormTitle: this.form.value.formtitle.replace('| d', '- d'),
      ClientEnityId: this.ngClientEntityID,
      AuditYear: parseFloat(this.form.value.audityear),
      Classification: this.form.value.classification,
      ClientContact: this.form.value.clientcontacts != null && this.form.value.clientcontacts != undefined ? this.form.value.clientcontacts.map(x => x.contactId).join(",") : "",
      GroupScope: this.form.value.groupscope,
      Comment: this.form.value.Comment,
      FormStatus: this.form.value.formstatus,
      Audit: lstAudittype,

      EngagementPartnerID: this.form.value.regionalleadpartner.contactId,
      //EngagementManagerID: this.form.value.assignedleadauditor.contactId,
      FinmaLeadAuditorID: this.form.value.assignedFinmaleadauditor.contactId,
      InternalAuditEngagementLeaderID: this.form.value.internalauditengagementleader != null && this.form.value.internalauditengagementleader != undefined ? this.form.value.internalauditengagementleader.contactId : null,

    }
    this._auditassignmentService.SaveData(obj)
      .subscribe(
        res => {
          
          if (res["message"] == "AuditAssignment Created") {
            this.toasttype = "success";
            this.toastflag = true;
            this.oncancel("success");
            this.toastmessage = "Audit Assignment has been added successfully.";
            this.form.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {
            // this.toasttype="error";
            //this.toastflag=true;

            //  this.toastmessage="Error occured please contact support team";

          }
          else if (res["message"] == "Duplicate AuditAssignment") {
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = "Audit Assignment already exists with this entity and year";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else if (res["message"] == "MobileNoExists") {
            //this.toasttype="warning";
            //this.toastflag=true;

            // this.toastmessage="Mobile no. already exists";

          }
        },
        err => {
          if (err.toString().toLocaleLowerCase().indexOf('max') != -1 || err.toString().toLocaleLowerCase().indexOf('allowed') != -1) {
            //var key=Object.keys(err["error"]["errors"])[0];
            //this.toasterService.message.next(err.toString());
            this.toasttype = "warning";
            this.toastflag = true;

            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else {
            this.toasttype = "error";
            this.toastflag = true;

            this.toastmessage = "Error occured. Please try after sometime";
            console.log(err);
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }
      )


  }
  oncancel(status: string) {
    ;

    let updateid = this._auditassignmentService.getupdateData();
    if (updateid.screen == "Tracker") {
      var objTacker = {
        auditassignmentid: null,
        flag: null,
        screen: null
      }
      this._auditassignmentService.setupdateData(objTacker);
      this.router.navigate(['/trackerview']);

    }
    else {
      if (updateid.flag == "update" || updateid.flag == "view") {
        if (status == "cancel") {
          var obj = {
            auditassignmentid: null,
            flag: null,
          }
          this._auditassignmentService.setupdateData(obj);

        }
        let objyear = {
          bankcode: "",
          AuditYear: this.form.value.audityear
        }
        this._auditassignmentService.setData(objyear);

        this.router.navigate([""]);
      }
      else if (updateid.newflag == "AUDupdate" ) {
        let objyear = {
          bankcode: "",
          AuditYear: ""
        }
        this._auditassignmentService.setData(objyear);

        this.router.navigate(['/auditassignment']);
        this.loaderflag = true;
        this.BackEventEmitter.emit(status);
      }
      else if (updateid.flag == null || updateid.flag == "" ) {

        this.router.navigate(['/auditassignment']);
        this.loaderflag = true;
        this.BackEventEmitter.emit(status);
      }
      
    }
  }

  onChangeStartDateRP(currentdate) {

    if (this.form.value.auditperiodenddateRP != null) {
      if (this.form.value.auditperiodenddateRP.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          auditperiodenddateRP: null
        });
      }
    }
    this.endDateRPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy', firstDayOfWeek:'mo',disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
  }


  onchangeFinancialAuditLeadRP(leadtype) {
    this.form.patchValue({
      auditexecutionstartdateRP: null,
      auditexecutionenddateRP: null,
      announcementdeliveryRP: null,
      clientdatadueRP: null,
      draftreportRP: null,
      iaworkcompletedRP: null,
      decisivedate: null,
      latestSignoffDate: null,
      actualSignoffDate: null,
      iaworkpaperreviewdueRP: null,
      draftreporttoclientdueRP: null,
      clientfeedbackdueRP: null,
      finalreporttoclientdueRP: null
    });

  }
  onChangeExcutionEndDateRP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "RP";
    });

    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadRP == "EY") {
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        let EydraftReport = planningdates[0].eydraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReport);
        this.form.patchValue({
          draftreporttoclientdueRP: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueRP: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueRP: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue),
          draftreportRP: planningdates[0].eydraftReport == null ? null : this.formatDate(EydraftReport)
        });
      }
      else {
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        let IrdraftReport = planningdates[0].irdraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReport);
        this.form.patchValue({
          draftreporttoclientdueRP: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueRP: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueRP: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue),
          draftreportRP: planningdates[0].irdraftReport == null ? null : this.formatDate(IrdraftReport)
        });
      }

    } else {
      this.form.patchValue({
        draftreporttoclientdueRP: "",
        clientfeedbackdueRP: "",
        finalreporttoclientdueRP: "",
        draftreportRP: ""
      });

    }




  }

  onChangeIAworkCompletedDateRP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "RP";
    });


    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadRP == "IR") {
        let IriaworkPaperReviewDue = planningdates[0].iriaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].iriaworkPaperReviewDue);
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueRP: planningdates[0].iriaworkPaperReviewDue == null ? null : this.formatDate(IriaworkPaperReviewDue),
          draftreporttoclientdueRP: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueRP: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueRP: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue)
        });
      }
      else {
        let EyiaworkPaperReviewDue = planningdates[0].eyiaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyiaworkPaperReviewDue);
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueRP: planningdates[0].eyiaworkPaperReviewDue == null ? null : this.formatDate(EyiaworkPaperReviewDue),
          draftreporttoclientdueRP: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueRP: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueRP: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue)
        });
      }
    } else {
      this.form.patchValue({
        iaworkpaperreviewdueRP: "",
        draftreporttoclientdueRP: "",
        clientfeedbackdueRP: "",
        finalreporttoclientdueRP: ""
      });
    }


  }
  onChangeExcutionStartDateRP(currentdate) {

    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }

    if (this.form.value.auditexecutionenddateRP != null) {
      if (this.form.value.auditexecutionenddateRP.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          auditexecutionenddateRP: null
        });
      }
    }
    let year = this.form.value.audityear;
    this.executionEndDateRPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy', firstDayOfWeek:'mo',disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "RP";
    });


    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadRP == "EY") {
        let Announcementdelivery = planningdates[0].eyannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyannouncementDelivery);
        let eyclientDataDue = planningdates[0].eyclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientDataDue);

        this.form.patchValue({
          announcementdeliveryRP: planningdates[0].eyannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueRP: planningdates[0].eyclientDataDue == null ? null : this.formatDate(eyclientDataDue)
        });
      }
      else {
        let Announcementdelivery = planningdates[0].irannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irannouncementDelivery);
        let IrclientDataDue = planningdates[0].irclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientDataDue);

        this.form.patchValue({
          announcementdeliveryRP: planningdates[0].irannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueRP: planningdates[0].irclientDataDue == null ? null : this.formatDate(IrclientDataDue)
        });
      }
    } else {
      this.form.patchValue({
        announcementdeliveryRP: "",
        clientdatadueRP: ""
      });
    }
  }

  onChangeStartDateAP(currentdate) {
    if (this.form.value.auditperiodenddateAP != null) {
      if (this.form.value.auditperiodenddateAP.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          auditperiodenddateAP: null
        });
      }
    }
    this.endDateAPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy', firstDayOfWeek:'mo',disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
  }

  onChangeStartDateIR1(currentdate) {
    if (this.form.value.ia1periodendate != null) {
      if (this.form.value.ia1periodenddate.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          ia1periodenddate: null
        });
      }
    }
    this.endDateIR1Options = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
  }

  onChangeStartDateIR2(currentdate) {
    if (this.form.value.ia2periodendate != null) {
      if (this.form.value.ia2periodenddate.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          ia2periodenddate: null
        });
      }
    }
    this.endDateIR2Options = { dateRange: false, dateFormat: 'dd.mm.yyyy', firstDayOfWeek:'mo',disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
  }

  onChangeExcutionStartDateAP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }

    if (this.form.value.auditexecutionenddateAP != null) {
      if (this.form.value.auditexecutionenddateAP.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          auditexecutionenddateAP: null
        });
      }
    }
    this.executionEndDateAPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
    let year = this.form.value.audityear;

    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "AP";
    });

    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadAP == "EY") {
        let Announcementdelivery = planningdates[0].eyannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyannouncementDelivery);
        let eyclientDataDue = planningdates[0].eyclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientDataDue);

        this.form.patchValue({
          announcementdeliveryAP: planningdates[0].eyannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueAP: planningdates[0].eyclientDataDue == null ? null : this.formatDate(eyclientDataDue)

        });
      }
      else {
        let Announcementdelivery = planningdates[0].irannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irannouncementDelivery);
        let IrclientDataDue = planningdates[0].irclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientDataDue);

        this.form.patchValue({
          announcementdeliveryAP: planningdates[0].irannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueAP: planningdates[0].irclientDataDue == null ? null : this.formatDate(IrclientDataDue)

        });
      }
    } else {
      this.form.patchValue({
        announcementdeliveryAP: "",
        clientdatadueAP: ""
      });
    }


  }
  onchangeFinancialAuditLeadAP(leadtype) {
    this.form.patchValue({
      auditexecutionstartdateAP: null,
      auditexecutionenddateAP: null,
      announcementdeliveryAP: null,
      clientdatadueAP: null,
      draftreportAP: null,
      iaworkcompletedAP: null,
      iaworkpaperreviewdueAP: null,
      draftreporttoclientdueAP: null,
      clientfeedbackdueAP: null,
      finalreporttoclientdueAP: null
    });

  }
  onChangeExcutionEndDateAP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "AP";
    });
    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadAP == "EY") {
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        let EydraftReport = planningdates[0].eydraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReport);
        this.form.patchValue({
          draftreporttoclientdueAP: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueAP: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueAP: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue),
          draftreportAP: planningdates[0].eydraftReport == null ? null : this.formatDate(EydraftReport)
        });
      }
      else {
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        let IrdraftReport = planningdates[0].irdraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReport);
        this.form.patchValue({
          draftreporttoclientdueAP: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueAP: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueAP: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue),
          draftreportAP: planningdates[0].irdraftReport == null ? null : this.formatDate(IrdraftReport)
        });
      }
    } else {
      this.form.patchValue({
        draftreporttoclientdueAP: "",
        clientfeedbackdueAP: "",
        finalreporttoclientdueAP: "",
        draftreportAP: ""
      });

    }

  }

  onChangeIAworkCompletedDateAP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "AP";
    });


    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadAP == "IR") {
        let IriaworAPaperReviewDue = planningdates[0].iriaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].iriaworkPaperReviewDue);
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueAP: planningdates[0].iriaworkPaperReviewDue == null ? null : this.formatDate(IriaworAPaperReviewDue),
          draftreporttoclientdueAP: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueAP: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueAP: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue)
        });
      }
      else {
        let EyiaworAPaperReviewDue = planningdates[0].eyiaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyiaworkPaperReviewDue);
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueAP: planningdates[0].eyiaworkPaperReviewDue == null ? null : this.formatDate(EyiaworAPaperReviewDue),
          draftreporttoclientdueAP: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueAP: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueAP: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue)
        });
      }
    } else {

      this.form.patchValue({
        iaworkpaperreviewdueAP: "",
        draftreporttoclientdueAP: "",
        clientfeedbackdueAP: "",
        finalreporttoclientdueAP: ""
      });

    }

  }

  onChangeExcutionStartDateKP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }

    if (this.form.value.auditexecutionenddateKP != null) {
      if (this.form.value.auditexecutionenddateKP.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          auditexecutionenddateKP: null
        });
      }
    }
    this.executionEndDateKPOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy', firstDayOfWeek:'mo',disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
    let year = this.form.value.audityear;

    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "KP";
    });


    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadKP == "EY") {
        let Announcementdelivery = planningdates[0].eyannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyannouncementDelivery);
        let eyclientDataDue = planningdates[0].eyclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientDataDue);
        this.form.patchValue({
          announcementdeliveryKP: planningdates[0].eyannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueKP: planningdates[0].eyclientDataDue == null ? null : this.formatDate(eyclientDataDue)
        });
      }
      else {
        let Announcementdelivery = planningdates[0].irannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irannouncementDelivery);
        let eyclientDataDue = planningdates[0].irclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientDataDue);

        this.form.patchValue({
          announcementdeliveryKP: planningdates[0].irannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueKP: planningdates[0].irclientDataDue == null ? null : this.formatDate(eyclientDataDue),

        });
      }
    } else {
      this.form.patchValue({
        announcementdeliveryKP: "",
        clientdatadueKP: ""
      });
    }
  }
  onchangeFinancialAuditLeadKP(leadtype) {
    this.form.patchValue({
      auditexecutionstartdateKP: null,
      auditexecutionenddateKP: null,
      announcementdeliveryKP: null,
      clientdatadueKP: null,
      draftreportKP: null,
      iaworkcompletedKP: null,
      iaworkpaperreviewdueKP: null,
      draftreporttoclientdueKP: null,
      clientfeedbackdueKP: null,
      finalreporttoclientdueKP: null
    });

  }
  onChangeExcutionEndDateKP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "KP";
    });


    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadKP == "EY") {
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        let EydraftReport = planningdates[0].eydraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReport);
        this.form.patchValue({
          draftreporttoclientdueKP: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueKP: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueKP: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue),
          draftreportKP: planningdates[0].eydraftReport == null ? null : this.formatDate(EydraftReport)
        });
      }
      else {
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        let IRdraftReport = planningdates[0].irdraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReport);
        this.form.patchValue({
          draftreporttoclientdueKP: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueKP: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueKP: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue),
          draftreportKP: planningdates[0].irdraftReport == null ? null : this.formatDate(IRdraftReport)
        });
      }
    } else {
      this.form.patchValue({
        draftreporttoclientdueKP: "",
        clientfeedbackdueKP: "",
        finalreporttoclientdueKP: "",
        draftreportKP: ""
      });

    }





  }

  onChangeIAworkCompletedDateKP(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "KP";
    });

    if (currentdate.singleDate.jsDate != null) {


      if (this.form.value.financialauditleadKP == "IR") {
        let IriaworkPaperReviewDue = planningdates[0].iriaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].iriaworkPaperReviewDue);
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueKP: planningdates[0].iriaworkPaperReviewDue == null ? null : this.formatDate(IriaworkPaperReviewDue),
          draftreporttoclientdueKP: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueKP: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueKP: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue)
        });
      }
      else {
        let EyiaworkPaperReviewDue = planningdates[0].eyiaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyiaworkPaperReviewDue);
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueKP: planningdates[0].eyiaworkPaperReviewDue == null ? null : this.formatDate(EyiaworkPaperReviewDue),
          draftreporttoclientdueKP: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueKP: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueKP: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue)
        });
      }
    } else {

      this.form.patchValue({
        iaworkpaperreviewdueKP: "",
        draftreporttoclientdueKP: "",
        clientfeedbackdueKP: "",
        finalreporttoclientdueKP: ""
      });

    }
  }
  onChangeStartDateOT(currentdate) {

    if (this.form.value.auditperiodenddateOther != null) {
      if (this.form.value.auditperiodenddateOther.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          auditperiodenddateOther: null
        });
      }
    }
    this.endDateOTOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy', firstDayOfWeek:'mo',disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
  }

  onChangeExcutionStartDateOT(currentdate) {

    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }

    if (this.form.value.auditexecutionenddateOther != null) {
      if (this.form.value.auditexecutionenddateOther.singleDate.formatted < currentdate.singleDate.formatted) {
        this.form.patchValue({
          auditexecutionenddateOther: null
        });
      }
    }
    this.executionEndDateOTOptions = { dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo', disableUntil: currentdate == null ? { year: 0, month: 0, day: 0 } : currentdate.singleDate.date }
    let year = this.form.value.audityear;

    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "OT";
    });
    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadOther == "EY") {
        let Announcementdelivery = planningdates[0].eyannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyannouncementDelivery);
        let eyclientDataDue = planningdates[0].eyclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientDataDue);

        this.form.patchValue({
          announcementdeliveryOT: planningdates[0].eyannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueOT: planningdates[0].eyclientDataDue == null ? null : this.formatDate(eyclientDataDue),

        });
      }
      else {
        let Announcementdelivery = planningdates[0].irannouncementDelivery == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irannouncementDelivery);
        let IrclientDataDue = planningdates[0].irclientDataDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientDataDue);

        this.form.patchValue({
          announcementdeliveryOT: planningdates[0].irannouncementDelivery == null ? null : this.formatDate(Announcementdelivery),
          clientdatadueOT: planningdates[0].irclientDataDue == null ? null : this.formatDate(IrclientDataDue),
        });
      }
    } else {

      this.form.patchValue({
        announcementdeliveryOT: "",
        clientdatadueOT: "",
      });
    }


  }
  onchangeFinancialAuditLeadOT(leadtype) {
    this.form.patchValue({
      auditexecutionstartdateOther: null,
      auditexecutionenddateOther: null,
      announcementdeliveryOT: null,
      clientdatadueOT: null,
      draftreportOT: null,
      iaworkcompletedOT: null,
      iaworkpaperreviewdueOT: null,
      draftreporttoclientdueOT: null,
      clientfeedbackdueOT: null,
      finalreporttoclientdueOT: null
    });
    if (this.form.value.financialauditleadOther == 'EY') {
      this.lstOTTeammembers = this.lstOTTeammembersEY
    }
    else if (this.form.value.financialauditleadOther == 'IR') {
      this.lstOTTeammembers = this.lstOTTeammembersIR

    }

    this.form.patchValue({
      regionalleadpartnerOther: null,
      assignedleadauditorOther: null
    });
  }
  onChangeExcutionEndDateOT(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "OT";
    });

    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadOther == "EY") {
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        let EydraftReport = planningdates[0].eydraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReport);
        this.form.patchValue({
          draftreporttoclientdueOT: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueOT: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueOT: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue),
          draftreportOT: planningdates[0].eydraftReport == null ? null : this.formatDate(EydraftReport)
        });
      }
      else {
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        let IrdraftReport = planningdates[0].irdraftReport == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReport);
        this.form.patchValue({
          draftreporttoclientdueOT: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueOT: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueOT: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue),
          draftreportOT: planningdates[0].irdraftReport == null ? null : this.formatDate(IrdraftReport)
        });

      }
    } else {

      this.form.patchValue({
        draftreporttoclientdueOT: "",
        clientfeedbackdueOT: "",
        finalreporttoclientdueOT: "",
        draftreportOT: ""
      });
    }


  }

  onChangeIAworkCompletedDateOT(currentdate) {
    if (this.form.value.entityname == null || this.form.value.entityname == "" || this.form.value.audityear == null) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please provide entity details and audit year";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    let year = this.form.value.audityear;
    let planningdates = this.lstPlanningDates.filter(function (n, i) {
      return n.year == year && n.auditType == "OT";
    });
    if (currentdate.singleDate.jsDate != null) {
      if (this.form.value.financialauditleadOther == "IR") {
        let IriaworkPaperReviewDue = planningdates[0].iriaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].iriaworkPaperReviewDue);
        let IrdraftReportToClientDue = planningdates[0].irdraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irdraftReportToClientDue);
        let IrclientFeedbackDue = planningdates[0].irclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irclientFeedbackDue);
        let IrfinalReporttoClientDue = planningdates[0].irfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].irfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueOT: planningdates[0].iriaworkPaperReviewDue == null ? null : this.formatDate(IriaworkPaperReviewDue),
          draftreporttoclientdueOT: planningdates[0].irdraftReportToClientDue == null ? null : this.formatDate(IrdraftReportToClientDue),
          clientfeedbackdueOT: planningdates[0].irclientFeedbackDue == null ? null : this.formatDate(IrclientFeedbackDue),
          finalreporttoclientdueOT: planningdates[0].irfinalReporttoClientDue == null ? null : this.formatDate(IrfinalReporttoClientDue)
        });
      } else {
        let EyiaworkPaperReviewDue = planningdates[0].eyiaworkPaperReviewDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyiaworkPaperReviewDue);
        let EydraftReportToClientDue = planningdates[0].eydraftReportToClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eydraftReportToClientDue);
        let EyclientFeedbackDue = planningdates[0].eyclientFeedbackDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyclientFeedbackDue);
        let EyfinalReporttoClientDue = planningdates[0].eyfinalReporttoClientDue == null ? null : this.CalculateBussinessDates(currentdate.singleDate.jsDate, planningdates[0].eyfinalReporttoClientDue);
        this.form.patchValue({
          iaworkpaperreviewdueOT: planningdates[0].eyiaworkPaperReviewDue == null ? null : this.formatDate(EyiaworkPaperReviewDue),
          draftreporttoclientdueOT: planningdates[0].eydraftReportToClientDue == null ? null : this.formatDate(EydraftReportToClientDue),
          clientfeedbackdueOT: planningdates[0].eyclientFeedbackDue == null ? null : this.formatDate(EyclientFeedbackDue),
          finalreporttoclientdueOT: planningdates[0].eyfinalReporttoClientDue == null ? null : this.formatDate(EyfinalReporttoClientDue)
        });
      }

    } else {


      this.form.patchValue({
        iaworkpaperreviewdueOT: "",
        draftreporttoclientdueOT: "",
        clientfeedbackdueOT: "",
        finalreporttoclientdueOT: ""
      });
    }


  }
  ValidationsforAuditTypes() {
    this.form.get('AuditTypeRP').valueChanges
      .subscribe(value => {

        if (value) {
          this.form.get('financialauditleadRP').setValidators(Validators.required);
          this.form.get('auditperiodstartdateRP').setValidators(Validators.required);
          this.form.get('auditperiodenddateRP').setValidators(Validators.required);
        } else {
          this.form.get('financialauditleadRP').setValidators(null);
          this.form.get('auditperiodstartdateRP').setValidators(null);
          this.form.get('auditperiodenddateRP').setValidators(null);
        }
        this.form.get('financialauditleadRP').updateValueAndValidity();
        this.form.get('auditperiodstartdateRP').updateValueAndValidity();
        this.form.get('auditperiodenddateRP').updateValueAndValidity();




      }
      );
    this.form.get('AuditTypeKP').valueChanges
      .subscribe(value => {

        if (value) {
          this.form.get('financialauditleadKP').setValidators(Validators.required);
        } else {
          this.form.get('financialauditleadKP').setValidators(null);
        }
        this.form.get('financialauditleadKP').updateValueAndValidity();


      }
      );
    this.form.get('AuditTypeAP').valueChanges
      .subscribe(value => {

        if (value) {
          this.form.get('financialauditleadAP').setValidators(Validators.required);
        } else {
          this.form.get('financialauditleadAP').setValidators(null);
        }
        this.form.get('financialauditleadAP').updateValueAndValidity();


      }
      );
    this.form.get('AuditTypeOther').valueChanges
      .subscribe(value => {

        if (value) {
          this.form.get('financialauditleadOther').setValidators(Validators.required);
          this.form.get('regionalleadpartnerOther').setValidators(Validators.required);
          this.form.get('assignedleadauditorOther').setValidators(Validators.required);
        } else {
          this.form.get('financialauditleadOther').setValidators(null);
          this.form.get('regionalleadpartnerOther').setValidators(null);
          this.form.get('assignedleadauditorOther').setValidators(null);
        }
        this.form.get('financialauditleadOther').updateValueAndValidity();
        this.form.get('regionalleadpartnerOther').updateValueAndValidity();
        this.form.get('assignedleadauditorOther').updateValueAndValidity();

      }
      );

  }
  addBusinessDays(startDate, days) {
    // Getting negative / positive increment
    var increment = days / Math.abs(days);
    // Looping weeks for each full 5 workdays
    var date = moment(startDate).clone().add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
    // Account for starting on Saturdays and Sundays
    if (date.isoWeekday() === 6) { date.add(-increment, 'days'); }
    else if (date.isoWeekday() === 7) { date.add(-2 * increment, 'days'); }
    // Adding / removing remaining days in a short loop, jumping over weekends
    var remaining = days % 5;
    while (remaining != 0) {
      date.add(increment, 'days');
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7)
        remaining -= increment;
    }
    return date;
  };

  CalculateBussinessDates(startDate, businessDays) {
    var endDate = this.addBusinessDays(startDate, businessDays);
    var canton = this.form.value.canton;
    var holidayDates = this.lstHolidaysMaster.filter(function (x, i) {
      return (x.year == startDate.getFullYear() && x.cantonShortname.trim() == canton) || (x.year == endDate.year() && x.cantonShortname.trim() == canton);
    });
    var daystobeadded = 0;
    holidayDates = holidayDates.map(x => moment(x.startAt))

    //const weekday = momentObject.format('dddd'); // Monday ... Sundayconst isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
    holidayDates = holidayDates.filter(x => x.format('dddd') != 'Sunday' && x.format('dddd') != 'Saturday')
    if (Number(businessDays) > 0)
      daystobeadded = holidayDates.filter(i => i <= endDate && i >= startDate).length
    else
      daystobeadded = -holidayDates.filter(i => i <= startDate && i >= endDate).length
    console.log(daystobeadded);
    if (daystobeadded != 0)
      endDate = this.addBusinessDays(endDate, daystobeadded);
    while (holidayDates.filter(i => i.diff(endDate) == 0).length > 0) {
      if (Number(businessDays) > 0)
        endDate = this.addBusinessDays(endDate, 1);
      else
        endDate = this.addBusinessDays(endDate, -1);
    }
    return endDate
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('.');
  }
  GetRoleBasedRegion() {

    this._auditassignmentService.GetRoleBasedRegion()
      .subscribe(
        res => {
          
          this.RoleBasedRegion = res;


        },
        err => {

          console.log(err);
        }
      )

  }
  trackById(index: number, item: any) {
    return item.id;
  }
  ////#endregion

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    debugger;
    let updateid = this._auditassignmentService.getupdateData();
    if(updateid.newflag == "AUDupdate"){
      this.Escmodalflag=true;
    }
    else{
      this.oncancel('cancel');
    }
  }  

}
