import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if( (window.location.href.indexOf("directory") !=-1 || window.location.href.indexOf("clientEntity") !=-1
|| window.location.href.indexOf("auditassignment") !=-1 || window.location.href.indexOf("preapproval") !=-1) &&  window.location.href.indexOf("id") !=-1)
{

localStorage.setItem('url', window.location.href);
}



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
