import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject,HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignalRService } from './core/Services/signal-r.service';
import { AdalService } from "adal-angular4";
import * as myGlobal from "../Global";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EYAMT';
  
  
// @HostListener('keydown', ['$event']) onKeyDown(event) {​​​        
//          var specialKeys = new Array();
//      specialKeys.push(8);  //Backspace
//      specialKeys.push(9);  //Tab
//      specialKeys.push(46); //Delete
//      specialKeys.push(36); //Home
//      specialKeys.push(35); //End
//      specialKeys.push(37); //Left
//      specialKeys.push(39); //Right
// let e = <KeyboardEvent> event;
// if (true) {​​​
// if ([46, 8, 9, 27, 13, 110, 190,222,192,186,219].indexOf(e.keyCode) !== -1 ||
// // Allow: Ctrl+A
//         (e.keyCode == 65 && e.ctrlKey === true) ||
// // Allow: Ctrl+C
//         (e.keyCode == 67 && e.ctrlKey === true) ||
// // Allow: Ctrl+X
//         (e.keyCode == 88 && e.ctrlKey === true) ||
// // Allow: home, end, left, right
//         (e.keyCode >= 35 && e.keyCode <= 39)) {​​​
// // let it happen, don't do anything
// return;
//         }​​​
//         let specialcharacters=['!','#','$','%','^','*','~','<','>','?']
//         // Ensure that it is a number and stop the keypress
        
//         if(specialcharacters.indexOf(e.key)==-1){
//                 if ((e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)||  e.keyCode == 45||  e.keyCode == 189 ||e.keyCode == 188 || e.keyCode == 32|| e.keyCode == 40 || (e.keyCode >= 97 && e.keyCode <= 122))) {​​​
            
//                 }
//         ​​​     else 
//                 e.preventDefault();
//         }
//         else
//         e.preventDefault();
//       }​​​

//   }​​​
​​​
​

  constructor(
    private router: Router, 
    @Inject(DOCUMENT) document,
    private adalService: AdalService
  ) {
    debugger;    
      this.adalService.init(myGlobal.adalEYConfiguration);
      this.adalService.handleWindowCallback();
    
  }
  ngOnInit() {
          
            ​​​
            
            
      if (this.adalService.userInfo.authenticated) {
        // let accessStatus = sessionStorage.getItem("Access");
        // if (accessStatus != null) {
        //   if (accessStatus == "Invalid Domain")
        //     this.router.navigate(["/InvalidDomain"]);
        //   else if (accessStatus == 'InvalidUser') {
        //     this.router.navigate(["/UserRegistration"]);
        //   }
        //   else
        //     this.router.navigate(["/AccessDenied"]);
        // } 
        
      }
        else   
        this.router.navigate(["/login"]);
     
    }
  }
  



