import { Component, OnInit } from '@angular/core';
import { MotifTableHeaderRendererComponent } from '@ey-xd/ng-motif';
import { MotifTableCellRendererComponent } from '@ey-xd/ng-motif';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  MotifTableHeaderRendererComponent = MotifTableHeaderRendererComponent;
  MotifTableCellRendererComponent = MotifTableCellRendererComponent;
  gridApi;
  rowData;
  rowDataforAuditType;
  columnDefs1;
  columnDefs2;
  PieChart = {
    title: {
      text: 'No. of Client entities'
    },
    tooltip: {
      trigger: 'item'
    },
    // legend: {
    //     orient: 'Horizantal',
    //     //left: 'left',
    //     //right: 12,
    // },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'No. of Client entities',
        type: 'pie',
        radius: ['50%'],
        top: '10%',
        data: [
          { value: 126, name: 'Active' },
          { value: 35, name: 'Discontinued' },
          { value: 10, name: 'Merged' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  doughNutChart = {
    title: {
      text: 'No. of Client entities'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    // legend: {
    //     orient: 'Horizantal',
    //     //right: 10,
    //     // data: ['Small', 'Medium', 'Large']
    // },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'No. of Client entities',
        type: 'pie',
        radius: ['25%', '50%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 126, name: 'small' },
          { value: 35, name: 'Medium' },
          { value: 10, name: 'Large' },
        ]
      }
    ]
  };
  constructor() {

  }
  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };
  option = {
    title: {
      text: 'Assigned Audits'
    },
    toolbox: {
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        //dataView: { show: true, readOnly: false },
        saveAsImage: {
          //name: 'Download'
          title: 'Download'
        },
        magicType: {
          show: true
          //type: ['line', 'stack', 'tiled']
        },
        restore: {
          show: true
        }
      }
    },
    legend: {
      orient: 'Horizantal',
      position: 'center',
      //data: ['Entity1','Entity2']
    },
    visualMap: {
      type: 'continuous',
      min: 0,
      max: 10,
      inRange: {
        color: ['#2F93C8', '#AEC48F', '#FFDB5C', '#F98862']
      }
    },
    series: {
      type: 'sunburst',
      avoidLabelOverlap: true,
      label: {
        show: true,
        fontSize: '10',
        fontWeight: 'bold',
        position: 'inside',
        formatter: function (param) {
          
          var depth = param.treePathInfo.length;
          if (depth === 2) {
              return param.name.substring(0, 7) + '...';
          }
          // else if (depth === 3) {
          //     return 'tangential';
          // }
          // else if (depth === 4) {
          //     return '1';
          // }
          else if (depth === 5) {
            return param.name.substring(0, 7) + '...';
          }
        }
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '10',
          fontWeight: 'bold'

        },
        //focus: 'ancestor',
        rotate: 'radial'
      },
      labelLine: {
        show: false
      },

      // emphasis: {
      //     focus: 'ancestor'
      // },
      data: [{
        name: 'Raiffeisenbank Prättigau-Davos Genossenschaft',
        children: [{
          name: 'AP',
          //value: 15,
          children: [{
            name: 'TM',
            value: 3,
            children: [
              { name: 'andrea.pozzi3@raiffeisen.ch', value: 1 },
              { name: 'diego.delponte@raiffeisen.ch', value: 1 },
              { name: 'ivan.rifaldi@raiffeisen.ch', value: 1 }
            ]
          }, {
            name: 'LA',
            value: 1,
            children: [
              { name: 'marc.ramseier@raiffeisen.ch', value: 1 }
            ]

          }, {
            name: 'LP',
            value: 1,
            children: [
              { name: 'audrey.oberson@raiffeisen.ch', value: 1 }
            ]
          }]
        }, {
          name: 'RP',
          //value: 10,
          children: [{
            name: 'TM',
            value: 3,
            children: [
              { name: 'andrea.pozzi3@raiffeisen.ch', value: 1 },
              { name: 'diego.delponte@raiffeisen.ch', value: 1 },
              { name: 'ivan.rifaldi@raiffeisen.ch', value: 1 }
            ]
          }, {
            name: 'LA',
            value: 1,
            children: [
              { name: 'marc.ramseier@raiffeisen.ch', value: 1 }
            ]
          },
          {
            name: 'LP',
            value: 1,
            children: [
              { name: 'audrey.oberson@raiffeisen.ch', value: 1 }
            ]
          }]
        },
        {
          name: 'KP',
          //value: 10,
          children: [{
            name: 'TM',
            value: 3,
            children: [
              { name: 'andrea.pozzi3@raiffeisen.ch', value: 1 },
              { name: 'diego.delponte@raiffeisen.ch', value: 1 },
              { name: 'ivan.rifaldi@raiffeisen.ch', value: 1 }
            ]
          }, {
            name: 'LA',
            value: 1,
            children: [
              { name: 'marc.ramseier@raiffeisen.ch', value: 1 }
            ]
          },
          {
            name: 'LP',
            value: 1,
            children: [
              { name: 'audrey.oberson@raiffeisen.ch', value: 1 }
            ]
          }]
        }]
      }, {
        name: 'Raiffeisenbank Emmen Genossenschaft',
        children: [{
          name: 'AP',
          //value: 15,
          children: [{
            name: 'TM',
            value: 3,
            children: [
              { name: 'andrea.pozzi3@raiffeisen.ch', value: 1 },
              { name: 'diego.delponte@raiffeisen.ch', value: 1 },
              { name: 'ivan.rifaldi@raiffeisen.ch', value: 1 }
            ]
          }, {
            name: 'LA',
            value: 1,
            children: [
              { name: 'marc.ramseier@raiffeisen.ch', value: 1 }
            ]

          }, {
            name: 'LP',
            value: 1,
            children: [
              { name: 'audrey.oberson@raiffeisen.ch', value: 1 }
            ]
          }]
        }, {
          name: 'RP',
          //value: 10,
          children: [{
            name: 'TM',
            value: 3,
            children: [
              { name: 'andrea.pozzi3@raiffeisen.ch', value: 1 },
              { name: 'diego.delponte@raiffeisen.ch', value: 1 },
              { name: 'ivan.rifaldi@raiffeisen.ch', value: 1 }
            ]
          }, {
            name: 'LA',
            value: 1,
            children: [
              { name: 'marc.ramseier@raiffeisen.ch', value: 1 }
            ]
          },
          {
            name: 'LP',
            value: 1,
            children: [
              { name: 'audrey.oberson@raiffeisen.ch', value: 1 }
            ]
          }]
        },
        {
          name: 'Other',
          //value: 10,
          children: [{
            name: 'TM',
            value: 3,
            children: [
              { name: 'andrea.pozzi3@raiffeisen.ch', value: 1 },
              { name: 'diego.delponte@raiffeisen.ch', value: 1 },
              { name: 'ivan.rifaldi@raiffeisen.ch', value: 1 }
            ]
          }, {
            name: 'LA',
            value: 1,
            children: [
              { name: 'marc.ramseier@raiffeisen.ch', value: 1 }
            ]
          },
          {
            name: 'LP',
            value: 1,
            children: [
              { name: 'audrey.oberson@raiffeisen.ch', value: 1 }
            ]
          }]
        }]
      }],
      radius: [0, '100%'],
      // label: {
      //     rotate: 'radial'
      // }
    },
    tooltip: {
      trigger: 'item',
      //formatter: '{a} <br/>{b}: {c} ({d}%)'
      formatter: '{b}'
      // formatter: (params) => {
      //   
      // }
    }
  };
  barChartofGS = {
    title: {
      // top:10,
      text: 'No. of Audits by Group Scope'
    },
    toolbox: {
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        //dataView: { show: true, readOnly: false },
        saveAsImage: {
          //name: 'Download'
          title: 'Download'
        },
        magicType: {
          show: true,
          type: ['line', 'stack', 'tiled']
        },
        restore: {
          show: true
        }
      }
    },
    legend: {
      bottom: 20,
      data: ['Group Scope - Yes', 'Group Scope - No']
    },
    tooltip: {},
    dataset: {
      source: [
        ['Group Scope', 'Group Scope - Yes', 'Group Scope - No'],
        ['2021', 115, 79],
        ['2020', 90, 170],
        ['2019', 130, 155],
      ]
    },
    label: {
      show: true,
      position: 'top',
      fontWeight: 'bold'
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
      { type: 'bar' },
      { type: 'bar' },

    ]
  };
  barChartofFormStatus = {
    title: {
      text: 'No. of Audits by Form Status'
    },
    toolbox: {
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        //dataView: { show: true, readOnly: false },
        saveAsImage: {
          //name: 'Download'
          title: 'Download'
        },
        magicType: {
          show: true,
          type: ['line', 'stack', 'tiled']
        },
        restore: {
          show: true
        }
      }
    },
    legend: {
      bottom: 20,

      data: ['Draft Planning', 'In Review/In Progress', 'Final Assigned']
    },
    tooltip: {},
    dataset: {
      source: [
        ['Form Status', 'Draft Planning', 'In Review/In Progress', 'Final Assigned'],
        ['2021', 80, 110, 45],
        ['2020', 130, 57, 40],
        ['2019', 50, 110, 80],
      ]
    },
  //   visualMap: {
  //     type: 'continuous',
  //     inRange: {
  //         color: ['#2F93C8', '#AEC48F', '#FFA500']
  //     }
  // },
    label: {
      show: true,
      position: 'top',
      fontWeight: 'bold'
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
      { type: 'bar' },
      { type: 'bar' },
      { type: 'bar' }

    ]
  };


  ngOnInit(): void {
    this.columnDefs1 = [
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Form Title',
        field: 'formTitle',
        sortable: true,
        filter: true,
        cellClass: "ag-grid-cell-wrap-text",
        wrapText: true,
        autoHeight: true,
        width: 500,
        resizable: true
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Audit Year',
        field: 'AuditYear',
        sortable: true,
        filter: true,
        cellClass: "ag-grid-cell-wrap-text",
        wrapText: true,
        autoHeight: true,
        //width: 400,
        resizable: true
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Classification',
        field: 'Classification',
        sortable: true,
        filter: true,
        cellClass: "ag-grid-cell-wrap-text",
        wrapText: true,
        autoHeight: true,
        //width: 400,
        resizable: true
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Group Scope',
        field: 'GroupScope',
        sortable: true,
        filter: true,
        cellClass: "ag-grid-cell-wrap-text",
        wrapText: true,
        autoHeight: true,
        //width: 400,
        resizable: true
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Form Status',
        field: 'FormStatus',
        sortable: true,
        filter: true,
        cellClass: "ag-grid-cell-wrap-text",
        wrapText: true,
        autoHeight: true,
        //width: 400,
        resizable: true
      },
    ];
    this.rowData = [
      // {formTitle: '2019 | Banca Raiffeisen Basso Ceresio società cooperativa',AuditYear:2019,Classification:'BZP',GroupScope:'No',FormStatus:"In progress/In review"},  
      // {formTitle: '2019 | Banca Raiffeisen Basso Ceresio società cooperativa',AuditYear:2019,Classification:'Standard',GroupScope:'yes',FormStatus:"Draft Planning"},
      { formTitle: '2019 | Banca Raiffeisen Basso Ceresio società cooperativa', AuditYear: 2019, Classification: 'Standard', GroupScope: 'yes', FormStatus: "Final Assigned" }];
    this.rowDataforAuditType = [
      { FinancialAuditlead: 'EY', AuditPeriodStartdate: '2021-03-23', AuditPeriodEnddate: '2021-08-23', AuditExecutionStartdate: '2021-07-23', AuditExecutionEnddate: "2022-03-23", AuidtIdCode: '0287RP19' },
      { FinancialAuditlead: 'IR', AuditPeriodStartdate: '2023-03-10', AuditPeriodEnddate: '2023-08-23', AuditExecutionStartdate: '2023-07-23', AuditExecutionEnddate: "2024-03-23", AuidtIdCode: '0287AP20' },
      { FinancialAuditlead: 'NA', AuditPeriodStartdate: '2023-03-10', AuditPeriodEnddate: '2023-08-23', AuditExecutionStartdate: '2023-07-23', AuditExecutionEnddate: "2024-03-23", AuidtIdCode: '0287KP21' },
      { FinancialAuditlead: 'NA', AuditPeriodStartdate: '2023-03-10', AuditPeriodEnddate: '2023-08-23', AuditExecutionStartdate: '2023-07-23', AuditExecutionEnddate: "2024-03-23", AuidtIdCode: '0287OH21' },];
    this.columnDefs2 = [
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Financial Audit Lead',
        field: 'FinancialAuditlead',
        sortable: true,
        filter: true,
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Audit Period Start Date',
        field: 'AuditPeriodStartdate',
        sortable: true,
        filter: true,
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Audit Period End Date',
        field: 'AuditPeriodEnddate',
        sortable: true,
        filter: true,
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Audit Execution Start Date',
        field: 'AuditExecutionStartdate',
        sortable: true,
        filter: true,
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'Audit Execution End Date',
        field: 'AuditExecutionEnddate',
        sortable: true,
        filter: true,
      },
      {
        headerComponentFramework: MotifTableHeaderRendererComponent,
        headerName: 'AuidtId Code',
        field: 'AuidtIdCode',
        sortable: true,
        filter: true,
      }
    ];
  }
  tableShow: boolean = false;
  auditTypeTable: boolean = false;
  tempDataforAuditType: any;
  addChartFilter(e) {
    
    console.log(e)
    if (e.treePathInfo.length == 2) {
      this.tableShow = true;
      this.auditTypeTable = false;

      // if(e.data.name=='Entity1'){
      //   this.rowData=this.rowData.filter(x=>e.data.name=='Entity1');
      // }
      // else if(e.data.name=='Entity2'){
      //   this.rowData=this.rowData[1];
      // }
    }
    else if (e.treePathInfo.length == 3) {
      this.tableShow = false;
      this.auditTypeTable = true;
      // if(e.data.name=='RP'){
      this.tempDataforAuditType = this.rowDataforAuditType.filter(y => y.AuidtIdCode.includes(e.data.name))
      //   }
    }
    else {
      this.tableShow = false;
      this.auditTypeTable = false;
    }
  }
}


