import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HolidayTableService {

  constructor(private _http: HttpClient) { }

  getCantons() {
    return this._http.get(`${environment.apiUrl}/Holidays/GetMasterData`);
  }


  get(year) {

    return this._http.post(`${environment.apiUrl}/Holidays/GetHolidays`, { year: year });
  }

  Add(obj) {
    return this._http.post(`${environment.apiUrl}/Holidays/PostHoliday/`, obj);

  }

  Update(obj) {
    return this._http.post(`${environment.apiUrl}/Holidays/UpdateHoliday/`, obj);

  }

  Delete(id) {


    return this._http.post(`${environment.apiUrl}/Holidays/DeleteHoliday`, { id: id });

  }


  GenarateHolidays(year) {

    return this._http.post(`${environment.apiUrl}/Holidays/GenrateHolidays`, { year: year });

  }
  ExportExcel(year: number) {
    return this._http.get(`${environment.apiUrl}/Holidays/ExcelExport?year=` + year, { responseType: "blob" });
  }
  TempleateExport() {
    return this._http.get(`${environment.apiUrl}/Holidays/DownloadTemplate`, { responseType: "blob" });
  }
  Upload(formdata: FormData) {

    return this._http.post(`${environment.apiUrl}/Holidays/Upload/`, formdata);

  }
}
