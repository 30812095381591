                            <ul>
                                 <li *ngIf="params?.FirstName !=undefined"> First Name : {{params.FirstName}}  </li>
                            <li *ngIf="params?.LastName !=undefined"> Last Name : {{params.LastName}}  </li>
  
                            <li *ngIf="params?.NameCombined !=undefined"> Name Combined : {{params.NameCombined}}  </li>
  
                            <li *ngIf="params?.Gender !=undefined"> Gender : {{params.Gender}}  </li>
                            <li *ngIf="params?.ContactType !=undefined"> Contact Type : {{params.ContactType}}  </li>
  
                            <li *ngIf="params?.Salutation !=undefined"> Salutation : {{params.Salutation}}  </li>
  
                            <li *ngIf="params?.Function !=undefined"> Function : {{params.Function}}  </li>
  
                            <li *ngIf="params?.Email !=undefined"> Email : {{params.Email}}  </li>
  
                            <li *ngIf="params?.Phone !=undefined"> Phone no. : {{ params?.Phone}}  </li>
  
                            <li *ngIf="params?.CommunicationLanguage !=undefined"> Communication Language : {{params.CommunicationLanguage}}  </li>
                            <li *ngIf="params?.Mobile !=undefined"> Mobile no.: {{params?.Mobile}}  </li>                           
  
                            <li *ngIf="params?.Location !=undefined"> Location : {{params.Location}}  </li>
                            <li *ngIf="params?.Role !=undefined"> Role : {{params.Role}}  </li>
                            <li *ngIf="params?.Rank !=undefined"> Rank : {{params.Rank}}  </li>
  
                            <li *ngIf="params?.EycontactSeniorManagerPromotiondate !=undefined"> EY contact Senior Manager Promotion date : {{params?.EycontactSeniorManagerPromotiondate| date: 'yyyy-MM-dd'}}  </li>
  
                            <li *ngIf="params?.StartDateonmandate !=undefined"> Start Date on mandate : {{params?.StartDateonmandate| date: 'yyyy-MM-dd'}}  </li>
                            <li *ngIf="params?.EntityName !=undefined"> Entity short Name : {{params.EntityName}}  </li>
                            <li *ngIf="params?.IROffice !=undefined">IR Office : {{params.IROffice}}  </li>
                            <li *ngIf="params?.EYOffice !=undefined"> EY Office : {{params.EYOffice}}  </li>
                            <li *ngIf="params?.UserAccount !=undefined"> User Account : {{params.UserAccount}}  </li>
                            <li *ngIf="params?.CommentContactForm !=undefined"> Comment Contact Form : {{params.CommentContactForm}}  </li>
                            <li *ngIf="params?.CommunicationStatus !=undefined"> Commnunication Status : {{params.CommunicationStatus}}  </li>
             
  
                          </ul>