<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div  *ngIf="divFlag" class="playbook-main">
    <div class="examples-container">
       
        <div class="motif-row">
            <div class="motif-col-xs-5 motif-col-md-5 motif-col-lg-5">
                <div class="motif-modal-header">
                    <h3 class="motif-h3"> Planning Dates Configuration Master</h3>
                </div>
            </div>
            <div  class="motif-col-xs-7 motif-col-md-7 motif-col-lg-7">    
               <div class="motif-row" >
                  <!-- style="margin-left: 59px;" -->
                 <div class="motif-col-xs-5 motif-col-md-5 motif-col-lg-5">
                    <motif-form-field>
                        <label motifLabel>Year :</label>           
                        <motif-select [(ngModel)]="selectedYear" class="ClienentitytModalselect" (change)="getPlaningdatesConfiguration($event)" aria-label="Location Form Field">
                          <motif-option *ngFor="let item of lstYear" [value]="item">
                           {{item}}
                          </motif-option>
                        </motif-select>        
                    </motif-form-field>
                 </div>
                 <div class="motif-col-xs-7 motif-col-md-7 motif-col-lg-7 buttonsdiv">          
                   <button  motifButton color="primary" *ngIf="btnFlag && ActionsCheck('Add / Update')"  size="large" (click)="CreatePlanningConfiguration()" style="margin-right: 10px;" class="motif-col-xs-4 motif-col-md-4 motif-col-lg-4" type="submit">Save</button>
                   <button motifButton *ngIf="btnFlag && ActionsCheck('Add / Update')"  color="primary"  size="large" (click)="modalflag=true"  style="margin-right: 10px;" class="motif-col-xs-4 motif-col-md-4 motif-col-lg-4" type="submit">Generate Days</button>
                   <button motifButton color="primary"  size="large" *ngIf="!btnFlag && ActionsCheck('Add / Update')"  (click)="UpdatePlanningConfiguration()" style="margin-right: 10px;" class="motif-col-xs-4 motif-col-md-4 motif-col-lg-4" type="submit">Update</button>
                    <button (click)="ExportExcel()" size="large" color="primary" class="motif-col-xs-4 motif-col-md-4 motif-col-lg-4" motifButton><motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel</button>
                </div>
               </div>      
            </div>
        </div>
        <form [formGroup]="form">
            <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <p class="BoldHead2">Planning Dates for Audit</p>
            </div>  
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">RP Lead EY</p>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">RP Lead IR</p>
            </div>  
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">AP Lead EY</p>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">AP Lead IR</p>
            </div>   
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">KP Lead EY</p>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">KP Lead IR</p>
            </div>   
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">OT Lead EY</p>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <p class="BoldHead2">OT Lead IR</p>
            </div>
        </div>      
        <div class="motif-row">
                <div class="motif-col-xs-4 motif-col-lg-2">
                    <label motifLabel>Announcement delivery</label>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-1">
                    <motif-form-field>  
                        <input motifInput type="number" formControlName="RPEYAnnouncementdelivery" aria-label="Username Form Field"  /> 
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-1">
                    <motif-form-field>  
                    <input motifInput type="number" formControlName="RPIRAnnouncementdelivery" aria-label="Username Form Field"  /> 
                    </motif-form-field>
                </div>       
                <div class="motif-col-xs-4 motif-col-lg-1">
                    <motif-form-field>  
                        <input motifInput type="number" formControlName="APEYAnnouncementdelivery" aria-label="Username Form Field"  /> 
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-1">
                    <motif-form-field>  
                    <input motifInput type="number" formControlName="APIRAnnouncementdelivery" aria-label="Username Form Field"  /> 
                    </motif-form-field>
                </div>
                <div class="motif-col-xs-4 motif-col-lg-1">
                    <motif-form-field>  
                    <input motifInput type="number" formControlName="KPEYAnnouncementdelivery" aria-label="Username Form Field"  /> 
                    </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="KPIRAnnouncementdelivery" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
        
            <div class="motif-col-xs-4 motif-col-lg-1">
                    <motif-form-field>  
                    <input motifInput type="number" formControlName="OTEYAnnouncementdelivery" aria-label="Username Form Field"  /> 
                    </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="OTIRAnnouncementdelivery" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <label motifLabel>Client data due</label>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="RPEYClientdatadue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="RPIRClientdatadue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>       
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="APEYClientdatadue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="APIRClientdatadue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="KPEYClientdatadue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="KPIRClientdatadue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="OTEYClientdatadue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="OTIRClientdatadue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <label motifLabel>Draft report</label>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="RPEYDraftreport" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="RPIRDraftreport" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>       
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="APEYDraftreport" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="APIRDraftreport" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="KPEYDraftreport" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="KPIRDraftreport" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="OTEYDraftreport" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="OTIRDraftreport" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <label motifLabel>IA work paper review due</label>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="RPEYIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="RPIRIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>       
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="APEYIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="APIRIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="KPEYIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="KPIRIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="OTEYIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="OTIRIAworkpaperreviewdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <label motifLabel>Draft report to client due</label>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="RPEYDraftreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="RPIRDraftreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>       
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="APEYDraftreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="APIRDraftreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="KPEYDraftreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="KPIRDraftreporttoclientdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="OTEYDraftreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="OTIRDraftreporttoclientdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <label motifLabel>Client feedback due</label>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="RPEYClientfeedbackdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="RPIRClientfeedbackdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>       
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="APEYClientfeedbackdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="APIRClientfeedbackdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="KPEYClientfeedbackdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="KPIRClientfeedbackdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="OTEYClientfeedbackdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="OTIRClientfeedbackdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>
        </div>
        <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
                <label motifLabel>Final report to client due</label>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="RPEYFinalreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="RPIRFinalreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>       
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                    <input motifInput type="number" formControlName="APEYFinalreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="APIRFinalreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="KPEYFinalreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="KPIRFinalreporttoclientdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-1">
                <motif-form-field>  
                <input motifInput type="number" formControlName="OTEYFinalreporttoclientdue" aria-label="Username Form Field"  /> 
                </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-1">
            <motif-form-field>  
            <input motifInput type="number" formControlName="OTIRFinalreporttoclientdue" aria-label="Username Form Field"  /> 
            </motif-form-field>
        </div>
        </div>
    </form>
    </div>
</div>
    <!--<motif-loader [show]="showLoader" [fullScreen]="true"></motif-loader>-->

    <motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
        {{toastmessage}}
    </motif-toast>

    <motif-modal [show]="modalflag" (close)="modalflag = false">
        <motif-modal-header>
            Generate Planning days From Previous Year
        </motif-modal-header>
        <motif-modal-body>
            <p>
                Are you sure, you want to generate planning days from previous year?
            </p>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="generateDays()">Confirm</button>
            <button motifButton color="secondary" (click)="modalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>
