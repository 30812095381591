import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IRRBOfficeService {

  constructor(private _http: HttpClient) { }


  get() {
    return this._http.get(`${environment.apiUrl}/irrbOffice/get`);
  }

  delete(id) {
    return this._http.post(`${environment.apiUrl}/irrbOffice/Delete/`,{id:id});
  }

  Add(obj) {
    return this._http.post(`${environment.apiUrl}/irrbOffice/Post/`, obj);
  }

  Update(obj) {
    return this._http.post(`${environment.apiUrl}/irrbOffice/Update/`, obj);
  }
  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/irrbOffice/ExcelExport`, {responseType: "blob"});
  }
}
