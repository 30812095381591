import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../Services/authentication.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../loader/loading.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public numberofrequests = 0;
  constructor(private authenticationService: AuthenticationService, private _loadingService: LoadingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
      this.numberofrequests++;
      //console.log("Request Added" + this.numberofrequests)
      this._loadingService.isLoading.next(true);
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

      return next.handle(request).pipe(finalize(() => {
        this.numberofrequests--;
        //console.log("Request Procesed" + this.numberofrequests)
        if (this.numberofrequests == 0) {
          this._loadingService.isLoading.next(false);
        }
      }
      ));
    }
}
