import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-edit-delete-actions',
  template: `
  <div class="motif-row">
  <div class="masterIcon" *ngIf="ActionsCheck('Add / Update')">
   <motif-icon [src]="'./assets/icons/editor/ic_mode_edit_24px.svg'" (click)="onEditClick($event)"></motif-icon>
   </div>
   <div *ngIf="ActionsCheck('Delete')" class="masterIcon" [ngClass]="{'masterDeleteIcon': !params.data.hasDelete}">
   <motif-icon [ngClass]="{'iconDisable': !params.data.hasDelete}" [src]="'./assets/icons/action/ic_delete_24px.svg'" (click)="onDeleteClick($event)"></motif-icon>
   </div>
 </div>
   `
})
export class EditDeleteActionsComponent implements ICellRendererAngularComp {
  public params: any;
public Actions:any;
  agInit(params: any): void {
    this.params = params;
    this.Actions=this.params.Actions;
}

refresh(): boolean {
  return false;
  }

  
  ActionsCheck(action){    
    
    if(this.Actions.indexOf(action)!=-1)
    return true;
    else
    return false;
  }
onEditClick($event) {
 // put anything into params u want pass into parents component
    const params = {
      event: $event,
      rowData: this.params.node.data
      // ...something
    }
    this.params.onEditClick(params);
}
  onDeleteClick($event) {
   
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onDeleteClick(params);
}
}
