<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">

    <div class="playbook-main">
        <form [formGroup]="form" novalidate>
            <fieldset [disabled]='auditassign' style="border: 0;">
                <section class="motif-modal-content">
                    <div class="row">
                        <div class="motif-col-xs-4 motif-col-lg-4" style="padding:0px">
                            <div class="motif-modal-header">
                                <h3 class="motif-h3" style="width: max-content !important;"> Email Configuration For Audit Type Status</h3>
                            </div>
                        </div>
                    </div>
                    <div class="csdivformmastercreation" *ngIf="ActionsCheck('Add / Update')">
                        <div class="motif-row">
                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <motif-form-field>
                                    <label motifLabel>Audit Type</label>
                                    <motif-select formControlName="audittype" class="ClienentitytModalselect"
                                        aria-label="Audit Type" (change)="Auditchange()" [disabled]="AlreadySelected">
                                        <motif-option *ngFor="let item of audittypes" [value]="item">
                                            {{item}}</motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                            form.get('audittype').hasError('required') &&
                                            form.get('audittype').touched
                                            ">Please select a Audit type!</motif-error>
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <motif-form-field>
                                    <label motifLabel>Audit Type Lead</label>
                                    <motif-select [multiple]="true" formControlName="audittypelead"
                                        class="ClienentitytModalselect" aria-label="audittypelead">
                                        <motif-option *ngFor="let item of audittypeleadlist" [value]="item">
                                            {{item}}</motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                            form.get('audittypelead').hasError('required') &&
                                            form.get('audittypelead').touched
                                            ">Please select a Audit Type Lead!</motif-error>
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <motif-form-field *ngIf="dummyshow">
                                    <label motifLabel>Audit Type status</label>
                                    <motif-select class="ClienentitytModalselect" aria-label="Audit Type"
                                        [disabled]="isSelected">
                                        <motif-option *ngFor="let item of audittypes" [value]="item">
                                            {{item}}</motif-option>
                                    </motif-select>
                                </motif-form-field>

                                <motif-form-field *ngIf="form.value.audittype=='AP'">
                                    <label motifLabel>Audit Status AP</label>
                                    <motif-select formControlName="auditstatusap" class="ClienentitytModalselect"
                                        aria-label="auditstatusap" [disabled]="AlreadySelected">
                                        <motif-option *ngFor="let item of lstAuditTypeAP"
                                            [value]="item.auditTypeStatusApId">{{item.auditTypeStatusName}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                              form.get('auditstatusap').hasError('required') &&
                              form.get('auditstatusap').touched
                              ">Please select Audit Status AP!</motif-error>
                                </motif-form-field>

                                <motif-form-field *ngIf="form.value.audittype=='KP'">
                                    <label motifLabel>Audit Status KP</label>
                                    <motif-select formControlName="auditstatuskp" class="ClienentitytModalselect"
                                        aria-label="auditstatuskp" [disabled]="AlreadySelected">
                                        <motif-option *ngFor="let item of lstAuditTypeKP"
                                            [value]="item.auditTypeStatusKpId">{{item.auditTypeStatusName}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                              form.get('auditstatuskp').hasError('required') &&
                              form.get('auditstatuskp').touched
                              ">Please select Audit Status KP!</motif-error>
                                </motif-form-field>

                                <motif-form-field *ngIf="form.value.audittype=='RP'">
                                    <label motifLabel>Audit Status RP</label>
                                    <motif-select formControlName="auditstatusrp" class="ClienentitytModalselect"
                                        aria-label="auditstatusrp" [disabled]="AlreadySelected">
                                        <motif-option *ngFor="let item of lstAuditTypeRP"
                                            [value]="item.auditTypeStatusRpId">{{item.auditTypeStatusName}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                              form.get('auditstatusrp').hasError('required') &&
                              form.get('auditstatusrp').touched
                              ">Please select Audit Status RP!</motif-error>
                                </motif-form-field>

                                <motif-form-field *ngIf="form.value.audittype=='OT'">
                                    <label motifLabel>Audit Status OT</label>
                                    <motif-select formControlName="auditstatusot" class="ClienentitytModalselect"
                                        aria-label="auditstatusot" [disabled]="AlreadySelected">
                                        <motif-option *ngFor="let item of lstAuditTypeOT"
                                            [value]="item.auditTypeStatusOtId">{{item.auditTypeStatusName}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                              form.get('auditstatusot').hasError('required') &&
                              form.get('auditstatusot').touched
                              ">Please select Audit Status OT!</motif-error>
                                </motif-form-field>
                            </div>
                            
                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <motif-form-field>
                                    <label motifLabel>Send To</label>
                                    <motif-select [multiple]="true" formControlName="sendto"
                                        class="ClienentitytModalselect" aria-label="Send To">
                                        <motif-option *ngFor="let item of sendtolist" [value]="item"
                                            >
                                            {{item}}</motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                            form.get('sendto').hasError('required') &&
                                            form.get('sendto').touched
                                            ">Please select a Send To!</motif-error>
                                </motif-form-field>

                            </div>

                        </div>
                        <div class="motif-row">
                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <motif-form-field>
                                    <label motifLabel>Send CC</label>
                                    <motif-select [multiple]="true" formControlName="sendcc"
                                        class="ClienentitytModalselect" aria-label="Send CC">
                                        <motif-option *ngFor="let item of sendcclist" [value]="item" >
                                            {{item}}</motif-option>
                                    </motif-select>
                                    <!-- <motif-error *ngIf="
                                            form.get('sendcc').hasError('required') &&
                                            form.get('sendcc').touched
                                            ">Please select a Send CC!</motif-error> -->
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <motif-form-field>
                                    <label motifLabel>Language</label>
                                    <motif-select [multiple]="true" formControlName="language"   class="ClienentitytModalselect"
                                        aria-label="language" [disabled]="AlreadySelected">
                                        <motif-option *ngFor="let item of languagelist" [value]="item.reportingLanguageId" [checked]="item.isChecked">
                                            {{item.reportingLanguageName}}</motif-option>
                                            

                                    </motif-select>


                                    <motif-error *ngIf="
                                          form.get('language').hasError('required') &&
                                          form.get('language').touched
                                          ">Please select a Language!</motif-error>
                                </motif-form-field>
                            </div>
                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <motif-form-field>
                                    <label motifLabel>Email Subject</label>
                                    <input motifInput formControlName="emailsubject" type="text"
                                        aria-label="emailsubject" />

                                    <motif-error *ngIf="
                          form.get('emailsubject').hasError('required') &&
                          form.get('emailsubject').touched
                          ">Please enter Email Subject!</motif-error>
                                </motif-form-field>
                            </div>
                            <div class="motif-col-xs-3 motif-col-lg-3">
                                <div class="buttonadd">
                                    <div class="motif-row">
                                        <div class="motif-col-xs-9 motif-col-lg-9" *ngIf="textShow"
                                            style="padding-right:0px">
                                            <motif-form-field>
                                                <label motifLabel>Additional Emails</label>
                                                <textarea motifInput formControlName="additionalemails"
                                                    class="motif-textarea" aria-label="additionalemails"
                                                    style="min-width: 240px; min-height: 45px; max-height: 45PX;"></textarea>

                                                <motif-error *ngIf="
                            form.get('additionalemails').hasError('required') &&
                             form.get('additionalemails').touched
                            ">Please enter Additional Emails!</motif-error>
                                            </motif-form-field>
                                        </div>
                                        <div class="motif-col-xs-3 motif-col-lg-3">
                                            <button (click)="Addmail()" *ngIf="!textShow" class="csresetbutton"
                                                motifButton color="warn">+</button>
                                            <button (click)="Removemail()" *ngIf="textShow" class="csresetbutton"
                                                motifButton color="warn">-</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="motif-row">
                            <div class="motif-col-xs-3 motif-col-lg-3" style="padding-top: 15px; padding-left: 15px;">
                                <motif-form-field>
                                    <motif-checkbox formControlName="isenabled" value="true">Enable Email Trigger for
                                        this Status</motif-checkbox>
                                    <motif-error *ngIf="
                                            form.get('isenabled').hasError('required') &&
                                            form.get('isenabled').touched
                                            ">Please check Email Trigger!</motif-error>
                                </motif-form-field>
                            </div>
                           
                        </div>
                        <div class="motif-row" id="quilltablerow">

                            <div class="motif-col-xs-10 motif-col-lg-10" style="padding:0px;">
                                <div class="quillbox">

                                    <motif-form-field>
                                        <quill-editor #editor formControlName="emailbody"></quill-editor>
                                        <label motifLabel class="emailbodyLabel">Email Body</label>


                                        <motif-error *ngIf="
                              form.get('emailbody').hasError('required') &&
                              form.get('emailbody').touched">Please enter Email Body</motif-error>
                                    </motif-form-field>
                                </div>
                            </div>
                            <div class="motif-col-xs-2 motif-col-lg-2">
                                <table class="table">
                                    <tr>
                                        <th>Fields</th>
                                    </tr>
                                    <div class="Tabledata">
                                        <tr *ngFor="let myrows of dataTable; let i = index">
                                            <td id="{{'value'+i}}"><button (click)="AddColData(i)">{{ myrows.columnvalue
                                                    }}</button></td>
                                        </tr>
                                    </div>
                                </table>
                            </div>
                        </div>

                        <div class="motif-col-xs-12" id="buttons">
                            <div class="csdivcenterformmastercreation">
                                <div class="motif-row" style="justify-content: flex-end;">
                                    <!-- <div class="button-container motif-col-xs-2" style="padding: 0;" > -->
                                    <button  (click)="onCopyHTMLClick()" motifButton color="primary"  style="height: 45px !important; width: 103px !important; border: none !important; margin: 0px !important; border-bottom: 3px solid black !important; margin-left: 14px !important;padding: 0px !important;">Copy HTML</button>
                                    <button style="height: 9px; width: 73px;" *ngIf="btnflag" motifButton
                                        color="primary"
                                        style="height: 45px !important; width: 103px !important; border: none !important; margin: 0px !important; border-bottom: 3px solid black !important; margin-left: 14px !important;padding: 0px !important;"
                                        (click)="Add()" type="submit">Save</button>
                                    <button motifButton color="primary" *ngIf="!btnflag && !auditassign"
                                        style="height: 45px !important; width: 103px !important; border: none !important; margin: 0px !important; border-bottom: 3px solid black !important; margin-left: 14px !important;padding: 0px !important;"
                                        (click)="update()" type="submit">Update</button>
                                    <!-- </div> -->
                                    <!-- <div class="motif-col-xs-2"> -->
                                    <!-- <button (click)="reset()" class="csresetbutton" style="width: 74px;"  motifButton color="warn">Reset</button> -->
                                    <button (click)="oncancel('cancel')" class="csresetbutton"
                                        style="width: 81px; MARGIN-LEFT: 32PX;" motifButton color="warn">Cancel</button>

                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </fieldset>
        </form>

    </div>
    <motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
        <motif-modal-header>
            Confirmation
    
        </motif-modal-header>
        <motif-modal-body>
          <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="oncancel('cancel')" type="submit">Yes</button>
           <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
        </motif-modal-footer>
      </motif-modal>

    <motif-toast [type]="toasttype" [position]="'bottom'"  *ngIf="toastflag" [show]="true" (close)="toastflag=false">
        {{toastmessage}}
    </motif-toast>
    <motif-loader [show]="loaderflag" [fullScreen]="true"></motif-loader>
</div>