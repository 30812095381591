
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { PreApprovalserviceService } from './pre-approvalservice.service';
import { HeaderService } from 'src/app/core/header/header.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { PreapprovalTableActionsComponent } from './TableActions/preapproval-edit-delete-actions.component';
import { AuditPreApprovalComponent } from './audit-pre-approval/audit-pre-approval.component';
import {Location} from '@angular/common';

@Component({
  selector: 'app-pre-approval',
  templateUrl: './pre-approval.component.html',
  styleUrls: ['./pre-approval.component.scss']
})
export class PreApprovalComponent implements OnInit {

  gridApi: any;

  constructor(private _formBuilder: FormBuilder,private PreApprovalService:PreApprovalserviceService ,private router: Router,private _headerService: HeaderService,private route: ActivatedRoute,private location: Location )
  { }
  public totalHours: any;
  public totalfee: any;
  public uploadedfile: any;
  PreApprovalUploader: FileUploader = new FileUploader({ url: '' });
  public uploadfileerror: boolean = false;
  public Validationsmodalflag: boolean = false;
  public invalidarryKeys: any;
  public validationdata: any;
  public PreApprovalAddEditModal: boolean = false;
  public showPopup:boolean=false;
  form: FormGroup;
  ExcelToexportForm: FormGroup;
  public divFlag: boolean = false;
  public currentactivetab: string = 'PreApproval';
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  modules = AllCommunityModules;
  public rowSelection: any = 'multiple';
  public showFileUploadModel : boolean = false;
  public ColumnDefs: any = [];
  public EntityType:any = [];
  public ApprovalTypeStatuses:any=[];
  public PreApprovalLevel:any=[];
  public ServiceStatuses:any=[];
  public GroupScope:any=[];
  public NatureofServices:any=[];
  public ClientEntityID: string = "";
  public ClientContactPhone:any;
  public EYPartnerID:any;
  public lstEntitydetails:any=[];
  public arrayCLientEntity:any=[];
  public lstRPTeammembers: any = [];
  public actionAccess: any;
  public showLoader: boolean = false;
  public ISUpdate: boolean = false;
  public tableDataset: any;
  public EditID: any;
  public DeletID: any;
  public deletemodalflag: boolean = false;
  public btnsubmittext: string = "Save";
  public ngClientEntityID: any;
  public RoleBasedRegion: any;
  public RegionBasedENabledFlag: boolean = true;
  public rowData: any;
  isSelected:boolean=false;
  public lstContacts: any = [];
  public selectCLientContacts: any;
  public frameworkComponents: any;
  public viewpreApprovalentity: boolean = true;
  public filterdata: any;
  public preApprovalId: number;
  public btnAddFlag: boolean = true;
  public ngSearchFilter: any;
  public auditpreapproval:any;
  public viewflag:boolean=false;
  public editflag:any;
  public Escmodalflag:boolean=false;



  //#region  toast
  public toasttype: string;
  public toastflag: boolean;
  public toastmessage: string;
  //#endregion toast

  public ExcelExportmodalflag:boolean=false;

  ngOnInit(): void {

    this.setDivHeight();
    this._headerService.getActions("Pre Approval").then((value) => {
      
      this.actionAccess = value;
      if (this.actionAccess.indexOf("View") != -1) {
        this.GetEntityDetails();
        this.divFlag = true;
        this.setFrameworkoptions();
        this.setTableHeight();
        this.buildHeaders();
        this.GetMasterData();
      }
      else
        this.router.navigate(['/unauthorizedaccess'])
        this.form = this.PreApprovalForm();
        this.ExcelToexportForm=this.ExceltoExport();
  });
  }

  public style = {
    marginTop: "",
    width: "",
    height: ""
  };

  
  setTableHeight() {
    this.style.marginTop = '20px';
    this.style.width = "100%";
    this.style.height = (document.documentElement.clientHeight - 150) + "px";
  }

  setDivHeight() {
    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }

  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };

  public ShowFileUploadModel() {
    this.showFileUploadModel = true;
  }

  setFrameworkoptions() {
    this.frameworkComponents = {
     editDeleteActions : PreapprovalTableActionsComponent
    }
  }

  handleGridReady(params) {
    this.gridApi = params;
    this.gridApi.api.showLoadingOverlay()
    this.gridApi.api.hideOverlay()
    this.bindGrid();

    const Id: string = this.route.snapshot.queryParamMap.get('id');
    if (Id != null) {
      var id = this._headerService.decryptUsingAES256(Id);
      this.PreApprovalService.get()
        .subscribe(
          res => {
            this.tableDataset = res;
            var filtereddata = this.tableDataset.filter(data => data.preApprovalID == Number(id));
            this.filterdata = {
              rowData: filtereddata[0]
            }
            this.viewPreApprovalClick(this.filterdata);
          })
    }
  };

  onViewClick(e) {
    var id=this._headerService.encryptUsingAES256(e.rowData.preApprovalID);
        this.location.go('/preapproval?id=' + id);
    this.viewPreApprovalClick(e);
  }

  viewPreApprovalClick(e) {
    this.viewflag=true;
    this.toastflag = false;
    this.preApprovalId = e.rowData.preApprovalID;
    this.ngClientEntityID = e.rowData.clientEntityID;
    this.PreApprovalAddEditModal = true;
    this.btnAddFlag = false;
    this.viewpreApprovalentity = true;
    this.rowData = e.rowData;
    this.editflag="view";
  
    setTimeout(() => {
      this.form.patchValue({

        ClientEntityID: this.rowData.clientEntityNamedata,
          Department: this.rowData.department,
          EntityTypeID: this.rowData.entityTypeID,
          DateServiceRequest:this.rowData.dateServiceRequest != null ? this.rowData.dateServiceRequest == null || this.rowData.dateServiceRequest == 'null' ? '' :
          this.rowData.dateServiceRequest.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.dateServiceRequest.split('T')[0].split('-')[0]),
              month: Number(this.rowData.dateServiceRequest.split('T')[0].split('-')[1]),
              day: Number(this.rowData.dateServiceRequest.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.dateServiceRequest.split('T')[0]
          }
        } : null,
          EYPartnerID: this.rowData.eyPartnerID != null ? { contactId: Number(this.rowData.eyPartnerID), contactName: e.rowData.eyPartnerName } : null,
          EYManagerID: this.rowData.eyManagerID != null ? { contactId: Number(this.rowData.eyManagerID), contactName: e.rowData.eyManagerName } : null,
          ClientContactName: this.rowData.clientContactName,
          ClientContactEmail: this.rowData.clientContactEmail,
          ClientContactPhone: this.rowData.clientContactPhone,
          ServiceTitle : this.rowData.serviceTitle,
          NatureofServices:this.rowData.natureOfServices,
          Fundrelated: this.rowData.fundRelated,
          Recurringservice : this.rowData.recurringService,  
          Servicedescription :  this.rowData.serviceDescription,
          ServiceStartDate : this.rowData.serviceStartDate != null ? this.rowData.serviceStartDate == null || this.rowData.serviceStartDate == 'null' ? '' :
          this.rowData.serviceStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.serviceStartDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.serviceStartDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.serviceStartDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.serviceStartDate.split('T')[0]
          }
        } : null,

          ServiceEndDate:this.rowData.serviceEndDate != null ? this.rowData.serviceEndDate == null || this.rowData.serviceEndDate == 'null' ? '' :
          this.rowData.serviceEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.serviceEndDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.serviceEndDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.serviceEndDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.serviceEndDate.split('T')[0]
          }
        } : null,
          Reportingrequirements: this.rowData.reportingRequirements,
          Additionalcomments:  this.rowData.additionalComments,
          TotalHours:  this.rowData.totalHours,
          TotalFee: this.rowData.totalFee,
          Feeconfirmed : this.rowData.feeConfirmed,
          ServiceIndependencechecked: this.rowData.serviceIndependenceChecked,
          Feecomments:  this.rowData.feeComments,
          ServiceStatusID: this.rowData.serviceStatusID,
          ApprovalStatusID: this.rowData.approvalStatusID,
          ApprovalNumber: this.rowData.approvalNumber,
          ApprovalrequestDate : this.rowData.approvalRequestDate != null ? this.rowData.approvalRequestDate == null || this.rowData.approvalRequestDate == 'null' ? '' :
          this.rowData.approvalRequestDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.approvalRequestDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.approvalRequestDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.approvalRequestDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.approvalRequestDate.split('T')[0]
          }
        } : null,

          ApprovalResponseDate : this.rowData.approvalResponseDate != null ? this.rowData.approvalResponseDate == null || this.rowData.approvalResponseDate == 'null' ? '' :
          this.rowData.approvalResponseDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.approvalResponseDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.approvalResponseDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.approvalResponseDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.approvalResponseDate.split('T')[0]
          }
        } : null,

          ApprovalLevelID: this.rowData.approvalLevelID,
          BillingCode: this.rowData.billingCode,
          Billingcomments: this.rowData.billingComments,

      })
      if(this.ngClientEntityID!=null){
        this.FillEntityBaseDetails();
      }
    }, 10);
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onphoneInputChange(value) {
    if (value != null) {
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      value = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '');
      this.ClientContactPhone = value;
    }
  }
  onTotalHoursInputChange(value){
    if (value != "" && value != undefined && value != null) {
      value = value.replace(/[a-zA-Z](.*)/g, '');
      value = value.replace(/[^\w\s]/gi, '');
      this.totalHours = value.trim() == "" ? null : parseInt(value).toLocaleString("de-CH", { currency: 'EUR'});
      if(this.totalHours == "" || this.totalHours == null){
        this.form.controls['TotalHours'].setErrors(Validators.required);
      }
      else{
        this.form.controls['TotalHours'].setErrors(null);
      }
    }
  }

  onTotalFeeInputChange(value){
    if (value != "" && value != undefined && value != null) {
      value = value.replace(/[a-zA-Z](.*)/g, '');
      value = value.replace(/[^\w\s]/gi, '');
      this.totalfee = value.trim() == "" ? null : parseInt(value).toLocaleString("de-CH", { currency: 'EUR'});
      if(this.totalfee == "" || this.totalfee == null){
        this.form.controls['TotalFee'].setErrors(Validators.required);
      }
      else{
        this.form.controls['TotalFee'].setErrors(null);
      }
    }
  }
  private PreApprovalForm(){

    return this._formBuilder.group({
      formtitle: [],
      ClientEntityID: [null, [Validators.required]],
      Department: [null],
      EntityTypeID: [null, [Validators.required]],
      DateServiceRequest: [null, [Validators.required]],
      EYPartnerID:[null, [Validators.required]],
      EYManagerID:[null, [Validators.required]],
      ClientContactName: [null, [Validators.required]],
      ClientContactEmail: [null, [Validators.required, Validators.email]],
      ClientContactPhone: [null, [Validators.required,Validators.pattern("[0-9 ]{13}")]],
      ServiceTitle : [null, [Validators.required]],
      NatureofServices:[null, [Validators.required]],
      Fundrelated: [null, [Validators.required]],
      Recurringservice : [null, [Validators.required]],
      Servicedescription :  [null, [Validators.required]],
      ServiceStartDate:  [null, [Validators.required]],
      ServiceEndDate :  [null, [Validators.required]],
      Reportingrequirements:  [null],
      Additionalcomments:  [null],
      TotalHours:  [null, [Validators.required, Validators.pattern("^[0-9\’\/]+$")]],
      TotalFee: [null, [Validators.required, Validators.pattern("^[0-9\’\/]+$")]],
      Feeconfirmed : [null, [Validators.required]],
      Feecomments:  [null],
      ServiceIndependencechecked: [null, [Validators.required]], 
      ServiceStatusID: [null, [Validators.required]],
      ApprovalStatusID: [null, [Validators.required]],
      //Approval number "atleast 4 characters" & "start with #" validation removal -- requested by Anke (Done by Devika)
      //ApprovalNumber: ["#", [Validators.required,Validators.pattern("(?=^.{5,}$)#[a-zA-Z0-9_.-]*$")]],
      ApprovalNumber: ["#", [Validators.required]],
      ApprovalrequestDate: [null], 
      ApprovalResponseDate : [null],
      ApprovalLevelID: [null, [Validators.required]],
      BillingCode: [null, [Validators.required]],
      Billingcomments: [null],
      EntityName: [null],
      Street: [null],
      PostalCode: [null], 
    })
  }

  submit() {
    ;
    this.validateForm(this.form);
    if (this.form.valid) {
      this.showLoader = true;
      if (this.ISUpdate) {
        this.update();
      } else {
        this.Add();
      }
    }
    else{
      this.validateForm(this.form);
    }
  }


  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  Add() {
    
    this.validateForm(this.form);
    if (this.form.valid) {
      this.showLoader = true;
    let ClientEntityID = this.form.get("ClientEntityID").value;
    let Department = this.form.get("Department").value;
    let EntityTypeID = this.form.get("EntityTypeID").value;
    let DateServiceRequest = this.form.value.DateServiceRequest == null || this.form.value.DateServiceRequest == "" || this.form.value.DateServiceRequest == undefined ? null : this.form.value.DateServiceRequest.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.DateServiceRequest.singleDate.formatted) : new Date(this.form.value.DateServiceRequest.singleDate.formatted.split('.')[1] + '-' + this.form.value.DateServiceRequest.singleDate.formatted.split('.')[0] + '-' + this.form.value.DateServiceRequest.singleDate.formatted.split('.')[2]);
    let EYPartnerID = this.form.get("EYPartnerID").value;
    let EYManagerID= this.form.get("EYManagerID").value;
    let ClientContactName = this.form.get("ClientContactName").value;
    let ClientContactEmail = this.form.get("ClientContactEmail").value;
    let ClientContactPhone = this.form.get("ClientContactPhone").value;
    let ServiceTitle = this.form.get("ServiceTitle").value;
    let NatureofServices = this.form.get("NatureofServices").value;
    let Fundrelated = this.form.get("Fundrelated").value;
    let Recurringservice= this.form.get("Recurringservice").value;
    let Servicedescription= this.form.get("Servicedescription").value;
    let ServiceStartDate= this.form.value.ServiceStartDate == null || this.form.value.ServiceStartDate == "" || this.form.value.ServiceStartDate == undefined ? null : this.form.value.ServiceStartDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ServiceStartDate.singleDate.formatted) : new Date(this.form.value.ServiceStartDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ServiceStartDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ServiceStartDate.singleDate.formatted.split('.')[2]);
    let ServiceEndDate= this.form.value.ServiceEndDate == null || this.form.value.ServiceEndDate == "" || this.form.value.ServiceEndDate == undefined ? null : this.form.value.ServiceEndDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ServiceEndDate.singleDate.formatted) : new Date(this.form.value.ServiceEndDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ServiceEndDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ServiceEndDate.singleDate.formatted.split('.')[2]);
    let Reportingrequirements = this.form.get("Reportingrequirements").value;
    let Additionalcomments = this.form.get("Additionalcomments").value;
    let TotalHours = this.totalHours;
    let TotalFee= this.totalfee;
    let Feeconfirmed= this.form.get("Feeconfirmed").value;
    let Feecomments= this.form.get("Feecomments").value;
    let ServiceIndependencechecked= this.form.get("ServiceIndependencechecked").value;
    let ServiceStatusID= this.form.get("ServiceStatusID").value;
    let ApprovalStatusID = this.form.get("ApprovalStatusID").value;
    let ApprovalNumber = this.form.get("ApprovalNumber").value;
    let ApprovalrequestDate = this.form.value.ApprovalrequestDate == null || this.form.value.ApprovalrequestDate == "" || this.form.value.ApprovalrequestDate == undefined ? null : this.form.value.ApprovalrequestDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ApprovalrequestDate.singleDate.formatted) : new Date(this.form.value.ApprovalrequestDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ApprovalrequestDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ApprovalrequestDate.singleDate.formatted.split('.')[2]);
    let ApprovalResponseDate= this.form.value.ApprovalResponseDate == null || this.form.value.ApprovalResponseDate == "" || this.form.value.ApprovalResponseDate == undefined ? null : this.form.value.ApprovalResponseDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ApprovalResponseDate.singleDate.formatted) : new Date(this.form.value.ApprovalResponseDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ApprovalResponseDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ApprovalResponseDate.singleDate.formatted.split('.')[2]);
    let ApprovalLevelID= this.form.get("ApprovalLevelID").value;
    let BillingCode = this.form.get("BillingCode").value;
    let Billingcomments = this.form.get("Billingcomments").value;
    
    let addobj = {
      FormTitle: this.form.value.formtitle.replace('| d', '- d'),
      ClientEntityID:this.ngClientEntityID,
      Department: Department,
      EntityTypeID: EntityTypeID,
      DateServiceRequest: DateServiceRequest,
      EyPartnerID: EYPartnerID.contactId,
      EyManagerID: EYManagerID.contactId,
      ClientContactName: ClientContactName,
      ClientContactEmail: ClientContactEmail,
      ClientContactPhone: ClientContactPhone,
      ServiceTitle: ServiceTitle,
      NatureOfServices: NatureofServices,
      FundRelated: Fundrelated,
      RecurringService: Recurringservice,
      ServiceDescription:btoa(unescape(encodeURIComponent(Servicedescription))),
      ServiceStartDate: ServiceStartDate,
      ServiceEndDate: ServiceEndDate,
      ReportingRequirements: Reportingrequirements==null? null:btoa(unescape(encodeURIComponent(Reportingrequirements))),
      AdditionalComments: Additionalcomments==null?null:btoa(unescape(encodeURIComponent(Additionalcomments))),
      TotalHours: TotalHours,
      TotalFee: TotalFee,
      FeeConfirmed: Feeconfirmed,
      FeeComments:Feecomments==null?null:btoa(unescape(encodeURIComponent(Feecomments))),
      ServiceIndependenceChecked: ServiceIndependencechecked,
      ServiceStatusID: ServiceStatusID,
      ApprovalStatusID: ApprovalStatusID,
      ApprovalNumber: ApprovalNumber,
      ApprovalRequestDate: ApprovalrequestDate,
      ApprovalResponseDate: ApprovalResponseDate,
      ApprovalLevelID: ApprovalLevelID,
      BillingCode: BillingCode,
      BillingComments:Billingcomments==null?null: btoa(unescape(encodeURIComponent(Billingcomments))),     
    }
    ;
    this.PreApprovalService.Add(addobj)
      .subscribe(
        (res: any) => {
          if (res.message == "success") {
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "Pre-Approval has been added successfully.";
            this.reset();
            console.log(res);
            this.PreApprovalAddEditModal = false;
            this.bindGrid();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res.message == "PreApprovalExists") {
            this.showLoader = false;
            this.toasttype = "warning";
            this.toastflag = true;
            this.toastmessage = "Pre-Approval already exist.";
            this.PreApprovalAddEditModal = true;
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        },
        err => {
          if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){ 
            this.toasttype = "warning";
            this.toastflag = true;
            this.toastmessage = err.toString();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else{
          this.reset();
          }
        }
      )
    }
    
  }
    
  reset() {
    ;
    this.form.reset();
    this.btnsubmittext = "Save";
    this.bindGrid();
  }

  onDeleteClick(e) {
    ;
    this.toastflag=false;
    this.DeletID=e.rowData.preApprovalID;
    this.deletemodalflag=true;
  }

  Delete() {
    ;
    this.toastflag=false;
    this.PreApprovalService.delete(this.DeletID)
      .subscribe(
        (res: any) => {
          if (res.message == "success") {
            this.deletemodalflag = false;
            this.toasttype = "success";
            this.toastflag = true;
            this.toastmessage = "PreApproval has been deleted successfully.";
            this.bindGrid();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          } else if (res.message == "PreApprovalCannotBeDeleted") {
            this.showLoader = false;
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "PreApproval cannot be deleted as it was mapped to Client Entity.";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          this.reset();
        },
        err => {
          this.reset();
        }
      )
  }

  AddPreApproval() {
    this.PreApprovalAddEditModal = true;
    this.viewflag=false;
    this.form.reset();
    this.form.patchValue({ApprovalNumber:"#"});
    this.editflag="add";
  }

  private bindGrid() {
    
    this.PreApprovalService.get()
      .subscribe(
        res => {
          ;
          this.tableDataset = res;
          console.log(res);
        },
        err => {
          console.log(err);
        }
      )
      this.ISUpdate=false;
  }

  private buildHeaders() {

    this.ColumnDefs = [
         { headerName: 'Form Title', field: 'formTitle', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 200, headerCheckboxSelection: true,
         headerCheckboxSelectionFilteredOnly: true,
         checkboxSelection: true,pinned: 'left'},
         {headerName: 'Service Status', field: 'serviceStatusName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124, pinned: 'left'},
         {headerName: 'Entity Type', field: 'entityTypeName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124,pinned: 'left' },
         {headerName: 'Department', field: 'department', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124,pinned: 'left' },
         {headerName: 'Client Contact - Name', field: 'clientContactName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Client Contact - Email', field: 'clientContactEmail', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Client Contact - Phone', field: 'clientContactPhone', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'EY Partner', field: 'eyPartnerName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'EY Manager', field: 'eyManagerName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Service Description', field: 'serviceDescription', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Nature Of Services', field: 'natureOfServices', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Fund Related', field: 'fundRelated', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Recurring Service', field: 'recurringService', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Total Hours', field: 'totalHours', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124, 
         cellClass: 'AGgridrightalign'},
         {headerName: 'Total Fee', field: 'totalFee', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 ,
         cellClass: 'AGgridrightalign'},
         {headerName: 'Client Entity Name', field: 'clientEntityNamedata', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         
         
         {headerName: 'Date Service Request', field: 'dateServiceRequest', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 ,
         cellRenderer: (data) => {
          if (data.value != undefined)
            return moment(data.value).format('DD.MM.YYYY')
        }
      },
         {headerName: 'Service Title', field: 'serviceTitle', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Service - Start Date', field: 'serviceStartDate', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 ,
         cellRenderer: (data) => {
          if (data.value != undefined)
            return moment(data.value).format('DD.MM.YYYY')
            }
         },
         {headerName: 'Service - End Date', field: 'serviceEndDate', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124,
         cellRenderer: (data) => {
          if (data.value != undefined)
            return moment(data.value).format('DD.MM.YYYY')
           }
         },
         {headerName: 'Reporting Requirements', field: 'reportingRequirements', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Additional Comments', field: 'additionalComments', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Fee Confirmed', field: 'feeConfirmed', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Fee comments', field: 'feeComments', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Service Independence Checked', field: 'serviceIndependenceChecked', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Approval Status', field: 'approvalStatusName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Approval Number', field: 'approvalNumber', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Approval Request to Management', field: 'approvalRequestDate', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 ,
         cellRenderer: (data) => {
          if (data.value != undefined)
            return moment(data.value).format('DD.MM.YYYY')
            }
         },
         {headerName: 'Approval Response', field: 'approvalResponseDate', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124,
         cellRenderer: (data) => {
          if (data.value != undefined)
            return moment(data.value).format('DD.MM.YYYY')
           }
         },
         {headerName: 'Approval Level', field: 'approvalLevelName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Billing Code', field: 'billingCode', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
         {headerName: 'Billing Comments', field: 'billingComments', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 124 },
      ]
    if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Add / Update")!=-1){
      ;
      this.ColumnDefs.unshift({
      headerName: '',
      cellRenderer: "editDeleteActions",
      cellRendererParams: {
        onEditClick: this.onEditClick.bind(this),
        onDeleteClick: this.onDeleteClick.bind(this),
        onViewClick: this.onViewClick.bind(this),
        Actions:this.actionAccess,
      },
      width: 80,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,
      });
    }
  }

  onEditClick(e) {
        this.ISUpdate = true;
        this.viewflag=false;
        this.btnAddFlag=true;
        this.ngClientEntityID = e.rowData.clientEntityID;
        console.log(e.rowData);
        var id=this._headerService.encryptUsingAES256(e.rowData.preApprovalID);
        this.location.go('/preapproval?id=' + id);
        this.PreApprovalAddEditModal = true;
        this.EditID = e.rowData.preApprovalID;
        this.btnsubmittext = "Update";
        this.rowData = e.rowData;
        this.editflag="edit";
        
        
        this.form.patchValue({
          formtitle: this.rowData.formTitle,
          ClientEntityID: this.rowData.clientEntityNamedata,
          Department: this.rowData.department,
          EntityTypeID: this.rowData.entityTypeID,
          DateServiceRequest:this.rowData.dateServiceRequest != null ? this.rowData.dateServiceRequest == null || this.rowData.dateServiceRequest == 'null' ? '' :
          this.rowData.dateServiceRequest.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.dateServiceRequest.split('T')[0].split('-')[0]),
              month: Number(this.rowData.dateServiceRequest.split('T')[0].split('-')[1]),
              day: Number(this.rowData.dateServiceRequest.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.dateServiceRequest.split('T')[0]
          }
        } : null,
          EYPartnerID: this.rowData.eyPartnerID != null ? { contactId: Number(this.rowData.eyPartnerID), contactName: e.rowData.eyPartnerName } : null,
          EYManagerID: this.rowData.eyManagerID != null ? { contactId: Number(this.rowData.eyManagerID), contactName: e.rowData.eyManagerName } : null,
          ClientContactName: this.rowData.clientContactName,
          ClientContactEmail: this.rowData.clientContactEmail,
          ClientContactPhone: this.rowData.clientContactPhone,
          ServiceTitle : this.rowData.serviceTitle,
          NatureofServices:this.rowData.natureOfServices,
          Fundrelated: this.rowData.fundRelated,
          Recurringservice : this.rowData.recurringService,  
          Servicedescription :  this.rowData.serviceDescription,
          ServiceStartDate : this.rowData.serviceStartDate != null ? this.rowData.serviceStartDate == null || this.rowData.serviceStartDate == 'null' ? '' :
          this.rowData.serviceStartDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.serviceStartDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.serviceStartDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.serviceStartDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.serviceStartDate.split('T')[0]
          }
        } : null,
          ServiceEndDate:this.rowData.serviceEndDate != null ? this.rowData.serviceEndDate == null || this.rowData.serviceEndDate == 'null' ? '' :
          this.rowData.serviceEndDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.serviceEndDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.serviceEndDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.serviceEndDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.serviceEndDate.split('T')[0]
          }
        } : null,
          Reportingrequirements: this.rowData.reportingRequirements,
          Additionalcomments: this.rowData.additionalComments,
          TotalHours:  this.rowData.totalHours,
          TotalFee: this.rowData.totalFee,
          Feeconfirmed : this.rowData.feeConfirmed,
          ServiceIndependencechecked: this.rowData.serviceIndependenceChecked,
          Feecomments: this.rowData.feeComments,
          ServiceStatusID: this.rowData.serviceStatusID,
          ApprovalStatusID: this.rowData.approvalStatusID,
          ApprovalNumber: this.rowData.approvalNumber,
          ApprovalrequestDate : this.rowData.approvalRequestDate != null ? this.rowData.approvalRequestDate == null || this.rowData.approvalRequestDate == 'null' ? '' :
          this.rowData.approvalRequestDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.approvalRequestDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.approvalRequestDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.approvalRequestDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.approvalRequestDate.split('T')[0]
          }
        } : null,
          ApprovalResponseDate : this.rowData.approvalResponseDate != null ? this.rowData.approvalResponseDate == null || this.rowData.approvalResponseDate == 'null' ? '' :
          this.rowData.approvalResponseDate.indexOf('-') == -1 ? '' : {
          isRange: false, singleDate: {
            date: {
              year: Number(this.rowData.approvalResponseDate.split('T')[0].split('-')[0]),
              month: Number(this.rowData.approvalResponseDate.split('T')[0].split('-')[1]),
              day: Number(this.rowData.approvalResponseDate.split('T')[0].split('-')[2])
            },
            formatted: this.rowData.approvalResponseDate.split('T')[0]
          }
        } : null,
          ApprovalLevelID: this.rowData.approvalLevelID,
          BillingCode: this.rowData.billingCode,
          Billingcomments: this.rowData.billingComments,
        });
        if(this.ngClientEntityID!=null){
          this.FillEntityBaseDetails();
        }
        this.isSelected = true;
  }

//#region Action check method
ActionsCheck(action){    
  if(this.actionAccess.indexOf(action)!=-1)
  return true;
  else
  return false;
}
GenerateFormTitle() {
 
  let approvalNumber = (this.form.value.ApprovalNumber != "" || this.form.value.ApprovalNumber != null) ? this.form.value.ApprovalNumber : "#";
  let serviceTitle=(this.form.value.ServiceTitle != "" || this.form.value.ServiceTitle != null) ? this.form.value.ServiceTitle : "";
  this.form.patchValue({
    formtitle: this.form.value.ApprovalNumber + ' | ' + serviceTitle,
    ApprovalNumber: approvalNumber
  });
}

  update() {
    ;
    let data = this.lstEntitydetails.find(ob => ob.clientEntityName === this.form.value.ClientEntityID);
    if (data != undefined) {
      this.ngClientEntityID = data.clientEntityId;
    }
    
    let upobj = {

      FormTitle: this.form.value.formtitle.replace('| d', '- d'),    
      ClientEntityID:this.ngClientEntityID,
      Department: this.form.value.Department,
      EntityTypeID: this.form.value.EntityTypeID,
      DateServiceRequest: this.form.value.DateServiceRequest == null || this.form.value.DateServiceRequest == "" || this.form.value.DateServiceRequest == undefined ? null : this.form.value.DateServiceRequest.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.DateServiceRequest.singleDate.formatted) : new Date(this.form.value.DateServiceRequest.singleDate.formatted.split('.')[1] + '-' + this.form.value.DateServiceRequest.singleDate.formatted.split('.')[0] + '-' + this.form.value.DateServiceRequest.singleDate.formatted.split('.')[2]),
      EyPartnerID: this.form.value.EYPartnerID.contactId,
      EyManagerID: this.form.value.EYManagerID.contactId,
      ClientContactName: this.form.value.ClientContactName,
      ClientContactEmail: this.form.value.ClientContactEmail,
      ClientContactPhone: this.form.value.ClientContactPhone,
      ServiceTitle: this.form.value.ServiceTitle,
      NatureOfServices: this.form.value.NatureofServices,
      FundRelated: this.form.value.Fundrelated,
      RecurringService: this.form.value.Recurringservice,
      ServiceDescription: btoa(unescape(encodeURIComponent(this.form.value.Servicedescription))),
      ServiceStartDate : this.form.value.ServiceStartDate == null || this.form.value.ServiceStartDate == "" || this.form.value.ServiceStartDate == undefined ? null : this.form.value.ServiceStartDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ServiceStartDate.singleDate.formatted) : new Date(this.form.value.ServiceStartDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ServiceStartDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ServiceStartDate.singleDate.formatted.split('.')[2]),
      ServiceEndDate : this.form.value.ServiceEndDate == null || this.form.value.ServiceEndDate == "" || this.form.value.ServiceEndDate == undefined ? null : this.form.value.ServiceEndDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ServiceEndDate.singleDate.formatted) : new Date(this.form.value.ServiceEndDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ServiceEndDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ServiceEndDate.singleDate.formatted.split('.')[2]),
      ReportingRequirements: this.form.value.Reportingrequirements==null? null : btoa(unescape(encodeURIComponent(this.form.value.Reportingrequirements))),
      AdditionalComments: this.form.value.Additionalcomments==null? null:btoa(unescape(encodeURIComponent(this.form.value.Additionalcomments))),
      TotalHours: this.totalHours,
      TotalFee: this.totalfee,
      FeeConfirmed: this.form.value.Feeconfirmed,
      FeeComments: this.form.value.Feecomments==null?null:btoa(unescape(encodeURIComponent(this.form.value.Feecomments))),
      ServiceIndependenceChecked: this.form.value.ServiceIndependencechecked,
      ServiceStatusID: this.form.value.ServiceStatusID,
      ApprovalStatusID: this.form.value.ApprovalStatusID,
      ApprovalNumber: this.form.value.ApprovalNumber,
      ApprovalRequestDate : this.form.value.ApprovalrequestDate == null || this.form.value.ApprovalrequestDate == "" || this.form.value.ApprovalrequestDate == undefined ? null : this.form.value.ApprovalrequestDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ApprovalrequestDate.singleDate.formatted) : new Date(this.form.value.ApprovalrequestDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ApprovalrequestDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ApprovalrequestDate.singleDate.formatted.split('.')[2]),
      ApprovalResponseDate : this.form.value.ApprovalResponseDate == null || this.form.value.ApprovalResponseDate == "" || this.form.value.ApprovalResponseDate == undefined ? null : this.form.value.ApprovalResponseDate.singleDate.formatted.indexOf('-') != -1 ? new Date(this.form.value.ApprovalResponseDate.singleDate.formatted) : new Date(this.form.value.ApprovalResponseDate.singleDate.formatted.split('.')[1] + '-' + this.form.value.ApprovalResponseDate.singleDate.formatted.split('.')[0] + '-' + this.form.value.ApprovalResponseDate.singleDate.formatted.split('.')[2]),
      ApprovalLevelID: this.form.value.ApprovalLevelID,
      BillingCode: this.form.value.BillingCode,
      BillingComments: this.form.value.Billingcomments==null?null:btoa(unescape(encodeURIComponent(this.form.value.Billingcomments))),
      
          preApprovalId: this.EditID
        }
    
        this.PreApprovalService.Update(upobj)
          .subscribe(
            (res: any) => {
              if (res["message"] == "PreApproval Exists") {
                this.showLoader = false;
                this.toasttype = "warning";
                this.toastflag = true;
                this.toastmessage = "Pre-Approval already exists.";
                setTimeout(() => {
                  this.toastflag=false;
                 }, 3000);
              }
              else if (res["message"] == "PreApproval updated") {
                  this.reset();
                  this.toasttype = "success";
                  this.toastflag = true;
                  this.toastmessage = "Pre-Approval has been updated successfully.";
                  this.PreApprovalAddEditModal = false;
                  this.bindGrid();
                  setTimeout(() => {
                    this.toastflag=false;
                   }, 3000);
              } 
              else{
                      this.reset();
                      this.toasttype = "error";
                      this.toastflag = true;
                      this.toastmessage = "An unexpected error has occured. Please contact support team.";
                      setTimeout(() => {
                        this.toastflag=false;
                       }, 3000);
              }
            },
            err => {
              if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
                this.toasttype = "warning";
                this.toastflag = true;
                this.toastmessage = err.toString();
                setTimeout(() => {
                  this.toastflag=false;
                 }, 3000);
              }
              else{
              this.reset();
              }
            }
          )
          this.ISUpdate=false;
          this.PreApprovalAddEditModal=false;
          
          //  this.auditpreapproval= new AuditPreApprovalComponent(this.PreApprovalService)
          // this.auditpreapproval.getAuditTrail();
          
  }
    
  public GetMasterData(){
      this.PreApprovalService.GetMasterData().subscribe(res=>{
      this.EntityType= res[2];
      this.ServiceStatuses = res[3];
      this.ApprovalTypeStatuses = res[1];
      this.PreApprovalLevel = res[0];
      this.GroupScope=res[4];
      this.NatureofServices = res[5];
      this.lstRPTeammembers = res[6];
      })
  }

  GetEntityDetails() {
      this.PreApprovalService.GetEntityDetails()
        .subscribe(
          res => {
            this.lstEntitydetails = res;
            this.lstEntitydetails.forEach(element => {
              this.arrayCLientEntity.push(element.clientEntityName);
            });
          },
          err => {
            console.log(err);
          }
        )
  }

  FillEntityBaseDetails() {
      ;
      let data = this.lstEntitydetails.find(ob => ob.clientEntityName === this.form.value.ClientEntityID);
      if (data !== undefined) {
        this.form.patchValue({
          EntityName: data.clientEntityName,
          Street: data.street,
          PostalCode: data.postalCode  
        });
  
      } else {
        this.ngClientEntityID = 0;
        this.form.patchValue({
          EntityName: "",
          Street: "",
          PostalCode: ""  
        });
  
      }
       if (data != undefined) {
        this.ngClientEntityID = data.clientEntityId;
        this.GenerateFormTitle();
       }
  }

  OnCancel(){
      this.reset();
      this.location.go('/preapproval');
      this.PreApprovalAddEditModal = false;
  }

  escapeYes(){
    this.reset();
      this.location.go('/preapproval');
      this.PreApprovalAddEditModal = false;
      this.Escmodalflag=false;
      this.editflag="";
  }
 
 ////////////// upload code/////////////////////////////
 ////#region template download
 PreApprovalTempleateExport() {
  this.toastflag = false;
  this.PreApprovalService.PreApprovalTempleateExport()
    .subscribe(
      res => {
        saveAs(res, "PreApprovalTemplate.xlsx");
      },
      err => {
        console.log(err);
      }
    )
 }

 fileChange($event) {
    if ($event.length > 0)
      this.uploadedfile = $event[0].file.rawFile;
 }

 clearPreApprovalFiles() {
    this.uploadedfile = null;
    this.uploadfileerror = false;
    this.PreApprovalUploader = new FileUploader({ url: '' });
 }

 UploadData() {
  this.toastflag = false;
  let formData: FormData = new FormData();
  formData.append("file", this.uploadedfile);
  this.PreApprovalService.Upload(formData)
    .subscribe(
      res => {
        if (res["status"] == "success") {
          this.hideFileUploadModel();
          this.toasttype = "success";
          this.toastflag = true;
          this.toastmessage = "Preapprovals has been uploaded successfully.";
          this.bindGrid();
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
        else if (res["status"] == "fail" || res["status"] == "invalidfile") {
          this.uploadfileerror = true;
        }
        else if (res["status"] == "invalid") {
          this.hideFileUploadModel();
          this.Validationsmodalflag = true;          
          this.invalidarryKeys = Object.keys(res).slice(0, -1);
          this.validationdata = res;
        }
      },
      err => {
        if (err == "Internal Server Error") {
          this.uploadfileerror = true;
        } else {
          this.hideFileUploadModel();
          this.toasttype = "error";
          this.toastflag = true;
          this.toastmessage = "Error occured please contact support team";
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      }
    )
 }

 public hideFileUploadModel() {
    this.showFileUploadModel = false;
    this.clearPreApprovalFiles();
 }
  ////#endregion

  //#region Excel export modal methods
 private ExceltoExport(): FormGroup {
   return this._formBuilder.group({
    chkFormTitle: [],
    chkEntityName: [],
    chkDepartment: [],
    chkEntityType: [],
    chkEYPartner: [],
    chkEYManager: [],
    chkDataServiceRequest: [],
    chkClientContactName: [],
    chkClientContactEmail: [],
    chkClientContactPhone: [],
    chkServiceTitle: [],
    chkNatureofServices: [],
    chkFundrelated: [],
    chkRecurringservice: [],
    chkServicedescription: [],
    chkServiceStartDate: [],
    chkServiceEndDate: [],
    chkReportingrequirements: [],
    chkAdditionalcommentsnotes: [],
    chkTotalHours: [],
    chkTotalFee: [],
    chkFeeconfirmedwithClient: [],
    chkFeecommentsnotes: [],
    chkServiceIndependencecheckedandconfirmed: [],
    chkServiceStatus: [],
    chkApprovalStatus: [],
    chkApprovalNumber: [],
    chkApprovalrequesttoManagement: [],
    chkApprovalresponsefromManagement: [],
    chkApprovalLevel: [],
    chkBillingCode: [],
    chkClientEntityName: [],
    chkStreet:[],
    chkPostalCodeLocation:[],
    chkBillingcommentsnotes:[],
    radioexceltype:[]
   });
  }

  ReportOptionChange() {
    this.toastflag = false;
    if (this.ExcelToexportForm.value.radioexceltype == "all") {
     this.ExcelToexportForm.setValue({
      chkFormTitle: true,
      chkEntityName: true,
      chkDepartment: true,
      chkEntityType: true,
      chkEYPartner: true,
      chkEYManager: true,
      chkDataServiceRequest: true,
      chkClientContactName: true,
      chkClientContactEmail: true,
      chkClientContactPhone: true,
      chkServiceTitle: true,
      chkNatureofServices: true,
      chkFundrelated: true,
      chkRecurringservice: true,
      chkServicedescription: true,
      chkServiceStartDate: true,
      chkServiceEndDate: true,
      chkReportingrequirements: true,
      chkAdditionalcommentsnotes: true,
      chkTotalHours: true,
      chkTotalFee: true,
      chkFeeconfirmedwithClient: true,
      chkFeecommentsnotes: true,
      chkServiceIndependencecheckedandconfirmed: true,
      chkServiceStatus: true,
      chkApprovalStatus: true,
      chkApprovalNumber: true,
      chkApprovalrequesttoManagement: true,
      chkApprovalresponsefromManagement: true,
      chkApprovalLevel: true,
      chkBillingCode: true,
      chkClientEntityName: true,
      chkStreet:true,
      chkPostalCodeLocation:true,
      chkBillingcommentsnotes:true,
       radioexceltype: "all"
      })
    }
    else if (this.ExcelToexportForm.value.radioexceltype == "MOFP") {
      this.ExcelToexportForm.setValue({
      chkFormTitle: true,
      chkEntityName: true,
      chkDepartment: true,
      chkEntityType: true,
      chkEYPartner: true,
      chkEYManager: true,
      chkDataServiceRequest: false,
      chkClientContactName: true,
      chkClientContactEmail: false,
      chkClientContactPhone: false,
      chkServiceTitle: true,
      chkNatureofServices: true,
      chkFundrelated: false,
      chkRecurringservice: true,
      chkServicedescription: false,
      chkServiceStartDate: true,
      chkServiceEndDate: true,
      chkReportingrequirements: false,
      chkAdditionalcommentsnotes: false,
      chkTotalHours: false,
      chkTotalFee: true,
      chkFeeconfirmedwithClient: false,
      chkFeecommentsnotes: false,
      chkServiceIndependencecheckedandconfirmed: false,
      chkServiceStatus: true,
      chkApprovalStatus: true,
      chkApprovalNumber: true,
      chkApprovalrequesttoManagement: true,
      chkApprovalresponsefromManagement: true,
      chkApprovalLevel: false,
      chkBillingCode: false,
      chkClientEntityName: false,
      chkStreet:false,
      chkPostalCodeLocation:false,
      chkBillingcommentsnotes:false,
      radioexceltype: "MOFP"
     })
    }
    else if (this.ExcelToexportForm.value.radioexceltype == "Custom") {
      this.ExcelToexportForm.setValue({
      chkFormTitle: false,
      chkEntityName: false,
      chkDepartment: false,
      chkEntityType: false,
      chkEYPartner: false,
      chkEYManager: false,
      chkDataServiceRequest: false,
      chkClientContactName: false,
      chkClientContactEmail: false,
      chkClientContactPhone: false,
      chkServiceTitle: false,
      chkNatureofServices: false,
      chkFundrelated: false,
      chkRecurringservice: false,
      chkServicedescription: false,
      chkServiceStartDate: false,
      chkServiceEndDate: false,
      chkReportingrequirements: false,
      chkAdditionalcommentsnotes: false,
      chkTotalHours: false,
      chkTotalFee: false,
      chkFeeconfirmedwithClient: false,
      chkFeecommentsnotes: false,
      chkServiceIndependencecheckedandconfirmed: false,
      chkServiceStatus: false,
      chkApprovalStatus: false,
      chkApprovalNumber: false,
      chkApprovalrequesttoManagement: false,
      chkApprovalresponsefromManagement: false,
      chkApprovalLevel: false,
      chkBillingCode: false,
      chkClientEntityName: false,
      chkStreet:false,
      chkPostalCodeLocation:false,
      chkBillingcommentsnotes:false,
      radioexceltype: "Custom"
     })
    }
  }

  PreApprovalExcelexport(form: FormGroup) {

    this.toastflag = false;
    let selectedNodes = this.gridApi.api.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data.preApprovalID);
    let columnNames = [];
    columnNames.push(form.value.chkFormTitle == true ? "Form Title" : "");
    columnNames.push(form.value.chkEntityName == true ? "Entity Name" : "");
    columnNames.push(form.value.chkDepartment == true ? "Department" : "");
    columnNames.push(form.value.chkEntityType == true ? "Entity Type" : "");
    columnNames.push(form.value.chkEYPartner == true ? "EY Partner" : "");  
    columnNames.push(form.value.chkEYManager == true ? "EY Manager" : "");
    columnNames.push(form.value.chkDataServiceRequest == true ? "Data Service Request" : "");
    columnNames.push(form.value.chkClientContactName == true ? "Client Contact Name" : "");
    columnNames.push(form.value.chkClientContactEmail == true ? "Client Contact Email" : "");
    columnNames.push(form.value.chkClientContactPhone == true ? "Client Contact Phone" : "");
    columnNames.push(form.value.chkServiceTitle == true ? "Service Title" : "");
    columnNames.push(form.value.chkNatureofServices == true ? "Nature of Services" : "");
    columnNames.push(form.value.chkFundrelated == true ? "Fund – Related?" : "");
    columnNames.push(form.value.chkRecurringservice == true ? "Recurring Service?" : "");
    columnNames.push(form.value.chkServicedescription == true ? "Service Description" : "");
    columnNames.push(form.value.chkServiceStartDate == true ? "Service - Start Date" : "");
    columnNames.push(form.value.chkServiceEndDate == true ? "Service - End Date" : "");
    columnNames.push(form.value.chkReportingrequirements == true ? "Reporting Requirements" : "");
    columnNames.push(form.value.chkAdditionalcommentsnotes == true ? "Additional comments/notes" : "");
    columnNames.push(form.value.chkTotalHours == true ? "Total Hours" : "");
    columnNames.push(form.value.chkTotalFee == true ? "Total Fee" : "");
    columnNames.push(form.value.chkFeeconfirmedwithClient == true ? "Fee confirmed with Client?" : "");
    columnNames.push(form.value.chkFeecommentsnotes == true ? "Fee comments/notes" : "");
    columnNames.push(form.value.chkServiceIndependencecheckedandconfirmed == true ? "Service Independence checked and confirmed?" : "");
    columnNames.push(form.value.chkServiceStatus == true ? "Service Status" : "");
    columnNames.push(form.value.chkApprovalStatus == true ? "Approval Status" : "");
    columnNames.push(form.value.chkApprovalNumber == true ? "Approval Number" : "");
    columnNames.push(form.value.chkApprovalrequesttoManagement == true ? "Approval request to Management" : "");
    columnNames.push(form.value.chkApprovalresponsefromManagement == true ? "Approval response from Management" : "");
    columnNames.push(form.value.chkApprovalLevel == true ? "Approval Level" : "");
    columnNames.push(form.value.chkBillingCode == true ? "Billing Code" : "");
    columnNames.push(form.value.chkClientEntityName == true ? "Client Entity Name" : "");
    columnNames.push(form.value.chkStreet == true ? "Street" : "");
    columnNames.push(form.value.chkPostalCodeLocation == true ? "Postal Code Location" : "");
    columnNames.push(form.value.chkBillingcommentsnotes == true ? "Billing comments/notes" : "");
  
    columnNames = columnNames.filter(function (el) {
    return el != "";
    });
    if (columnNames.length == 0) {
     this.toasttype = "warning";
     this.toastflag = true;
     this.toastmessage = "Please select atleast one column";
     setTimeout(() => {
      this.toastflag=false;
     }, 3000);
     return
    }
    var bodydata = {
      columnNames: columnNames,
      selectedData: selectedData
    }
    this.PreApprovalService.PreApprovalExcelexport(bodydata)
    .subscribe(
      res => {
        this.ExcelExportmodalflag = false;
        let date = new Date().toString();
        saveAs(res, "PreApprovalData" + date + ".xlsx");
      },
      err => {
        console.log(err);
      }
    )
  }

  ExcelExportpopup() {
    this.toastflag = false;
    this.ExcelExportmodalflag = true;
    this.ExcelToexportForm.setValue({
      chkFormTitle: true,
      chkEntityName: true,
      chkDepartment: true,
      chkEntityType: true,
      chkEYPartner: true,
      chkEYManager: true,
      chkDataServiceRequest: true,
      chkClientContactName: true,
      chkClientContactEmail: true,
      chkClientContactPhone: true,
      chkServiceTitle: true,
      chkNatureofServices: true,
      chkFundrelated: true,
      chkRecurringservice: true,
      chkServicedescription: true,
      chkServiceStartDate: true,
      chkServiceEndDate: true,
      chkReportingrequirements: true,
      chkAdditionalcommentsnotes: true,
      chkTotalHours: true,
      chkTotalFee: true,
      chkFeeconfirmedwithClient: true,
      chkFeecommentsnotes: true,
      chkServiceIndependencecheckedandconfirmed: true,
      chkServiceStatus: true,
      chkApprovalStatus: true,
      chkApprovalNumber: true,
      chkApprovalrequesttoManagement: true,
      chkApprovalresponsefromManagement: true,
      chkApprovalLevel: true,
      chkBillingCode: true,
      chkClientEntityName: true,
      chkStreet:true,
      chkPostalCodeLocation:true,
      chkBillingcommentsnotes:true,
      radioexceltype: "all"
    })
  }
   ////#endregion
   
  onFilterTextBoxChanged() {
  this.gridApi.api.setQuickFilter(this.ngSearchFilter);
  }

  // getAuditTrail() {
  //   ;
  //   this.setTableHeight();
  // //  this.currentactivetab = "Audit";
  //   this.loaderflag = true;
  
  //   this._preapprovalService.getAuditTrail()
  //     .subscribe(
  //       res => {
  
  //         this.AuditTrailBuildHeaders();
  //         this.AuditTrailDataset = res;
  //         this.DirectoryAuditDataSet = res;
  //         this.AuditTrailfilterDataset = res;
  //         this.loaderflag = false;
  //       },
  //       err => {
  
  //         console.log(err);
  //       }
  //     )
  
  // }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    debugger;
    if(this.editflag == "edit"){
      this.Escmodalflag=true;
    }
    else{
      this.PreApprovalAddEditModal = false;
    }
  }  

}
