import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-edit-action',
  templateUrl: './edit-action.component.html',
  styleUrls: ['./edit-action.component.scss']
})
export class EditActionComponent implements ICellRendererAngularComp {

  public params: any;
  public Actions: any;

  agInit(params: any): void {

    
        
    this.params = params;
    this.Actions=this.params.Actions;
}
ActionsCheck(action){    
  
  // if(this.Actions.indexOf(action)!=-1)
  return true;
  //else
  //return false;
}
refresh(): boolean {
  return false;
}
onEditClick($event) {
    // put anything into params u want pass into parents component
       const params = {
         event: $event,
         rowData: this.params.node.data
         // ...something
       }
       this.params.onEditClick(params);
   }
// onEditClick(bankCode) {
//       // put anything into params u want pass into parents component
         
//       this.params.onEditClick(bankCode);
//      }
    

}
