import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-diectoryauditlist',
  templateUrl: './diectoryauditlist.component.html',
  styleUrls: ['./diectoryauditlist.component.scss']
})
export class DiectoryauditlistComponent implements ICellRendererAngularComp {

  public params: any;


  agInit(params: any): void {

    if (params.data.auditType != "Bulk Upload" && params.data.auditType != "Insert") {
      if (params.colDef.headerName == "New Values") {
        this.params = params.data.newValues;
      }
      else if (params.colDef.headerName == "Old Values") {

        this.params = params.data.oldValues;
      }
    } else { this.params=null }
  }

  refresh(): boolean {
    return false;
  }

}
