import { Component, OnInit } from '@angular/core';
import { SignalRService } from 'src/app/core/Services/signal-r.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public signalRService: SignalRService) { }

  ngOnInit() {
    
    this.signalRService.startConnection();
    this.signalRService.logOutListner();   
    this.signalRService.UpdateLogOutDateTimeListner();
  }

}
