import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private _http: HttpClient) { }

  get()
  {    
    return this._http.get(`${environment.apiUrl}/user/Get`);
  }

  getRoles()
  {    
    return this._http.get(`${environment.apiUrl}/user/GetRoles`);
  }

  delete(id)
  {    
    return this._http.post(`${environment.apiUrl}/user/Delete/`,{id:id});
  }

  Add(obj)
  {    
    return this._http.post(`${environment.apiUrl}/user/Post/`,obj);
  }

  Update(obj)
  {    
    return this._http.post(`${environment.apiUrl}/user/Update/`,obj);
  }

}
