import { Component, OnInit } from '@angular/core'
//import { Subject } from 'rxjs';
import { LoadingService } from './loading.service'
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  //public isLoading: Subject<boolean>;
  public loading: boolean;

  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {


    this.loadingService.isLoading.subscribe((v) => {
     
      this.loading = v;
    });

  
  }

}
