import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../Services/authentication.service';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  returnUrl='';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private adalService:AdalService
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit(): void {    
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required]      
    });
  }
  get f() { return this.loginForm.controls; }
  
  onSubmit() {    
    
    this.adalService.login();
  }

  // onSubmit() {
  //   this.submitted = true;
  //   // stop here if form is invalid
  //   if (this.loginForm.invalid) {
  //     return;
  //   }
  //   //  this.loading = true;
  //   this.authenticationService.login(this.f.username.value)
  //     .subscribe(
  //       res => {
  //         if (res.statusCode == 200) {           
  //           this.router.navigate([""]);
  //         } else if (res.statusCode == 404) {

 

}
       
