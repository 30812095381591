import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PreApprovalEmailConfigurationService {
  private emailobj:any={
    newflag:""
  }
  constructor(private _http: HttpClient) { }

  Add(obj) {
    return this._http.post(`${environment.apiUrl}/preApprovalemailconfig/Post/`, obj);
  }
  get() {
    return this._http.get(`${environment.apiUrl}/preApprovalemailconfig/get`);
  }

  delete(id) {
    return this._http.post(`${environment.apiUrl}/preApprovalemailconfig/Delete/`,{id:id});
  }
  Update(obj) {
    return this._http.post(`${environment.apiUrl}/preApprovalemailconfig/Update/`, obj);
  }

  GetMastersData() {

    return this._http.get(`${environment.apiUrl}/preApprovalemailconfig/GetMasterData`);

  }
  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/preApprovalemailconfig/ExcelExport`, {responseType: "blob"});
  }

  setupdateData(obj) {      
    this.emailobj = obj;  
  }  
  getupdateData() {  
    return this.emailobj;  
  }


}
