import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class DirectoryMasterService{  

    constructor(private _http: HttpClient) { }


    Upload(formdata:FormData) {   
        
        return this._http.post(`${environment.apiUrl}/Directory/Upload/`, formdata);

      } 
      GetDirectoryDetils() {   
        
        return this._http.get(`${environment.apiUrl}/Directory/Get/`);

      }
      ExcelToExport() {   
        
        return this._http.get(`${environment.apiUrl}/Directory/ExcelExport/`,{responseType: "blob"});

      } 
      GetSearchFilterMastersData() {   
        
        return this._http.get(`${environment.apiUrl}/Directory/GetSearchFiltersMastersData/`);

      }    
      GetMastersData() {   
        
        return this._http.get(`${environment.apiUrl}/Directory/GetMasterData/`);

      } 
      UpdateData(obj) {   
        
        return this._http.post(`${environment.apiUrl}/Directory/Update/`,obj);

      }
      MassUpdateData(obj) {   
        
        return this._http.post(`${environment.apiUrl}/Directory/MassUpdate/`,obj);

      }  
      DirectoryExcelexport(columnnames) {   
        
        return this._http.post(`${environment.apiUrl}/Directory/ExcelExport/`,columnnames,{responseType: "blob"});

      } 

      SaveData(obj) {   
        
        return this._http.post(`${environment.apiUrl}/Directory/Save`,obj);

      }  
      DeleteData(id) {   
        
        return this._http.post(`${environment.apiUrl}/Directory/Delete`,{id:id});

      }   
      DirectoryTempleateExport() {   
        
        return this._http.get(`${environment.apiUrl}/Directory/DownloadTemplate/`,{responseType: "blob"});

      }
      GetEntityDetails() {   
        
        return this._http.get(`${environment.apiUrl}/Directory/GetEntityDetails/`);

      }

  getAuditTrail() {
       
    return this._http.get(`${environment.apiUrl}/AuditClientEntities/Get`);

      }





}
