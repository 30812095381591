import { Component, OnInit } from '@angular/core';
import{ DashBoardCountService } from './dashboard-count.service'

@Component({
  selector: 'app-dashborad-count',
  templateUrl: './dashborad-count.component.html',
  styleUrls: ['./dashborad-count.component.scss']
})
export class DashboradCountComponent implements OnInit {

  constructor(private service:DashBoardCountService) { }
  public selectedYear:any;
 public lstYear:any=[];
public eyRegionMasterData:any;
public IROfficeMasterData:any;
public selectedRegion:any;
public selectedIROffice:any;
public selectedLead: any;
public DashboardData:any={
  bankCount :0,
  apAuditCount : 0,
  apAuditStatusNotstartedCount:0,
  apAuditStatusInprogressCount :0,
  apAuditStatusCompletedCount : 0,
  apCompletedRetio :0,
  kpAuditCount : 0,
  kpAuditStatusNotstartedCount : 0,
  kpAuditStatusInprogressCount : 0,
  kpAuditStatusCompletedCount : 0,
  kpCompletedRetio :0,
  rpAuditCount :0,
  rpAuditStatusNotstartedCount : 0,
  rpAuditStatusInprogressCount :0,
  rpAuditStatusCompletedCount :0,
  rpCompletedRetio :0,
  otAuditCount : 0,
  otAuditStatusNotstartedCount : 0,
  otAuditStatusInprogressCount :0,
  otAuditStatusCompletedCount:0,
  otCompletedRetio :0
};
  ngOnInit(): void {
    this.GeneratYear();
    this.selectedYear=new Date().getFullYear();
    this.selectedRegion="All";
    this.selectedIROffice="All";
    this.selectedLead="NA";
    this.GetMastersData();
    this.getallDashboard(this.selectedYear);
    
  }
  GeneratYear(){
    var year = new Date().getFullYear();
    year=2021;   
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
        this.lstYear.push(year + i);
    }
  }
  GetMastersData() {


    this.service.getMastersData()
      .subscribe(
        res => {
          console.log(res);

          this.eyRegionMasterData = res[0];
          this.IROfficeMasterData = res[1];
         

        },
        err => {

          console.log(err);
        }
      )

  }

  getDashboardCounts() {

    var obj={
      eyRegionId: this.selectedRegion == "All" ? 0 : parseInt(this.selectedRegion),
      year:this.selectedYear.toString(),
      lead:this.selectedLead,
      irOffice:this.selectedIROffice == "All" ? 0 : parseInt(this.selectedIROffice)
    }
    this.service.getDashBoarddata(obj).subscribe(
      res => {
      
        this.DashboardData=res;
      },
      err => {
         console.log(err);
      }
    )



    
  }
  getYearWiseDashboard(){

    this.getDashboardCounts();






    // if(this.selectedRegion=='All' && this.selectedLead == 'NA'){
    //   this.getallDashboard(Year);
    //   return
    // }
    // if(this.selectedRegion != 'All' && this.selectedLead == 'NA')
    // {
    //   var obj={
    //     eyRegionId:Number(this.selectedRegion),
    //     year:this.selectedYear.toString(),
    //     lead:this.selectedLead
    //   }
    //   this.service.getDashBoardData(obj).subscribe(
    //     res => {
    //     
    //     console.log(res);
    //     this.DashboardData=res; 
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   )   
    // } 
    // else if(this.selectedRegion != 'All' && this.selectedLead != 'NA')
    // {
    //   var obje={
    //     eyRegionId:Number(this.selectedRegion),
    //     year:this.selectedYear.toString(),
    //     lead:this.selectedLead
    //   }
    //   this.service.getDashBoarddata(obje).subscribe(
    //     res => {
    //       
    //       console.log(res);
    //       this.DashboardData=res;
    //     },
    //     err => {
    //        console.log(err);
    //     }
    //   )
    // }
  }

  getDashboard(regionid) {
    this.getDashboardCounts();
    // if(regionid=='All' && this.selectedLead == 'NA'){
    //   this.getallDashboard(this.selectedYear);
    //   return
    // }
    // if(this.selectedLead == 'NA')
    // {
    //   var obj={
    //     eyRegionId:Number(regionid),
    //     year:this.selectedYear.toString()
    //   }
    //   this.service.getDashBoardData(obj).subscribe(
    //     res => {
    //       
    //       console.log(res);
    //       this.DashboardData=res;
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   )
    // }
    // else if(this.selectedLead != 'NA')
    // {
    //   var obje={
    //     eyRegionId:Number(this.selectedRegion),
    //     year:this.selectedYear.toString(),
    //     lead:this.selectedLead
    //   }
    //   this.service.getDashBoarddata(obje).subscribe(
    //     res => {
    //       
    //       console.log(res);
    //       this.DashboardData=res;
    //     },
    //     err => {
    //        console.log(err);
    //     }
    //   )
    // }
  }

  getallDashboard(Year) {

    this.getDashboardCounts();
  //   this.service.getAllDashBoardData(Year).subscribe(
  //     res => {
  //       
  //       console.log(res);
  //       this.DashboardData=res;
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   )
  // }

  }

externalLeadFilterChanged(newValue) {
    
    this.getDashboardCounts();
  //     if(newValue == 'NA' && this.selectedRegion == 'All')
  //     {
  //       this.getallDashboard(this.selectedYear);
  //       return
  //     }    
  //     else if(newValue == 'NA' && this.selectedRegion != 'All')
  //     {
  //       this.getDashboard(this.selectedRegion);
  //       return
  //     }
  //     if(this.selectedRegion == 'All')
  //     {
  //     var obj={
  //       eyRegionId:0,
  //       year:this.selectedYear.toString(),
  //       lead:newValue
  //     }
  //     this.service.getDashBoarddata(obj).subscribe(
  //       res => {
  //         
  //         console.log(res);
  //         this.DashboardData=res;
  //       },
  //       err => {
  //          console.log(err);
  //       }
  //     )
  //   }
  //   else
  //   {
  //   var obj={
  //     eyRegionId:Number(this.selectedRegion),
  //     year:this.selectedYear.toString(),
  //     lead:newValue
  //   }
  //   this.service.getDashBoarddata(obj).subscribe(
  //     res => {
  //       
  //       console.log(res);
  //       this.DashboardData=res;
  //     },
  //     err => {
  //        console.log(err);
  //     }
  //   )
  // }
  // }
}}
