import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { PlanningDatesConfigurationService } from './planning-dates-configuration.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-planning-dates-configuration',
  templateUrl: './planning-dates-configuration.component.html',
  styleUrls: ['./planning-dates-configuration.component.scss']
})
export class PlanningDatesConfigurationComponent implements OnInit {
  form: FormGroup;
  public lstYear:any=[];
  public selectedYear:any;


  public btnFlag:boolean=true;
 //#region  toast

 public toasttype: string;
 public toastflag: boolean;
 public toastmessage: string;
public modalflag:boolean=false;
 //#endregion toast
  constructor(private router: Router,private _headerService : HeaderService, private _formBuilder: FormBuilder,private _service:PlanningDatesConfigurationService) { }
////#region actions variables
public divFlag : boolean=false;
public styleDIV = {
  marginTop: "",
  width: "",
  height: ""
};
public actionAccess:any;
////#endregion

  ngOnInit(): void {
    this.form = this.createForm();
    this.GeneratYear();
    this.selectedYear=new Date().getFullYear();
    this.setDivHeight();
    this._headerService.getActions("Planing Date Config").then((value) => {
                  
      this.actionAccess=value;
      if(this.actionAccess.indexOf("View")!=-1){
        this.divFlag=true;
        this.getPlaningdatesConfiguration(this.selectedYear);

      }
      else
      this.router.navigate(['/unauthorizedaccess'])
    });
    
  }

  GeneratYear(){
    var year = new Date().getFullYear();
    year =2021;
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
      this.lstYear.push(year + i);
    }
  }
  createForm() {
    return this._formBuilder.group({
      RPEYAnnouncementdelivery: [],
      RPEYClientdatadue: [],
      RPEYDraftreport: [],
      RPEYIAworkpaperreviewdue: [],
      RPEYDraftreporttoclientdue: [],
      RPEYClientfeedbackdue: [],
      RPEYFinalreporttoclientdue: [],
      RPIRAnnouncementdelivery: [],
      RPIRClientdatadue: [],
      RPIRDraftreport: [],
      RPIRIAworkpaperreviewdue: [],
      RPIRDraftreporttoclientdue: [],
      RPIRClientfeedbackdue: [],
      RPIRFinalreporttoclientdue: [],
      APEYAnnouncementdelivery: [],
      APEYClientdatadue: [],
      APEYDraftreport: [],
      APEYIAworkpaperreviewdue: [],
      APEYDraftreporttoclientdue: [],
      APEYClientfeedbackdue: [],
      APEYFinalreporttoclientdue: [],
      APIRAnnouncementdelivery: [],
      APIRClientdatadue: [],
      APIRDraftreport: [],
      APIRIAworkpaperreviewdue: [],
      APIRDraftreporttoclientdue: [],
      APIRClientfeedbackdue: [],
      APIRFinalreporttoclientdue: [],
      KPEYAnnouncementdelivery: [],
      KPEYClientdatadue: [],
      KPEYDraftreport: [],
      KPEYIAworkpaperreviewdue: [],
      KPEYDraftreporttoclientdue: [],
      KPEYClientfeedbackdue: [],
      KPEYFinalreporttoclientdue: [],
      KPIRAnnouncementdelivery: [],
      KPIRClientdatadue: [],
      KPIRDraftreport: [],
      KPIRIAworkpaperreviewdue: [],
      KPIRDraftreporttoclientdue: [],
      KPIRClientfeedbackdue: [],
      KPIRFinalreporttoclientdue: [],
      OTEYAnnouncementdelivery: [],
      OTEYClientdatadue: [],
      OTEYDraftreport: [],
      OTEYIAworkpaperreviewdue: [],
      OTEYDraftreporttoclientdue: [],
      OTEYClientfeedbackdue: [],
      OTEYFinalreporttoclientdue: [],
      OTIRAnnouncementdelivery: [],
      OTIRClientdatadue: [],
      OTIRDraftreport: [],
      OTIRIAworkpaperreviewdue: [],
      OTIRDraftreporttoclientdue: [],
      OTIRClientfeedbackdue: [],
      OTIRFinalreporttoclientdue: []

    })
 
  }
  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {        
        const control = form.get(field);
        if (control instanceof FormControl) {                        
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {            
          this.validateForm(control);
        }
    });      
}
UpdatePlanningConfiguration() {
  //this.toastflag=false;
  
  // this.validateForm(this.form);
  // if(!this.form.valid)
  // return  
     let lstAudittype:any=[];   
    
    var APobj={    
      AuditType :"AP",
      EyannouncementDelivery:this.form.value.APEYAnnouncementdelivery,
      EyclientDataDue:this.form.value.APEYClientdatadue,
      EydraftReport :this.form.value.APEYDraftreport,
      EyiaworkPaperReviewDue :this.form.value.APEYIAworkpaperreviewdue,
      EydraftReportToClientDue :this.form.value.APEYDraftreporttoclientdue,         
      EyclientFeedbackDue: this.form.value.APEYClientfeedbackdue,
      EyfinalReporttoClientDue: this.form.value.APEYFinalreporttoclientdue,
      IrannouncementDelivery:this.form.value.APIRAnnouncementdelivery,
      IrclientDataDue:this.form.value.APIRClientdatadue,
      IrdraftReport:this.form.value.APIRDraftreport,
      IriaworkPaperReviewDue:this.form.value.APIRIAworkpaperreviewdue,
      IrdraftReportToClientDue:this.form.value.APIRDraftreporttoclientdue,
      IrclientFeedbackDue:this.form.value.APIRClientfeedbackdue,
      IrfinalReporttoClientDue:this.form.value.APIRFinalreporttoclientdue
    }
    lstAudittype.push(APobj);
    var RPobj={    
      AuditType :"RP",
      EyannouncementDelivery:this.form.value.RPEYAnnouncementdelivery,
      EyclientDataDue:this.form.value.RPEYClientdatadue,
      EydraftReport :this.form.value.RPEYDraftreport,
      EyiaworkPaperReviewDue :this.form.value.RPEYIAworkpaperreviewdue,
      EydraftReportToClientDue :this.form.value.RPEYDraftreporttoclientdue,         
      EyclientFeedbackDue: this.form.value.RPEYClientfeedbackdue,
      EyfinalReporttoClientDue: this.form.value.RPEYFinalreporttoclientdue,
      IrannouncementDelivery:this.form.value.RPIRAnnouncementdelivery,
      IrclientDataDue:this.form.value.RPIRClientdatadue,
      IrdraftReport:this.form.value.RPIRDraftreport,
      IriaworkPaperReviewDue:this.form.value.RPIRIAworkpaperreviewdue,
      IrdraftReportToClientDue:this.form.value.RPIRDraftreporttoclientdue,
      IrclientFeedbackDue:this.form.value.RPIRClientfeedbackdue,
      IrfinalReporttoClientDue:this.form.value.RPIRFinalreporttoclientdue
    }
    lstAudittype.push(RPobj);  
        var KPobj={    
      AuditType :"KP",
      EyannouncementDelivery:this.form.value.KPEYAnnouncementdelivery,
      EyclientDataDue:this.form.value.KPEYClientdatadue,
      EydraftReport :this.form.value.KPEYDraftreport,
      EyiaworkPaperReviewDue :this.form.value.KPEYIAworkpaperreviewdue,
      EydraftReportToClientDue :this.form.value.KPEYDraftreporttoclientdue,         
      EyclientFeedbackDue: this.form.value.KPEYClientfeedbackdue,
      EyfinalReporttoClientDue: this.form.value.KPEYFinalreporttoclientdue,
      IrannouncementDelivery:this.form.value.KPIRAnnouncementdelivery,
      IrclientDataDue:this.form.value.KPIRClientdatadue,
      IrdraftReport:this.form.value.KPIRDraftreport,
      IriaworkPaperReviewDue:this.form.value.KPIRIAworkpaperreviewdue,
      IrdraftReportToClientDue:this.form.value.KPIRDraftreporttoclientdue,
      IrclientFeedbackDue:this.form.value.KPIRClientfeedbackdue,
      IrfinalReporttoClientDue:this.form.value.KPIRFinalreporttoclientdue
    }
    lstAudittype.push(KPobj);
    var OTobj={    
      AuditType :"OT",
      EyannouncementDelivery:this.form.value.OTEYAnnouncementdelivery,
      EyclientDataDue:this.form.value.OTEYClientdatadue,
      EydraftReport :this.form.value.OTEYDraftreport,
      EyiaworkPaperReviewDue :this.form.value.OTEYIAworkpaperreviewdue,
      EydraftReportToClientDue :this.form.value.OTEYDraftreporttoclientdue,         
      EyclientFeedbackDue: this.form.value.OTEYClientfeedbackdue,
      EyfinalReporttoClientDue: this.form.value.OTEYFinalreporttoclientdue,
      IrannouncementDelivery:this.form.value.OTIRAnnouncementdelivery,
      IrclientDataDue:this.form.value.OTIRClientdatadue,
      IrdraftReport:this.form.value.OTIRDraftreport,
      IriaworkPaperReviewDue:this.form.value.OTIRIAworkpaperreviewdue,
      IrdraftReportToClientDue:this.form.value.OTIRDraftreporttoclientdue,
      IrclientFeedbackDue:this.form.value.OTIRClientfeedbackdue,
      IrfinalReporttoClientDue:this.form.value.OTIRFinalreporttoclientdue
    }
    lstAudittype.push(OTobj);        
  var obj={
    
    Year:this.selectedYear.toString(),
    AuditTypeDates:lstAudittype
    }
  this._service.Update(obj)
  .subscribe(
    res => {
      
      if(res["message"]=="PlaningDatesConfiguration Updated"){
       this.toasttype="success";
      this.toastflag=true;        
      this.toastmessage="Planning dates has been updated successfully.";         
      //this.form.reset();
      }
      else if(res["message"]=="fail"){
       // this.toasttype="error";
        //this.toastflag=true;
        
      //  this.toastmessage="Error occured please contact support team";
       
      }
          
    },
    err => {
      this.toasttype="error";
     this.toastflag=true;
      
      this.toastmessage="Error occured. Please try after sometime";
      console.log(err);
    }
  )

  
}

  CreatePlanningConfiguration() {
    //this.toastflag=false;
    
    // this.validateForm(this.form);
    // if(!this.form.valid)
    // return  
       let lstAudittype:any=[];   
      
      var APobj={    
        AuditType :"AP",
        EyannouncementDelivery:this.form.value.APEYAnnouncementdelivery,
        EyclientDataDue:this.form.value.APEYClientdatadue,
        EydraftReport :this.form.value.APEYDraftreport,
        EyiaworkPaperReviewDue :this.form.value.APEYIAworkpaperreviewdue,
        EydraftReportToClientDue :this.form.value.APEYDraftreporttoclientdue,         
        EyclientFeedbackDue: this.form.value.APEYClientfeedbackdue,
        EyfinalReporttoClientDue: this.form.value.APEYFinalreporttoclientdue,
        IrannouncementDelivery:this.form.value.APIRAnnouncementdelivery,
        IrclientDataDue:this.form.value.APIRClientdatadue,
        IrdraftReport:this.form.value.APIRDraftreport,
        IriaworkPaperReviewDue:this.form.value.APIRIAworkpaperreviewdue,
        IrdraftReportToClientDue:this.form.value.APIRDraftreporttoclientdue,
        IrclientFeedbackDue:this.form.value.APIRClientfeedbackdue,
        IrfinalReporttoClientDue:this.form.value.APIRFinalreporttoclientdue
      }
      lstAudittype.push(APobj);
      var RPobj={    
        AuditType :"RP",
        EyannouncementDelivery:this.form.value.RPEYAnnouncementdelivery,
        EyclientDataDue:this.form.value.RPEYClientdatadue,
        EydraftReport :this.form.value.RPEYDraftreport,
        EyiaworkPaperReviewDue :this.form.value.RPEYIAworkpaperreviewdue,
        EydraftReportToClientDue :this.form.value.RPEYDraftreporttoclientdue,         
        EyclientFeedbackDue: this.form.value.RPEYClientfeedbackdue,
        EyfinalReporttoClientDue: this.form.value.RPEYFinalreporttoclientdue,
        IrannouncementDelivery:this.form.value.RPIRAnnouncementdelivery,
        IrclientDataDue:this.form.value.RPIRClientdatadue,
        IrdraftReport:this.form.value.RPIRDraftreport,
        IriaworkPaperReviewDue:this.form.value.RPIRIAworkpaperreviewdue,
        IrdraftReportToClientDue:this.form.value.RPIRDraftreporttoclientdue,
        IrclientFeedbackDue:this.form.value.RPIRClientfeedbackdue,
        IrfinalReporttoClientDue:this.form.value.RPIRFinalreporttoclientdue
      }
      lstAudittype.push(RPobj);  
          var KPobj={    
        AuditType :"KP",
        EyannouncementDelivery:this.form.value.KPEYAnnouncementdelivery,
        EyclientDataDue:this.form.value.KPEYClientdatadue,
        EydraftReport :this.form.value.KPEYDraftreport,
        EyiaworkPaperReviewDue :this.form.value.KPEYIAworkpaperreviewdue,
        EydraftReportToClientDue :this.form.value.KPEYDraftreporttoclientdue,         
        EyclientFeedbackDue: this.form.value.KPEYClientfeedbackdue,
        EyfinalReporttoClientDue: this.form.value.KPEYFinalreporttoclientdue,
        IrannouncementDelivery:this.form.value.KPIRAnnouncementdelivery,
        IrclientDataDue:this.form.value.KPIRClientdatadue,
        IrdraftReport:this.form.value.KPIRDraftreport,
        IriaworkPaperReviewDue:this.form.value.KPIRIAworkpaperreviewdue,
        IrdraftReportToClientDue:this.form.value.KPIRDraftreporttoclientdue,
        IrclientFeedbackDue:this.form.value.KPIRClientfeedbackdue,
        IrfinalReporttoClientDue:this.form.value.KPIRFinalreporttoclientdue
      }
      lstAudittype.push(KPobj);
      var OTobj={    
        AuditType :"OT",
        EyannouncementDelivery:this.form.value.OTEYAnnouncementdelivery,
        EyclientDataDue:this.form.value.OTEYClientdatadue,
        EydraftReport :this.form.value.OTEYDraftreport,
        EyiaworkPaperReviewDue :this.form.value.OTEYIAworkpaperreviewdue,
        EydraftReportToClientDue :this.form.value.OTEYDraftreporttoclientdue,         
        EyclientFeedbackDue: this.form.value.OTEYClientfeedbackdue,
        EyfinalReporttoClientDue: this.form.value.OTEYFinalreporttoclientdue,
        IrannouncementDelivery:this.form.value.OTIRAnnouncementdelivery,
        IrclientDataDue:this.form.value.OTIRClientdatadue,
        IrdraftReport:this.form.value.OTIRDraftreport,
        IriaworkPaperReviewDue:this.form.value.OTIRIAworkpaperreviewdue,
        IrdraftReportToClientDue:this.form.value.OTIRDraftreporttoclientdue,
        IrclientFeedbackDue:this.form.value.OTIRClientfeedbackdue,
        IrfinalReporttoClientDue:this.form.value.OTIRFinalreporttoclientdue
      }
      lstAudittype.push(OTobj);        
    var obj={
      
      Year:this.selectedYear.toString(),
      AuditTypeDates:lstAudittype
      }
    this._service.Add(obj)
    .subscribe(
      res => {
        
        if(res["message"]=="PlaningDatesConfiguration Created"){
         this.toasttype="success";
        this.toastflag=true;        
        this.toastmessage="Planning dates has been added successfully.";         
       /// this.form.reset();
        }
        else if(res["message"]=="fail"){
         // this.toasttype="error";
          //this.toastflag=true;
          
        //  this.toastmessage="Error occured please contact support team";
         
        }
            
      },
      err => {
        if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
          //var key=Object.keys(err["error"]["errors"])[0];   
          //this.toasterService.message.next(err.toString());    
          this.toasttype = "warning";
          this.toastflag = true;
          
          this.toastmessage = err.toString();
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
        else{
       
        this.toasttype="error";
       this.toastflag=true;
        
        this.toastmessage="Error occured. Please try after sometime";
        console.log(err);
        setTimeout(() => {
          this.toastflag=false;
         }, 3000);
        }
      }
    )
  
    
  }

  getPlaningdatesConfiguration(year){
    this._service.get(year)
     .subscribe(
       res => {

         
         if(res!=null)
         this.setPlanningDateValues(res);
         else
         {
           this.btnFlag=true;
           this.form.reset();
         }

       },
       err => {

         console.log(err);
       }
     )

  }
  
  generateDays(){
    this._service.GenerateDays(this.selectedYear)
     .subscribe(
       res => {

        if(res["message"]=="generatedDayssuccessfully"){
          this.toasttype="success";
         this.toastflag=true;        
         this.toastmessage="Planning dates has been added successfully.";   
         this.modalflag=false;    
         this.getPlaningdatesConfiguration(this.selectedYear);  
        /// this.form.reset();
         }
         else if(res["message"]=="No data found"){
          this.toasttype="warning";
           this.toastflag=true;
           
          this.toastmessage="Planning dates not found from previous year";
          this.modalflag=false;
          
         }
             
       },
       err => {
        if(err.toString().toLocaleLowerCase().indexOf('max')!=-1 ||err.toString().toLocaleLowerCase().indexOf('allowed')!=-1){
          //var key=Object.keys(err["error"]["errors"])[0];   
          //this.toasterService.message.next(err.toString());    
          this.toasttype = "warning";
          this.toastflag = true;
          
          this.toastmessage = err.toString();
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
        else{
        
        
         this.toasttype="error";
        this.toastflag=true;
         
         this.toastmessage="Error occured. Please try after sometime";
         console.log(err);
        }
       }
     )

  }
  setPlanningDateValues(data){
    this.btnFlag=false;
    this.form.setValue({
      RPEYAnnouncementdelivery: data.rPEyannouncementDelivery,
      RPEYClientdatadue:data.rpEyclientDataDue ,
      RPEYDraftreport:data.rpEydraftReport ,
      RPEYIAworkpaperreviewdue:data.rpEyiaworkPaperReviewDue ,
      RPEYDraftreporttoclientdue: data.rpEydraftReportToClientDue,
      RPEYClientfeedbackdue: data.rpEyclientFeedbackDue,
      RPEYFinalreporttoclientdue: data.rpEyfinalReporttoClientDue,
      RPIRAnnouncementdelivery: data.rPIrannouncementDelivery,
      RPIRClientdatadue: data.rpIrclientDataDue,
      RPIRDraftreport: data.rpIrdraftReport,
      RPIRIAworkpaperreviewdue: data.rpIriaworkPaperReviewDue,
      RPIRDraftreporttoclientdue:data.rpIrdraftReportToClientDue ,
      RPIRClientfeedbackdue: data.rpIrclientFeedbackDue,
      RPIRFinalreporttoclientdue: data.rpIRfinalReporttoClientDue,
      APEYAnnouncementdelivery:data.aPEyannouncementDelivery ,
      APEYClientdatadue:data.apEyclientDataDue ,
      APEYDraftreport: data.apEydraftReport,
      APEYIAworkpaperreviewdue:data.apEyiaworkPaperReviewDue ,
      APEYDraftreporttoclientdue: data.apEydraftReportToClientDue,
      APEYClientfeedbackdue: data.apEyclientFeedbackDue,
      APEYFinalreporttoclientdue: data.apEyfinalReporttoClientDue,
      APIRAnnouncementdelivery:data.aPIrannouncementDelivery ,
      APIRClientdatadue: data.apIrclientDataDue,
      APIRDraftreport: data.apIrdraftReport,
      APIRIAworkpaperreviewdue: data.apIriaworkPaperReviewDue,
      APIRDraftreporttoclientdue: data.apIrdraftReportToClientDue,
      APIRClientfeedbackdue: data.apIrclientFeedbackDue,
      APIRFinalreporttoclientdue:data.apIRfinalReporttoClientDue ,
      KPEYAnnouncementdelivery:data.kPEyannouncementDelivery ,
      KPEYClientdatadue: data.kpEyclientDataDue,
      KPEYDraftreport:data.kpEydraftReport ,
      KPEYIAworkpaperreviewdue: data.kpEyiaworkPaperReviewDue,
      KPEYDraftreporttoclientdue:data.kpEydraftReportToClientDue ,
      KPEYClientfeedbackdue: data.kpEyclientFeedbackDue,
      KPEYFinalreporttoclientdue: data.kpEyfinalReporttoClientDue,
      KPIRAnnouncementdelivery: data.kPIrannouncementDelivery,
      KPIRClientdatadue:data.kpIrclientDataDue ,
      KPIRDraftreport:data.kpIrdraftReport ,
      KPIRIAworkpaperreviewdue: data.kpIriaworkPaperReviewDue,
      KPIRDraftreporttoclientdue: data.kpIrdraftReportToClientDue,
      KPIRClientfeedbackdue: data.kpIrclientFeedbackDue,
      KPIRFinalreporttoclientdue:data.kpIRfinalReporttoClientDue ,
      OTEYAnnouncementdelivery: data.oTEyannouncementDelivery,
      OTEYClientdatadue:data.oTEyclientDataDue ,
      OTEYDraftreport: data.oTEydraftReport,
      OTEYIAworkpaperreviewdue: data.oTEyiaworkPaperReviewDue,
      OTEYDraftreporttoclientdue: data.oTEydraftReportToClientDue,
      OTEYClientfeedbackdue: data.oTEyclientFeedbackDue,
      OTEYFinalreporttoclientdue: data.oTEyfinalReporttoClientDue,
      OTIRAnnouncementdelivery: data.oTIrannouncementDelivery,
      OTIRClientdatadue:data.oTIrclientDataDue ,
      OTIRDraftreport: data.oTIrdraftReport,
      OTIRIAworkpaperreviewdue: data.oTIriaworkPaperReviewDue,
      OTIRDraftreporttoclientdue:data.oTIrdraftReportToClientDue ,
      OTIRClientfeedbackdue: data.oTIrclientFeedbackDue,
      OTIRFinalreporttoclientdue:data.oTIRfinalReporttoClientDue 

    });
  }
  ////#region Action check method
ActionsCheck(action){  
  if(this.actionAccess.indexOf(action)!=-1)
  return true;
  else
  return false;
}
setDivHeight() {
    
  this.styleDIV.width = "100%";
  this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
}

ExportExcel(){
  this._service.ExcelExport(this.selectedYear.toString()).subscribe(
    res => {
      let date = new Date().toString();
      saveAs(res, "PlanningDateConfiguration" + date + ".xlsx");
    },
    err => {

      console.log(err);
    }
  )
}
////#endregion
}
