import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models/User';
import { Router } from '@angular/router';
import { HeaderService } from '../header/header.service';
import jwt_decode from "jwt-decode";
import { AdalService } from 'adal-angular4';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private invalidLogin: boolean;


    constructor(private http: HttpClient, private router: Router, private _HeaderService: HeaderService,private adalService:AdalService) {       
        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('jwt')));
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('jwt')) || []);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    //To get user info from token
    getUserEmail(username:any): any {
        // var jwtToken = JSON.parse(sessionStorage.getItem("jwt"));
        // if (jwtToken !== null && jwtToken !== undefined) {
        //     var decodedUserInfo = jwt_decode(jwtToken.token);
        //     var obj = {
        //         email: decodedUserInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
        //         userName: decodedUserInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        //         RoleName: decodedUserInfo["RoleName"]
        //     };
        //     return obj;
        // }
         return this.http.post<any>(`${environment.apiUrl}/Role/getUserEmail`, { username })
        .pipe(map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            var obj = {
                        email:obj.email,
                        userName: obj.name,
                        RoleName: obj.roleName
                    };
            return obj; 
        }));
    }

    getUserID():number {
        var jwtToken = JSON.parse(sessionStorage.getItem("jwt"));
        var userID=0;
        if (jwtToken !== null && jwtToken !== undefined) {
            var decodedUserInfo = jwt_decode(jwtToken.token);

            var userID=decodedUserInfo["UID"];


        }
return userID;
    }

    login(username: string) {
        return this.http.post<any>(`${environment.apiUrl}/authentication/login`, { username })
            .pipe(map(user => {
                
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // sessionStorage.setItem('username', username);
                sessionStorage.setItem('refreshToken', user.refreshToken);
                sessionStorage.setItem("jwt", JSON.stringify({ token: user.token }));
                //     sessionStorage.setItem("refreshToken", refreshToken);
                this.currentUserSubject.next(user);
                return user;
            }));


        // const credentials = JSON.stringify({ UserName: username });
        // this.http.post(`${environment.apiUrl}/authentication/login`,
        //     credentials, {
        //     headers: new HttpHeaders({
        //         "Content-Type": "application/json"
        //     })
        // }).subscribe(response => {
        //     
        //     const token = (<any>response).token;
        //     const refreshToken = (<any>response).refreshToken;
        //     sessionStorage.setItem("jwt", token);
        //     sessionStorage.setItem("refreshToken", refreshToken);
        //     this.invalidLogin = false;
        //     this.router.navigate([""]);
        // }, err => {
        //     this.invalidLogin = true;
        // });
    }

    logout() {
        
        // remove user from local storage to log user out
        //sessionStorage.removeItem('jwt');
        //sessionStorage.removeItem("refreshToken");
        //sessionStorage.clear();
        sessionStorage.clear();
        this.adalService.logOut();
        //this._HeaderService.ClearActions();
        this.currentUserSubject.next(null);
        this.router.navigate(['login']);
    }
}