<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
<div *ngIf="!AddUpdateFlag">

    <div class="motif-row headerwithbutton">
      <div class="motif-col-xs-6 motif-col-md-6 motif-col-lg-6">
        <div class="motif-modal-header" >
          <h3 class="motif-h3" style="width: max-content;"> Email Configuration For Due Dates</h3>
        </div>
      </div>
        <div class="motif-col-xs-6 motif-col-md-6 motif-col-lg-6 buttonsdiv">
          <button motifButton color="primary" size="large" (click)="ShowFileUploadModel()">
            <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_upload_24px.svg'"></motif-icon>Bulk Upload
          </button> 
          <button size="large" motifButton color="primary" routerLink="/AddUpdateEmailConfigurationForDueDates" (click)="Addemailconfig()" style="margin-left: 20px !important;" title="Add Email Configuration" >
            <motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/content/ic_add_circle_24px.svg'"></motif-icon>Add Email Configuration
          </button>
          <button motifButton color="primary" size="large" (click)="ExportExcel()" style="margin-left: 20px !important;"><motif-icon class="Custom-motif-button-icon" [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
          </button>
        </div>
    </div>


           <!-- FileUpload Model Start-->
           <motif-modal [show]="showFileUploadModel" (close)="showFileUploadModel = false">
            <motif-modal-header>
                Upload
            </motif-modal-header>
            <motif-modal-body>



                <div *ngIf="showFileUploadModel" class="motif-container">

                    <div class="motif-row motif-end-lg">
                        <button style="margin-bottom:8px" size="large" class="motif-col-xs-4 motif-col-md-2"
                            (click)="TempleateExport()" motifButton color="primary">
                            <motif-icon [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Download Template
                        </button>


                    </div>

                    <motif-form-field>
                        <motif-file-upload [disabled]="Uploader.queue.length > 0 " (change)="fileChange($event)"
                            [uploader]="Uploader" [multiple]="false">
                            Drop files here or click to upload
                        </motif-file-upload>
                    </motif-form-field>

                    <motif-uploader (cancel)="clearFiles()" *ngFor="let item of Uploader?.queue"
                        fileName="{{item.file.name}}" progressValue="100" fileSize="" progressLabel=""
                        errorMessage="There was a problem while uploading the file, please upload valid file"
                        [error]="uploadfileerror">
                    </motif-uploader>


                </div>


            </motif-modal-body>
            <motif-modal-footer>
                <button motifButton color="primary" [disabled]="Uploader.queue.length == 0 "
                    (click)="UploadData()">Confirm</button>
                <button motifButton color="secondary" (click)="showFileUploadModel = false">Cancel</button>
            </motif-modal-footer>
        </motif-modal>
        <!-- FileUpload Model End-->

<div class="playbook-main">

    
    
    <!-- <div class="motif-col-xs-3" style="height: 500px;" > -->
        <div class="myemailduetable" style=" width:100%;"> 
            <div class="motif-table"  >
              <div id="dvag-grid"  class="motif-table-wrapper" [ngClass]="{ 'motif-table-first-column-border': true}">
                 <ag-grid-angular  [overlayLoadingTemplate]="overlayLoadingTemplate" class="ag-theme-material motif-ag-grid" [columnDefs]="ColumnDefs" [rowData]="tableDataset" [modules]="modules" (gridReady)="handleGridReady($event)" [animateRows]="true"
                   [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents" [ngStyle]="style">
                 </ag-grid-angular>
              </div>
            </div>
         </div>
    <!-- </div> -->

    <motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
        <motif-modal-header> Delete Audit Type Status </motif-modal-header>
        <motif-modal-body>
            <p> Are you sure, you want to delete audit type status? </p>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="Delete()">Confirm</button>
            <button motifButton color="warn" (click)="deletemodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>
</div>

</div>

<app-add-update-email-configuration-for-due-dates [Params]="EditParams" 
            (BackEventEmitter)="onBack($event)" *ngIf="AddUpdateFlag">
        </app-add-update-email-configuration-for-due-dates>


<motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
    {{toastmessage}}
</motif-toast>
</div>

