import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactTypeService {

  constructor(private _http: HttpClient) { }


  get() {
    return this._http.get(`${environment.apiUrl}/contacttype/get`);
  }

  delete(id) {
    return this._http.post(`${environment.apiUrl}/contacttype/Delete/`,{id:id});
  }

  Add(obj) {
    return this._http.post(`${environment.apiUrl}/contacttype/Post/`, obj);
  }

  Update(obj) {
    return this._http.post(`${environment.apiUrl}/contacttype/Update/`, obj);
  } 
  
  ExportExcel(){
    return this._http.get(`${environment.apiUrl}/contacttype/ExcelExport`, {responseType: "blob"});
  }
}
