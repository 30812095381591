import { Component, ErrorHandler, HostListener, OnInit, ViewChild } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import {ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { Location } from '@angular/common';
import { EditDeleteActionsComponent } from 'src/app/reusable/components/ag-grid/edit-delete-actions/edit-delete-actions.component';
import { saveAs } from 'file-saver';
import { PreApprovalEmailConfigurationService } from '../pre-approval-email-configuration.service';


@Component({
  selector: 'app-pre-approval-email-configuration',
  templateUrl: './pre-approval-email-configuration.component.html',
  styleUrls: ['./pre-approval-email-configuration.component.scss']
})
export class PreApprovalEmailConfigurationComponent implements OnInit {

  @ViewChild('editor') editor;

  constructor(private router: Router,private _headerService : HeaderService,private _PreEmailConfigservice:PreApprovalEmailConfigurationService ,
    private _formBuilder: FormBuilder,private location: Location,private route: ActivatedRoute) { }

    public filterdata: any;
    //#region ag-grid_variables
    modules = AllCommunityModules;
    public tableDataset: any;
    gridApi: any;
    public style = {
      marginTop: "",
      width: "",
      height: ""
    };
    public btnsubmittext: string = "ADD";
    public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    public ColumnDefs: any;
    public frameworkComponents: any;
    
    public deletemodalflag: boolean = false;
    public EmailConfigurationId: any;
    //#endregion ag-grid_variables

    public EditID: any;
    public ISUpdate: boolean = false;
    public showLoader: boolean = false;
    public rowData: any;
  
    //#region  toast
    public toasttype: string;
    public toastflag: boolean;
    public toastmessage: string;
    //#endregion toast

    public AddUpdateFlag: boolean = false;
    public EditParams: any = [];
   
  ////#region actions variables
  public divFlag : boolean=false;
  public Aggridstyle = {
    marginTop: "",
    width: "",
    height: ""
  };
  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };
  public actionAccess:any;
  ////#endregion
  AlreadySelected:boolean=false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableHeight();
  }
    ngOnInit(): void {
      this.setDivHeight();
      this.bindGrid();
      this._headerService.getActions("PreApproval EmailConfig").then((value) => {
        this.actionAccess=value;
        if(this.actionAccess.indexOf("View")!=-1){
          this.divFlag=true;
          this.setFrameworkoptions();
          this.setTableHeight();
          this.buildHeaders();
          this.setTableHeight();
          this.bindGrid();
        }
        else
        this.router.navigate(['/unauthorizedaccess'])
      });
    }
    
    pageloadflag: any = true;
    handleGridReady(params) {
      this.gridApi = params;
      this.gridApi.api.showLoadingOverlay()
      this.gridApi.api.hideOverlay()
      if (this.pageloadflag) {
        this.pageloadflag = false;
  
        const Id: string = this.route.snapshot.queryParamMap.get('id');
        if (Id != null && Id.trim() != "") {
          var id = this._headerService.decryptUsingAES256(Id);
          this._PreEmailConfigservice.get()
            .subscribe(
              res => {
                this.tableDataset = res;
                var filtereddata = this.tableDataset.filter(data => data.preApprovalEmailConfigurationId == Number(id))
                this.filterdata = {
                  rowData: filtereddata[0]
                }
                  if (this.actionAccess.indexOf("Update") != -1) {
                   this.onEditClick(this.filterdata);
                   }
              })
        }
      }
    };
  
    setFrameworkoptions() {
      this.frameworkComponents = {
        editDeleteActions: EditDeleteActionsComponent,
      }
    }
    
    setTableHeight() {
      
      this.style.marginTop = '20px';
      this.style.width = "100%";
      this.style.height = (document.documentElement.clientHeight - 180) + "px";  
    }
    private buildHeaders() {
  ;
      this.ColumnDefs = [
        { headerName: 'Email Subject', field: 'emailSubject', width:400, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true,pinned: 'left' },
        {headerName: 'Service Status', field: 'serviceStatusName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 200 },
        {headerName: 'Approval Status', field: 'approvalStatusName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 200 },
        { headerName: 'Enable Email Trigger', field: 'isEnabled', width:225, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true ,
        cellRenderer: (data) => {
          ;
          var isEnabled=data.value;
          if(isEnabled==true)
              {
            return "Enabled";
              }
          else{
            return "Disabled";
              }
           }
         },
        { headerName: 'Send To', field: 'sendTo', width:150, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Send CC', field: 'sendCc', width:150, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Additional Emails', field: 'additionalEmails', width:205, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', resizable: true },
        { headerName: 'Language', field: 'languageName', width:161, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        { headerName: 'Email Body', field: 'emailBody', width:169, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', resizable: true },
        { headerName: 'Last Updated By', field: 'lastUpdatedByName', width:198, sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',  resizable: true },
        {headerName: 'Last Updated On', field: 'lastUpdatedDate', width:200, sortable: true, unSortIcon: true, filter: 'agDateColumnFilter',  resizable: true,
          cellRenderer: (data) => {
            var celldate = moment(data.value);
            return data.lastUpdatedDate ? "" : celldate.format("DD-MMM-YYYY") + " "
              + celldate.format("H:m");
          },
          
          filterParams: {
            // provide comparator function
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = cellValue;
              if (dateAsString == null) {
                return 0;
              }
              var date = dateAsString.split('T')[0];
              var dateParts = date.split('-');
              var day = Number(dateParts[2]);
              var month = dateParts[1] - 1;
              var year = Number(dateParts[0]);
              var cellDate = moment(year + "/" + month + "/" + day, "YYYY/mm/DD");
  
              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            }
          }
        }
      ]
      if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Add / Update")!=-1){
        this.ColumnDefs.unshift({
        headerName: '',
        cellRenderer: "editDeleteActions",
        cellRendererParams: {
          onEditClick: this.onEditClick.bind(this),
          onDeleteClick: this.onDeleteClick.bind(this),
          Actions:this.actionAccess,
        },
        width: 118,
        sortable: false,
        pinned: 'left',
        autoHeight: false,
        filter: false,
        });
      }
    }
  
    private bindGrid() {
      ;
      this._PreEmailConfigservice.get()
        .subscribe(
          res => {
            this.tableDataset = res;
             console.log(res);
          },
          err => {
            console.log(err);
          }
        )
    }
  
  onBack(status: string) {
      this.AddUpdateFlag = false;
      this.diasabledEditFlag = false;
      if (status == "success") {
        this.toasttype = "success";
        this.toastflag = true;
        this.toastmessage = "Pre-approval email configuration has been added successfully.";
        setTimeout(() => {
          this.toastflag=false;
         }, 3000);
      }
      else if (status == "update") {
        this.toasttype = "success";
        this.toastflag = true;
        this.toastmessage = "Pre-approval email configuration has been updated successfully.";
        setTimeout(() => {
          this.toastflag=false;
         }, 3000);
      }
      this.bindGrid();
      this.EditParams = null;
    }
    public diasabledEditFlag: boolean = false;
    
    onEditClick(e) {
      ;
      var id = this._headerService.encryptUsingAES256(e.rowData.preApprovalEmailConfigurationId);
      this.location.go('/PreApprovalEmailConfiguration?id=' + id);
      this.AddUpdateFlag = true;
      this.AlreadySelected =true;
      this.EditParams = e.rowData;
      var obj = {
        newflag: "AUDupdate",
      }
      this._PreEmailConfigservice.setupdateData(obj);
      console.log(this.EditParams);
      if(this.EditParams!=null){
        if (this.actionAccess.indexOf("Update") != -1) {
         this.onEditClick(this.filterdata);
         }
      }
    }
  
    onDeleteClick(e) {
      let row = e.rowData;
      this.deletemodalflag = true;
      this.EmailConfigurationId = e.rowData.preApprovalEmailConfigurationId;
    }
   
    Delete() {
      this._PreEmailConfigservice.delete(this.EmailConfigurationId)
        .subscribe(
          (res: any) => {
            if (res.message == "success") {
              this.deletemodalflag = false;

              this.toasttype = "success";
              this.toastflag = true;
              this.toastmessage = "Pre-approval email configuration has been deleted successfully.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
  
            } else if (res.message == "PreApprovalCannotBeDeleted") {
              this.showLoader = false;
              this.toasttype = "error";
              this.toastflag = true;
              this.toastmessage = "Pre-approval email configuration cannot be deleted as it was mapped to pre-approval form.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            this.reset();
          },
          err => {
            this.reset();
          }
        )
      }
      reset() {
      this.showLoader = false;
      this.ISUpdate = false;
      this.EditID = null;
      this.EmailConfigurationId = null;
      this.btnsubmittext = "ADD";
      this.bindGrid();
    }
  
  ActionsCheck(action){  
    if(this.actionAccess.indexOf(action)!=-1)
    return true;
    else
    return false;
  }
  setDivHeight() {  
    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }
  
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  
  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  Addemailconfig(){
    this.AddUpdateFlag=true;
    var obj = {
      newflag: "",
    }
    this._PreEmailConfigservice.setupdateData(obj);


  }

  ExportExcel(){
    this._PreEmailConfigservice.ExportExcel().subscribe(
      res => {
        let date = new Date().toString();
        saveAs(res, "PreApprovalEmailConfiguration" + date + ".xlsx");

      },
      err => {

        console.log(err);
      }
    )
  }
}
