<!-- <div class="header">
    <p>Notification Center</p>
    <a class="nav-link" routerLink="/Dashboard"><motif-icon class="notification"[src]="'/assets/icons/content/ic_clear_24px.svg'"></motif-icon></a>
</div> -->

             <!-- commented one <div class="notificatontext" *ngFor="let notification of notifications" >{{notification}}</div> -->

<!-- <div class="notificatontext" *ngFor="let notification of notifications" >{{notification.NotificationName}}</div>


<footer>
    
    <button (click)="clearNotification()" class="csresetbutton" motifButton color="warn">Clear</button>
</footer> -->










<button type="button" data-toggle="modal" data-target="#myModal" (click)="showModel()">Open Modal</button>
<div *ngIf="showModal" >
  <div id="myModal" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <p>Some text in the modal.</p>
            </div>
        </div>
    </div>
</div>

