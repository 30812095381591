import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    template: `<a href="javascript:void(0)" (click)="onclick(params.value)">{{params.value}}</a>`
})
export class MyLinkRendererComponent implements AgRendererComponent {    
    params: any;    

    agInit(params: any): void {
        this.params = params;
    }

    refresh(params: any): boolean {
        return false;
    }    
   onclick($event){ 
    const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }       
        this.params.onClick(params);
    }
}