
<div [ngStyle]="styleDIV" *ngIf="!divFlag"></div>
<div *ngIf="divFlag">
    <div class="playbook-main">
        <div class="examples-container">
            <div>
                <div class="motif-row" id="Preapproval">

                    <div class="motif-col-xs-4 motif-col-lg-3">
                        <motif-tab-bar class="motif-tab-bar">
                            <button motifButton (click)="currentactivetab = 'PreApproval'"
                                [ngClass]="{'motif-active': currentactivetab === 'PreApproval'}"
                                class="motif-text-link motif-active">
                                <motif-icon [src]="'/assets/icons/social/ic_group_24px.svg'"></motif-icon>
                                Pre-Approval
                            </button>
                            <button motifButton class="motif-text-link" *ngIf="ActionsCheck('Audit Trail')" (click)="currentactivetab = 'Audit';AuditTrail.getAuditTrail()"
                                [ngClass]="{'motif-active': currentactivetab === 'Audit'}">
                                <motif-icon [src]="'/assets/icons/action/ic_history_24px.svg'"></motif-icon>
                                Audit Trail
                            </button>
                        </motif-tab-bar>
                    </div>



                    <div class="motif-col-xs-8 motif-col-lg-9" [hidden]="currentactivetab != 'PreApproval'">

                        <div class="motif-row motif-end-lg">

                            <input motifInput id="Search" placeholder="Search.." type="text" [(ngModel)]="ngSearchFilter" (ngModelChange)="onFilterTextBoxChanged()"
                                class="motif-col-xs-4 motif-col-lg-5" />
                            &nbsp;

                            <button size="large" class="motif-col-xs-4 motif-col-md-1" *ngIf="ActionsCheck('Bulk Upload')" motifButton color="primary"
                                (click)="ShowFileUploadModel()">
                                <motif-icon class="Custom-motif-button-icon"
                                    [src]="'/assets/icons/file/ic_file_upload_24px.svg'"></motif-icon>Bulk Upload
                            </button> &nbsp;
                            <button size="large" class="motif-col-xs-4 motif-col-md-1" *ngIf="ActionsCheck('Export')" motifButton color="primary"
                                (click)="ExcelExportpopup()">
                                <motif-icon class="Custom-motif-button-icon"
                                    [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Export To Excel
                            </button> &nbsp;
                            <button size="large" class="motif-col-xs-4 motif-col-md-1" *ngIf="ActionsCheck('Add')" motifButton color="primary"
                                title="Add Pre-Approval" (click)="AddPreApproval()">
                                <motif-icon class="Custom-motif-button-icon"
                                    [src]="'/assets/icons/content/ic_add_circle_24px.svg'"></motif-icon>Add Pre-Approval
                            </button> &nbsp;
                            &nbsp;
                        </div>
                    </div>
                </div>

                <div id="preTab" [hidden]="currentactivetab != 'PreApproval'">
                    <div class="motif-table">
                        <div id="dvag-grid" class="motif-table-wrapper"
                            [ngClass]="{'motif-table-first-column-border': true}">
                            <ag-grid-angular [ngStyle]="style" [suppressRowClickSelection]="true"
                                [rowSelection]="rowSelection" [overlayLoadingTemplate]="overlayLoadingTemplate"
                                class="ag-theme-balham" [columnDefs]="ColumnDefs" [rowData]="tableDataset"
                                [modules]="modules" (gridReady)="handleGridReady($event)" [animateRows]="true"
                                (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)"
                                [pagination]="true" [paginationPageSize]=30 [frameworkComponents]="frameworkComponents">
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>

                <div [hidden]="currentactivetab != 'Audit'">
         
                    <app-audit-pre-approval #AuditTrail></app-audit-pre-approval>
                </div>


            </div>
        </div>
    </div>

    <motif-modal [show]="PreApprovalAddEditModal" (close)="PreApprovalAddEditModal = false" [slideout]="true"
        class="PreApprovalModal">
        <motif-modal-body id="PreModalbody" class="Modelbodycontainer">
            <form [formGroup]="form" novalidate *ngIf="form">
                <fieldset  [disabled]="viewflag" style="border: 0;">
                    <div class="csdivformmastercreation">
                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-2">
                              <div class="motif-modal-header">
                                <h3 class="motif-h3"> Pre-Approval</h3>
                              </div>
                            </div>
                            <div class="motif-col-xs-12 motif-col-lg-10">
                                <motif-form-field>
                                  <label motifLabel>Form Title</label>
                                  <input motifInput type="text" disabled aria-label="Form Title Form Field" formControlName="formtitle" />
                                </motif-form-field>
                             
                            </div>
                        </div>

                        <div class="motif-row">

                            <div class="motif-col-xs-8 motif-col-lg-8">
                                <motif-form-field>
                                    <label motifLabel>Entity Name</label>
                                    <input motifInput motifSearch type="text" placeholder="Search for entity name..."
                                        [hideClear]="true" (change)="FillEntityBaseDetails()" aria-label="Search"
                                        formControlName="ClientEntityID" [motifTypeahead]="arrayCLientEntity" />
                                    <ng-container *ngIf="form.controls.ClientEntityID.invalid">
                                        <motif-error [showIcon]="false" *ngIf="form.get('ClientEntityID').hasError('required') &&
                                                            form.get('ClientEntityID').touched">Please select a valid entity name!</motif-error>
                                    </ng-container>

                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Department</label>
                                    <input motifInput type="text" id="Department" formControlName="Department" />

                                </motif-form-field>
                            </div>
                        </div>

                        <div class="motif-row">

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Entity Type</label>
                                    <motif-select formControlName="EntityTypeID" class="PreApprovalModalselect"
                                        aria-label="EntityType">
                                        <motif-option *ngFor="let Entity of EntityType" [value]="Entity.entityTypeId">
                                            {{Entity.entityTypeName}}
                                        </motif-option>

                                    </motif-select>
                                    <motif-error *ngIf="
                                          form.get('EntityTypeID').hasError('required') &&
                                          form.get('EntityTypeID').touched
                                          ">Please select entity type!</motif-error>
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Date Service Request</label>
                                    <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="startDatemanDate"
                                        id="datepicker1" #dp2="motifDatepicker" (keydown.enter)="dp2.openCalendar()"
                                        (click)="dp2.toggleCalendar()" formControlName="DateServiceRequest" />

                                        <motif-error *ngIf="
                                        form.get('DateServiceRequest').hasError('required') &&
                                        form.get('DateServiceRequest').touched
                                        ">Please provide date service request!
                                        </motif-error>
                                </motif-form-field>
                            </div>
                        </div>

                        <div class="motif-row">

                            
                            <div class="motif-col-xs-4 motif-col-lg-4" id="preApprovalpartnerManagercols">
                                <p-dropdown [virtualScroll]="true" [disabled]="viewflag" itemSize="15" [options]="lstRPTeammembers"
                                    formControlName="EYPartnerID" optionLabel="contactName" [filter]="true"
                                    filterBy="contactName" [showClear]="true" placeholder="EY Partner">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="PreApprovalModalselect" *ngIf="form.value.EYPartnerID">
                                            <div><b class="newAddedLabel"> EY Partner </b>
                                                {{form.value.EYPartnerID.contactName}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-contact pTemplate="item">
                                        <div class="country-item">
                                            <div>{{contact.contactName}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <motif-form-field>
                                    <motif-error *ngIf="
                                form.get('EYPartnerID').hasError('required') &&
                                form.get('EYPartnerID').touched
                                ">Please select a EY partner!</motif-error>
                                    <motif-error
                                        *ngIf="form.value.EYPartnerID==null && !form.get('EYPartnerID').touched"
                                        class="addedExtraIconUntouched"></motif-error>
                                    <motif-error *ngIf="form.get('EYPartnerID').hasError('required') &&
                                    form.get('EYPartnerID').touched" class="addedExtraIcon"></motif-error>
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4" id="preApprovalpartnerManagercols">
                                <p-dropdown [disabled]="viewflag" [virtualScroll]="true" itemSize="15" [options]="lstRPTeammembers"
                                    formControlName="EYManagerID" optionLabel="contactName" [filter]="true"
                                    filterBy="contactName" [showClear]="true" placeholder="EY Manager">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="PreApprovalModalselect" *ngIf="form.value.EYManagerID">
                                            <div><b class="newAddedLabel" > EY Manager </b>
                                                {{form.value.EYManagerID.contactName}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-contact pTemplate="item">
                                        <div class="country-item">
                                            <div>{{contact.contactName}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <motif-form-field>
                                    <motif-error *ngIf="
                                form.get('EYManagerID').hasError('required') &&
                                form.get('EYManagerID').touched
                                ">Please select a EY Manager!</motif-error>
                                    <motif-error
                                        *ngIf="form.value.EYManagerID==null && !form.get('EYManagerID').touched"
                                        class="addedExtraIconUntouched"></motif-error>
                                    <motif-error *ngIf="form.get('EYManagerID').hasError('required') &&
                                    form.get('EYManagerID').touched" class="addedExtraIcon"></motif-error>
                                </motif-form-field>

                            </div>

                        </div>

                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Client Contact Name</label>
                                    <input motifInput type="text" id="ClientContactName"
                                        aria-label="Client Contact Name" formControlName="ClientContactName" />
                                    <motif-error *ngIf="
                                            form.get('ClientContactName').hasError('required') &&
                                            form.get('ClientContactName').touched
                                            ">Please provide client contact name!
                                    </motif-error>
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Client Contact - Email</label>
                                    <input motifInput type="text" id="ClientContactEmail"
                                        aria-label="Client Contact Email" formControlName="ClientContactEmail" />
                                    <motif-error *ngIf="
                                            form.get('ClientContactEmail').hasError('required') &&
                                            form.get('ClientContactEmail').touched
                                            ">Please provide client contact email!
                                    </motif-error>

                                    <motif-error *ngIf="
                                        form.get('ClientContactEmail').hasError('email')
                                        ">Please provide a valid email</motif-error>
                                </motif-form-field>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Client Contact - Phone</label>
                                    <input motifInput type="text" id="ClientContactPhone"
                                        aria-label="Client Contact Phone" formControlName="ClientContactPhone"
                                        [(ngModel)]="ClientContactPhone" (ngModelChange)="onphoneInputChange($event)" />
                                    <motif-error *ngIf="
                                                form.get('ClientContactPhone').hasError('required') &&
                                                form.get('ClientContactPhone').touched
                                                ">Please provide phone number!
                                    </motif-error>
                                    <motif-error *ngIf="form.get('ClientContactPhone').hasError('pattern')">Please
                                        provide a valid phone no!</motif-error>
                                </motif-form-field>
                            </div>

                            <!-- <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel >Nature of Services</label>
                                    <motif-select formControlName="NatureofServices" class="PreApprovalModalselect"
                                        aria-label="NatureofServices">
                                        <motif-option *ngFor="let item of NatureofServices" [value]="item">{{item}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                            form.get('NatureofServices').hasError('required') &&
                                            form.get('NatureofServices').touched
                                            ">Please select nature of services!</motif-error>
                                </motif-form-field>

                            </div> -->

                        </div>

                        <div class="motif-row">
                            <div class="motif-col-xs-8 motif-col-lg-8">
                                <motif-form-field>
                                    <label motifLabel>Service Title</label>
                                    <input motifInput type="text" id="ServiceTitle" aria-label="Service Title"
                                        formControlName="ServiceTitle" (change)="GenerateFormTitle()"/>
                                    <motif-error *ngIf="
                                                form.get('ServiceTitle').hasError('required') &&
                                                form.get('ServiceTitle').touched
                                                ">Please provide service title!
                                    </motif-error>
                                </motif-form-field>
                            </div>
                        </div>
                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel >Nature of Services</label>
                                    <motif-select formControlName="NatureofServices" class="PreApprovalModalselect"
                                        aria-label="NatureofServices">
                                        <motif-option *ngFor="let item of NatureofServices" [value]="item">{{item}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                            form.get('NatureofServices').hasError('required') &&
                                            form.get('NatureofServices').touched
                                            ">Please select nature of services!</motif-error>
                                </motif-form-field>

                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Fund related</label>
                                    <motif-select formControlName="Fundrelated" class="PreApprovalModalselect"
                                        aria-label="Fundrelated">
                                        <motif-option *ngFor="let item of GroupScope" [value]="item">{{item}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                            form.get('Fundrelated').hasError('required') &&
                                            form.get('Fundrelated').touched
                                            ">Please select fund related!</motif-error>
                                </motif-form-field>

                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Recurring Service</label>
                                    <motif-select formControlName="Recurringservice" class="PreApprovalModalselect"
                                        aria-label="Recurringservice">
                                        <motif-option *ngFor="let item of GroupScope" [value]="item">{{item}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                            form.get('Recurringservice').hasError('required') &&
                                            form.get('Recurringservice').touched
                                            ">Please select recurring service!</motif-error>
                                </motif-form-field>

                            </div>

                            


                        </div>

                        <div class="motif-row">
                            <div class="motif-col-xs-12 motif-col-lg-4 servicedescriptioncol">
                                <motif-form-field>
                                    <label motifLabel>Service Description</label>
                                    <textarea motifInput aria-label="disabled example textarea" id="Servicedescription" class="motif-textarea"
                                    formControlName="Servicedescription"></textarea>
                                    
                                    <motif-error *ngIf="
                                                form.get('Servicedescription').hasError('required') &&
                                                form.get('Servicedescription').touched
                                                ">Please provide service description!
                                    </motif-error>
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4 servicedescriptioncol">
                                <motif-form-field>
                                    <label motifLabel>Reporting Requirements</label>
                                    <textarea motifInput type="text" id="Reportingrequirements" class="motif-textarea"
                                        aria-label="Reporting requirements" formControlName="Reportingrequirements" ></textarea>
                                </motif-form-field>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4 servicedescriptioncol">
                                <motif-form-field>
                                    <label motifLabel>Additional Comments</label>
                                    <textarea motifInput aria-label="disabled example textarea" class="motif-textarea" id="additionalComments"
                                        formControlName="Additionalcomments"></textarea>
                                </motif-form-field>
                            </div>
                        </div>
                        
                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Service Start Date</label>
                                    <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="ServiceStartDate"
                                        id="datepicker3" #dp3="motifDatepicker" (keydown.enter)="dp3.openCalendar()"
                                        (click)="dp3.toggleCalendar()" formControlName="ServiceStartDate" />
                                      
                                </motif-form-field>
                                <motif-error *ngIf="
                                form.get('ServiceStartDate').hasError('required') &&
                                form.get('ServiceStartDate').touched
                                ">Please provide service start date!
                                </motif-error>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Service End Date</label>
                                    <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="ServiceEndDate"
                                        id="datepicker4" #dp4="motifDatepicker" (keydown.enter)="dp4.openCalendar()"
                                        (click)="dp4.toggleCalendar()" formControlName="ServiceEndDate" />
                                      
                                </motif-form-field>
                                <motif-error *ngIf="
                                form.get('ServiceEndDate').hasError('required') &&
                                form.get('ServiceEndDate').touched
                                ">Please provide service end date!
                                </motif-error>
                            </div>
                            

                        </div>

                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Total Hours</label>
                                    <input motifInput type="text" id="TotalHours" aria-label="Total Hours" swissNumberFormat
                                        formControlName="TotalHours" [(ngModel)]="totalHours" (ngModelChange)="onTotalHoursInputChange($event)"/>
                                    <motif-error *ngIf="
                                                    (form.get('TotalHours').hasError('required') && form.get('TotalHours').touched) || totalHours == ''
                                                    ">Please provide total hours!
                                    </motif-error>
                                </motif-form-field>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Total Fee</label>
                                    <input motifInput type="text" id="TotalFee" aria-label="Total Fee" swissNumberFormat
                                        formControlName="TotalFee" [(ngModel)]="totalfee"  (ngModelChange)="onTotalFeeInputChange($event)"/>
                                    <motif-error *ngIf="
                                            (form.get('TotalFee').hasError('required') &&
                                            form.get('TotalFee').touched) ||  totalfee == ''
                                            ">Please provide total fee!
                                    </motif-error>
                                </motif-form-field>
                            </div>

                            
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Fee confirmed</label>
                                    <motif-select formControlName="Feeconfirmed" class="PreApprovalModalselect"
                                        aria-label="Feeconfirmed">
                                        <motif-option *ngFor="let item of GroupScope" [value]="item">{{item}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                                      form.get('Feeconfirmed').hasError('required') &&
                                                      form.get('Feeconfirmed').touched
                                                      ">Please select fee confirmed!</motif-error>
                                </motif-form-field>


                            </div>
                            

                        </div>
                        <div class="motif-row">


                            <div class="motif-col-xs-4 motif-col-lg-12">
                                <motif-form-field>
                                    <label motifLabel>Fee comments</label>
                                    <textarea motifInput aria-label="disabled example textarea" class="motif-textarea" id="feeComments"
                                        formControlName="Feecomments"></textarea>
                                </motif-form-field>
                            </div>
                        </div>

                        <span class="fonts-col__heading-4"><span class="BoldHead2"> Approval Details</span></span>

                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Service Independence checked</label>
                                    <motif-select formControlName="ServiceIndependencechecked"
                                        class="PreApprovalModalselect" aria-label="ServiceIndependencechecked">
                                        <motif-option *ngFor="let item of GroupScope" [value]="item">{{item}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                                      form.get('ServiceIndependencechecked').hasError('required') &&
                                                      form.get('ServiceIndependencechecked').touched
                                                      ">Please select service independence checked!</motif-error>
                                </motif-form-field>


                            </div>
                        </div>

                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-4">

                                <motif-form-field>
                                    <label motifLabel>Service Status</label>
                                    <motif-select formControlName="ServiceStatusID" class="ServiceStatus"
                                        aria-label="ServiceStatus">
                                        <motif-option *ngFor="let Status of ServiceStatuses"
                                            [value]="Status.serviceTypeId">
                                            {{Status.serviceTypeName}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                              form.get('ServiceStatusID').hasError('required') &&
                                              form.get('ServiceStatusID').touched
                                              ">Please select service status!</motif-error>
                                </motif-form-field>

                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4">

                                <motif-form-field>
                                    <label motifLabel>Approval Status</label>
                                    <motif-select formControlName="ApprovalStatusID" class="ApprovalStatus"
                                        aria-label="ApprovalStatus">
                                        <motif-option *ngFor="let ApprovalType of ApprovalTypeStatuses"
                                            [value]="ApprovalType.approvalStatusId">
                                            {{ApprovalType.approvalStatusName}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                          form.get('ApprovalStatusID').hasError('required') &&
                                          form.get('ApprovalStatusID').touched
                                          ">Please select approval status!</motif-error>
                                </motif-form-field>

                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Approval Number</label>
                                    <input motifInput type="text" id="ApprovalNumber" aria-label="Approval Number"
                                        formControlName="ApprovalNumber" (change)="GenerateFormTitle()"/>
                                    <motif-error *ngIf="
                                                form.get('ApprovalNumber').hasError('required') &&
                                                form.get('ApprovalNumber').touched
                                                ">Please provide approval number!
                                    </motif-error>
                                   <!-- Approval number "atleast 4 characters" & "start with #" validation removal -- requested by Anke (Done by Devika) -->
                                    <!-- <motif-error *ngIf="
                                                form.get('ApprovalNumber').hasError('pattern')
                                                ">Please provide approval number format start with '#' and at least 4 letters or characters!
                                    </motif-error> -->
                                </motif-form-field>
                            </div>
                        </div>

                        <div class="motif-row">

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Approval Request Date</label>
                                    <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                                        name="ApprovalrequestDate" id="datepicker5" #dp5="motifDatepicker"
                                        (keydown.enter)="dp5.openCalendar()" (click)="dp5.toggleCalendar()"
                                        formControlName="ApprovalrequestDate" />

                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Approval Response Date</label>
                                    <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                                        [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                                        name="ApprovalResponseDate" id="datepicker6" #dp6="motifDatepicker"
                                        (keydown.enter)="dp6.openCalendar()" (click)="dp6.toggleCalendar()"
                                        formControlName="ApprovalResponseDate" />

                                </motif-form-field>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-4">

                                <motif-form-field>
                                    <label motifLabel>Approval Level</label>
                                    <motif-select formControlName="ApprovalLevelID" class="ApprovalLevel"
                                        aria-label="ApprovalLevel">
                                        <motif-option *ngFor="let ApprovalLevel of PreApprovalLevel"
                                            [value]="ApprovalLevel.approvalLevelId">
                                            {{ApprovalLevel.approvalLevelName}}
                                        </motif-option>
                                    </motif-select>
                                    <motif-error *ngIf="
                                      form.get('ApprovalLevelID').hasError('required') &&
                                      form.get('ApprovalLevelID').touched
                                      ">Please select approval level!</motif-error>
                                </motif-form-field>

                            </div>
                        </div>

                        <span class="fonts-col__heading-4"><span class="BoldHead2"> Billing Address:</span></span>

                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Client Entity Name</label>
                                    <input motifInput type="text" id="ClientEntityName" aria-label="Client Entity Name"
                                        formControlName="EntityName" disabled />
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Street</label>
                                    <input motifInput type="text" id="Street" aria-label="Street"
                                        formControlName="Street" disabled />
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-4">
                                <motif-form-field>
                                    <label motifLabel>Postal Code</label>
                                    <input motifInput type="text" id="PostalCode" aria-label="Postal Code"
                                        formControlName="PostalCode" disabled />
                                </motif-form-field>
                            </div>

                        </div>
                        
                        <div class="motif-row">

                            <div class="motif-col-xs-4 motif-col-lg-4" style="align-self: end !important">
                                <motif-form-field>
                                    <label motifLabel>Billing Code</label>
                                    <input motifInput type="text" id="BillingCode" aria-label="Billing Code"
                                        formControlName="BillingCode" />
                                    <motif-error *ngIf="
                                                form.get('BillingCode').hasError('required') &&
                                                form.get('BillingCode').touched
                                                ">Please provide billing code!
                                    </motif-error>
                                </motif-form-field>
                            </div>

                            <div class="motif-col-xs-4 motif-col-lg-8">
                                <motif-form-field>
                                    <label motifLabel>Billing comments</label>
                                    <textarea motifInput aria-label="disabled example textarea" class="motif-textarea"
                                        formControlName="Billingcomments"></textarea>
                                </motif-form-field>
                            </div>


                        </div>
                    </div>
                </fieldset>
            </form>

        </motif-modal-body>
        <motif-modal-footer>
            <fieldset *ngIf="!viewflag" style="display: inline; margin-inline: 0; padding-inline: 0; padding-block: 0; border: none; min-inline-size: 0; margin: 0px 14px;">
              <button motifButton color="primary" *ngIf="ActionsCheck('Add') || ActionsCheck('Update') && btnAddFlag" type="submit"
                buttonColor="yellow" (click)="submit()" value="{{btnsubmittext}}">{{btnsubmittext}}</button>
            </fieldset>

            <button motifButton id="btnCancelEmployee" color="secondary" (click)="OnCancel()">Cancel</button>
        </motif-modal-footer>
    </motif-modal>



    <!-- FileUpload Model Start-->
    <motif-modal [show]="showFileUploadModel" (close)="showFileUploadModel = false">
        <motif-modal-header>
            Upload
        </motif-modal-header>
        <motif-modal-body>



            <div *ngIf="showFileUploadModel" class="motif-container">

                <div class="motif-row motif-end-lg">
                    <button id="Templateexport" size="large" class="motif-col-xs-4 motif-col-md-2" motifButton
                        color="primary" (click)="PreApprovalTempleateExport()">
                        <motif-icon [src]="'/assets/icons/file/ic_file_download_24px.svg'"></motif-icon>Download
                        Template
                    </button>


                </div>

                <motif-form-field>
                    <motif-file-upload [disabled]="PreApprovalUploader.queue.length > 0 " (change)="fileChange($event)"
                        [uploader]="PreApprovalUploader" [multiple]="false">
                        Drop files here or click to upload
                    </motif-file-upload>
                </motif-form-field>

                <motif-uploader (cancel)="clearPreApprovalFiles()" *ngFor="let item of PreApprovalUploader?.queue"
                    fileName="{{item.file.name}}" progressValue="100" fileSize="" progressLabel=""
                    errorMessage="There was a problem while uploading the file, please upload valid file"
                    [error]="uploadfileerror">
                </motif-uploader>


            </div>


        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" [disabled]="PreApprovalUploader.queue.length == 0 "
                (click)="UploadData()">Confirm</button>
            <button motifButton color="secondary" (click)="showFileUploadModel = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>

    <!--- FileUpload Model End-->

    <!-- FileUpload validation modal start-->

    <motif-modal [show]="Validationsmodalflag" (close)="Validationsmodalflag = false">
        <motif-modal-header>
            Incomplete data found in the following fields, please correct and upload again.

        </motif-modal-header>
        <motif-modal-body>

            <motif-accordion type="editorial" id="example-2" *ngFor="let item of invalidarryKeys">
                <motif-accordion-header>
                    {{item}}
                </motif-accordion-header>

                <motif-accordion-content>
                    <ul>
                        <li *ngFor="let invaliditem of validationdata[item].split(',')">{{invaliditem}}</li>
                    </ul>
                </motif-accordion-content>
            </motif-accordion>

        </motif-modal-body>
        <motif-modal-footer>

        </motif-modal-footer>
    </motif-modal>
    <!-- FileUpload validation model end -->

    <motif-modal [show]="deletemodalflag" (close)="deletemodalflag = false">
        <motif-modal-header> Delete Pre-Approval </motif-modal-header>
        <motif-modal-body>
            <p> Are you sure, you want to delete Pre-Approval? </p>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="Delete()">Confirm</button>
            <button motifButton color="warn" (click)="deletemodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>


    <motif-toast [type]="toasttype" [position]="'bottom'" *ngIf="toastflag" [show]="true" (close)="toastflag=false">
        {{toastmessage}}
    </motif-toast>

    <!--excel Export modal start-->
    <motif-modal [show]="ExcelExportmodalflag" (close)="ExcelExportmodalflag = false">
        <motif-modal-header>
            Export To Excel
        </motif-modal-header>
        <motif-modal-body>

            <form [formGroup]="ExcelToexportForm">
                <motif-form-field>
                    <motif-radio-group formControlName="radioexceltype" (change)="ReportOptionChange()">
                        <div class="motif-row">
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="all" [title]="'Custom radio element title'">ALL
                                </motif-radio-button>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="MOFP" [title]="'Custom radio element title'">MO F&P
                                </motif-radio-button>
                            </div>
                            <div class="motif-col-xs-4 motif-col-lg-2">
                                <motif-radio-button value="Custom" [title]="'Custom radio element title'">Custom
                                </motif-radio-button>
                            </div>
                        </div>
                    </motif-radio-group>
                </motif-form-field>

                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFormTitle">Form Title</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEntityName">Entity Name</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkDepartment">Department</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEntityType">Entity Type</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>                    
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEYPartner">EY Partner</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkEYManager">EY Manager</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkDataServiceRequest">Date Service Request
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkClientContactName">Client Contact Name
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>                    
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkClientContactEmail">Client Contact Email
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkClientContactPhone">Client Contact Phone
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkServiceTitle">Service Title</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkNatureofServices">Nature of Services
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>                    
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFundrelated">Fund – related?</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkRecurringservice">Recurring service?
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkServicedescription">Service description
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkServiceStartDate">Service - Start Date
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkServiceEndDate">Service - End Date</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkReportingrequirements">Reporting requirements
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkAdditionalcommentsnotes">Additional comments/notes
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkTotalHours">Total Hours</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkTotalFee">Total Fee</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFeeconfirmedwithClient">Fee confirmed with Client?
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkFeecommentsnotes">Fee comments/notes
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkServiceIndependencecheckedandconfirmed">Service
                                    Independence checked and confirmed? </motif-checkbox>
                            </motif-form-field>
                        </div>

                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkServiceStatus">Service Status</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkApprovalStatus">Approval Status</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkApprovalNumber">Approval Number</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkApprovalrequesttoManagement">Approval request to
                                    Management</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>

                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkApprovalresponsefromManagement">Approval response
                                    from Management</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkApprovalLevel">Approval Level</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkBillingCode">Billing Code</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkClientEntityName">Client Entity Name
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
                <div class="motif-row">
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkStreet">Street</motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkPostalCodeLocation">Postal Code Location
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                    <div class="motif-col-xs-1">
                        <div>
                            <motif-form-field>
                                <motif-checkbox formControlName="chkBillingcommentsnotes">Billing comments/notes
                                </motif-checkbox>
                            </motif-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="PreApprovalExcelexport(ExcelToexportForm)"
                type="submit">Export</button>
            <button motifButton color="secondary" (click)="ExcelExportmodalflag = false">Cancel</button>
        </motif-modal-footer>
    </motif-modal>

    <!--excel export modal end-->

    <motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
        <motif-modal-header>
            Confirmation
    
        </motif-modal-header>
        <motif-modal-body>
          <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
        </motif-modal-body>
        <motif-modal-footer>
            <button motifButton color="primary" (click)="escapeYes()" type="submit">Yes</button>
           <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
        </motif-modal-footer>
    </motif-modal>

</div>