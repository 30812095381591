<div>
    <section>
        <div class="dashboardCountMain">
            <div class="bashboardHeader">

                <div class="motif-row">
                    <div class="motif-col-xs-4 motif-col-md-2">
                        <h3 class="motif-h3"> Audit Count and Status<span></span></h3>
                    </div>
                    <div class="motif-col-xs-4 motif-col-md-1">
                        <motif-form-field>
                            <label motifLabel>Select Year</label>
                            <motif-select [(ngModel)]="selectedYear" (change)="getYearWiseDashboard()"
                                [multiple]="false" aria-label="function Form Field">
                                <motif-option *ngFor="let item of lstYear" [value]="item">
                                    {{item}}
                                </motif-option>
                            </motif-select>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-md-1">
                        <motif-form-field>
                            <label motifLabel>Select Lead</label>
                            <motif-select [(ngModel)]="selectedLead" (change)="externalLeadFilterChanged($event)"
                                [(ngModel)]="selectedLead" [multiple]="false" aria-label="function Form Field">
                                <motif-option value="NA">All</motif-option>
                                <motif-option value="EY">EY</motif-option>
                                <motif-option value="IR">IR</motif-option>

                            </motif-select>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-md-2">
                        <!-- <div class="regionSelect"> -->
                        <motif-form-field>
                            <label motifLabel>Select Region</label>
                            <motif-select [(ngModel)]="selectedRegion" [multiple]="false"
                                aria-label="function Form Field" (change)="getDashboard($event)">
                                <motif-option value="All">
                                    All</motif-option>
                                <motif-option *ngFor="let item of eyRegionMasterData" [value]="item.eyregionId">
                                    {{item.eyregionName}}</motif-option>
                            </motif-select>
                        </motif-form-field>
                    </div>
                    <div class="motif-col-xs-4 motif-col-md-2">
                        <motif-form-field>
                            <label motifLabel>IR Office</label>
                            <motif-select (change)="getDashboard($event)"
                                [(ngModel)]="selectedIROffice" [multiple]="false" aria-label="function Form Field">
                                <motif-option value="All">All</motif-option>
                                <motif-option *ngFor="let item of IROfficeMasterData" [value]="item.irofficeId">
                                    {{item.irofficeName}}</motif-option>

                            </motif-select>
                        </motif-form-field>
                    </div>
                    
                </div>
            </div>
            <div class="bashboardBody">
                <div class="RegionsCountBlock">
                    <ul class="list-inline">
                        <li>
                            <div class="card">
                                <span class="BoldHead">Count of Banks</span>
                                <h3>{{DashboardData.bankCount}}</h3>
                                <!-- <span class="ratio"> {{DashboardData.bankCount}}%</span> -->
                                <!-- <label class="CRatio">Completion Ratio</label> -->
                                <br>
                            </div>
                        </li>
                        <li>
                            <div class="card">
                                <span class="BoldHead">AP Audits Count</span>
                                <h3>{{DashboardData.apAuditCount}}</h3>
                                <span class="ratio"> {{DashboardData.apCompletedRetio}}%</span>
                                <label class="CRatio">Completion Ratio</label>
                            </div>
                        </li>
                        <li>
                            <div class="card">
                                <span class="BoldHead">KP Audits Count</span>
                                <h3>{{DashboardData.kpAuditCount}}</h3>
                                <span class="ratio"> {{DashboardData.kpCompletedRetio}}% </span>
                                <label class="CRatio">Completion Ratio</label>
                            </div>
                        </li>
                        <li>
                            <div class="card">
                                <span class="BoldHead">RP Audits Count</span>
                                <h3>{{DashboardData.rpAuditCount}}</h3>
                                <span class="ratio"> {{DashboardData.rpCompletedRetio}}%</span>
                                <label class="CRatio">Completion Ratio</label>
                            </div>
                        </li>
                        <li>
                            <div class="card">
                                <span class="BoldHead">Other Audits Count </span>
                                <h3>{{DashboardData.otAuditCount}}</h3>
                                <span class="ratio"> {{DashboardData.otCompletedRetio}}%</span>
                                <label class="CRatio">Completion Ratio</label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="RegionsCounttype">
                    <ul class="list-inline">
                        <li>
                            <div class="card RegionType">
                                <h4>Total Audits</h4>
                                <div class="status">
                                    <h6>Status</h6>
                                    <ul class="list-inline">
                                        <li class="notStart">
                                            <label for="">Not Started</label>
                                            <span> {{DashboardData.apAuditStatusNotstartedCount +
                                                DashboardData.kpAuditStatusNotstartedCount
                                                +
                                                DashboardData.rpAuditStatusNotstartedCount+DashboardData.otAuditStatusNotstartedCount}}</span>
                                        </li>
                                        <li class="inPro">
                                            <label for="">In Progress </label>
                                            <span>
                                                {{DashboardData.apAuditStatusInprogressCount+DashboardData.rpAuditStatusInprogressCount
                                                +DashboardData.kpAuditStatusInprogressCount+DashboardData.otAuditStatusInprogressCount}}</span>
                                        </li>
                                        <li class="compl">
                                            <label for="">Completed </label>
                                            <span>
                                                {{DashboardData.apAuditStatusCompletedCount+DashboardData.otAuditStatusCompletedCount
                                                +DashboardData.kpAuditStatusCompletedCount+DashboardData.rpAuditStatusCompletedCount}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="card RegionType">
                                <h4>Regulatory Audit</h4>
                                <div class="status">
                                    <h6>AP Status </h6>
                                    <ul class="list-inline">
                                        <li class="notStart">
                                            <label for="">Not Started</label>
                                            <span> {{DashboardData.apAuditStatusNotstartedCount}}</span>
                                        </li>
                                        <li class="inPro">
                                            <label for="">In Progress </label>
                                            <span> {{DashboardData.apAuditStatusInprogressCount}}</span>
                                        </li>
                                        <li class="compl">
                                            <label for="">Completed </label>
                                            <span> {{DashboardData.apAuditStatusCompletedCount}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="card RegionType">
                                <h4>Loan Audit</h4>
                                <div class="status">
                                    <h6>KP Status </h6>
                                    <ul class="list-inline">
                                        <li class="notStart">
                                            <label for="">Not Started</label>
                                            <span> {{DashboardData.kpAuditStatusNotstartedCount}}</span>
                                        </li>
                                        <li class="inPro">
                                            <label for="">In Progress </label>
                                            <span> {{DashboardData.kpAuditStatusInprogressCount}}</span>
                                        </li>
                                        <li class="compl">
                                            <label for="">Completed </label>
                                            <span> {{DashboardData.kpAuditStatusCompletedCount}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="card RegionType">
                                <h4>Financial Audit</h4>
                                <div class="status">
                                    <h6>RP Status </h6>
                                    <ul class="list-inline">
                                        <li class="notStart">
                                            <label for="">Not Started</label>
                                            <span> {{DashboardData.rpAuditStatusNotstartedCount}}</span>
                                        </li>
                                        <li class="inPro">
                                            <label for="">In Progress </label>
                                            <span> {{DashboardData.rpAuditStatusInprogressCount}}</span>
                                        </li>
                                        <li class="compl">
                                            <label for="">Completed </label>
                                            <span> {{DashboardData.rpAuditStatusCompletedCount}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="card RegionType">
                                <h4>Other Audit</h4>
                                <div class="status">
                                    <h6>OT Status</h6>
                                    <ul class="list-inline">
                                        <li class="notStart">
                                            <label for="">Not Started</label>
                                            <span> {{DashboardData.otAuditStatusNotstartedCount}}</span>
                                        </li>
                                        <li class="inPro">
                                            <label for="">In Progress </label>
                                            <span> {{DashboardData.otAuditStatusInprogressCount}}</span>
                                        </li>
                                        <li class="compl">
                                            <label for="">Completed </label>
                                            <span> {{DashboardData.otAuditStatusCompletedCount}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </section>
</div>